import 'service/asset-service';
import 'service/auth-service';
import 'service/browser-service';
import 'service/card-service';
import 'service/event-emitter-service';
import 'service/communication-service';
import 'service/constants';
import 'service/controller-processor';
import 'service/current-user-service';
import 'service/customer-card-factory';
import 'service/customer-card-service';
import 'service/customer-factory';
import 'service/customer-lookup-service';
import 'service/customer-service';
import 'service/d3-service';
import 'service/data-filter-service';
import 'service/error-message-display-service';
import 'service/error-redirect-service';
import 'service/form-submitted';
import 'service/heap-service';
import 'service/http-client-service';
import 'service/html-to-image-service';
import 'service/html-to-pdf-service';
import 'service/http-auth-error-interceptor-service';
import 'service/idle-check-service';
import 'service/image-editing/models/ImageCategory';
import 'service/image-editing/services/FileReader';
import 'service/file-uploading/services/CSVFileReader';
import 'service/intercom-service-locator';
import 'service/internationalization-service';
import 'service/local-storage-service';
import 'service/localization-service';
import 'service/mcc-service';
import 'service/merchant-application-service';
import 'service/merchant-service';
import 'service/oauth-token-refresh-interceptor';
import 'service/oauth-token-service';
import 'service/pagination-data-service';
import 'service/pagination-service';
import 'service/paper-calendar-filter-service';
import 'service/pdf-generator-service';
import 'service/plan-service';
import 'service/reset-password-service';
import 'service/resize-service';
import 'service/route';
import 'service/server-error-service';
import 'service/session-service';
import 'service/settings-services';
import 'service/subscription-service';
import 'service/subscription-status';
import 'service/time-service';
import 'service/transaction-endpoint-service';
import 'service/transaction-factory';
import 'service/eft-service';
import 'service/transaction-receipt-service';
import 'service/transaction-service';
import 'service/analytics-service';
import 'service/url-service';
import 'service/user-factory';
import 'service/user-permission-service';
import 'service/invoice-service';
import 'service/user-redirection-service';
import 'service/user-service';
import 'service/utils';
import 'service/image2datauri-service';
import 'service/hpp-setting-service';
import 'service/iframe-setting-service';
import 'service/eft-fee-service';
import 'service/age-verification-service';
import 'service/new-reporting-system-service';
import 'service/merchant-account-request-service';
