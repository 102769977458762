/* @flow */

import angular from 'angular';
import ConfigurationService from 'service/configuration-service';
import _ from 'lodash';
import UtilityService from 'service/utility-service';
import ExportFileService from 'service/export-file-service';
import countryProvinceData from 'country-region-data';

/**
 * @ngdoc service
 * @name payhqUIApp.transactionApp.service:TransactionService
 * @requires TransactionFactory
 * @description
 *
 * Service for accessing transaction API
 *
 */
angular
.module('transactionApp')
.service('TransactionService', function TransactionService(Restangular, TransactionFactory,
    $log, HttpClientService, UserPermissionService, $window) {

    var _this = this;

    _this.exportService = new ExportFileService($window);

    const DASHBOARD_TRANSACTION_ENDPOINT = 'dashboard_transactions/';
    const ALL_TRANSACTIONS_PERMISSION = 'MyTransactions_AllTransactions';
    /*
        * Parse API response to array of Transaction objects.
        *
        * @param {Object} response object to parse
        * @return {Array} List of transaction objects
        */
    function parseResponseDataToArray(response) {
        var data = [],
            dataArray = response;

        if (response) {
            // Sometimes the data comes back as an array already, instead of in .data
            if (response.data) {
                dataArray = response.data;
            }
            _.forEach(dataArray, function (value) {
                data.push(TransactionFactory.parseResponse(value, undefined));
            });
        }
        return data;
    }

    function checkAllTranctionPermission(){
        return UserPermissionService.checkUserPermission(UserPermissionService.getUserPermissions(),
            ALL_TRANSACTIONS_PERMISSION);
    }

    function getGetTransactionsEndpoint() {

        const TRANSACTION_ENDPOINT = 'transaction';
        const USER_TRANSACTION_ENDPOINT = 'transaction/user';
        if (checkAllTranctionPermission()) {
            return TRANSACTION_ENDPOINT;
        } else {
            return USER_TRANSACTION_ENDPOINT;
        }
    }

    //https://jira.payfirma.com/jira/browse/CRU-1610
    //expose parseResponseDataToArray because it is needed by customer vault
    _this.parseResponseDataToArray = function (response) {
        return parseResponseDataToArray(response);
    };
    /**
     * Create a new transaction.
     *
     * @param {Object} data - Data to initialize transaction with (optional)
     * @return {Object} transaction - New transaction object
     */
    _this.createTransaction = function (data) {
        return TransactionFactory.createTransaction(data);
    };

    /**
     * Get all dashboard transactions.
     *
     * @param {Object} searchParameters - Search parameters for fetching dashboard transactions
     * @return {Array} List of dashboard transactions
     */
    _this.getDashboardTransactions = function (searchParameters) {
        var getDashboardTransactionRequest = Restangular.one(DASHBOARD_TRANSACTION_ENDPOINT);
        return getDashboardTransactionRequest.get(searchParameters)
            .then(
                function (response) {
                    if (response.data) {
                        return response.data;
                    } else {
                        return undefined;
                    }
                },
                function () {
                    return undefined;
                }
            );
    };

    /**
     * Get all  inital transactions by search.
     *
     * @return {Array} List of transactions for last 30 days
     */
    function getTransactions(searchOptions) {
        let param = _.extend({}, searchOptions.searchParameters);
        const limit = searchOptions ? searchOptions.limit : undefined;
        const cursor = searchOptions ? searchOptions.cursor : undefined;
        const backwards = searchOptions ? searchOptions.backwards : false;

        if (limit) {
            param.limit = limit;
        }
        if (cursor) {
            if (backwards) {
                param.before = cursor;
            } else {
                param.after = cursor;
            }
        }

        return new Promise(function (resolve, reject) {
            HttpClientService
            .doGet(ConfigurationService.getConfig().transactionServiceApi + getGetTransactionsEndpoint(), param)
                .then(
                    function (response) {
                        var returnedCursor;
                        if (response.data.paging) {
                            returnedCursor = response.data.paging.cursor;
                        }
                        resolve(new window.PAYFIRMA.Paging(
                            returnedCursor, parseResponseDataToArray(response.data.transactions)));
                    },
                    function (error) {
                        reject(error);
                    }
                );
        });
    }

    function initializeEndpointAndGetTransactions(searchOptions) {
        return getTransactions(searchOptions);
    }

    /**
     * Get a transaction.
     *
     * @param {Number} transactionId - Id of transaction to fetch
     * @returns {Object} transaction - Returns a transaction object
     */
    _this.getTransaction = function (transactionId) {
        return HttpClientService.doGet(ConfigurationService.getConfig()
                .transactionServiceApi+'transaction/' + transactionId)
                .then(function (response) {
                    return TransactionFactory.parseResponse(response.data, undefined);
                })
                .catch(function(e){
                    throw UtilityService.parseErrorMessage(e);
                });
    };

    function mapTransactionRequestObject(transaction, withCardInfo) {

        var transactionRequestObject = {
            amount: transaction.amount,
            first_name: transaction.first_name,
            last_name: transaction.last_name,
            company: transaction.company,
            bcc_emails: transaction.bcc_email,
            telephone: transaction.telephone,

            address1: transaction.address1,
            address2: transaction.address2,
            city: transaction.city,
            province: transaction.province,
            country: transaction.country,
            postal_code: transaction.postal_code,

            order_id: transaction.order_id,
            invoice_id: transaction.invoice_id,
            description: transaction.description,

            currency: transaction.currency.slice(0, 2) + 'D',

            email: undefined,
            card_expiry_month: undefined,
            card_expiry_year: undefined,
            card_number: undefined,
            cvv2: undefined,

            is_card_terminal_transaction: transaction.is_card_terminal_transaction,
            processor_id: transaction.processor_id
        };

        if (transaction.emailList && transaction.emailList.length) {
            transactionRequestObject.email = transaction.emailList[0];

            if (transaction.emailList.length >= 2) {
                var emailList = transaction.emailList.slice(1, transaction.emailList.length);
                transactionRequestObject.bcc_emails = emailList.toString();
            }
        }
        //card meta data
        if (withCardInfo) {
            transactionRequestObject.card_expiry_month = transaction.card_expiry_month;
            transactionRequestObject.card_expiry_year = transaction.card_expiry_year;
            transactionRequestObject.card_number = transaction.card_number;
            transactionRequestObject.cvv2 = transaction.card_cvv || undefined;
        }

        return transactionRequestObject;
    }

    /**
     * Build transaction service url
     *
     */
    _this.buildTransactionsServiceUrl = function (transaction) {
        var base;
        //build purchase or authorize
        if (transaction.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.SALE) {
            base = 'sale';
        }
        if (transaction.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE) {
            base = 'authorize';
        }
        if (base && transaction.customer_id) {
            base = base + '/customer/' + transaction.customer_id;
        }
        //build refund or captures
        if (transaction.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE) {
            base = 'capture/' + transaction.original_id;
        }
        if (transaction.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.REFUND) {
            base = 'refund/' + transaction.original_id;
        }
        if (transaction.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.NAKED_REFUND) {
            base = 'refund';
        }
        if (transaction.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.VOID) {
            base = 'void/' + transaction.original_id;
        }

        return base;
    };

    /**
     * Save a transaction.
     *
     * @param {Object} customerId - id of the customer for transaction
     * @param {Object} cardId - id of the credit card for transaction
     * @param {Object} transaction - Transaction object to save
     * @returns {Object} transaction - Returns a transaction response returned from endpoint
     */
    _this.doSaleTransactionWithCustomerCard = function (customerId, cardId, transaction) {

        let transactionRequestObject = mapTransactionRequestObject(transaction, false);

        return HttpClientService
            .doPost(ConfigurationService.getConfig().transactionServiceApi+'sale/customer/'+customerId+'/card/'+cardId,
                transactionRequestObject)
            .then(function (response) {
                let transactionResponse = {};
                if (response && response.data) {
                    transactionResponse = response.data;
                } else if (response) {
                    transactionResponse = response;
                }

                return TransactionFactory.parseResponse(transactionResponse, transaction);
            })
            .catch(function(e){
                throw UtilityService.parseErrorMessage(e);
            });
    };

    _this.doAuthorizeTransactionWithCustomerCard = function (customerId, cardId, transaction) {

        let transactionRequestObject = mapTransactionRequestObject(transaction, false);

        return HttpClientService
            .doPost(
                ConfigurationService.getConfig().transactionServiceApi+'authorize/customer/'+customerId+'/card/'+cardId,
                transactionRequestObject)
            .then(function (response) {
                let transactionResponse = {};
                if (response && response.data) {
                    transactionResponse = response.data;
                } else if (response) {
                    transactionResponse = response;
                }

                return TransactionFactory.parseResponse(transactionResponse, transaction);
            })
            .catch(function(e){
                throw UtilityService.parseErrorMessage(e);
            });
    };

    //TODO: NO UNIT TESTS, PLEASE FIX
    /**
     * Save a transaction.
     *
     * @param {Object} customerId - id of the customer for transaction
     * @param {Object} transaction - Transaction object to save
     * @returns {Object} transaction - Returns a transaction response returned from endpoint
     */
    _this.doSaleTransactionWithCustomer = function (customerId, transaction) {

        let transactionRequestObject = mapTransactionRequestObject(transaction, true);

        return HttpClientService
            .doPost(ConfigurationService.getConfig().transactionServiceApi+'sale/customer/'+customerId,
                transactionRequestObject)
            .then(function (response) {
                let transactionResponse = {};
                if (response && response.data) {
                    transactionResponse = response.data;
                } else if (response) {
                    transactionResponse = response;
                }

                return TransactionFactory.parseResponse(transactionResponse, transaction);
            })
            .catch(function(e){
                throw UtilityService.parseErrorMessage(e);
            });
    };

    _this.doAuthorizeTransactionWithCustomer = function (customerId, transaction) {

        let transactionRequestObject = mapTransactionRequestObject(transaction, true);

        return HttpClientService
            .doPost(ConfigurationService.getConfig().transactionServiceApi+'authorize/customer/'+customerId,
                transactionRequestObject)
            .then(function (response) {
                let transactionResponse = {};
                if (response && response.data) {
                    transactionResponse = response.data;
                } else if (response) {
                    transactionResponse = response;
                }

                return TransactionFactory.parseResponse(transactionResponse, transaction);
            })
            .catch(function(e){
                throw UtilityService.parseErrorMessage(e);
            });
    };

    /**
     * Save a transaction.
     *
     * @param {Object} transaction - Transaction object to save
     * @returns {Object} transaction - Returns a transaction object
     */
    _this.doTransaction = function (transaction) {
        if (transaction) {
            let url = _this.buildTransactionsServiceUrl(transaction);

            var transactionServiceObject;
            if (!transaction.is_card_terminal_transaction) {
                transactionServiceObject = mapTransactionRequestObject(transaction, true);
            } else {
                transactionServiceObject = mapTransactionRequestObject(transaction, false);
            }

            return HttpClientService
            .doPost(ConfigurationService.getConfig().transactionServiceApi + url, transactionServiceObject)
            .then(function (response) {
                let transactionResponse = {};
                if (response && response.data) {
                    transactionResponse = response.data;
                } else if (response) {
                    transactionResponse = response;
                }

                return TransactionFactory.parseResponse(transactionResponse, transaction);
            })
            .catch(function(e){
                throw UtilityService.parseErrorMessage(e);
            });
        }
    };
    
    /**
     * Save a transaction.
     *
     * @param {Object} transaction - Transaction object to save
     * @returns {Object} transaction - Returns a transaction response returned from endpoint
     */
    _this.doSaleTransactionWithCardTerminal = function (customerId, transaction) {
        let transactionRequestObject = mapTransactionRequestObject(transaction, true);
        let terminalEndpoint;

        if (customerId === undefined) {
            terminalEndpoint = 'sale/terminal';
        } else {
            terminalEndpoint = 'sale/terminalcustomer/' + customerId;
        }
        return HttpClientService
            .doPost(ConfigurationService.getConfig().transactionServiceApi + terminalEndpoint,
                transactionRequestObject)
            .then(function (response) {
                let transactionResponse = {};
                if (response && response.data) {
                    transactionResponse = response.data;
                } else if (response) {
                    transactionResponse = response;
                }

                return TransactionFactory.parseResponse(transactionResponse, transaction);
            })
            .catch(function(e){
                throw UtilityService.parseErrorMessage(e);
            });
    };

    _this.doAuthorizeTransactionWithCardTerminal = function (transaction) {
        let transactionRequestObject = mapTransactionRequestObject(transaction, true);

        return HttpClientService
            .doPost(ConfigurationService.getConfig().transactionServiceApi + 'authorize/terminal',
                transactionRequestObject)
            .then(function (response) {
                let transactionResponse = {};
                if (response && response.data) {
                    transactionResponse = response.data;
                } else if (response) {
                    transactionResponse = response;
                }

                return TransactionFactory.parseResponse(transactionResponse, transaction);
            })
            .catch(function(e){
                throw UtilityService.parseErrorMessage(e);
            });
    };

    /**
     * Get all transactions items search.
     *
     * @param {num} transactionId - Search parameters for transactions itmes to fetch
     * @return {Array} List of transactions inventory items
     */
    _this.getInventoryItems = function (transactionId) {
        var transactionItemRequest = Restangular.one('transactions', transactionId).one('items');
        return transactionItemRequest.get()
            .then(
                function (response) {
                    return response.data;
                },
                function () {
                    return undefined;
                }
            );
    };

    //to move Canada and USA to the first position
    function moveCountryFirstPosition(countries){
        let canada;
        _.each(countries, function (item,index) {
            if (item.value === 'CA'){
                countries.splice(index, 1);
                canada=item;
            } else if (item.value === 'US'){
                countries.splice(index, 1);
                countries.unshift(canada,item);
            }
        });
    }

    //check if country exist by comparing code or name
    function findCountry (countries, value) {
        if (value !== null) {
            return _.find(countries, (country) => country.countryShortCode.toLowerCase() === value.toLowerCase() ||
                        country.countryName.toLowerCase() === value.toLowerCase());
        }
    }
        
    //check if province exist by comparing code or name
    function findProvince (provinces, value) {
        if (value !== null) {
            return _.find(provinces, (province) => province.name.toLowerCase() === value.toLowerCase() ||
                        province.shortCode.toLowerCase() === value.toLowerCase());
        }
    }

    //to get all countries list having key and value property
    _this.getCountries = function (required = false) {
        let countryDefault = [],countries = [];
        countryDefault.push({
            key: `Select a Country ${ required ? '*' : ''}`,
            value: null
        });
        countries = _.map(countryProvinceData, (countryProvince) => {
            return {
                key: countryProvince.countryName,
                value: countryProvince.countryShortCode
            };
        });
        moveCountryFirstPosition(countries);
        return _.concat(countryDefault,countries);
    };

    //to get all provinces list having key and value property
    _this.getProvinces = function (selectedCountry) {
        let provinces = [];
        provinces = findCountry(countryProvinceData, selectedCountry).regions;
        provinces = _.map(provinces, (province) => {
            return {
                key: province.name,
                value: province.shortCode
            };
        });
        return _.concat([
            {
                key: 'Select a Province/State',
                value: null
            }
        ],provinces);
    };

    //to get name of country and province finding by their code
    _this.getCountryProvinceName = function (source, dest=source, countryKey = 'country', provinceKey = 'province') {
        let country;
        dest[countryKey]=source[countryKey];
        dest[provinceKey]=source[provinceKey];
        if (source[countryKey]) {
            country = findCountry(countryProvinceData, source[countryKey]);
            if (country && country.countryName){
                dest[countryKey]=country.countryName;
            }
        }
        if (country && (country.regions || []).length && source[provinceKey]){
            let province = findProvince(country.regions, source[provinceKey]);
            if (province && province.name){
                dest[provinceKey]=province.name;
            }
        }
    };

    //to get code of country and province finding by their name
    _this.getCountryProvinceCode = function (source, callbackOnChangeCountry, dest=source, countryKey = 'country', provinceKey = 'province') {
        let found = {
            country : false,
            province : false
        };
        const valuesFullName = {
            country : source[countryKey],
            province : source[provinceKey]
        };
        let country = findCountry(countryProvinceData, source[countryKey]);
        if (country){
            found.country = true;
            callbackOnChangeCountry(country.countryShortCode);
            dest[countryKey]=country.countryShortCode;
        }
        if (country && (country.regions || []).length && source[provinceKey]){
            let province = findProvince(country.regions, source[provinceKey]);
            if (province){
                found.province = true;
                dest[provinceKey]=province.shortCode;
            }
        }
        if (!found.country){
            dest[countryKey]=valuesFullName.country;
        }
        if (!found.province){
            dest[provinceKey]=valuesFullName.province;
        }
        return found;
    };

     //to handle when codes were not found
    _this.notFoundOldValues = function (oldValues, source, message, forCountry = true, dest=source, countryKey = 'country', provinceKey = 'province') {
        if (forCountry){
            oldValues.country = source[countryKey];
            oldValues.countryMessage = oldValues.country && (message + oldValues.country);
            dest[countryKey] = null;
        } else {
            oldValues.province = source[provinceKey];
            oldValues.provinceMessage = oldValues.province && (message + oldValues.province);
            dest[provinceKey] = null;
        }
    };

    /**
     * send payment receipt.
     *
     * @param transactionId - transaction id
     * @param {Object} sendReceiptRequest - emails.
     */
    this.sendReceipt = function (transactionId, sendReceiptRequest) {
        return HttpClientService
            .doPost(ConfigurationService.getConfig().transactionServiceApi + 'transaction/' + transactionId +
            '/sendreceipt', sendReceiptRequest);
    };

    //check if string is valid json
    function isJsonString(string) {
        try {
            JSON.parse(string);
        } catch (e) {
            return false;
        }
        return true;
    }

    //format madril returned string
    _this.emailStringToHuman = function (emailString, primary) {
        var emailObject;
        var index;
        var bccEmails;
        //check if string is there and valid json string
        if (emailString && isJsonString(emailString)) {
            emailObject = JSON.parse(emailString);
            index = 0;
            bccEmails = '';
            if (primary) { //Show primary email only
                return emailObject[0];
            } else if (!primary && emailObject.length >= 2) { // return all bcc emails
                angular.forEach(emailObject, function (value) {
                    if (index > 0) {
                        bccEmails = bccEmails + value + ', ';
                    }
                    index++;
                });
                //remove last coma
                bccEmails = bccEmails.replace(/,\s*$/, '');
                return bccEmails;
            }
        } else if (primary) {
            //if string is not valid json it is a single email
            return emailString;
        }
    };

    _this.getNext = function (searchOptions) {
        return initializeEndpointAndGetTransactions(
            {
                limit: searchOptions && searchOptions.limit ? searchOptions.limit :
                    window.PAYFIRMA.CONSTANT.PAGINATION_LOAD_BATCH_SIZE,
                cursor: searchOptions ? searchOptions.cursor : undefined,
                searchParameters: searchOptions ? searchOptions.searchParameters : undefined,
                backwards: false
            });
    };

    _this.getPrevious = function (searchOptions) {
        return initializeEndpointAndGetTransactions(
            {
                limit: searchOptions && searchOptions.limit ? searchOptions.limit :
                    window.PAYFIRMA.CONSTANT.PAGINATION_LOAD_BATCH_SIZE,
                cursor: searchOptions ? searchOptions.cursor : undefined,
                searchParameters: searchOptions ? searchOptions.searchParameters : undefined,
                backwards: true
            });
    };

    _this.getInitialData = function (searchOptions) {
        return initializeEndpointAndGetTransactions(
            {
                limit: searchOptions && searchOptions.limit ? searchOptions.limit :
                    window.PAYFIRMA.CONSTANT.PAGINATION_INITIAL_LOAD_BATCH_SIZE,
                cursor: undefined,
                searchParameters: searchOptions ? searchOptions.searchParameters : undefined,
                backwards: false
            });
    };

    _this.pollMorePreviousData = function (searchOptions) {
        return initializeEndpointAndGetTransactions(
            {
                limit: 100,
                cursor: searchOptions ? searchOptions.cursor : undefined,
                searchParameters: searchOptions ? searchOptions.searchParameters : undefined,
                backwards: true
            });
    };

    _this.createInitialSearchParameters = function () {
        var searchParameters = {};
        //get last 30 days of transactions
        searchParameters.from_date = window.PAYFIRMA.CONSTANT.DEFAULT_CALENDAR_START_DATE.format('YYYY-MM-DD');
        searchParameters.to_date = window.PAYFIRMA.CONSTANT.DEFAULT_CALENDAR_END_DATE.format('YYYY-MM-DD');
        return searchParameters;
    };

    _this.getTransactionMerchant = (transactionId, merchantId) => {
        return HttpClientService.doGet(ConfigurationService.getConfig()
                .transactionServiceApi+'transaction/' + transactionId +'/merchant/'+ merchantId)
                .then(function (response) {
                    return TransactionFactory.parseResponse(response.data, undefined);
                })
                .catch(function(e){
                    throw UtilityService.parseErrorMessage(e);
                });
    };

    _this.patchTransactionMerchant = (transactionId, merchantId, transactionData) => {
        return HttpClientService.doPatch(ConfigurationService.getConfig()
                .transactionServiceApi+'transaction/' + transactionId +'/merchant/'+ merchantId, transactionData)
                .then(function (response) {
                    return TransactionFactory.parseResponse(response.data, undefined);
                })
                .catch(function(e){
                    throw UtilityService.parseErrorMessage(e);
                });
    };

    _this.exportTransactionListCSV = (parameters) => {

        let param = {};

        if (parameters.channel) {
            param.channel = parameters.channel;
        }

        if (parameters.from_date) {
            param.from_date = parameters.from_date;
        }

        if (parameters.to_date) {
            param.to_date = parameters.to_date;
        }

        if (parameters.min_amount) {
            param.min_amount = parameters.min_amount;
        }

        if (parameters.max_amount) {
            param.max_amount = parameters.max_amount;
        }

        if (parameters.transaction_status) {
            param.transaction_status = parameters.transaction_status;
        }

        if (parameters.email) {
            param.email_address = parameters.email_address.keyword;
        }

        if (parameters.advanced) {
            param.from_date = null;
            param.to_date = null; // date filter is cleared when advanced filter is present
            param.advanced = parameters.advanced;
        }

        let exportEndpoint = 'transaction/export/user';
        if (checkAllTranctionPermission()) {
            exportEndpoint = 'transaction/export';
        }

        return HttpClientService.doGet(ConfigurationService.getConfig()
                .transactionServiceApi+ exportEndpoint, param)
            .then(function(response) {
                let fileName = response.data.file_name;

                if (fileName) {
                    _this.exportService.export(fileName);
                } else {
                    throw new Error('File name cannot be empty');
                }
            });
    };

});
