/* eslint-disable */
import angular from 'angular';

function IncomeService($log) {
    const INTERCOM_APP_ID = 'tsfgy65o';
    let log = $log;
    return {
        load: function () {
            try {
                (function () {
                    var w = window;
                    var ic = w.Intercom;
                    if (typeof ic === 'function') {
                        ic('reattach_activator');
                        ic('update', intercomSettings);
                    } else {
                        var d = document;
                        var i = function () {
                            i.c(arguments);
                        };
                        i.q = [];
                        i.c = function (args) {
                            i.q.push(args);
                        };
                        w.Intercom = i;
                        function l() {
                            var s = d.createElement('script');
                            s.type = 'text/javascript';
                            s.async = true;
                            s.src = 'https://widget.intercom.io/widget/tsfgy65o';
                            var x = d.getElementsByTagName('script')[0];
                            x.parentNode.insertBefore(s, x);
                        }

                        if (w.attachEvent) {
                            w.attachEvent('onload', l);
                        } else {
                            w.addEventListener('load', l, false);
                        }
                    }
                })();
            } catch (e) {
                log.error(e);
            }
        },
        boot: function (user) {
            try {
                window.Intercom('boot', {
                    app_id: INTERCOM_APP_ID,
                    name: user.first_name + ' ' + user.last_name, // Full name
                    email: user.email, // Email address
                    created_at: undefined // Signup date as a Unix timestamp
                });
            } catch (e) {
                log.error(e);
            }
        },
        shutdown: function () {
            try {
                window.Intercom('shutdown');
            } catch (e) {
                log.error(e);
            }
        },
        update: function () {
            try {
                window.Intercom('update');
            } catch (e) {
                log.error(e);
            }
        }
    };
}

angular
.module('payhqUIApp')
.service('IntercomServiceLocator', function ($location, $http, $log, CurrentUserService) {
    let intercomInstance;

    function getOrCreateIntercomInstance() {
        if (!intercomInstance) {
            intercomInstance = new IncomeService($log);
            intercomInstance.load();
        }
        return intercomInstance;
    }

    return {
        getInstance: function () {
            return getOrCreateIntercomInstance();
        },
        //Get current user and send info to intercom
        bootIntercom: function () {
            return CurrentUserService.getCurrentUser().then(
                function (response) {
                    //user intercom to post user data
                    if (response) {
                        getOrCreateIntercomInstance().boot(response);
                    }
                },
                function (error) {
                    $log.error(error);
                }
            );
        }
    };
});