import angular from 'angular';
import moment from 'moment';
import SessionStorageService from 'service/session-storage-service';

// Created this function to satisfy the eslint complexity rule
// Placed this function here so that the length of the dashboard
// function below does not exceed 1000 lines as permitted by eslint.
function parseRefunds(transactions, parsedTransactionList) {
    var i;
    var item;
    for (i in transactions) {
        if (transactions.hasOwnProperty(i)) {
            item = transactions[i];
            parsedTransactionList.push({
                date: i, grossTotal: 0, web: 0, mobile: 0, ecommerce: 0, tablet: 0,
                recurring: 0, invoicing: 0, cardTerminal: 0,
                refund: (item.Refund === undefined) ? 0 : item.Refund, totalSale: 0
            });
        }
    }
}

// Created this function to satisfy the eslint complexity rule
// Placed this function here so that the length of the dashboard
// function below does not exceed 1000 lines as permitted by eslint.
function parseTotalSales(transactions, parsedTransactionList) {
    var i;
    var item;
    for (i in transactions) {
        if (transactions.hasOwnProperty(i)) {
            item = transactions[i];
            parsedTransactionList.push({
                date: i, grossTotal: 0, web: 0, mobile: 0, ecommerce: 0, tablet: 0,
                recurring: 0, invoicing: 0, cardTerminal: 0, refund: 0,
                totalSale: (item.TotalSale === undefined) ? 0 : item.TotalSale
            });
        }
    }
}

const webTerminal = 'web';
const cardTerminal = 'cardTerminal';
const mobile = 'mobile';
const tablet = 'tablet';
const ecommerce = 'ecommerce';
const recurring = 'recurring';
const invoicing = 'invoicing';
const hpp = 'hpp';
const shopify = 'shopify';
const iframe = 'iFrame';
const paysubscription = 'paySubscription';
const colorArray = ['#00c5df', '#5664c9', '#f84d35', '#ffa600', '#ffdd00', '#13c68b','#c9bebb','#e29a13','#33ff33','#ff00bb', '#800000', '#FBC2B4', '#E5C2E7'];
const timeMapping = {
    '12 am': 0, '1 am': 1, '2 am': 2, '3 am': 3, '4 am': 4, '5 am': 5, '6 am': 6, '7 am': 7, '8 am': 8, '9 am': 9,
    '10 am': 10, '11 am': 11, '12 pm': 12, '1 pm': 13, '2 pm': 14, '3 pm': 15, '4 pm': 16, '5 pm': 17, '6 pm': 18,
    '7 pm': 19, '8 pm': 20, '9 pm': 21, '10 pm': 22, '11 pm': 23
};

var mapData = {};

// Format number with commas
// @param {Number} number - Number to format
function formatNumber(number) {
    if (number) {
        var parsedNumber = parseFloat(number);
        var formattedNumber = parsedNumber.toFixed(2);
        var parts = formattedNumber.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }
    return 0.00;
}

// Scattermap: Get marker colours
function getMarkerColor(d) {
    var markerColor;
    markerColor = colorArray[5];

    if (d >= mapData.data_range[4]) {
        markerColor = colorArray[0];
    } else if (d >= mapData.data_range[3]) {
        markerColor = colorArray[1];
    } else if (d >= mapData.data_range[2]) {
        markerColor = colorArray[2];
    } else if (d >= mapData.data_range[1]) {
        markerColor = colorArray[3];
    } else if (d >= 0) {
        markerColor = colorArray[4];
    }
    return markerColor;
}


// Scattermap: Get marker size for scatter map
function getMarkerSize(d) {
    var markerSize;
    markerSize = 2;

    if (d >= mapData.data_range[4]) {
        markerSize = 20;
    } else if (d >= mapData.data_range[3]) {
        markerSize = 16;
    } else if (d >= mapData.data_range[2]) {
        markerSize = 12;
    } else if (d >= mapData.data_range[1]) {
        markerSize = 8;
    } else if (d >= 0) {
        markerSize = 4;
    }
    return markerSize;
}

// Scattermap: Get marker options
function getMarkerOptions(d) {
    return {
        'clickable': true,
        'fillColor': getMarkerColor(d),
        'fillOpacity': 0.5,
        'opacity': 0.5,
        'radius': getMarkerSize(d),
        'stroke': false
    };
}


function dashboard($scope, $timeout, AnalyticsService, d3Service, permissions, SessionService, UrlService,
                   PaperToastService) {

    var dashboardTransactions = null;
    var map = null;
    var _this = this;

    _this.checkDemoAccount = function () {
        _this.isDemoVisible = (SessionStorageService.get().getUser().isDemoAccount());
        if (_this.isDemoVisible) {
            PaperToastService.create('Showing sample dashboard data. ' +
                '<a ng-click="paperToast.displayLiveDashboardData()">Click here</a>' +
                ' to display live data.', 'info');
        }
    };

    _this.checkDemoAccount();

    _this.demoImageSource = 'app/css/images/dashboard-wide.jpg';

    function hideDemo() {
        // required to redraw map on screen, as hiding the element will not draw it properly when unhidden
        if (map !== null) {
            map._onResize();
        }
        _this.isDemoVisible = false;
    }

    window.PAYFIRMA.CommunicationService.onDisplayLiveDashboardData(function () {
        hideDemo();
    });

    _this.allowedProducts = {
        web: SessionService.checkUserPermission(permissions.WEB_TERMINAL),
        mobile: SessionService.checkUserPermission(permissions.MOBILE),
        tablet: SessionService.checkUserPermission(permissions.TABLET_POS),
        ecommerce: SessionService.checkUserPermission(permissions.API_MASTER),
        recurring: SessionService.checkUserPermission(permissions.RECURRING),
        invoicing: SessionService.checkUserPermission(permissions.INVOICE),
        cardTerminal: SessionService.checkUserPermission(permissions.CARD_TERMINAL),
        hpp:true,
        shopify:true,
        iFrame: true,
        paySubscription: true,
        refund: true,
        totalSale: true
    };

    _this.showSalesVolumeMap = true;
    _this.chartScale = 'months';
    _this.grossTotal = '0.00';
    _this.numOfSales = '0';
    _this.totoalNewCustomers = '0';
    _this.repeatCustomers = '0';
    _this.avgTotal = '0';
    _this.topStaff = null;
    _this.topCustomers = null;
    _this.markerLayer = null;
    _this.scatterLayer = null;
    _this.loading = false;

    _this.noGrossSalesChart = false;
    _this.noSalesByHourChart = false;
    _this.noTotalTransactionChart = false;
    _this.noTransactionByHourChart = false;

    _this.getTransactionsInProcess = false;

    _this.selectedCurrency = '';


    // Chart Function
    function getChartScale(startDate, endDate) {
        var start = moment(startDate);
        var end = moment(endDate);
        var diffDays = end.diff(start, 'days');

        _this.chartScale = 'years';
        if (diffDays < 1) {
            _this.chartScale = 'hours';
        } else if (diffDays <= 35) {
            _this.chartScale = 'days';
        } else if (diffDays <= 182) {
            _this.chartScale = 'weeks';
        } else if (diffDays <= 730) {
            _this.chartScale = 'months';
        }

        return _this.chartScale;
    }

    // Gets Transactions for Summary, Charts and Maps
    function getTransactions() {
        var extendUserSession = false;
        var startDate = _this.filtercalendarstart;
        var endDate = _this.filtercalendarend;
        var currency = _this.filtercurrency;
        var searchParameters;

        startDate = moment(startDate).format('YYYY-MM-DD');
        endDate = moment(endDate).format('YYYY-MM-DD');

        searchParameters = {
            'date_start': startDate,
            'date_end': endDate,
            'extend_session': extendUserSession,
            'period': getChartScale(startDate, endDate),
            'currency': currency
        };

        _this.getTransactionsInProcess = true;
        _this.loading = true;

        AnalyticsService.getDashboardTransactions(searchParameters).then(function (transactionsResponse) {
            dashboardTransactions = transactionsResponse;
            _this.refreshDashboard();
        });
    }

    // Shortens Currency label
    _this.shortenCurrencyLabel = function (currency) {
        var labelString = '';
        if (currency === 'CAD') {
            labelString = 'CA';
        }
        if (currency === 'USD') {
            labelString = 'US';
        }
        return labelString;
    };

    _this.reloadDashboard = () => {
        getTransactions();
    };

    function parseSalesByHour(transactions, parsedTransactionList){
        var i;
        var item;
        for (i in transactions) {
            if (transactions.hasOwnProperty(i)) {
                item = transactions[i];
                parsedTransactionList.push({
                    index: timeMapping[i],
                    date: i,
                    average: (item.HourlyAverage === undefined) ? 0 : item.HourlyAverage,
                    web: (item.VT_avg === undefined) ? 0 : item.VT_avg,
                    mobile: (item.Mobile_avg === undefined) ? 0 : item.Mobile_avg,
                    ecommerce: (item.eCommerce_avg === undefined) ? 0 : item.eCommerce_avg,
                    tablet: (item.TabletPOS_avg === undefined) ? 0 : item.TabletPOS_avg,
                    recurring: (item.Recurring_avg === undefined) ? 0 : item.Recurring_avg,
                    invoicing: (item.Invoice_avg === undefined) ? 0 : item.Invoice_avg,
                    cardTerminal: (item.CardTerminal_avg === undefined) ? 0 : item.CardTerminal_avg,
                    hpp: (item.HPP_avg === undefined) ? 0 : item.HPP_avg,
                    shopify: (item.Shopify_avg === undefined) ? 0 : item.Shopify_avg,
                    iFrame: (item.iFrame_avg === undefined) ? 0 : item.iFrame_avg,
                    paySubscription: (item.PaySubscription_avg === undefined) ? 0 : item.PaySubscription_avg
                });
            }
        }
        parsedTransactionList.sort(function (a, b) {
            return a.index - b.index;
        });
    }

    function parseTotalTransactions(transactions, parsedTransactionList){
        var i;
        var item;
        for (i in transactions) {
            if (transactions.hasOwnProperty(i)) {
                item = transactions[i];
                parsedTransactionList.push({
                    date: i,
                    numSales: (item.NumberOfSales === undefined) ? 0 : item.NumberOfSales,
                    web: (item.VT_count === undefined) ? 0 : item.VT_count,
                    mobile: (item.Mobile_count === undefined) ? 0 : item.Mobile_count,
                    ecommerce: (item.eCommerce_count === undefined) ? 0 : item.eCommerce_count,
                    tablet: (item.TabletPOS_count === undefined) ? 0 : item.TabletPOS_count,
                    recurring: (item.Recurring_count === undefined) ? 0 : item.Recurring_count,
                    invoicing: (item.Invoice_count === undefined) ? 0 : item.Invoice_count,
                    cardTerminal: (item.CardTerminal_count === undefined) ? 0 : item.CardTerminal_count,
                    hpp: (item.HPP_count === undefined) ? 0 : item.HPP_count,
                    shopify: (item.Shopify_count === undefined) ? 0 : item.Shopify_count,
                    iFrame: (item.iFrame_count === undefined) ? 0 : item.iFrame_count,
                    paySubscription: (item.PaySubscription_count === undefined) ? 0 : item.PaySubscription_count
                });
            }
        }
    }

    function parseGrossSales(transactions, parsedTransactionList){
        var i;
        var item;
        for (i in transactions) {
            if (transactions.hasOwnProperty(i)) {
                item = transactions[i];
                parsedTransactionList.push({
                    date: i,
                    grossTotal: (item.GrossTotal === undefined) ? 0 : item.GrossTotal,
                    web: (item.VT === undefined) ? 0 : item.VT,
                    mobile: (item.Mobile === undefined) ? 0 : item.Mobile,
                    ecommerce: (item.eCommerce === undefined) ? 0 : item.eCommerce,
                    tablet: (item.TabletPOS === undefined) ? 0 : item.TabletPOS,
                    recurring: (item.Recurring === undefined) ? 0 : item.Recurring,
                    invoicing: (item.Invoice === undefined) ? 0 : item.Invoice,
                    cardTerminal: (item.CardTerminal === undefined) ? 0 : item.CardTerminal,
                    hpp: (item.HPP === undefined) ? 0 : item.HPP,
                    shopify: (item.Shopify === undefined) ? 0 : item.Shopify,
                    iFrame: (item.iFrame === undefined) ? 0 : item.iFrame,
                    paySubscription: (item.PaySubscription === undefined) ? 0 : item.PaySubscription
                });
            }
        }
    }

    function parseTransactionsByHour(transactions, parsedTransactionList){
        var i;
        var item;
        for (i in transactions) {
            if (transactions.hasOwnProperty(i)) {
                item = transactions[i];
                parsedTransactionList.push({
                    index: timeMapping[i],
                    date: i,
                    avgCount: (item.AvgCount === undefined) ? 0 : item.AvgCount,
                    web: (item.VT_count_avg === undefined) ? 0 : item.VT_count_avg,
                    mobile: (item.Mobile_count_avg === undefined) ? 0 : item.Mobile_count_avg,
                    ecommerce: (item.eCommerce_count_avg === undefined) ? 0 : item.eCommerce_count_avg,
                    tablet: (item.TabletPOS_count_avg === undefined) ? 0 : item.TabletPOS_count_avg,
                    recurring: (item.Recurring_count_avg === undefined) ? 0 : item.Recurring_count_avg,
                    invoicing: (item.Invoice_count_avg === undefined) ? 0 : item.Invoice_count_avg,
                    cardTerminal: (item.CardTerminal_count_avg === undefined) ? 0 : item.CardTerminal_count_avg,
                    hpp: (item.HPP_count_avg === undefined) ? 0 : item.HPP_count_avg,
                    shopify: (item.Shopify_count_avg === undefined) ? 0 : item.Shopify_count_avg,
                    iFrame: (item.iFrame_count_avg === undefined) ? 0 : item.iFrame_count_avg,
                    paySubscription: (item.PaySubscription_count_avg === undefined) ? 0 : item.PaySubscription_count_avg
                });
            }
        }
        parsedTransactionList.sort(function (a, b) {
            return a.index - b.index;
        });
    }

    function parseTransactions(transactions, widget) {
        var parsedTransactionList = [];
        if (widget === 'salesByHour') {
            parsedTransactionList = parseSalesByHour(transactions, parsedTransactionList);
        } else if (widget === 'grossSales') {
            parseGrossSales(transactions, parsedTransactionList);
        } else if (widget === 'refunds') {
            parseRefunds(transactions, parsedTransactionList);
        } else if (widget === 'totalSales') {
            parseTotalSales(transactions, parsedTransactionList);
        } else if (widget === 'totalTransaction') {
            parseTotalTransactions(transactions, parsedTransactionList);
        } else if (widget === 'transactionByHour') {
            parseTransactionsByHour(transactions, parsedTransactionList);
        }
        return parsedTransactionList;
    }
    //end function

    // Renders Chart
    // @param {String} widget - Name of chart to render
    function renderChart(widget) {
        d3Service.d3().then(function (d3) {
            var data;

            if (widget==='refunds' || widget === 'totalSales') {
                data = dashboardTransactions['grossSales' + 'Chart'];
            } else {
                data = dashboardTransactions[widget + 'Chart'];
            }

            if (widget === 'totalTransaction') {
                data = dashboardTransactions.grossSalesChart;
            } else if (widget === 'transactionByHour') {
                data = dashboardTransactions.salesByHourChart;
            }

            data = parseTransactions(data, widget);

            if (data && data.length > 0) {
                var pWidth = 680;
                var pHeight = 400;
                var colors = d3.scale.ordinal().range(colorArray);

                // Transpose the data into layers with appropriate totals
                colors.domain(d3.keys(data[0]).filter(function (key) {
                    var keyDoesNotEqual = key !== 'date' && key !== 'grossTotal' &&
                        key !== 'numSales' && key !== 'average';
                    var keyDoesNotEqual2 = key !== 'dayOfWeekAverage' && key !== 'index' && key !== 'avgCount';
                    return keyDoesNotEqual && keyDoesNotEqual2;
                }));
                data.forEach(function (d) {
                    var y0 = 0;
                    d.products = colors.domain().map(function (name) {
                        var dataVal = d[name];
                        if (dataVal !== 0 && !_this.allowedProducts[name]) {
                            _this.allowedProducts[name] = true;
                        }
                        return {
                            name: name, y0: y0, y1: y0 += +d[name]
                        };
                    });
                });

                // Hide legend labels for products if needed
                for (var product in _this.allowedProducts) {
                    if (!_this.allowedProducts[product]) {
                        $('#' + widget + ' .' + product + 'Legend').hide();
                    }
                }

                // Calculate max values
                var yMax = 0.00;

                if (widget === 'totalTransaction') {
                    yMax = data ? d3.max(data, function (d) {
                        return d.numSales;
                    }) : 0.00;
                }
                if (widget === 'transactionByHour') {
                    yMax = data ? d3.max(data, function (d) {
                        return d.avgCount;
                    }) : 0.00;
                }
                if (widget === 'grossSales') {
                    yMax = data ? d3.max(data, function (d) {
                        return d.grossTotal;
                    }) : 0.00;
                }
                if (widget === 'refunds') {
                    yMax = data ? d3.max(data, function (d) {
                        return d.refund;
                    }) : 0.00;
                }
                if (widget === 'totalSales') {
                    yMax = data ? d3.max(data, function (d) {
                        return d.totalSale;
                    }) : 0.00;
                }
                if (widget === 'salesByHour') {
                    yMax = data ? d3.max(data, function (d) {
                        return d.average;
                    }) : 0.00;
                }

                if (yMax === 0.00) {
                    $('#' + widget + 'Chart .chart-svg').empty();
                    return;
                }

                // Calculate margins
                var yMaxLen = parseFloat(yMax.toString()).toFixed(2).length;
                var leftPadding = yMaxLen * 7;
                var bottomPadding = 0;
                if (data.length > 0 && ((data[0].date.toString().indexOf('-') !== -1) &&
                    (data[0].date.toString().split('-')[1].length >= 4))) {
                    bottomPadding = 15;
                }

                var margin = {
                        top: 20, right: 40, bottom: 60 + bottomPadding, left: 30 + leftPadding
                    },
                    width = pWidth,
                    height = pHeight - margin.top - margin.bottom;

                // Setup x and y axes
                var x = d3.scale.ordinal()
                    .domain(data.map(function (d) {
                        return d.date;
                    }))
                    .rangeRoundBands([0, width], 0.1);
                var y = d3.scale.linear()
                    .domain([0, yMax + yMax / 5])
                    .range([height, 0]);
                var xAxis = d3.svg.axis()
                    .scale(x)
                    .orient('bottom');

                if (data.length > 15) {
                    // Show every other data point if > 15 points
                    xAxis.tickFormat(function (d, i) {
                        var dataPoint = '';
                        if (i % 2 === 0) {
                            dataPoint = d;
                        }
                        return dataPoint;
                    });
                }

                var format = d3.format('.2f');
                var formatInt = d3.format('d');
                var yAxis;
                var yAxisLabel;

                if (widget === 'totalTransaction') {
                    yAxis = d3.svg.axis()
                        .scale(y)
                        .orient('left')
                        .tickFormat(function (d) {
                            yAxisLabel = formatInt(d);
                            return yAxisLabel;
                        });
                } else if (widget === 'transactionByHour') {
                    yAxis = d3.svg.axis()
                        .scale(y)
                        .orient('left')
                        .tickFormat(function (d) {
                            yAxisLabel = format(d);
                            return yAxisLabel;
                        });
                } else {
                    yAxis = d3.svg.axis()
                        .scale(y)
                        .orient('left')
                        .tickFormat(function (d) {
                            yAxisLabel = format(d);
                            return '$' + yAxisLabel;
                        });
                }

                var makeYAxis = function () {
                    return d3.svg.axis()
                        .scale(y)
                        .orient('left');
                };

                // Setup svg
                $('#' + widget + 'Chart .chart-svg').empty();
                var svg = d3.select('#' + widget + 'Chart .chart-svg').append('svg')
                    .attr('preserveAspectRatio', 'xMinYMin meet')
                    .attr('viewBox', '20 0 730 400')
                    .append('g')
                    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
                svg.append('g')
                    .attr('class', 'x axis')
                    .attr('transform', 'translate(0, ' + height + ')')
                    .call(xAxis)
                    .selectAll('text')
                    .style('font-size', '11px')
                    .style('text-anchor', 'end')
                    .attr('dx', '-.8em')
                    .attr('dy', '.15em')
                    .attr('transform', function () {
                        return 'rotate(-45)';
                    });
                svg.append('g')
                    .attr('class', 'y axis')
                    .style('font-size', '11px')
                    .call(yAxis);
                svg.append('g')
                    .attr('class', 'grid')
                    .call(makeYAxis()
                        .tickSize(-width, 0, 0)
                        .tickFormat('')
                    );

                // Create groups for each series and rects for each segment
                var layer = svg.selectAll('.date')
                    .data(data)
                    .enter().append('g')
                    .attr('class', 'g')
                    .attr('transform', function (d) {
                        return 'translate(' + x(d.date) + ',0)';
                    });

                var rangeBand = x.rangeBand();
                var maxBarWidth = 50;
                var barWidth = Math.min.apply(null, [rangeBand, maxBarWidth]);
                if (barWidth === 0) {
                    barWidth = maxBarWidth;
                }
                layer.selectAll('rect')
                    .data(function (d) {
                        return d.products;
                    })
                    .enter().append('rect')
                    .attr('y', function (d) {
                        return y(d.y1);
                    })
                    .attr('width', barWidth)
                    .attr('height', function (d) {
                        return y(d.y0) - y(d.y1);
                    })
                    .style('fill', function (d) {
                        return colors(d.name);
                    })
                    .attr('rx', '2')
                    .attr('ry', '2');

                if (barWidth === maxBarWidth) {
                    layer.selectAll('rect')
                        .attr('x', (rangeBand - maxBarWidth) / 2);
                }

                // Setup tooltips
                var tooltip = d3.select('body')
                    .append('div')
                    .attr('class', 'chart-tooltip')
                    .style('position', 'absolute')
                    .style('z-index', '10')
                    .style('visibility', 'visible');

                layer.selectAll('rect')
                    .on('mouseover', function () {
                        return tooltip.style('visibility', 'visible');
                    })
                    .on('mouseout', function () {
                        return tooltip.style('visibility', 'hidden');
                    })
                    .on('mousemove', function () {
                        var parent = this.parentNode;
                        var columnData = parent.__data__;
                        var webTotal;
                        var cardTerminalTotal;
                        var mobileTotal;
                        var ecommerceTotal;
                        var invoicingTotal;
                        var tabletTotal;
                        var recurringTotal;
                        var hppTotal;
                        var shopifyTotal;
                        var iframeTotal;
                        var paysubscriptionTotal;
                        var dollarSign = '';
                        var toolTipText = '<section class="dashboard-tooltip">';

                        webTotal = formatNumber(columnData[webTerminal]);
                        cardTerminalTotal = formatNumber(columnData[cardTerminal]);
                        mobileTotal = formatNumber(columnData[mobile]);
                        ecommerceTotal = formatNumber(columnData[ecommerce]);
                        invoicingTotal = formatNumber(columnData[invoicing]);
                        tabletTotal = formatNumber(columnData[tablet]);
                        recurringTotal = formatNumber(columnData[recurring]);
                        hppTotal = formatNumber(columnData[hpp]);
                        shopifyTotal = formatNumber(columnData[shopify]);
                        iframeTotal = formatNumber(columnData[iframe]);
                        paysubscriptionTotal = formatNumber(columnData[paysubscription]);

                        tooltip.style('top', (d3.event.pageY + 10) + 'px').style('left', (d3.event.pageX - 210) + 'px');

                        toolTipText += '<section class="tooltip-header">' + columnData.date + '</section>';
                        toolTipText += '<section class="tooltip-content">';

                        if (widget === 'totalTransaction') {
                            webTotal = formatInt(columnData[webTerminal]);
                            cardTerminalTotal = formatInt(columnData[cardTerminal]);
                            mobileTotal = formatInt(columnData[mobile]);
                            ecommerceTotal = formatInt(columnData[ecommerce]);
                            invoicingTotal = formatInt(columnData[invoicing]);
                            tabletTotal = formatInt(columnData[tablet]);
                            recurringTotal = formatInt(columnData[recurring]);
                            hppTotal = formatNumber(columnData[hpp]);
                            shopifyTotal = formatNumber(columnData[shopify]);
                            iframeTotal = formatNumber(columnData[iframe]);
                            paysubscriptionTotal = formatNumber(columnData[paysubscription]);
                            toolTipText += '<section class="dashboard-tooltip-row gross-total-row">' +
                                '<section>Total Transactions</section><section>' + dollarSign +
                                formatInt(columnData.numSales) + '</section></section>';
                        }

                        if (widget === 'transactionByHour') {
                            toolTipText += '<section class="dashboard-tooltip-row gross-total-row">' +
                                '<section>Avg Hourly Transactions</section><section>' + dollarSign +
                                formatNumber(columnData.avgCount) + '</section></section>';

                        } else if (widget === 'grossSales') {
                            dollarSign = '$';
                            toolTipText += '<section class="dashboard-tooltip-row gross-total-row">' +
                                '<section>Gross Sales</section><section>' +
                                dollarSign + formatNumber(columnData.grossTotal) + '</section></section>';

                        } else if (widget === 'refunds') {
                            dollarSign = '$';
                            toolTipText += '<section class="dashboard-tooltip-row gross-total-row">' +
                                '<section>Refund</section><section>' +
                                dollarSign + formatNumber(columnData.refund) + '</section></section>';

                            toolTipText += '</section>';
                            tooltip.html(toolTipText);

                            return;

                        } else if (widget === 'totalSales') {
                            dollarSign = '$';
                            toolTipText += '<section class="dashboard-tooltip-row gross-total-row">' +
                                '<section>Total Sale</section><section>' +
                                dollarSign + formatNumber(columnData.totalSale) + '</section></section>';

                            toolTipText += '</section>';
                            tooltip.html(toolTipText);

                            return;

                        } else if (widget === 'salesByHour') {
                            dollarSign = '$';
                            toolTipText += '<section class="dashboard-tooltip-row gross-total-row">' +
                                '<section>Avg Hourly Sales</section><section>' + dollarSign +
                                formatNumber(columnData.average) + '</section></section>';
                        }

                        if (_this.allowedProducts[webTerminal] || webTotal !== 0.00) {
                            toolTipText += '<section class="dashboard-tooltip-row"><section class="product_label">' +
                                '<section class="rectangle web-terminal-legend">'+
                                '</section>Web Terminal</section><section>' +
                                dollarSign + webTotal + '</section></section>';
                        }
                        if (_this.allowedProducts[cardTerminal] || cardTerminalTotal !== 0.00) {
                            toolTipText += '<section class="dashboard-tooltip-row"><section class="product_label">' +
                                '<section class="rectangle card-terminal-legend">'+
                                '</section>Card Terminal</section><section>' +
                                dollarSign + cardTerminalTotal + '</section></section>';
                        }
                        if (_this.allowedProducts[mobile] || mobileTotal !== 0.00) {
                            toolTipText += '<section class="dashboard-tooltip-row"><section class="product_label">' +
                                '<section class="rectangle mobile-legend"></section>Mobile</section><section>' +
                                dollarSign + mobileTotal + '</section></section>';
                        }
                        if (_this.allowedProducts[ecommerce] || ecommerceTotal !== 0.00) {
                            toolTipText += '<section class="dashboard-tooltip-row"><section class="product_label">' +
                                '<section class="rectangle ecommerce-legend"></section>Ecommerce</section><section>' +
                                dollarSign + ecommerceTotal + '</section></section>';
                        }
                        if (_this.allowedProducts[tablet] || tabletTotal !== 0.00) {
                            toolTipText += '<section class="dashboard-tooltip-row"><section class="product_label">' +
                                '<section class="rectangle tablet-pos-legend"></section>Tablet POS</section><section>' +
                                dollarSign + tabletTotal + '</section></section>';
                        }
                        if (_this.allowedProducts[recurring] || recurringTotal !== 0.00) {
                            toolTipText += '<section class="dashboard-tooltip-row"><section class="product_label">' +
                                '<section class="rectangle recurring-legend"></section>Recurring Billing</section>' +
                                '<section>' + dollarSign + recurringTotal + '</section></section>';
                        }
                        if (_this.allowedProducts[invoicing] || invoicingTotal !== 0.00) {
                            toolTipText += '<section class="dashboard-tooltip-row"><section class="product_label">' +
                                '<section class="rectangle invoicing-legend"></section>Invoicing</section><section>' +
                                dollarSign + invoicingTotal + '</section></section>';
                        }
                        if (_this.allowedProducts[hpp] || hppTotal !== 0.00) {
                            toolTipText += '<section class="dashboard-tooltip-row"><section class="product_label">' +
                                '<section class="rectangle hpp-legend">'+
                                '</section>HPP</section><section>' +
                                dollarSign + hppTotal + '</section></section>';
                        }
                        if (_this.allowedProducts[shopify] || shopifyTotal !== 0.00) {
                            toolTipText += '<section class="dashboard-tooltip-row"><section class="product_label">' +
                                '<section class="rectangle shopify-legend">'+
                                '</section>Shopify</section><section>' +
                                dollarSign + shopifyTotal + '</section></section>';
                        }
                        if (_this.allowedProducts[iframe] || iframeTotal !== 0.00) {
                            toolTipText += '<section class="dashboard-tooltip-row"><section class="product_label">' +
                                '<section class="rectangle iframe-legend">'+
                                '</section>iFrame</section><section>' +
                                dollarSign + iframeTotal + '</section></section>';
                        }
                        if (_this.allowedProducts[paysubscription] || paysubscriptionTotal !== 0.00) {
                            toolTipText += '<section class="dashboard-tooltip-row"><section class="product_label">' +
                                '<section class="rectangle paysubscription-legend">'+
                                '</section>Pay Subscription</section><section>' +
                                dollarSign + paysubscriptionTotal + '</section></section>';
                        }
                        toolTipText += '</section>';

                        tooltip.html(toolTipText);
                    });
            } else {
                $('#' + widget + 'Chart .chart-svg').empty();
            }
        });
    }

    // Summary Function
    function refreshSummary() {
        if (angular.isUndefined(dashboardTransactions)) {
            _this.getTransactionsInProcess = false;
            return false;
        }
        _this.grossTotal = dashboardTransactions.salesSummary.grossTotal;
        _this.grossRefund = dashboardTransactions.salesSummary.grossRefund;
        _this.totalSale = dashboardTransactions.salesSummary.totalSale;
        _this.numOfSales = dashboardTransactions.salesSummary.numOfSales;
        _this.avgTotal = dashboardTransactions.salesSummary.avgTotal;
        _this.totoalNewCustomers = dashboardTransactions.salesSummary.total_new_customers;
        _this.repeatCustomers = dashboardTransactions.salesSummary.repeat_customers_count;
        _this.getTransactionsInProcess = false;
        return true;
    }

    function destroyMapData() {
        mapData.points = [];
        mapData.points_by_location = [];
        mapData.markers = null;
    }

    // Parse transactions into map data point structures
    function parseMapData() {
        var points = [];
        var pointsGrouped = [];
        var locationData = [];
        var markers = new window.L.MarkerClusterGroup();
        var amount;
        var geoJson;
        var maxAmount = 0;
        var transactions = dashboardTransactions.mapTransactions;
        var numOfTrans = transactions.length;

        for (var i = 0; i < numOfTrans; i++) {
            var dataValue = transactions[i];
            var id = dataValue.id;
            var longVal = dataValue.longitude;
            var latVal = dataValue.latitude;
            amount = parseFloat(dataValue.amount);
            var amountFmt = formatNumber(amount);
            var source = dataValue.source;

            if (amount > maxAmount) {
                maxAmount = amount;
            }

            if (longVal && latVal) {
                longVal = parseFloat(longVal);
                latVal = parseFloat(latVal);

                geoJson = {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [longVal, latVal]
                    },
                    'properties': {
                        'amount': amount,
                        'popupContent': '$' + amountFmt
                    }
                };
                points.push(geoJson);

                var title = source + ' $' + amountFmt;
                var marker = window.L.marker(new window.L.LatLng(latVal, longVal), {
                    icon: window.L.mapbox.marker.icon({
                        'marker-color': 'FF9900'
                    }),
                    id: id,
                    title: title
                });
                marker.bindPopup(title);
                markers.addLayer(marker);

                var key = longVal + '#' + latVal;
                if (locationData[key]) {
                    var tmpAmount = locationData[key].amount;
                    amount += tmpAmount;
                } else {
                    locationData[key] = {};
                }
                locationData[key].amount = amount;
                locationData[key].longitude = longVal;
                locationData[key].latitude = latVal;
            }
        }

        if (locationData) {
            for (var j in locationData) {
                if (locationData.hasOwnProperty(j)) {
                    var data = locationData[j];
                    amount = (data.amount).toFixed(2);
                    geoJson = {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [data.longitude, data.latitude]
                        },
                        'properties': {
                            'amount': amount,
                            'popupContent': '$' + formatNumber(amount)
                        }
                    };
                    pointsGrouped.push(geoJson);
                }
            }
        }

        mapData.points = points;
        mapData.points_by_location = pointsGrouped;
        mapData.markers = markers;
        mapData.data_range = _this.getMapDataRanges(maxAmount);
    }

    // Scattermap: Render Map
    function renderMap() {
        var points = mapData.points;
        if (points && points.length > 0) {
            var featureLayer = window.L.mapbox.featureLayer(points);
            map.fitBounds(featureLayer.getBounds());

            var mostRecentPoint = points[0];
            var point = mostRecentPoint.geometry.coordinates;
            var latlng = [point[1], point[0]];
            map.setView(latlng, 16);
        }
    }

    // Scattermap: Bind popup bubbles
    function onEachFeature(feature, layer) {
        if (feature.properties && feature.properties.popupContent) {
            layer.bindPopup(feature.properties.popupContent);
        }
    }

    // Scattermap: Setup legend
    function getLegendHTML() {
        var ranges = mapData.data_range,
            labels = [],
            from,
            to,
            lookup;

        for (var i = 0; i < ranges.length; i++) {
            to = ranges[i + 1];
            from = ranges[i];
            if (to !== 0) {
                var diff = +(to - from).toFixed(2);
                if (diff && diff !== 0.01) {
                    to -= 0.01;
                    lookup = from + 0.01;
                } else {
                    lookup = from;
                    to = '';
                    if (i === ranges.length - 1) {
                        to = '+';
                    }
                }

                labels.push(
                    '<li class="map_legend_li"><span class="swatch" style="background:' +
                    getMarkerColor(lookup) + '"></span> $' +
                    from.toFixed(2) + ((to && to !== '+') ? '&ndash;' + to.toFixed(2) : to) + '</li>');
            }
        }
        return '<ul class="map_legend_ul">' + labels.join('') + '</ul>';
    }

    // Scattermap: Render map legend
    function renderMapLegend() {
        map.legendControl.addLegend(getLegendHTML());
    }

    // Render scatter map for Sales Volume
    function renderScatterMap() {
        _this.showSalesVolumeMap = true;
        if (_this.markerLayer) {
            map.removeLayer(_this.markerLayer);
        }
        if (_this.scatterLayer && (!map.hasLayer(_this.scatterLayer))) {
            map.addLayer(_this.scatterLayer);
            map.addControl(map.legendControl);
        } else {
            var points = mapData.points_by_location;
            if (points && points.length > 0) {
                _this.scatterLayer = window.L.geoJson(points, {
                    pointToLayer: function (feature, latlng) {
                        return window.L.circleMarker(latlng, getMarkerOptions(feature.properties.amount));
                    },
                    onEachFeature: onEachFeature
                });
                _this.scatterLayer.addTo(map);
                renderMapLegend();
            }
        }
    }

    // Render cluster map for Transaction Count
    function renderClusterMap() {
        _this.showSalesVolumeMap = false;
        if (_this.scatterLayer) {
            map.removeLayer(_this.scatterLayer);
            map.removeControl(map.legendControl);
        }
        if (_this.markerLayer && (!map.hasLayer(_this.markerLayer))) {
            map.addLayer(_this.markerLayer);
        } else {
            _this.markerLayer = mapData.markers;
            if (_this.markerLayer) {
                map.addLayer(_this.markerLayer);
            }
        }
    }

    _this.refreshDashboard = function () {
        if (!refreshSummary()) {
            PaperToastService.create('unexpected error', 'error');
            _this.loading = false;
            return;
        }

        _this.selectedCurrency = _this.filtercurrency;

        renderChart('grossSales');
        renderChart('refunds');
        renderChart('totalSales');
        renderChart('salesByHour');
        renderChart('totalTransaction');
        renderChart('transactionByHour');
        _this.topStaff = dashboardTransactions.leaderboard.topSalesperson;
        _this.topCustomers = dashboardTransactions.leaderboard.topCustomers;

        // Currently the map will only show if user has permission to access tablet or mobile
        if (_this.allowedProducts[mobile] || _this.allowedProducts[tablet]) {
            if (map) {
                _this.markerLayer = null;
                _this.scatterLayer = null;
                map.remove();
            }
            window.L.mapbox.accessToken = 'pk.eyJ1IjoicGF5ZmlybWEiLCJhIjoiY2w3cWphMWJyMDV5aDNxbnVnbDN1azAwNCJ9.jWfcA-Azr1zMZ2TDtGjJ7g';
            map = window.L.mapbox.map('saleLocationMap')
                    .setView([43.71, -79.63], 9)
                    .addLayer(window.L.mapbox.styleLayer('mapbox://styles/payfirma/cl7qkecn0002814oe9vy8y6x0'));

            var transactions = dashboardTransactions.mapTransactions;
            if (transactions.length > 0) {
                parseMapData();
                renderMap();
                if (_this.showSalesVolumeMap) {
                    renderScatterMap();
                } else {
                    renderClusterMap();
                }
            } else {
                destroyMapData();
            }
        } else {
            $('#map').hide();
        }
        _this.loading = false;
    };

    // Get map data ranges used for d3 rendering
    // @param {Number} maxValue - Max Y-axis value
    _this.getMapDataRanges = function (maxValue) {
        var size = 5;
        var range = [];
        var previousVal = maxValue;
        var currentVal;
        var step = 0.2;
        for (var i = 4; i >= 0; i--) {
            currentVal = +(maxValue * (step * i)).toFixed(2);
            if (currentVal !== previousVal) {
                range[--size] = currentVal;
            }
            previousVal = currentVal;
        }
        for (var j = size; j >= 0; j--) {
            range[j] = 0;
        }

        return range;
    };

    _this.goToEditStaff = function () {
        UrlService.go('staff/create');
    };

    _this.goToCustomerVault = function () {
        UrlService.go('customer-vault/create');
    };

    _this.renderClusterMap = function () {
        renderClusterMap();
    };

    _this.renderScatterMap = function () {
        renderScatterMap();
    };

    _this.$onInit = function () {
        _this.loading = true;
        $timeout(function () {
            getTransactions();
        }, 500);
    };
}

angular.module('payhqUIApp').component('dashboard', {
    bindings: {},
    controller: dashboard,
    controllerAs: 'dashboard',
    templateUrl: 'view/dashboard/dashboard.html'
});