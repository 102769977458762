import angular from 'angular';

angular
.module('payhqUIApp')
.service('IdleCheckService', function (Idle, $rootScope, SessionService, Utils, $translate, AuthService) {
    var idleWatchStarted = false;
    var shouldDisplayErrorMessage = false;
    return {
        initialize: function () {
            // When route change is requested
            $rootScope.$on('$routeChangeStart', function () {
                if (!idleWatchStarted && SessionService.isLoggedIn()) {
                    //watch after user logged in
                    idleWatchStarted = true;
                    Idle.watch();
                } else if (!SessionService.isLoggedIn()) {
                    //unwatch after user logged out
                    idleWatchStarted = false;
                    Idle.unwatch();
                }
            });
            // When route change responds succesfully
            $rootScope.$on('$routeChangeSuccess', function (event, current) {
                if (current && current.$$route.originalPath === '/login' && shouldDisplayErrorMessage) {
                    $translate(['sessionTimedOut', 'inactivityMessage']).then(function (translations) {
                        var messages = {
                            title: translations.sessionTimedOut,
                            subtitle: translations.inactivityMessage
                        };
                        window.PAYFIRMA.CommunicationService.triggerSystemErrorEnabledEvent(messages);
                    });
                    shouldDisplayErrorMessage = false;
                }
            });
            // When user starts idling after a certain time period
            $rootScope.$on('IdleStart', function () {
                $rootScope.idling = true;
                if (SessionService.isLoggedIn()){
                    window.PAYFIRMA.CommunicationService.triggerSystemIdleEvent(true);
                }

            });
            // When user stops idling
            $rootScope.$on('IdleEnd', function() {
                $rootScope.idling = false;
                if (SessionService.isLoggedIn()){
                    window.PAYFIRMA.CommunicationService.triggerSystemIdleEvent(false);
                }
            });
            // When user times out
            $rootScope.$on('IdleTimeout', function () {
                shouldDisplayErrorMessage = true;
                AuthService.logout();
                Utils.forceScopeApply($rootScope);
                if (SessionService.isLoggedIn()){
                    window.PAYFIRMA.CommunicationService.triggerSystemIdleEvent(false);
                }
                window.location.reload(true);
            });
        }
    };
});
