import angular from 'angular';

/**
 * @ngdoc service
 * @name payhqUIApp.service:BrowserService
 * @description
 *
 * Service for handling local data storage
 * for now it only handles compress and store large objects that exceeds cookie size limit
 *
 */
//TODO maybe we should check object size after compression to make sure it is still below cookie size limit
angular
.module('payhqUIApp')
.service('BrowserService', function () {
    // Check if browser is chrome
    this.isBrowserChrome = function () {
        var isChrome = /Chrome/.test(window.navigator.userAgent) && /Google Inc/.test(window.navigator.vendor);
        return isChrome;
    };
});