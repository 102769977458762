import angular from 'angular';
import ConfigurationService from 'service/configuration-service';
import UtilityService from 'service/utility-service';

/**
 * @ngdoc service
 * @name payhqUIApp.transactionApp.service:EFTService
 * @requires $TransactionFactory
 * @description
 *
 * Service for accessing EFT API
 *
 */
angular
.module('transactionApp')
.service('EFTService', function EFTService(HttpClientService, TransactionFactory) {

    var _this = this;

     /**
     * Send a EFT transaction.
     *
     * @param {Object} eft - EFT  object to send
     * @returns {Object} transaction - Returns a transaction response returned from endpoint
     */
    _this.sendEFT = function (eft, customerLookupId) {
        return HttpClientService
            .doPost(ConfigurationService.getConfig().transactionServiceApi+'eft/send/'+customerLookupId,
            eft)
            .then(function (response) {
                let transactionResponse = {};
                if (response && response.data) {
                    transactionResponse = response.data;
                } else if (response) {
                    transactionResponse = response;
                }
                return TransactionFactory.parseResponse(transactionResponse);
            })
            .catch(function(e){
                throw UtilityService.parseErrorMessage(e);
            });
    };

    /**
     * Send a EFT request transaction.
     *
     * @param {Object} eftRequest - EFT request object to send
     * @returns {Object} transaction - Returns a transaction response returned from endpoint
     */
    _this.sendEFTRequest = function (eftRequest, customerLookupId) {
        return HttpClientService
            .doPost(ConfigurationService.getConfig().transactionServiceApi+'eft/request/'+customerLookupId,
            eftRequest)
            .then(function (response) {
                let transactionResponse = {};
                if (response && response.data) {
                    transactionResponse = response.data;
                } else if (response) {
                    transactionResponse = response;
                }
                return TransactionFactory.parseResponse(transactionResponse);
            })
            .catch(function(e){
                throw UtilityService.parseErrorMessage(e);
            });
    };

    /**
     * Cancel a EFT request transaction.
     *
     * @param {number} transactionId - EFT request transaction id
     * @returns {Object} transaction - Returns a transaction response returned from endpoint
     */
    _this.cancelEFTRequest = function (transactionId) {
        return HttpClientService
            .doPost(ConfigurationService.getConfig().transactionServiceApi+'eft/cancel-outgoing-requests/'+transactionId,
            )
            .then(function (response) {
                let transactionResponse = {};
                if (response && response.data) {
                    transactionResponse = response.data;
                } else if (response) {
                    transactionResponse = response;
                }
                return TransactionFactory.parseResponse(transactionResponse);
            })
            .catch(function(e){
                throw UtilityService.parseErrorMessage(e);
            });
    };

    /**
     * Get wallet Balance.
     *
     * @returns {Object} - Return wallet balance
     */
    _this.getWalletBallance = function () {
        return HttpClientService.doGet(ConfigurationService.getConfig()
                .transactionServiceApi+'eft/balance')
                .then(function (response) {
                    return response;
                })
                .catch(function(e){
                    throw UtilityService.parseErrorMessage(e);
                });
    };

    /**
     * Post deposit to bank
     *
     */
    _this.depositToBank = function (amount) {
        return HttpClientService.doPost(ConfigurationService.getConfig()
                .transactionServiceApi+'eft/bank-deposit', {
                    amount
                })
                .then(function (response) {
                    return response;
                })
                .catch(function(e){
                    throw UtilityService.parseErrorMessage(e);
                });
    };

    /**
     * Get Incoming Requests.
     *
     * @returns {Array} - Return incoming request for EFT
     */
    _this.getIncomingRequests = function () {
        return HttpClientService.doGet(ConfigurationService.getConfig()
                    .transactionServiceApi+'eft/incoming-requests')
                    .then(function (response) {
                        return response;
                    })
                    .catch(function(e){
                        throw UtilityService.parseErrorMessage(e);
                    });
    };

    _this.updateStatus = function(transactionId) {
        var endpoint = 'eft/status-update';
        if (transactionId) {
            endpoint = endpoint + '/' + transactionId;
        }
        return HttpClientService.doGet(ConfigurationService.getConfig()
                    .transactionServiceApi + endpoint)
                    .then(function (response) {
                        return response;
                    })
                    .catch(function(e){
                        throw UtilityService.parseErrorMessage(e);
                    });

    };
});