import angular from 'angular';

angular
.module('payhqUIApp')
.directive('imagefileopener',
function ($http, $log, FileReader, ImageStore, PaperToastService, $translate) {
    let translation = {};
    $translate([
        'component_paper_toast.image_file_size_too_large'
    ]).then((translated) => {
        translation = translated;
    });
    return {
        templateUrl: 'view/image-editing/templates/image-file-opener.html',
        link: function (scope, elem) {
            var fileInputElement = elem[0].querySelector('input[type="file"]');
            FileReader.readFile(fileInputElement, FileReader.CONTENT_TYPE.IMAGE,
                function (imageData) {
                    ImageStore.updateImageForCropping(imageData);
                },
                function (errorMessage) {
                    PaperToastService
                        .create(errorMessage, 'error');
                    $log.error(errorMessage);
                },
                function (file) {
                    if (file.size > 2048000) {
                        return translation['component_paper_toast.image_file_size_too_large'];
                    }

                    return undefined;
                }
            );
        }
    };
});
