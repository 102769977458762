import angular from 'angular';
import moment from 'moment';
import SessionStorageService from 'service/session-storage-service';
import ConfigurationService from 'service/configuration-service';

/**
 * @ngdoc service
 * @name payhqUIApp.service:HeapService
 * @description
 *
 * Service for accessing transaction API
 *
 */

function HeapServiceWrapper($log) {
    let log = $log;

    return {
        init: function () {
            /*eslint-disable */
            try {
                window.heap = window.heap || [], heap.load = function (e, t) {
                    window.heap.appid = e, window.heap.config = t = t || {};
                    var r = t.forceSSL || 'https:' === document.location.protocol, a = document.createElement('script');
                    a.type = 'text/javascript', a.async = !0, a.src = (r ? 'https:' : 'http:')
                        + '//cdn.heapanalytics.com/js/heap-' + e + '.js';
                    var n = document.getElementsByTagName('script')[0];
                    n.parentNode.insertBefore(a, n);
                    for (var o = function (e) {
                            return function () {
                                heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                            }
                        }, p = ['addEventProperties', 'addUserProperties', 'clearEventProperties', 'identify',
                            'removeEventProperty',
                            'setEventProperties', 'track', 'unsetEventProperty'],
                             c = 0; c < p.length; c++)heap[p[c]] = o(p[c])
                };
            } catch (e) {
                log.error(e);
            }
            /*eslint-enable */
        },
        load: function (heapIdentification) {
            try {
                window.heap.load(heapIdentification);
            } catch (e) {
                log.error(e);
            }
        },
        track: function (heapTrackingEventIdentifier, heapTrackingEventObject) {
            try {
                window.heap.track(heapTrackingEventIdentifier, heapTrackingEventObject);
            } catch (e) {
                log.error(e);
            }
        },
        identify: function (username) {
            try {
                window.heap.identify(username);
            } catch (e) {
                log.error(e);
            }
        },

        addUserProperties: function (userProperties) {
            try {
                window.heap.addUserProperties(userProperties);
            } catch (e) {
                log.error(e);
            }
        }
    };
}

angular
.module('payhqUIApp')
.service('HeapService', function HeapService($log) {
    let _this = this;
    let heapServiceInstance;

    function getHeapServiceInstance() {
        if (!heapServiceInstance) {
            heapServiceInstance = new HeapServiceWrapper($log);
            heapServiceInstance.init();
        }
        return heapServiceInstance;
    }

    function heapIdentifyWithUserProperties(username, userProperties) {
        getHeapServiceInstance().identify(username);
        getHeapServiceInstance().addUserProperties(userProperties);
    }

    function trackEmailAndPassword(email, password, trackingEventName) {
        var object = {
            email: email
        };
        if (password) {
            object.password_changed = 'yes';
        }
        _this.track(trackingEventName, object);
    }

    function toggleHeapEventText(toggle, text1, text2) {
        var toggledText;
        if (toggle) {
            toggledText = text1;
        } else {
            toggledText = text2;
        }
        return toggledText;
    }

    this.getHeapServiceInstance = function () {
        return getHeapServiceInstance();
    };

    this.heapLoad = () => {
        let hqConfig = ConfigurationService.getConfig();
        getHeapServiceInstance().load(hqConfig.heapId);
    };
    this.heapIdentifyWithUserProperties = () => {
        let currentUser = SessionStorageService.get().getUser();
        let userEmail = currentUser.email;
        let username = userEmail.substring(0, userEmail.lastIndexOf('@'));
        let userProperties = {
            'Permission Group': currentUser.user_group,
            // TODO : SHOULD WE REMOVE THIS FROM HEAP DATA
            // 'Account Status': currentMerchant.status,
            // 'Customer Plan': currentMerchant.type,
            // 'MCC': currentMerchant.mcc_code,
            
            'Pixel Density': window.devicePixelRatio,
            'Screen Dimensions': '' + window.screen.width + ' x ' + window.screen.height,
            'Window Width': window.innerWidth,
            'Window Height': window.innerHeight
        };
        heapIdentifyWithUserProperties(username, userProperties);
    };

    this.track = function (heapTrackingEventIdentifier, heapTrackingEventObject) {
        if (heapTrackingEventIdentifier && heapTrackingEventObject) {
            getHeapServiceInstance().track(heapTrackingEventIdentifier, heapTrackingEventObject);
        }
    };
    this.heapTrack = function (key, value) {
        var object = {
            Message: value
        };
        _this.track(key, object);
    };

    this.trackInput = function (inputName, inputValue) {
        var object = {
            inputValue: inputValue
        };
        _this.track(inputName, object);
    };

    this.trackDatePicker = function (startDate, endDate) {
        // YYYY-MM-DD regex
        const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
        const TRACKING_EVENT_NAME = 'heap-event-datepicker';
        var todayMoment;
        var todaysDateFormatted;
        var startDateFormatted;
        var endDateFormatted;
        var daysFormatted;
        var object;
        if (startDate || endDate) {
            todayMoment = moment();
            todaysDateFormatted = todayMoment.format('YYYY-MM-DD');
            startDateFormatted = moment(startDate).format('YYYY-MM-DD');
            endDateFormatted = moment(endDate).format('YYYY-MM-DD');
            daysFormatted = String(moment(endDateFormatted, 'YYYY-MM-DD')
                .diff(moment(startDateFormatted, 'YYYY-MM-DD'), 'days'));
            object = {
                start_date: startDateFormatted,
                end_date: endDateFormatted,
                todays_date: todaysDateFormatted,
                days: daysFormatted
            };
            // if dates are formatted properly as YYYY-MM-DD;
            if (dateFormatRegex.test(todaysDateFormatted) && dateFormatRegex.test(endDateFormatted)
                && dateFormatRegex.test(startDateFormatted)) {
                _this.track(TRACKING_EVENT_NAME, object);
            }
        }
    };

    this.trackUserEmailAndPassword = function (email, password) {
        const TRACKING_EVENT_NAME = 'heap-event-user-profile-email-and-password';
        trackEmailAndPassword(email, password, TRACKING_EVENT_NAME);
    };

    this.trackMerchantUserEmailAndPassword = function (email, password) {
        const TRACKING_EVENT_NAME = 'heap-event-merchant-user-profile-email-and-password';
        trackEmailAndPassword(email, password, TRACKING_EVENT_NAME);
    };

    this.toggleNavigationSizeText = function (toggle) {
        return toggleHeapEventText(
            toggle,
            window.PAYFIRMA.CONSTANT.HEAP_EVENT.NAVIGATION.EXPAND,
            window.PAYFIRMA.CONSTANT.HEAP_EVENT.NAVIGATION.COMPACT);
    };

    this.toggleTerminalOrderDetailsText = function (toggle) {
        return toggleHeapEventText(
            toggle,
            window.PAYFIRMA.CONSTANT.HEAP_EVENT.TERMINAL.LESS_DETAILS,
            window.PAYFIRMA.CONSTANT.HEAP_EVENT.TERMINAL.MORE_DETAILS);
    };
});