import angular from 'angular';
import CustomerSubscription from 'model/customer-subscription';

function removeCreditCard(CustomerCardService, $log, SubscriptionService) {
    var _this;
    _this = this;

    function closeAndCleanup() {
        _this.componentEnabled = false;
    }
    function doUpdatePlans(allPlans) {
        return Promise.all(
            _.map(allPlans, function (plan) {
                return new Promise(function (resolve, reject) {
                    plan.card_lookup_id = _this.newCreditCardLookupId;
                    SubscriptionService.updateSubscription(plan, _this.customer.lookup_id).then(
                        function (response) {
                            resolve(response);
                        },
                        function (error) {
                            reject(error);
                        }
                    );
                });
            })
        ).then(function (response) {
            window.PAYFIRMA.CommunicationService
                .triggerRefreshRecurringPlanList(response[response.length -1].data.subscriptions);
        });
    }

    function updatePlansWithNewCreditCardLookupId() {
        var customerPlans = _this.customer.subscriptions;
        var subscribedPlans = _.filter(customerPlans, function (customerPlan) {
            var plan = new CustomerSubscription(customerPlan);
            if ( plan.isValid() && plan.card_lookup_id === _this.removedCreditCardLookupId) {
                return plan;
            }
        });
        return doUpdatePlans(subscribedPlans);
    }

    function doRemoveCreditCard() {
        if (_this.customer && _this.customer.subscriptions){
            _.forEach(_this.customer.subscriptions, (subscription) => {
                if (subscription.card_lookup_id === _this.removedCreditCardLookupId){
                    let subscriptionObject = {
                        lookup_id: subscription.lookup_id,
                        card_lookup_id: _this.newCreditCardLookupId
                    };
                    SubscriptionService.updateSubscription(subscriptionObject, _this.customer.lookup_id)
                    .then(() => {
                        CustomerCardService.deleteCustomerCard(
                            _this.removedCreditCardLookupId, _this.customer.lookup_id)
                        .then((response) => {
                            window.PAYFIRMA.CommunicationService.triggerRefreshCreditCardList(response);
                            return updatePlansWithNewCreditCardLookupId();
                        })
                        .then(() => {
                            _this.isLoading = false;
                            closeAndCleanup();
                        })
                        .catch((error) => {
                            $log.error(error);

                        });
                    });
                }
            });
        }
    }

    _this.removeCreditCard = function () {
        doRemoveCreditCard();
    };

    _this.cancel = function () {
        closeAndCleanup();
    };

    function formatCreditCardText(creditCard) {
        var text = creditCard.card_suffix;
        if (creditCard.card_description) {
            text += '-' + creditCard.card_description;
        }
        return text;
    }

    function createRemainingCreditCardListOptions() {
        _this.customer.setCards(_this.customer.cards);
        var creditCardList = _this.customer.getAllValidCreditCards();
        _this.remainingCreditCardListOptions = _.map(
            _.filter(creditCardList, function (creditCard) {
                return creditCard.lookup_id !== _this.removedCreditCardLookupId && !creditCard.is_expired;
            }),
            function (creditCard) {
                return {
                    value: creditCard.lookup_id,
                    key: formatCreditCardText(creditCard)
                };
            });
    }

    window.PAYFIRMA.CommunicationService.onRemoveCreditCardModalOpen(function (removedCreditCardLookupId) {
        _this.removedCreditCardLookupId = removedCreditCardLookupId;
        createRemainingCreditCardListOptions();
    });
}

angular
.module('payhqUIApp')
.component('removeCreditCard', {
    bindings: {
        componentEnabled: '=',
        customer: '<'
    },
    controller: removeCreditCard,
    controllerAs: 'removeCreditCard',
    templateUrl: 'view/vault/modal/remove-credit-card.html'
});