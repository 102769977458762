/* @flow */
'use strict';

import UtilityService from 'service/utility-service';
import ConfigurationService from 'service/configuration-service';

function getStateValue(urlString) {
    return UtilityService.getQueryParameterValue(urlString, 'state');
}

function constructOidcAuthCodeRequestUrl(deepLinkUrl) {
    let config = ConfigurationService.getConfig();
    let clientId = config.cibcOidcClientId;
    let authRedirectUrl = config.cibcAuthRedirectUrl;
    let oidcUrl = config.cibcOidcUrl;

    let url = oidcUrl + '?response_type=code&client_id=' + clientId +
        '&redirect_uri=' + authRedirectUrl + '&scope=openid';
    let state = getStateValue(deepLinkUrl);
    if (state) {
        url += '&state=' + state;
    }

    return url;
}

class CibcSsoService {
    windowObject: Object;

    constructor(windowObject: Object) {
        this.windowObject = windowObject;
    }

    requestOidcAuthCode() {
        this.windowObject.location.href =
            constructOidcAuthCodeRequestUrl(this.windowObject.location.href);
    }
}

export default CibcSsoService;