import angular from 'angular';
import _ from 'lodash';

function eftFee(PaperToastService, EFTFeeService, $log, $scope) {
    var _this;
    _this = this;
    _this.isLoading = false;
    _this.data = {
        feeHead: 'TRANSACTION_TIERS',
        amount: null,
        minAmount: null,
        maxAmount: null
    };
    _this.modal = {
        type : 'NEW',
        editFee : null,
        formType: 'CREATE'
    };
    _this.feeHeads = [
        {
            key: 'Transaction Tiers',
            value: 'TRANSACTION_TIERS',
            categoryId : 1
        },
        {
            key: 'Chargeback / Returns',
            value: 'CHARGEBACK_RETURNS',
            categoryId : 2
        },
        {
            key: 'Item Dispute',
            value: 'ITEM_DISPUTE',
            categoryId : 2
        },
        {
            key: 'Traces to Originator',
            value: 'TRACES_TO_ORIGINATOR',
            categoryId : 2
        },
        {
            key: 'EFT Flat Rate',
            value: 'PAYHQ_FLAT_RATE',
            categoryId : 3
        }];

    _this.fees = [];

    // To get fee head name
    function getFeeHeadName (feeHead) {
        return _.find(_this.feeHeads, function (eftFeeHead) {
            return eftFeeHead.value === feeHead;
        }).key;
    }

    // To get list of fees to check overlapping
    function except () {
        if (_this.modal.type === 'NEW'){
            return _.filter(_this.fees, function(o) { return !o.isDelete; });
        }
        return _.filter(_this.fees, function(o) { return o !== _this.modal.editFee && !o.isDelete; });
    }

    // To reset fee fields
    function resetEFTFee (form) {
        _this.data = {
            feeHead: 'TRANSACTION_TIERS',
            amount: null,
            minAmount: null,
            maxAmount: null
        };
        form.$setPristine(true);
    }

    // To hide modal
    _this.cancel = function () {
        $scope.$emit('closeEFTFee');
    };

    // To edit eft fee
    _this.editEFTFee = function (fee) {
        _this.data = {
            feeHead: fee.feeHead,
            amount: fee.amount,
            minAmount: fee.minAmount,
            maxAmount: fee.maxAmount
        };
        _this.modal.editFee = fee;
        _this.modal.type = 'EDIT';
    };

    // To delete eft fee
    _this.deleteEFTFee = function (fee) {
        if (!fee.id){
            _.remove(_this.fees, fee);
        } else {
            _.assign(fee,{ isDelete : true });
        }
    };

    // To check overlapping range of fees
    function checkOverLapping(fee) {
        if (Number.isFinite(_this.data.minAmount)) {
            if (Number.isFinite(_this.data.maxAmount)) {
                if (Number.isFinite(fee.minAmount) && Number.isFinite(fee.maxAmount)) {
                    if (fee.minAmount <= _this.data.maxAmount && fee.maxAmount>=_this.data.minAmount){
                        return true;
                    }
                } else if (Number.isFinite(fee.maxAmount)) {
                    if (_this.data.minAmount <= fee.maxAmount){
                        return true;
                    }
                } else if (_this.data.maxAmount >= fee.minAmount){
                    return true;
                }
            } else if ((Number.isFinite(fee.minAmount) && fee.minAmount >= _this.data.minAmount) || (Number.isFinite(fee.maxAmount) && _this.data.minAmount <= fee.maxAmount)){
                return true;
            }
        } else if (Number.isFinite(_this.data.maxAmount)
            && ((Number.isFinite(fee.maxAmount) && fee.maxAmount <= _this.data.maxAmount) || (_this.data.maxAmount >= fee.minAmount && (Number.isFinite(fee.maxAmount))))) {
            return true;
        }
        return false;
    }


     // To add eft fee
    _this.addEFTFee = function (form) {
        if (form.$invalid) {
            return PaperToastService.create('Fill in Required Fields.', 'error');
        }
        if (_this.data.feeHead === 'TRANSACTION_TIERS') {
            if (!Number.isFinite(_this.data.minAmount) && !Number.isFinite(_this.data.maxAmount)) {
                return PaperToastService.create('Atleast one is required from Min or Max transaction amount', 'error');
            }
            if ((Number.isFinite(_this.data.minAmount) && Number.isFinite(_this.data.maxAmount)) && (this.data.minAmount > _this.data.maxAmount)) {
                return PaperToastService.create('Max transaction amount must be greater than Min transaction amount', 'error');
            }
        }
        if (_this.data.feeHead !== 'TRANSACTION_TIERS' && _.some(_this.fees, fee => fee.feeHead === _this.data.feeHead && !fee.isDelete)) {
            return PaperToastService.create('Cannot add more than one EFT fee for this fee head', 'error');
        }
        if (_this.data.feeHead === 'TRANSACTION_TIERS' && _.find(except(), checkOverLapping)) {
            return PaperToastService.create('Already added EFT fee for this range', 'error');
        }
        if (_this.modal.type === 'NEW'){
            _this.fees.push({
                feeHead: _this.data.feeHead,
                feeHeadName: getFeeHeadName(_this.data.feeHead),
                amount: _this.data.amount,
                minAmount: _this.data.minAmount,
                maxAmount: _this.data.maxAmount,
                isDelete: false
            });
        } else {
            let updateFee = _.find(_this.fees, _this.modal.editFee);
            updateFee.feeHeadName = getFeeHeadName(_this.data.feeHead);
            updateFee.feeHead = _this.data.feeHead;
            updateFee.amount = _this.data.amount;
            updateFee.minAmount = _this.data.minAmount;
            updateFee.maxAmount = _this.data.maxAmount;
            _this.modal.type = 'NEW';
        }
        _this.fees = _.orderBy(_this.fees, ['amount', 'minAmount', 'maxAmount'], ['asc', 'asc', 'asc']);
        resetEFTFee(form);
    };

    // To save eft fees
    _this.saveEFTFees = function () {
        let promise, requestBody;
        _this.isLoading = true;
        if (_this.modal.formType === 'CREATE'){
            requestBody = {
                eft_merchant_fees : _.map(_this.fees, function(val){
                    return {
                        eft_fee_head : val.feeHead,
                        min_amount : val.minAmount,
                        max_amount : val.maxAmount,
                        fee_amount : val.amount
                    };
                })
            };
            promise = EFTFeeService.createEFTFees(_this.merchantId, _this.processorId, requestBody);
        } else {
            requestBody = {
                eft_merchant_fees : _.map(_this.fees, function(val){
                    return {
                        id : val.id,
                        eft_fee_head : val.feeHead,
                        min_amount : val.minAmount,
                        max_amount : val.maxAmount,
                        fee_amount : val.amount,
                        deleted : val.isDelete
                    };
                })
            };
            promise = EFTFeeService.updateEFTFees(_this.merchantId, _this.processorId, requestBody);
        }
        promise.then(
            function (response) {
                if (response){
                    _this.isLoading = false;
                    $scope.$emit('closeEFTFee');
                    PaperToastService.create('EFT fees saved successfully', 'update');
                }
            },
            function (error) {
                _this.isLoading = false;
                PaperToastService.create('EFT fees not saved successfully', 'error');
                $log.error(error);
            }
        );
    };

    // To get eft fees
    function getEFTFees() {
        const queryParams = {
            processor : _this.processorId
        };
        _this.isLoading = true;
        EFTFeeService.getEFTFees(_this.merchantId, queryParams).then(
                function (response) {
                    if (response) {
                        _this.isLoading = false;
                        _this.modal.formType = response.length ? 'UPDATE' : 'CREATE';
                        _this.fees = _.map(response, function(val){
                            return {
                                id : val.id,
                                feeHead: val.eft_fee_head,
                                feeHeadName: getFeeHeadName(val.eft_fee_head),
                                amount: val.fee_amount,
                                minAmount: val.min_amount,
                                maxAmount: val.max_amount,
                                isDelete: false
                            };
                        });
                    }
                },
                function (error) {
                    _this.isLoading = false;
                    PaperToastService.create('EFT fees not loaded successfully', 'error');
                    $log.error(error);
                }
            );
    }

    // to track changes using this lifecycle hook
    _this.$onChanges = function(changesObj){
        if (changesObj.merchantId && changesObj.processorId){
            getEFTFees();
        }
    };
}


angular
.module('payhqUIApp')
.component('eftFee', {
    bindings: {
        merchantId: '<',
        processorId: '<'
    },
    controller: eftFee,
    controllerAs: 'eftFee',
    templateUrl: 'view/merchant/eft-fee/eft-fee.html'
});
