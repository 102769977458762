import angular from 'angular';

function paperTime($scope, $interval, TimeService) {

    var _this = this;

    function updateTime() {
        if (_this.timezone) {
            _this.timeFormat = TimeService.getLocalTime(_this.timezone);
        }
    }

    updateTime();

    var scheduler = $interval(updateTime, 500);

    $scope.$on('$destroy', function () {
        if (scheduler) {
            $interval.cancel(scheduler);
            scheduler = undefined;
        }
    });
}
angular.module('payhqUIApp').component('paperTime', {
    bindings: {
        timezone: '<'
    },
    controller: paperTime,
    controllerAs: 'paperTime',
    templateUrl: 'component/paper-time/paper-time.html'
});