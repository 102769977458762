/* @flow */
'use strict';

import angular from 'angular';
import CibcSsoService from 'service/cibc-sso-service';

function sso($window) {

    let _this = this;

    _this.initialize = function (){
        new CibcSsoService($window).requestOidcAuthCode();
    };

    _this.initialize();
}

angular.module('payhqUIApp').component('cibcsso', {
    controller: sso,
    templateUrl: 'view/login/sso/cibc-sso.html'
});
