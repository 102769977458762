import angular from 'angular';
import Clipboard from 'clipboard';

function ecommerceSettings(PaperToastService, MerchantApplicationService, $timeout, $scope, Utils, $log, $translate) {
    let _this = this;
    _this.isPaginationLoading = false;
    _this.isLoadingData = true;

    let translation = {};

    $translate([
        'view_ecommerce_settings.toast_application_refresh_success',
        'view_ecommerce_settings.toast_application_refresh_fail',
        'view_ecommerce_settings.toast_application_delete_success',
        'view_ecommerce_settings.toast_application_delete_fail',
        'view_ecommerce_settings.toast_copy_success'
    ]).then((translated) => {
        translation = translated;
    });

    function initializeClipBoard() {
        //use a function to bypass lint check
        let clipboard = new Clipboard('.clipboard');
        clipboard.on('success', () => {
            PaperToastService.create(translation['view_ecommerce_settings.toast_copy_success'], 'update');
        });
    }

    initializeClipBoard();

    _this.paginationService = new window.PAYFIRMA.PaginationService(_this.apiKeys);

    function reloadApiKeys() {
        window.PAYFIRMA.MerchantAppDataService.getAllData({
            forceFetch: true
        })
        //always reload from server when enters customer vault
            .then(function (data) {
                $timeout(function () {
                    _this.paginationService.updateAllData(data);
                    _this.apiKeysForDisplay = _this.paginationService.getAllData();
                    _this.isLoadingData = false;
                });
            });
    }

    reloadApiKeys();

    window.PAYFIRMA.CommunicationService.onApiKeyCreatedEvent(function () {
        reloadApiKeys();
    });

    window.PAYFIRMA.CommunicationService.onApiKeyModifiedEvent(function () {
        reloadApiKeys();
    });

    _this.refreshKey = (apiKey) => {
        if (apiKey) {
            window.PAYFIRMA.CommunicationService.triggerApiKeyRefreshEvent(apiKey);
        }
    };

    window.PAYFIRMA.CommunicationService.onRefreshApiKeyConfirmationEvent((apiKey) => {
        return MerchantApplicationService
        .refreshMerchantApplication(apiKey.lookup_id)
        .then(function () {
            //TODO: rxjs does not trigger digest cycle, so I cannot update individual keys,
            //have to refresh the all list to see the new client id/secret in the view...we should find a way to
            //update individual api key, not the entire list.
            reloadApiKeys();
            PaperToastService
            .create(translation['view_ecommerce_settings.toast_application_refresh_success'], 'update');
        })
        .catch(function (error) {
            PaperToastService
            .create(translation['view_ecommerce_settings.toast_application_refresh_fail'], 'error');
            $log.error(error);
            throw error;
        });
    });

    _this.editKey = (apiKey) => {
        window.PAYFIRMA.CommunicationService.triggerEditApiKeyEnabledEvent(apiKey);
    };
    _this.deleteKey = (apiKey) => {
        if (apiKey) {
            return MerchantApplicationService
            .deleteMerchantApplication(apiKey.lookup_id)
            .then(function () {
                _this.apiKeysForDisplay = _.without(_this.apiKeysForDisplay, apiKey);
                PaperToastService
                .create(translation['view_ecommerce_settings.toast_application_delete_success'], 'update');
            })
            .catch(function (error) {
                PaperToastService
                .create(translation['view_ecommerce_settings.toast_application_delete_fail'], 'error');
                $log.error(error);
                throw error;
            });
        }
    };

    _this.addNewKey = () => {
        window.PAYFIRMA.CommunicationService.triggerAddApiKeyEnabledEvent();
    };

    _this.sortBy = function (columnNameSelected, $event) {
        _this.sortKey = Utils.updateSortKey(_this.sortKey, columnNameSelected);
        if ($event) {
            _this.modifySortColumnClass($event);
        }
    };

    _this.modifySortColumnClass = function ($event) {

        let targetElement = $($event.target);
        let sortableColumn = $('[sort]');
        //set tabel sort function and display arrow class
        if (targetElement.hasClass('ascending')) {
            _this.orderListBy = '-' + window.parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort').addClass('decending');
        } else if (targetElement.hasClass('decending')) {
            _this.orderListBy = window.parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort').addClass('ascending');
        } else if (targetElement.hasClass('no-sort')) {
            _this.orderListBy = window.parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort');
            targetElement.addClass('ascending');
        }
    };

    _this.sortBy('name');

    function hasMoreData() {
        return _this.paginationService.hasMoreData()
            && _this.paginationService.nextCursor() < _this.apiKeysForDisplay.length;
    }

    _this.getPaginationNext = function () {
        _this.paginationService.getNext();
        _this.isPaginationLoading = false;
    };

    /**
     * Infinite scrolling, firing once user hits bottom of the page.
     */
    _this.lazyLoad = function () {
        //check if next page value and grab transactions
        if (hasMoreData()) {
            _this.isPaginationLoading = true;
            //prevent duplicate api calls when scrolling with 1s timeout
            _this.scrollTimeout = setTimeout(function () {
                _this.getPaginationNext();
            }, 0);
        }
    };

    //$scope has to be used here because _this.$onDestroy does not work, sounds like
    //a bug in angularjs
    $scope.$on('$destroy', function () {
        if (_this.paginationService) {
            _this.paginationService.unload();
        }
        window.PAYFIRMA.CommunicationService.disableAddApiKeyEnabledEventTrigger();
        window.PAYFIRMA.CommunicationService.disableEditApiKeyEnabledEventTrigger();
        window.PAYFIRMA.CommunicationService.disableApiKeyCreatedEventTrigger();
        window.PAYFIRMA.CommunicationService.disableApiKeyModifiedEventTrigger();
        window.PAYFIRMA.CommunicationService.disableRefreshApiKeyConfirmationEventTrigger();
    });
}
angular
.module('payhqUIApp')
.component('ecommerceSettings', {
    bindings: {
        apiKeys: '<'
    },
    templateUrl: 'view/settings/ecommerce-settings/ecommerce-settings.html',
    controller: ecommerceSettings,
    controllerAs: 'ecommerceSettings'
});