'use strict';

function resetTodDefault(imageModel) {
    imageModel.image = imageModel.defaultImageUrl;
    imageModel.imageForCropping = imageModel.defaultImageUrl;
    imageModel.imageForCroppingFileName = undefined;
    imageModel.default = true;
}
function init(imageModel) {
    imageModel.defaultImageUrl = '';
    resetTodDefault(imageModel);
    imageModel.image = '';
    imageModel.retrieveImageUrl = '';
    imageModel.uploadImageUrl = '';
    imageModel.deleteImageUrl = '';

}
window.PAYFIRMA = window.PAYFIRMA || {};

window.PAYFIRMA.Image = function () {
    init(this);
};

window.PAYFIRMA.Image.prototype = {
    resetToDefault: function () {
        resetTodDefault(this);

    },
    syncImageForCropping: function () {
        this.imageForCropping = this.image;
    },

    getImage: function () {
        return this.image;
    },

    getImageForCropping: function () {
        return this.imageForCropping;
    },

    setImage: function (i) {
        this.image = i;
        this.default = false;
    },

    setImageForCropping: function (i) {
        this.imageForCropping = i;
    },

    setImageForCroppingFileName: function (f) {
        this.imageForCroppingFileName = f;
    },

    getImageForCroppingFileName: function () {
        return this.imageForCroppingFileName;
    },

    getRetrieveImageUrl: function () {
        return this.retrieveImageUrl;
    },

    getUploadImageUrl: function () {
        return this.uploadImageUrl;
    },

    getDeleteImageUrl: function () {
        return this.deleteImageUrl;
    },

    isDefault: function () {
        return this.default;
    }
};
