import angular from 'angular';
/**
 * @ngdoc service
 * @name payhqUIApp.service:LocalStorageService
 * @requires Html2ImageService
 * @requires PdfGeneratorService
 * @description
 *
 * Service for converting html to pdf
 * 1) html->image via html2canvas
 * 2) image->pdf via jsPdf
 *
 */
angular
.module('payhqUIApp')
.service('Html2PdfService', function (Html2ImageService, PdfGeneratorService) {
    function toImage(canvas) {
        var IMAGE_FORMAT = 'PNG',
            imgData = canvas
                .toDataURL('image/' + IMAGE_FORMAT.toLowerCase());

        return {
            format: IMAGE_FORMAT,
            data: imgData,
            width: canvas.width,
            height: canvas.height
        };
    }

    return {
        convert: function (domElement, fileNameIfSave) {
            return Html2ImageService.toImage(domElement)
                .then(function (canvas) {
                    return PdfGeneratorService.fromImage(toImage(canvas), fileNameIfSave);
                });
        }

    };
});