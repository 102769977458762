/* @flow */

import angular from 'angular';
import ConfigurationService from 'service/configuration-service';

/**
 * @ngdoc service
 * @name payhqUIApp.customerApp.service:AgeVerificationService
 * @requires Restangular
 * @description
 *
 * Service for age-verification related endpoints
 *
 */
angular
    .module('settingsApp')
    .service('AgeVerificationService', function (HttpClientService) {
        var _this = this;
        const privateAgeVerificationUrl = ConfigurationService.getConfig().merchantServiceApi + 'age-verification';

        function updateAgeVerificationAccessToken(ageVerificationLookupId, token) {
            const updateRequest = {
                access_token:token
            };
            return HttpClientService
                    .doPut(
                        privateAgeVerificationUrl + '/' + ageVerificationLookupId + '/token' ,
                        updateRequest
                    )
                    .then(function (response) {
                        return response;
                    });
        }

        /**
         * Get all  inital transactions by search.
         *
         * @return {Array} List of transactions for last 30 days
         */
        function getAgeVerifications(searchOptions) {
            let param = _.extend({}, searchOptions.searchParameters);
            const limit = searchOptions ? searchOptions.limit : undefined;
            const cursor = searchOptions ? searchOptions.cursor : undefined;
            const backwards = searchOptions ? searchOptions.backwards : false;

            if (limit) {
                param.limit = limit;
            }
            if (cursor) {
                if (backwards) {
                    param.before = cursor;
                } else {
                    param.after = cursor;
                }
            }

            return new Promise(function (resolve, reject) {
                HttpClientService
                .doGet(ConfigurationService.getConfig().customerServiceApi + 'customer/age-verification', param)
                    .then(
                        function (response) {
                            var returnedCursor;
                            if (response.data.paging) {
                                returnedCursor = response.data.paging.cursor;
                            }
                            resolve(new window.PAYFIRMA.Paging(
                                returnedCursor, response.data.age_verifications));
                        },
                        function (error) {
                            reject(error);
                        }
                    );
            });
        }

        _this.createInitialSearchParameters = function () {
            var searchParameters = {};
            //get last 30 days of age verifications
            searchParameters.from_date = window.PAYFIRMA.CONSTANT.DEFAULT_CALENDAR_START_DATE.format('YYYY-MM-DD');
            searchParameters.to_date = window.PAYFIRMA.CONSTANT.DEFAULT_CALENDAR_END_DATE.format('YYYY-MM-DD');
            
            return searchParameters;
        };

        _this.getInitialData = function (searchOptions) {
            return getAgeVerifications(
                {
                    /* limit: searchOptions && searchOptions.limit ? searchOptions.limit :
                        window.PAYFIRMA.CONSTANT.PAGINATION_INITIAL_LOAD_BATCH_SIZE, */
                    limit:20,
                    cursor: undefined,
                    searchParameters: searchOptions ? searchOptions.searchParameters : undefined,
                    backwards: false
                });
        };

        _this.getNext = function (searchOptions) {
            return getAgeVerifications(
                {
                    /* limit: searchOptions && searchOptions.limit ? searchOptions.limit :
                        window.PAYFIRMA.CONSTANT.PAGINATION_LOAD_BATCH_SIZE, */
                    limit:20,
                    cursor: searchOptions ? searchOptions.cursor : undefined,
                    searchParameters: searchOptions ? searchOptions.searchParameters : undefined,
                    backwards: false
                });
        };

        return {
            createInitialSearchParameters: _this.createInitialSearchParameters,
            getNext: _this.getNext,
            getInitialData: _this.getInitialData,
            getAgeVerifications: getAgeVerifications,
            getAgeVerification: function () {
                return HttpClientService
                    .doGet(privateAgeVerificationUrl)
                    .then((response) => {
                        return response.data;
                    }).catch((error) => {
                        throw error;
                    });
            },

            saveAgeVerification: function () {
                return HttpClientService
                    .doPost(privateAgeVerificationUrl)
                    .then((response) => {
                        return response.data;
                    }).catch((error) => {
                        throw error;
                    });
            },

            deleteAgeVerification: function (lookupId) {
                return HttpClientService
                    .doDelete(
                        privateAgeVerificationUrl + '/' + lookupId )
                    .then((response) => {
                        return response.data;
                    }).catch((error) => {
                        throw error;
                    });
            },

            getAgeVerificationGenerateToken: function (requestObj) {
                let hqConfig = ConfigurationService.getConfig();
                return HttpClientService.doFormPost(
                hqConfig.hqJwtTokenUrl + 'oauth/token',
                    {
                        grant_type: 'client_credentials',
                        client_id: requestObj.clientId,
                        client_secret: requestObj.clientSecret
                    },{ headers: { 'Authorization': 'Basic ' + window.btoa(requestObj.clientId + ':' + requestObj.clientSecret) } },
                 false)
                .then(function (response) {
                    if (response.data.access_token) {
                        return updateAgeVerificationAccessToken(requestObj.lookupId,response.data.access_token);

                    } else {
                        throw new Error('Cannot get generated token');
                    }
                })
                .catch(function (errors) {
                    throw errors;
                });

            }
        };
    });
