import angular from 'angular';

function paperCheckbox() {
    var _this = this;
    _this.click = function(){
        _this.ngModel = !_this.ngModel;
    };
}
angular
.module('payhqUIApp')
.component('paperCheckbox', {
    bindings: {
        ngModel         : '=',
        ngChange : '&',
        ngDisabled      : '@ngDisabled',
        ngReadonly      : '@ngReadonly',
        label           : '@',
        labelCompiled   : '@',
        heapIdentifier  : '@'
    },
    controller: paperCheckbox,
    controllerAs: 'paperCheckbox',
    templateUrl: 'component/paper-checkbox/paper-checkbox.html'
});