import angular from 'angular';
import SessionStorageService from 'service/session-storage-service';
import _ from 'lodash';

function paperFormCardTerminal($route, $log, $timeout, $window,
                           TransactionService, CustomerService, PaperToastService, SessionService, UrlService,
                           InvoiceService,
                           TransactionReceiptService, HeapService, Utils, TimeService, $element, $location) {

    var _this;

    _this = this;
    _this.view = {};
    _this.form = {};
    _this.requiredFields = {};
    _this.orginalTransAmount = 0;
    _this.orginalTransId = null;
    _this.isRefund = false;
    _this.allowClear = true;
    _this.emailSelectionIndex = 0;
    _this.currentSelectedCustomer = null;
    _this.isNewCustomer = true;
    _this.terminalList = {};
    _this.countryList = [];
    _this.provinceList = [];
    _this.data.customerList = [];
    _this.oldValues = {
        country : '',
        countryMessage : '',
        province : '',
        provinceMessage : ''
    };
    const oldValueMessage = 'Old value : ';

    _this.isEmailRequired = true;
    _this.isValidCardNumber = true;

    _this.HEAP_EVENT_NAME = window.PAYFIRMA.CONSTANT.HEAP_EVENT.TERMINAL;

    _this.HEAP_EVENT_NAME.CHARGE = _this.HEAP_EVENT_NAME.SALE;

    // Checks if order details is shown or hidden
    function checkShowDetailsToggle() {
        _this.HEAP_EVENT_NAME.DETAILS = HeapService.toggleTerminalOrderDetailsText(_this.showOrderDetails);
    }

    // Checks to see which tab the view is currently set on
    _this.checkCurrentTransactionType = function () {
        switch (_this.type) {
            case window.PAYFIRMA.TRANSACTION_TYPE.SALE:
                _this.secondaryChargeEnabled = false;
                _this.transactionTypeText = 'charge';
                _this.HEAP_EVENT_NAME.CHARGE_TEXT = _this.HEAP_EVENT_NAME.SALE;
                break;
            case window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE:
                _this.secondaryChargeEnabled = false;
                _this.transactionTypeText = 'authorize';
                _this.HEAP_EVENT_NAME.CHARGE_TEXT = _this.HEAP_EVENT_NAME.AUTHORIZE;
                break;
            case window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE:
                _this.secondaryChargeEnabled = false;
                _this.transactionTypeText = 'capture';
                _this.HEAP_EVENT_NAME.CHARGE_TEXT = _this.HEAP_EVENT_NAME.CAPTURE;
                break;
            case window.PAYFIRMA.TRANSACTION_TYPE.REFUND:
                _this.secondaryChargeEnabled = true;
                _this.secondaryChargeText = 'void';
                _this.transactionTypeText = 'refund';
                _this.HEAP_EVENT_NAME.CHARGE_TEXT = _this.HEAP_EVENT_NAME.REFUND;
                break;
            case window.PAYFIRMA.TRANSACTION_TYPE.NAKED_REFUND:
                _this.secondaryChargeEnabled = true;
                _this.secondaryChargeText = 'void';
                _this.transactionTypeText = 'refund';
                _this.HEAP_EVENT_NAME.CHARGE_TEXT = _this.HEAP_EVENT_NAME.OPEN_REFUND;
                break;
            default:
                break;
        }
    };

    //build email list that mandril will accept
    function buildEmailList() {
        var emailString = '';
        var count = 0;
        _.forEach(_this.data.emailList, function (value) {
            count++;
            if (count === 1) {
                emailString = '[\"' + value;
            } else {
                emailString = emailString + '\",\"' + value;
            }
        });
        emailString = emailString + '\"]';
        return emailString;
    }

    _this.responseInvalidCardNumberErrorDisplay = function (errors) {
        _.forEach(errors, function (error) {
            if (error.code === 'CardNumber') {
                _this.isValidCardNumber = false;
                var cardnumber = $element.find('.hp-cardnumber');
                cardnumber.removeClass('ng-valid-cardnumber');
                cardnumber.addClass('ng-invalid-cardnumber');
                cardnumber.removeClass('ng-valid');
                cardnumber.addClass('ng-invalid');
            }
        });
    };

    //set transaction response
    function setTransactionSuccessResponse(transactionData, response, isSecondary) {
        //create responose object and map respons

        _this.saleResponse = {};
        _this.saleResponse.id = response.id;
        _this.saleResponse.amount = response.amount;
        _this.saleResponse.amountTip = response.amount_tip;
        _this.saleResponse.amountSurcharge = response.amount_surcharge;
        _this.saleResponse.amountCashback = response.amount_cashback;
        _this.saleResponse.card_type = response.card_type;
        _this.saleResponse.telephone = transactionData.telephone;
        _this.saleResponse.company = transactionData.company;
        _this.saleResponse.address1 = transactionData.address1;
        _this.saleResponse.address2 = transactionData.address2;
        _this.saleResponse.city = transactionData.city;
        _this.saleResponse.invoice_id = transactionData.invoice_id;
        _this.saleResponse.order_id = transactionData.order_id;
        _this.saleResponse.description = transactionData.description;
        //mapping for transaction service transactions
        // using new endpoints for web terminal
        // and old enpoints for customer vault for backwards compatability
        if (response.transaction_id) {
            _this.saleResponse.transaction_success = response.transaction_success;
            _this.saleResponse.cardNumberMaskedValue = response.cardNumberMaskedValue;
            _this.saleResponse.status = Utils.enumToHuman(response.transaction_result);
            _this.saleResponse.time = TimeService.epochToDateAndTime(response.transaction_time);
            _this.saleResponse.statusMessage = response.transactionTypeLabel + ' ' + _this.saleResponse.status;
            _this.saleResponse.bccEmails = '';
            if (response.emailList) {
                _this.saleResponse.email = response.emailList[0];
                _this.saleResponse.bccEmails = response.emailList[0];
            }
            _this.saleResponse.firstname = response.first_name;
            _this.saleResponse.lastname = response.last_name;
            _this.saleResponse.postalcode = response.postal_code;
            _this.saleResponse.bccEmails = response.bcc_emails;
            _this.saleResponse.transaction_id = response.transaction_id;
            _this.saleResponse.original_id = response.original_id;
            _this.saleResponse.date = response.date;
            _this.saleResponse.transaction_message = response.transaction_message ? response.transaction_message.replace(/\n/ig, '') : '';
            _this.saleResponse.currency = response.currency.replace('$', 'D');
        } else {
            _this.saleResponse.statusMessage = response.transactionTypeLabel + ' ' + response.status;
            _this.saleResponse.id = response.id;
            _this.saleResponse.cardNumberMaskedValue = response.cardNumberMaskedValue;
            _this.saleResponse.firstname = response.firstname;
            _this.saleResponse.lastname = response.lastname;
            _this.saleResponse.email = response.email;
            _this.saleResponse.postalcode = response.postalcode;
            _this.saleResponse.status = response.status;
            _this.saleResponse.date = response.date;
            _this.saleResponse.time = response.time;
            _this.saleResponse.currency = response.currency.replace('$', 'D');
        }
        TransactionService.getCountryProvinceName(response, _this.saleResponse);
        if (!isSecondary) {
            _this.view.transaction = true;
        } else {
            _this.view.transactionSecondary = true;
        }
        
        HeapService.heapTrack(response.transactionType, response.statusMessage);
        _this.isLoading = false;
        _this.isLoadingSecondary = false;
        //reset
        if (_this.isValidCardNumber && _this.form) {
            _this.form.$setPristine();
            _this.form.$setUntouched();
        }
        //show response
    }

    //set transaction response
    function setTransactionErrorResponse(response) {
        //create responose object and map respons

        var errorMessage = response.error;

        if (response.errors) {
            if (response.errors.length >= 2) {
                errorMessage = response.errors[0].message + ' and ' + (response.errors.length - 1) + ' more errors';
                _this.responseInvalidCardNumberErrorDisplay(response.errors);
            } else {
                errorMessage = response.errors[0].message;
                _this.responseInvalidCardNumberErrorDisplay(response.errors);
                if (response.errors[0].code === 'CardNumber') {
                    errorMessage = 'Fill in the required fields.';
                }
            }
        }

        PaperToastService.create(errorMessage, 'error');

        _this.isLoading = false;
        _this.isLoadingSecondary = false;
        //reset
        if (_this.isValidCardNumber && _this.form) {
            _this.form.$setPristine();
            _this.form.$setUntouched();
        }
        //show response
    }

    function handleTransactionError(error) {
        let message = 'Transaction error';
        if (error && error.message) {
            message += ' - ' + error.message;
        }
        PaperToastService.create(message, 'error');
        _this.isLoading = false;
        _this.isLoadingSecondary = false;
        $log.error(message, error);
        throw error;
    }

    _this.doTransaction = function(transactionData) {
        let customerLookupId;
        //let creditCardLookupId;
        var transactionObject = TransactionService.createTransaction(null);
        transactionObject.setTransactionInfo(transactionData);

        if (transactionData && transactionData.customer_lookup_id){
            customerLookupId = transactionData.customer_lookup_id;
        }

        // Sale Terminal Transaction
        if (transactionData.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.SALE) {
            TransactionService.doSaleTransactionWithCardTerminal(customerLookupId, transactionObject)
            .then(function (response) {
                setTransactionSuccessResponse(transactionData, response, false);
            })
            .catch(function (error) {
                handleTransactionError(error);
            });
        // Authorize Terminal Transaction
        } else if (transactionData.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE) {
            TransactionService.doAuthorizeTransactionWithCardTerminal(transactionObject)
            .then(function (response) {
                setTransactionSuccessResponse(transactionData, response, false);
            })
            .catch(function (error) {
                handleTransactionError(error);
            });
        } else if (transactionData.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.VOID) {
            TransactionService.doTransaction(transactionObject).then(
                function (response) {
                    if (!response.error) {
                        setTransactionSuccessResponse(transactionData, response, true);
                    } else {
                        setTransactionErrorResponse(response);
                    }
                },
                function (error) {
                    handleTransactionError(error);
                }
            );
        } else {
            TransactionService.doTransaction(transactionObject).then(
                function (response) {
                    if (!response.error) {
                        setTransactionSuccessResponse(transactionData, response, false);
                    } else {
                        setTransactionErrorResponse(response);
                    }
                },
                function (error) {
                    handleTransactionError(error);
                }
            );
        }
    };

    //update currency values
    function updateCurrency(data, currencyValue) {
        data.currencySelected = currencyValue;
        if (data.currencySelected.length === 2) {
            data.currency = data.currencySelected + '$';
        }
    }

    //remove previosly refunded amount from amount to be refunded
    function removeRefundFromAmount() {
        var amount = parseFloat(_this.data.amount);
        var amountRefunded = parseFloat(_this.data.amount_refunded);
        _this.data.amount = amount - amountRefunded;

        // CRU - 3152
        // Added this line to store the refunded amount and if
        // this is a partial refund then the void button will be hidden.
        _this.data.amount_refunded = amountRefunded;
        //if transaction is fully refunded disable amount entry
        if (_this.data.amount <= 0) {
            _this.data.disableAmountEntry = true;
        }
    }

    //is customer vault pre loaded
    _this.isCustomerDataPreloaded = function () {
        var isCustomerDataPreloaded = false;
        if (_this.settings.customer && _this.settings.customer.id) {
            isCustomerDataPreloaded = true;
        }
        return isCustomerDataPreloaded;
    };

    _this.isCustomerQueryLoaded = () => {
        if ($route && $route.current && $route.current.params.customer){
            return true;
        }
        return false;
    };

    //is a transaction refundable
    _this.checkRefund = function (data) {
        var isValid;
        var amountRefunded;
        var originalAmount;
        var amountToRefund;
        //if its not a refund then return
        if (!_this.isRefund) {
            return true;
        }
        //grab values
        isValid = true;
        amountRefunded = 0;
        originalAmount = parseFloat(_this.orginalTransAmount).toFixed(2);
        if (data.amount_refunded) {
            amountRefunded = parseFloat(data.amount_refunded).toFixed(2);
        }
        amountToRefund = amountRefunded + parseFloat(data.amount).toFixed(2);
        //check if your refunding more than allowed
        if (Number(amountToRefund) > 0 && Number(amountToRefund) > Number(originalAmount)) {
            isValid = false;
            PaperToastService.create('Cannot refund more than the original Transaction', 'error');
        }
        return isValid;
    };

    function hasRequiredField(requiredFields, transactionObject) {
        var objectWithRequiredField = _.find(requiredFields, function (requireField) {
            return transactionObject && transactionObject[requireField];
        });
        return objectWithRequiredField ? true : false;
    }


    //check if this is a invoice transaction or credit card charge
    _this.chargeOrPayInvoice = function(transactionData) {
        //if invoice is set, charge invoice otherwize terminal paymen
        if (_this.data.currentInvoice) {
            _this.doPayInvoice(transactionData);
        } else {
            _this.doTransaction(transactionData);
        }
    };

    //merge transaction and customer data
    function parseCustomerData(transactionData, customerData) {
        var mergeResult = _.merge({}, transactionData, customerData); // Use _.merge instead of angular.merge
        return mergeResult;
    }

    //set customer datas
    function setCustomerData(data) {
        var customerData = {
            email: data.email,
            first_name: data.firstname,
            last_name: data.lastname,
            telephone: data.telephone,
            company: data.company,
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            province: data.province,
            country: data.country,
            postal_code: data.postalcode
        };

        //check if customer data should be saved
        _this.isNewCustomer = true;
        if (data.customer && data.customer.lookup_id ||
            _this.data.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE ||
            _this.data.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.REFUND) {
            //Do not save customer data flag
            _this.isNewCustomer = false;
        }

        if (_this.oldValues.country && !data.country){
            customerData.country = _this.oldValues.country;
        }

        if (_this.oldValues.province && !data.province){
            customerData.province = _this.oldValues.province;
        }

        return customerData;
    }

    //set transaction info
    _this.setTransactionData = function (data, isSecondary) {
        var skipCardData;
        var cardNumber;

        // Set the type accordingly if secondary action button was pressed
        if (isSecondary) {
            switch (_this.type) {
                case window.PAYFIRMA.TRANSACTION_TYPE.REFUND:
                    data.transactionType = window.PAYFIRMA.TRANSACTION_TYPE.VOID;
                    break;
                case window.PAYFIRMA.TRANSACTION_TYPE.NAKED_REFUND:
                    data.transactionType = window.PAYFIRMA.TRANSACTION_TYPE.VOID;
                    break;
                default:
                    data.transactionType = _this.type;
                    break;
            }
        } else {
            data.transactionType = _this.type;
        }
        
        data.use_customer_payment_data = _this.data.use_customer_payment_data ? true : false;
        //should you skip card data
        skipCardData = false;

        if (data.use_customer_payment_data ||
            data.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE ||
            data.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.REFUND ||
            data.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.VOID) {
            //dont grab card data
            skipCardData = true;
        }

        //set credit card data
        if (!skipCardData) {
            data.card_expiry_month = data.card_date.slice(0, 2);
            data.card_expiry_year = data.card_date.slice(3, 5);
            data.card_number = _this.data.card_number_formatted.split(' ').join('');
            data.card_suffix = data.card_number.slice(-4);
            // Check card type
            cardNumber = data.card_number;
            data.card_type = Utils.getCreditCardType(cardNumber);
            data.card_expiry = data.card_expiry_month + '/' + data.card_expiry_year;

        }
        // If there are items on the email list
        if (_this.data.emailList && _this.data.emailList.length) {
            // Select the first item in item list as the primary email
            data.emailList = _this.data.emailList;
            data.email = buildEmailList();
        } else {
            data.email = _this.emailSearch;
        }

        //Grab
        data.user_name = data.email;
        data.postal_code = data.postalcode; // someplaces postal_code, others postalcode ???
        updateCurrency(data, data.currencySelected);
        // api takes amount as string rather than number
        data.amount = parseFloat(data.amount);
        data.amount = data.amount.toFixed(2);
        data.currencyAmount = data.currency + data.amount;
        // Set original transation id if terminal view is refund
        if (_this.type === window.PAYFIRMA.TRANSACTION_TYPE.REFUND ||
            _this.type === window.PAYFIRMA.TRANSACTION_TYPE.VOID) {
            data.original_id = data.transaction_id;
        }
        //Set original trans id if it exsists
        if (_this.orginalTransId) {
            data.original_id = _this.orginalTransId;
        }

        // Set is_card_terminal_transaction flag
        data.is_card_terminal_transaction = true;
        data.processor_id = _this.data.processor_id;

        return data;
    };

    function fetchAutoCompleteApi() {
        // TODO: This call should be replaced with GraphQL call
        // after we deploy the GraphQL micro-service
        if (!_.isEmpty(_this.emailSearch)) {
            _this.isSearchingCustomerVault = true;
            CustomerService.getCustomersForAutoComplete(_this.emailSearch).then(res => {
                let customerList = res.data.map((customer) => {
                    return {
                        lookup_id: customer.lookup_id,
                        first_name: customer.first_name,
                        last_name: customer.last_name,
                        email: customer.email
                    };
                });
                _this.allCustomers = customerList;
                _this.isSearchingCustomerVault = false;
            }).catch(err => {
                _this.isSearchingCustomerVault = false;
                console.error(err);
            });
        }
    }

    let debouncedFetchAutoCompleteApi = _.debounce(fetchAutoCompleteApi, 500);

    /**
     * validate email address for send list
     * @param {string} email - email string to validate
     */
    function validateEmail(email) {
        var regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    }

    function populateTransactionData(response) {
        //populate data fields
        if (response.email){
            _this.data.emailList = [response.email];
        }

        _this.data.original_id = _this.data.id;
        updateCurrency(_this.data, response.currency.slice(0,2));
        _this.data.email = response.email;
        _this.data.company = response.company;
        _this.data.address1 = response.address1;
        _this.data.address2 = response.address2;
        _this.data.city = response.city;
        _this.data.postalcode = response.postal_code;
        _this.data.country = response.country;
        _this.data.telephone = response.telephone;
        _this.data.firstname = response.first_name;
        _this.data.lastname = response.last_name;
        _this.data.order_id = response.order_id;
        _this.data.invoice_id = response.invoice_id;
        _this.data.description = response.description;
        _this.data.card_type = response.card_type;
        if (response.country){
            const resultFound = TransactionService.getCountryProvinceCode(response, _this.onChangeCountry, _this.data);
            if (!resultFound.country){
                TransactionService.notFoundOldValues(_this.oldValues, response, oldValueMessage, true, _this.data);
            }
            if (!resultFound.province){
                TransactionService.notFoundOldValues(_this.oldValues, response, oldValueMessage, false, _this.data);
            }
        }
        //disabled entry on retrieved transaction
        _this.data.disableEmailEntry = true;
    }

    function initializeCountrySelections () {
        _this.provinceList.push({
            key: `Select a Province/State ${ _this.requiredFields.province ? '*' : ''}`,
            value: null
        });
        _this.countryList = TransactionService.getCountries(_this.requiredFields.country);
    }

    function initialize(originalTransaction) {
        //set required fields
        var preloadedEmail;
        var originalTransactionType;
        _this.requiredFields = {
            card_cvv: _this.settings.requiredFields.card_cvv,
            email: _this.settings.requiredFields.email,
            firstname: _this.settings.requiredFields.first_name,
            lastname: _this.settings.requiredFields.last_name,
            company: _this.settings.requiredFields.company,
            telephone: _this.settings.requiredFields.telephone,
            description: _this.settings.requiredFields.description,
            order_id: _this.settings.requiredFields.order_id,
            invoice_id: _this.settings.requiredFields.invoice_id,
            address: _this.settings.requiredFields.address,
            city: _this.settings.requiredFields.city,
            province: _this.settings.requiredFields.province,
            country: _this.settings.requiredFields.country,
            postalcode: _this.settings.requiredFields.postal_code
        };
        _.forEach(_this.settings.requiredFields, function (value, key) {
            if (key !== 'email' && value === true && key !== 'card_cvv') {
                _this.showOrderDetails = true;
            }
        });

        if (_this.type === window.PAYFIRMA.TRANSACTION_TYPE.REFUND ||
            _this.type === window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE) {
            _this.requiredFields.email = false;
            if ((originalTransaction || !_this.settings.originalTransaction)) {

                if (originalTransaction) {
                    _this.orginaldata = originalTransaction;
                }

                _this.settings.originalTransaction = _this.orginaldata;

                if (_this.type === window.PAYFIRMA.TRANSACTION_TYPE.REFUND) {
                    _this.orginalTransId = _this.settings.originalTransaction.id;
                    _this.orginalTransAmount = _this.settings.originalTransaction.amount;
                    _this.data.isTabSwitch = true;
                    _this.isRefund = true;
                }
            }

            _this.isEmailRequired = false;
        }

        //if customer data is loaded from vault
        if (_this.isCustomerDataPreloaded()) {
            _this.data.firstname = _this.settings.customer.first_name;
            _this.data.lastname = _this.settings.customer.last_name;
            _this.data.email = _this.settings.customer.email;
            _this.data.bcc_email = _this.settings.customer.bcc_emails;
            _this.data.company = _this.settings.customer.company;
            _this.data.telephone = _this.settings.customer.telephone;
            _this.data.description = _this.settings.customer.description;
            _this.data.order_id = _this.settings.customer.order_id;
            _this.data.invoice_id = _this.settings.customer.invoice_id;
            _this.data.address1 = _this.settings.customer.address1;
            _this.data.address2 = _this.settings.customer.address2;
            _this.data.city = _this.settings.customer.city;
            _this.data.postalcode = _this.settings.customer.postal_code;
            _this.data.customer_lookup_id = _this.settings.customer.lookup_id;
            if (_this.settings.customer.country){
                const resultFound = TransactionService.getCountryProvinceCode(_this.settings.customer, _this.onChangeCountry, _this.data);
                if (!resultFound.country){
                    TransactionService.notFoundOldValues(_this.oldValues, _this.data, oldValueMessage);
                }
                if (!resultFound.province){
                    TransactionService.notFoundOldValues(_this.oldValues, _this.data, oldValueMessage, false);
                }
            }
            _this.data.amount = 0;
            _this.data.card_number_formatted = '**** **** **** ' + _this.settings.creditCard.card_suffix;
            _this.data.card_date = _this.settings.creditCard.card_expiry;
            _this.data.card_expiry = _this.settings.creditCard.card_expiry;
            _this.data.card_cvv = '***';
            _this.isEmailRequired = false;
            _this.showOrderDetails = true;
            preloadedEmail = _this.settings.customer.email;
            if (preloadedEmail) {
                _this.data.emailList.push(preloadedEmail);
            }
        } else if (_this.isCustomerQueryLoaded()) {
            CustomerService.getCustomer($route.current.params.customer).then( function (customerResponse) {
                _this.data.firstname = customerResponse.first_name;
                _this.data.lastname = customerResponse.last_name;
                _this.data.email = customerResponse.email;
                _this.data.bcc_email = customerResponse.bcc_emails;
                _this.data.company = customerResponse.company;
                _this.data.telephone = customerResponse.telephone;
                _this.data.description = customerResponse.description;
                _this.data.order_id = customerResponse.order_id;
                _this.data.invoice_id = customerResponse.invoice_id;
                _this.data.address1 = customerResponse.address1;
                _this.data.address2 = customerResponse.address2;
                _this.data.city = customerResponse.city;
                _this.data.postalcode = customerResponse.postal_code;
                _this.data.customer_lookup_id = customerResponse.lookup_id;
                _this.showOrderDetails = true;
                _this.data.emailList.push(customerResponse.email);
                if (customerResponse.country){
                    const resultFound = TransactionService.getCountryProvinceCode(customerResponse, _this.onChangeCountry, _this.data);
                    if (!resultFound.country){
                        TransactionService.notFoundOldValues(_this.oldValues, _this.data, oldValueMessage);
                    }
                    if (!resultFound.province){
                        TransactionService.notFoundOldValues(_this.oldValues, _this.data, oldValueMessage, false);
                    }
                }
            });
        } else if (_this.type !== window.PAYFIRMA.TRANSACTION_TYPE.REFUND &&
            _this.type !== window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE) {
            _.forEach(_this.settings.requiredFields, function (value) {
                if (value.required === true && _this.data.currentInvoice) {
                    if (value.name === 'card_cvv' || value.name === 'email') {
                        _this.requiredFields[value.name] = true;
                    } else {
                        _this.requiredFields[value.name] = false;
                    }
                }
            });
            _this.data.disableEmailEntry = false;
            _this.data.card_date = '';
            _this.data.card_cvv = '';
            _this.data.emailSearch = '';
            _this.data.firstname = '';
            _this.data.lastname = '';
            _this.data.company = '';
            _this.data.telephone = '';
            _this.data.description = '';
            _this.data.card_number_formatted = '';
            _this.data.order_id = '';
            _this.data.invoice_id = '';
            _this.data.address1 = '';
            _this.data.address2 = '';
            _this.data.city = '';
            _this.data.province = null;
            _this.data.country = null;
            _this.data.postalcode = '';

            if (_this.data.currentInvoice) {
                _this.data.amount = _this.data.currentInvoice.total;
                _this.checkEmailListLength();
            } else {
                _this.data.amount = 0.00;
                _this.data.emailList = [];
            }
        }

        //If route shows existing transaction run logic for refund or capture
        if ((!_this.data.isTabSwitch || originalTransaction) && _this.settings.originalTransaction) {

            //what is transaction type
            originalTransactionType = _this.settings.originalTransaction.transactionType;

            //set data fields
            _this.allowClear = false;
            _this.orginalTransId = _this.settings.originalTransaction.id;
            _this.orginalTransAmount = _this.settings.originalTransaction.amount;
            _this.data = _this.settings.originalTransaction;
            populateTransactionData(_this.settings.originalTransaction);
            //_this.data.currencySelected = getCurrencyValue(_this.data.currency);
            _this.isEmailRequired = false;
            //Detected capture
            if (originalTransactionType === window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE) {
                _this.data.transaction_id = _this.settings.originalTransaction.id;
                _this.type = window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE;
            }

            //Detected refund
            if (originalTransactionType === window.PAYFIRMA.TRANSACTION_TYPE.SALE ||
                originalTransactionType === window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE) {
                _this.data.transaction_id = _this.settings.originalTransaction.id;
                _this.type = window.PAYFIRMA.TRANSACTION_TYPE.REFUND;
                _this.isRefund = true;
                //calcuate amount field
                if (_this.data.amount_refunded) {
                    removeRefundFromAmount();
                }
            }

            //add emails
            _this.data.emailList = [];
            if (_this.settings.originalTransaction.email) {
                _this.data.emailList.push(_this.settings.originalTransaction.email);
                _this.data.disableEmailEntry = true;
                _this.isNewCustomer = false;
            }

        }

        _this.data.currencySelected = _this.terminalCurrencySelected.currency.slice(0, 2);
        _this.data.processor_id = _this.terminalCurrencySelected.processor_id;
        initializeCountrySelections();
        checkShowDetailsToggle();
        _this.checkCurrentTransactionType();
    }

    _this.initializeWithTransaction = function(transaction) {
        initialize(transaction);
    };

    _this.$onInit = function(){
        initialize();
    };

    _this.close = function () {
        _this.view.transaction = false;
        _this.view.transactionSecondary = false;
    };

    //add email to list
    _this.pushEmailList = function () {
        if (_this.settings.originalTransaction.email) {
            _this.data.emailList.push(_this.settings.originalTransaction.email);
            _this.data.disableEmailEntry = true;
        }
    };

    //hide show more order details
    _this.toggleOrderDetails = function () {
        _this.showOrderDetails = !_this.showOrderDetails;
        checkShowDetailsToggle();
    };

    //select customer from list
    _this.selectedCustomer = function (customer) {
        // Fetch All customer info over REST
        _this.isLoadingOrderDetails = true;
        CustomerService.getCustomerRaw(customer.lookup_id).then(res => {
            if (_this.data.emailList.length <= 2) {
                _this.isNewCustomer = false;
                _this.data.emailList.push(res.email);
                _this.data.customerList.push(res);
                _this.emailSearch = '';
                // Only populate customer data if it's the first email added
                if (_this.data.emailList.length === 1) {
                    _this.setAdditionalInfoFromCustomer(res);
                }
                _this.checkEmailListLength();
            }
            _this.isLoadingOrderDetails = false;
        });
    };

    // When to show customer data
    _this.showCustomerData = function (transactionObject) {
        var required = ['company', 'address1', 'city', 'province', 'country',
            'postalcode', 'telephone', 'firstname', 'lastname', 'email'];
        return hasRequiredField(required, transactionObject);
    };

    // When to show customer data
    _this.showAddressData = function (transactionObject) {
        var requiredFields = ['address1', 'city', 'province', 'country', 'postalcode', 'company'];
        return hasRequiredField(requiredFields, transactionObject);
    };

    //add padding to email field based on amount of email tags
    _this.emailSearchPadding = function () {
        return 'email-padding-' + _this.data.emailList.length;
    };

    //grab keydown functions from user and apply actions to customer emial list
    _this.autoCompleteEmail = function (keyEvent) {
        var newEmail = false;
        if (_this.filterdCustomerList && _this.filterdCustomerList.length <= 0) {
            newEmail = true;
        }
        //Down arrow
        if (_this.data.emailList.length >= 3) {
            keyEvent.preventDefault();

        } else {
            if (keyEvent.keyCode === 40) {
                _this.emailSelectionIndex++;
            }
            //Up arrow
            if (keyEvent.keyCode === 38) {
                _this.emailSelectionIndex--;
            }
            //entery key
            if (keyEvent.keyCode === 13 || keyEvent.keyCode === 188 || keyEvent.keyCode === 32) {
                keyEvent.preventDefault();
                if (newEmail) {
                    if (validateEmail(_this.emailSearch)) {
                        _this.isEmailRequired = false;
                        _this.data.emailList.push(_this.emailSearch);
                        _this.data.customerList.push(null);
                        _this.emailSearch = '';
                    }
                } else {
                    _this.selectedCustomer(_this.currentSelectedCustomer);
                }
                _this.emailSelectionIndex = 0;
                _this.currentSelectedCustomer = null;
            }
            //limmit of list
            if (_this.emailSelectionIndex >= 4) {
                _this.emailSelectionIndex = 4;
            }
            //start of list
            if (_this.emailSelectionIndex < 0) {
                _this.emailSelectionIndex = 0;
            }
        }
        debouncedFetchAutoCompleteApi();
    };

    _this.checkEmailListLength = function () {
        if (_this.data.emailList && _this.data.emailList.length) {
            _this.isEmailRequired = false;
        } else {
            _this.isEmailRequired = true;
        }
    };

    _this.setAdditionalInfoFromCustomer = function (customer) {
        _this.data.firstname = customer.first_name;
        _this.data.lastname = customer.last_name;
        _this.data.company = customer.company;
        _this.data.telephone = customer.telephone;
        _this.data.address1 = customer.address1;
        _this.data.address2 = customer.address2;
        _this.data.city = customer.city;
        _this.data.postalcode = customer.postal_code;
        _this.data.customer_lookup_id = customer.lookup_id;
        _this.data.bcc_email = customer.bcc_emails;
        if (customer.country){
            const resultFound = TransactionService.getCountryProvinceCode(customer, _this.onChangeCountry, _this.data);
            if (!resultFound.country){
                TransactionService.notFoundOldValues(_this.oldValues, _this.data, oldValueMessage);
            }
            if (!resultFound.province){
                TransactionService.notFoundOldValues(_this.oldValues, _this.data, oldValueMessage, false);
            }
        }
    };

    function resetOldValuesForCountry (forCountry) {
        if (forCountry){
            _this.oldValues.country = '';
            _this.oldValues.countryMessage = '';
        } else {
            _this.oldValues.province = '';
            _this.oldValues.provinceMessage = '';
        }
    }

    _this.removeEmailFromList = function (index) {
        _this.data.customerList.splice(index, 1);
        _this.data.emailList.splice(index, 1);
        _this.checkEmailListLength();

        if (_this.data.customerList.length !== 0 && _this.data.customerList[0] !== null) {
            const customer = _this.data.customerList[0];
            _this.setAdditionalInfoFromCustomer(customer);
        } else {
            _this.data.address1 = '';
            _this.data.address2 = '';
            _this.data.bcc_email = '';
            _this.data.company = '';
            _this.data.city = '';
            _this.data.country = null;
            _this.data.customer_lookup_id = '';
            _this.data.firstname = '';
            _this.data.lastname = '';
            _this.data.postalcode = '';
            _this.data.province = null;
            _this.data.telephone = '';
            _this.data.custom_id = '';
            resetOldValuesForCountry(true);
            resetOldValuesForCountry(false);
        }
    };

    //populate selection var with indexed customer
    _this.checkSelectionIndex = function (index, customerList) {
        var isSelected = false;
        if (_this.emailSelectionIndex >= customerList.length) {
            _this.emailSelectionIndex--;
        }
        if (index === _this.emailSelectionIndex) {
            isSelected = true;
            _this.currentSelectedCustomer = customerList[index];
        }
        return isSelected;
    };

    function pushEmailToList() {
        if (_this.emailSearch) {
            _this.data.emailList.push(_this.emailSearch);
            _this.emailSearch = '';
        }
    }

    //make new transaction
    _this.doCharge = function () {
        // Save default terminal and currency selected
        var defaultTerminal = _.find(_this.terminalList, { value: _this.terminalCurrencyList });
        var defaultCurrency = _.find(_this.terminalCurrencyList, { value: _this.terminalCurrencySelected });
        $window.localStorage.setItem('defaultTerminal', defaultTerminal.key);
        $window.localStorage.setItem('defaultCurrency', defaultCurrency.key);
        
        var transactionData;
        var customerData;
        var form = _this.form;
        _this.isValidCardNumber = true;
        //check form
        if (form.$valid) {
            _this.isLoading = true;
            _this.view.transaction = false;
            _this.view.transactionSecondary = false;

            //Revert BUG-730
            pushEmailToList();
            //Set up the transaction data object
            transactionData = _this.setTransactionData(_this.data, false);
            //Is transaction a refund aand is that refund allowed
            if (_this.checkRefund(transactionData)) {
                //Set customer data
                customerData = setCustomerData(_this.data);
                //merge customer and transaction data
                transactionData = parseCustomerData(transactionData, customerData);
                //save transaction
                _this.chargeOrPayInvoice(transactionData);
            } else {
                _this.isLoading = false;
                _this.isLoadingSecondary = false;
            }
        } else {
            PaperToastService.create('Fill in the required fields.', 'error');
        }
    };

    // Secondary Action
    _this.doSecondary = function () {
        var transactionData;
        var customerData;
        var form = _this.form;
        _this.isValidCardNumber = true;
        //check form
        if (form.$valid) {
            _this.isLoadingSecondary = true;
            _this.view.transaction = false;
            _this.view.transactionSecondary = false;

            //Revert BUG-730
            pushEmailToList();

            //Set up the transaction data object
            transactionData = _this.setTransactionData(_this.data, true);
            //Is transaction a refund aand is that refund allowed
            if (_this.checkRefund(transactionData)) {
                //Set customer data
                customerData = setCustomerData(_this.data);
                //merge customer and transaction data
                transactionData = parseCustomerData(transactionData, customerData);
                //save transaction
                _this.doTransaction(transactionData);
            } else {
                _this.isLoading = false;
                _this.isLoadingSecondary = false;
            }
        } else {
            PaperToastService.create('Fill in the required fields.', 'error');
        }
    };

    _this.newTransaction = function (form) {
        //Check url for customer vault id, re-route to vault to the
        if ($route.current.params.customer) {
            $location.url('customer-vault/edit/' + $route.current.params.customer);
        } else {
            _this.clearSale(_this.data, form);
            _this.view.transaction = false;
            _this.view.transactionSecondary = false;
            UrlService.go('cardterminal');
            $route.reload();
        }
    };

    //clear object data
    _this.clearSale = function (data, form) {


        if (data && form) {
            data.amount = '0.00';
            data.card_number = '';
            data.card_date = '';
            data.card_cvv = '';
            data.email = '';
            data.bcc_email = '';
            data.firstname = '';
            data.lastname = '';
            data.company = '';
            data.telephone = '';
            data.description = '';
            data.order_id = '';
            data.invoice_id = '';
            data.address1 = '';
            data.address2 = '';
            data.city = '';
            data.province = null;
            data.customer_lookup_id = '';
            data.country = null;
            data.postalcode = '';
            _this.data.emailList = [];
            _this.emailSearch = '';
            _this.saleResponse.original_id = null;
            form.$setPristine();
        }
    };

    _this.onChangeCountry = function (selectedCountry) {
        resetOldValuesForCountry(true);
        if (!selectedCountry){
            _this.data.province=null;
            return;
        }
        _this.provinceList = TransactionService.getProvinces(selectedCountry);
    };

    _this.onChangeProvince = function () {
        resetOldValuesForCountry(false);
    };

    _this.initializeTerminalSelections = function() {
        const terminals = SessionStorageService.get().getUser().terminals;
        // No terminals found
        if (!terminals) {
            _this.terminalList = [{
                key: 'No Terminals Found',
                value: [{
                    key: 'ERR',
                    value: null
                }]
            }];
            _this.disableAmountEntry = true;
            return;
        }

        // Key: terminal ID, value: processor ID and currency as obj
        var terminalList = [];
        for (var i = 0; i < terminals.length; i++) {
            // Check if key already exists
            let terminalFound = _.find(terminalList, { key: terminals[i].terminal_id });

            // If it exists push it to the key's array in value
            if (terminalFound) {
                terminalFound.value.push({
                    key: terminals[i].currency,
                    value: {
                        currency: terminals[i].currency,
                        processor_id: terminals[i].processor_id,
                        terminal_id: terminals[i].terminal_id
                    }
                });
            } else {
                // Key does not exist, create as new key
                terminalList.push({
                    key: terminals[i].terminal_id,
                    value: [{
                        key: terminals[i].currency,
                        value: {
                            currency: terminals[i].currency,
                            processor_id: terminals[i].processor_id,
                            terminal_id: terminals[i].terminal_id
                        }
                    }]
                });
            }
        }
        _this.terminalList = terminalList;

        // Check if terminalList actually has elements
        if (!_this.terminalList[0] || !_this.terminalList[0].value
            || !_this.terminalList[0].value[0] || !_this.terminalList[0].value[0].value) {
            _this.terminalList = [{
                key: 'No Terminals Found',
                value: [{
                    key: 'ERR',
                    value: null
                }]
            }];
            _this.disableAmountEntry = true;
            return;
        }
        
        // Get default terminal and currency selected from localStorage and match with
        // available values if possible
        var defaultTerminal = $window.localStorage.getItem('defaultTerminal');
        var defaultCurrency = $window.localStorage.getItem('defaultCurrency');

        var terminalFound = false;
        var currencyFound = false;

        if (defaultTerminal && defaultCurrency) {
            // Find saved terminal from localStorage
            terminalFound = _.find(_this.terminalList, { key: defaultTerminal });
            if (terminalFound) {
                // Terminal found
                _this.terminalCurrencyList = terminalFound.value;
                currencyFound = _.find(terminalFound.value, { key: defaultCurrency });
                if (currencyFound) {
                    // 1. Both terminal and currency found
                    _this.terminalCurrencySelected = currencyFound.value;
                } else {
                    // 2. Terminal found, currency not found but list exists
                    // Set to first element in list
                    _this.terminalCurrencySelected = _this.terminalCurrencyList[0].value;
                }
            } else {
                // Terminal not found
                // Set both to first elements
                _this.terminalCurrencyList = _this.terminalList[0].value;
                _this.terminalCurrencySelected = _this.terminalCurrencyList[0].value;
            }
        } else {
            // Saved terminal or currency do not exist
            // Set both to first elements
            _this.terminalCurrencyList = _this.terminalList[0].value;
            _this.terminalCurrencySelected = _this.terminalCurrencyList[0].value;
        }

        _this.data.currencySelected = _this.terminalCurrencySelected.currency.slice(0, 2);
        _this.data.processor_id = _this.terminalCurrencySelected.processor_id;
    };
    _this.initializeTerminalSelections();

    // Initialize currency selection after terminal is selected
    _this.onTerminalSelected = function(model) {
        _this.terminalCurrencySelected = model[0].value;
        _this.data.currencySelected = model[0].value.currency.slice(0, 2);
        _this.data.processor_id = model[0].value.processor_id;
    };

    _this.onTerminalCurrencySelected = function(model) {
        _this.data.currencySelected = model.currency.slice(0, 2);
        _this.data.processor_id = model.processor_id;
    };

    /**
     *  Pay invoice using credit card data
     */
    _this.doPayInvoice = function (data) {
        var status;
        var transactionResult;
        //build card details
        var invoiceId = _this.data.currentInvoice.invoice_id;
        var cardDetails = {
            card_expiry_month: data.card_date.slice(0, 2),
            card_expiry_year: data.card_date.slice(3, 5),
            card_number: data.card_number.split(' ').join(''),
            cvv2: data.card_cvv
        };

        //pay invoice from card details
        $log.info('paying invoice...');
        InvoiceService.payInvoice(invoiceId, cardDetails)
            .then(function (payInvoiceResponse) {
                _this.isLoading = false;
                _this.isLoadingSecondary = false;
                status = payInvoiceResponse.status;
                transactionResult = payInvoiceResponse.data.transaction_result;
                if (status === 200 && transactionResult === 'APPROVED') {
                    PaperToastService.create('Invoice payment successfull.', 'update');
                    _this.data.currentInvoice.showPayInvoice = false;
                    _this.data.currentInvoice.status = 'Paid';
                    $log.info('invoice paid:', transactionResult);
                } else if (status === 200 && transactionResult === 'DECLINED') {
                    PaperToastService.create('Transaction Declined.', 'error');
                    $log.info('invoice payment declined:', transactionResult);
                } else {
                    PaperToastService.create(transactionResult, 'error');
                    $log.info('invoice payment failed:', transactionResult);
                }
            });

    };

    /**
     * Print transaction receipts
     * @param {Number} transId - Id of transaction to print
     */
    _this.printReceipt = function (transId) {
        TransactionReceiptService.printReceipt(transId);
    };

    _this.closeInvoiceModal = function () {
        _this.data.currentInvoice.showPayInvoice = false;
    };

    _this.makeInvoiceAsPaid = function () {
        var invoiceId = _this.data.currentInvoice.invoice_id;
        InvoiceService.changeInvoiceStatus(invoiceId, 'Paid')
            .then(function () {
                PaperToastService.create('Invoice marked as paid.', 'update');
                _this.data.currentInvoice.status = 'Paid';
                _this.data.currentInvoice.showPayInvoice = false;
            });
    };

    // Shortens Currency label
    _this.shortenCurrencyLabel = function (currency) {
        var labelString = '';
        if (currency === 'CAD') {
            labelString = 'CA';
        }
        if (currency === 'USD') {
            labelString = 'US';
        }
        return labelString;
    };

    _this.emailStringToHuman = function (emailString, primary) {
        return TransactionService.emailStringToHuman(emailString, primary);
    };

    _this.populateTransactionAndReinitializeTerminal = function (transaction) {
        _this.initializeWithTransaction(transaction); //initialize view data again just in case it is not called
    };

    window.PAYFIRMA.CommunicationService.onOriginalTransactionPopulatedEvent(function (transaction) {
        _this.populateTransactionAndReinitializeTerminal(transaction);
    });

    window.PAYFIRMA.CommunicationService.onModalCloseEvent(function () {
        $route.reload();
    });

    //Clear form state and data once modal is closed using escape key
    window.PAYFIRMA.CommunicationService.onModalCloseEvent(function () {
        $route.reload();
    });
}

export default angular.module('payhqUIApp').component('paperFormCardTerminal', {
    bindings: {
        name: '=',
        type: '@',
        data: '=',
        orginaldata: '=',
        form: '=',
        settings: '='
    },
    controller: paperFormCardTerminal,
    controllerAs: 'paperFormCardTerminal',
    require: '?ngModel',
    templateUrl: 'component/paper-form-card-terminal/paper-form-card-terminal.html'
}).name;