import angular from 'angular';

function eft(Utils, EFTService, $window, PaperToastService) {
    var _this;
    _this = this;
    _this.view = {
        bankDepositModal : false
    };
    _this.data = {
        amount : ''
    };

    _this.viewAllEftCreditTransactions = function () {
        Utils.go(decodeURIComponent('/transactions/eft/credit'));
    };

    _this.viewAllEftDebitTransactions = function () {
        Utils.go(decodeURIComponent('/transactions/eft/debit'));
    };

    _this.viewAllEftTransactions = function () {
        Utils.go(decodeURIComponent('/transactions/eft'));
    };

    _this.requestMoney = function () {
        Utils.go(decodeURIComponent('/customer-vault'));
    };

    _this.addCustomer = function () {
        Utils.go(decodeURIComponent('/customer-vault/create'));
    };

    _this.payRequest = function (requestUrl) {
        $window.open(requestUrl);
    };

    function clearForm() {
        _this.data = {
            amount : ''
        };
    }

    _this.open = function () {
        _this.view.bankDepositModal = true;
    };

    _this.cancel = function () {
        _this.view.bankDepositModal = false;
        clearForm();
    };

    function getWalletBallance() {
        _this.isLoadingData = true;
        EFTService.getWalletBallance()
                .then(function (response) {
                    _this.isLoadingData = false;
                    _this.availableBalance = response.data.available_balance;
                    _this.actualBalance = response.data.actual_balance;
                    let reserve = _this.actualBalance - _this.availableBalance;
                    _this.reserve = !isNaN(reserve) ? reserve : 0;
                });
    }

    _this.save = function (form) {
        if (form.$invalid) {
            return PaperToastService.create('Fill in Required Fields.', 'error');
        }
        if (_this.data.amount > _this.availableBalance) {
            return PaperToastService.create('Amount should be less than or equal to the available balance', 'error');
        }
        _this.isLoading = true;
        EFTService.depositToBank(_this.data.amount)
                .then(() => {
                    PaperToastService.create('Depositing amount to bank successfully', 'update');
                    _this.view.bankDepositModal = false;
                    _this.isLoading = false;
                    getWalletBallance();
                    clearForm();
                },
                () => {
                    _this.isLoading = false;
                    PaperToastService.create('Depositing amount to bank failed', 'error');
                });
    };

    _this.$onInit = function(){
        window.PAYFIRMA.CommunicationService.triggerTotalIncomingRequestEvent(_this.list.length);
        getWalletBallance();
    };
}

angular
.module('payhqUIApp')
.component('eft', {
    bindings: {
        list: '='
    },
    controller: eft,
    controllerAs: 'eft',
    templateUrl: 'view/eft/eft.html'
});
