let BILLING_CONSTANT = {
    status: {
        active: 'ACTIVE',
        cancelled: 'CANCELLED',
        retry: 'RETRY',
        suspended: 'SUSPENDED',
        expired: 'EXPIRED',
        paused: 'PAUSED'
    },
    category: {
        all: 'All Customers',
        with_plans: 'With Plans',
        without_plans: 'Without Plans'
    }
};
export default BILLING_CONSTANT;