/* @flow */

import _ from 'lodash';

let PlanModel = function (data : Object) {
    var _this = this;

    _.extend(_this, data);

    if (_this.number_of_payments === 0) {
        _this.number_of_payments = undefined;
    }

    _this.getFormattedBaseAmount = function () {
        var textValue = _this.amount + '';
        var formattedValue = parseFloat(textValue.replace(/[^\d\.]/, ''));
        return isNaN(formattedValue) ? 0 : formattedValue;
    };

    _this.getFormattedNumberOfPayments = function () {
        var numberOfPayments = _this.number_of_payments;
        if (!numberOfPayments || numberOfPayments === 0) {
            return '∞';
        } else {
            return numberOfPayments + '';
        }
    };

    return _this;
};
export default PlanModel;