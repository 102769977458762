/* @flow */

import CIBC_CONFIG from 'service/config/cibc-config';
import LOCAL_DEV_CONFIG from 'service/config/local-dev-config';
import PRODUCTION_CONFIG_ALT from 'service/config/production-config-alt';
import PRODUCTION_CONFIG_CYAN from 'service/config/production-config-cyan';
import PRODUCTION_CONFIG from 'service/config/production-config';
import SANDBOX_CONFIG from 'service/config/sandbox-config';
import SANDBOX_SIT_CONFIG from 'service/config/sandbox-sit-config';
import TEST_CONFIG from 'service/config/test-config';
import ONYX_CONFIG from 'service/config/onyx-config';

let currentHost = window.location.host;

function urlConfigurationData(){
    let urlConfigData;
    if (currentHost === 'test.payfirma.com' ||
        currentHost === 'test-demo.payfirma.com') {
        urlConfigData = TEST_CONFIG;
    } else if (currentHost === 'cibctest.payfirma.com') {
        urlConfigData = CIBC_CONFIG;
    } else if (currentHost === 'sandbox.payfirma.com') {
        urlConfigData = SANDBOX_CONFIG;
    } else if (currentHost === 'sandbox-sit.payfirma.com') {
        urlConfigData = SANDBOX_SIT_CONFIG;
    } else if (currentHost === 'hq2.payfirma.com' ||
        currentHost === 'hq2-demo.payfirma.com') {
        urlConfigData = PRODUCTION_CONFIG_ALT;
    } else if (currentHost === 'dev.payfirma.com' ||
        currentHost === 'dev-api.payfirma.com' ||
        currentHost === 'dev-hq.payfirma.com' ||
        currentHost === 'dev-local-api.payfirma.com') {
        urlConfigData = LOCAL_DEV_CONFIG;
    } else if (currentHost === 'cyan.payfirma.com' ) {
        urlConfigData = PRODUCTION_CONFIG_CYAN;
    } else if (currentHost === 'onyx.payfirma.com') {
        urlConfigData = ONYX_CONFIG;
    } else {
        urlConfigData = PRODUCTION_CONFIG;
    }
    return urlConfigData;
}

let urlConfig = {
    hqApi: urlConfigurationData().hq_api,
    hqResetPasswordDomain: urlConfigurationData().hq_resetPassword_domain,
    hqJwtTokenUrl: urlConfigurationData().hq_jwt_token_url,
    jwtClientId: urlConfigurationData().jwt_client_id,
    payInvoiceSecret: urlConfigurationData().pay_invoice_secret,
    invoiceApi: urlConfigurationData().invoice_api,
    fileManagementServiceApi: urlConfigurationData().file_management_service_api,
    fileManagementServicePublicApi: urlConfigurationData().file_management_service_public_api,
    heapId: urlConfigurationData().heap_id,
    planServiceApi: urlConfigurationData().plan_service_api,
    customerServiceApi: urlConfigurationData().customer_service_api,
    transactionServiceApi: urlConfigurationData().transaction_service_api,
    analyticsServiceApi: urlConfigurationData().analytics_service_api,
    merchantServiceApi: urlConfigurationData().merchant_service_api,
    cibcOidcUrl: urlConfigurationData().cibc_oidc_url,
    cibcOidcClientId: urlConfigurationData().cibc_oidc_client_id,
    cibcAuthRedirectUrl: urlConfigurationData().cibc_auth_redirect_url,
    newRelicAppId: urlConfigurationData().new_relic_app_id,
    merchantServicePublicApi: urlConfigurationData().merchant_service_public_api,
    hppServiceApi: urlConfigurationData().hpp_service_api,
    captchaSiteKey: urlConfigurationData().catpcha_site_key,
    reportingSystemUrl: urlConfigurationData().reporting_system_url,
    graphqlServiceApi: urlConfigurationData().graphql_service_api
};

let ConfigurationService = {
    getConfig: () => {
        return urlConfig;
    }
};

export default ConfigurationService;
