import angular from 'angular';

function eftTransactionTableSection(Utils, EFTService, TimeService, CustomerService, PaperToastService, $translate, $log, SessionService) {
    var _this;
    _this = this;
    _this.view = {
        EFTModal : false,
        EFTSendModal : false,
        transactionModal : false
    };
    _this.data = {
        amount : '',
        description : '',
        transitNumber : '',
        institutionNumber : '',
        accountNumber : ''
    };
    _this.tempRecentTransaction=[];
    _this.viewAllCustomerTransactions = function () {
        Utils.go(decodeURIComponent('/transactions/customer/lookup/' + _this.customerLookupId + '/eft'));
    };

    _this.open=function () {
        _this.view.EFTModal = true;
    };

    _this.openEFTSend=function () {
        _this.view.EFTSendModal = true;
    };

    _this.hasEFTPermission = function () {
        return SessionService.checkUserPermission('EFTMaster');
    };

    function clearForm() {
        _this.data = {
            amount : '',
            description : '',
            transitNumber : '',
            institutionNumber : '',
            accountNumber : ''
        };
    }

    _this.cancel=function (type) {
        _this.view[type] = false;
        if (_this.tempRecentTransaction.length){
            _this.recentTransactions = _this.tempRecentTransaction;
        }
        clearForm();
    };

    _this.closeTrasactionModal=function () {
        _this.view.transactionModal = false;
        _this.recentTransactions = _this.tempRecentTransaction;
        clearForm();
    };

    _this.newTransaction=function () {
        _this.view.transactionModal = false;
        clearForm();
        if (_this.eftResponse.statusMessage.includes('CREDIT')) {
            _this.view.EFTModal = true;
        } else {
            _this.view.EFTSendModal = true;
        }
    };

    //set EFT request data
    function setEFTRequestData() {
        return {
            amount: _this.data.amount,
            description: _this.data.description
        };
    }

     //set EFT send data
    function setEFTSendData() {
        return {
            amount: _this.data.amount,
            description: _this.data.description,
            transit_number: _this.data.transitNumber,
            institution_number: _this.data.institutionNumber,
            account_number: _this.data.accountNumber
        };
    }

    function handleTransactionError(error) {
        let message = 'Transaction error';
        if (error && error.message) {
            message += ' - ' + error.message;
        }
        if (error.toString().includes('First and last name')) {
            message = 'First and last name is required, Please add it for this customer profile';
        }

        if (error.toString().toLowerCase().includes('access is denied')) {
            $translate(['accessDenied']).then((translated) => {
                message = translated.accessDenied;
                PaperToastService.create(message, 'error');
            });
        } else {
            PaperToastService.create(message, 'error');
        }

        _this.isLoading = false;
        $log.error(message, error);
        throw error;
    }

    //set transaction response
    function setTransactionSuccessResponse(response) {
    //create responose object and map response
        _this.eftResponse = {};
        _this.eftResponse.id = response.id;
        _this.eftResponse.amount = response.amount;
        _this.eftResponse.card_type = response.card_type;
        _this.eftResponse.telephone = response.telephone;
        _this.eftResponse.company = response.company;
        _this.eftResponse.country = response.country;
        _this.eftResponse.province = response.province;
        _this.eftResponse.address1 = response.address1;
        _this.eftResponse.address2 = response.address2;
        _this.eftResponse.city = response.city;
        _this.eftResponse.description = response.description;
        _this.eftResponse.status = Utils.enumToHuman(response.transaction_result);
        _this.eftResponse.currency = 'CAD';
        _this.eftResponse.statusMessage = response.transactionTypeLabel + ' ' + _this.eftResponse.status;
        _this.eftResponse.firstname = response.first_name;
        _this.eftResponse.lastname = response.last_name;
        _this.eftResponse.postalcode = response.postal_code;
        _this.eftResponse.email = response.email;
        if (response.transaction_id) {
            _this.eftResponse.transaction_success = response.transaction_success;
            _this.eftResponse.time = TimeService.epochToDateAndTime(response.transaction_time);
            _this.eftResponse.transaction_id = response.transaction_id;
            _this.eftResponse.date = response.date;
            _this.eftResponse.transaction_message = response.transaction_message ? response.transaction_message.replace(/\n/ig, '') : '';
        }
        _this.view.transactionModal = true;
    }

    //set transaction response
    function setTransactionErrorResponse(response) {
        //create responose object and map respons
        var errorMessage = response.error;
        if (response.errors) {
            if (response.errors.length >= 2) {
                errorMessage = response.errors[0].message + ' and ' + (response.errors.length - 1) + ' more errors';
            } else {
                errorMessage = response.errors[0].message;
            }
        }
        PaperToastService.create(errorMessage, 'error');
        _this.isLoading = false;
    }

    function hasRequiredField(requiredFields, transactionObject) {
        var objectWithRequiredField = _.find(requiredFields, function (requireField) {
            return transactionObject && transactionObject[requireField];
        });
        return objectWithRequiredField ? true : false;
    }

    // When to show customer data
    _this.showCustomerData = function (transactionObject) {
        var required = ['company', 'address1', 'city', 'province', 'country',
            'postalcode', 'telephone', 'firstname', 'lastname', 'email', 'custom_id'];
        return hasRequiredField(required, transactionObject);
    };
    // When to show customer data
    _this.showAddressData = function (transactionObject) {
        var requiredFields = ['address1', 'city', 'province', 'country', 'postalcode', 'company'];
        return hasRequiredField(requiredFields, transactionObject);
    };

    // Shortens Currency label
    _this.shortenCurrencyLabel = function (currency) {
        var labelString = '';
        if (currency === 'CAD') {
            labelString = 'CA';
        }
        if (currency === 'USD') {
            labelString = 'US';
        }
        return labelString;
    };

    // send EFT Request
    _this.save = function(form, type) {
        if (form.$invalid) {
            return PaperToastService.create('Fill in Required Fields.', 'error');
        }
        let promise;
        _this.isLoading = true;
        if (type === 'REQUEST') {
            promise = EFTService.sendEFTRequest(setEFTRequestData(), _this.customerLookupId);
        } else if (type === 'SEND') {
            promise = EFTService.sendEFT(setEFTSendData(), _this.customerLookupId);
        }
        promise.then(
            function (response) {
                _this.isLoading = false;
                if (type === 'REQUEST') {
                    _this.view.EFTModal = false;
                } else if (type === 'SEND') {
                    _this.view.EFTSendModal = false;
                }
                if (!response.error) {
                    setTransactionSuccessResponse(response);
                    CustomerService.getCustomerEFTTransactions(_this.customerLookupId).then(function(recentTransactions){
                        _this.tempRecentTransaction = recentTransactions;
                    });
                } else {
                    setTransactionErrorResponse(response);
                }
            },
            function (error) {
                handleTransactionError(error);
            }
        );
    };
}

angular
.module('payhqUIApp')
.component('eftTransactionTableSection', {
    bindings: {
        recentTransactions: '<',
        customerLookupId: '<',
        customerEmail: '<'
    },
    controller: eftTransactionTableSection,
    controllerAs: 'eftTransactionTableSection',
    templateUrl: 'view/vault/eft-transaction-table/eft-transaction-table-section.html'
});
