/* @flow */

import angular from 'angular';
import 'script/app-init';
import CONSTANT_APP from 'constant/constant-app';
import CONSTANT_COMPONENT from 'constant/constant-component';
import SessionStorageService from 'service/session-storage-service';

const APP_MODULE = CONSTANT_APP.module;
const COMPONENT_SETTINGS = CONSTANT_COMPONENT.settings;

function settings($scope, ImageStore, $route, PaperToastService, $log, Utils,
                  ImageCropService, InvoiceService, SessionService, SettingsService,
                  $timeout, TimeService, $window, $location) {

    let _this = this;

    _this.isMasterEntityMerchant = SessionService.checkUserPermission('MasterEntity');

    // $FlowFixMe -- fix SessionStorageService first
    let currentUser = SessionStorageService.get().getUser();

    _this.currentMechantEmail = currentUser.business_information.email;

    _this.merchantid = currentUser.business_information.id;

    _this.userid = currentUser.id;


    function initializeReceiptSettingsImage() {
        _this.receiptLogo = undefined;

        ImageStore.onImageUpdated(function (image) {
            _this.receiptLogo = image;
            Utils.forceScopeApply($scope);
        });

        ImageStore.requestImage({
            merchantId: _this.merchantid
        }, window.PAYFIRMA.IMAGE_CATEGORY.MERCHANT_LOGO);
    }

    function initializeInvoiceLogoImage() {
        _this.invoiceLogo = undefined;
        ImageStore.onImageUpdated(function (image) {
            _this.invoiceLogo = image;
            Utils.forceScopeApply($scope);
        });
        ImageStore.requestImage({
            templateId: _this.invoice.template_id
        }, window.PAYFIRMA.IMAGE_CATEGORY.INVOICE_LOGO);
    }

    _this.loading = false;
    _this.cancel = function () {
        $route.reload();
    };

    _this.list_timezone = TimeService.getTimezoneList();
    _this.list_language = [{
        'key': 'English',
        'value': 'ENGLISH'
    }, {
        'key': 'French',
        'value': 'FRENCH'
    }];

    _this.list_tax_type = [
        {
            'key': '%',
            'value': 'percent'
        },
        {
            'key': '$',
            'value': 'dollar'
        }
    ];

    _this.invoice = {
        language: _this.list_language[0].value,
        logo_image_path: '',
        business_name: '',
        business_address1: ' ',
        business_address2: '',
        business_city: ' ',
        business_state: ' ',
        business_country: ' ',
        business_postal_code: '',
        email_from: '',
        support_email: '',
        support_phone_number: '',
        custom_invoice_message: '',
        custom_email_message: '',
        tax1_label: '',
        tax1: '',
        percentile1: '',
        tax2_label: '',
        tax2: '',
        percentile2: '',
        resend_invoices: true
    };

    _this.switchView = function (view) {
        $location.path('settings/' + view);

        if (view === 'invoicing') {
            initializeInvoiceLogoImage();
        } else if (view === 'receipt') {
            initializeReceiptSettingsImage();
        }
        // Whenever the user switches tab (view), it brings them back up to the top
        Utils.scrollToTop();
    };

    _this.getSettings = function () {
        _this.requiredField = _this.merchantSettings.requiredField;
    };

    _this.getReceiptSettings = function () {
        return SettingsService.getReceiptSettings().then(function (response) {
            _this.receipt = response;
        });
    };
    _this.getInvoiceTemplates = function () {
        //standardize promise signatures using bluebird library
        return InvoiceService.getInvoiceTemplates().then(function (response) {
            _this.invoice = response.templates[0];
            if (_this.invoice.percentile1 === false) {
                _this.percentOrDollar1 = 'dollar';
            } else {
                _this.percentOrDollar1 = 'percent';
            }
            if (_this.invoice.percentile2 === false) {
                _this.percentOrDollar2 = 'dollar';
            } else {
                _this.percentOrDollar2 = 'percent';
            }
            _this.invoice.business_address2 = '';
            _this.invoice.email_from = '';
            _this.invoice.logo_image_path = '';
        }).error(function (error) {
            throw error;
        });
    };

    _this.saveReceiptSettings = function () {
        _this.loading = true;
        return SettingsService.saveReceiptSettings(_this.receipt).then(function (response) {
            _this.loading = false;
            PaperToastService.create('Updated receipt settings.', 'update');
            Utils.scrollToTop();
            return response;
        }).catch(function () {
            let errorMessage = 'Error updating receipt settings';
            _this.loading = false;
            PaperToastService.create('Error updating receipt settings.', 'error');
            Utils.scrollToTop();
            $log.error(errorMessage);
            throw new Error(errorMessage);
        });
    };

    _this.updateRequired = function () {
        let data = {
            required_field: _this.requiredField,
            timezone: _this.timezone
        };

        _this.saveSettings(data).then(function () {
            PaperToastService.create('Updated required fields settings.', 'update');
        });

    };

    _this.updateTimezone = function () {
        let data = {
            required_field: _this.requiredField,
            timezone: _this.timezone
        };
        _this.saveSettings(data)
            .then(function () {
                PaperToastService.create('Updated time zone settings.', 'update');
            });
    };

    _this.saveSettings = function (data) {
        _this.loading = true;
        return SettingsService.saveSettings({
            required_fields: data.required_field,
            timezone: data.timezone || undefined
        }).then(function (response) {
            _this.loading = false;
            return response;
        }).catch(function (error) {
            $log.error('Error updating settings');
            _this.loading = false;
            throw error;
        });
    };

    _this.getApiSettings = function () {
        _this.ecom = undefined;
        return SettingsService.getApiSettings().then(function (response) {
            _this.ecom = response;
            return response;
        }).catch(function (error) {
            throw error;
        });
    };

    function refreshApiKey() {
        _this.loading = true;
        let refreshApiKeyRequest = {
            refresh_api_key: true
        };
        return SettingsService.updateApiSettings(refreshApiKeyRequest).then(function (response) {
            _this.ecom = response;
            PaperToastService.create('Api key has been refreshed.', 'update');
            _this.refreshInput = undefined;
            _this.loading = false;
            return response;
        }).catch(function (error) {
            PaperToastService.create('Error refreshing keys.', 'error');
            _this.loading = false;
            throw error;
        });
    }

    function refreshPublicKey() {
        _this.loading = true;
        let refreshPublicKeyRequest = {
            refresh_public_encryption_key: true
        };
        return SettingsService.updateApiSettings(refreshPublicKeyRequest).then(function (response) {
            _this.ecom = response;
            PaperToastService.create('Public key has been refreshed.', 'update');
            _this.refreshInput = undefined;
            _this.loading = false;
            return response;
        }).catch(function (error) {
            PaperToastService.create('Error refreshing keys.', 'error');
            _this.loading = false;
            throw error;
        });
    }

    _this.refreshKeyApi = function (form) {
        if (form.$valid) {
            _this.isApiKeyModalVisible = false;
            return refreshApiKey();
        }
    };

    _this.refreshKeyPublic = function (form) {
        if (form.$valid) {
            _this.isPublicKeyModalVisible = false;
            return refreshPublicKey();
        }
    };

    _this.saveURL = function () {
        _this.loading = true;

        let savePublicApiUrlRequest = {
            public_api_url: _this.ecom.public_api_url,
            refresh_public_encryption_key: false,
            refresh_api_key: false
        };

        return SettingsService.updateApiSettings(savePublicApiUrlRequest).then(function (response) {
            _this.loading = false;
            _this.ecom = response;
            PaperToastService.create('URL successfully saved.', 'update');
            return response;
        }).catch(function (error) {
            _this.loading = false;
            PaperToastService.create('Error saving URL.', 'error');
            throw error;
        });
    };

    _this.saveInvoicing = function (form) {
        Utils.scrollToTop();

        if (form.$valid) {
            if (_this.percentOrDollar1 === 'dollar') {
                _this.invoice.percentile1 = false;
            } else {
                _this.invoice.percentile1 = true;
            }
            if (_this.percentOrDollar2 === 'dollar') {
                _this.invoice.percentile2 = false;
            } else {
                _this.invoice.percentile2 = true;
            }
            _this.loading = true;
            if (!_this.invoice.template_id) {
                return InvoiceService.saveInvoiceTemplate(_this.invoice)
                    .then(function (invoiceTemplateResponse) {

                        _this.loading = false;
                        if (invoiceTemplateResponse.status !== 200) {
                            PaperToastService.create('Error saving invoice settings.', 'error');
                        } else {
                            _this.invoice.template_id = invoiceTemplateResponse.data.template_id;
                            PaperToastService.create('Invoicing settings saved.', 'update');
                            $route.reload();
                        }
                        return invoiceTemplateResponse;
                    });
            } else {
                return InvoiceService.updateInvoiceTemplate(_this.invoice.template_id, _this.invoice)
                    .then(function (invoiceTemplateResponse) {

                        _this.loading = false;
                        if (invoiceTemplateResponse.status !== 200) {
                            PaperToastService.create('Error saving invoicing settings.', 'error');
                        } else {
                            PaperToastService.create('Invoicing settings updated.', 'update');
                        }

                        return invoiceTemplateResponse;
                    });
            }
        } else {
            let errorMessage = 'Please fill in the required fields.';
            PaperToastService.create(errorMessage, 'error');
            throw new Error(errorMessage);
        }
    };

    _this.showPreview = function () {
        let url = '#/preview-theme';
        $window.open(url, '_blank');
    };

    _this.closeRefreshModal = function () {
        _this.isPublicKeyModalVisible = false;
        _this.isApiKeyModalVisible = false;
        _this.refreshInput = '';
    };

    _this.$onInit = function () {
        _this.getApiSettings();
        _this.getSettings();
        _this.getInvoiceTemplates()
            .then(() => {
                $timeout(function () {
                    if (_this.view.invoicing) {
                        initializeInvoiceLogoImage();
                    } else if (_this.view.receipt) {
                        initializeReceiptSettingsImage();
                    }
                }, 300);
            });
        _this.getReceiptSettings();
        //initialize and set default value
        _this.timezone = TimeService.getTimezone();

        
    };

    _this.hasEcommercePermission = function () {
        return SessionService.checkUserPermission('eCommerceMaster');
    };

    _this.isInvoiceTemplateAvailable = function() {
        return _this.invoice && _this.invoice.template_id;
    };

    ImageCropService.disableImageCroppingTriggers($scope);

    window.PAYFIRMA.CommunicationService.disableImageUploadEventTrigger();
}
angular
    .module(APP_MODULE)
    .component(COMPONENT_SETTINGS, {
        bindings: {
            merchantSettings: '=',
            view: '='
        },
        controller: settings,
        controllerAs: 'settings',
        templateUrl: 'view/settings/settings.html'
    });