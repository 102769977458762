import angular from 'angular';

window.PAYFIRMA = window.PAYFIRMA || {};

window.PAYFIRMA.TEXT_STRINGS = {};

angular
.module('payhqUIApp')
.service('InternationalizationService', function ($translate) {
    return {
        getTranslatedStrings: function () {
            return $translate(['allCustomers', 'withPlans', 'withoutPlans', 'status',
                'active', 'cancelled', 'retry', 'suspended', 'expired', 'paused',
                'ecommerce', 'invoicing', 'mobile', 'recurring', 'tabletPos', 'webTerminal',
                'draft', 'unpaid', 'paid', 'overdue', 'closed',
                'us', 'canada', 'unknown', 'location',
                'new', 'inactive', 'closed', 'testing',
                'type', 'demo', 'basic', 'pro', 'internal', 'channel'])
                .then(function (translations) {
                    window.PAYFIRMA.TEXT_STRINGS.ALL_CUSTOMERS = translations.allCustomers;
                    window.PAYFIRMA.TEXT_STRINGS.WITH_PLANS = translations.withPlans;
                    window.PAYFIRMA.TEXT_STRINGS.WITHOUT_PLANS = translations.withoutPlans;
                    window.PAYFIRMA.TEXT_STRINGS.STATUS = translations.status;
                    window.PAYFIRMA.TEXT_STRINGS.ACTIVE = translations.active;
                    window.PAYFIRMA.TEXT_STRINGS.CANCELLED = translations.cancelled;
                    window.PAYFIRMA.TEXT_STRINGS.RETRY = translations.retry;
                    window.PAYFIRMA.TEXT_STRINGS.EXPIRED = translations.expired;
                    window.PAYFIRMA.TEXT_STRINGS.PAUSED = translations.paused;
                    window.PAYFIRMA.TEXT_STRINGS.SUSPENDED = translations.suspended;
                    window.PAYFIRMA.TEXT_STRINGS.ECOMMERCE = translations.ecommerce;
                    window.PAYFIRMA.TEXT_STRINGS.INVOICING = translations.invoicing;
                    window.PAYFIRMA.TEXT_STRINGS.MOBILE = translations.mobile;
                    window.PAYFIRMA.TEXT_STRINGS.RECURRING = translations.recurring;
                    window.PAYFIRMA.TEXT_STRINGS.TABLETPOS = translations.tabletPos;
                    window.PAYFIRMA.TEXT_STRINGS.WEBTERMINAL = translations.webTerminal;
                    window.PAYFIRMA.TEXT_STRINGS.CHANNEL = translations.channel;
                    window.PAYFIRMA.TEXT_STRINGS.DRAFT = translations.draft;
                    window.PAYFIRMA.TEXT_STRINGS.UNPAID = translations.unpaid;
                    window.PAYFIRMA.TEXT_STRINGS.PAID = translations.paid;
                    window.PAYFIRMA.TEXT_STRINGS.OVERDUE = translations.overdue;
                    window.PAYFIRMA.TEXT_STRINGS.CLOSED = translations.closed;
                    window.PAYFIRMA.TEXT_STRINGS.US = translations.us;
                    window.PAYFIRMA.TEXT_STRINGS.CANADA = translations.canada;
                    window.PAYFIRMA.TEXT_STRINGS.UNKNOWN = translations.unknown;
                    window.PAYFIRMA.TEXT_STRINGS.LOCATION = translations.location;
                    window.PAYFIRMA.TEXT_STRINGS.NEW = translations.new;
                    window.PAYFIRMA.TEXT_STRINGS.INACTIVE = translations.inactive;
                    window.PAYFIRMA.TEXT_STRINGS.CLOSED = translations.closed;
                    window.PAYFIRMA.TEXT_STRINGS.TESTING = translations.testing;
                    window.PAYFIRMA.TEXT_STRINGS.TYPE = translations.type;
                    window.PAYFIRMA.TEXT_STRINGS.DEMO = translations.demo;
                    window.PAYFIRMA.TEXT_STRINGS.BASIC = translations.basic;
                    window.PAYFIRMA.TEXT_STRINGS.PRO = translations.pro;
                    window.PAYFIRMA.TEXT_STRINGS.INTERNAL = translations.internal;
                    return true;
                });
        }
    };
});