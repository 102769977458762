import angular from 'angular';
import filter from 'lodash/filter';
import find from 'lodash/find';
import BILLING_CONSTANT from 'constant/billing-constant';

angular
.module('payhqUIApp')
.filter('recurringPlanStatus', function () {
    return function (customerList, recurringPlanPropertyKey, customerGroupCategory, planStatus) {
        if (customerList && customerList.length && recurringPlanPropertyKey) {
            return filter(customerList, function (customer) {
                if (customerGroupCategory === BILLING_CONSTANT.category.all) {
                    return true; //all
                } else if (customerGroupCategory === BILLING_CONSTANT.category.with_plans) {
                    if (customer.recurring_plan_statuses){
                        return find(planStatus.recurring_plan_statuses, function (status) {
                            if (customer.recurring_plan_statuses.indexOf(status) > -1 ) {
                                return true;
                            }
                            return false;
                        });
                    }
                } else {
                    return !customer[recurringPlanPropertyKey];
                    //no recurring plan
                }

            });
        }
        return [];
    };
});