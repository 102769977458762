import angular from 'angular';
import CREDIT_CARD_FIELD_TYPE from 'filter/credit-card-field-type';
import SPECIAL_CHARACTERS from 'service/formatters/special-characters';

angular
.module('payhqUIApp')
.filter('creditCardMask', function () {
    return function (value, fieldType) {
        //format xxxx **** **** xxxx
        if (fieldType === CREDIT_CARD_FIELD_TYPE.CARD_NUMBER) {
            var maskedString = Array(16 - value.length + 1).join(SPECIAL_CHARACTERS.CREDIT_CARD_MASK) + value;

            //split credit card with a space every 4 characters
            return maskedString.match(/.{1,4}/g).join(' ');
        } else if (fieldType === CREDIT_CARD_FIELD_TYPE.CVV) {
            //@author: carl
            //sometimes server returns a cvv value, sometimes it does not
            //both cases are legit, we need to mask cvv by default with 3 stars
            //if server returns no value
            var numberOfDigits = 3;
            if (value) {
                numberOfDigits = value.length;
            }
            //+1 is important, otherwise it will only append n-1 characters
            return Array(numberOfDigits+1).join(SPECIAL_CHARACTERS.CREDIT_CARD_MASK);
        }
        return '';
    };
});