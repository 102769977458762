import moment from 'moment';
let FormatterLocator = {
    formatNumber: function (modelValue) {
        var viewValue;
        if (modelValue && !isNaN(modelValue)) {
            viewValue = parseInt(modelValue + '', 10);
        }
        return viewValue;
    },
    formatCardType: function(modelValue) {
        return modelValue ? modelValue.toLowerCase() : '';
    },
    validateCardMonth: function (value) {
        return value && !isNaN(value) &&
            (Number(value) <= 12 && Number(value) >= 1);
    },
    validateCardYear: function (value) {

        var currentYear = moment().year();
        if (value && !isNaN(value)) {
            var cardYear = parseInt(value, 10);
            if (value < 100) {
                cardYear += 2000;
            }
            return (cardYear >= currentYear);
        }

        return false;
    }
};
export default FormatterLocator;