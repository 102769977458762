import angular from 'angular';
import ConfigurationService from 'service/configuration-service';
import CONSTANT_IMAGE_CATEGORY from 'constant/constant-image-category';
import 'script/app-init';


import 'service/image-editing/models/UserProfilePicture';
import 'service/image-editing/models/ImageCategory';
import 'service/image-editing/models/MerchantLogo';
import 'service/image-editing/models/InvoiceLogo';
import 'service/image-editing/models/OtherUserProfilePicture';

function toBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    var parts, contentType, raw;

    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        parts = dataURL.split(',');
        contentType = parts[0].split(':')[1];
        raw = decodeURIComponent(parts[1]);

        return new window.Blob([raw], {
            type: contentType
        });
    } else {
        parts = dataURL.split(BASE64_MARKER);
        contentType = parts[0].split(':')[1];
        raw = window.atob(parts[1]);
        var rawLength = raw.length;

        var uInt8Array = new window.Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }


        return new window.Blob([uInt8Array], {
            type: contentType
        });
    }
}

function toDataUrl(imageBinaryData) {
    var uarr = new window.Uint8Array(imageBinaryData);
    var len = uarr.length;

    //http://codereview.stackexchange.com/questions/3569/pack-and-unpack-bytes-to-strings
    //http://jsperf.com/string-fromcharcode-test
    var result = '';
    for (var i = 0; i < len; i++) {
        result += String.fromCharCode(uarr[i]);
    }

    var b64 = window.btoa(result);
    return 'data:image/jpeg;base64,' + b64;
}

function createImageModel(imageKey, imageCategory) {
    if (imageCategory === CONSTANT_IMAGE_CATEGORY.MERCHANT_LOGO) {
        return new window.PAYFIRMA.MerchantLogo(imageKey.merchantId);
    } else if (imageCategory === CONSTANT_IMAGE_CATEGORY.USER_PROFILE_PICRTURE) {
        return new window.PAYFIRMA.UserProfilePicture();
    } else if (imageCategory === CONSTANT_IMAGE_CATEGORY.INVOICE_LOGO) {
        return new window.PAYFIRMA.InvoiceLogo(imageKey.templateId);
    } else if (imageCategory === CONSTANT_IMAGE_CATEGORY.OTHER_USER_PROFILE_PICTURE) {
        return new window.PAYFIRMA.OtherUserProfilePicture(imageKey.merchantId, imageKey.userId);
    }
    return undefined;
}

export default angular
.module('payhqUIApp')
.service('ImageStore',
    function ($log, HttpClientService) {
        //by convention, all of the image variables are stored in base64 encoded data url format
        var imageModel;

        function resetImageToDefault() {
            imageModel.resetToDefault();
            window.PAYFIRMA.CommunicationService.triggerImageUpdateEvent();
        }

        return {
            reset: function () {
                imageModel = undefined;
            },

            requestDefaultImage: function (imageCategory) {
                imageModel = createImageModel({}, imageCategory);
                resetImageToDefault();
            },
            imageExists: function () {
                return imageModel && !imageModel.isDefault();
            },
            onImageUpdated: function (callback) {
                window.PAYFIRMA.CommunicationService.onImageUpdateEvent(function () {
                    callback(imageModel.getImage());
                });
            },
            onImageCroppingUpdated: function (callback) {
                window.PAYFIRMA.CommunicationService.onCroppedImageUpdateEvent(function () {
                    callback(imageModel.getImageForCropping());
                });
            },
            requestImage: function (imageKey, imageCategory) {
                let hqConfig = ConfigurationService.getConfig();
                imageModel = createImageModel(imageKey, imageCategory);
                HttpClientService.doArrayBufferGet(hqConfig.fileManagementServiceApi
                        + imageModel.getRetrieveImageUrl(), {})
                    .then(function (response) {
                        if (response && response.data) {
                            var imageDataUrl = toDataUrl(response.data);
                            imageModel.setImage(imageDataUrl);
                            imageModel.setImageForCropping(imageDataUrl);
                            window.PAYFIRMA.CommunicationService.triggerImageUpdateEvent();
                        }
                    })
                    .catch(function (exception) {
                        resetImageToDefault();
                        $log.error(exception);
                    });
            },
            requestImageForCopping: function () {
                imageModel.syncImageForCropping();
                if (imageModel.getImageForCropping()) {
                    window.PAYFIRMA.CommunicationService.triggerCroppedImageUpdateEvent();
                }
            },
            updateImageForCropping: function (p) {
                imageModel.setImageForCropping(p.dataUrl);
                imageModel.setImageForCroppingFileName(p.filename);
                window.PAYFIRMA.CommunicationService.triggerCroppedImageUpdateEvent();
            },
            uploadImage: function (imageDataUrlToUpload) {
                let hqConfig = ConfigurationService.getConfig();
                var fd = new window.FormData();
                fd.append('file', toBlob(imageDataUrlToUpload));
                fd.append('filename', imageModel.getImageForCroppingFileName());
                return HttpClientService.doPost(
                    hqConfig.fileManagementServiceApi + imageModel.getUploadImageUrl(),
                    fd,
                    {
                        transformRequest: angular.identity,
                        headers: {
                            'Content-Type': undefined
                        }
                    }
                ).then(function () {
                    imageModel.setImage(imageDataUrlToUpload);
                    window.PAYFIRMA.CommunicationService.triggerImageUpdateEvent();
                }).catch(function (err) {
                    $log.error(err);
                    throw err;
                });
            },
            deleteImage: function () {
                let hqConfig = ConfigurationService.getConfig();
                return HttpClientService.doDelete(
                    hqConfig.fileManagementServiceApi + imageModel.getDeleteImageUrl()
                ).then(function () {
                    resetImageToDefault();
                }).catch(function (err) {
                    $log.error(err);
                    throw err;
                });
            }
        };
    }).name;
