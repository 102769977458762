import angular from 'angular';
import ConfigurationService from 'service/configuration-service';
import SessionStorageService from 'service/session-storage-service';

angular
    .module('payhqUIApp')
    .service('ResetPasswordService', function ResetPasswordService(AuthService, HttpClientService) {
        function toExceptionObject(error) {
            let message = window.PAYFIRMA.ServerErrorService.extractErrorMessage(error);

            throw new Error(message);
        }

        return {
            verifyToken: function (userLookupId, token) {
                return AuthService.getNonce()
                    .then(function (nonce) {
                        let postData = {
                            token: token,
                            nonce: nonce
                        };

                        return HttpClientService.doPost(
                            ConfigurationService.getConfig().merchantServicePublicApi
                            + 'user/password/' + userLookupId + '/token',
                            postData);
                    }).then(function (checkResponse) {
                        return checkResponse.data;
                    }).catch(function (error) {
                        throw toExceptionObject(error);
                    });
            },
            applyNewPassword: function (userLookupId, token, newPassword) {
                return AuthService.getNonce()
                    .then(function (nonce) {
                        let postData = {
                            token: token,
                            password: newPassword,
                            nonce: nonce
                        };
                        return HttpClientService.doPut(
                            ConfigurationService.getConfig().merchantServicePublicApi
                            + 'user/password/' + userLookupId,
                            postData);
                    }).then(function (applyResponse) {
                        return applyResponse.data;
                    }).catch(function (error) {
                        throw toExceptionObject(error);
                    });
            },
            requestResetPassword: function (userEmail) {
                return AuthService.getNonce()
                    .then(function (nonce) {
                        let postData = {
                            email: userEmail,
                            nonce: nonce
                        };
                        return HttpClientService.doPut(
                            ConfigurationService.getConfig().merchantServicePublicApi
                            + 'user/password',
                            postData);
                    }).then(function (requestResponse) {
                        return requestResponse.data;
                    }).catch(function (error) {
                        throw toExceptionObject(error);
                    });
            },

            changePassword: function (changeOwnPassword) {
                return AuthService.getNonce()
                    .then(function (nonce) {
                        let postData = {
                            token: changeOwnPassword.resetPasswordHashToken,
                            old_password: changeOwnPassword.currentPassword,
                            new_password: changeOwnPassword.newPassword,
                            user_lookup_id: changeOwnPassword.userLookupId,
                            institution_id: changeOwnPassword.institutionId,
                            nonce: nonce
                        };
                        return HttpClientService.doPut(
                            ConfigurationService.getConfig().merchantServicePublicApi
                            + 'user/password/change',
                            postData);
                    }).then(function (response) {
                        return response.data;
                    }).catch(function (error) {
                        throw toExceptionObject(error);
                    });
            },
            getChangePasswordInfo: function () {
                if (SessionStorageService.get()) {
                    return SessionStorageService.get().getChangePasswordInfo();
                }

                return undefined;
            },
            clearChangePasswordInfo: function () {
                if (SessionStorageService.get()) {
                    return SessionStorageService.get().setChangePasswordInfo(undefined);
                }
            },
            updateChangePasswordInfo: function (userLookupId,
                                                institutionId,
                                                resetPasswordHashToken,
                                                email,
                                                tokenType) {
                let changePasswordInfo = {
                    userLookupId: userLookupId,
                    resetPasswordHashToken: resetPasswordHashToken,
                    institutionId: institutionId,
                    email: email,
                    tokenType: tokenType
                };
                SessionStorageService.get().setChangePasswordInfo(changePasswordInfo);
            }
        };
    });