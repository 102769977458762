let TRANSACTION_CONSTANT = {
    result: [
        {
            key: '',
            value: null
        },
        {
            key: 'PENDING',
            value: 'PENDING'
        },
        {
            key: 'SUBMITTED',
            value: 'SUBMITTED'
        },
        {
            key: 'CLEARED',
            value: 'CLEARED'
        },
        {
            key: 'CANCELED',
            value: 'CANCELED'
        },
        {
            key: 'FUTURE',
            value: 'FUTURE'
        },
        {
            key: 'APPROVED',
            value: 'APPROVED'
        },
        {
            key: 'DECLINED',
            value: 'DECLINED'
        },
        {
            key: 'BATCH_PENDING',
            value: 'BATCH_PENDING'
        },
        {
            key: 'BATCH_ERROR',
            value: 'BATCH_ERROR'
        },
        {
            key: 'BITCOIN_SALE_PENDING',
            value: 'BITCOIN_SALE_PENDING'
        },
        {
            key: 'BITCOIN_REFUND_PENDING',
            value: 'BITCOIN_REFUND_PENDING'
        }
    ],
    source: [
        {
            key: '',
            value: null
        },
        {
            key: 'VT',
            value: 'VT'
        },
        {
            key: 'TEST_PAGE',
            value: 'TEST_PAGE'
        },
        {
            key: 'MOBILE',
            value: 'MOBILE'
        },
        {
            key: 'TABLET_POS',
            value: 'TABLET_POS'
        },
        {
            key: 'API',
            value: 'API'
        },
        {
            key: 'E_COMMERCE',
            value: 'E_COMMERCE'
        },
        {
            key: 'RECURRING',
            value: 'RECURRING'
        },
        {
            key: 'BATCH_UPLOAD',
            value: 'BATCH_UPLOAD'
        },
        {
            key: 'INVOICE',
            value: 'INVOICE'
        },
        {
            key: 'CARD_TERMINAL',
            value: 'CARD_TERMINAL'
        },
        {
            key: 'EFT',
            value: 'EFT'
        },
        {
            key: 'HPP',
            value: 'HPP'
        },
        {
            key: 'IFRAME',
            value: 'IFRAME'
        },
        {
            key: 'PAY_SUBSCRIPTION',
            value: 'PAY_SUBSCRIPTION'
        },
        {
            key: 'SHOPIFY',
            value: 'SHOPIFY'
        }
    ]
};
export default TRANSACTION_CONSTANT;