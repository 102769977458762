let CustomerSubscription= function (data) {
    var _this = this;

    _.extend(_this, data);

    _this.isValid = function() {
        return _this.plan_status !== window.PAYFIRMA.SUBSCRIPTION_STATUS.CANCELLED
            && _this.plan_status !== window.PAYFIRMA.SUBSCRIPTION_STATUS.EXPIRED;
    };

    _this.isSuspended = function() {
        return _this.plan_status === window.PAYFIRMA.SUBSCRIPTION_STATUS.SUSPENDED;
    };

    _this.getPlanStatus = function() {
        return _this.plan_status;
    };

    _this.getPlanFailedAttempts = function() {
        return _this.failed_attempts;
    };

    _this.getPlanDelinquentCycles = function() {
        return _this.delinquent_cycles;
    };

    return _this;
};
export default CustomerSubscription;