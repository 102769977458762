import angular from 'angular';

function paperSystemError(Utils, $scope, AuthService) {
    var _this = this;
    _this.logout = function () {
        _this.componentEnabled = false;
        AuthService.logout().then( function () {
            window.location.reload(true); //to get a new CSRF token
        });

        Utils.forceScopeApply($scope);
    };
    window.PAYFIRMA.CommunicationService.onSystemErrorEnabledEvent(function(messages){
        _this.errorTitle = messages.title;
        _this.errorMessage = messages.subtitle;
        _this.componentEnabled = true;
        Utils.forceScopeApply($scope);
    });
}

angular
.module('payhqUIApp')
.component('paperSystemError', {
    bindings: {},
    controller: paperSystemError,
    controllerAs: 'paperSystemError',
    templateUrl: 'component/paper-system-error/paper-system-error.html'
});
