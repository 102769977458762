'use strict';
import 'service/image-editing/models/Image';
import Constants from 'service/constants';

window.PAYFIRMA.InvoiceLogo = function(templateId){
    this.defaultImageUrl = Constants.IMAGE_BASE_LOCATION + 'phq-profile-image.jpg';
    var baseUrl = 'invoice_logos/';
    var cachebuster = window.Math.round(new Date().getTime() / 1000);
    this.retrieveImageUrl = baseUrl+'template/'+templateId+'?cache=' + cachebuster;
    this.uploadImageUrl = baseUrl+templateId;
    this.deleteImageUrl = baseUrl+templateId;
};

window.PAYFIRMA.InvoiceLogo.prototype = Object.create(window.PAYFIRMA.Image.prototype, {

});
