import angular from 'angular';
import d3 from 'd3';

angular
.module('d3')
.factory('d3Service', function ($document, $q, Utils, $rootScope) {
    var d = $q.defer();
    d.resolve(d3);
    Utils.forceScopeApply($rootScope);
    return {
        d3: function () {
            return d.promise;
        }
    };
});