'use strict';
import 'service/image-editing/models/Image';
import Constants from 'service/constants';

window.PAYFIRMA.MerchantLogo = function (merchantId) {
    this.defaultImageUrl = Constants.IMAGE_BASE_LOCATION + 'phq-profile-image.jpg';
    var baseUrl = 'receipt_images';
    var cachebuster = window.Math.round(new Date().getTime() / 1000);
    this.retrieveImageUrl = baseUrl + '/' + merchantId + '?cache=' + cachebuster;
    this.uploadImageUrl = baseUrl;
    this.deleteImageUrl = baseUrl;
};

window.PAYFIRMA.MerchantLogo.prototype = Object.create(window.PAYFIRMA.Image.prototype, {});