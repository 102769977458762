import angular from 'angular';
import BigNumber from 'bignumber.js';
import EventEmitterService from 'service/event-emitter-service';
import SessionStorageService from 'service/session-storage-service';
import 'script/app-init';
import 'script/constants-angular';
import 'service/service';
import 'script/app-controller';
import 'filter/filter';
import 'component/component';
import 'view/view';
import ConfigurationService from 'service/configuration-service';

/**
 * @ngdoc overview
 * @name payhqUIApp
 * @description # payhqUIApp
 *
 * Main module of the application.
 */

angular
    .module('payhqUIApp')
    .constant('permissions', {
        API_MASTER: 'APIMaster',
        CUSTOMER_VAULT: 'CustomerVault',
        MOBILE: 'MobileMaster',
        RECURRING: 'RecurringMaster',
        TABLET_POS: 'TabletPOSMaster',
        TRANSACTION_MASTER: 'MyTransactions_Master',
        WEB_TERMINAL: 'VTMaster',
        CARD_TERMINAL: 'CardTerminal',
        RECEIPT_SETTINGS: 'ReceiptSettings',
        INVOICE:'InvoiceMaster',
        EFT:'EFTMaster'
    })
    .constant('CURRENCY', {
        CA: 'CA',
        US: 'US'
    })
    .constant('CARDTYPE', {
        AMEX: 'amex',
        DISCOVER: 'discover',
        JCB: 'jcb',
        MASTERCARD: 'mastercard',
        VISA: 'visa'
    });


/**
 * Define app routes
 */
export default angular.module('payhqUIApp').config(['$translateProvider', function ($translateProvider) {
    $translateProvider.useStaticFilesLoader({
        prefix: 'languages/',
        suffix: '.json'
    });
    $translateProvider.preferredLanguage('en');
    $translateProvider.useLocalStorage();
    $translateProvider.useSanitizeValueStrategy('escaped');
}]);

angular.module('payhqUIApp').config(['$translateProvider', function ($translateProvider) {
    $translateProvider.useStaticFilesLoader({
        prefix: 'languages/',
        suffix: '.json'
    });
    $translateProvider.preferredLanguage('en');
    $translateProvider.useLocalStorage();
    $translateProvider.useSanitizeValueStrategy('escaped');
}]);

angular
.module('payhqUIApp')
.config(['$routeProvider',
    '$locationProvider',
    'permissions',
    'CustomerServiceProvider',
    'TransactionServiceProvider',
    'InvoiceServiceProvider',
    'SessionServiceProvider',
    'InternationalizationServiceProvider',
    'MerchantApplicationServiceProvider',
    'MerchantServiceProvider',
    'MerchantAccountRequestServiceProvider',
    'AgeVerificationServiceProvider',
    ($routeProvider,
$locationProvider,
permissions,
CustomerServiceProvider,
TransactionServiceProvider,
InvoiceServiceProvider,
SessionServiceProvider,
InternationalizationServiceProvider,
MerchantApplicationServiceProvider,
MerchantServiceProvider,
MerchantAccountRequestServiceProvider,
AgeVerificationServiceProvider) => {
        SessionStorageService.initialize(EventEmitterService, window.sessionStorage);

        window.PAYFIRMA = window.PAYFIRMA || {};

        window.PAYFIRMA.TransactionDataService =
            new window.PAYFIRMA.PaginationDataService(TransactionServiceProvider.$get(), true);

        window.PAYFIRMA.InvoiceDataService =
            new window.PAYFIRMA.PaginationDataService(InvoiceServiceProvider.$get(), true);

        window.PAYFIRMA.CustomerDataService =
            new window.PAYFIRMA.PaginationDataService(CustomerServiceProvider.$get());

        window.PAYFIRMA.MerchantAppDataService =
            new window.PAYFIRMA.PaginationDataService(MerchantApplicationServiceProvider.$get());

        window.PAYFIRMA.MerchantDataService =
            new window.PAYFIRMA.PaginationDataService(MerchantServiceProvider.$get());

        window.PAYFIRMA.MerchantAccountRequestService =
            new window.PAYFIRMA.PaginationDataService(MerchantAccountRequestServiceProvider.$get());

        window.PAYFIRMA.AgeVerificationDataService =
            new window.PAYFIRMA.PaginationDataService(AgeVerificationServiceProvider.$get());

        var universalResolves = {
            'session': function() {
                //TODO: we do not have a loading sequence control on application startup
                //so we have to call this in every single route change
                //initially i deleted this and put it in user-redirect-service, and i brought it back for now
                //need to refactor to call this only once on startup
                //@author: carlz
                //each call here generates its own copy of SessionService
                //we have to use $rootscope variables to synchronize the calls
                //I don't know if there is a way other than using rootScope
                return SessionServiceProvider.$get().initializeSessionWithPromise()
                    .then(function(permission){
                        return permission;
                    })
                    .catch(function(){
                        return undefined;
                    });
            },
            'translation': function () {
                return InternationalizationServiceProvider.$get().getTranslatedStrings();
            }
        };

        var customRouteProvider = angular.extend({}, $routeProvider, {
            when: function(path, route) {
                route.resolve = (route.resolve) ? route.resolve : {};

                angular.extend(route.resolve, universalResolves);

                $routeProvider.when(path, route);
                return this;

            }
        });

        customRouteProvider
        .when(window.PAYFIRMA.ROUTE.LOGIN, {
            template: '<login></login>'
        })
        .when(window.PAYFIRMA.ROUTE.RESET_PASSWORD, {
            template: '<reset-password></reset-password>'
        }).when(window.PAYFIRMA.ROUTE.CHANGE_OWN_PASSWORD, {
            template: '<change-own-password></change-own-password>'
        }).when(window.PAYFIRMA.ROUTE.PAY_INVOICE+':invoiceHash', {
            templateUrl: 'views/pay-invoice.html',
            resolve: {
                data: function($route) {
                    var invoiceHash = $route.current.params.invoiceHash;
                    return invoiceHash;
                }
            },
            template: '<pay-invoice data="$resolve.data"></pay-invoice>'

        }).when(window.PAYFIRMA.ROUTE.CARD_REQUEST+':customerHash', {
            resolve: {
                data: function($route) {
                    const customerHash = $route.current.params.customerHash;
                    return customerHash;
                }
            },
            template: '<card-request data="$resolve.data"></card-request>'
        })
    .when('/invoicing', {
        resolve: {
            initialData : function(InvoiceService, CurrentUserService, SettingsService) {
                // 1. get current user
                return window.PAYFIRMA.InvoiceDataService.getInitialData({})
                    .then(function (invoiceResponse) {
                        return {
                            settings: {
                                requiredFields: SettingsService.getRequiredFields()
                            },
                            invoices: invoiceResponse
                        };
                    }).catch(function () {
                        return {
                            settings: {},
                            invoices: []
                        };
                    });
            }
        },
        template: '<invoicing invoices="$resolve.initialData.invoices" '+
                  'settings="$resolve.initialData.settings"></invoicing>'
    })
    .when('/invoicing/create', {
        resolve: {
            initialData : function() {
                var invoice = null;
                return {
                    invoice_type: window.PAYFIRMA.CONSTANT.NEW_INVOICE,
                    invoice: invoice
                };
            }
        },
        template: '<invoicing-edit initialdata="$resolve.initialData"></invoicing-edit>'
    })
    .when('/invoicing/view-invoice/:invoiceId', {
        resolve: {
            data: function($route, InvoiceService) {
                var invoiceId = $route.current.params.invoiceId;
                return InvoiceService.getInvoice(invoiceId)
                .then(function(invoiceResponse){
                    return {
                        invoice: invoiceResponse.data
                    };
                });
            }
        },
        template: '<view-invoice data="$resolve.data"></view-invoice>'

    })
    .when('/draft-invoice/:invoiceId', {
        resolve: {
            initialData : function($route, InvoiceService) {
                var invoice = null;
                var invoiceId = $route.current.params.invoiceId;
                return InvoiceService.getInvoice(invoiceId)
                    .then(function(invoiceResponse){
                        invoice = invoiceResponse;
                        return {
                            invoice_type: window.PAYFIRMA.CONSTANT.DRAFT_INVOICE,
                            invoice: invoice
                        };
                    });
            }
        },
        template: '<invoicing-edit initialdata="$resolve.initialData"></invoicing-edit>'
    })
    .when('/clone-invoice/:invoiceId', {
        resolve: {
            initialData : function($route, InvoiceService) {
                var invoice = null;
                var invoiceId = $route.current.params.invoiceId;
                return InvoiceService.getInvoice(invoiceId)
                    .then(function(invoiceResponse){
                        invoice = invoiceResponse;
                        return {
                            invoice_type: window.PAYFIRMA.CONSTANT.CLONE_INVOICE,
                            invoice: invoice
                        };
                    });
            }
        },
        template: '<invoicing-edit initialdata="$resolve.initialData"></invoicing-edit>'
    })

    .when('/create-invoice/:custId', {
        resolve: {
            initialData : function($route, CustomerService) {

                var customerId = $route.current.params.custId;
                var vaultCustomer = null;
                return CustomerService.getCustomer(customerId)
                    .then(function(cusomterVaultResponse) {
                        vaultCustomer = cusomterVaultResponse;

                        return {
                            invoice_type: window.PAYFIRMA.CONSTANT.NEW_INVOICE,
                            vaultCustomer: vaultCustomer
                        };
                    });
            }
        },
        template: '<invoicing-edit initialdata="$resolve.initialData"></invoicing-edit>'
    })
    .when('/billing', {
        resolve: {
            bills : function(PlanService) {
                return PlanService.get().then(function(allPlansObject) {
                    return {
                        list: allPlansObject.entities,
                        paging: allPlansObject.paging,
                        isCurrentUser: true
                    };
                });
            }
        },
        template: '<billing bills="$resolve.bills.list", paging="$resolve.bills.paging"></billing>'
    })
    .when('/eft', {
        resolve: {
            eft : function(EFTService) {
                return EFTService.getIncomingRequests()
                .then(function (response) {
                    return response.data.incoming_requests;
                }).catch(function () {
                    return [];
                });
            }
        },
        template: '<eft list="$resolve.eft"></eft>'
    })
    .when('/new-reporting-system', {
        template: '<new-reporting-system></new-reporting-system>'
    })
    .when('/age-verification', {
        resolve: {
            age_verification: function(AgeVerificationService){
                return window.PAYFIRMA.AgeVerificationDataService.getInitialData({
                    searchParameters: AgeVerificationService.createInitialSearchParameters()
                })
                    .then(function(response) {
                        return response;
                    }).catch(function () {
                        return [];
                    });
            }
        },
        template: '<age-verification list="$resolve.age_verification"></age-verification>'
    })
    .when('/transactions', {
        resolve: {
            transactions : function(TransactionService){
                return window.PAYFIRMA.TransactionDataService.getInitialData({
                    searchParameters: TransactionService.createInitialSearchParameters()
                })
                    .then(function(transactionsResponse) {
                        return transactionsResponse;
                    });
            }

        },
        template: '<transactions list="$resolve.transactions"></transactions>'
    })
    .when('/transactions/customer/email/:customerEmail', {
        resolve: {
            initialData : function($route) {
                var customerEmail = $route.current.params.customerEmail;
                var searchParameters = {
                    email_address: customerEmail
                };
                return window.PAYFIRMA.TransactionDataService.getAllData({
                    searchParameters: searchParameters
                }).then(function (transactionsFromServer) {
                    return {
                        transactions: transactionsFromServer,
                        customerEmail: customerEmail
                    };
                });
            }
        },
        template: '<transactions list="$resolve.initialData.transactions" '+
                        'customer-email="$resolve.initialData.customerEmail"></transactions>'
    })
    .when('/transactions/customer/email/:customerEmail/recurring', {
        resolve: {
            initialData : function($route) {
                var customerEmail = $route.current.params.customerEmail;
                var searchParameters = {
                    email_address: $route.current.params.customerEmail,
                    channel:'RECURRING'
                };

                return window.PAYFIRMA.TransactionDataService.getAllData({
                    searchParameters: searchParameters
                })
                    .then(function(transactionsReponse) {
                        return {
                            transactions: transactionsReponse,
                            customerEmail: customerEmail,
                            channels: ['RECURRING']
                        };
                    });
            }
        },
        template: '<transactions list="$resolve.initialData.transactions" '+
        'customer-email="$resolve.initialData.customerEmail" '+
        'channels= "$resolve.initialData.channels"></transactions>'
    })
    .when('/transactions/customer/lookup/:customerLookupId/eft', {
        resolve: {
            initialData : function($route) {
                const customerLookupId = $route.current.params.customerLookupId;
                const searchParameters = {
                    customerLookupId,
                    channel:'EFT'
                };

                return window.PAYFIRMA.TransactionDataService.getAllData({
                    searchParameters: searchParameters
                })
                    .then(function(transactionsReponse) {
                        return {
                            transactions: transactionsReponse,
                            customerLookupId,
                            channels: ['EFT']
                        };
                    });
            }
        },
        template: '<transactions list="$resolve.initialData.transactions" '+
        'customer-lookup-id="$resolve.initialData.customerLookupId" '+
        'channels= "$resolve.initialData.channels"></transactions>'
    })
    .when('/transactions/eft/:eft_type?', {
        resolve: {
            initialData : function() {
                const searchParameters = {
                    channel:'EFT'
                };
                return window.PAYFIRMA.TransactionDataService.getAllData({
                    searchParameters: searchParameters
                })
                    .then(function(transactionsReponse) {
                        return {
                            transactions: transactionsReponse,
                            channels: ['EFT']
                        };
                    });
            }
        },
        template: '<transactions list="$resolve.initialData.transactions" '+
        'customer-lookup-id="$resolve.initialData.customerLookupId" '+
        'channels= "$resolve.initialData.channels"></transactions>'
    })
    .when('/terminal/:transaction_type?/:transaction_id?', {
        resolve: {
            settings : function($location, CustomerService, TransactionService,
                CustomerCardService, CurrentUserService, SettingsService, $route) {
                var requiredFields = null;
                var originalTransactionId = $route.current.params.transaction_id;
                var customerId = $route.current.params.customer;
                var cardId = $route.current.params.card;

                let promise = new Promise(function(resolve){
                    resolve(SettingsService.getRequiredFields());
                });

                return promise.then(function(requiredFieldsResponse) {
                    requiredFields = requiredFieldsResponse;
                    // If it contains original transaction ID
                    if (originalTransactionId) {
                        return TransactionService.getTransaction(originalTransactionId)
                        .then(function(transactionResponse) {
                            return {
                                requiredFields: requiredFields,
                                originalTransaction:transactionResponse
                            };
                        });
                    // else if it contains customer ID and card ID
                    } else if (customerId && cardId){
                        return CustomerService.getCustomer(customerId)
                        .then(function (customer){
                            if (!customer){
                                $location.url('terminal/sale');
                                return undefined;
                            } else {
                                let cardResponse = CustomerCardService.getCustomerCard(customer.cards, cardId);
                                return {
                                    customer: customer,
                                    creditCard: cardResponse,
                                    requiredFields: requiredFields
                                };
                            }
                        });
                    } else {
                        return {
                            requiredFields: requiredFields
                        };
                    }
                });
            },
            transactionType: function($route){
                return $route.current.params.transaction_type;
            }
        },
        template: '<terminal settings="$resolve.settings" transaction-type="$resolve.transactionType"></terminal>'
    })
    // TODO Vincent: Card Terminal
    .when('/cardterminal/:transaction_type?/:transaction_id?', {
        resolve: {
            settings : function($location, CustomerService, TransactionService,
                CustomerCardService, CurrentUserService, SettingsService, $route) {
                var requiredFields = null;
                var originalTransactionId = $route.current.params.transaction_id;
                var customerId = $route.current.params.customer;
                var cardId = $route.current.params.card;

                let promise = new Promise(function(resolve){
                    resolve(SettingsService.getRequiredFields());
                });

                return promise.then(function(requiredFieldsResponse) {
                    requiredFields = requiredFieldsResponse;
                    // If it contains original transaction ID
                    if (originalTransactionId) {
                        return TransactionService.getTransaction(originalTransactionId)
                        .then(function(transactionResponse) {
                            return {
                                requiredFields: requiredFields,
                                originalTransaction:transactionResponse
                            };
                        });
                    // else if it contains customer ID and card ID
                    } else if (customerId && cardId){
                        return CustomerService.getCustomer(customerId)
                        .then(function (customer){
                            if (!customer){
                                $location.url('cardterminal/sale');
                                return undefined;
                            } else {
                                let cardResponse = CustomerCardService.getCustomerCard(customer.cards, cardId);
                                return {
                                    customer: customer,
                                    creditCard: cardResponse,
                                    requiredFields: requiredFields
                                };
                            }
                        });
                    } else {
                        return {
                            requiredFields: requiredFields
                        };
                    }
                });
            },
            transactionType: function($route){
                return $route.current.params.transaction_type;
            }
        },
        template: '<cardterminal settings="$resolve.settings" transaction-type="$resolve.transactionType">'
        + '</cardterminal>'
    })
    .when('/merchant', {
        resolve: {
            merchants : function() {
                return window.PAYFIRMA.MerchantDataService.getInitialData()
                    .then(function(merchantsResponse) {
                        return merchantsResponse;
                    });
            }
        },

        template: '<merchant merchants=$resolve.merchants ></merchant>'
    })
    .when('/merchant/create', {
        template:'<merchant-edit></merchant-edit>'
    })
    .when('/merchant/create/:accountRequestId', {
        resolve: {
            accountRequest : function($route, MerchantAccountRequestService) {
                let accountRequestId = $route.current.params.accountRequestId;
                return MerchantAccountRequestService.getMerchantAccountRequest(accountRequestId)
                    .then(function(response) {
                        return response;
                    });
            }
        },
        template: '<merchant-edit account-request="$resolve.accountRequest"></merchant-edit>'
    })
    .when('/merchant/:merchantId', {
        resolve: {
            merchant : function($route, MerchantService) {
                var merchantId = $route.current.params.merchantId;
                return MerchantService.getMerchant(merchantId)
                    .then(function(merchantResponse) {
                        return merchantResponse;
                    });
            }
        },
        template:'<merchant-edit merchant="$resolve.merchant"></merchant-edit>'
    })
    .when('/merchant/:merchantId/users', {
        resolve: {
            users : function($route, UserService) {
                let merchantId = $route.current.params.merchantId;
                return UserService.getUsersByMerchant(merchantId)
                    .then(function(merchantUsers) {
                        return {
                            users: merchantUsers,
                            merchantId: merchantId
                        };
                    });
            }
        },
        template:'<merchant-users users="$resolve.users.users" merchant-id="$resolve.users.merchantId">'
        +'</merchant-users>'
    })
    .when('/merchant/:merchantId/user/:merchantUserId', {
        resolve: {
            user : function($route, UserService) {
                var merchantUserId = $route.current.params.merchantUserId;
                var merchantId = $route.current.params.merchantId;
                return UserService.getMerchantUser(merchantUserId, merchantId)
                    .then(function(merchantUserResponse) {
                        return merchantUserResponse;
                    });
            }
        },
        template: '<profile merchantuser=$resolve.user is-my-profile="false" ></profile>'
    })
    .when('/staff', {
        resolve: {
            staff : function(UserService) {
                return UserService.getAllUsers()
                    .then(function(userListResponse) {
                        return userListResponse;
                    });
            }
        },
        template: '<staff list="$resolve.staff"></staff>'
    })
    .when('/staff/create', {
        resolve: {
            staff : function() {
                let currentUser = SessionStorageService.get().getUser();
                return {
                    payfirmaInternal: (currentUser && (currentUser.isPayfirmaInternal()))
                };
            }
        },
        template:'<staff-edit staff="$resolve.staff"></staff-edit>'
    })
    .when('/staff/:userLookupId', {
        resolve: {
            initialData : function($route, UserService) {
                var userLookupId = $route.current.params.userLookupId;
                var user = null;
                return UserService.getUser(userLookupId)
                    .then(function(userResponse) {
                        user = userResponse;
                        let currentUser = SessionStorageService.get().getUser();
                        user.merchant_id = currentUser.business_information.id;
                        return {
                            user: user,
                            payfirmaInternal: (currentUser && (currentUser.isPayfirmaInternal()))
                        };
                    });
            }
        },
        template:'<staff-edit staff="$resolve.initialData"></staff-edit>'
    })
    .when('/dashboard', {
        template: '<dashboard></dashboard>'
    })
    .when('/customer-vault', {
        resolve: {
            customerList: function() {
                return window.PAYFIRMA.CustomerDataService.getInitialData()
                    .then(function(getCustomersResponse) {
                        return getCustomersResponse;
                    });
            }
        },
        template: '<customer-vault customer-list="$resolve.customerList"></customer-vault>'
    })
    .when('/customer-vault/edit/:custId', {
        template: '<customer-details customer="$resolve.customerData.customer"></customer-details>',
        resolve: {
            customerData : function($route, CustomerService) {
                return CustomerService.getVaultCustomer($route.current.params.custId, true)
                    .then(function(response) {
                        return {
                            customer: response
                        };
                    });
            }
        }
    })
    .when('/customer-vault/create', {
        template: '<customer-details is-creating-new-customer="true"></customer-details>'
    })
    .when('/customer-vault/recurring/:planLookupId', {
        resolve: {
            customerRecurringData: function($route, CustomerService) {
                var planLookupId = $route.current.params.planLookupId;
                return CustomerService.getCustomersByRecurringPlan(planLookupId)
                    .then(function(customersByrecurringResponse) {
                        return {
                            planLookupId  : planLookupId,
                            recurringCustomers: customersByrecurringResponse
                        };
                    });
            }
        },
        template: '<customer-vault-recurring customerrecurringdata="$resolve.customerRecurringData">'+
                  '</customer-vault-recurring>'
    })
    .when('/my-profile', {
        resolve: {
            user : function( CurrentUserService, UserPermissionService ) {
                var user = null;
                return CurrentUserService.getCurrentUser()
                    .then(function (response){
                        user = response;
                        return UserPermissionService.getUserPermissions(user.id);
                    }).then(function(permissionsResponse) {
                        return {
                            data: user,
                            permissions: permissionsResponse,
                            editMode:'my-profile'
                        };
                    });
            }
        },
        template: '<profile user=$resolve.user is-my-profile="true" ></profile>'
    })
    .when('/settings/:view', {
        resolve: {
            merchantSettings: function (CurrentUserService, SettingsService,
                                        TimeService, MerchantService, AuthService, SessionService, $log, $route, HppSettingService) {
                let promise = new Promise(function (resolve) {
                    resolve({
                        timezone: TimeService.getTimezone(),
                        requiredField: SettingsService.getRequiredFields()
                    });
                });

                if ($route.current.params.view.toLowerCase()==='ecommerce') {
                    // Prevent users to access ecommerce settings page with no permission
                    if (!SessionService.checkUserPermission('eCommerceMaster')) {
                        AuthService.logout().then( function () {
                            window.location.reload(true); //to get a new CSRF token
                        });
                    }
                    promise = promise.then(function (settings) {
                        return window.PAYFIRMA.MerchantAppDataService.getInitialData()
                            .then(function (merchantAppResponse) {
                                let settingsCopy = settings || {};
                                settingsCopy.merchantApplications = merchantAppResponse;
                                return settingsCopy;
                            })
                            .catch(function(error){
                                $log.error(error);
                                throw error;
                            });
                    });
                }

                if ($route.current.params.view.toLowerCase()==='hpp') {
                    promise = promise.then(function (settings) {
                        return HppSettingService.getHppSetting().then(function (response) {
                            let settingsCopy = settings || {};
                            settingsCopy.hppSettings = response;
                            return settingsCopy;
                        });
                    });
                    promise = promise.then(function (settings) {
                        return HppSettingService.getHppLinkAppSetting().then(function (response) {
                            let settingsCopy = settings || {};
                            settingsCopy.hppLinkAppSettings = response;
                            return settingsCopy;
                        });
                    });

                }

                return promise;
            },
            view: ($route) => {
                let view = {};
                let currentView = $route.current.params.view;
                if (currentView){
                    view[currentView] = true;
                } else {
                    view.receipt = true;
                }
                return view;
            }
        },
        template: '<settings merchant-settings="$resolve.merchantSettings" view ="$resolve.view"></settings>'
    })
    .when('/preview-theme-login', {
        template: '<preview-theme-login></preview-theme-login>'
    })
    .when('/reporting', {
        template: '<reporting></reporting>'
    }).when(window.PAYFIRMA.ROUTE.CIBC_SSO, {
        template: '<cibcsso></cibcsso>'
    })
    //removed /logout route, use AuthSErvice.logout instead to avoid possible
        //synchronization issue
    .when(window.PAYFIRMA.ROUTE.PAGE_NOT_FOUND, {
        template: '<page-not-found></page-not-found>'
    })
    .when(window.PAYFIRMA.ROUTE.PROSPECTS, {
        template: '<prospects></prospects>'
    })
    .otherwise({
        redirectTo: window.PAYFIRMA.ROUTE.LOGIN
    });
    }]);


/**
 * Localization support
 */
angular.module('payhqUIApp').value('localeSupported', [
    'en', 'fr'
]);


/**
 * Disable Angular ui-utils masking blurHandler
 */
angular.module('payhqUIApp').config(['uiMask.ConfigProvider', function(uiMaskConfigProvider) {
    uiMaskConfigProvider.clearOnBlur(false);
}]);

/**
 * Configure Restangular requests
 */
angular.module('payhqUIApp').config(function(RestangularProvider, HeapServiceProvider) {
    var config = ConfigurationService.getConfig();
    var hqApiUrl = config.hqApi;
    HeapServiceProvider.$get().heapLoad();
    RestangularProvider.setBaseUrl(hqApiUrl);
    RestangularProvider.addResponseInterceptor(function(data, operation, what, url, response) {
        var responseData = response.data;
        return responseData;
    });
    RestangularProvider.setResponseExtractor(function (response) {
        return response;
    });

});
/**
 * Configure ng-idle
 */
angular.module('payhqUIApp').config(function(IdleProvider) {
    IdleProvider.idle(900);
    IdleProvider.timeout(60);
});

// By Default Angular.js sets all negatives numbers within round brackets/parentheses/().
// ($12.34)
// This is because in bookkeeping, amounts owed are often represented by red numbers,
// or a number in parentheses, as an alternative notation to represent negative numbers.
// https://en.wikipedia.org/wiki/Negative_number
// Decorator Provider for Negative Numbers sidewide
// Now displays negative numbers with a minus symbol at the start:
// -$12.34

/**
 * Run the main app
 */
angular.module('payhqUIApp').run(function ($rootScope,
                                           $location,
                                           $cookieStore,
                                           $http,
                                           Restangular,
                                           AuthService,
                                           UserPermissionService,
                                           SessionService,
                                           locale,
                                           permissions,
                                           $translate,
                                           IdleCheckService,
                                           UserRedirectionService,
                                           OauthTokenRefreshInterceptorService,
                                           HttpAuthErrorInterceptorService) {
    BigNumber.config({ DECIMAL_PLACES: 2, ROUNDING_MODE: 4 });
    //to group all of the changes triggered by $locationChangeStart together
    OauthTokenRefreshInterceptorService.init();
    HttpAuthErrorInterceptorService.initialize();
    function requireSessionCheck() {
        return SessionService.requireSessionCheck($location.path());
    }
    function performRouteChangeChecks() {
        // Redirect to login page if not logged in
        var isPayInvoiceORCardRequest = false;
        $rootScope.hideNavigation = false;

        if ($location.path().search('pay-invoice') === 1 ||
        $location.path().search('card-request') === 1) {
            isPayInvoiceORCardRequest = true;
            $rootScope.hideNavigation = true;
        }

        if (isPayInvoiceORCardRequest !== true && requireSessionCheck() && !AuthService.isAuthenticated()) {
            SessionService.deleteAllStoredLogInSessionsAndRedirectToLogin();
        }
    }

    IdleCheckService.initialize();
    UserRedirectionService.initialize();
    SessionService.restoreLoggedinSession();
    performRouteChangeChecks();
});