import angular from 'angular';
import 'script/app-init';

angular
    .module('payhqUIApp')
    .constant('permissions', {
        API_MASTER: 'APIMaster',
        CUSTOMER_VAULT: 'CustomerVault',
        MOBILE: 'MobileMaster',
        RECURRING: 'RecurringMaster',
        TABLET_POS: 'TabletPOSMaster',
        TRANSACTION_MASTER: 'MyTransactions_Master',
        WEB_TERMINAL: 'VTMaster',
        CARD_TERMINAL: 'CardTerminal',
        RECEIPT_SETTINGS: 'ReceiptSettings',
        INVOICE:'InvoiceMaster',
        EFT:'EFTMaster'
    })
    .constant('CURRENCY', {
        CA: 'CA',
        US: 'US'
    })
    .constant('CARDTYPE', {
        AMEX: 'amex',
        DISCOVER: 'discover',
        JCB: 'jcb',
        MASTERCARD: 'mastercard',
        VISA: 'visa'
    })
    .constant('DateFormat', {
        DISPLAY_FORMAT: 'MMM DD, YYYY',
        DEFAULT_VALUE_FORMAT: 'YYYY-MM-DD',
        VALUE_FORMAT_WITH_SLASH: 'MM/DD/YYYY'
    })
    .constant('TimeFormat', {
        DISPLAY_FORMAT: 'h:mm A'
    })
    .constant('HeapMessage', {
        TYPE_CUSTOMER_VAULT: 'Customer Vault',
        TYPE_STAFF: 'Staff',
        TYPE_RECURRING: 'Recurring',
        TYPE_INVOICE: 'Invoice',
        TYPE_SESSION_DURATION: 'Session Duration',
        MESSAGE_NEW_PROFILE: 'New Profile Created',
        MESSAGE_CARD_ADDED: 'Card Added',
        MESSAGE_SUBSCRIBER_ADDED: 'Subscriber Added',
        MESSAGE_STAFF_CREATED: 'Staff Created',
        MESSAGE_INVOICE_SENT: 'Invoice Created and Sent',
        MESSAGE_RECURRING_ADDED: 'Recurring Plan Created'
    })
    .constant('HEAP_EVENT', {
        TERMINAL_TAB_SALE: 'heap-terminal-tab-sale',
        TERMINAL_TAB_AUTHORIZE: 'heap-terminal-tab-authorize',
        TERMINAL_TAB_CAPTURE: 'heap-terminal-tab-capture',
        TERMINAL_TAB_REFUND: 'heap-terminal-tab-refund',
        TERMINAL_SALE: 'heap-terminal-sale',
        TERMINAL_AUTHORIZE: 'heap-terminal-authorize',
        TERMINAL_CAPTURE: 'heap-terminal-capture',
        TERMINAL_REFUND: 'heap-terminal-refund',
        TERMINAL_MORE_DETAILS: 'heap-terminal-more-details',
        TERMINAL_LESS_DETAILS: 'heap-terminal-less-details',
        TRANSACTIONS_LESS_DETAILS: 'heap-transactions-less-details',
        TRANSACTIONS_MORE_DETAILS: 'heap-transactions-more-details',
        TRANSACTIONS: {
            MORE_DETAILS: 'heap-transactions-more-details',
            LESS_DETAILS: 'heap-transactions-less-details',
            SEND_RECEIPT: 'heap-transactions-send-receipt',
            PRINT_RECEIPT: 'heap-transactions-print-receipt',
            CAPTURE_PAYMENT: 'heap-transactions-capture-payment'
        },
        NAVIGATION: {
            LOGO: 'heap-navigation-logo',
            COMPACT: 'heap-navigation-compact',
            EXPAND: 'heap-navigation-expand'
        }
    })
    .constant('PROVINCES',[
        { 'country': 'United States', 'province' : 'Alaska' },
        { 'country': 'United States', 'province' : 'American Samoa' },
        { 'country': 'United States', 'province' : 'Arizona' },
        { 'country': 'United States', 'province' : 'Arkansas' },
        { 'country': 'United States', 'province' : 'California' },
        { 'country': 'United States', 'province' : 'Colorado' },
        { 'country': 'United States', 'province' : 'Connecticut' },
        { 'country': 'United States', 'province' : 'Delaware' },
        { 'country': 'United States', 'province' : 'District Of Columbia' },
        { 'country': 'United States', 'province' : 'Micronesia' },
        { 'country': 'United States', 'province' : 'Florida' },
        { 'country': 'United States', 'province' : 'Georgia' },
        { 'country': 'United States', 'province' : 'Guam' },
        { 'country': 'United States', 'province' : 'Hawaii' },
        { 'country': 'United States', 'province' : 'Idaho' },
        { 'country': 'United States', 'province' : 'Illinois' },
        { 'country': 'United States', 'province' : 'Indiana' },
        { 'country': 'United States', 'province' : 'Iowa' },
        { 'country': 'United States', 'province' : 'Kansas' },
        { 'country': 'United States', 'province' : 'Kentucky' },
        { 'country': 'United States', 'province' : 'Louisiana' },
        { 'country': 'United States', 'province' : 'Maine' },
        { 'country': 'United States', 'province' : 'Marshall Islands' },
        { 'country': 'United States', 'province' : 'Maryland' },
        { 'country': 'United States', 'province' : 'Massachusetts' },
        { 'country': 'United States', 'province' : 'Michigan' },
        { 'country': 'United States', 'province' : 'Minnesota' },
        { 'country': 'United States', 'province' : 'Mississippi' },
        { 'country': 'United States', 'province' : 'Missouri' },
        { 'country': 'United States', 'province' : 'Montana' },
        { 'country': 'United States', 'province' : 'Nebraska' },
        { 'country': 'United States', 'province' : 'Nevada' },
        { 'country': 'United States', 'province' : 'New Hampshire' },
        { 'country': 'United States', 'province' : 'New Jersey' },
        { 'country': 'United States', 'province' : 'New Mexico' },
        { 'country': 'United States', 'province' : 'New York' },
        { 'country': 'United States', 'province' : 'North Carolina' },
        { 'country': 'United States', 'province' : 'North Dakota' },
        { 'country': 'United States', 'province' : 'Northern Mariana Islands' },
        { 'country': 'United States', 'province' : 'Ohio' },
        { 'country': 'United States', 'province' : 'Oklahoma' },
        { 'country': 'United States', 'province' : 'Oregon' },
        { 'country': 'United States', 'province' : 'Palau' },
        { 'country': 'United States', 'province' : 'Pennsylvania' },
        { 'country': 'United States', 'province' : 'Puerto Rico' },
        { 'country': 'United States', 'province' : 'Rhode Island' },
        { 'country': 'United States', 'province' : 'South Carolina' },
        { 'country': 'United States', 'province' : 'South Dakota' },
        { 'country': 'United States', 'province' : 'Tennessee' },
        { 'country': 'United States', 'province' : 'Texas' },
        { 'country': 'United States', 'province' : 'Utah' },
        { 'country': 'United States', 'province' : 'Vermont' },
        { 'country': 'United States', 'province' : 'Virgin Islands' },
        { 'country': 'United States', 'province' : 'Virginia' },
        { 'country': 'United States', 'province' : 'Washington' },
        { 'country': 'United States', 'province' : 'West Virginia' },
        { 'country': 'United States', 'province' : 'Wisconsin' },
        { 'country': 'United States', 'province' : 'Wyoming' },
        { 'country': 'Canada', 'province' : 'Ontario' },
        { 'country': 'Canada', 'province' : 'Quebec' },
        { 'country': 'Canada', 'province' : 'British Columbia' },
        { 'country': 'Canada', 'province' : 'Alberta' },
        { 'country': 'Canada', 'province' : 'Manitoba' },
        { 'country': 'Canada', 'province' : 'Saskatchewan' },
        { 'country': 'Canada', 'province' : 'Nova Scotia' },
        { 'country': 'Canada', 'province' : 'New Brunswick' },
        { 'country': 'Canada', 'province' : 'Newfoundland and Labrador' },
        { 'country': 'Canada', 'province' : 'Prince Edward Island' },
        { 'country': 'Canada', 'province' : 'Northwest Territories' },
        { 'country': 'Canada', 'province' : 'Yukon' },
        { 'country': 'Canada', 'province' : 'Nunavut' }
    ]).constant('TIMEZONE_OFFSET',[
        {
            'key': 'Alaska Time - Anchorage, Juneau',
            'value': 'AKST',
            'zone': 'America/Juneau'
        },
        {
            'key': 'Atlantic Time - Halifax',
            'value': 'AST',
            'zone': 'America/Halifax'
        },
        {
            'key': 'Central Time - Regina',
            'value': 'CST',
            'zone': 'America/Regina'
        },
        {
            'key': 'Central Time - Chicago, Dallas, Winnipeg',
            'value': 'CDT',
            'zone': 'America/Chicago'
        },
        {
            'key': 'Hawaii Time - Honolulu',
            'value': 'HAST',
            'zone': 'Pacific/Honolulu'
        },
        {
            'key': 'Eastern Time - Miami, New York, Ottawa',
            'value': 'EST',
            'zone': 'America/New_York'
        },
        {
            'key': 'Mountain Time - Calgary, Denver',
            'value': 'MDT',
            'zone': 'America/Denver'
        },
        {
            'key': 'Mountain Time - Phoenix',
            'value': 'MST',
            'zone': 'America/Phoenix'
        },
        {
            'key': 'Newfoundland Time - St. Johns',
            'value': 'NST',
            'zone': 'America/St_Johns'
        },
        {
            'key': 'Pacific Time - Los Angeles, Seattle, Vancouver',
            'value': 'PST',
            'zone': 'America/Los_Angeles'
        }
    ])
    .constant('LANGUAGES',[
        {
            'key': 'English',
            'value': 'ENGLISH'
        },
        {
            'key': 'French',
            'value': 'FRENCH'
        }
    ])
    .constant('REGEX', {
        SPECIAL_CHARACTER: /[$&+,:;=?@#|'<>.^*()%!\-\}\{\"]/,
        INTERGER: /[0-9]/,
        UPPERCASE_LETTER: /[A-Z]/,
        LOWERCASE_LETTER: /[a-z]/,
        MINIMUM_CHARACTERS: /^.{8,}$/,
        CARD_MASTERCARD: /^5[1-5]/,
        CARD_VISA: /^4/,
        CARD_AMERICAN_EXPRESS: /^3[47]/,
        CARD_DISCOVER: /^6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5]))/,
        CARD_JCB: /^35|800|2131/
    });