import angular from 'angular';

function paperTransactionsourceFilter($element, SessionService, permissions) {
    const TRANSACTION_SOURCE = 'Transaction Source';

    const webTerminal = 'web';
    const cardTerminal = 'cardTerminal';
    const mobile = 'mobile';
    const tablet = 'tablet';
    const ecommerce = 'ecommerce';
    const recurring = 'recurring';
    const invoicing = 'invoicing';
    const EFT = 'EFT';

    var _this = this;
    _this.isDropped = false;
    _this.isLoading = false;

    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function(){
        if (_this.isDropped === true){
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });

    $element.on('click', function(e){
        if (_this.isDropped === true){
            $('dropdown').show();
        }
        e.stopPropagation();
    });
    _this.allowedProducts = {
        web: SessionService.checkUserPermission(permissions.WEB_TERMINAL),
        mobile: SessionService.checkUserPermission(permissions.MOBILE),
        tablet: SessionService.checkUserPermission(permissions.TABLET_POS),
        ecommerce: SessionService.checkUserPermission(permissions.API_MASTER),
        recurring: SessionService.checkUserPermission(permissions.RECURRING),
        invoicing: SessionService.checkUserPermission(permissions.INVOICE),
        cardTerminal: SessionService.checkUserPermission(permissions.CARD_TERMINAL),
        EFT: SessionService.checkUserPermission(permissions.EFT)
    };

    _this.transactionSourceList = [];
    _this.transactionSourceList.push({ name: 'All', key: 0 });

    if (_this.allowedProducts[webTerminal]) {
        _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.WEBTERMINAL, key: 1 });
    }

    if (_this.allowedProducts[cardTerminal]) {
        _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.CARDTERMINAL, key: 11 });
    }

    if (_this.allowedProducts[mobile]) {
        _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.MOBILE, key: 3 });
    }

    if (_this.allowedProducts[tablet]) {
        _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.TABLETPOS, key: 4 });
    }

    if (_this.allowedProducts[ecommerce]) {
        _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.ECOMMERCE, key: 6 });
    }

    if (_this.allowedProducts[recurring]) {
        _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.RECURRING, key: 7 });
    }

    if (_this.allowedProducts[invoicing]) {
        _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.INVOICING, key: 10 });
    }

    _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.HPP, key: 12 });

    if (_this.allowedProducts[EFT]) {
        _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.EFT, key: 13 });
    }

    _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.SHOPIFY, key: 16 });

    _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.IFRAME, key: 14 });

    _this.transactionSourceList.push({ name: window.PAYFIRMA.TEXT_STRINGS.PAY_SUBSCRIPTION, key: 15 });

    _this.setSelectedTransactionSource = function (transactionSource) {
        _this.title = transactionSource.name;
        _this.filtertransactionsource = transactionSource.key;
        _this.doDrop(false);
    };

    function initFilter() {
        _this.title = TRANSACTION_SOURCE;
    }

    initFilter();
}

angular
.module('payhqUIApp')
.component('paperTransactionsourceFilter', {
    bindings: {
        filtertransactionsource: '='
    },
    controller: paperTransactionsourceFilter,
    controllerAs: 'paperTransactionsourceFilter',
    templateUrl: 'component/paper-filter/paper-transactionsource-filter/paper-transactionsource-filter.html'
});