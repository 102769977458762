/* @flow */

import angular from 'angular';
import _ from 'lodash';

angular.module('payhqUIApp')
    .service('OauthTokenRefreshInterceptorService', function ($rootScope, authService,
                                                              OauthTokenService,
                                                              $log, SessionService) {

        const tokenExpiredMessageNewFormat = 'The access_token is expired';

        function forwardRejection(data) {
            authService.loginCancelled(undefined, data);
            window.PAYFIRMA.CommunicationService.triggerHttpAuthErrorEvent(data);
        }

        // New error format
        // "{"data":{"status":401,"message":"API Gateway Service Error","errors":[{"code":"UNAUTHORIZED",
        // "message":"The access_token is expired."}],"uuid":"f363136e-924b-4eae-bfac-00c32d00d0ca"}"
        function isTokenExpiredWithNewErrorFormat(error) {
            //check error body
            let hasErrors =
                error && error.data && error.data.status === 401 && error.data.errors && error.data.errors.length;

            if (hasErrors) {
                let errors = error.data.errors;
                return _.find(errors, function (tempError) {
                    return tempError.message && tempError.message.indexOf(tokenExpiredMessageNewFormat) !== -1;
                });
            }

            return false;
        }

        // Old error format
        // "{"data":{"error":"invalid_token","error_description":"Access token expired:a604cc5d-5292-42de"}"
        function isTokenExpiredWithOldErrorFormat(error) {
            const tokenExpiredMessageOldFormat = 'Access token expired';
            return error && error.status === 401 && error.data && error.data.error_description &&
                error.data.error_description.indexOf(tokenExpiredMessageOldFormat) !== -1;
        }

        function isTokenExpiredWithStatusText(error) {
            //check error status text
            let hasErrors =
                error && error.status === 401 && error.statusText;

            if (hasErrors) {
                let errorMessage = error.statusText;
                return errorMessage.toLowerCase().indexOf(tokenExpiredMessageNewFormat.toLowerCase()) !== -1;
            }

            return false;
        }

        function isTokenExpired(error) {
            return (isTokenExpiredWithNewErrorFormat(error)
                || isTokenExpiredWithStatusText(error)
                || isTokenExpiredWithOldErrorFormat(error))
                && !SessionService.isLoggedInAsUser();
        }

        return {

            isTokenExpired: function (error) {
                return isTokenExpired(error);
            },

            init: function () {
                $rootScope.$on('event:auth-loginRequired', function (event, data) {
                    if (!isTokenExpired(data)) {
                        forwardRejection(data);
                    } else {
                        OauthTokenService.refreshTokenAndUpdateSession()
                            .then(function () {
                                authService.loginConfirmed('success', function (config) {
                                    var authHeader = SessionService.getAuthorizationHeader();
                                    config.headers = config.headers ?
                                        _.extend(config.headers, authHeader) : authHeader;
                                    return config;
                                });
                            })
                            .catch(function (error) {
                                $log.error(error);
                                forwardRejection(error);
                            });
                    }
                });

                $rootScope.$on('event:auth-forbidden', function (event, data) {
                    forwardRejection(data);
                });
            }
        };

    });