import angular from 'angular';
import USER_GROUP from 'constant/user-group';
import SessionStorageService from 'service/session-storage-service';

function paperUsergroupFilter($element) {

    var _this = this;

    _this.isDropped = false;

    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function(){
        if (_this.isDropped === true){
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });

    $element.on('click' ,function(e){
        if (_this.isDropped === true){
            $('dropdown').show();
        }
        e.stopPropagation();
    });

    var defaultTitle=window.PAYFIRMA.TEXT_STRINGS.USER_GROUP;
    var defaultSelectedUserGroup = [USER_GROUP.ADMIN, USER_GROUP.REPORTING,
        USER_GROUP.SALES];
    _this.userGroupList = [
        { name: window.PAYFIRMA.TEXT_STRINGS.ADMIN,group_id:USER_GROUP.ADMIN },
        { name: window.PAYFIRMA.TEXT_STRINGS.REPORTING,group_id:USER_GROUP.REPORTING },
        { name: window.PAYFIRMA.TEXT_STRINGS.SALES,group_id:USER_GROUP.SALES }
    ];

    _this.selectedUserGroup = defaultSelectedUserGroup;

    _this.setSelectedUserGroup = function (userGroup) {
        var groupId = userGroup.group_id;

        if (userGroup.userGroupSelected) {
            _this.selectedUserGroup.push(groupId);
            userGroup.userGroupSelected = true;
        } else if (_.includes(_this.selectedUserGroup, groupId)) {
            _this.selectedUserGroup = _.without(_this.selectedUserGroup, groupId);
            userGroup.userGroupSelected = false;
        }

        if (_this.selectedUserGroup.length === 0) {
            _this.title = defaultTitle;
            _this.filterusergroup = { user_group: defaultSelectedUserGroup };
        } else {
            _this.title = defaultTitle + ' (' + _this.selectedUserGroup.length + ')';
            _this.filterusergroup = { user_group: _this.selectedUserGroup };
        }
    };

    function initUserGroupFilter() {
        angular.forEach(_this.userGroupList, function (userGroup) {
            userGroup.userGroupSelected = true;
        });
        _this.filterusergroup = { user_group: _this.selectedUserGroup };
        _this.title = defaultTitle + ' (' + _this.userGroupList.length + ')';
    }

    if (SessionStorageService.get().getUser().isPayfirmaAdmin()){
        defaultSelectedUserGroup.push(USER_GROUP.PAYFIRMA_ADMIN);
        _this.userGroupList
            .push({ name: window.PAYFIRMA.TEXT_STRINGS.PAYFIRMA_ADMIN,
                group_id:USER_GROUP.PAYFIRMA_ADMIN });
    }
    initUserGroupFilter();
}

angular
.module('payhqUIApp')
.component('paperUsergroupFilter', {
    bindings: {
        filterusergroup: '='
    },
    controller: paperUsergroupFilter,
    controllerAs: 'paperUsergroupFilter',
    templateUrl: 'component/paper-filter/paper-usergroup-filter/paper-usergroup-filter.html'
});