import angular from 'angular';
/**
 *  view : <paper-currency-filter filtercurrency='filtercurrency'></paper-currency-filter>
 *  set filter on ng-repeat : customFilter:filtercurrency
 */
function paperCurrencyFilter($element, SessionService) {

    var _this = this;

    _this.isDropped = false;


    _this.isLoading = false;

    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function(){
        if (_this.isDropped === true){
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });

    $element.on('click', function(e){
        if (_this.isDropped === true){
            $('dropdown').show();
        }
        e.stopPropagation();
    });

    var defaultTitle = 'CAD';
    var defaultKey = 'CAD';

    // Currency Toggle
    // Get the merchant's gateways
    // - set to CA$ by default if the merchant has both CA$ and US$
    var gateways = SessionService.getCurrentMerchantGateways();
    if (gateways && gateways.length) {
        var currenciesAvailable = gateways.length;
        if (currenciesAvailable === 1) {
            if (gateways[0].currency_id === 1) {
                defaultTitle = 'CAD';
                defaultKey = 'CAD';
            } else if (gateways[0].currency_id === 2) {
                defaultTitle = 'USD';
                defaultKey = 'USD';
            }
        } else if (currenciesAvailable === 2) {
            var tempList = [];
            angular.forEach(gateways, function(value) {
                // If processor ID exists
                if (value.processor_id) {
                    tempList.push({
                        'key': value.currency,
                        'value': value.currency
                    });
                }
                if (tempList.length === 1 && tempList[0].value === 'USD') {
                    defaultTitle = tempList[0].value;
                    defaultKey = tempList[0].value;
                }
            });
        }
    }
    _this.currencyList = [{ name: 'CAD',key:'CAD' }, { name: 'USD',key:'USD' }];
    _this.setSelectedCurrency = function (currency) {
        _this.title = currency.name;
        _this.filtercurrency = currency.key;
        _this.doDrop(false);
    };
    function initFilter() {
        _this.title = defaultTitle;
        _this.filtercurrency = defaultKey;
    }
    initFilter();
}

angular
.module('payhqUIApp')
.component('paperCurrencyFilter', {
    bindings: {
        filtercurrency: '='
    },
    controller: paperCurrencyFilter,
    controllerAs: 'paperCurrencyFilter',
    templateUrl: 'component/paper-filter/paper-currency-filter/paper-currency-filter.html'
});