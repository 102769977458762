let LOCAL_DEV_CONFIG = {
    hq_api: 'https://dev-api.payfirma.com:2443/',
    hq_resetPassword_domain: 'dev-hq.payfirma.com/#',
    hq_jwt_token_url: 'https://dev-api.payfirma.com:6100/',
    jwt_client_id: 'fc47a4dc1ec381c321f98312fe0444c0',
    pay_invoice_secret: 'SU5WT0lDRV9FTUFJTFNfQVJFX1dPUktJTkc=',
    invoice_api: 'https://dev-api.payfirma.com:6443/',
    file_management_service_api: 'https://dev-api.payfirma.com:6443/file-management-service/',
    file_management_service_public_api: 'https://dev-api.payfirma.com:6200/',
    heap_id: '2167414450',
    plan_service_api : 'https://dev-api.payfirma.com:6443/plan-service/',
    customer_service_api: 'https://dev-api.payfirma.com:6443/customer-service/',
    transaction_service_api: 'https://dev-api.payfirma.com:6443/transaction-service-vt/',
    analytics_service_api: 'https://dev-api.payfirma.com:6443/analytics-service/',
    merchant_service_api: 'https://dev-api.payfirma.com:6443/merchant-service/',
    merchant_service_public_api: 'https://dev-api.payfirma.com:7500/',
    cibc_oidc_url: 'https://rapi.env1.cmo.cibc.com/isam/oidc/endpoint/amapp-runtime-cmo/authorize',
    cibc_oidc_client_id: 'L9iu4yJKHBzGNz8usbQe',
    cibc_auth_redirect_url: 'https://oidc.payfirma.com/cibc',
    hpp_service_api: 'https://dev-api.payfirma.com:6443/merchant-service/hpp/init',
    catpcha_site_key: '6LcnVMgeAAAAAMZelQH6j_BM3qAsD61aWyA0A0iU',
    reporting_system_url: 'https://dev.payfirma.com:3000/login',
    graphql_service_api: 'https://dev-api.payfirma.com:6443/graphql-service/graphql'
};
export default LOCAL_DEV_CONFIG;
