'use strict';

window.PAYFIRMA = window.PAYFIRMA || {};

window.PAYFIRMA.Paging = function (cursor, list) {
    var _this = this;

    if (list) {
        _this.content = list;
    }

    if (cursor) {
        _this.before = cursor.before;
        _this.after = cursor.after;
        _this.cursorInitialized = true;
    }

    _this.nextCursor = function () {
        return _this.after;
    };

    _this.previousCursor = function () {
        return _this.before;
    };
};
