import angular from 'angular';

function refreshApikeyConfirmation() {
    const REFRESH_KEYWORD = 'REFRESH';
    let _this = this;
    _this.isModalEnabled = false;

    function cleanup() {
        _this.apiKey = undefined;
        _this.refreshInput = undefined;
        _this.isModalEnabled = false;
    }

    window.PAYFIRMA.CommunicationService.onApiKeyRefreshEvent(function (apiKey) {
        _this.apiKey = apiKey;
        _this.isModalEnabled = true;
    });

    _this.cancel = function () {
        cleanup();
    };

    _this.isRefreshTyped = function () {
        return _this.refreshInput === REFRESH_KEYWORD;
    };

    _this.refresh = function () {
        if (_this.isRefreshTyped()) {
            window.PAYFIRMA.CommunicationService.triggerRefreshApiKeyConfirmationEvent(_this.apiKey);
            cleanup();
        }
    };
}

angular
.module('payhqUIApp')
.component('refreshApikeyConfirmation', {
    templateUrl: 'view/settings/ecommerce-settings/modify-apikey/refresh-apikey-confirmation.html',
    controller: refreshApikeyConfirmation,
    controllerAs: 'refreshApikeyConfirmation'
});
