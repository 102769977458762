import angular from 'angular';

angular.module('customerApp', []);
angular.module('recurringApp', []);
angular.module('transactionApp', []);
angular.module('analyticsApp', []);
angular.module('userApp', []);
angular.module('oracleApp', []);
angular.module('settingsApp', []);
angular.module('invoiceApp', []);
angular.module('d3', []);
