import angular from 'angular';
/**
 *  view : <paper-location-filter filterlocation='filterlocation'></paper-location-filter>
 *  set filter on ng-repeat : customFilter:filterlocation
 *  $scope.countryTypeRegx = '^$|CA|Canada|canada|US|USA|usa';
 */
function paperLocationFilter($element) {

    var _this = this;

    _this.isDropped = false;

    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function(){
        if (_this.isDropped === true){
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });

    $element.on('click', function(e){
        if (_this.isDropped === true){
            $('dropdown').show();
        }
        e.stopPropagation();
    });

    var defaultTitle = window.PAYFIRMA.TEXT_STRINGS.LOCATION;
    var defaultSelected = ['Canada', 'US', ''];

    _this.title = defaultTitle + ' ('+ defaultSelected.length + ')';

    _this.selectedLocation = defaultSelected;
    _this.locationList = [{ name: window.PAYFIRMA.TEXT_STRINGS.CANADA, key: 'Canada' },
    { name: window.PAYFIRMA.TEXT_STRINGS.US, key: 'US' }, { name: window.PAYFIRMA.TEXT_STRINGS.UNKNOWN, key: '' }];

    _this.setSelectLocation = function(location){

        if (location.locationSelected) {
            _this.selectedLocation.push(location.key);
            location.locationSelected = true;
        } else if (_.includes(_this.selectedLocation, location.key)) {
            _this.selectedLocation = _.without(_this.selectedLocation, location.key);
            location.locationSelected = false;
        }

        if (_this.selectedLocation.length === 0){
            _this.title = defaultTitle;
            _this.filterlocation = { country: defaultSelected };

        } else {
            _this.title = defaultTitle + ' (' + _this.selectedLocation.length + ')';
            _this.filterlocation = { country: _this.selectedLocation };
        }
    };

    function initFilter() {
        angular.forEach(_this.locationList, function (location) {
            location.locationSelected = true;
        });
        _this.filterlocation = { country: _this.selectedLocation };
    }

    initFilter();
}


angular
.module('payhqUIApp')
.component('paperLocationFilter', {
    bindings: {
        filterlocation: '='
    },
    controller: paperLocationFilter,
    controllerAs: 'paperLocationFilter',
    templateUrl: 'component/paper-filter/paper-location-filter/paper-location-filter.html'
});