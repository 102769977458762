import angular from 'angular';
import SessionStorageService from 'service/session-storage-service';
import ConfigurationService from 'service/configuration-service';
import _ from 'lodash';

var updateEFTTransactionInterval = null;

angular
.module('payhqUIApp')
.service('SessionService', function (UserPermissionService,
    Restangular, LocalStorageService, $http, CurrentUserService, Utils, SettingsService, $route,
                                     TransactionEndpointService, EFTService, $injector, $location) {

    let userPermissions;

    var _this = this;
    _this.merchantGateways = null;

    function updateTotalIncomingRequestAndStatus() {
        EFTService.updateStatus();
        EFTService.getIncomingRequests().then(function (response) {
            window.PAYFIRMA.CommunicationService.triggerTotalIncomingRequestEvent(response.data.incoming_requests.length);
        });
    }

    function initializeSession() {

        let user;

        if (_this.isLoggedIn()) {

            let hqconfig = ConfigurationService.getConfig();
            Restangular.setBaseUrl(hqconfig.hqApi);

            return CurrentUserService.getCurrentUser()
                .then((currentResponse) => {

                    if (!currentResponse) {
                        return undefined;
                    } else {

                        user = currentResponse;

                        // update eft transactions every 10 mins if there is permission
                        if (user.permissions.find(permission => permission === 'EFTMaster') && !updateEFTTransactionInterval) {
                            // To call first time after login
                            updateTotalIncomingRequestAndStatus();
                            updateEFTTransactionInterval = setInterval(() => {
                                updateTotalIncomingRequestAndStatus();
                            }, 1000 * 60 * 10);
                        }

                        return SettingsService.initSettings()
                            .then(() => {
                                _this.merchantGateways = user.currencies;
                                userPermissions = user.permissions;
                                return userPermissions;
                            })
                            .catch(function (e) {
                                throw e;
                            });
                    }
                }).catch((errorResponse) => {
                    throw errorResponse;
                });
        }
        return undefined;
    }

    _this.initializeSessionWithPromise = () => {
        var promise;
        //convert initializeSession into
        return new Promise(function (resolve, reject) {
            if (userPermissions) {
                resolve(userPermissions);
            } else {
                promise = initializeSession();
                if (promise) {
                    promise
                        .then(function (perm) {
                            if (perm) {
                                resolve(perm);
                            } else {
                                reject(new Error('Cannot get permission'));
                            }
                        })
                        .catch(function(error){
                            reject(error);
                        });
                } else {
                    reject(new Error('Cannot initialize session'));
                }
            }
        });
    };

    /**
     * Check to see if the current user has a specific permission
     *
     * @return {boolean} True if has permission.  False otherwise.
     */
    _this.checkUserPermission = function (permission) {
        return (!(!userPermissions ||
        userPermissions.indexOf(permission) === -1));
    };

    _this.getCurrentMerchantDefaultCurrency = function () {
        // Get the merchant's gateways
        // - set to CA$ by default if the merchant has both CA$ and US$
        const user = SessionStorageService.get().getUser();
        var defaultCurrency;
        if (user && user.currencies){
            for (let index = 0; index < user.currencies.length; ++index) {
                var currencyLabel = user.currencies[index];
                if (currencyLabel === 'CAD') {
                    defaultCurrency = window.PAYFIRMA.CONSTANT.CURRENCY.CAD;

                }
                if (currencyLabel === 'USD' && !defaultCurrency) {
                    //set to default only if no cad option
                    defaultCurrency = window.PAYFIRMA.CONSTANT.CURRENCY.USD;
                }
            }
            return defaultCurrency;
        }
    };

    _this.getCurrentMerchantAvailableCurrencies = function () {
        // Get the merchant's gateways
        // - set to CA$ by default if the merchant has both CA$ and US$
        const user = SessionStorageService.get().getUser();
        var currencyLabel;
        var availableCurrencies = [];
        if (user && user.currencies){
            for (let index = 0; index < user.currencies.length; ++index) {
                currencyLabel = user.currencies[index];
                if (currencyLabel === 'CAD') {
                    availableCurrencies.push(window.PAYFIRMA.CONSTANT.CURRENCY.CAD);
                }
                if (currencyLabel === 'USD') {
                    availableCurrencies.push(window.PAYFIRMA.CONSTANT.CURRENCY.USD);
                }
            }

            return availableCurrencies;
        }
    };

    _this.setAuthorizationHeaderToken = function(token){
        const currentUserInfo = SessionStorageService.get().getCurrentUserInfo() || {};
        currentUserInfo.jwt = token;
        SessionStorageService.get().setCurrentUserInfo(currentUserInfo);
    };

    function updateTokenInGlobals() {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo() || {};
        let refreshToken = SessionStorageService.get().getRefreshToken();

        if (refreshToken) {
            currentUserInfo.refreshToken = refreshToken;
        }

        SessionStorageService.get().setCurrentUserInfo(currentUserInfo);
        SessionStorageService.update();
    }

    function isLoggedInAsUser() {
        let parentUser = SessionStorageService.get().getParentUser();
        return parentUser && parentUser.jwt;
    }

    function cleanCachedUserObjects() {
        userPermissions = undefined;
        CurrentUserService.deleteCurrentUser();
    }

    function clearCredentials() {
        cleanCachedUserObjects();
        $http.defaults.headers.common.Authorization = '';
    }

    function createAuthorizationHeader(){
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        return { 'Authorization': 'Bearer ' + currentUserInfo.jwt };
    }

    function createLoggedInAsUserAuthorizationHeader(){
        return { 'Authorization': 'Bearer ' + _this.getJwtToken() };
    }

    function setDefaultAuthorizationHeader(isReplaceExpiredTokens) {
        if (!isReplaceExpiredTokens) {
            clearCredentials();
        }
        if (_this.isLoggedIn()) {
            if (_this.isLoggedInAsUser()) {
                Restangular.setDefaultHeaders(createLoggedInAsUserAuthorizationHeader());
                TransactionEndpointService.setDefaultHeaders(createLoggedInAsUserAuthorizationHeader());

            } else {
                Restangular.setDefaultHeaders(createAuthorizationHeader());
                TransactionEndpointService.setDefaultHeaders(createAuthorizationHeader());
            }
        }

    }

    function saveTokens(jwtToken, refreshToken, isReplaceExpiredTokens) {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo() || {};
        currentUserInfo.jwt = jwtToken;
        SessionStorageService.get().setCurrentUserInfo(currentUserInfo);
        if (refreshToken) {
            SessionStorageService.get().setRefreshToken(refreshToken);
        }
        SessionStorageService.update();
        updateTokenInGlobals();
        setDefaultAuthorizationHeader(isReplaceExpiredTokens);
    }

    _this.storeLoggedinSession = function (username, jwtToken, apiUrl, invoiceApi, refreshToken) {
        SessionStorageService.get().setSetting(undefined);
        SessionStorageService.update();
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo() || {};
        currentUserInfo.username = username;
        SessionStorageService.get().setCurrentUserInfo(currentUserInfo);
        SessionStorageService.update();
        saveTokens(jwtToken, refreshToken, false);
    };

    _this.restoreLoggedinSession = function () {
        // Keep user logged in after page refresh
        updateTokenInGlobals();
        setDefaultAuthorizationHeader(false);
    };

    _this.deleteStoredLoggedinSession = function () {
        SessionStorageService.get().setRefreshToken(undefined);
        SessionStorageService.get().setCurrentUserInfo(undefined);
        SessionStorageService.get().setSetting(undefined);
        SessionStorageService.get().setUser(undefined);
        SessionStorageService.update();
        clearCredentials();
    };

    _this.storeParentAdminUserSession = function () {
        let hqconfig = ConfigurationService.getConfig();
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var adminUserName = currentUserInfo.username;
        var apiUrl = hqconfig.hqApi;
        var jwtToken = currentUserInfo.jwt;
        var refreshToken = currentUserInfo.refreshToken;

        let parentUser = {
            username: adminUserName,
            apiUrl: apiUrl
        };

        parentUser.jwt = jwtToken;
        parentUser.refreshToken = refreshToken;
        SessionStorageService.get().setParentUser(parentUser);
        SessionStorageService.update();

        //delete parent permission cache
        cleanCachedUserObjects();
    };

    _this.retrieveParentAdminUserSession = function () {
        let adminCookie = SessionStorageService.get().getParentUser();
        if (adminCookie) {
            let adminJwtToken = adminCookie.jwt;
            let adminRefreshToken = adminCookie.refreshToken;
            SessionStorageService.get().setParentUser({
                username: adminCookie.username,
                jwt: adminJwtToken,
                refreshToken: adminRefreshToken
            });
        }
    };

    _this.deleteStoredLogInAsUserSession = function () {
        SessionStorageService.get().setParentUser(undefined);
        SessionStorageService.get().setSetting(undefined);
        SessionStorageService.update();
    };

    _this.deleteAllStoredLogInSessions = function () {
        _this.deleteStoredLoggedinSession();
        _this.deleteStoredLogInAsUserSession();

        if (updateEFTTransactionInterval) {
            clearInterval(updateEFTTransactionInterval);
        }
    };

    _this.deleteAllStoredLogInSessionsAndRedirectToLogin = function () {
        _this.deleteAllStoredLogInSessions();

        // Always redirect to login,
        // unless path is of reset password or cibc sso
        if ($location.path() !== window.PAYFIRMA.ROUTE.CHANGE_OWN_PASSWORD &&
            $location.path() !== window.PAYFIRMA.ROUTE.RESET_PASSWORD &&
            $location.path() !== window.PAYFIRMA.ROUTE.CIBC_SSO){
            Utils.go('/login');
        }
    };

    function setCredentials(username, jwtToken, apiUrl, refreshToken) {
        let hqConfig = ConfigurationService.getConfig();
        _this.storeLoggedinSession(username, jwtToken, apiUrl, hqConfig.invoiceApi, refreshToken);
    }

    _this.deleteStoredLogInAsUserSessionAndRedirectToMerchant = function(){
        let hqConfig = ConfigurationService.getConfig();
        var apiUrl = hqConfig.hqApi;
        var parentJwtToken = _this.getParentAdminJwtToken();
        var parentRefreshToken = _this.getParentAdminRefreshToken();
        var username = _this.getParentAdminUsername();
        setCredentials(username, parentJwtToken, apiUrl, parentRefreshToken);
        _this.deleteStoredLogInAsUserSession();
        Utils.go('/merchant');

    };

    function getAuthorizationTokenString() {
        if (_this.isLoggedIn()) {
            let currentUserInfo = SessionStorageService.get().getCurrentUserInfo() || {};
            if (_this.isLoggedInAsUser()) {
                return 'Bearer ' + _this.getJwtToken();
            } else {
                return 'Bearer ' + currentUserInfo.jwt;
            }
        }
        return undefined;
    }

    _this.getAuthorizationHeader = function () {
        var authTokenString = getAuthorizationTokenString();
        if (authTokenString) {
            return {
                'Authorization': authTokenString
            };
        } else {
            return {};
        }
    };

    _this.getParentAdminJwtToken = function () {
        return SessionStorageService.get().getParentUser().jwt;
    };

    _this.getParentAdminRefreshToken = function () {
        return SessionStorageService.get().getParentUser().refreshToken;
    };

    _this.getParentAdminUsername = function () {
        var adminCookie = SessionStorageService.get().getParentUser();
        if (adminCookie && adminCookie.username) {
            return adminCookie.username;
        }

        return undefined;
    };

    _this.isLoggedIn = function () {
        return _this.getJwtToken();
    };

    _this.getJwtToken = function () {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        if (currentUserInfo) {
            return currentUserInfo.jwt;
        } else {
            return undefined;
        }
    };

    _this.getRefreshToken = function () {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        return currentUserInfo.refreshToken;
    };

    _this.getAccessToken = function () {
        var token = _this.getJwtToken();
        if (token) {
            var subStingArray = token.split(/[..]/);
            var base64String = subStingArray[1];
            var decodedString = window.atob(base64String);
            var jsonDecodedObject = JSON.parse(decodedString);
            return jsonDecodedObject.access_token;
        }
        return undefined;
    };

    function requireSessionCheck(state) {
        var conditionalOperatorCheck = _.find(
            [window.PAYFIRMA.ROUTE.LOGOUT,
                window.PAYFIRMA.ROUTE.CIBC_SSO,
                window.PAYFIRMA.ROUTE.PAGE_NOT_FOUND,
                window.PAYFIRMA.ROUTE.PAY_INVOICE,
                window.PAYFIRMA.ROUTE.CARD_REQUEST],
            function(value) { return state.indexOf(value) !== -1; }
        ) ? false : true;
        var conditionalOperatorCheckWithChangePassword = state !== window.PAYFIRMA.ROUTE.RESET_PASSWORD &&
            state !== window.PAYFIRMA.ROUTE.CHANGE_OWN_PASSWORD;
        return state && state !== window.PAYFIRMA.ROUTE.LOGIN && conditionalOperatorCheck &&
        conditionalOperatorCheckWithChangePassword;
    }

    _this.requireSessionCheck = (state) => requireSessionCheck(state);
    _this.getCurrentLoggedInUser = () => SessionStorageService.get().getUser();
    _this.isLoggedInAsUser = () => isLoggedInAsUser();
    _this.updateTokens = (jwtToken, refreshToken) => saveTokens(jwtToken, refreshToken, true);
    _this.getCurrentUser = () => {
        return SessionStorageService.get().getUser();
    };
    _this.getCurrentMerchantGateways = () => _this.merchantGateways;
    _this.initializeSession = () => initializeSession();

    _this.requirePermissionCheck = function (state) {
        return state && requireSessionCheck(state);
    };


    _this.cleanup = function() {
        if (window.PAYFIRMA.CustomerDataService) {
            window.PAYFIRMA.CustomerDataService.unload();
        }

        if (window.PAYFIRMA.TransactionDataService) {
            window.PAYFIRMA.TransactionDataService.unload();
        }
    };

    _this.goToDefaultView = function(permission) {
        if (permission.indexOf('CardTerminal') !== -1) {
            Utils.go(window.PAYFIRMA.ROUTE.CARD_TERMINAL);
        } else if (permission.indexOf('VTMaster') !== -1) {
            Utils.go(window.PAYFIRMA.ROUTE.WEB_TERMINAL);
        } else if (permission.indexOf('CardTerminal') === -1 && permission.indexOf('VTMaster') === -1
            && permission.indexOf('ReportViewing') !== -1) {
            Utils.go(window.PAYFIRMA.ROUTE.REPORTING);
        } else {
            Utils.go(window.PAYFIRMA.ROUTE.TRANSACTION);
        }
    };
});