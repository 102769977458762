let PRODUCTION_CONFIG = {
    hq_api: 'https://api.payfirma.com/',
    hq_resetPassword_domain: 'hq.payfirma.com/#',
    hq_jwt_token_url: 'https://auth.payfirma.com/',
    jwt_client_id: '4b3db714b7d6a9ab3cf9b8ae8f49e8c4',
    pay_invoice_secret: 'SU5WT0lDRV9FTUFJTFNfQVJFX1dPUktJTkc=',
    invoice_api: 'https://apigateway.payfirma.com/',
    file_management_service_api : 'https://apigateway.payfirma.com/file-management-service/',
    file_management_service_public_api : 'https://file.payfirma.com/',
    heap_id: '1463243271',
    plan_service_api : 'https://apigateway.payfirma.com/plan-service/',
    customer_service_api: 'https://apigateway.payfirma.com/customer-service/',
    transaction_service_api: 'https://apigateway.payfirma.com/transaction-service-vt/',
    analytics_service_api: 'https://apigateway.payfirma.com/analytics-service/',
    merchant_service_api: 'https://apigateway.payfirma.com/merchant-service/',
    merchant_service_public_api: 'https://merchant.payfirma.com/',
    cibc_oidc_url: 'https://rapi.env1.cmo.cibc.com/isam/oidc/endpoint/amapp-runtime-cmo/authorize',
    cibc_oidc_client_id: 'L9iu4yJKHBzGNz8usbQe',
    cibc_auth_redirect_url: 'https://auth.payfirma.com/oidc/cibc',
    new_relic_app_id: '32788498',
    hpp_service_api: 'https://apigateway.payfirma.com/merchant-service/hpp/init',
    catpcha_site_key: '6LcnVMgeAAAAAMZelQH6j_BM3qAsD61aWyA0A0iU',
    reporting_system_url: 'https://reporting.payfirma.com/login',
    graphql_service_api: 'https://apigateway.payfirma.com/graphql-service/graphql'
};
export default PRODUCTION_CONFIG;
