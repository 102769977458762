import angular from 'angular';

angular
.module('payhqUIApp')
.filter('minMax', function () {
    return function (list, propertyKey, min, max) {
        if (list && list.length && propertyKey) {
            return _.filter(list, function (item) {
                var value = Number(item[propertyKey]);
                if (!isNaN(value)) {
                    var result = true;

                    if (min) {
                        result = result && value >= min;
                    }

                    if (max) {
                        result = result && value <= max;
                    }

                    return result;
                }
                return false;
            });
        }
        return [];
    };
});