import angular from 'angular';
import Hashids from 'hashids';
import ConfigurationService from 'service/configuration-service';

function payInvoice($translate, localeSupported, $scope, InvoiceService,
                    Html2PdfService, PaperToastService, $log, TransactionService) {
    var _this;
    _this = this;

    _this.invoiceData = null;
    _this.invoicePaid = false;
    _this.result = {};
    _this.invoiceIdHash = null;
    _this.tokenRequest = null;
    _this.invoiceId = null;
    _this.errorMessage = true;
    _this.errorMessages = [];
    _this.payInvoiceToken = null;
    _this.invoicePaid = null;
    _this.loading = false;
    _this.imageLogoSource = '';

    // Loading overlay
    function loadingOverlay(bool) {
        // If it's true: display overlay. Otherwise remove.
        if (bool === true) {
            var docHeight = $(window).height();
            $('.invoice-overlay').append('<div class="overlay">' +
                '<div class="whirl ringed big-loader overlay-loading"></div></div>');
            $('.whirl').height(docHeight).css({
                'opacity': 1.0,
                'position': 'fixed',
                'top': '0px',
                'left': '0px',
                'background': 'rgba(255, 255, 255, 0.01)',
                'width': '100%',
                'z-index': 5000,
                'margin': '0 auto'
            });
        } else if ($('.overlay').length) {
            $('.overlay').remove();
        }
    }

    // Get Invoice by using ID Hash
    function getInvoice(invoiceIdHash) {
        let hqConfig = ConfigurationService.getConfig();
        // Start by unhashing the Invoice ID Hash
        var payInvoiceSecret = hqConfig.payInvoiceSecret;
        var hashid = new Hashids(payInvoiceSecret,
            15, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890');
        _this.invoiceId = hashid.decode(invoiceIdHash)[0];
        // Get Invoice using Invoice ID and Invoice Token
        InvoiceService.getInvoice(_this.invoiceId, _this.payInvoiceToken)
            .then(function (InvoiceResponse) {
                _this.imageLogoSource =
                    InvoiceService.getInvoiceLogo(InvoiceResponse.data.invoice_id);
                _this.invoiceData = InvoiceResponse.data;
                TransactionService.getCountryProvinceName(_this.invoiceData, InvoiceResponse.data, 'billing_country', 'billing_state');
                _this.invoiceData.due_date_converted = window.moment(_this.invoiceData.due_date).format('MMM Do YYYY');
                _this.invoiceData.tax_total = (_this.invoiceData.tax1 + _this.invoiceData.tax2);

                // Set invoice status
                var invoiceStatus = _this.invoiceData.status;

                if (InvoiceService.isPayInvoiceAllowed(invoiceStatus)) {
                    _this.invoicePaid = false;
                    _this.invoiceData.card = {
                        number: '',
                        expiry: '',
                        security: ''
                    };
                } else {
                    _this.invoicePaid = true;
                }

                // Checks language that the invoice was set under settings
                if (_this.invoiceData.language === 'FRENCH') {
                    $translate.use('fr');
                } else if (_this.invoiceData.language === 'ENGLISH') {
                    $translate.use('en');
                }

                _this.loading = false;
                loadingOverlay(false);
                return InvoiceResponse;
            });
    }

    // Request Invoice Token using a token request and invoice ID hash
    function requestToken(tokenRequest, invoiceIdHash) {
        _this.loading = true;
        InvoiceService.requestPayInvoiceToken(tokenRequest)
            .then(function (requestResponse) {
                _this.payInvoiceToken = requestResponse.data.access_token;
                getInvoice(invoiceIdHash);
                return requestResponse;
            });
    }

    // Split URL by Pipe
    function splitUrlByPipe(urlString) {
        var stringSplit = [];
        var index = 0;
        //grab url and split on pipe into string split
        urlString.split('|').forEach(function (x) {
            stringSplit[index] = x;
            index++;
        });
        var invoiceIdHash = stringSplit[0];
        var tokenRequest = stringSplit[1];
        requestToken(tokenRequest, invoiceIdHash);
        $('body').removeClass('hide');
    }

    function init() {
        loadingOverlay(true);
        //Split Url string
        splitUrlByPipe(_this.data);
    }

    // Toggles Invoice View from Minimized to Full
    _this.toggleInvoiceExpansion = function () {
        _this.isInvoiceExpanded = !_this.isInvoiceExpanded;
    };
    /**
     *
     */
    _this.thismonth = function () {
        if (_this.invoiceData.card.year < window.moment().format('YY')) {
            return true;
        } else if (_this.invoiceData.card.year === window.moment().format('YY')) {
            return _this.invoiceData.card.month <= window.moment().format('MM');
        } else {
            return false;
        }
    };

    /**
     *  Tab over
     */
    _this.tabOver = function ($event) {
        setTimeout(function () {
            var currentTab = $event.currentTarget.attributes.tabindex.value;
            var userCharLength = $event.currentTarget.value.length;
            var maxCharLength = $event.currentTarget.attributes['tab-over'].value;
            //
            // !!! WARNING: INVISIBLE CHARACTER INSIDE INDEXOF("") Zero Width Space !!!
            //
            var isMasked = ($event.currentTarget.value.indexOf('_') > -1);
            if (userCharLength >= maxCharLength && isMasked === false) {
                var targetInput = Number(currentTab) + 1;
                $('input[tabindex="' + targetInput + '"]').focus();
            }
        }, 50);
    };

    _this.payInvoice = function (payForm, invoiceData) {
        if (payForm.$valid) {

            _this.invoicePaid = null;
            loadingOverlay(true);
            var cardDetails = {
                'card_expiry_month': invoiceData.card.expiry.substring(0, 2),
                'card_expiry_year': invoiceData.card.expiry.substring(3, 5),
                'card_number': invoiceData.card.number.split(' ').join(''),
                'cvv2': invoiceData.card.security,
                'postal_code': invoiceData.billing_postal_code,
                'first_name': invoiceData.recipient_first_name,
                'last_name': invoiceData.recipient_last_name,
                'test_mode': false
            };
            //pay invoice from card detials
            InvoiceService.payInvoice(_this.invoiceId, cardDetails, _this.payInvoiceToken)
                .then(function (payInvoiceResponse) {
                    loadingOverlay(false);
                    _this.loading = false;
                    let transactionResult;

                    if (payInvoiceResponse.data.transaction_result) {
                        transactionResult = payInvoiceResponse.data.transaction_result.toLowerCase();
                    }

                    if (payInvoiceResponse.status === 200 &&
                        transactionResult === 'approved') {
                        _this.invoicePaid = true;
                        _this.invoiceData.status = 'Paid';
                    } else if (payInvoiceResponse.status === 200 &&
                        transactionResult === 'declined') {
                        _this.invoicePaid = false;
                        PaperToastService.error('transactionDeclinedTitle');
                    } else {
                        _this.invoicePaid = false;
                        let message;
                        if (transactionResult) {
                            message = transactionResult;
                        } else {
                            message = payInvoiceResponse.data.errors[0].code;
                        }
                        PaperToastService.create(message, 'error');
                    }

                    return payInvoiceResponse;
                });
        }
    };


    /*BEGIN: SAVE PDF*/
    var INVOICE_PAID_FOOTER_CLASS = 'invoice-paid-footer';

    function savePdfPreprocessing() {
        var invoicePaidFooter = window.document.getElementById(INVOICE_PAID_FOOTER_CLASS);
        var originalInovicePaidFooterDisplayStyle = invoicePaidFooter.style.display;
        invoicePaidFooter.style.display = 'none';
        return originalInovicePaidFooterDisplayStyle;
    }

    function savePdfPostprocessing(originalInovicePaidFooterDisplayStyle) {
        var invoicePaidFooter = window.document.getElementById(INVOICE_PAID_FOOTER_CLASS);
        invoicePaidFooter.style.display = originalInovicePaidFooterDisplayStyle;
    }

    _this.savePdf = function () {
        var INVOICE_BODY_CLASS = 'invoice-paid',
            invoicePaidBody = window.document
                .getElementsByClassName(INVOICE_BODY_CLASS);
        var originalInovicePaidFooterDisplayStyle = savePdfPreprocessing();
        Html2PdfService.convert(invoicePaidBody, 'invoice.pdf')
            .then(function () {
                savePdfPostprocessing(originalInovicePaidFooterDisplayStyle);
                //add spinner
            })
            .catch(function (error) {
                $log.error(error);
            });

    };
    /*END: SAVE PDF*/

    _this.selectedLang = $translate.use(_this.selectedLang);
    _this.supportedLang = localeSupported;
    _this.localeData = {
        'en': {
            langDisplayText: 'English'
        },
        'fr': {
            langDisplayText: 'Français'
        }
    };

    _this.getLanguage = function (lng) {
        return _this.localeData[lng].langDisplayText;
    };
    _this.currentLang = _this.getLanguage($translate.use());
    _this.setLocale = function () {
        _this.currentLang = (_this.localeData[_this.selectedLang].langDisplayText);
        $translate.use(_this.selectedLang);
    };

    init();
}

angular
    .module('payhqUIApp')
    .component('payInvoice', {
        bindings: {
            data: '='
        },
        controller: payInvoice,
        controllerAs: 'payInvoice',
        templateUrl: 'view/pay-invoice/pay-invoice.html'
    });
