import angular from 'angular';

function paperNavigation($scope, $location, ResizeService, SessionService, $translate, Utils,
    HeapService) {
    var _this = this;
    _this.totalIncomingRequest = 0;
    _this.HEAP_EVENT_NAME = window.PAYFIRMA.CONSTANT.HEAP_EVENT.NAVIGATION;

    function updateNavigationItems() {
        $translate(['webTerminal', 'cardTerminal', 'customerVault', 'invoicing', 'myTransactions', 'recurringBilling',
            'staff', 'dashboard', 'reporting', 'merchants', 'eftShort', 'age_verification', 'newReportingSystem', 'prospects']).then(function (translations) {
                _this.navigationLinkArrays = [
                    _this.navigationLinkArrayOne = [
                        {
                            'name'      : 'terminal',
                            'title'     : translations.webTerminal,
                            'url'       : 'terminal',
                            'permission': 'VTMaster'
                        },
                        {
                            'name'      : 'cardterminal',
                            'title'     : translations.cardTerminal,
                            'url'       : 'cardterminal',
                            'permission': 'CardTerminal'
                        },
                        {
                            'name'      : 'invoicing',
                            'title'     : translations.invoicing,
                            'url'       : 'invoicing',
                            'permission': 'InvoiceMaster'
                        },
                        {
                            'name'      : 'billing',
                            'title'     : translations.recurringBilling,
                            'url'       : 'billing',
                            'permission': 'RecurringMaster'
                        },
                        {
                            'name'      : 'transactions',
                            'title'     : translations.eftShort,
                            'url'       : 'eft',
                            'permission': 'EFTMaster'
                        },
                        {
                            'name'      : 'ageVerification',
                            'title'     : translations.age_verification,
                            'url'       : 'age-verification',
                            'permission': 'Age_Verification'
                        }
                    ],
                    _this.navigationLinkArrayTwo = [
                        {
                            'name'      : 'transactions',
                            'title'     : translations.myTransactions,
                            'url'       : 'transactions',
                            'permission': 'MyTransactions_Master'
                        },
                        {
                            'name'      : 'vault',
                            'title'     : translations.customerVault,
                            'url'       : 'customer-vault',
                            'permission': 'CustomerVault'
                        },
                        {
                            'name'      : 'staff',
                            'title'     : translations.staff,
                            'url'       : 'staff',
                            'permission': 'MyPeople'
                        }
                    ],
                    _this.navigationLinkArrayThree = [
                        {
                            'name'      : 'dashboard',
                            'title'     : translations.dashboard,
                            'url'       : 'dashboard',
                            'permission': 'Dashboard_Master'
                        },
                        {
                            'name'      : 'reporting',
                            'title'     : translations.reporting,
                            'url'       : 'reporting',
                            'permission': 'ReportViewing'
                        }
                    ],
                    _this.navigationLinkArrayNew = [ // New tag will be displayed for this section
                        {
                            'name'      : 'newReportingSystem',
                            'title'     : translations.newReportingSystem,
                            'url'       : 'new-reporting-system',
                            'permission': 'Consolidated_Reporting'
                        }
                    ],
                    _this.navigationLinkArrayFour = [
                        {
                            'name'      : 'merchant',
                            'title'     : translations.merchants,
                            'url'       : 'merchant',
                            'permission': 'Oracle'
                        },
                        {
                            'name'      : 'prospects',
                            'title'     : translations.prospects,
                            'url'       : 'prospects',
                            'permission': 'Oracle'
                        }
                    ]
                ];
                Utils.forceScopeApply($scope);
            });
    }
    
    //Checks if the user has required permissions for any of the links in the provided linkArray.
    _this.hasPermissionForAnyLink = function(linkArray) {
        return linkArray.some(function(link) {
            return _this.checkUserPermission(link.permission);
        });
    };

    _this.checkUserPermission = function(permission) {
        return SessionService.checkUserPermission(permission);
    };

    _this.getClass = function (path) {
        var currentPath = $location.path().substring(1);
        var currentPathMin;
        if (currentPath.indexOf('/') === -1){
            currentPathMin = currentPath;
        } else {
            currentPathMin = currentPath.substring(0, currentPath.indexOf('/'));
        }
        if (currentPathMin === path) {
            return 'active';
        } else {
            return '';
        }
    };

    _this.toggleCompactSidebar = function(){
        ResizeService.toggleCompact();
    };

    function updateNavigationMode(){
        _this.compactNavigation = ResizeService.isCompact();
        _this.HEAP_EVENT_NAME.NAVIGATION_SIZE = HeapService.toggleNavigationSizeText(_this.compactNavigation);
    }
    // Register previous function
    ResizeService.registerObserverCallback(updateNavigationMode);

    updateNavigationMode();

    function updateNavigation(){
        if (SessionService.isLoggedIn()) {
            updateNavigationItems();
        }
    }
    updateNavigation();
    //hack...navigation menu is static, it doesn't change when route changes, which means that the texts do not
    // auto refresh
    //so we have to manually force it to refresh when user select a different language
    window.PAYFIRMA.CommunicationService.onLanguageChangedEvent(function(){
        updateNavigation();
    });

    // Updating total incoming request number
    window.PAYFIRMA.CommunicationService.onTotalIncomingRequestEvent(function(value){
        _this.totalIncomingRequest = value;
    });
}
angular
.module('payhqUIApp')
.component('paperNavigation', {
    bindings: {
    },
    controller: paperNavigation,
    controllerAs: 'paperNavigation',
    templateUrl: 'component/paper-navigation/paper-navigation.html'
});