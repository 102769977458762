import _ from 'lodash';

//TODO: User should be CurrentUser
export default class User {
    constructor(serverResponse : Object, merchantId : number) {
        _.extend(this, serverResponse);
        this.merchant_id = merchantId;
    }

    isPayfirmaAdmin() {
        return this.user_group === 'PAYFIRMA_ADMIN';
    }

    isAdmin() {
        return this.user_group === 'ADMIN';
    }

    isNew() {
        return !this.id;
    }

    isPayfirmaInternal() {
        return this.business_information && this.business_information.id === 1;
    }

    isDemoAccount() {
        return this.business_information.account_type === 'DEMO';
    }

    getUsername() {
        return this.email;
    }

    getFullName() {
        return this.first_name + ' ' + this.last_name;
    }
}
