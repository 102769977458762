import angular from 'angular';

function paperButtonExport() {
    var _this;
    _this = this;

    _this.openDropdown = function(e){
        _this.dropdownIsOpen = true;
        $('dropdown').show();
        e.stopPropagation();
    };

    $(window.document).click(function(){
        _this.dropdownIsOpen = false;
        $('dropdown').hide();
    });
}

angular
.module('payhqUIApp')
.component('paperButtonExport', {
    bindings: {
        itemSummaryExport: '&',
        salesSummaryExport: '&'
    },
    controller: paperButtonExport,
    controllerAs: 'paperButtonExport',
    templateUrl: 'component/paper-button-export/paper-button-export.html'
});