/* @flow */

let sessionObjectProperties = {};

let SessionObject = function () {
    this.deserialize = function (jsonString) {
        sessionObjectProperties = JSON.parse(jsonString);
    };

    this.serialize = function (): string {
        return JSON.stringify(sessionObjectProperties);
    };

    this.getUser = function () {
        return sessionObjectProperties.user;
    };

    this.setUser = function (user) {
        sessionObjectProperties.user = user;
    };

    this.setRefreshToken = function (refreshToken) {
        sessionObjectProperties.refreshToken = refreshToken;
    };

    this.getRefreshToken = function () {
        return sessionObjectProperties.refreshToken;
    };

    this.setParentUser = function (parentUser) {
        sessionObjectProperties.parentUser = parentUser;
    };

    this.getParentUser = function () {
        return sessionObjectProperties.parentUser;
    };

    this.setCurrentUserInfo = function (currentUserInfo) {
        sessionObjectProperties.currentUserInfo = currentUserInfo;
    };

    this.getCurrentUserInfo = function () {
        return sessionObjectProperties.currentUserInfo;
    };

    this.setSetting = function (setting) {
        sessionObjectProperties.setting = setting;
    };

    this.getSetting = function () {
        return sessionObjectProperties.setting;
    };

    this.setChangePasswordInfo = function (changePasswordInfo) {
        sessionObjectProperties.changePasswordInfo = changePasswordInfo;
    };

    this.getChangePasswordInfo = function () {
        return sessionObjectProperties.changePasswordInfo;
    };

    this.getTimezone = function () {
        if (sessionObjectProperties.setting) {
            return sessionObjectProperties.setting.timezone;
        }
        return undefined;
    };

    this.getRequiredFields = function () {
        if (sessionObjectProperties.setting) {
            return sessionObjectProperties.setting.requiredFields;
        }
        return undefined;
    };
};

export {
    SessionObject
};