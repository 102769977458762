import angular from 'angular';
import SessionStorageService from 'service/session-storage-service';

angular
.module('payhqUIApp').service('HttpClientService', function ($http, $httpParamSerializerJQLike) {

    var RequestMethod = {
        GET: 'GET',
        POST: 'POST',
        PUT: 'PUT',
        DELETE: 'DELETE',
        PATCH: 'PATCH'
    };

    function isSuccessfulResponse(response) {
        var successfulResponseStatusCodes = [200, 201, 204, 202];
        return response &&
            _.find(successfulResponseStatusCodes, function (statusCode) {
                return response.status === statusCode;
            });
    }

    function processPromise(promise) {
        return new Promise(function (resolve, reject) {
            promise.then(
                function (response) {
                    if (isSuccessfulResponse(response)) {
                        resolve(response);
                    } else {
                        //TODO: fix Promise warning: a promise was rejected with a non-error: [object Object]
                        reject(response);
                    }
                },
                function (error) {
                    reject(error);
                }
            );
        });
    }

    function getJwtToken () {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        if (currentUserInfo) {
            return currentUserInfo.jwt;
        } else {
            return undefined;
        }
    }

    function getParentJwtToken () {
        let parentUser = SessionStorageService.get().getParentUser();
        if (parentUser) {
            return parentUser.jwt;
        } else {
            return undefined;
        }
    }

    function isLoggedInAsUser() {
        let parentUser = SessionStorageService.get().getParentUser();
        return parentUser && parentUser.jwt;
    }

    function isLoggedIn () {
        return getJwtToken();
    }

    function getAuthorizationTokenString() {
        if (isLoggedIn()) {
            let currentUserInfo = SessionStorageService.get().getCurrentUserInfo() || {};
            if (isLoggedInAsUser()) {
                return 'Bearer ' + getJwtToken();
            } else {
                return 'Bearer ' + currentUserInfo.jwt;
            }
        }
        return undefined;
    }

    function getParentAuthorizationTokenString() {
        if (isLoggedIn()) {
            let parentUser = SessionStorageService.get().getParentUser() || {};
            if (isLoggedInAsUser()) {
                return 'Bearer ' + getParentJwtToken();
            } else {
                return 'Bearer ' + parentUser.jwt;
            }
        }
        return undefined;
    }

    function getAuthorizationHeader () {
        var authTokenString = getAuthorizationTokenString();
        if (authTokenString) {
            return {
                'Authorization': authTokenString
            };
        } else {
            return {};
        }
    }

    function getParentAuthorizationHeader () {
        var authTokenString = getParentAuthorizationTokenString();
        if (authTokenString) {
            return {
                'Authorization': authTokenString
            };
        } else {
            return {};
        }
    }

    function createRequestHeader(config, contentType, noAuthorizationRequired, ignoreAuthInterceptor, useParentJwt=false) {
        var options = {};

        var authHeader = {};

        var contentTypeCopy = contentType;

        if (config) {
            options = config;
        }

        if (!(config && config.headers && config.headers.Authorization) && !noAuthorizationRequired) {
            if (useParentJwt){
                authHeader = getParentAuthorizationHeader();
            } else {
                authHeader = getAuthorizationHeader();
            }
        }

        //do not override content-type in the config
        if (config && config.headers && config.headers.hasOwnProperty('Content-Type')) {
            contentTypeCopy = {};
        }

        options.headers = _.extend(config && config.headers ? _.extend(config.headers, contentTypeCopy)
            : contentTypeCopy, authHeader);

        //https://github.com/witoldsz/angular-http-auth，
        //no need to go through 401 auth interceptor again when
        //refresh token because if fail we should directly go to the login page
        //ignoreAuthModule: true
        if (ignoreAuthInterceptor) {
            options.ignoreAuthModule = true;
        }

        return options;
    }

    //Identify URL paths that are meant to use parentUser JWT for access rather than currentUser
    function isUrlForParentUser(url) {
        const pattern1 = /\/merchant-service\/application\/merchant\/\d+$/;
        const pattern2 = /\/merchant-service\/application\/[a-zA-Z0-9]+\/merchant\//;

        return pattern1.test(url) || pattern2.test(url);
    }

    function httpCall(method, data, serviceUrl, config) {

        //DEFAULT THE REQUEST TO JSON REQUEST
        //CAN BE OVERRIDDEN IF CUSTOM HEADERS PROVIDED.
        var contentType = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let useParentJwt = false;
        if (isUrlForParentUser(serviceUrl)){
            useParentJwt = true;
        }

        var options = createRequestHeader(config, contentType, false, null, useParentJwt);
        
        // Set withCredentials to true for the token login endpoint, as remembered_device_uuid is passed via HttpOnly cookies.
        // The server must set a specific Access-Control-Allow-Origin header (no wildcard *) for CORS compliance.
        if (serviceUrl.includes('verify_otp')) {
            options.withCredentials = true;
        }

        if (method === RequestMethod.POST) {
            return processPromise($http.post(serviceUrl, data, options));
        } else if (method === RequestMethod.PUT) {
            return processPromise($http.put(serviceUrl, data, options));
        } else if (method === RequestMethod.PATCH) {
            return processPromise($http.patch(serviceUrl, data, options));
        } else if (method === RequestMethod.DELETE) {
            return processPromise($http.delete(serviceUrl, options));
        } else {
            return processPromise($http.get(serviceUrl, options));
        }
    }

    function arrayBufferHttpCall(serviceUrl, config) {
        var options = createRequestHeader(config, {}, false);

        options.responseType = 'arraybuffer';

        return processPromise($http.get(serviceUrl, options));
    }

    function serializeData(data) {
        var buffer = [];
        // Serialize each key in the object.
        for (var name in data) {
            if (!data.hasOwnProperty(name)) {
                continue;
            }
            var value = data[name];
            buffer.push(
                encodeURIComponent(name) + '=' + (value ? value : '')
            );
        }
        // Serialize the buffer and clean it up for transportation.
        return buffer.join('&');
    }

    function createFormRequestHeader(config, noAuthorizationRequired, ignoreAuthInterceptor) {
        var contentType = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        return createRequestHeader(config, contentType, noAuthorizationRequired, ignoreAuthInterceptor);
    }

    function formPostHttpCall(serviceUrl, bodyData, config, noAuthorizationRequired, ignoreAuthInterceptor) {


        var options = createFormRequestHeader(config, noAuthorizationRequired, ignoreAuthInterceptor);
        
        // Set withCredentials to true for the token login endpoint, as remembered_device_uuid is passed via HttpOnly cookies.
        // The server must set a specific Access-Control-Allow-Origin header (no wildcard *) for CORS compliance.
        if (serviceUrl.includes('token')) {
            options.withCredentials = true;
        }

        //use angular's own component encoder and serializer
        //no need to write customized component enocder anymore
        return processPromise(
            $http.post(serviceUrl, $httpParamSerializerJQLike(bodyData), options));
    }

    function formDeleteHttpCall(serviceUrl, config, noAuthorizationRequired, ignoreAuthInterceptor) {
        return processPromise($http.delete(serviceUrl, createFormRequestHeader(config, noAuthorizationRequired,
            ignoreAuthInterceptor)));
    }

    function transformRequest(apiEndpointBaseUrl, apiEndpointPath, method, data, config) {

        var apiMethod = method;
        if (!method) {
            apiMethod = RequestMethod.GET;
        }

        //we use our own deferred to unwrap the requested data from the returned data object
        return httpCall(apiMethod, data, apiEndpointBaseUrl + apiEndpointPath, config);
    }

    function addParamsToUrl(url, params){
        var urlCopy = url;
        if (params && !_.isEmpty(params)) {
            var queryString = serializeData(params);
            if (url.indexOf('?') !== -1) {
                if (url.indexOf('&') !== -1) {
                    urlCopy += queryString;
                } else {
                    urlCopy += '&' + queryString;
                }

            } else {
                urlCopy += '?' + queryString;
            }
        }
        return urlCopy;
    }

    return {
        doArrayBufferGet: function (url, config) {
            return arrayBufferHttpCall(url, config);
        },

        doFormPost: function (url, bodyData, config, noAuthorizationRequired, ignoreAuthInterceptor) {
            return formPostHttpCall(url, bodyData, config, noAuthorizationRequired, ignoreAuthInterceptor);
        },

        doFormDelete: function (url, config, ignoreAuthInterceptor) {
            return formDeleteHttpCall(url, config, ignoreAuthInterceptor);
        },

        doGet: function (url, params, config) {
            return transformRequest('',
                addParamsToUrl(url, params),
                RequestMethod.GET,
                config);
        },

        doPost: function (url, data, config) {
            return transformRequest('',
                url,
                RequestMethod.POST,
                data,
                config);
        },

        doPut: function (url, data, config) {
            return transformRequest('',
                url,
                RequestMethod.PUT,
                data,
                config);
        },

        doPatch: function (url, data, config) {
            return transformRequest('',
                url,
                RequestMethod.PATCH,
                data,
                config);
        },
        doDelete: function (url, data, config) {
            return transformRequest('',
                url,
                RequestMethod.DELETE,
                data,
                config);
        }
    };

});
