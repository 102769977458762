import angular from 'angular';

function previewTheme() {
    var _this = this;
    let previewThemeCss = window.localStorage.getItem('preview-theme-css');

    function injectCssStringToHtml(cssString) {
        $('<style>' + cssString + '</style>'
        ).appendTo('head');
    }

    function loadStyles(){
        if (previewThemeCss){
            injectCssStringToHtml(previewThemeCss);
        }
    }

    _this.$onInit = function() {
        loadStyles();
    };

    _this.transactions = window.PAYFIRMA.PREVIEW_THEME_CONSTANT.transactions;
    _this.transactions[0].isVisible = true;
}
angular
.module('payhqUIApp')
.component('previewTheme', {
    bindings: {
        data: '='
    },
    controller: previewTheme,
    controllerAs: 'previewTheme',
    templateUrl: 'view/preview-theme/preview-theme.html'
});
