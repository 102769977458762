import angular from 'angular';

function paperDropdownMenu() {
    let _this = this;

    _this.openDropdown = (e) => {
        _this.dropdownIsOpen = true;
        $('dropdown_a').show();
        e.stopPropagation();
    };
    $(window.document).click(() => {
        _this.dropdownIsOpen = false;
        $('dropdown_a').hide();
    });
}
angular
.module('payhqUIApp')
.component('paperDropdownMenu', {
    bindings: {
        label: '<',
        showSubmenu:'<'
    },
    transclude: true,
    controller: paperDropdownMenu,
    controllerAs: 'paperDropdownMenu',
    templateUrl: 'component/paper-dropdown-menu/paper-dropdown-menu.html'
});