import angular from 'angular';
import ConfigurationService from 'service/configuration-service';

/**
 * @ngdoc service
 * @name payhqUIApp.customerApp.service:CustomerCardService
 * @requires Restangular
 * @requires CustomerCardFactory
 * @description
 *
 * Service for handling customer card behaviours
 *
 */
angular
.module('customerApp')
.factory('CustomerCardService', function CustomerCardService(CustomerCardFactory, $log, CardService, HttpClientService) {

    function parseExpiry(expiryString) {
        var expiryValues = expiryString.split('/');
        var expiryYear = parseInt(expiryValues[1], 10);
        var expiryMonth = parseInt(expiryValues[0], 10);

        return {
            expiryMonth: expiryMonth,
            expiryYear: expiryYear
        };

    }

    return {
        /**
         * Parse Api response to array of CustomerCard objects .
         *
         * @param {Object} response object to parse
         * @return {Array} List of customer card objects
         */
        parseResponseDataToArray: function (response) {
            var data = [];
            if (response) {
                // Sometimes the data comes back as an array already, instead of in .data
                var dataArray = response;
                if (response.data) {
                    dataArray = response.data;
                }
                angular.forEach(dataArray, function (value) {
                    data.push(CustomerCardFactory.parseResponse(value));
                });
            }
            return data;
        },

        /**
         * Get A customer card
         * @param {cardListArray} - Customer card list
         * @param {cardLookupId}  - Customer card lookup id
         * @return {matchCreadCard} - Returns a customer card object
         */
        getCustomerCard: function (cardListArray,cardLookupId) {
            var matchCreditCard;
            matchCreditCard = _.find(cardListArray,function(card){
                return card.lookup_id === cardLookupId;
            });
            return CustomerCardFactory.parseResponse(matchCreditCard);
        },

        /**
         * Save a customer card.
         *
         * @param {Object} customerCard - Customer card object to save
         * @returns {Object} customerCard - Returns a customer card object
         */
        saveCustomerCard: function (customerCardObject) {

            var cardId = customerCardObject.id;
            var customerLookupId = customerCardObject.customer_lookup_id;

            if (customerCardObject.card_expiry) {
                var expiryData = parseExpiry(customerCardObject.card_expiry);
                customerCardObject.card_expiry_month = expiryData.expiryMonth;
                customerCardObject.card_expiry_year = expiryData.expiryYear;
            }

            //Update an existing customer card
            if (customerCardObject && cardId) {
                return CardService.updateExistingCard(customerCardObject,customerLookupId)
                 .then(
                    function (response){
                        return CustomerCardFactory.parseCards(response.data.cards);
                    }).catch(function(error){
                        throw error;
                    });
            } else {
                return CardService.saveNewCard(customerCardObject,customerLookupId)
                 .then(
                    function (response){
                        return CustomerCardFactory.parseCards(response.data.cards);
                    }).catch(function(error){
                        throw error;
                    });
            }
        },

        /**
         * Delete a customer card.
         *
         * @param {Object} customerCard - Customer card object to delete
         * @returns {Object} response - Returns api response
         */
        deleteCustomerCard: function (removedCreditCardLookupId, customerLookupId) {
            return CardService.deleteExistingCard(removedCreditCardLookupId,customerLookupId)
                        .then(function (response){
                            return CustomerCardFactory.parseCards(response.data.cards);
                        })
                        .catch(function(error){
                            throw error;
                        });
        },

        getCardRequestURLEmail: function (customerLookupId, sendEmail) {
            return HttpClientService.doPost(
                ConfigurationService.getConfig().customerServiceApi +
                'customer/' + customerLookupId + '/card-request',
                {
                    send_email: sendEmail
                })
            .then(function(response){
                return response.data;
            }).catch(function(error){
                throw error;
            });
        },

        requestCardAddToken : function (tokenRequest) {
            let hqConfig = ConfigurationService.getConfig();
            if (hqConfig){
                const clientId = hqConfig.jwtClientId;
                return HttpClientService.doFormPost(hqConfig.hqJwtTokenUrl + 'payhq/token',
                    {
                        grant_type: 'external_link',
                        card_request_token: tokenRequest,
                        client_id: clientId
                    }, {}, true, true)
                .then(function (response) {
                    return response.data;
                }).catch(function(error){
                    throw error;
                });
            }
        }
    };
});
