import moment from 'moment';
import angular from 'angular';
import 'script/app-init';
import ThemeLoadService from 'service/theme-load-service';

angular
    .module('payhqUIApp')
    .controller('ApplicationController',
        function ApplicationController($compile, $scope, Utils, ResizeService, CurrentUserService,
            $location, SessionService, ngProgress, Restangular, AuthService, $rootScope, $http, Idle,
            UserService, PaperToastService) {

            let _this = this;
            let themeLoad = new ThemeLoadService();
            themeLoad.getTheme();

            var currentPath;
            var currentPathMin;
            var isInternetExplorer;
            var isSafari;
            isInternetExplorer = Utils.isInternetExplorer();
            isSafari = Utils.isSafari;
            // This function will fire from ResizeService
            function updateNavigationMode(){
                $scope.compactNavigation = ResizeService.isCompact();
            }

            // Register previous function
            ResizeService.registerObserverCallback(updateNavigationMode);

            $scope.$on('$routeChangeStart', function () {
                var path = $location.path();
                if (/(view-invoice)/.test(path) || /(preview-theme)/.test(path)){
                    $('body').addClass('hidden');
                }
                if (!isSafari){
                    $('main').addClass('hidden');
                    $('main').addClass('no-overflow');
                    $('main').scrollTop(0);
                }
            });

            $scope.$on('$routeChangeSuccess', function () {
                var locationpath;
                var path = $location.path();
                if (/(view-invoice)/.test(path) || /(preview-theme)/.test(path)){
                    $('body').removeClass('hidden');
                }
                if (!isSafari){
                    locationpath = $location.path();
                    $scope.locationpath = locationpath.replace('/', '_');
                    $('main').removeClass('hidden');
                    $('main').removeClass('no-overflow');
                }

                // IE FIX
                // If it's IE11+, scroll the pages to the top after load, after a second
                if (isInternetExplorer) {
                    setTimeout(function () {
                        document.getElementsByTagName('main')[0].scrollTop = 0;
                    }, 1000);
                }
            });

            $rootScope.isNavigationVisible = false;
            $rootScope.navigationShow = true;

            // This variable is for triggerLanguageChangedEvent on paperNavigation only one time.
            _this.afterLogin = false;

            $scope.$on('$locationChangeSuccess', function () {
                currentPath = $location.path().substring(1);
                currentPathMin = currentPath.substring(0, currentPath.indexOf('/'));
                if ($scope.mode === 'small') {
                    $scope.state = 'contracted';
                }
                $rootScope.navigationShow = $location.path() !== '/login'
                && $location.path() !== window.PAYFIRMA.ROUTE.CIBC_SSO;

                if (['pay-invoice', 'card-request'].includes(currentPathMin)) {
                    $rootScope.isNavigationVisible = false;
                } else {
                    $rootScope.isNavigationVisible = true;
                }
                if ( SessionService.isLoggedIn()) {
                    if (_this.afterLogin === false) {
                        window.PAYFIRMA.CommunicationService.triggerLanguageChangedEvent();
                        _this.afterLogin = true;
                    }
                } else {
                    _this.afterLogin = false;
                }
            });

            function initialize() {
                //check user agent using regex
                var iPad = /iPad/.test(window.navigator.userAgent) && !window.MSStream;
                var iPhone = /iPhone/.test(window.navigator.userAgent) && !window.MSStream;
                var iPod = /iPod/.test(window.navigator.userAgent) && !window.MSStream;
                var Android = /Android/.test(window.navigator.userAgent) && !window.MSStream;
                var iOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;
                var windows = /WoW/.test(window.navigator.userAgent) && !window.MSStream;

                if (iOS === true) {
                    $('body').addClass('iOS');
                }


                $scope.userDevice = function () {
                    if (iPad) {
                        return 'iPad';
                    } else if (iPhone) {
                        return 'iPhone';
                    } else if (iPod) {
                        return 'iPod';
                    } else if (Android) {
                        return 'android';
                    } else if (windows) {
                        return 'PC';
                    }
                };

            }

            initialize();

            ngProgress.color('#fff'); // change color of progess bar
            // Ensures reload of filters
            $rootScope.filterShow = false;
            $scope.$on('$locationChangeSuccess', function () {
                $rootScope.filterShow = false;
                if (
                    $location.path() === '/transactions-list' ||
                    $location.path() === '/dashboard' ||
                    $location.path() === '/reporting' ||
                    $location.path() === '/my-people-list' ||
                    $location.path() === '/customer-list'
                ) {
                    $rootScope.filterShow = true;
                }
            });

            $scope.showfilters = false;

            /**
             * Set datepicker default options
             */
            $scope.datePickerOptions = {
                local: {
                    firstDay: 1
                },
                ranges: {
                    'Last 7 Days': [moment().subtract('days', 6), moment()],
                    'Last 30 Days': [moment().subtract('days', 29), moment()]
                },
                format: 'MMM/DD/YY'
            };

            /**
             * Check for a specific user permission.
             *
             * @param {string} permissionName - Permission name to check for
             * @returns {boolean} - Returns true if the user has the permission. False otherwise.
             */
            $scope.checkPerm = function (permissionName) {
                return SessionService.checkUserPermission(permissionName);
            };

            /**
             * Route the app.
             *
             * @param {string} url - Url to route the app to
             */
            $scope.go = function (url) {
                $location.path(url);
            };

            $scope.isUpgradeOpen = function () {
                if ($('#upgradePopup').hasClass('ng-hide') === true) {
                    $('#upgradePopup').removeClass('ng-hide');
                } else {
                    $('#upgradePopup').addClass('ng-hide');
                }
            };

            $scope.isDemoAccount = function () {
                var isDemo = false;
                if ($scope.currentUserInfo.accountType === 'Demo' && $location.path() !== '/login') {
                    isDemo = true;

                }
                return isDemo;
            };

            /**
             * Show routs loading
             *
             */
            $scope.$on('$routeChangeStart', function () {
                ngProgress.reset();
                ngProgress.start();
                if ($location.url() !== '/login?resetpassword=true'){
                    PaperToastService.closeErrors();
                }
            });

            /**
             * End loading after routch chance sucess
             *
             */
            $scope.$on('$routeChangeSuccess', function () {
                ngProgress.complete();
            });

            /**
             * Idle check for 30 mins
             */
            function closeModals() {
                if ($scope.warning) {
                    $scope.warning.close();
                    $scope.warning = null;
                }

                if ($scope.timedout) {
                    $scope.timedout.close();
                    $scope.timedout = null;
                }
            }

            /**
             * Idle check start
             */
            $scope.start = function () {
                closeModals();
                Idle.watch();
                $scope.started = true;
            };

            /**
             * Idle check stop
             */
            $scope.stop = function () {
                closeModals();
                Idle.unwatch();
                $scope.started = false;
            };

            /**
             * Check browser for min page height
             */
            function checkPageHeight() {
                var browserHeight = $(window).height();
                var navHeight = $('.header-nav').height();
                var minHeight = browserHeight - navHeight;
                $('.main-content').css('min-height', minHeight);
            }

            checkPageHeight();
        });
