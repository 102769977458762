import angular from 'angular';
import CONSTANT_APP from 'constant/constant-app';
import CONSTANT_COMPONENT from 'constant/constant-component';

const APP_MODULE = CONSTANT_APP.module;
const COMPONENT_PAPER_DROPDOWN = CONSTANT_COMPONENT.paper_dropdown;

function paperDropdown($element, AuthService, $location) {

    let _this = this;

    _this.isDropped = false;

    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(window.document).click(function () {
        if (_this.isDropped) {
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });

    $element.on('click', function (e) {
        if (_this.isDropped) {
            $('dropdown').show();
        }

        e.stopPropagation();
    });

    _this.go = function (value) {
        _this.isDropped = false;
        $location.path(value);
    };

    _this.logout = function () {
        _this.isDropped = false;
        AuthService.logout().then( function () {
            window.location.reload(true); //to get a new CSRF token
        });
    };
}
angular
.module(APP_MODULE)
.component(COMPONENT_PAPER_DROPDOWN, {
    bindings: {
        label: '@'
    },
    controller: paperDropdown,
    controllerAs: 'paperDropdown',
    templateUrl: 'component/paper-dropdown/paper-dropdown.html'
});
