/* @flow */

import angular from 'angular';
import _ from 'lodash';

/**
 * @ngdoc factory
 * @name payhqUIApp.transactionApp.factory:TransactionFactory
 * @description
 *
 * Factory for creating transaction request and response objects
 *
 */
angular
.module('transactionApp')
.factory('TransactionFactory', function TransactionFactory($translate, CardService, locale, TimeService,
    $filter) {

    const TRANSLATIONS = {};

    $translate([
        'card_terminal',
        'web_terminal',
        'invoicing',
        'ecommerce',
        'mobile',
        'tabletPOS']).then(function(translations){
            TRANSLATIONS.CARD_TERMINAL = translations.card_terminal;
            TRANSLATIONS.WEB_TERMINAL = translations.web_terminal;
            TRANSLATIONS.INVOICING = translations.invoicing;
            TRANSLATIONS.ECOMMERCE = translations.ecommerce;
            TRANSLATIONS.MOBILE = translations.mobile;
            TRANSLATIONS.TABLETPOS = translations.tabletPOS;
            TRANSLATIONS.RECURRING = translations.recurring;
        });

    /*
     * Transaction Object constructor.
     *
     * @constructor A subclass constructor for Transaction
     */
    let Transaction = function () {};

    /*
     * Transaction Request Object constructor.
     *
     * @constructor A subclass constructor for Transaction Requests (inherits from Transaction)
     * @param {Object} initialData - Data to initialize transaction request object with
     */
    var TransactionRequest = function (initialData) {
        var self = this,
            transactionRequestData = {
                card_number: null,
                card_cvv: null,
                card_cvv2: null,
                card_expiry_month: null,
                card_expiry_year: null,
                ksn: null,
                swipe: null,
                track1_enc: null,
                track1_edl: null,
                track1_msk: null,
                use_customer_payment_data: false
            };

        /*
         * Populate additional transaction request specific properties.
         *
         * @param {Object} data - Transaction data to parse
         */
        function populateAdditionalRequestData(data) {
            if (data) {
                // Populate currency and amount values
                var currencyAmount = data.currencyAmount;
                if (currencyAmount && currencyAmount.indexOf('$') !== -1) {
                    currencyAmount = currencyAmount.split('$');
                    self.currency = currencyAmount[0] + '$';
                    self.amount = currencyAmount[1];
                }

                // Get transaction type being performed (tab selected)
                var transactionType = data.transactionType;
                if (transactionType) {
                    switch (transactionType) {
                        case 'sale':
                            transactionType = 'purchase';
                            break;
                        case 'authorize':
                            transactionType = 'authorize';
                            break;
                        case 'capture':
                            transactionType = 'capture';
                            break;
                        case 'open refund':
                        case 'naked_refund':
                            transactionType = 'naked_refund';
                            break;
                        case 'refund':
                            transactionType = 'refund';
                            break;
                        case 'void':
                            transactionType = 'void';
                            break;
                        default:
                            transactionType = 'purchase';
                            break;
                    }

                    self.transaction_type = transactionType;
                }

                // Set card expiry month and year
                var cardExpiry = data.card_expiry;
                if (cardExpiry) {
                    var cardExpiryObj = CardService.getCardExpiry(cardExpiry);
                    if (cardExpiryObj) {
                        angular.extend(self, cardExpiryObj);
                    }
                }
            }
        }

        /**
         * Set transaction info.
         *
         * @param {Object} data - Data to set transaction request object with
         * @param {Boolean} nullNotAllowed - (Optional) Allow null values to override properties.
         * By default, this is set to false - any value is allowed to support overriding.
         * Set to true if only non-null values are allowed.
         */
        this.setTransactionInfo = function (data) {
            _.extend(self, data);
            populateAdditionalRequestData(data);
        };

        // Initialize transaction request data
        Transaction.call(this);
        angular.extend(self, transactionRequestData);
        self.setTransactionInfo(initialData);

        return self;
    };

    /*
     * Transaction Response Object constructor.
     *
     * @constructor A subclass constructor for Transaction Responses
     * @param {Object} initialData - Data to initialize transaction request object with
     */
    var TransactionResponse = function (initialData) {
        var self = this,
            transactionResponseData = {
                id: null,
                amount_refunded: null,
                auth_code: null,
                captured: null,
                customer_name: null,
                date: null,
                latitude: null,
                longitude: null,
                payfirma_supervisor: null,
                processor_transaction_id: null,
                status: null,
                test_mode: false,
                time: null,
                transaction_channel: null,
                transaction_source: null,
                user_id: null,
                user_name: null
            },
            additionalTransactionResponseData = {
                addressWithCountry: null,
                addressWithPostal: null,
                currencyAmount: null,
                cardIsCredit: false,
                cardCvvMaskedValue: null,
                cardNumberMaskedValue: null,
                hasCustomerInfo: false,
                isAllowCapture: false,
                isAllowRefund: false,
                statusColour: null,
                statusMessage: null,
                transactionSourceLabel: null,
                transactionTypeLabel: null
            };

        /*
         * Get transaction card type
         *
         * @param {String} cardType - Transaction card type
         * @param {String} transactionType - (Optional) Transaction type being performed
         * @return {String} type - Card type label of transaction
         */
        function getCardType(cardType, transactionType) {
            var type = (cardType === 'Other' &&
            (transactionType && transactionType !== 'external_purchase' && transactionType !== 'external_refund')) ?
                'Credit' : cardType;
            return type;
        }

        /*
         * Get transaction source label
         *
         * @param {String} transactionSource - Source of transaction being performed
         * @return {String} source - Transaction source label
         */
        function getSourceLabel(transactionSource) {
            var source;
            switch (transactionSource){
                case 'VT':
                    source = TRANSLATIONS.WEB_TERMINAL;
                    break;
                case 'E_COMMERCE':
                    source = TRANSLATIONS.ECOMMERCE;
                    break;
                case 'MOBILE':
                    source = TRANSLATIONS.MOBILE;
                    break;
                case 'RECURRING':
                    source = TRANSLATIONS.RECURRING;
                    break;
                case 'INVOICE':
                    source = TRANSLATIONS.INVOICING;
                    break;
                case 'CARD_TERMINAL':
                    source = TRANSLATIONS.CARD_TERMINAL;
                    break;
                default:
                    source = transactionSource;
                    break;
            }
            return source;
        }

        /*
         * Get transaction type label
         *
         * @param {String} transactionType - Transaction type being performed
         * @return {String} type - Transaction type label
         */
        function getTypeLabel(transactionType) {
            var type;
            var givenType = transactionType.toLowerCase();
            var group = {
                authorization: [
                    'authorize',
                    'AUTHORIZE',
                    'terminal_authorize'
                ],
                sale: [
                    'sale',
                    'SALE',
                    'purchase',
                    'cash_purchase',
                    'cash purchase',
                    'check_purchase',
                    'check purchase',
                    'external_purchase',
                    'external purchase',
                    'terminal_purchase',
                    'terminal purchase',
                    'google_pay_purchase'
                ],
                capture: [
                    'capture',
                    'CAPTURE',
                    'terminal_capture',
                    'terminal capture'
                ],
                refund: [
                    'refund',
                    'REFUND',
                    'cash_refund',
                    'cash refund',
                    'check_refund',
                    'check refund',
                    'external_refund',
                    'external refund',
                    'naked_refund',
                    'naked refund', // in some places there is an underscore, and some not
                    'terminal_refund',
                    'terminal refund'
                ],
                voidType: [
                    'void',
                    'VOID'
                ]
            };
            if (group.authorization.indexOf(givenType) >= 0){
                type = 'Authorization';
            } else if (group.sale.indexOf(givenType) >= 0){
                type = 'Sale';
            } else if (group.capture.indexOf(givenType) >= 0){
                type = 'Capture';
            } else if (group.refund.indexOf(givenType) >= 0){
                type = 'Refund';
            } else if (group.voidType.indexOf(givenType) >= 0) {
                type = 'Void';
            } else if (givenType==='eft_credit') {
                type = 'EFT CREDIT';
            } else if (givenType==='eft_debit') {
                type = 'EFT DEBIT';
            } else {
                type = 'UNKNOWN';
            }
            return type;
        }

        /*
         * Set has customer info flag
         *
         * @param {Object} data - Transaction data to check
         * @return {Boolean} type -True if transaction has customer info. False otherwise.
         */
        function setTransactionHasCustomerInfo(data) {
            var conditionalOperatorCheckOne = data.firstname || data.lastname || data.email || data.company;
            var conditionalOperatorCheckTwo = data.address1 || data.address2 || data.city || data.province;
            var conditionalOperatorCheckThree = data.postalcode || data.country || data.telephone;
            if ( conditionalOperatorCheckOne || conditionalOperatorCheckTwo || conditionalOperatorCheckThree ) {
                return true;
            }
            return false;
        }

        /*
         * Sets whether transaction should allow captures.
         * Only Approved Authorization transactions that haven't been captured yet can be captured.
         *
         * @param {Boolean} isCaptured - Whether the transaction has been captured yet or not
         * @param {String} status - Transaction status
         * @param {String} type - Transaction type
         * @returns {Boolean} allowCapture - Returns true if captures are allowed.
         * False otherwise.
         */
        function setAllowCapture(isCaptured, status, type) {
            if (!isCaptured && status === 'Approved' && type === 'Authorization') {
                return true;
            }
            return false;
        }

        /*
         * Sets whether transaction should allow refunds.
         * Only Approved Sale and Capture transactions can be refunded.
         * TabletPOS transactions can only be refunded on the tablet.
         *
         * @param {String} source - Transaction source
         * @param {String} status - Transaction status
         * @param {String} type - Transaction type
         * @returns {Boolean} allowRefund - Returns true if refunds are allowed.
         * False otherwise.
         */
        function setAllowRefund(source, status, type, cardtype) {
            var hasBeenRefunded = self.getIsRefunded();
            var isAllowedRefund = true;
            var isNotCaptureSaleApprovedTabletPOSRefunded = (source !== 'TabletPOS' && status === 'Approved' &&
                (type === 'Sale' || type === 'Capture') && !hasBeenRefunded);
            if (isNotCaptureSaleApprovedTabletPOSRefunded && (source === 'Mobile' && cardtype === 'Check')) {
                isAllowedRefund = false;
            }
            return isAllowedRefund;
        }

        /*
         * Populate card information response specific properties.
         *
         * @param {String} type - Transaction type
         * @param {String} cardType - Card type
         * @param {String} cardSuffix - Card suffix
         */
        function populateCardInformation(type, cardType, cardSuffix) {
            // Set card type
            if (cardType) {
                self.card_type = getCardType(cardType, type);
            }

            // Set whether this transaction is a credit or not
            if (cardType && cardType !== 'Cash' && cardType !== 'Credit') {
                self.cardIsCredit = true;
            }

            // Set card mask values
            if (cardSuffix) {
                self.cardNumberMaskedValue = '************' + cardSuffix;
                self.cardCvvMaskedValue = '***';
                if (cardType === 'Amex') {
                    self.cardNumberMaskedValue = '***********' + cardSuffix;
                    self.cardCvvMaskedValue = '****';
                }
            }
        }

        /*
         * Populate additional transaction source, type and status properties.
         *
         * @param {String} source - Transaction source
         * @param {String} type - Transaction type
         * @param {String} status - Transaction status
         */
        function populateTransactionInformation(source, type, status) {
            if (source) {
                self.transactionSourceLabel = getSourceLabel(source);
            }

            // Get transaction type label
            if (type) {
                self.transactionTypeLabel = getTypeLabel(type);
            }

            // Get transaction status displayed message
            if (status) {
                self.statusColour = (status === 'Approved') ? 'green' : 'red';
                if (self.transactionTypeLabel) {
                    self.statusMessage = self.transactionTypeLabel + ' ' + status;
                }
            }
        }

        function getTransactionType(data) {
            let transactionType = data.transaction_type;
            if (transactionType) {
                switch (transactionType) {
                    case 'SALE':
                    case 'GOOGLE_PAY_PURCHASE':
                        transactionType = window.PAYFIRMA.TRANSACTION_TYPE.SALE;
                        break;
                    case 'AUTHORIZE':
                        transactionType = window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE;
                        break;
                    case 'CAPTURE':
                        transactionType = window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE;
                        break;
                    case 'NAKED_REFUND':
                        transactionType = window.PAYFIRMA.TRANSACTION_TYPE.NAKED_REFUND;
                        break;
                    case 'REFUND':
                        transactionType = window.PAYFIRMA.TRANSACTION_TYPE.REFUND;
                        break;
                    case 'VOID':
                        transactionType = window.PAYFIRMA.TRANSACTION_TYPE.VOID;
                        break;
                    case 'EFT_CREDIT':
                        transactionType = window.PAYFIRMA.TRANSACTION_TYPE.EFT_CREDIT;
                        break;
                    case 'EFT_DEBIT':
                        transactionType = window.PAYFIRMA.TRANSACTION_TYPE.EFT_DEBIT;
                        break;
                    default:
                        transactionType = '';
                        break;
                }
            }

            return transactionType;
        }
        /*
         * Populate additional transaction response specific properties.
         *
         * @param {Object} data - Transaction data to parse
         */
        function populateAdditionalResponseData(data) {

            var amount = $filter('currency')(data.amount);
            var cardSuffix = data.card_suffix;
            var cardType = data.card_type;
            var city = data.city ? data.city : '';
            var country = data.country ? data.country : '';
            var currency = data.currency;
            var date = data.date;
            var postal = data.postalcode ? data.postalcode : '';
            var province = data.province ? data.province : '';
            var source = data.transaction_source;
            var status = data.status;
            var time = data.time;
            var type = data.transaction_type;

            // Populate currency and amount values
            if (amount && currency) {
                self.currencyAmount = currency + ' ' + amount;
            }

            // Format dates and times if not already formatted
            if (date && time && (time.indexOf('am') === -1 && time.indexOf('pm') === -1)) {
                var datetime = window.moment(date + ' ' + time).format('MMM DD, YYYY-h:mma'),
                    dateobj = datetime.split('-');
                self.date = dateobj[0];
                self.time = dateobj[1];
            }

            // Set has customer info flag and other related info
            var hasCustomerInfo = setTransactionHasCustomerInfo(data);
            if (hasCustomerInfo) {
                self.hasCustomerInfo = hasCustomerInfo;

                // Format address info
                var address = city;
                if (address) {
                    address += ', ';
                }
                address += province;
                if (address) {
                    address += ' ';
                }
                // In some places we only want the postal or country
                self.addressWithPostal = address + postal;
                self.addressWithCountry = address + country;
            }

            populateCardInformation(type, cardType, cardSuffix);
            populateTransactionInformation(source, type, status);

            // Set capture and refund statuses
            self.isAllowCapture = setAllowCapture(self.getIsCaptured(), self.getStatus(), self.getType());
            self.isAllowRefund = setAllowRefund(self.getSource(),
                self.getStatus(), self.getType(), self.getCardType());
        }


        /**
         * Get whether card is a credit card or not.
         *
         * @returns {Boolean} cardIsCredit - Returns true if the card is a credit.
         * Otherwise, false.
         */
        this.getCardIsCredit = function () {
            return self.cardIsCredit;
        };

        /**
         * Get card number masked value.
         *
         * @returns {String} cardNumberMaskedValue - Returns the card number masked value
         */
        this.getCardNumberMaskedValue = function () {
            return self.cardNumberMaskedValue;
        };

        /**
         * Get card cvv masked value.
         *
         * @returns {String} cardCvvMaskedValue - Returns the card cvv masked value
         */
        this.getCardCvvMaskedValue = function () {
            return self.cardCvvMaskedValue;
        };

        /**
         * Get transaction card suffix.
         *
         * @returns {String} cardSuffix - Returns the transaction card suffix
         */
        this.getCardSuffix = function () {
            return self.card_suffix;
        };

        /**
         * Get transaction card type.
         *
         * @returns {String} cardType - Returns the transaction card type
         */
        this.getCardType = function () {
            return self.card_type;
        };

        /**
         * Get transaction currency.
         *
         * @returns {String} currency - Returns the transaction currency
         */
        this.getCurrency = function () {
            return self.currency;
        };

        /**
         * Get transaction currency and amount.
         *
         * @returns {String} currencyAmount - Returns the transaction currency and amount
         */
        this.getCurrencyAmount = function () {
            return self.currencyAmount;
        };

        /**
         * Get transaction customer email.
         *
         * @returns {String} email - Returns the transaction customer email. If
         * there are multiple emails, just return the first one which will be the
         * primary customer.
         */
        this.getCustomerEmail = function () {
            var customerEmail;
            if (self.emailList && self.emailList.length > 0) {
                customerEmail = self.emailList[0];
            } else {
                customerEmail = self.email;
            }
            return customerEmail;
        };

        /**
         * Get transaction customer full name.
         *
         * @returns {String} name - Returns the transaction customer full name
         */
        this.getCustomerFullname = function () {
            var name = self.firstname;
            var lastname = self.lastname;
            if (lastname) {
                name += ' ' + lastname;
            }
            return name;
        };

        /**
         * Get transaction customer telelphone.
         *
         * @returns {String} telephone - Returns the transaction customer telephone
         */
        this.getCustomerTelephone = function () {
            return self.telephone;
        };

        /**
         * Get transaction date.
         *
         * @returns {String} date - Returns the transaction date
         */
        this.getEpochByFormat = function (format) {
            var momentValue;
            switch (format) {
                case 'date':
                    momentValue = TimeService.epochToDate(self.transaction_time);
                    break;
                case 'time':
                    momentValue = TimeService.epochToTime(self.transaction_time);
                    break;
                case 'dateAndTime':
                    momentValue = TimeService.epochToDateAndTime(self.transaction_time);
                    break;
                default:
                    momentValue = '';
            }
            return momentValue;
        };

        /**
         * Get transaction description.
         *
         * @returns {String} description - Returns the transaction description
         */
        this.getDescription = function () {
            return self.description;
        };

        /**
         * Get whether the transaction has any customer info.
         *
         * @returns {Boolean} hasCustomerInfo - Returns true if the transaction
         * has any customer info. False otherwise.
         */
        this.getHasCustomerInfo = function () {
            return self.hasCustomerInfo;
        };

        /**
         * Get transaction id.
         *
         * @returns {Number} id - Returns the transaction id
         */
        this.getId = function () {
            return self.id;
        };

        /**
         * Get transaction invoice id.
         *
         * @returns {Number} invoiceId - Returns the transaction invoice id
         */
        this.getInvoiceId = function () {
            return self.invoice_id;
        };

        /**
         * Get transaction is recurring flag.
         *
         * @returns {Boolean} isRecurring - Returns true if this is a recurring transaction.
         * False otherwise.
         */
        this.getIsRecurring = function () {
            return self.is_recurring;
        };

        /**
         * Gets whether transaction should allow captures.
         *
         * @returns {Boolean} allowCapture - Returns true if captures are allowed.
         * False otherwise.
         */
        this.getAllowCapture = function () {
            return self.isAllowCapture;
        };

        /**
         * Gets whether transaction should allow refunds.
         *
         * @returns {Boolean} allowRefund - Returns true if refunds are allowed.
         * False otherwise.
         */
        this.getAllowRefund = function () {
            return self.isAllowRefund;
        };

        /**
         * Get if transaction has already been captured.
         *
         * @returns {Boolean} isCaptured - Returns true if the transaction has already been
         * captured.  False otherwise.
         */
        this.getIsCaptured = function () {
            return !!self.captured;
        };

        /**
         * Get if transaction has already been refunded.
         *
         * @returns {Boolean} isRefunded - Returns true if the transaction has already been
         * fully refunded.  False otherwise.
         */
        this.getIsRefunded = function () {
            //make sure to force amount to number instead of string
            var amount = parseFloat(self.amount);
            var amountRefunded = parseFloat(self.amount_refunded);
            return !!(self.amount_refunded && amountRefunded >= amount);
        };

        /**
         * Get transaction order id.
         *
         * @returns {String} orderId - Returns the transaction order id
         */
        this.getOrderId = function () {
            return self.order_id;
        };

        /**
         * Get transaction original id.
         *
         * @returns {Number} originalId - Returns the transaction original id
         */
        this.getOriginalId = function () {
            return self.original_id;
        };

        /**
         * Get transaction sales person.
         *
         * @returns {String} salesPerson - Returns the transaction sales person
         */
        this.getSalesPerson = function () {
            return self.user_name;
        };

        /**
         * Get transaction source.
         *
         * @returns {String} source - Returns the transaction source
         */
        this.getSource = function () {
            return self.transactionSourceLabel;
        };

        /**
         * Get transaction status.
         *
         * @returns {String} status - Returns the transaction status
         */
        this.getStatus = function () {
            return self.status;
        };

        /**
         * Get transaction status colour.
         *
         * @returns {String} statusColour - Returns the transaction status colour
         */
        this.getStatusColour = function () {
            return self.statusColour;
        };

        /**
         * Get transaction status message.
         *
         * @returns {String} statusMessage - Returns the transaction status message
         */
        this.getStatusMessage = function () {
            if ($translate.use() === 'fr') {
                if (self.statusMessage === 'Sale Approved') {
                    return 'Vente Approuvée';
                } else if (self.statusMessage === 'Capture Approved') {
                    return 'Saisi Approuvée';
                } else if (self.statusMessage === 'Authorization Approved') {
                    return 'Autorisation Approuvée';
                } else if (self.statusMessage === 'Sale Declined') {
                    return 'Vente Refusée';
                } else if (self.statusMessage === 'Capture Declined') {
                    return 'Saisi Refusée';
                } else if (self.statusMessage === 'Authorization Declined') {
                    return 'Autorisation Refusée';
                } else if (self.statusMessage === 'Refund Approved') {
                    return 'Rembourser Approuvé';
                } else if (self.statusMessage === 'Refund Declined') {
                    return 'Rembourser Refusé';
                } else {
                    return self.statusMessage;
                }
            } else {
                if (self.statusMessage === 'Refund BitcoinRefundPending') {
                    return 'Bitcoin Refund Pending';
                }
                if (self.statusMessage === 'Sale BitcoinSalePending') {
                    return 'Bitcoin Sale Pending';
                }
                return self.statusMessage;
            }
        };

        /**
         * Get transaction time.
         *
         * @returns {String} time - Returns the transaction time
         */
        this.getTime = function () {
            return self.time;
        };

        /**
         * Get transaction type.
         *
         * @returns {String} type - Returns the transaction type
         */
        this.getType = function () {
            return self.transactionTypeLabel;
        };

        /**
         * Show transaction type.
         *
         * @returns {String} type - Returns the transaction type
         */
        this.showType = function () {
            if (locale.getLocale() === 'fr-FR') {
                if (self.transactionTypeLabel === 'Sale') {
                    return 'Vente';
                } else if (self.transactionTypeLabel === 'Capture') {
                    return 'Saisi';
                } else if (self.transactionTypeLabel === 'Authorization') {
                    return 'Autorisation';
                } else if (self.transactionTypeLabel === 'Refund') {
                    return 'Rembourser';
                }
            } else {
                return self.transactionTypeLabel;
            }
        };

        /**
         * Set transaction info.
         *
         * @param {Object} data - Data to set transaction response object with
         * @param {Boolean} nullNotAllowed - (Optional) Allow null values to override properties.
         * By default, this is set to false - any value is allowed to support overriding.
         * Set to true if only non-null values are allowed.
         */
        this.setTransactionInfo = function (data) {
            _.extend(self, data);
            populateAdditionalResponseData(data);
        };

        function copyFormOriginalTransaction(originalTransaction) {
            let propertyList = ['address1', 'address2', 'amount_tax', 'amount_tip', 'amount_surcharge', 'amount_cashback', 'bcc_email',
                'card_expiry', 'card_suffix', 'card_type', 'city', 'company', 'country', 'currency', 'customer_id',
                'description', 'email', 'emailList', 'firstname', 'first_name', 'invoice_id', 'is_recurring',
                'lastname', 'last_name', 'order_id', 'original_id', 'postalcode', 'postal_code', 'province',
                'recurring_plan_id', 'send_receipt', 'telephone'];

            _.each(propertyList, function (property) {
                let originalValue = originalTransaction[property];
                if (originalValue) {
                    self[property] = originalValue;
                }
            });
        }

        this.copyFromOriginalTransaction = function(data) {
            copyFormOriginalTransaction(data);
            populateAdditionalResponseData(data);
        };

        // Initialize transaction response data
        Transaction.call(this);
        angular.extend(self, transactionResponseData);
        angular.extend(self, additionalTransactionResponseData);
        self.setTransactionInfo(initialData);

        /**
         * Convert epoch time to human time strings
         */
        function epochToHuman(epoch, category) {
            var momentValue;
            switch (category) {
                case 'date':
                    momentValue = TimeService.epochToDate(epoch);
                    break;
                case 'time':
                    momentValue = TimeService.epochToTime(epoch);
                    break;
                case 'dateAndTime':
                    momentValue = TimeService.epochToDateAndTime(epoch);
                    break;
                default:
                    momentValue = '';
                    break;
            }
            return momentValue;
        }

        /*
         * Get transaction type label
         *
         * @param {String} transactionType - Transaction type being performed
         * @return {String} type - Transaction type label
         */
        function toTransactionTypeLabel(transactionType) {

            var type;
            var givenType = transactionType? transactionType.toLowerCase() : '';
            var group = {
                authorization: [
                    'authorize',
                    'terminal_authorize'
                ],
                sale: [
                    'sale',
                    'purchase',
                    'cash_purchase',
                    'cash purchase',
                    'check_purchase',
                    'check purchase',
                    'external_purchase',
                    'external purchase',
                    'terminal_purchase',
                    'terminal purchase',
                    'google_pay_purchase'
                ],
                capture: [
                    'capture',
                    'terminal_capture',
                    'terminal capture'
                ],
                refund: [
                    'refund',
                    'cash_refund',
                    'cash refund',
                    'check_refund',
                    'check refund',
                    'external_refund',
                    'external refund',
                    'naked_refund',
                    'naked refund', // in some places there is an underscore, and some not
                    'terminal_refund',
                    'terminal refund'
                ],
                voidType: [
                    'void',
                    'VOID'
                ]
            };
            if (group.authorization.indexOf(givenType) >= 0) {
                type = 'Authorization';
            } else if (group.sale.indexOf(givenType) >= 0) {
                type = 'Sale';
            } else if (group.capture.indexOf(givenType) >= 0) {
                type = 'Capture';
            } else if (group.refund.indexOf(givenType) >= 0) {
                type = 'Refund';
            } else if (group.voidType.indexOf(givenType) >= 0) {
                type = 'Void';
            } else if (givenType==='eft_credit') {
                type = 'EFT CREDIT';
            } else if (givenType==='eft_debit') {
                type = 'EFT DEBIT';
            } else {
                type = 'UNKNOWN';
            }

            return type;
        }

        function toTransactionChannelLabel(transactionSource) {
            var source;

            if (transactionSource === 'VT') {
                source = window.PAYFIRMA.TEXT_STRINGS.WEBTERMINAL;
            } else if (transactionSource === 'CARD_TERMINAL') {
                source = window.PAYFIRMA.TEXT_STRINGS.CARDTERMINAL;
            } else if (transactionSource === 'E_COMMERCE') {
                source = window.PAYFIRMA.TEXT_STRINGS.ECOMMERCE;
            } else if (transactionSource === 'TABLET_POS') {
                source = window.PAYFIRMA.TEXT_STRINGS.TABLETPOS;
            } else if (transactionSource === 'INVOICE') {
                source = window.PAYFIRMA.TEXT_STRINGS.INVOICING;
            } else if (transactionSource === 'MOBILE') {
                source = window.PAYFIRMA.TEXT_STRINGS.MOBILE;
            } else if (transactionSource === 'RECURRING') {
                source = window.PAYFIRMA.TEXT_STRINGS.RECURRING;
            } else if (transactionSource === 'Naked Refund') {
                source = 'Open Refund';
            } else if (transactionSource === 'IFRAME') {
                source = window.PAYFIRMA.TEXT_STRINGS.IFRAME;
            } else if (transactionSource === 'PAY_SUBSCRIPTION') {
                source = window.PAYFIRMA.TEXT_STRINGS.PAY_SUBSCRIPTION;
            } else if (transactionSource === 'SHOPIFY') {
                source = window.PAYFIRMA.TEXT_STRINGS.SHOPIFY;
            } else {
                source = transactionSource;
            }
            return source;
        }

        function toFullName(firstName, lastName) {
            let name = '';
            if (firstName) {
                name = firstName;
            }

            if (lastName) {
                if (firstName) {
                    name+=' '+lastName;
                } else {
                    name = lastName;
                }
            }
            return name;
        }

        const transactionTime = self.transaction_time;
        if (transactionTime) {
            self.transactionDateSring = epochToHuman(transactionTime, 'date');
            self.transactionTimeString = epochToHuman(transactionTime, 'time');
            self.transactionDateTimeString = epochToHuman(transactionTime, 'dateAndTime');
        }

        const transactionSource = self.transaction_source;
        if (transactionSource) {
            self.channelLabel = toTransactionChannelLabel(transactionSource);
        }

        const transactionType = self.transaction_type;
        if (transactionType) {
            self.transactionTypeLabel = toTransactionTypeLabel(transactionType);
        }

        //BUG-884 for full text search
        self.fullName = toFullName(self.first_name, self.last_name);

        self.transactionType = getTransactionType(initialData);
        return self;
    };


    /**
     * Create a transaction request object.
     *
     * @param {Object} data - Data to set transaction request to
     * @returns {Object} Transaction - Returns a transaction request object
     */
    TransactionFactory.createTransaction = function (data) {
        return new TransactionRequest(data);
    };

    /**
     * Create a transaction response object.
     *
     * @param {Object} data - Data to set transaction response to
     * @param {Object} originalRequestTransaction - Additional data to set
     * transaction response to
     * @returns {Object} Transaction - Returns a transaction response object
     */
    TransactionFactory.parseResponse = function (data, originalRequestTransaction) {
        var newTransactionResponse = new TransactionResponse(data);
        if (originalRequestTransaction) {
            newTransactionResponse.copyFromOriginalTransaction(originalRequestTransaction);
        }
        return newTransactionResponse;
    };

    return TransactionFactory;
});
