/* @flow */
import angular from 'angular';
import ConfigurationService from 'service/configuration-service';
/**
 * @ngdoc service
 * @name payhqUIApp.payhqUIApp.service:CardService
 * @description
 *
 * Service for handling credit card functions
 *
 */
export default angular
    .module('payhqUIApp')
    .service('CardService', function CardService($log, CARDTYPE, REGEX, HttpClientService) {

        return {
            /**
             * Get card expiry
             *
             * @param {string} value - Card number to be evaluated
             * @returns {Object} Returns the card expiry object
             */
            getCardExpiry: function (value) {
                var cardExpiryObject = {};
                if (value.indexOf('/') === -1) {
                    cardExpiryObject.card_expiry_month = value.substring(0, 2);
                    cardExpiryObject.card_expiry_year = value.substring(2);
                    cardExpiryObject.card_expiry = cardExpiryObject.card_expiry_month
                        + '/' + cardExpiryObject.card_expiry_year;
                } else {
                    cardExpiryObject.card_expiry_month = value.substring(0, 2);
                    cardExpiryObject.card_expiry_year = value.substring(3);
                    cardExpiryObject.card_expiry = value;
                }
                return cardExpiryObject;
            },

            /**
             * Get card expiry status
             *
             * @param {string} month - Card expiry month
             * @param {string} year - Card expiry year
             * @returns {Boolean} Returns true if the card is expired,
             */
            getCardExpiryStatus: function (month, year) {

                // Since [month] is only the last two digits of the year,
                // we add the first two digits of this century. eg. 17 -> 2017
                var cardYear = '20' + year;
                // We have to subtract the expiry month by 1 because Moment.js months are from 0-11.
                var cardMonth = month - 1;
                var cardDate = window.moment([cardYear, cardMonth, 28]);
                var thisDay = window.moment();
                var isCardExpired = window.moment(cardDate).isBefore(thisDay);

                return isCardExpired;
            },

            /**
             * Get months until expiry
             *
             * @param {string} month - Card expiry month
             * @param {string} year - Card expiry year
             * @returns {Boolean} Returns number of months till expirary or -1 for expired
             */
            getCardMonthsUntilExpiry: function (month, year) {
                // Since [month] is only the last two digits of the year,
                // we add the first two digits of this century. eg. 17 -> 2017
                var cardYear = '20' + year;
                var cardDate = window.moment(cardYear + '-' + month);
                var thisDay = window.moment().format('YYYY-MM');
                var isCardExpired = window.moment(cardDate).isBefore(thisDay);
                var warningMonths = [0, 1, 2, 3];
                var monthsAhead = warningMonths;
                var expiresInMonth = warningMonths;
                var expiryMonth;
                var almostExpires = false;

                //use moment to check to see if card expires in: 0, 1, 2, or 3 months
                monthsAhead.forEach(function (value, index) {
                    var cardExpires;
                    var currentDate = window.moment().add(value, 'month').format('YYYY-MM');
                    cardExpires = window.moment(cardDate).isSameOrBefore(currentDate);
                    if (!almostExpires && cardExpires) {
                        expiryMonth = expiresInMonth[index];
                        almostExpires = true;
                    }
                });

                //If credit card is expired return -1 months
                if (isCardExpired){
                    expiryMonth = -1;
                }

                return expiryMonth;
            },

            /**
             * Get card prefix
             *
             * @param {string} value - Card number to be evaluated
             * @returns {string} Returns the first 4 digits of the card number
             */
            getCardPrefix: function (value) {
                var cardPrefix = null;
                if (value && value.length > 4) {
                    cardPrefix = value.slice(0, 4);
                }
                return cardPrefix;
            },

            /**
             * Get card suffix
             *
             * @param {string} value - Card number to be evaluated
             * @returns {string} Returns the last 4 digits of the card number
             */
            getCardSuffix: function (value) {
                var cardSuffix = null;
                if (value && value.length > 4) {
                    cardSuffix = value.slice(value.length - 4);
                }
                return cardSuffix;
            },

            // Save new [card] to [customer]
            saveNewCard: function(card, customerLookupId){
                var cardObject = {
                    'card_expiry_month': card.card_expiry_month,
                    'card_expiry_year': card.card_expiry_year,
                    'card_number': card.card_number,
                    'cvv2': card.card_cvv || undefined,
                    'is_default': card.is_default,
                    'card_description': card.card_description,
                    'is_through_customer_vault': card.is_through_customer_vault,
                    'captcha': card.captcha
                };
                return HttpClientService.doPost(
                    ConfigurationService.getConfig()
                    .customerServiceApi + 'customer/' + customerLookupId + '/card', cardObject)
                .then(function(response){
                    return response;
                }).catch(function(error){
                    throw error;
                });
            },

            updateExistingCard: function(card, customerLookupId){
                var cardObject = {
                    'card_expiry_month': card.card_expiry_month || undefined,
                    'card_expiry_year': card.card_expiry_year || undefined,
                    'cvv2': card.card_cvv || undefined,
                    'is_default': card.is_default,
                    'card_description': card.card_description
                };
                return HttpClientService.doPatch(
                    ConfigurationService.getConfig()
                    .customerServiceApi + 'customer/' + customerLookupId + '/card/' + card.lookup_id, cardObject)
                .then(function(response){
                    return response;
                }).catch(function(error){
                    throw error;
                });
            },
            deleteExistingCard: function(removedCreditCardLookupId, customerLookupId){
                return HttpClientService.doDelete(
                    ConfigurationService.getConfig()
                    .customerServiceApi + 'customer/' + customerLookupId + '/card/' + removedCreditCardLookupId)
                .then(function(response){
                    return response;
                }).catch(function(error){
                    throw error;
                });
            },

            /**
             * Get card type.
             *
             * @param {string} value - Card number to be evaluated
             * @returns {string} cardType - Card type
             */
            getCardType: function (cardNumber) {
                if (REGEX.CARD_MASTERCARD.test(cardNumber)) {
                    return CARDTYPE.MASTERCARD;
                } else if (REGEX.CARD_VISA.test(cardNumber)) {
                    return CARDTYPE.VISA;
                } else if (REGEX.CARD_AMERICAN_EXPRESS.test(cardNumber)) {
                    return CARDTYPE.AMEX;
                } else if (REGEX.CARD_DISCOVER.test(cardNumber)) {
                    return CARDTYPE.DISCOVER;
                } else if (REGEX.CARD_JCB.test(cardNumber)) {
                    return CARDTYPE.JCB;
                } else {
                    return '';
                }
            }
        };
    });
