'use strict';

function previewThemeLogin() {
    var _this = this;
    let previewThemeCss = window.localStorage.getItem('preview-theme-css');

    function injectCssStringToHtml(cssString) {
        $('<style>' + cssString + '</style>'
        ).appendTo('head');
    }

    function loadStyles(){
        if (previewThemeCss){
            injectCssStringToHtml(previewThemeCss);
        }
    }

    _this.$onInit = function() {
        loadStyles();
    };
}
angular.module('payhqUIApp').component('previewThemeLogin', {
    bindings: {
        data: '='
    },
    controller: previewThemeLogin,
    controllerAs: 'previewThemeLogin',
    templateUrl: 'view/preview-theme-login/preview-theme-login.html'
});
