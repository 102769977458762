import angular from 'angular';
import SessionStorageService from 'service/session-storage-service';
import ConfigurationService from 'service/configuration-service';
import ExportFileService from 'service/export-file-service';
import _ from 'lodash';
/**
 * @ngdoc service
 * @name payhqUIApp.customerApp.service:InvoiceService
 * @description
 *
 * Service for handling customer card behaviours
 *
 */
angular
.module('payhqUIApp')
.service('InvoiceService', function ($http, $log, $route, HttpClientService, $window) {
    let _this = this;

    function getInvoiceApiUrl() {
        let hqconfig = ConfigurationService.getConfig();
        return hqconfig.invoiceApi;
    }

    function getInvoices(searchOptions) {
        let param = _.extend({}, searchOptions.searchParameters);
        const limit = searchOptions ? searchOptions.limit : undefined;
        const cursor = searchOptions ? searchOptions.cursor : undefined;
        const backwards = searchOptions ? searchOptions.backwards : false;

        if (limit) {
            param.limit = limit;
        }
        if (cursor) {
            if (backwards) {
                param.before = cursor;
            } else {
                param.after = cursor;
            }
        }

        return HttpClientService.doGet(getInvoiceApiUrl() + 'invoice', param)
            .then((serverResponse) => {
                let invoicesData = serverResponse.data;
                let returnedCursor;
                if (invoicesData.paging) {
                    returnedCursor = invoicesData.paging.cursors;
                }
                return new window.PAYFIRMA.Paging(returnedCursor, invoicesData.invoices);
            });
    }

    function initializeEndpointAndgetInvoices(searchOptions) {
        return getInvoices(searchOptions);
    }

    _this.exportService = new ExportFileService($window);

    _this.getFormattedDate = (date) => {
        var DATE_FORMAT = 'MMM DD YYYY';
        if (date) {
            return window.moment.unix(date / 1000).format(DATE_FORMAT);
        } else {
            return window.moment.unix().format(DATE_FORMAT);
        }

    };

    _this.getInvoice = (invoiceId, authToken) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        //convert all upcase enum returns too first captials
        var token = authToken;
        if (!token) {
            token = currentUserInfo.jwt;
        }
        // From coinfig.josn file
        return $http({
            method: 'GET',
            url: getInvoiceApiUrl() + 'invoice/' + invoiceId,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).success((response) => {
            var invoice = response;
            var currentStatus = invoice.status;
            currentStatus = currentStatus.toLowerCase();
            invoice.status = currentStatus.charAt(0).toUpperCase() + currentStatus.slice(1);
            return invoice;
        }).error((response) => {
            return response;
        });
    };

    _this.getInitialData = (searchOptions) => {
        return initializeEndpointAndgetInvoices({
            limit: searchOptions && searchOptions.limit ? searchOptions.limit :
                window.PAYFIRMA.CONSTANT.PAGINATION_INITIAL_LOAD_BATCH_SIZE,
            cursor: undefined,
            searchParameters: searchOptions ? searchOptions.searchParameters : undefined,
            backwards: false
        });
    };

    _this.getNext = function (searchOptions) {
        return initializeEndpointAndgetInvoices(
            {
                limit: searchOptions && searchOptions.limit ? searchOptions.limit :
                    window.PAYFIRMA.CONSTANT.PAGINATION_LOAD_BATCH_SIZE,
                cursor: searchOptions ? searchOptions.cursor : undefined,
                searchParameters: searchOptions ? searchOptions.searchParameters : undefined,
                backwards: false
            });
    };

    _this.getPrevious = function (searchOptions) {
        return initializeEndpointAndgetInvoices(
            {
                limit: searchOptions && searchOptions.limit ? searchOptions.limit :
                    window.PAYFIRMA.CONSTANT.PAGINATION_LOAD_BATCH_SIZE,
                cursor: searchOptions ? searchOptions.cursor : undefined,
                searchParameters: searchOptions ? searchOptions.searchParameters : undefined,
                backwards: true
            });
    };

    /**
     * Pay a Invoice
     *
     *
     *
     */
    _this.payInvoice = (invoiceId, cardDetails, authToken) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var token = authToken;
        if (!token) {
            token = currentUserInfo.jwt;
        }
        // From coinfig.josn file
        return $http({
            method: 'POST',
            url: getInvoiceApiUrl() + 'invoice/' + invoiceId + '/pay',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: cardDetails
        }).then(
            function success(response) {
                return response;
            },
            function error(response) {
                return response;
            }
        );
    };

    /**
     * Pay a Invoice
     *
     *
     *
     */
    _this.sendInvoiceEmail = (invoiceId, email) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var token = currentUserInfo.jwt;
        var invoiceApiUrl = getInvoiceApiUrl();
        var endPoint = 'invoice/' + invoiceId + '/send';
        if (email) {
            endPoint = 'invoice/' + invoiceId + '/send/' + email;
        }
        return $http({
            method: 'POST',
            url: invoiceApiUrl + endPoint,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).then(
            function success(response) {
                return response;
            },
            function error(response) {
                return response;
            }
        );


    };

    /**
     * change Invoice Status
     *
     * Paid,Pending,closed
     *
     */
    _this.changeInvoiceStatus = (invoiceId, status) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var invoiceStatusUpdate = {
            status: status
        };
        var token = currentUserInfo.jwt;
        // From coinfig.josn file
        return $http({
            method: 'PATCH',
            url: getInvoiceApiUrl() + 'invoice/' + invoiceId,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: invoiceStatusUpdate
        }).success((response) => {
            return response;
        }).error((response) => {
            return response;
        });
    };

    _this.saveInvoiceDraft = (invoice) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var token = currentUserInfo.jwt;

        return $http({
            method: 'POST',
            url: getInvoiceApiUrl() + 'invoice',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: invoice
        }).then(
            function success(response) {
                return response;
            },
            function error(response) {
                return response;
            }
        );
    };

    /**
     * CreateInvoice
     * @param invoice
     */
    _this.createInvoice = (invoice) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var token = currentUserInfo.jwt;

        return $http({
            method: 'POST',
            url: getInvoiceApiUrl() + 'invoice',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: invoice
        }).then(
            function success(response) {
                return response;
            },
            function error(response) {
                return response;
            }
        );
    };

    /**
     * CreateDraft
     * @param invoice
     */
    _this.createDraft = (invoice) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var token = currentUserInfo.jwt;
        return $http({
            method: 'POST',
            url: getInvoiceApiUrl() + 'invoice/draft',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: invoice
        }).then(
            function success(response) {
                return response;
            },
            function error(response) {
                return response;
            }
        );
    };

    _this.updateDraft = (invoiceId,invoice) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var token = currentUserInfo.jwt;
        return $http({
            method: 'PUT',
            url: getInvoiceApiUrl() + 'invoice/' + invoiceId,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: invoice
        }).then(
            function success(response) {
                return response;
            },
            function error(response) {
                return response;
            }
        );
    };


    _this.sendDraftInvoice = (invoiceId,invoice) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        let token = currentUserInfo.jwt;
        return $http({
            method: 'PUT',
            url: getInvoiceApiUrl() + 'invoice/' + invoiceId,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: invoice
        }).then(
            function success(response) {
                return response;
            },
            function error(response) {
                return response;
            }
        );
    };

    /**
     * Pay a Invoice
     *
     *
     *
     */
    _this.saveInvoiceTemplate = (invoiceTemplate) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        let token = currentUserInfo.jwt;
        // From coinfig.josn file
        return $http({
            method: 'POST',
            url: getInvoiceApiUrl() + 'invoice/template',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: invoiceTemplate
        }).then(
            function success(response) {
                return response;
            },
            function error(response) {
                return response;
            }
        );
    };

    _this.updateInvoiceTemplate = (templateId, invoiceTemplate) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var token = currentUserInfo.jwt;
        // From coinfig.josn file
        return $http({
            method: 'PUT',
            url: getInvoiceApiUrl() + 'invoice/template/' + templateId,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: invoiceTemplate
        }).then(
            function success(response) {
                return response;
            },
            function error(response) {
                return response;
            }
        );
    };

    _this.getInvoiceTemplates = () => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var token = currentUserInfo.jwt;
        // From coinfig.josn file
        //standardize promise signatures using bluebird library
        return new Promise((resolve) => {
            $http({
                method: 'GET',
                url: getInvoiceApiUrl() + 'invoice/template',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }).success((response) => {
                resolve(response);
            }).error((error) => {
                resolve(error);
            });
        });
    };

    _this.submitInvoice = (submitInvoiceObject) => {
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var token = currentUserInfo.jwt;
        return new Promise((resolve, reject) => {
            $http({
                method: 'POST',
                url: getInvoiceApiUrl() + 'invoice',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                data: submitInvoiceObject
            }).success((response) => {
                resolve(response);
            }).error((error) => {
                reject(error);
            });
        });
    };

    /**
     * Validate invoice template
     *
     */
    _this.validateTemplate = (template, populateTemplate) => {
        var isValid = true;
        var hasAddress = false;
        var addressFields = [
            template.business_address1,
            template.business_city,
            template.business_country,
            template.business_postal_code,
            template.business_state
        ];
        let currentMerchant = SessionStorageService.get().getUser().business_information;
        //Check to see if any of the address fields are set
        angular.forEach(addressFields, function(value) {
            if (value) {
                hasAddress = true;
            }
        });
        //address check
        if (!hasAddress){
            isValid = false;
        }
        //bussiness name check
        if (!template.business_name){
            isValid = false;
        }
        //email check
        if (!template.support_email){
            isValid = false;
        }
        //phone check
        if (!template.support_phone_number){
            isValid = false;
        }
        //If address is missing populate tempate
        if (!hasAddress && populateTemplate === true){
            template.business_address1 = currentMerchant.address1;
            template.business_city = currentMerchant.city;
            template.business_state = currentMerchant.province;
            template.business_country = currentMerchant.country;
            template.business_postal_code = currentMerchant.postal_code;
        }
        //If business name is missing populate using DBA name
        if (!template.business_name && populateTemplate === true){
            template.business_name = currentMerchant.name;
        }
        //If email is missing populate from merchant
        if (!template.support_email && populateTemplate === true){
            template.support_email = currentMerchant.email;
        }
        //If support number is missing populate from merchant
        if (!template.support_phone_number && populateTemplate === true){
            template.support_phone_number = currentMerchant.telephone;
        }

        //set up response messgae
        var response = isValid;
        //If template population is requested send back status and updated template
        if (populateTemplate) {
            response = {
                isValid: isValid,
                validTemplate: template
            };
        }

        return response;
    };

    /**
     * Pay a Invoice
     */
    _this.exportInvoiceListCSV = (parameters) => {

        let queryUrl = '';
        let urlFirstDelim = '';
        let querySpecifier = '';
        //build status
        if (parameters.status) {
            queryUrl = queryUrl + 'status=' + parameters.status;
            urlFirstDelim = '&';
        }
        //build date
        if (parameters.from_date) {
            queryUrl = queryUrl + urlFirstDelim + 'from_date=' +
            parameters.from_date + '&to_date=' + parameters.to_date;
        }
        //add delem
        if (queryUrl) {
            querySpecifier = '?';
        }

        return HttpClientService.doGet(getInvoiceApiUrl() + 'invoice/export/csv' + querySpecifier + queryUrl)
            .then(function(response) {
                let fileName = response.data.file_name;

                if (fileName) {
                    _this.exportService.export(fileName);
                } else {
                    throw new Error('File name cannot be empty');
                }
            });
    };


    /**
     * Pay a Invoice
     *
     *
     *
     */
    _this.requestPayInvoiceToken = (tokenRequest) => {
        let hqConfig = ConfigurationService.getConfig();
        if (hqConfig){
            var hqJwtTokenUrl = hqConfig.hqJwtTokenUrl;
            var clientId = hqConfig.jwtClientId;
            var jwtToken = null;
            return $http({
                method: 'POST',
                url: hqJwtTokenUrl + '/payhq/token',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: $.param({
                    grant_type: 'external_link',
                    invoice_token: tokenRequest,
                    client_id: clientId
                })
            }).success(function(response) {
                jwtToken = response.access_token;
                return jwtToken;
            }).error(function(response) {
                return response;
            });
        } else {
            $route.reload();
        }
    };

    /**
     * grab logo and pace back image url
     */
    _this.getInvoiceLogo = (invoiceId) => {
        let hqConfig = ConfigurationService.getConfig();
        return hqConfig.fileManagementServicePublicApi + 'invoice_logos/invoice/' + invoiceId;
    };

    _this.isPayInvoiceAllowed = (invoiceStatus) => {
        if (invoiceStatus){
            return invoiceStatus.toLowerCase() === 'unpaid' || invoiceStatus.toLowerCase() ==='overdue';
        }
    };

    _this.fetchCustomerInvoices = function(customerId) {
        let hqconfig = ConfigurationService.getConfig();
        let invoiceApiUrl = hqconfig.invoiceApi;
        return HttpClientService.doGet(invoiceApiUrl + 'invoice/customer/' + customerId)
            .then(function (response) {
                let customerInvoices = response.data.invoices;
                _.each(customerInvoices, function (customerInvoice) {
                    let currentStatus = customerInvoice.status;
                    currentStatus = currentStatus.toLowerCase();
                    customerInvoice.status = currentStatus.charAt(0).toUpperCase() + currentStatus.slice(1);
                });
                return customerInvoices;
            });
    };

    /**
     * get a specific invoice url
     */
    _this.getInvoiceUrl = (invoiceId, authToken) => {
        let token = authToken;
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        if (!token){
            token = currentUserInfo.jwt;
        }
        let endPoint = 'invoice/' + invoiceId + '/send/geturl';

        return $http({
            method: 'GET',
            url: getInvoiceApiUrl() + endPoint,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).then(
            function success(response) {
                return response;
            },
            function error(response) {
                return response;
            }
        );
        
    };
});
