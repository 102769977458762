/* @flow */

import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import ConfigurationService from 'service/configuration-service';
import Nonce from 'model/nonce';
import UtilityService from 'service/utility-service';


angular
    .module('payhqUIApp').factory('AuthService',
    function ($http, $cookieStore, $location, HttpClientService,
              SessionService, $q, HeapService, HeapMessage, OauthTokenService) {

        var loginTime;

        let hqconfig = ConfigurationService.getConfig();
        //TODO: not sure if all of the error responses from the server are in this format
        //if yes, we should put the following logic in a generic error handler
        function extractErrorDescription(errorDescription) {
            var error = {};
            if (errorDescription) {
                try {
                    var errorJson = JSON.parse(errorDescription);
                    if (errorJson && errorJson.error) {
                        var errorJsonError = errorJson.error;
                        error.message = errorJsonError.message;
                        error.system_error_code = errorJsonError.system_error_code;
                    }
                } catch (e) {
                    error.message = errorDescription;
                }
            }

            return error;
        }

        function apiError() {
            return {
                type: 'api_error',
                code: 'api_error',
                system_error_code: 5,
                message: ''
            };
        }

        function invalidTokenError() {
            var error = apiError();
            error.message = 'Login failed, do not receive a valid token.';
            return error;
        }


        function loginFailedError(errorResponse) {
            var error = apiError();
            error.message = 'Login failed, please double-check email/password';
            // default error message
            if (errorResponse && errorResponse.data) {
                var errorObject = extractErrorDescription(errorResponse.data.errors);
                if (errorObject) {
                    if (errorObject.message[0].message) {
                        error.message = errorObject.message[0].message;
                    }
                    if (errorObject.system_error_code) {
                        error.system_error_code = errorObject.system_error_code;
                    }
                }
            }

            return error;
        }

        //TODO: I don't think we even use username, why store it?
        function setCredentials(username, jwtToken, apiUrl, refreshToken) {
            SessionService.storeLoggedinSession(username, jwtToken, apiUrl, hqconfig.invoiceApi, refreshToken);
        }

        function createCredentials(username, password, clientId, oidcUserId, oidcProvider) {
            let credentials = {
                grant_type: 'password',
                username: encodeURIComponent(username),
                password: encodeURIComponent(password),
                /*global globalAntiCSRFToken:true*/
                /*eslint no-undef: "error"*/
                csrfToken: globalAntiCSRFToken,
                client_id: clientId
            };

            if (oidcUserId && oidcProvider) {
                credentials = _.extend(credentials, {
                    oidc_user_id: encodeURIComponent(oidcUserId),
                    provider: oidcProvider
                });
            }
            return credentials;
        }

        function getSessionDurationDisplay(millisec) {
            return moment.utc(millisec).format('HH:mm:ss');
        }

        function login(loginHttpCall, username) {
            SessionService.deleteAllStoredLogInSessions();

            var apiUrl = hqconfig.hqApi;
            UtilityService.unloadDataService();
            return loginHttpCall.then(function (response) {
                let data = response.data;
                var jwtToken = data.access_token;
                var refreshToken = data.refresh_token;
                if (data.otpVerificationRequired && data.otpVerificationRequired === true){
                    return data;
                } else {
                    let passwordChangeRequired = data.reset_password_hash_token;
                    let successfulLogin = jwtToken && refreshToken;
                    if (passwordChangeRequired) {
                        //if first time login return token and redirect to change password page
                        return data;
                    } else if (successfulLogin) {
                        setCredentials(username, jwtToken, apiUrl, refreshToken);
                        //Set login Time
                        loginTime = new Date().getTime();
                        window.PAYFIRMA.CommunicationService.triggerAuthSuccessfulEvent();
                        return data;
                    } else {
                        throw invalidTokenError();
                    }
                }
            }).catch(function (errorResponse) {
                throw loginFailedError(errorResponse);
            });
        }

        function decryptString(encryptedString){
            let decryptUrl = hqconfig.reportingSystemUrl;
            decryptUrl = decryptUrl.replace('login', '') + 'api/decrypt/';
            return HttpClientService.doPost(
                decryptUrl,
                { text: encryptedString }
            ).then(function (response) {
                return response.data;
            }).catch(function (errorResponse) {
                throw loginFailedError(errorResponse);
            });
        }


        return {
            getNonce: function () {
                let clientId = hqconfig.jwtClientId;
                return HttpClientService.doGet(
                    hqconfig.hqJwtTokenUrl + 'payhq/nonce?client_id=' + clientId)
                    .then(function (response) {
                        return new Nonce(response.data);
                    });
            },
            login: function (username, password, oidcUserId, oidcProvider) {
                let jwtTokenUrl = hqconfig.hqJwtTokenUrl;
                let clientId = hqconfig.jwtClientId;

                let loginHttpCall = HttpClientService.doFormPost(
                    jwtTokenUrl + 'payhq/token', createCredentials(
                        username,
                        password,
                        clientId,
                        oidcUserId,
                        oidcProvider),
                    {
                        headers: {
                            'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                        }
                    }, false
                );

                return login(loginHttpCall, username);
            },

            //Handle Single sign on login when redirecting via reporting system.
            //Decrypts the data and set the login credentials.
            loginSSO: function (jwtToken, refreshToken, username) {
                return new Promise((resolve, reject) => {
                    Promise.all([
                        decryptString(jwtToken),
                        decryptString(refreshToken),
                        decryptString(username)
                    ]).then(([decryptedJWT, decryptedRefreshToken, decryptedUsername]) => {
                        var apiUrl = hqconfig.hqApi;
                        this.setLoginAsUserCredentials(decryptedUsername.value, decryptedJWT.value, apiUrl, decryptedRefreshToken.value);
                        resolve();
                    }).catch(error => {
                        console.error('Error during SSO login:', error);
                        reject(error);
                    });
                });
            },
            
            // Sends an HTTP request to resend OTP using a given Redis key
            sendEmailOTP: function (otpSessionToken) {
                return HttpClientService.doGet(hqconfig.hqJwtTokenUrl + 'payhq/token/otp_email?otpSessionToken=' + otpSessionToken)
                .then(function (response) {
                    return response.data;
                }).catch(function (errorResponse) {
                    throw loginFailedError(errorResponse);
                });
            },

            // Sends HTTP request to authorization service to send mobile OTP to the mobile number attached to the email that is attempting to login.
            sendMobileOTP: function (otpSessionToken) {
                //Make http request and return the data.
                return HttpClientService.doGet(hqconfig.hqJwtTokenUrl + 'payhq/token/otp_mobile?otpSessionToken=' + otpSessionToken)
                .then(function (response) {
                    return response.data;
                }).catch(function (errorResponse) {
                    throw loginFailedError(errorResponse);
                });
            },

            verifyOneTimePassword: function (otpSessionToken, userEnteredOTP, rememberDevice) {
                SessionService.deleteAllStoredLogInSessions();
                let clientId = hqconfig.jwtClientId;
                var apiUrl = hqconfig.hqApi;

                return HttpClientService.doGet(
                    hqconfig.hqJwtTokenUrl + 'payhq/verify_otp?client_id=' + clientId
                    + '&otpSessionToken=' + otpSessionToken
                    + '&user_entered_otp=' + userEnteredOTP
                    + '&rememberDevice=' + rememberDevice)
                    .then(function (response) {
                        var jwtToken = response.data.access_token;
                        var refreshToken = response.data.refresh_token;
                        setCredentials(null, jwtToken, apiUrl, refreshToken);
                        //Set login Time
                        loginTime = new Date().getTime();
                        window.PAYFIRMA.CommunicationService.triggerAuthSuccessfulEvent();
                        return response.data;
                    }).catch(function (errorResponse) {
                        throw loginFailedError(errorResponse);
                    });
            },

            oneTimeAuthenticate: function (oneTimeAuthenticationToken) {
                let jwtTokenUrl = hqconfig.hqJwtTokenUrl;
                let clientId = hqconfig.jwtClientId;

                let oneTimeAuthenticationHttpCall = HttpClientService.doFormPost(
                    jwtTokenUrl + 'payhq/token',
                    {
                        grant_type: 'one_time_authentication',
                        token: oneTimeAuthenticationToken,
                        client_id: clientId
                    }, {}, true
                );

                return login(oneTimeAuthenticationHttpCall);
            },

            isAuthenticated: function () {
                return SessionService.isLoggedIn();
            },

            setLoginAsUserCredentials: function (username, hqToken, apiUrl, refreshToken) {
                //when login as user is compatible with oauth
                setCredentials(username, hqToken, apiUrl, refreshToken);
                UtilityService.unloadDataService();
            },

            /**
             * Logout
             */
            logout: function () {

                SessionService.cleanup();
                UtilityService.unloadDataService();

                if (!!SessionService.isLoggedIn()) {

                    SessionService.retrieveParentAdminUserSession();
                    // If current session is logged in as a user
                    if (!!SessionService.isLoggedInAsUser()) {
                        // Remove token for logged in as user
                        return OauthTokenService.revokeTokenAndLogoutForLoggedInAsUser();
                    } else {
                        //Get Session duration from loginTime
                        var logoutTime = new Date().getTime();
                        var sessionDuration = logoutTime - loginTime;
                        sessionDuration = getSessionDurationDisplay(sessionDuration);
                        HeapService.heapTrack(HeapMessage.TYPE_SESSION_DURATION, sessionDuration);
                        // remove token for user and logged in as user
                        return OauthTokenService.revokeTokenAndLogout();
                    }
                } else {
                    SessionService.deleteAllStoredLogInSessionsAndRedirectToLogin();
                }
            }
        };

    });