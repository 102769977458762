import angular from 'angular';

angular.module('payhqUIApp').filter('recurringPlan', function () {
    return function (customerList, planId, recurringCustomers) {
        if (customerList && customerList.length) {
            return _.filter(customerList, function (customer) {
                return _.find(recurringCustomers, function (recurringCustomer) {
                    var recurringCustomerFound = recurringCustomer.customer_id === customer.id;
                    if (planId) {
                        return recurringCustomerFound && recurringCustomer.recurring_plan_id === planId;
                    } else {
                        return recurringCustomerFound;
                    }
                });
            });
        }
        return [];
    };
});