'use strict';
import 'service/image-editing/models/UserProfilePicture';
import Constants from 'service/constants';

window.PAYFIRMA.OtherUserProfilePicture = function (merchantId, userId) {
    this.defaultImageUrl = Constants.IMAGE_BASE_LOCATION + 'phq-profile-image.jpg';
    var baseUrl = 'user_images/';
    var cachebuster = window.Math.round(new Date().getTime() / 1000);
    this.retrieveImageUrl = baseUrl + merchantId + '/' + userId + '?cache=' + cachebuster;
};

window.PAYFIRMA.OtherUserProfilePicture.prototype =
    Object.create(window.PAYFIRMA.UserProfilePicture.prototype, {});