'use strict';

window.PAYFIRMA = window.PAYFIRMA || {};

function isApiError(error) {
    return error && error.code === 'api_error';
}

function has401Error(error) {
    return error && error.status === 401 && error.message
    && error.message.toLowerCase().indexOf('api gateway service error') !== -1;
}

function isUnauthorizedError(error) {
    return has401Error(error);
}

function isErrorMessage(error, errorIndicator) {
    return isApiError(error) && error.message
        && error.message.toLowerCase().indexOf(errorIndicator) !== -1;
}

window.PAYFIRMA.ServerErrorService = {
    isSystemUnderMaintenance: function (error) {
        return isErrorMessage(error, 'system under maintenance');
    },

    isSessionExpired: function (error) {
        return isErrorMessage(error, 'session expired');
    },

    isInvalidToken: function (error) {
        return isErrorMessage(error, 'invalid token');
    },

    isAccountDisabled: function (error) {
        return isErrorMessage(error, 'accountaccess') ||
            isErrorMessage(error, 'account is disabled');
    },

    isUnauthorized: function (error) {
        return isUnauthorizedError(error);
    },

    isAuthorizationServiceError: function (error) {
        return error && error.message &&
            error.message.toLowerCase().indexOf('authorization service error') !== -1;
    },

    extractErrorMessage: function (serverErrorResponse) {
        if (serverErrorResponse && serverErrorResponse.data
            && serverErrorResponse.data.errors && serverErrorResponse.data.errors.length) {
            return serverErrorResponse.data.errors[0].message;
        }
        return '';
    }
};