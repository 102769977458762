import angular from 'angular';

/**
 * @ngdoc factory
 * @name payhqUIApp.customerApp.factory:CustomerFactory
 * @requires CustomerCardService
 * @description
 *
 * Factory for creating customer objects
 *
 */
angular
.module('customerApp')
.factory('CustomerFactory', function CustomerFactory($http, CustomerCardService,
    Utils, CustomerCardFactory, CardService, InvoiceService) {
    var ComplexCustomer;
    var SimpleCustomer;

    function getDefaultCard(cards){
        var defaultCard = _.find(cards, function(value){
            return value.is_default === true;
        });
        return defaultCard;
    }

    function getCreditCardType(cardNumber){
        return Utils.getCreditCardType(cardNumber);
    }

    /**
     * Customer Object constructor.
     *
     * @constructor
     * @param {Object} initialData - Data to initialize customer object with
     */
    ComplexCustomer = function (initialData) {
        var _this = this;
        var customerData = {
            'id': null,
            'lookup_id': null,
            'email': null,
            'first_name': null,
            'last_name': null,
            'company': null,
            'bcc_emails': null,
            'telephone': null,
            'address1': null,
            'address2': null,
            'city': null,
            'province': null,
            'country': null,
            'postal_code': null,
            'custom_id': null,

            creation_date: null,
            default_card: null,
            recurring_plan_statuses: null
        };
        var customerCards = [];
        var merchantPlans = [];
        var customerTransactions = [];
        var customerEFTTransactions = [];
        var customerInvoices = [];

        _this.cards = CustomerCardFactory.parseCards(initialData.cards);
        _this.subscriptions = initialData.subscriptions;
        this.getAllCustomerPlans = function () {
            return _this.subscriptions;
        };

        this.getAllMerchantPlans = function () {
            return merchantPlans;
        };

        this.getAllCustomerEFTTransactions = function () {
            return customerEFTTransactions;
        };

        this.getAllCustomerTransactions = function () {
            return customerTransactions;
        };

        this.getAllCustomerInvoices = function () {
            return customerInvoices;
        };

        this.getDefaultCardType = function() {
            var isDefaultCard;
            var defaultCardType;
            _.forEach(initialData.cards, function(value){
                isDefaultCard = value.is_default;
                if (isDefaultCard){
                    defaultCardType = CardService.getCardType(value.card_prefix);
                }
            });
            return defaultCardType;
        };

        this.getAllValidCreditCards = function () {
            return _.filter(customerCards, function (card) {
                return !card.is_expired;
            });
        };

        /**
         * Get customer Invoices.
         *
         * @param {Number} customerId - Customer id of Invoices to fetch
         * @param {Boolean} isNewFetch - If true, this is a new API call to fetch
         *    the customer's Invoices. Otherwise, return what is currently stored.
         * @returns {Array} customerInvoices - Returns a list of transaction objects
         */
        this.getInvoices = function (customerId, isNewFetch) {
            if (!isNewFetch && customerInvoices) {
                return new Promise(function (resolve) {
                    resolve(customerInvoices);
                });
            } else {
                return InvoiceService.fetchCustomerInvoices(customerId)
                .then(function (invoices) {
                    customerInvoices = invoices;
                    return customerInvoices;
                });
            }
        };

        /**
         * Get customer address1.
         *
         * @returns {String} customerAddress1 - Returns the customer's address1.
         */
        this.getInvoice = function () {
            return _this.customerInvoices;
        };

        /**
         * Get customer address1.
         *
         * @returns {String} customerAddress1 - Returns the customer's address1.
         */
        this.getAddress1 = function () {
            return _this.address1;
        };

        /**
         * Get customer address2.
         *
         * @returns {String} customerAddress2 - Returns the customer's address2.
         */
        this.getAddress2 = function () {
            return _this.address2;
        };

        /**
         * Get customer default card on file.
         *
         * @returns {String} customerCardOnFile - Returns the customer's card on file.
         */
        this.getCardOnFile = function () {
            return _this.card_on_file;
        };

        /**
         * Get customer city.
         *
         * @returns {String} customerCity - Returns the customer's city.
         */
        this.getCity = function () {
            return _this.city;
        };

        /**
         * Get customer company.
         *
         * @returns {String} customerCompany - Returns the customer's company
         */
        this.getCompany = function () {
            return initialData.company;
        };

        /**
         * Get customer country.
         *
         * @returns {String} customerCountry - Returns the customer's country.
         */
        this.getCountry = function () {
            return _this.country;
        };

        /**
         * Get customer creation date.
         *
         * @returns {String} customerCreationDate - Returns the customer's creation date.
         */
        this.getCreationDate = function () {
            return _this.creation_date;
        };

        /**
         * Get customer default card id.
         *
         * @returns {Number} customerDefaultCard - Returns the customer's default card
         */
        this.getDefaultCard = function () {
            var defaultCard = _.find(_this.cards, function(value){
                return value.is_default === true;
            });
            return defaultCard;
        };

        /**
         * Get customer email.
         *
         * @returns {String} customerEmail - Returns the customer's email
         */
        this.getEmail = function () {
            return initialData.email;
        };

        /**
         * Get customer first name.
         *
         * @returns {String} customerFirstName - Returns the customer's first name
         */
        this.getFirstName = function () {
            return initialData.first_name;
        };

        /**
         * Get customer full name.
         *
         * @returns {String} name - Returns the customer full name
         */
        this.getFullname = function () {
            var name = _this.first_name;
            var lastname = _this.last_name;
            if (lastname) {
                name += ' ' + lastname;
            }
            return name;
        };

        /**
         * Get customer id.
         *
         * @returns {Number} customerId - Returns the customer id
         */
        this.getId = function () {
            return _this.id;
        };

        this.getCustomerLookupId = function () {
            return _this.lookup_id;
        };

        /**
         * Get customer info.
         *
         * @returns {Object} customerData - Returns the customer's data
         */
        this.getInfo = function () {
            return customerData;
        };

        /**
         * Get customer last name.
         *
         * @returns {String} customerLastName - Returns the customer's last name
         */
        this.getLastName = function () {
            return initialData.last_name;
        };

        /**
         * Get customer postal code.
         *
         * @returns {String} customerPostalCode - Returns the customer's postal code.
         */
        this.getPostalCode = function () {
            return _this.postal_code;
        };

        /**
         * Get customer province.
         *
         * @returns {String} customerProvince - Returns the customer's province.
         */
        this.getProvince = function () {
            return _this.province;
        };

        /**
         * Get customer recurring plan statuses.
         *
         * @returns {String} customerRecurringPlanStatuses - Returns the customer's
         * recurring plan statuses.
         */
        this.getRecurringPlanStatuses = function () {
            return _this.recurring_plan_statuses;
        };

        /**
         * Get customer telephone.
         *
         * @returns {String} customerTelephone - Returns the customer's telephone.
         */
        this.getTelephone = function () {
            return _this.telephone;
        };

        /**
         * Set customer cards.
         *
         * @param {Array} cards - List of customer's cards
         */
        this.setCards = function (cards) {
            customerCards = cards;

            // Set default card
            _.forEach(customerCards, function (card) {
                if (card.is_default) {
                    _this.card_on_file = CardService.getCardType(card.card_prefix);
                    _this.default_card = card;
                }
            });
        };

        /**
         * Set merchant plans.
         *
         * @param {Array} plans - List of merchant plans
         */
        this.setMerchantPlans = function (plans) {
            merchantPlans = plans;
        };

        /**
         * Set customer plans.
         *
         * @param {Array} plans - List of customer's plans
         */
        this.setCustomerPlans = function (plans) {
            _this.subscriptions = plans;
        };

        /**
         * Set customer transactions.
         *
         * @param {Array} transactions - List of customer's transactions
         */
        this.setTransactions = function (transactions) {
            customerTransactions = transactions;
        };

        /**
         * Set customer EFT transactions.
         *
         * @param {Array} eftTransactions - List of customer's EFT transactions
         */
        this.setEFTTransactions = function (eftTransactions) {
            customerEFTTransactions = eftTransactions;
        };

        /**
         * Set customer info.
         *
         * @param {Object} data - Data to set customer object with
         * @param {Boolean} nullNotAllowed - (Optional) Allow null values to override properties.
         * By default, this is set to false - any value is allowed to support overriding.
         * Set to true if only non-null values are allowed.
         */
        this.setCustomerInfo = function (data) {
            angular.forEach(data, function (value, key) {
                if (_this.hasOwnProperty(key)) {
                    _this[key] = value;
                }
                // Not sure if this line is necessary ???
                if (customerData.hasOwnProperty(key)) {
                    customerData[key] = value;
                }
            });

        };

        // Extends customer Data properties onto _this
        _.assign(_this, customerData);
        // Initialize customer data
        _this.setCustomerInfo(initialData);

        return _this;
    };

    SimpleCustomer = function (initialData) {
        var _this = this;
        _this.first_name = initialData.first_name;
        _this.last_name = initialData.last_name;
        _this.company = initialData.company;
        _this.email = initialData.email;
        _this.address1 = initialData.address1;
        _this.address2 = initialData.address2;
        _this.telephone = initialData.telephone;
        _this.province = initialData.province;
        _this.postal_code = initialData.postal_code;
        _this.city = initialData.city;
        _this.country = initialData.country;
        _this.bcc_emails = initialData.bcc_emails;
        _this.id = initialData.id;
        _this.lookup_id = initialData.lookup_id;
        _this.custom_id = initialData.custom_id;

        var recurringPlanStatuses = [];

        // TODO : CHECK NULL
        if (initialData.cards && getDefaultCard(initialData.cards)){
            _this.default_card_type = getCreditCardType(getDefaultCard(initialData.cards).card_prefix);
        }

        if (initialData.subscriptions) {
            _this.subscriptions = initialData.subscriptions;
            angular.forEach(initialData.subscriptions, function (value) {
                recurringPlanStatuses.push(value.plan_status);
            });
            _this.recurring_plan_statuses = recurringPlanStatuses.join();
        }
        return _this;
    };

    return {
        buildComplexCustomer: function (data) {
            var newRequestCustomer = new ComplexCustomer(data);
            return newRequestCustomer;
        },
        buildSimpleCustomer: function (data) {
            var newRequestCustomer = new SimpleCustomer(data);
            return newRequestCustomer;
        },
        parseSimpleCustomerList: function(data){
            var _this = this;
            var customerList = [];
            if (data){
                customerList = _.map(data.entities, function (value) {
                    return _this.buildSimpleCustomer(value);
                });
            }

            return customerList;
        }
    };
});
