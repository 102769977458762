const CONSTANT_COMPONENT = {
    paper_button: 'paperButton',
    paper_button_export: 'paperButtonExport',
    paper_button_group_menu: 'paperButtonGroupMenu',
    paper_card: 'paperCard',
    paper_checkbox: 'paperCheckbox',
    paper_close: 'paperClose',
    paper_modal: 'paperModal',
    paper_header: 'paperHeader',
    paper_dropdown: 'paperDropdown',
    settings: 'settings',
    terminal: 'terminal',
    cardterminal: 'cardterminal',
    billing: 'billing',
    transactions: 'transactions',
    profile: 'profile',
    invoicing: 'invoicing',
    view_invoice: 'viewInvoice',
    paper_filter_invoice_status: 'paperFilterInvoiceStatus',
    staff_edit: 'staffEdit'
};

export default CONSTANT_COMPONENT;