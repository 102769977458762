'use strict';

window.PAYFIRMA = window.PAYFIRMA || {};

/*
 SearchOptions object:
 {
 searchParameters: additional parameters when doing search
 backwards: true if search backwards use before cursor
 limit: response size limit
 cursor: cursor where the search starts from
 forceFetch: force to make a server instead of using cache if available
 }
 */
window.PAYFIRMA.PaginationDataService = function (service, noCacheUsed) {
    let _this = this;
    let items = [];
    let noCache = noCacheUsed;
    let dataService = service;
    let hasLoadedData = false;
    let needToUpdate = false;
    let isRetrievingData = false;
    let startCursor;
    let endCursor;
    let topCursor;
    const CONCURRENT_WAIT_CYCLE_TIME_IN_MILLIS = 150;
    const MAX_LIST_SIZE = 48000;
    //TODO: NEED TO PERFORMANCE TEST THIS TO MAKE SURE WE DO NOT NEED TO CAP THE MAX SIZE IN UI

    function clearFlags() {
        hasLoadedData = false;
        needToUpdate = false;
    }

    function clearCursor() {
        startCursor = undefined;
        endCursor = undefined;
        topCursor = undefined;
    }

    function toSearchOptions(cusor, searchOptionsFromUpstream) {
        let searchOptions = {};
        if (cusor) {
            searchOptions.cursor = cusor;
        } else if (searchOptionsFromUpstream && searchOptionsFromUpstream.cursor) {
            searchOptions.cursor = searchOptionsFromUpstream.cursor;
        }

        if (searchOptionsFromUpstream) {
            if (searchOptionsFromUpstream.searchParameters) {
                searchOptions.searchParameters = searchOptionsFromUpstream.searchParameters;
            }

            if (searchOptionsFromUpstream.limit) {
                searchOptions.limit = searchOptionsFromUpstream.limit;
            }
        }
        return searchOptions;
    }

    function loadMoreFrom(cursor, searchOptions) {
        let getMoreDataCall = dataService.getNext;

        const backwards = searchOptions && searchOptions.backwards ?
            searchOptions.backwards : false;

        if (backwards) {
            getMoreDataCall = dataService.getPrevious;
        }

        const newSearchOptions = toSearchOptions(cursor, searchOptions);

        return getMoreDataCall(newSearchOptions)
            .then(function (pagination) {
                let nextBatchOfData = pagination.content;
                let newCursor = pagination.nextCursor();
                if (backwards) {
                    newCursor = pagination.previousCursor();
                }

                if (backwards) {
                    startCursor = newCursor;
                    if (newCursor) {
                        topCursor = newCursor;
                    }
                    if (!endCursor) {
                        endCursor = pagination.nextCursor();
                    }
                } else {
                    endCursor = newCursor;
                    if (!startCursor) {
                        startCursor = pagination.previousCursor();
                    }
                    if (!topCursor) {
                        topCursor = pagination.previousCursor();
                    }
                }

                if (nextBatchOfData && nextBatchOfData.length) {
                    if (backwards) {
                        items = nextBatchOfData.concat(items);
                    } else {
                        items = items.concat(nextBatchOfData);
                    }
                }

                if (newCursor && (backwards || items.length < MAX_LIST_SIZE)) {
                    return loadMoreFrom(newCursor, searchOptions);
                } else {
                    items = items.slice(0, MAX_LIST_SIZE);
                    return new Promise(function (resolve) {
                        resolve();
                    });
                }
            });
    }

    function loadAllFrom(cursor, searchOptions) {
        clearFlags();
        if (!cursor) {
            items = [];
        }
        return loadMoreFrom(cursor, searchOptions);
    }

    function reloadNeeded() {
        return !hasLoadedData || needToUpdate || noCache;
    }


    function executeGetInitialData(searchOptions) {
        isRetrievingData = true;
        if (reloadNeeded()) {
            _this.unload();
            const newSearchOptions = toSearchOptions(undefined, searchOptions);

            return dataService.getInitialData(newSearchOptions)
                .then(function (data) {
                    items = data.content;
                    startCursor = data.previousCursor();
                    endCursor = data.nextCursor();
                    if (startCursor) {
                        topCursor = startCursor;
                    }
                    if (!endCursor) {
                        hasLoadedData = true;
                    }
                    isRetrievingData = false;
                    return items;
                }).catch(
                    //make sure we reset isRetrievingData, otherwise application will stop working if promise never
                    //returns
                    () => {
                        isRetrievingData = false;
                        return items;
                    });

        } else {
            isRetrievingData = false;
            return new Promise(function (resolve) {
                resolve(items);
            });
        }
    }

    _this.getInitialData = function (searchOptions) {
        if (!isRetrievingData) {
            return executeGetInitialData(searchOptions);
        } else {
            //wait while another data retrieval function is executing
            return Promise.delay(CONCURRENT_WAIT_CYCLE_TIME_IN_MILLIS)
                .then(function () {
                    return _this.getInitialData(searchOptions);
                });
        }
    };

    function executeLoadAllData(searchOptions) {
        isRetrievingData = true;
        const forceFetch = searchOptions && searchOptions.forceFetch ? searchOptions.forceFetch : false;
        if (reloadNeeded() || forceFetch) {
            if (hasLoadedData) {
                clearCursor();
            }
            return loadAllFrom(endCursor, searchOptions)
                .then(function () {
                    hasLoadedData = true;
                    isRetrievingData = false;
                    return items;
                }).catch(
                    //make sure we reset isRetrievingData, otherwise application will stop working if promise never
                    //returns
                    () => {
                        isRetrievingData = false;
                        return items;
                    });
        } else {
            return new Promise(function (resolve) {
                isRetrievingData = false;
                resolve(items);
            });
        }
    }

    _this.getAllData = function (searchOptions) {
        if (!isRetrievingData) {
            return executeLoadAllData(searchOptions);
        } else {
            //wait while another data retrieval function is executing
            return Promise.delay(CONCURRENT_WAIT_CYCLE_TIME_IN_MILLIS)
                .then(function () {
                    return _this.getAllData(searchOptions);
                });
        }
    };

    function resetExistingData() {
        startCursor = undefined;
        endCursor = undefined;
        items = [];
    }

    function loadMore(searchOptions) {
        let backwards = searchOptions && searchOptions.backwards ?
            searchOptions.backwards : false;

        let promise;
        isRetrievingData = true;

        if ((backwards && !startCursor) || (!backwards && !endCursor)) {
            //refresh the page if already reaches to the end
            resetExistingData();
        }

        if (backwards) {
            promise = loadMoreFrom(startCursor, searchOptions);
        } else {
            promise = loadMoreFrom(endCursor, searchOptions);
        }
        return promise
            .then(function () {
                hasLoadedData = true;
                isRetrievingData = false;
                return items;
            });
    }

    _this.getNext = function (searchOptions) {
        if (!isRetrievingData) {
            return loadMore(searchOptions);
        } else {
            //wait while another data retrieval function is executing
            return Promise.delay(CONCURRENT_WAIT_CYCLE_TIME_IN_MILLIS)
                .then(function () {
                    return _this.getNext(searchOptions);
                });
        }
    };

    _this.setNeedToUpdate = function (update) {
        needToUpdate = update;
    };

    _this.getStartCursor = function () {
        return startCursor;
    };

    _this.getEndCursor = function () {
        return endCursor;
    };

    _this.hasMorePreviousData = function (searchOptions) {
        const newSearchOptions = toSearchOptions(topCursor, searchOptions);
        return dataService.pollMorePreviousData(newSearchOptions)
            .then(function (pagination) {
                return pagination && pagination.content && pagination.content.length;
            });
    };

    _this.exceedsDataLimit = function () {
        return items.length >= MAX_LIST_SIZE && endCursor;
    };
    _this.unload = function () {
        items = [];
        clearFlags();
        clearCursor();
    };
};