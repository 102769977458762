import angular from 'angular';
const UPDATE_PAPER_CALENDAR_FILTER_RANGE = 'UPDATE_PAPER_CALENDAR_FILTER_RANGE';
angular
.module('payhqUIApp')
.service('PaperCalendarFilterService', function ($rootScope) {
    return {
        updateCalenderFilterDateRange: function (dateRange) {
            $rootScope.$broadcast(UPDATE_PAPER_CALENDAR_FILTER_RANGE, dateRange);
        }
    };
});