/* @flow */

import _ from 'lodash';
import USER_GROUP from 'constant/user-group';
import Url from 'domurl';

let UtilityService = {
    userGroupStringMapping: (string : string) => {
        let mappedString;

        switch (string) {
            case USER_GROUP.ADMIN:
                mappedString = window.PAYFIRMA.TEXT_STRINGS.ADMIN;
                break;
            case USER_GROUP.REPORTING:
                mappedString = window.PAYFIRMA.TEXT_STRINGS.REPORTING;
                break;
            case USER_GROUP.SALES:
                mappedString = window.PAYFIRMA.TEXT_STRINGS.SALES;
                break;
            case USER_GROUP.PAYFIRMA_ADMIN:
                mappedString = window.PAYFIRMA.TEXT_STRINGS.PAYFIRMA_ADMIN;
                break;
            default:
                mappedString = '';
        }

        return mappedString;
    },
    toLowerCaseCapitalized: (string : string) => {
        let toLowerCaseCapitalizedString;
        if (string) {
            toLowerCaseCapitalizedString = string.charAt(0) + string.toLowerCase().slice(1);
        }
        return toLowerCaseCapitalizedString;
    },
    unionBy: (array1 : Object[], array2 : Object[]) => {
        let unionedArray;
        if (array1 && array2) {
            unionedArray = _.unionBy(array1, array2, 'id');
        }
        return unionedArray;
    },
    orderBy: (array : Object[], key : Object, order : Object) => {
        let orderedArray;
        if (array && key && order) {
            orderedArray = _.orderBy(array, key, order);
        }
        return orderedArray;
    },
    slice: (array : Object[], from : number, to : number) => {
        let slicedArray;
        if (array) {
            slicedArray = array.slice(from, to);
        }
        return slicedArray;
    },
    parseErrorMessage: //TODO: PUT THIS ERROR PARSING IN HTTP-CLIENT-SERVICE FOR GLOBAL ERROR HANDLING
        function (e : Object) {
            if (e && e.data && e.data.errors && e.data.errors.length &&
                e.data.errors[0].message) {
                return new Error(e.data.errors[0].message);
            } else if (e && e.data && e.data.message) {
                return new Error(e.data.message);
            } else {
                return new Error('Unknown error');
            }
        },
    getQueryParameterValue: (urlString: string, parameterName: string) => {
        let url = new Url(urlString.replace('#', ''));
        if (url && url.query) {
            return url.query[parameterName];
        }

        return undefined;
    },
    unloadDataService: () => {
        if (window.PAYFIRMA.TransactionDataService) {
            window.PAYFIRMA.TransactionDataService.unload();
        }
        if (window.PAYFIRMA.InvoiceDataService) {
            window.PAYFIRMA.InvoiceDataService.unload();
        }
        if (window.PAYFIRMA.CustomerDataService) {
            window.PAYFIRMA.CustomerDataService.unload();
        }
        if (window.PAYFIRMA.MerchantAppDataService) {
            window.PAYFIRMA.MerchantAppDataService.unload();
        }
        if (window.PAYFIRMA.MerchantDataService) {
            window.PAYFIRMA.MerchantDataService.unload();
        }
    },
    convertToYesNo: (boolean) => {
        if (boolean) {
            return 'Yes';
        }
        return 'No';
    }
};

export default UtilityService;