import angular from 'angular';

angular
.module('payhqUIApp')
.factory('DataFilterService', function($rootScope, $location) {

    var urlConfigData;
    var urlConfig;

    var currentHost = $location.host();

    if (currentHost === 'hq.payfirma.com') {
        urlConfigData = window.PAYFIRMA.PROD_CONFIG;
    } else if (currentHost === 'staging.payfirma.com') {
        urlConfigData = window.PAYFIRMA.STAGING_CONFIG;
    } else if (currentHost === 'dev-test.payfirma.com') {
        urlConfigData = window.PAYFIRMA.QA_CONFIG;
    } else if (currentHost === 'cibctest.payfirma.com') {
        urlConfigData = window.PAYFIRMA.CIBC_CONFIG;
    } else if (currentHost === 'sandbox.payfirma.com') {
        urlConfigData = window.PAYFIRMA.SANDBOX_CONFIG;
    } else if (currentHost === 'hq2.payfirma.com') {
        urlConfigData = window.PAYFIRMA.PROD2_CONFIG;
    } else {
        urlConfigData = window.PAYFIRMA.LOCAL_DEV_CONFIG;
    }

    urlConfig = {
        hqApi: urlConfigData.hq_api,
        hqResetPasswordDomain: urlConfigData.hq_resetPassword_domain,
        hqJwtTokenUrl: urlConfigData.hq_jwt_token_url,
        jwtClientId: urlConfigData.jwt_client_id,
        payInvoiceSecret: urlConfigData.pay_invoice_secret,
        invoiceApi: urlConfigData.invoice_api,
        fileManagementServiceApi: urlConfigData.file_management_service_api,
        fileManagementServicePublicApi: urlConfigData.file_management_service_public_api,
        heapId: urlConfigData.heap_id,
        planServiceApi : urlConfigData.plan_service_api,
        customerServiceApi : urlConfigData.customer_service_api
    };

    $rootScope.hqConfig = urlConfig;

    return {
        getConfig: function () {
            return urlConfig;
        }
    };
});
