import angular from 'angular';

function paperTransactionstatusFilter($element) {
    const TRANSACTION_STATUS = 'Status';

    var _this = this;
    _this.isDropped = false;
    _this.isLoading = false;

    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function(){
        if (_this.isDropped === true){
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });

    $element.on('click', function(e){
        if (_this.isDropped === true){
            $('dropdown').show();
        }
        e.stopPropagation();
    });

    _this.transactionStatusList = [];
    _this.transactionStatusList.push({ name: 'All', key: '' });
    _this.transactionStatusList.push({ name: 'Approved', key: 'APPROVED' });
    _this.transactionStatusList.push({ name: 'Declined', key: 'DECLINED' });

    _this.setSelectedTransactionStatus = function (transactionStatus) {
        _this.title = transactionStatus.name;
        _this.filtertransactionstatus = transactionStatus.key;
        _this.doDrop(false);
    };

    function initFilter() {
        _this.title = TRANSACTION_STATUS;
    }

    initFilter();
}

angular
.module('payhqUIApp')
.component('paperTransactionstatusFilter', {
    bindings: {
        filtertransactionstatus: '='
    },
    controller: paperTransactionstatusFilter,
    controllerAs: 'paperTransactionstatusFilter',
    templateUrl: 'component/paper-filter/paper-transactionstatus-filter/paper-transactionstatus-filter.html'
});