/* @flow */

import angular from 'angular';
import CONSTANT_APP from 'constant/constant-app';
import CONSTANT_COMPONENT from 'constant/constant-component';
import Staff from 'model/staff';
import USER_GROUP from 'constant/user-group';

const APP_MODULE = CONSTANT_APP.module;
const COMPONENT_STAFF_EDIT = CONSTANT_COMPONENT.staff_edit;

function staffEdit(Utils, UserService, UrlService,
                   PaperToastService, ImageStore, $scope, ImageCropService, OauthTokenService) {

    let _this = this;

    function initDefaultStaffData() {
        _this.staff = _this.staff || {};

        if (!_this.staff.user) {
            // Initialize self.staff
            _this.staff.user = new Staff();
        }
    }

    function initUserGroup() {
        // User permission groups
        if (!_this.staff.payfirmaInternal) {
            _this.userGroup = [{
                key: 'Admin',
                value: USER_GROUP.ADMIN
            }, {
                key: 'Sales',
                value: USER_GROUP.SALES
            }, {
                key: 'Reporting',
                value: USER_GROUP.REPORTING
            }];
        } else {
            // Payfirma Internal User permission groups
            _this.userGroup = [{
                key: 'Admin',
                value: USER_GROUP.ADMIN
            }, {
                key: 'Sales',
                value: USER_GROUP.SALES
            }, {
                key: 'Reporting',
                value: USER_GROUP.REPORTING
            }, {
                key: 'Payfirma Admin',
                value: USER_GROUP.PAYFIRMA_ADMIN
            }];
        }

        if (!_this.staff.user.user_group) {
            _this.staff.user.user_group = _this.userGroup[0].value;
            _this.changeGroup(_this.staff.user.user_group);
        }
    }

    function initializeUserProfileImage() {
        ImageStore.onImageUpdated(function (image) {
            _this.profilePicture = image;
            _this.imageLoaded = true;
            Utils.forceScopeApply($scope);
        });

        if (_this.staff.user && _this.staff.user.id) {
            let user = _this.staff.user;
            ImageStore.requestImage({
                merchantId: user.merchant_id,
                userId: user.id
            }, window.PAYFIRMA.IMAGE_CATEGORY.OTHER_USER_PROFILE_PICTURE);
        }
    }

    // Initialize
    _this.onInit = function () {
        initDefaultStaffData();
        initUserGroup();
        initializeUserProfileImage();
    };

    /**
     * Save a user
     *
     * @param {Object} form - form
     * @param {Object} staff - user object
     */
    _this.save = function (form, staff) {
        if (form.$valid) {
            _this.loading = true;
            let isChangedToDeactivated = _this.staff.user.isChangedToDeactivated();
            return UserService.saveUser(staff.user)
                .then(
                    function (response) {
                        let successMessage = 'Staff user updated.';
                        if (!_this.staff.user.lookup_id) {
                            successMessage = 'Staff user created.';
                        } else if (_this.staff.user.lookup_id && isChangedToDeactivated) {
                            OauthTokenService.revokeTokenWithUserId(_this.staff.user.id);
                        }
                        PaperToastService.create(successMessage, 'update');
                        _this.goToStaffList();
                        return response;
                    })
                .catch(function (e) {
                    _this.loading = false;
                    PaperToastService.create(e.message, 'error');
                    throw e;
                });
        } else {
            PaperToastService.create('Fill in Required Fields.', 'error');
        }
    };

    _this.deleteStaff = function() {
        _this.deleteStaffProfileEnabled = true;
    };

    /**
     * Switch to different group permissions
     *
     * @param groupName - group ID
     */
    _this.changeGroup = function (groupName: string) {

        switch (groupName) {
            case USER_GROUP.ADMIN:
            case USER_GROUP.PAYFIRMA_ADMIN:
                _this.staff.user.staffPermissions.updateAllPermissions(true);
                break;

            case USER_GROUP.SALES:
                _this.staff.user.staffPermissions.updateAllPermissions(true);
                _this.staff.user.staffPermissions.accessPermission.apiAccess = false;
                _this.staff.user.staffPermissions.transactionPermission.authorize = false;
                _this.staff.user.staffPermissions.transactionPermission.refund = false;
                _this.staff.user.staffPermissions.reportingPermission.viewAllTransactions = false;
                break;

            case USER_GROUP.REPORTING:
                _this.staff.user.staffPermissions.updateAllPermissions(false);
                _this.staff.user.staffPermissions.accountPermission.active = true;
                _this.staff.user.staffPermissions.accessPermission.myTransactions = true;
                _this.staff.user.staffPermissions.accessPermission.customerVault = true;
                _this.staff.user.staffPermissions.accessPermission.dashboard = true;
                _this.staff.user.staffPermissions.reportingPermission.viewAllTransactions = true;
                break;

            default:
                _this.staff.user.staffPermissions.updateAllPermissions(false);
                break;
        }
    };

    /**
     * Select all access permissions
     */
    _this.selectAllAccessPermissions = function () {
        _this.staff.user.staffPermissions.accessPermission.updateAllPermissions(true);
    };

    /**
     * Clear all access permissions
     */
    _this.clearAllAccessPermissions = function () {
        _this.staff.user.staffPermissions.accessPermission.updateAllPermissions(false);
    };

    /**
     * Select all transaction permissions
     */
    _this.selectAllTransactionPermissions = function () {
        _this.staff.user.staffPermissions.transactionPermission.updateAllPermissions(true);
    };

    /**
     * Clear all transaction permissions
     */
    _this.clearAllTransactionPermissions = function () {
        _this.staff.user.staffPermissions.transactionPermission.updateAllPermissions(false);
    };

    /**
     * Go back to staff list
     */
    _this.goToStaffList = function () {
        UrlService.go('staff');
    };

    // Initialize function
    _this.onInit();

    ImageCropService.disableImageCroppingTriggers($scope);
}

angular
    .module(APP_MODULE)
    .component(COMPONENT_STAFF_EDIT, {
        bindings: {
            staff: '<'
        },
        controller: staffEdit,
        controllerAs: 'staffEdit',
        templateUrl: 'view/staff/edit/edit.html'
    });
