import angular from 'angular';
import UtilityService from 'service/utility-service';

function saveSubscription(TransactionService, PaperToastService, $log, Utils,
    HeapService, HeapMessage, SubscriptionService) {
    var _this = this;

    function enableLoader() {
        _this.isLoading = true;
    }

    function disableLoader() {
        _this.isLoading = false;
    }

    function disableContents() {
        _this.saveSubscriptionModalEnabled = false;
    }

    disableContents();

    function enableSaveSubscriptionModal() {
        _this.saveSubscriptionModalEnabled = true;
    }

    function formatCreditCardText(creditCard) {

        var card = creditCard.card_type + ' ' + creditCard.card_suffix;
        if (creditCard.card_description) {
            card += ' - ' + creditCard.card_description;
        }
        return card;
    }

    function createCreditCardOptionsAndSelect(existingSubscription) {
        _this.creditCardListOptions = _.map(
            _.filter(_this.creditCards, function (creditCard) {
                return !creditCard.is_expired;
            }),
            function (creditCard) {
                return {
                    value: creditCard,
                    key: formatCreditCardText(creditCard)
                };
            });
        if (_this.creditCardListOptions && _this.creditCardListOptions.length) {
            if (existingSubscription) {
                var matchedCard = _.find(_this.creditCardListOptions, function (creditCardOption) {
                    return existingSubscription.card_lookup_id === creditCardOption.value.lookup_id;
                });

                if (matchedCard){
                    _this.creditCardSelected = matchedCard.value;
                }
            }
            if (!_this.creditCardSelected) {
                var creditCardSelected = _.find(_this.creditCardListOptions, function (creditCardOption) {
                    return creditCardOption.value.is_default;
                });
                // check for default card selected and assign it a value
                if (!creditCardSelected) {
                    _this.creditCardSelected = _this.creditCardListOptions[0].value;
                } else {
                    _this.creditCardSelected = creditCardSelected.value;
                }
            }
        }
    }

    function checkPlanCycles (plan){
        var totalCycles = plan.total_cycles;
        var remainingCycles = plan.remaining_cycles;
        // Was sometimes returning as 0 others as "0"
        if (!totalCycles || totalCycles === 0 || totalCycles === '0'){
            return '∞';
        } else {
            return remainingCycles;
        }
    }

    function getPlanOptionName (plan) {
        var cycleCount = checkPlanCycles(plan);
        return plan.name + ' (' + plan.currency + ' ' +
            cycleCount + '/' + plan.frequency + ')';
    }

    _this.existingSubscriptionString = () => {
        let totalCycles;
        let planFrequency = UtilityService.toLowerCaseCapitalized(_this.existingSubscription.recurring_plan_frequency);
        if (!_this.existingSubscription.total_cycles){
            totalCycles = '∞';
        } else {
            totalCycles = _this.existingSubscription.total_cycles;
        }
        return _this.existingSubscription.name +
        ' (' +
        _this.existingSubscription.currency +
        ' ' +
        totalCycles +
        '/' +
        planFrequency +
        ')';
    };

    _this.createMerchantPlanOptionsAndSelect = function(existingSubscription) {
        _this.merchantPlansOptions = _.map(
            _.sortBy(_this.merchantPlans, function(plan){
                return plan.name ? plan.name.toLowerCase() : '';
            }),
            function (merchantPlan) {
                merchantPlan.frequency = UtilityService.toLowerCaseCapitalized(merchantPlan.frequency);
                return {
                    value: merchantPlan,
                    key: getPlanOptionName(merchantPlan)
                };
            });
        if (_this.merchantPlansOptions && _this.merchantPlansOptions.length) {
            if (existingSubscription) {
                let temp = _.find(_this.merchantPlansOptions, function (merchantPlanOption) {
                    return existingSubscription.plan_lookup_id === merchantPlanOption.value.lookup_id;
                });

                if (temp) {
                    _this.merchantPlanSelected = temp.value;
                }
            }

            if (!_this.merchantPlanSelected) {
                _this.merchantPlanSelected = _this.merchantPlansOptions[0].value;
            }

            if (_this.merchantPlanSelected) {
                _this.amount = _this.merchantPlanSelected.amount;
            }
        }
        _this.emailAddress = _this.customer.email;
    };

    function populateWithNewSubscription() {
        _this.bbcReceiptsTo = _this.customer.bcc_emails;
    }

    function populateWithExistingSubscription(existingSubscription) {
        _this.isExistingBillingSubscription = true;
        _this.startDate = window.moment(existingSubscription.next_run)
            .format(window.PAYFIRMA.CONSTANT.EPOCH_FORMAT.DATE_STANDARD);
        _this.initialDate = existingSubscription.next_run;
        _this.amount = existingSubscription.amount;
        if (_this.canSendReceipt()) {
            _this.emailAddress = existingSubscription.email;
            _this.bbcReceiptsTo = existingSubscription.bcc_emails;
        }
        _this.existingSubscription = existingSubscription;
    }

    window.PAYFIRMA.CommunicationService.onSaveSubscriptionModalOpen((existingSubscription) => {
        if (existingSubscription) {
            _this.isExistingBillingSubscription = true;
        }

        _this.createMerchantPlanOptionsAndSelect(existingSubscription);
        createCreditCardOptionsAndSelect(existingSubscription);

        if (existingSubscription) {
            populateWithExistingSubscription(existingSubscription);
        } else {
            populateWithNewSubscription();
        }

        enableSaveSubscriptionModal();
    });

    _this.canSendReceipt = function () {
        return _this.merchantPlanSelected && _this.merchantPlanSelected.send_receipt;
    };

    function closeAndCleanup() {
        populateWithNewSubscription();
        _this.merchantPlanSelected = undefined;
        _this.creditCardSelected = undefined;
        _this.amount = undefined;
        _this.emailAddress = undefined;
        _this.bbcReceiptsTo = undefined;
        _this.componentEnabled = false;
        _this.isExistingBillingSubscription = false;
        _this.transactionConfirmationEnabled = false;
        _this.saveSubscriptionModalEnabled = false;
        _this.transactionObject = undefined;
        _this.customerPlanResponse = undefined;
        disableContents();
    }

    function exitSaveSubscription(response) {
        disableLoader();
        window.PAYFIRMA.CommunicationService.triggerRefreshRecurringPlanList(response);
        closeAndCleanup();
    }

    _this.cancel = function () {
        closeAndCleanup();
    };

    function createSaveCustomerPlanRequestObject() {
        var planData = {};

        if (_this.isExistingBillingSubscription) {
            planData = _this.existingSubscription;
            planData.amount = _this.amount;
        } else {
            planData.plan_lookup_id = _this.merchantPlanSelected.lookup_id;
            planData.amount = _this.amount;
            planData.description = _this.description;
            planData.start_date = moment(_this.startDate).valueOf();
        }

        planData.card_lookup_id = _this.creditCardSelected.lookup_id;

        if (_this.canSendReceipt()) {
            planData.email = _this.emailAddress;
            planData.bcc_emails = _this.bbcReceiptsTo;
        }

        return planData;
    }

    window.PAYFIRMA.CommunicationService.onCancelTransactionConfirmEvent(function () {
        SubscriptionService.deleteCustomerPlan(_this.customerPlanResponse.id)
            .then(function () {
                exitSaveSubscription();
            });
    });

    window.PAYFIRMA.CommunicationService.onTransactionCompleleteEvent(function () {
        exitSaveSubscription();
    });

    function save() {
        return new Promise(function (resolve, reject) {
            if (!_this.isExistingBillingSubscription){
                SubscriptionService
                    .createCustomerPlan(createSaveCustomerPlanRequestObject(), _this.customer.lookup_id).then(
                        function(response){
                            resolve(response);
                        },
                        function (error){
                            reject(error);
                        }
                    );
            } else {
                SubscriptionService
                    .updateSubscription(createSaveCustomerPlanRequestObject(),
                        _this.customer.lookup_id).then(
                        function(response){
                            resolve(response);
                        },
                        function (error){
                            reject(error);
                        }
                    );
            }
        }).then(function (customerPlanResponse) {
            if (customerPlanResponse.status === 200) {
                //Heap Track
                HeapService.heapTrack(HeapMessage.TYPE_CUSTOMER_VAULT, HeapMessage.MESSAGE_SUBSCRIBER_ADDED);
                PaperToastService.create('Subscription saved', 'update');
                exitSaveSubscription(customerPlanResponse.data.subscriptions);
            }
            return undefined;
        }).then(function (salesResponse) {
            disableLoader();
            if (!salesResponse) {
                //no sales, no problem
                //just exits
                exitSaveSubscription();
            }
            return undefined;
        }).catch(function (error) {
            $log.error(error);
            PaperToastService.create('Something is wrong when saving subscription', 'error');
            exitSaveSubscription();
            throw error;
        });
    }

    _this.suspendSubscription = function (subscription) {
        SubscriptionService.suspendSubscription(subscription);
    };

    _this.activateSubscription = function (subscription) {
        SubscriptionService.activateSubscription(subscription);
    };

    // Populates Subscription information depending on currently selected
    _this.populateSubscriptionInformation = function(value){
        _this.emailAddress = _this.customer.email;
        _this.amount = value.amount;
    };

    _this.save = function (form) {
        enableLoader();
        if (form.$valid) {
            return save();
        } else {
            disableLoader();
        }
    };
}

angular
.module('payhqUIApp')
.component('saveSubscription', {
    bindings: {
        componentEnabled: '=',
        creditCards: '<',
        merchantPlans: '<',
        customer: '<'
    },
    controller: saveSubscription,
    controllerAs: 'saveSubscription',
    templateUrl: 'view/vault/modal/save-subscription.html'
});
