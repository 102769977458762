import angular from 'angular';
import PlanModel from 'model/plan';
import ConfigurationService from 'service/configuration-service';

angular
.module('payhqUIApp')
.service('PlanService',
    function PlanService(HttpClientService) {
        //GET ALL FOR NOW UNTIL PAGINATION WORKS TOGETHER WITH SORTING AND SEARCH
        const PAGE_LIMIT = window.PAYFIRMA.CONSTANT.PLAN_PAGINATION_LIMIT;

        return {
            get: function (params) {
                var paramsCopy = params || {};
                if (!paramsCopy.limit) {
                    paramsCopy.limit = PAGE_LIMIT;
                }
                return HttpClientService.doGet(
                    ConfigurationService.getConfig().planServiceApi + 'plan', paramsCopy)
                    .then(function (allPlansResponse) {
                        var allPlansObject = {
                            entities: _.map(allPlansResponse.data.entities, function (planResponse) {
                                return new PlanModel(planResponse);
                            })
                        };

                        if (allPlansResponse.data.paging && allPlansResponse.data.paging.cursor) {
                            allPlansObject.paging =
                                new window.PAYFIRMA.Paging(allPlansResponse.data.paging.cursor);
                        }
                        return allPlansObject;
                    });
            },
            create: function (newPlanObject) {
                return HttpClientService
                    .doPost(
                        ConfigurationService.getConfig().planServiceApi + 'plan',
                        newPlanObject
                    )
                    .then(function (planResponse) {
                        return new PlanModel(planResponse.data);
                    });
            },

            update: function (lookupId, updatedPlanObject) {
                return HttpClientService
                    .doPut(
                        ConfigurationService.getConfig().planServiceApi + 'plan/' + lookupId,
                        updatedPlanObject
                    )
                    .then(function (planResponse) {
                        return new PlanModel(planResponse.data);
                    });
            },

            delete: function (lookupId) {
                return HttpClientService.doDelete(
                    ConfigurationService.getConfig().planServiceApi + 'plan/' + lookupId
                );
            },
            /**
             * Billing frequency options
             */
            getAllBillingFrequencies: function () {
                return [{
                    key: 'Daily',
                    value: 'DAILY'
                }, {
                    key: 'Weekly',
                    value: 'WEEKLY'
                }, {
                    key: 'Monthly',
                    value: 'MONTHLY'
                }, {
                    key: 'Quarterly',
                    value: 'QUARTERLY'
                }, {
                    key: 'Annually',
                    value: 'ANNUALLY'
                }];
            },

            /**
             * Return list of currencies selectable
             *
             * @param {Object} availableCurrencies object of currencys availible
             * @return {Array} options selection object of avaialbe currrnecys
             */
            getAvailableCurrencyOptions: function (availableCurrencies) {
                return _.map(availableCurrencies, function (currency) {
                    return {
                        key: currency,
                        value: currency
                    };
                });
            }
        };
    }
);