import angular from 'angular';
import MerchantModel from 'model/merchant';
import ConfigurationService from 'service/configuration-service';
import UtilityService from 'service/utility-service';

/**
 * @ngdoc service
 * @name payhqUIApp.merchantApp.service:MerchantService
 * @description
 *
 * Service for handling merchantApp
 *
 */

angular
    .module('payhqUIApp')
    .service('MerchantService',
        function MerchantService(HttpClientService) {

            function getMerchants(searchOptions) {

                const cursor = searchOptions ? searchOptions.cursor : undefined;
                const limit = searchOptions ? searchOptions.limit : undefined;

                let param = {};

                if (limit) {
                    param.limit = limit;
                }
                if (cursor) {
                    param.after = cursor;
                }

                return HttpClientService.doGet(
                    ConfigurationService.getConfig().merchantServiceApi + 'merchant', param)
                    .then(function (allMerchantsResponse) {
                        let returnedCursor;

                        if (allMerchantsResponse.data.paging) {
                            returnedCursor = allMerchantsResponse.data.paging.cursor;
                        }

                        let entities = _.map(allMerchantsResponse.data.entities, function (merchantResponse) {
                            return new MerchantModel(merchantResponse);
                        });

                        return new window.PAYFIRMA.Paging(returnedCursor, entities);
                    }).catch(function(e){
                        throw UtilityService.parseErrorMessage(e);
                    });
            }

            return {
                getNext: function (searchOptions) {
                    return getMerchants({
                        limit: window.PAYFIRMA.CONSTANT.PAGINATION_LOAD_BATCH_SIZE,
                        cursor: searchOptions ? searchOptions.cursor : undefined
                    });
                },
                getInitialData: function () {
                    return getMerchants({
                        limit: window.PAYFIRMA.CONSTANT.PAGINATION_INITIAL_LOAD_BATCH_SIZE
                    });
                },
                saveMerchant: function (merchant) {
                    // Save a new customer
                    if (merchant.isNew()) {
                        return HttpClientService
                            .doPost(
                                ConfigurationService.getConfig().merchantServiceApi + 'merchant',
                                merchant
                            ).then(function (response) {
                                return new MerchantModel(response);
                            }).catch(function(e){
                                throw UtilityService.parseErrorMessage(e);
                            });
                    } else {
                        return HttpClientService
                            .doPut(
                                ConfigurationService.getConfig().merchantServiceApi + 'merchant/' + merchant.id,
                                merchant
                            ).then(function (response) {
                                return new MerchantModel(response);
                            }).catch(function(e){
                                throw UtilityService.parseErrorMessage(e);
                            });
                    }
                },

                deleteMerchant: function (merchantId) {
                    return HttpClientService.doDelete(
                        ConfigurationService.getConfig().merchantServiceApi + 'merchant/' + merchantId
                    );
                },
                showHideMarketingBanner: function (merchantId) {
                    return HttpClientService.doGet(
                        ConfigurationService.getConfig().merchantServiceApi + 'merchant/showHideMarketingBanner?merchantId=' +
                        merchantId
                    ).then(function(response) {
                        return response;
                    }).catch(function(e) {
                        throw UtilityService.parseErrorMessage(e);
                    });
                },
                getMerchant: function (merchantId) {
                    return HttpClientService.doGet(
                        ConfigurationService.getConfig().merchantServiceApi + 'merchant/' + merchantId)
                        .then(function (merchantResponse) {
                            return new MerchantModel(merchantResponse.data);
                        })
                        .catch(function(e){
                            throw UtilityService.parseErrorMessage(e);
                        });
                }
            };
        });