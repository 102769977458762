import angular from 'angular';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import SessionStorageService from 'service/session-storage-service';

function invoicingEdit($scope, ImageStore, $translate, InvoiceService, CustomerService,
    SessionService, TransactionService, Utils, PaperToastService, $timeout, HeapService, HeapMessage, TimeService) {
    const CONSTANT = window.PAYFIRMA.CONSTANT;
    const END_OF_DAY = moment().endOf('day');

    let _this = this;
    _this.initialdata = _this.initialdata || {};
    const INVOICE_TYPE = _this.initialdata.invoice_type;

    window.PAYFIRMA.CustomerDataService.getAllData()
        .then((data) => {
            $timeout(() => {
                _this.customers = data;
            });
        });

    _this.errorNoInvoice = false;
    _this.submitType = '';
    _this.emailSelectionIndex = 0;
    _this.provinceSelectionIndex = 0;
    _this.displayed_due_date = '';
    _this.errorMessages = [];
    _this.successMessages = [];
    _this.searchingCustomer = false;
    _this.autoCompleteSelect = false;
    _this.isNewCustomer = true;
    _this.showTax1 = false;
    _this.showTax2 = false;
    _this.previewInvoice = false;
    _this.dateCreated = null;
    _this.customers = null;
    _this.email = '';
    _this.limit = 5;
    _this.autoCompleteSelect = 0;
    _this.merchantDefaultCurrency = 'CAD';
    _this.allowUSD = false;
    _this.allowCAD = false;
    _this.todays_date = moment().format('MMM DD YYYY');
    _this.singleDate = moment().format('MMM DD YYYY');
    _this.imageLogoSource = '';
    _this.showPreview = false;
    _this.loading = false;
    _this.emailSelected = true;
    _this.datePickerOptionDate = moment().format('MM/DD/YYYY');
    //invoice settings object
    _this.invoiceSettings = {
        language: 'English',
        logo_image_path: '',
        business_name: '',
        business_address1: '',
        business_address2: '',
        business_city: '',
        business_state: '',
        business_country: '',
        business_postal_code: '',
        email_from: '',
        support_email: '',
        support_phone_number: '',
        custom_invoice_message: '',
        custom_email_message: '',
        resend_invoices: true
    };

    //Invoice object
    _this.invoice = {
        due_date: '',
        title: '',
        language: 'English',
        description: '',
        logo_image_path: '',
        business_name: '',
        business_address1: '',
        business_address2: '',
        business_city: '',
        business_state: '',
        business_country: '',
        business_postal_code: '',
        email_from: '',
        support_email: '',
        support_phone_number: '',
        customer_id: null,
        customer_lookup_id: null,
        recipient_email: '',
        recipient_first_name: '',
        recipient_last_name: '',
        company: '',
        order_id: '',
        billing_address1: '',
        billing_address2: '',
        billing_city: '',
        billing_state:null,
        billing_country: null,
        billing_postal_code: '',
        email_message: '',
        invoice_message: '',
        items: [{
            item: null,
            quantity: 1,
            price: null,
            tax1: {
                percentile: true,
                amount: 0.00
            },
            tax2: {
                percentile: true,
                amount: 0.00
            },
            amount: 0.00,
            discount: false
        }],
        sub_total: 0.00,
        currency: null,
        tax1: null,
        tax1_label: '',
        tax2: null,
        tax2_label: '',
        total: 0.00,
        status: 'Unpaid',
        invoice_id: '',
        resend_invoice: true
    };
    _this.currencyList = [];
    _this.countryList = [];
    _this.provinceList = [];

    _this.list_tax_type = [
        { 'key': '%', 'value': true },
        { 'key': '$', 'value': false }
    ];

    _this.oldValues = {
        country : '',
        countryMessage : '',
        province : '',
        provinceMessage : ''
    };
    const oldValueMessage = 'Old value : ';

    function initializeImageLogo(defaultTemplate) {
        var existingInvoice = _this.initialdata.invoice;
        _this.invoiceLogo = '';
        if (existingInvoice) {
            _this.invoiceLogo = InvoiceService.getInvoiceLogo(_this.initialdata.invoice.data.invoice_id);
        } else {
            ImageStore.onImageUpdated((image) => {
                _this.invoiceLogo = image;
                Utils.forceScopeApply($scope);
            });

            ImageStore.requestImage({
                templateId: defaultTemplate.template_id
            }, window.PAYFIRMA.IMAGE_CATEGORY.INVOICE_LOGO);
        }
    }
    function templateError() {
        _this.errorNoInvoice = true;
    }
    function setTaxFromTemplate(data) {
        if (!data.tax1_label) {
            _this.invoice.tax1_label = 'Tax1';
        } else {
            _this.invoice.tax1_label = data.tax1_label;
        }
        if (!data.tax2_label) {
            _this.invoice.tax2_label = 'Tax2';
        } else {
            _this.invoice.tax2_label = data.tax2_label;
        }
        if (!data.tax1) {
            _this.invoice.items[0].tax1.amount = 0.00;
        } else {
            _this.invoice.items[0].tax1.amount = data.tax1;
        }
        if (!angular.isDefined(data.percentile1)) {
            _this.invoice.items[0].tax1.percentile = true;
        } else {
            _this.invoice.items[0].tax1.percentile = data.percentile1;
        }
        if (!data.tax2) {
            _this.invoice.items[0].tax2.amount = 0.00;
        } else {
            _this.invoice.items[0].tax2.amount = data.tax2;
        }
        if (!angular.isDefined(data.percentile2)) {
            _this.invoice.items[0].tax2.percentile = true;
        } else {
            _this.invoice.items[0].tax2.percentile = data.percentile2;
        }
    }
    function parseSettingsData(data) {
        // If invoice is new invoice
        if (INVOICE_TYPE === CONSTANT.NEW_INVOICE){
            _this.invoice.invoice_message = data.custom_invoice_message;
            _this.invoice.email_message = data.custom_email_message;
            _.forEach(data, (value, key) => {
                if (_this.invoice.hasOwnProperty(key) && value !== null) {
                    _this.invoice[key] = value;
                }
            });
            setTaxFromTemplate(data);
        }
        if (INVOICE_TYPE !== CONSTANT.DRAFT_INVOICE) {
            _this.invoice.resend_invoice = data.resend_invoices;
        }
    }
    /**
     * Set Taxes from invoiceTemplate
     * @param data
     */
    function getInvoiceSettings() {
        //update settings object
        //Set from invoice api call
        InvoiceService.getInvoiceTemplates()
        .then((invoiceTemplateResponse) => {
            //select first Template as deffault
            var defualtTemplate = invoiceTemplateResponse.templates[0];
            //validate template fields and populate missing information
            var templateResponse = InvoiceService.validateTemplate(defualtTemplate, true);
            //warn user if using populate information
            if (!templateResponse.isValid) {
                templateError();
            }
            initializeImageLogo(defualtTemplate);
            //Add template info into the invoice object
            parseSettingsData(templateResponse.validTemplate);

        }).catch(() => {
            //If no template is set generate a temporary template settings if user is admin
            var generateTempTemplate = {};
            let currentUser = SessionStorageService.get().getUser();
            if (currentUser.isAdmin() || currentUser.isPayfirmaAdmin()) {
                InvoiceService.validateTemplate(generateTempTemplate, true);
                parseSettingsData(generateTempTemplate);
            }
            templateError();
        });
    }
    // Check item quantity. If it is blank, then set 0
    function checkItemQuantity() {
        angular.forEach(_this.invoice.items, (item, key) => {
            if (!item.quantity) {
                _this.invoice.items[key].quantity = 0;
            }
        });
    }
    //@author: carl, this is a hack because server does not like empty string for nullable values
    //so we have to specifically set the variable to undefined instead of using empty string
    //I prefer to move this check to the server, but for now, I guess we will leave it in the client
    function createSumbitInvoiceObject() {
        var submitInvoiceObject = {};
        _.extend(submitInvoiceObject, _this.invoice);
        if (!submitInvoiceObject.support_email) {
            submitInvoiceObject.support_email = undefined;
        }
        if (!submitInvoiceObject.email_from) {
            submitInvoiceObject.email_from = undefined;
        }
        if (!submitInvoiceObject.resend_invoice) {
            submitInvoiceObject.resend_invoice = true;
        }

        submitInvoiceObject.due_date = _this.getInvoiceDueDateWithTimezone(submitInvoiceObject.due_date);

        // Set taxes information for blank
        if (!submitInvoiceObject.tax1_label) {
            submitInvoiceObject.tax1_label = null;
            angular.forEach(_this.invoice.items, (item, key) => {
                submitInvoiceObject.items[key].tax1 = null;
            });
        }
        // Set taxes information for blank
        if (!submitInvoiceObject.tax2_label) {
            submitInvoiceObject.tax2_label = null;
            angular.forEach(_this.invoice.items, (item, key) => {
                submitInvoiceObject.items[key].tax2 = null;
            });
        }

        // using old country value to update
        if (_this.oldValues.country && !_this.invoice.billing_country){
            submitInvoiceObject.billing_country = _this.oldValues.country;
        }
        // using old province value to update
        if (_this.oldValues.province && !_this.invoice.billing_state){
            submitInvoiceObject.billing_state = _this.oldValues.province;
        }

        return submitInvoiceObject;
    }
    // Create Draft
    function createDraft() {
        var invoiceData = createSumbitInvoiceObject();
        InvoiceService.createDraft(invoiceData)
            .then((createDraftResponce) => {
                if (createDraftResponce.status === 200) {
                    PaperToastService.create('Draft Saved', 'update');
                    Utils.go('/invoicing');
                } else {
                    _this.previewInvoice = false;
                    PaperToastService.create('There was an error creating your draft, please try again.', 'error');
                }
                return createDraftResponce;
            });
    }
    //  Send invoice TEMP
    function sendInvoice(invoiceId) {
        //update settings object
        //Set from invoice api call
        InvoiceService.sendInvoiceEmail(invoiceId)
            .then((sendInvoiceResponce) => {
                //Heap Track
                HeapService.heapTrack(HeapMessage.TYPE_INVOICE, HeapMessage.MESSAGE_INVOICE_SENT);
                _this.loading = false;
                if (sendInvoiceResponce.status === 200) {
                    _this.previewInvoice = false;

                    $translate('invoiceSentTo').then((translation) => {
                        var message = translation + ': ' + sendInvoiceResponce.data.email_address;
                        PaperToastService.create(message, 'update');
                    });
                    Utils.go('/invoicing');
                } else {
                    PaperToastService.create('Failed to send invoice', 'error');
                    Utils.go('/invoicing');
                }
                return sendInvoiceResponce;
            });
    }
    function createInvoice() {
        var invoiceData = createSumbitInvoiceObject();
        InvoiceService.createInvoice(invoiceData)
            .then((createInvoiceResponce) => {
                _this.loading = false;
                if (createInvoiceResponce.status === 200) {
                    sendInvoice(createInvoiceResponce.data.invoice_id);
                } else if (createInvoiceResponce.data.errors[0].code === 'REQUIRES_TO_BE_IN_FUTURE') {
                    $translate('dueDatePast').then((translation) => {
                        PaperToastService.create(translation, 'error');
                    });
                } else if (createInvoiceResponce.data.errors[0].code === 'INVALID_EMAIL') {
                    $translate('errorInvalidEmail').then((translation) => {
                        PaperToastService.create(translation, 'error');
                    });
                } else {
                    $translate('invoiceCreateError').then((translation) => {
                        PaperToastService.create(translation, 'error');
                    });
                }
                return createInvoiceResponce;
            });
    }
    //  Save cusomter
    function saveNewCustomer() {
        var invoice = _this.invoice;
        var customerObject = {
            address1: invoice.billing_address1,
            address2: invoice.billing_address2,
            bcc_emails: '',
            card_on_file: null,
            city: invoice.billing_city,
            company: invoice.company,
            country: invoice.billing_country,
            creation_date: null,
            custom_id: '',
            default_card: null,
            email: invoice.recipient_email,
            first_name: invoice.recipient_first_name,
            last_name: invoice.recipient_last_name,
            postal_code: invoice.billing_postal_code,
            province: invoice.billing_state,
            recurring_plan_id: null,
            recurring_plan_statuses: null,
            telephone: ''
        };
        CustomerService.createNewCustomer(customerObject).then((response) => {
            if (typeof response.id !== 'undefined') {
                _this.invoice.customer_id = response.id;
                _this.invoice.customer_lookup_id = response.lookup_id;
                createInvoice();
            } else {
                createInvoice();
            }
            return true;
        });
    }
    //  validate fields.
    function validateFields(inputGroup) {
        var isValid = true;
        //clear all egsisting error fields
        _this.errorMessages = [];
        $('.error').removeClass('error');

        //check required fields
        $(inputGroup).each(() => {
            if (!(this.value && this.value.length)) {
                isValid = false;
                $(this.parentElement).addClass('error');
            }
        });
        return isValid;
    }

    function initializeCountrySelections () {
        _this.provinceList.push({
            key: 'Select a Province/State',
            value: null
        });
        _this.countryList = TransactionService.getCountries();
    }

    function resetOldValuesForCountry (forCountry) {
        if (forCountry){
            _this.oldValues.country = '';
            _this.oldValues.countryMessage = '';
        } else {
            _this.oldValues.province = '';
            _this.oldValues.provinceMessage = '';
        }
    }

    _this.onChangeCountry = function (selectedCountry) {
        resetOldValuesForCountry(true);
        if (!selectedCountry){
            _this.invoice.billing_state=null;
            return;
        }
        _this.provinceList = TransactionService.getProvinces(selectedCountry);
    };

    _this.onChangeProvince = function () {
        resetOldValuesForCountry(false);
    };

    _this.autoFillCustomer = function (value) {
        if (value && value.country){
            value.billing_country = value.country;
            value.billing_state = value.province;
            const resultFound = TransactionService.getCountryProvinceCode(value, _this.onChangeCountry, _this.invoice, 'billing_country', 'billing_state');
            if (!resultFound.country){
                TransactionService.notFoundOldValues(_this.oldValues, value, oldValueMessage, true, _this.invoice, 'billing_country', 'billing_state');
            }
            if (!resultFound.province){
                TransactionService.notFoundOldValues(_this.oldValues, value, oldValueMessage, false, _this.invoice, 'billing_country', 'billing_state');
            }
        }
    };

    initializeCountrySelections();

    //  Send in percent and amount and return a decimal
    function bigPercentToDecimal(percent, amount) {
        let bigPercent = new BigNumber(percent || 0);
        let bigAmount = new BigNumber(amount || 0);
        let bigDecimal = bigPercent
            .times(bigAmount)
            .round()
            .dividedBy(100);
        return bigDecimal;
    }
    function setInvoiceDueDate() {
        let invoice = _this.initialdata.invoice;
        if (invoice) {
            _this.invoice.due_date = moment(invoice.data.due_date).format('MMM DD, YYYY');
        }
    }

    $scope.$watch('singleDate', (newVal, oldVal) => {
        if (newVal !== oldVal) {
            setInvoiceDueDate();
        }
    });

    // Initialize
    _this.$onInit = () => {
        if (_this.initialdata.vaultCustomer) {
            _this.isNewCustomer = false;
            _this.invoice.customer_id = _this.initialdata.vaultCustomer.id;
            _this.invoice.customer_lookup_id = _this.initialdata.vaultCustomer.lookup_id;
            _this.invoice.recipient_email = _this.initialdata.vaultCustomer.email;
            _this.invoice.recipient_first_name = _this.initialdata.vaultCustomer.first_name;
            _this.invoice.recipient_last_name = _this.initialdata.vaultCustomer.last_name;
            _this.invoice.recipient_last_name = _this.initialdata.vaultCustomer.last_name;
            _this.invoice.company = _this.initialdata.vaultCustomer.company;
            _this.invoice.billing_address1 = _this.initialdata.vaultCustomer.address1;
            _this.invoice.billing_address2 = _this.initialdata.vaultCustomer.address2;
            _this.invoice.billing_city = _this.initialdata.vaultCustomer.city;
            _this.invoice.billing_postal_code = _this.initialdata.vaultCustomer.postal_code;
            if (_this.initialdata.vaultCustomer.country){
                _this.initialdata.vaultCustomer.billing_country = _this.initialdata.vaultCustomer.country;
                _this.initialdata.vaultCustomer.billing_state = _this.initialdata.vaultCustomer.province;
                const resultFound = TransactionService.getCountryProvinceCode(_this.initialdata.vaultCustomer, _this.onChangeCountry,
                    _this.invoice, 'billing_country', 'billing_state');
                if (!resultFound.country){
                    TransactionService.notFoundOldValues(_this.oldValues, _this.initialdata.vaultCustomer, oldValueMessage, true, _this.invoice, 'billing_country', 'billing_state');
                }
                if (!resultFound.province){
                    TransactionService.notFoundOldValues(_this.oldValues, _this.initialdata.vaultCustomer, oldValueMessage, false, _this.invoice, 'billing_country', 'billing_state');
                }
            }
            _this.searchingCustomer = false;
        }
        if (_this.initialdata.invoice) {
            angular.forEach(_this.initialdata.invoice.data, (value, key) => {
                if (_this.invoice.hasOwnProperty(key) && value !== null) {
                    _this.invoice[key] = value;
                }
            });
            if (_this.initialdata.invoice.data.status === 'Draft') {
                _this.singleDate = moment(_this.initialdata.invoice.data.due_date).format('MMM DD YYYY');
            }
        }
        // Get currency from merchant account
        _this.gateways = SessionService.getCurrentMerchantGateways() || [];

        _this.allowCAD = !!_.find(_this.gateways, function(gateway){
            return gateway === 'CAD';
        });

        _this.allowUSD = !!_.find(_this.gateways, function(gateway){
            return gateway === 'USD';
        });

        if (_this.allowCAD) {
            _this.currencyList.push({ 'key': 'CAD','value': 'CAD' });
        }

        if (_this.allowUSD) {
            _this.currencyList.push({ 'key': 'USD', 'value': 'USD' });
        }

        let numberOfCurrencies = _this.gateways.length;

        //defulat to cad if there is support for usd and cad
        if (numberOfCurrencies === 1) {
            _this.merchantDefaultCurrency = _this.gateways[0];
        } else {
            _this.merchantDefaultCurrency = 'CAD';
        }
        _this.invoice.currency = _this.merchantDefaultCurrency;
        if (_this.initialdata.invoice) {
            angular.forEach(_this.initialdata.invoice.data, (value, key) => {
                if (_this.invoice.hasOwnProperty(key) && value !== null) {
                    _this.invoice[key] = value;
                }
            });
            if (_this.initialdata.invoice.data.status === 'Draft') {
                _this.singleDate = moment(_this.initialdata.invoice.data.due_date).format('MMM DD YYYY');
            }
            if (_this.initialdata.invoice.data.billing_country){
                const resultFound = TransactionService.getCountryProvinceCode(_this.initialdata.invoice.data, _this.onChangeCountry, _this.invoice, 'billing_country', 'billing_state');
                if (!resultFound.country){
                    TransactionService.notFoundOldValues(_this.oldValues, _this.initialdata.invoice.data, oldValueMessage, true, _this.invoice, 'billing_country', 'billing_state');
                }
                if (!resultFound.province){
                    TransactionService.notFoundOldValues(_this.oldValues, _this.initialdata.invoice.data, oldValueMessage, false, _this.invoice, 'billing_country', 'billing_state');
                }
            }
        }
        getInvoiceSettings();
        setInvoiceDueDate();
    };
    // Send Invoice
    _this.doSave = (form) => {
        if (form.$valid) {
            _this.loading = true;
            // If date selected is today: Set to the last time of the day
            if (_this.isNewCustomer === false || _this.invoice.customer_id) {
                createInvoice();
            } else {
                saveNewCustomer();
            }
        } else {
            PaperToastService.create('Missing required field', 'error');
        }
    };
    // Save Draft
    _this.doDraft = () => {
        var selectedDate = moment(_this.invoice.due_date).format('MMM DD YYYY');
        _this.loading = true;
        if (selectedDate === _this.todays_date) {
            _this.invoice.due_date = moment(END_OF_DAY).unix() * 1000;
        }
        checkItemQuantity();
        createDraft();
        _this.previewInvoice = false;
    };
    // Updte Draft invoice
    _this.updateDraft = (invoiceId) => {
        var invoiceData = createSumbitInvoiceObject();
        checkItemQuantity();
        InvoiceService.updateDraft(invoiceId, invoiceData)
            .then((updateDraftResponce) => {
                if (updateDraftResponce.status === 200) {
                    PaperToastService.create('Draft Saved', 'update');
                    Utils.go('/invoicing');
                } else {
                    _this.previewInvoice = false;
                    PaperToastService.create('There was an error creating your draft, please try again.', 'error');
                }
                return updateDraftResponce;
            });
    };
    // Send invoice Draft
    _this.sendDraftInvoice = (invoiceId, form) => {
        var invoiceData;
        _this.previewInvoice = false;

        if (form.$valid) {
            invoiceData = createSumbitInvoiceObject();
            _this.loading = true;
            InvoiceService.sendDraftInvoice(invoiceId, invoiceData)
                .then((sendDraftResponce) => {
                    _this.loading = false;
                    if (sendDraftResponce.status === 200) {
                        sendInvoice(invoiceId);
                    } else {
                        _this.previewInvoice = false;
                        PaperToastService.create('There was an error sending your invoice, please try again.', 'error');
                    }
                    return sendDraftResponce;
                });
        } else {
            $translate('fillOutRequiredFields').then((translation) => {
                PaperToastService.create(translation, 'error');
            });
        }
    };
    //  Add new Item to items array
    _this.addLineItem = () => {
        var hasTax1 = (_this.invoice.items[0].tax1 !== null);
        var hasTax2 = (_this.invoice.items[0].tax2 !== null);
        var newInvoiceObject;
        if (hasTax1 && hasTax2) {
            newInvoiceObject = {
                item: null,
                tax1: {
                    percentile: _this.invoice.items[0].tax1.percentile,
                    amount: _this.invoice.items[0].tax1.amount
                },
                tax2: {
                    percentile: _this.invoice.items[0].tax2.percentile,
                    amount: _this.invoice.items[0].tax2.amount
                },
                amount: 0,
                quantity: 1,
                discount: false,
                price: null
            };
        } else if (hasTax1) {
            newInvoiceObject = {
                item: null,
                tax1: {
                    percentile: _this.invoice.items[0].tax1.percentile,
                    amount: _this.invoice.items[0].tax1.amount
                },
                amount: 0,
                quantity: 1,
                discount: false,
                price: null
            };
        } else if (hasTax2) {
            newInvoiceObject = {
                item: null,
                tax2: {
                    percentile: _this.invoice.items[0].tax2.percentile,
                    amount: _this.invoice.items[0].tax2.amount
                },
                amount: 0,
                quantity: 1,
                discount: false,
                price: null
            };
        } else {
            newInvoiceObject = {
                item: null,
                amount: 0,
                quantity: 1,
                discount: false,
                price: null
            };
        }
        _this.invoice.items.push(newInvoiceObject);
    };

    _this.removeItem = (index) => {
        _this.invoice.items.splice(index, 1);
    };

    _this.amount = (item) => {
        let amount;
        let bigAmount = new BigNumber(0);
        let bigItemPrice = new BigNumber(item.price || 0);
        let bigItemQuantity = new BigNumber(item.quantity || 0);
        if (item.tax1 && !_this.invoice.tax1_label){
            item.tax1.amount = 0;
        }
        if (item.tax2 && !_this.invoice.tax2_label){
            item.tax2.amount = 0;
        }
        if (item.price !== null) {
            bigAmount = bigItemPrice.times(bigItemQuantity);
            item.amount = bigAmount.toNumber();
        }
        amount = bigAmount.toNumber();
        return amount;
    };

    _this.subtotal = () => {
        let subtotal = 0;
        let bigSubtotal = new BigNumber(subtotal);
        _.forEach(_this.invoice.items, (item) => {
            let bigPrice = new BigNumber(item.price || 0);
            let bigQuantity = new BigNumber(item.quantity || 0);
            let itemSubTotal = bigPrice.times(bigQuantity);
            bigSubtotal =
                bigSubtotal
                .plus(itemSubTotal);
        });
        subtotal = bigSubtotal.toNumber();
        _this.invoice.sub_total = subtotal;
        return subtotal;
    };

    _this.taxAmount = (tax) => {
        let taxAmount = 0;
        let bigTaxAmount = new BigNumber(taxAmount);
        _.forEach(_this.invoice.items, (item) => {
            let bigTaxValue = new BigNumber(0);
            let bigItemTaxAmount = new BigNumber(item[tax].amount || 0);
            let bigItemAmount = new BigNumber(item.amount || 0);
            let bigItemQuantity = new BigNumber(item.quantity || 0);

            if (item[tax].percentile) {
                bigTaxValue = new BigNumber(bigPercentToDecimal(bigItemTaxAmount, bigItemAmount));
            } else if (!item[tax].percentile) {
                bigTaxValue = bigItemTaxAmount.times(bigItemQuantity);
            }
            bigTaxAmount = bigTaxAmount.plus(bigTaxValue);
        });
        taxAmount = bigTaxAmount.toNumber();
        _this.invoice[tax] = taxAmount;
        return taxAmount;
    };

    _this.selectTax = (params, selector) => {
        if (params.taxType === 'tax1') {
            _this.invoice.items[params.index].tax1.percentile = selector;
        } else {
            _this.invoice.items[params.index].tax2.percentile = selector;
        }
    };

    _this.total = () => {
        let total = 0;
        let bigTotal = new BigNumber(total);
        let bigTax1 = new BigNumber(_this.invoice.tax1 || 0);
        let bigTax2 = new BigNumber(_this.invoice.tax2 || 0);

        _.forEach(_this.invoice.items, (item) => {
            let bigItemAmount = new BigNumber(item.amount || 0);
            bigTotal =
                bigTotal
                .plus(bigItemAmount);
        });

        bigTotal =
            bigTotal
            .plus(bigTax1)
            .plus(bigTax2);

        total = bigTotal.toNumber();
        _this.invoice.total = total;
        return total;
    };

    _this.price = (keyEvent, index) => {
        keyEvent.preventDefault();
        //remove negative amount flag if 0 dollars
        if (keyEvent.currentTarget.value === '$ 0.00') {
            _this.invoice.items[index].discount = false;
        }
        //flag the item for discount on '-' key and numpad
        if (keyEvent.which === 189 || keyEvent.which === 109) {
            keyEvent.preventDefault();
            _this.invoice.items[index].discount = true;
            _this.invoice.items[index].tax1.amount = 0;
            _this.invoice.items[index].tax2.amount = 0;
        }
    };

    _this.viewPreview = () => {
        var formElements = $('.validate-field');
        if (validateFields(formElements) === true) {
            _this.previewInvoice = true;

        } else {
            $translate('invoicePreviewError').then((translation) => {
                _this.successMessages.push(translation);
            });
            $('html, body').animate({
                scrollTop: 0
            }, 'slow');
        }
    };

    _this.displayTax1 = () => {
        var displayTax = true;
        if (!(_this.invoice.tax1_label.length !== 0 || _this.tax1focused)) {
            displayTax = false;
        }
        return displayTax;
    };

    _this.displayTax2 = () => {
        var displayTax = true;
        if (!(_this.invoice.tax2_label.length !== 0 || _this.tax2focused)) {
            displayTax = false;
        }
        return displayTax;
    };

    _this.doAction = (form, invoice) => {

        if (_this.submitType === 'saveDraft') {
            _this.doDraft();
        } else if (_this.submitType === 'updateDraft') {
            _this.updateDraft(invoice.invoice_id);
        } else if (_this.submitType === 'sendDraft') {
            _this.sendDraftInvoice(invoice.invoice_id, form);
        } else {
            _this.doSave(form);
        }
    };

    _this.showInvoicePreview = () => {
        window.PAYFIRMA.CommunicationService.triggerPaperModalOpenEvent();
    };

    _this.cancel = () => {
        Utils.go('/invoicing');
    };
    _this.getInvoiceDueDateWithTimezone = (dueDate) => {
        return TimeService.toEndOfDayByTimezone(dueDate);
    };

}
angular.module('payhqUIApp').component('invoicingEdit', {
    bindings: {
        initialdata: '='
    },
    controller: invoicingEdit,
    controllerAs: 'invoicingEdit',
    templateUrl: 'view/invoicing/edit/edit.html'
});