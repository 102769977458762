/* @flow */
import ConfigurationService from 'service/configuration-service';

class ExportFileService {
    downloadElement: Object

    constructor(windowObject: Object) {
        this.downloadElement = windowObject.document.createElement('a');
        windowObject.document.body.appendChild(this.downloadElement);
    }

    export(fileName: string) {
        if (this.downloadElement) {
            this.downloadElement.href = ConfigurationService.getConfig().fileManagementServicePublicApi
                + 'export/' + fileName;
            this.downloadElement.download = fileName;
            this.downloadElement.click();
        }
    }
}

export default ExportFileService;