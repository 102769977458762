import angular from 'angular';

function transactionTableSection(Utils) {
    var self = this;
    self.viewAllCustomerTransactions = function () {
        Utils.go(decodeURIComponent('/transactions/customer/email/' + self.customerEmail));
    };
}

angular
.module('payhqUIApp')
.component('transactionTableSection', {
    bindings: {
        recentTransactions: '<',
        customerId: '<',
        customerEmail: '<'
    },
    controller: transactionTableSection,
    controllerAs: 'transactionTableSection',
    templateUrl: 'view/vault/transaction-table/transaction-table-section.html'
});
