import angular from 'angular';
import SessionStorageService from 'service/session-storage-service';

angular
.module('payhqUIApp')
.service('UserPermissionService', function UserPermissionService(Restangular, $log) {
    /**
     * Parse the permissions allowed by a user
     *
     * @param {Object} permissions - List of user permissions
     * @return {Array} parsedPermissions - Filtered list of allowed permissions by a user
     */
    var parsePermissions = function(permissions) {
        return _.map(_.filter(permissions, function(permission) {
            return permission.allow;
        }), function(permission) {
            return permission.acl_permission_name;
        });
    };


    /**
     * load and construct the permission settings for the user
     * @param {Object} permissions - List of user permissions
     * @return {accessPermissions, transactionPermissions, accountPermission,
        viewAllTransactionPermission} permission settings - a list of permissions settings for the user
        */
    var loadStaffPermissions = function(permissions) {
        var accountPermission = {
            AccountAccess: false
        };
        var accessPermissions = {
            MobileMaster: false,
            CustomerVault: false,
            VTMaster: false,
            CardTerminal: false,
            Dashboard_Master: false,
            TabletPOSMaster: false,
            MyTransactions_Master: false,
            APIMaster: false
        };

        var viewAllTransactionPermission = {
            MyTransactions_AllTransactions: false
        };


        var transactionPermissions = {
            Sale: false,
            Auth: false,
            Refund: false,
            Capture: false
        };

        angular.forEach(permissions, function(item) {
            var set = true;
            switch (item) {
                case 'AccountAccess':
                    accountPermission.AccountAccess = true;
                    break;
                case 'MobileMaster':
                    accessPermissions.MobileMaster = true;
                    break;
                case 'CustomerVault':
                    accessPermissions.CustomerVault = true;
                    break;
                case 'VTMaster':
                    accessPermissions.VTMaster = true;
                    break;
                case 'CardTerminal':
                    accessPermissions.CardTerminal = true;
                    break;
                case 'Dashboard_Master':
                    accessPermissions.Dashboard_Master = true;
                    break;
                case 'TabletPOSMaster':
                    accessPermissions.TabletPOSMaster = true;
                    break;
                case 'MyTransactions_Master':
                    accessPermissions.MyTransactions_Master = true;
                    break;
                case 'InvoiceMaster':
                    accessPermissions.InvoiceMaster = true;
                    break;
                case 'APIMaster':
                    accessPermissions.APIMaster = true;
                    break;
                case 'MyTransactions_AllTransactions':
                    viewAllTransactionPermission.MyTransactions_AllTransactions = true;
                    break;
                case 'Recurring_ProcessSale':
                case 'VT_ProcessNakedSale':
                case 'Invoice_ProcessSale':
                case 'Recurring_ProcessRefund':
                case 'VT_ProcessNakedRefund':
                case 'Invoice_ProcessAuth':
                case 'Recurring_ProcessAuth':
                case 'VT_ProcessNakedAuth':
                case 'Recurring_ProcessCapture':
                case 'VT_ProcessNakedCapture':
                case 'Invoice_ProcessRefund':
                    break;
                default:
                    set = false;
            }

            if (!set) {
                if (item.indexOf('Sale') > -1) {
                    transactionPermissions.Sale = true;
                } else if (item.indexOf('Auth') > -1) {
                    transactionPermissions.Auth = true;
                } else if (item.indexOf('Refund') > -1) {
                    transactionPermissions.Refund = true;
                } else if (item.indexOf('Capture') > -1) {
                    transactionPermissions.Capture = true;
                }
            }

        });
        return {
            accessPermissions: accessPermissions,

            transactionPermissions: transactionPermissions,

            accountPermission: accountPermission,

            viewAllTransactionPermission: viewAllTransactionPermission
        };
    };

    return {
        checkUserPermission: function(permissionList, permission){
            return _.includes(permissionList, permission);
        },
        
        getUserPermissions: function() {
            return SessionStorageService.get().getUser().permissions;
        },

        getStaffPermissions: function(userId) {
            var userPermRequest = Restangular.all('users');
            return userPermRequest.get(userId + '/effective_user_permissions')
                .then(
                    function(response) {
                        return loadStaffPermissions(parsePermissions(response.data));
                    },
                    function() {
                        return undefined;
                    }
                );
        },

        updatePermission: function(permission) {
            var userId = permission.user_id;
            var permissionRequest = Restangular.one('users', userId);
            return permissionRequest.post('user_permissions', permission).then(
                function(response) {
                    return response;
                },
                function(error) {
                    $log.error(error);
                    return error;
                }
            );
        }
    };
});