import angular from 'angular';

function paperButton() {
}

angular
.module('payhqUIApp')
.component('paperButton', {
    bindings: {
        label: '@',
        type: '@',
        value : '@',
        name : '@',
        tabindex: '@',
        heapIdentifier : '@'
    },
    controller: paperButton,
    controllerAs: 'paperButton',
    templateUrl: 'component/paper-button/paper-button.html'
});