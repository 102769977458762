import angular from 'angular';
import FormatterLocator from 'service/formatters/formatter-locator';
import CONSTANT_APP from 'constant/constant-app';
import CONSTANT_COMPONENT from 'constant/constant-component';
import CONSTANT_HEAP from 'constant/constant-heap';
import SessionStorageService from 'service/session-storage-service';
import _ from 'lodash';
import CREDIT_CARD_FIELD_TYPE from 'filter/credit-card-field-type';
import TC_BANNERS from './tc-banners';
import USER_GROUP from 'constant/user-group';

const APP_MODULE = CONSTANT_APP.module;
const COMPONENT_TERMINAL = CONSTANT_COMPONENT.terminal;

function terminal(
    SessionService,
    TransactionService,
    PaperToastService,
    $location, $filter, $route, $scope) {

    const HEAP_TERMINAL = CONSTANT_HEAP.TERMINAL;

    let _this = this;
    _this.view = {};
    _this.view[_this.transactionType] = true;
    _this.hasTransaction = false;

    _this.HEAP_EVENT_NAME = HEAP_TERMINAL;

    function getTCBannerData(){
        let tcBanners = TC_BANNERS;
        if (tcBanners.length > 0) {
            _this.BANNER = tcBanners[0];
        }
    }

    function redirectToSaleIfWrongTransactionType(){
        if (_this.transactionType !== window.PAYFIRMA.TRANSACTION_TYPE.SALE &&
            _this.transactionType !== window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE &&
            _this.transactionType !== window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE &&
            _this.transactionType !== window.PAYFIRMA.TRANSACTION_TYPE.REFUND){
            $location.path('terminal/sale');
        }
    }

    //Loading exsisitng cusotmer data from vault
    function loadExistingCustomerData() {
        if (_this.settings && _this.settings.customer){
            let customer = _this.settings.customer;
            _this.data.customer_lookup_id = customer.lookup_id;
            _this.data.email = customer.email;
            _this.data.company = customer.getCompany();
            _this.data.address1 = customer.getAddress1();
            _this.data.address2 = customer.getAddress2();
            _this.data.city = customer.getCity();
            _this.data.province = customer.getProvince();
            _this.data.postalcode = customer.getPostalCode();
            _this.data.country = customer.getCountry();
            _this.data.telephone = customer.getTelephone();
            _this.data.firstname = customer.getFirstName();
            _this.data.lastname = customer.getLastName();
        }
    }

    //Load exsisting card data from vault
    function loadExistingCard() {
        let customer;
        let creditCard;
        let creditCardMask;

        if (_this.settings){
            customer = _this.settings.customer;
            creditCard = _this.settings.creditCard;
        }

        if (creditCard && customer) {
            _this.data.customer = customer;
            _this.data.creditCard = creditCard;

            creditCardMask = $filter('creditCardMask');
            _this.data.card_number_formatted = creditCardMask(creditCard.card_suffix,
                CREDIT_CARD_FIELD_TYPE.CARD_NUMBER);
            _this.data.card_date = creditCard.card_expiry;
            _this.data.card_cvv = creditCardMask('',
                CREDIT_CARD_FIELD_TYPE.CVV);
            _this.data.card_prefix = creditCard.card_prefix;
            _this.data.card_suffix = creditCard.card_suffix;
            _this.data.card_type = FormatterLocator.formatCardType(creditCard.card_type);
            //selects the card to use by index number, null values will  choose default card
            _this.data.use_customer_payment_data = true;
        }
    }

    //reset the views data model
    function clearTransactionData(){
        _this.data = {
            address1: null,
            address2: null,
            amount: 0.00,
            amount_tax: null,
            amount_tip: null,
            bcc_email: null,
            card_date: null,
            card_cvv : null,
            card_suffix: null,
            card_type: null,
            card_number: null,
            card_expiry_month: null,
            card_expiry_year: null,
            city: null,
            company: null,
            country: null,
            currency: null,
            customer_lookup_id: null,
            customerList: [],
            description: null,
            email: null,
            emailList: [],
            firstname: null,
            invoice_id: null,
            is_recurring: null,
            lastname: null,
            lookup_id: null, // card lookup id
            order_id: null,
            original_id: null,
            postalcode: null,
            postal_code: null, // Temporary fix
            province: null,
            recurring_plan_id: null,
            send_receipt: null,
            telephone: null,
            transaction_type: null,
            transactionType: null
        };
    }
    _this.isLoading = false;
    _this.showOrderDetails = false;
    _this.orderDetailsText = 'More Order Details';
    _this.data = {};

    //clear form on load
    clearTransactionData();

    _this.canViewBanner = function(){
        let currentUser = SessionStorageService.get().getUser();

        // CIM-1954 - Revert all changes done in this ticket when done with MFA Banner
        // if (currentUser.business_information.show_marketing_banner===false) {
        //     return false;
        // }

        let userGroup = currentUser.user_group;

        if ((userGroup === USER_GROUP.PAYFIRMA_ADMIN || userGroup === USER_GROUP.ADMIN) && !currentUser.telephone) {
            // https://jira.payfirma.com/jira/browse/CRU-3649
            // not showing the banner to everyone
            // but leave code as it is in case we need to have anothe marketing banner in the future
            return true;
        }

        return false;
    };

    _this.showLargeBanner = function() {
        return _this.canViewBanner() && !_this.showSmallerMarketingBanner();
    };

    _this.showSmallBanner = function() {
        return false;

        // return _this.canViewBanner() && _this.showSmallerMarketingBanner();
    };

    _this.showSmallerMarketingBanner = function() {

        // CIM-1954 - Revert all changes done in this ticket when done with MFA Banner
        // let isMarketingBannerSmall = window.localStorage.getItem('isMarketingBannerSmall');

        // if (isMarketingBannerSmall === 'true'){
        //     return true;
        // }

        if (window.sessionStorage.getItem('hideTemporaryMFABanner')) {
            return true;
        }
        
        return false;
    };

    _this.makeMarketingBannerSmall = function(){
        window.sessionStorage.setItem('hideTemporaryMFABanner', true);
        
        // CIM-1954 - Revert all changes done in this ticket when done with MFA Banner
        // window.localStorage.setItem('isMarketingBannerSmall','true');
    };

    _this.canRefund = function() {
        return SessionService.checkUserPermission('VT_ProcessRefund');
    };

    _this.canSale = function() {
        return SessionService.checkUserPermission('VT_ProcessSale');
    };

    _this.canCapture = function() {
        return SessionService.checkUserPermission('VT_ProcessCapture');
    };

    _this.canAuthorize = function() {
        return SessionService.checkUserPermission('VT_ProcessAuth');
    };

    _this.$onInit = function(){
        var isOriginalTransactionPurchase;
        var isOriginalTransactionCapture;
        var isOriginalTransactionAuthorize;

        getTCBannerData();

        redirectToSaleIfWrongTransactionType();
        _this.merchantHasOpenRefund();
        loadExistingCustomerData();
        loadExistingCard();
        if (_this.settings && _this.settings.originalTransaction){
            isOriginalTransactionPurchase = _this.settings.originalTransaction &&
            _this.settings.originalTransaction.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.SALE;
            isOriginalTransactionCapture = _this.settings.originalTransaction &&
            _this.settings.originalTransaction.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE;
            isOriginalTransactionAuthorize = _this.settings.originalTransaction &&
            _this.settings.originalTransaction.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE;

            if (isOriginalTransactionPurchase || isOriginalTransactionCapture || isOriginalTransactionAuthorize){
                _this.hasTransaction = true;
                _this.data.id = _this.settings.originalTransaction.id;

            } else if (!_this.canSale() && _this.canRefund()){
                //@author: carl, default to refund if no sale access, hope this does not break other parts in terminal
            }
        }
    };

    _this.merchantHasOpenRefund = function(){
        _this.hasOpenRefund = !!_.find(SessionStorageService.get().getUser().permissions, function (value) {
            return value === 'VT_ProcessNakedRefund';
        });
    };

    //Switch view
    _this.switchView = function(view){
        if (_this.view[view] === true) {
            $route.reload();
        }
        if (view === window.PAYFIRMA.TRANSACTION_TYPE.SALE ||
            view === window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE ||
            view === window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE ||
            view === window.PAYFIRMA.TRANSACTION_TYPE.REFUND ||
            view === window.PAYFIRMA.TRANSACTION_TYPE.NAKED_REFUND){
            $location.url('terminal/' + view);
        } else {
            $location.url('terminal/sale');
        }
    };

    //open refund request, allow unlimited refund amounts with no initial transaction id
    _this.newOpenRefund = function(){
        _this.gotOpenRefund = true;
    };

    //get a transaction from forms transaction id and populate data model
    _this.getTransaction = function(form) {
        var transactionId = _this.data.id;
        var transactionAction = _this.transactionAction;

        if (form.$valid){
            return TransactionService.getTransaction(transactionId)
                .then(function (response) {
                    if (transactionAction === window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE) {
                        if (response.transaction_source !== 'VT'){
                            PaperToastService.create('Transaction must be approved Web Terminal authorization', 'error');
                        } else if (response.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE &&
                            response.transaction_result === 'APPROVED' && !response.captured) {
                            _this.hasTransaction = true;
                        } else if (response.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE &&
                            response.transaction_result === 'APPROVED' && response.captured) {
                            PaperToastService.create('Transaction has been already captured', 'error');
                        } else {
                            PaperToastService.create('Transaction must be approved Web Terminal authorization', 'error');
                        }
                    } else if (transactionAction === window.PAYFIRMA.TRANSACTION_TYPE.REFUND) {
                        if (response.transaction_source !== 'CARD_TERMINAL') {
                            if (response.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.SALE ||
                                response.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE) {
                                _this.hasTransaction = true;
                            } else {
                                PaperToastService.create('Transaction must be approved with Credit Card', 'error');
                            }
                        } else {
                            PaperToastService.create('Transaction must be approved Web Terminal sale', 'error');
                        }
                    } else if (transactionAction === 'open') {
                        _this.hasTransaction = true;
                    }

                    if (response.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.SALE ||
                        response.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.CAPTURE) {
                        _this.type = window.PAYFIRMA.TRANSACTION_TYPE.REFUND;
                        _this.isRefund = true;
                    }

                    window.PAYFIRMA.CommunicationService.triggerOriginalTransactionPopulatedEvent(response);

                });
        } else {
            PaperToastService.create('Invalid transaction ID', 'error');
        }
    };

    $scope.$on('$destroy', function () {
        window.PAYFIRMA.CommunicationService.disableOriginalTransactionPopulatedEventTrigger();
    });
}
angular
.module(APP_MODULE)
.component(COMPONENT_TERMINAL, {
    bindings: {
        settings: '=',
        customer: '<',
        creditCard: '<',
        transactionType: '='
    },
    controller: terminal,
    controllerAs: 'terminal',
    templateUrl: 'view/terminal/terminal.html'
});
