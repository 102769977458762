import angular from 'angular';
import moment from 'moment';

function invoicing(UrlService, InvoiceService, PaperToastService, $window, $location, $timeout) {
    let _this = this;
    _this.isPaginationLoading = false;
    _this.isLoadingData = true;
    _this.sendEmailTo = '';
    _this.currentInvoice = {};
    _this.data = {};
    _this.exportButtonClicked = false;
    let paginationService = new window.PAYFIRMA.PaginationService(_this.invoices);
    _this.invoices = paginationService.getAllData() || [];


    function hasMoreData() {
        return paginationService.hasMoreData()
            && paginationService.nextCursor() < _this.filteredInvoices.length;
    }

    function updateAfterGetInvoices(dataFromServer) {
        $timeout(function () {
            paginationService.updateAllData(dataFromServer);
            _this.invoices = paginationService.getAllData();
            _this.isLoadingData = false;
        });
    }

    function getInvoices() {
        _this.isLoadingData = true;
        window.PAYFIRMA.InvoiceDataService.getAllData({
            forceFetch: true,
            searchParameters: {}
        })
            .then(function (data) {
                updateAfterGetInvoices(data);
            });
    }

    _this.$onInit = () => {
        getInvoices();
    };

    _this.getPaginationNext = function () {
        paginationService.getNext();
        _this.isPaginationLoading = false;
    };

    _this.lazyLoad = () => {
        //check if next page value and grab transactions
        if (hasMoreData()) {
            _this.isPaginationLoading = true;
            //prevent duplicate api calls when scrolling with 1s timeout
            _this.scrollTimeout = setTimeout(() => {
                _this.getPaginationNext();
            }, 0);
        }
    };

    //Create new invoice
    _this.createInvoice = () => {
        UrlService.go('/invoicing/create');
    };

    //Clone invoice
    _this.cloneInvoice = (invoiceObject) => {
        var url = '/clone-invoice/' + invoiceObject.invoice_id;
        UrlService.go(url);
    };

    //Edit invoice draft
    _this.editDraft = (invoiceObject) => {
        var url = '/draft-invoice/' + invoiceObject.invoice_id;
        UrlService.go(url);
    };

    //Convert times stamp to human
    _this.timeStampToHuman = function(date) {
        var formattedDate = date / 1000;
        formattedDate = moment.unix(formattedDate).format('MMM DD YYYY');
        return formattedDate;
    };

    // Shortens Currency label
    _this.shortenCurrencyLabel = function(currency) {
        var labelString = '';
        if (currency === 'CAD') {
            labelString = 'CA';
        }
        if (currency === 'USD') {
            labelString = 'US';
        }
        return labelString;
    };

    //Is email valid?
    function validateEmail(email) {
        var regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    }

    //Send invoice via email
    _this.sendInvoice = function(invoiceObject, email){
        var invoiceId = invoiceObject.invoice_id;
        if (validateEmail(email)){
            InvoiceService.sendInvoiceEmail(invoiceId, email);
            invoiceObject.showResendInvoice = false;
            invoiceObject.showViewInvoice = false;
            PaperToastService.create('Invoice sent', 'update');
        } else {
            PaperToastService.create('Please enter a valid email', 'error');
        }
    };

    //Change invoice status
    function changeInvoiceStatus(invoiceId, status){
        return InvoiceService.changeInvoiceStatus(invoiceId,status).then(
            function(response) {
                var isValidResponse = false;
                if (response.status === 200){
                    isValidResponse = true;
                }
                return isValidResponse;
            }
        );
    }
    //close invoice by id
    _this.closeInvoice = function(invoiceObject){
        changeInvoiceStatus(invoiceObject.invoice_id, 'closed').then(
            function(response) {
                if (response){
                    PaperToastService.create('Invoice closed', 'update');
                    invoiceObject.showCloseInvoice = false;
                    invoiceObject.status = 'Closed';
                } else {
                    PaperToastService.create('Could not close invoice', 'error');
                }
            }
        );
    };

    //Setup resetnd email
    _this.setupResendEmail = function(invoiceObject){
        invoiceObject.showResendInvoice = true;
        _this.sendEmailTo = invoiceObject.recipient_email;
    };

    //view invoice modal
    _this.viewInvoice = function(invoiceObject){
        if (invoiceObject && invoiceObject.invoice_id){
            _this.openNewTab($location.absUrl() + '/view-invoice/' + invoiceObject.invoice_id);
        }
    };

    // open new tab
    _this.openNewTab = function(url){
        $window.open(url, '_blank');
    };

    //export invoice
    _this.exportInvoices = function (statusFilter) {
        _this.exportButtonClicked = true;

        let parameters = {};
        if (statusFilter.status) {
            parameters.status = _.join(statusFilter.status);
        }

        return InvoiceService.exportInvoiceListCSV(parameters)
            .then(function(){
                _this.exportButtonClicked = false;
            })
            .catch(function (error) {
                _this.exportButtonClicked = false;
                let errorMessage = 'Could not export File';
                if (error && error.message) {
                    errorMessage = error.message;
                }
                PaperToastService.create(errorMessage, 'error');
                throw error;
            });
    };

    //view pay invoice modal
    _this.viewPayInvoice = function(invoiceObject) {
        _this.currentInvoice = invoiceObject;
        //_this.data sets up variables for paper-form-terminal.js
        _this.data.currentInvoice = invoiceObject;
        _this.data.disableAmountEntry = true;
        _this.data.payInvoice = true;
        _this.data.emailList = [invoiceObject.recipient_email];
        _this.data.amount = invoiceObject.total;
        if (invoiceObject.currency === 'USD'){
            _this.data.currencySelected = 'US';
        } else {
            _this.data.currencySelected = 'CA';
        }
        invoiceObject.showPayInvoice = true;
    };

    _this.isPayInvoiceAllowed = function (invoiceStatus) {
        return InvoiceService.isPayInvoiceAllowed(invoiceStatus);
    };

    //get a specific invoice url
    _this.getInvoiceUrl = (invoiceObject) => {
        invoiceObject.showInvoiceUrl = true;
        _this.isLoadingUrl = true;
        InvoiceService.getInvoiceUrl(invoiceObject.invoice_id)
            .then( response => {
                _this.isLoadingUrl = false;
                if (response.status === 200){
                    _this.invoiceUrl = response.data.invoice_url;
                    _this.isSuccessToGetUrl = true;
                } else {
                    _this.isSuccessToGetUrl = false;
                }

            });
    };

    //copy the invoice url to the clipboard
    _this.copyUrlToClip = (url, invoiceObject) => {
        if (window.clipboardData && window.clipboardData.setData) {
            invoiceObject.showInvoiceUrl = false;
            // IE specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData('Text',url);
        } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            let textarea = document.createElement('textarea');
            textarea.textContent = url;
            textarea.style.position = 'fixed';
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy');
                PaperToastService.create('The URL has been copied.', 'update');
                invoiceObject.showInvoiceUrl = false;
            } catch (ex) {
                PaperToastService.create('Failed to copy' ,'error');
            } finally {
                document.body.removeChild(textarea);
            }
        }
    };

}
angular
.module('payhqUIApp')
.component('invoicing', {
    bindings: {
        invoices: '=',
        settings: '='
    },
    controller: invoicing,
    controllerAs: 'invoicing',
    templateUrl: 'view/invoicing/invoicing.html'
});


