import angular from 'angular';

function transactionConfirmation($scope, Utils) {
    var _this;
    _this = this;

    window.PAYFIRMA.CommunicationService.onOpenTransactionConfirmEvent(function () {
        Utils.forceScopeApply($scope);
    });
    _this.close = function () {
        window.PAYFIRMA.CommunicationService.triggerTransactionCompleleteEvent();
    };
    _this.cancel = function () {
        window.PAYFIRMA.CommunicationService.triggerCancelTransactionConfirmEvent();
    };
    _this.retry = function () {
        window.PAYFIRMA.CommunicationService.triggerRetryTransactionEvent();
    };
}

angular
.module('payhqUIApp')
.component('transactionConfirmation', {
    bindings: {
        saleResponse: '<'
    },
    controller: transactionConfirmation,
    controllerAs: 'transactionConfirmation',
    templateUrl: 'view/vault/modal/transaction-confirmation.html'
});