import angular from 'angular';

function errorInvoiceModal(SessionService,Utils) {
    var self = this;

    self.hasSettingsPermission = true;
    self.hasSettingsPermission = SessionService.checkUserPermission('ReceiptSettings');

    function closeAndCleanup() {
        self.componentEnabled = false;
    }
    self.cancel = function () {
        closeAndCleanup();
    };
    self.goTooSettings = function(){
        Utils.go('/settings');
        closeAndCleanup();
    };
    self.back = function(){
        Utils.go('/invoicing');
        closeAndCleanup();
    };

}

angular
.module('payhqUIApp')
.component('errorInvoiceModal', {
    bindings: {
        componentEnabled: '=',
        customer: '<'
    },
    controller: errorInvoiceModal,
    controllerAs: 'errorInvoiceModal',
    templateUrl: 'view/invoicing/edit/modal/error-invoice-modal.html'
});