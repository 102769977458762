/* @flow */

import angular from 'angular';
import 'script/app-init';
import 'service/pagination-service';
import 'filter/pagination-filter';
import 'filter/recurring-plan-status-filter';
import 'filter/order-by-empty-at-bottom';
import 'component/paper-search-box/paper-search-box';
import 'component/paper-filter/paper-customervault-filter/paper-customervault-filter';
import 'component/paper-loader/paper-loader';
import 'service/utils';
import 'view/vault/customer-details';
import SessionStorageService from 'service/session-storage-service';

function customerVault(Utils, $timeout, $scope, PaperToastService, $translate, CustomerService, $route, CustomerFactory) {

    var _this;

    _this = this;

    var paginationService = new window.PAYFIRMA.PaginationService(_this.customerList);

    _this.paginationService = paginationService;
    _this.isPaginationLoading = false;
    _this.isLoadingData = true;
    _this.fieldListOverride = [{ name: 'First Name', key: 'first_name' },
     { name: 'Last Name', key: 'last_name' },
     { name: 'Email', key: 'email_address' },
     { name: 'Customer ID', key: 'custom_id' },
     { name: 'Company', key: 'company' }];
    _this.customerListForDisplay = paginationService.getAllData() || [];

    window.PAYFIRMA.CustomerDataService.getAllData({
        forceFetch : true
    })
    //always reload from server when enters customer vault
        .then(function (data) {
            $timeout(function () {
                paginationService.updateAllData(data);
                _this.customerListForDisplay = paginationService.getAllData();
                _this.isLoadingData = false;
            });
        });

    _this.sortCustomersBy = function (columnNameSelected, $event) {
        _this.customerSortKey = Utils.updateSortKey(_this.customerSortKey, columnNameSelected);
        if ($event) {
            _this.modifySortColumnClass($event);
        }
    };

    _this.modifySortColumnClass = function ($event) {

        var targetElement = $($event.target);
        var sortableColumn = $('[sort]');
        //set tabel sort function and display arrow class
        if (targetElement.hasClass('ascending')) {
            _this.orderListBy = '-' + window.parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort').addClass('decending');
        } else if (targetElement.hasClass('decending')) {
            _this.orderListBy = window.parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort').addClass('ascending');
        } else if (targetElement.hasClass('no-sort')) {
            _this.orderListBy = window.parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort');
            targetElement.addClass('ascending');
        }
    };

    _this.editCustomer = function (customerLookupId) {
        Utils.go('/customer-vault/edit/' + customerLookupId);
    };

    _this.sortCustomersBy('first_name');

    _this.export = function () {
        _this.exportButtonClicked = true;
        return CustomerService.exportCustomers(_this.filtercustomervault, _this.filterplanstatus)
            .then(function(){
                _this.exportButtonClicked = false;
            })
            .catch(function (error) {
                _this.exportButtonClicked = false;
                let errorMessage = 'Could not export File';
                if (error && error.message) {
                    errorMessage = error.message;
                }
                PaperToastService.create(errorMessage, 'error');
                throw error;
            });
    };

    _this.addCustomer = function () {
        Utils.go('/customer-vault/create');
    };

    const FILE_UPLOAD_TYPE = 'csv';
    _this.JSONData = '';

    _this.addCustomerInBulk = function (files) {
        _this.csvJSON(files.data.dataUrl);

        if (_this.JSONData !== '') {
            return CustomerService.createNewCustomerInBulk(_this.JSONData).then(function (data) {
                if (data === 'Success') {
                    window.PAYFIRMA.CommunicationService.triggerConfirmBulkCustomerUpload();
                }
                if (data ==='PartialSuccess') {
                    window.PAYFIRMA.CommunicationService.triggerPartialBulkCustomerUpload();
                }
            })
            .catch((error) => {
                PaperToastService.create(error.message, 'error');
                _this.cancel();
            });
        }
    };

    _this.onfileloaded = (imageObject) => {
        _this.addCustomerInBulk(imageObject);
    };

    _this.loadImage = () => {
        window.PAYFIRMA.CommunicationService.triggerFileUploadEvent(FILE_UPLOAD_TYPE);
    };

    _this.downloadSampleCSV = () => {
        window.open('https://payhq-csvupload.s3.amazonaws.com/Sample-Customers-Information.csv', '_blank');
    };

    _this.displayOnlyToAdmin = () => {
        let currentUser = SessionStorageService.get().getUser();
        if (currentUser.isAdmin() || currentUser.isPayfirmaAdmin()) {
            return true;
        }
        return false;
    };

    _this.csvJSON = function(csvText) {

        var lines = csvText.split('\n');

        if (lines.length >= 501){
            $translate('maxRecordsForBulkUploadError').then((translation) => {
                PaperToastService.create(translation, 'error');
            });
            return;
        }

        let result = [];
        let headers = lines[0].split(',');

        for (let i = 1; i < lines.length; i++) {
            let obj = {};
            let currentline = lines[i].split(',');
     
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }
            result.push(obj);
        }
        _this.JSONData = JSON.stringify(result);
    };

    window.PAYFIRMA.CommunicationService.onConfirmBulkCustomerUpload(function () {
        $translate('uploadBulkCustomerSuccess').then((translation) => {
            $route.reload();
            PaperToastService.create(translation, 'update');
        });
    });

    window.PAYFIRMA.CommunicationService.onConfirmPartialBulkCustomerUpload(function () {
        $translate('uploadPartialBulkCustomerSuccess').then((translation) => {
            $route.reload();
            PaperToastService.create(translation, 'error');
        });
    });

    _this.viewAllCustomerTransactions = function () {
        Utils.go(decodeURIComponent('/transactions/customer/email/' + _this.customerEmail));
    };

    function hasMoreData() {
        return paginationService.hasMoreData()
            && paginationService.nextCursor() < _this.filteredCustomerList.length;
    }

    _this.getPaginationNext = function () {
        paginationService.getNext();
        _this.isPaginationLoading = false;
    };

    _this.resetAdvancedSearch = function() {
        _this.advancedSearchActive = false;
        _this.customerListForDisplay = paginationService.getAllData();
    };

    window.PAYFIRMA.CommunicationService.onConfirmDeleteCustomer(function () {
        $translate('deleteCustomerSuccess').then((translation) => {
            PaperToastService.create(translation, 'update');
        });
    });

    /**
     * Infinite scrolling, firing once user hits bottom of the page.
     */
    _this.lazyLoad = function () {
        //check if next page value and grab transactions
        if (hasMoreData()) {
            _this.isPaginationLoading = true;
            //prevent duplicate api calls when scrolling with 1s timeout
            _this.scrollTimeout = setTimeout(function () {
                _this.getPaginationNext();
            }, 0);
        }
    };
    
    $scope.$watch('advancedsearch', (newValue) => {
        if (_.isEmpty(newValue)) {
            _this.resetAdvancedSearch();
            return;
        }

        if (_this.isLoadingData) {
            PaperToastService.create('Please wait for the page to load.', 'error');
            return;
        }

        _this.advancedSearchActive = true;
        _this.isLoadingData = true;

        let filter = {};
        _this.advancedSearchParams = newValue;

        newValue.fields.forEach((element, index) => {
            filter[element] = newValue.keywords[index];
        });

        CustomerService.getCustomersForAdvancedSearch(filter)
            .then(res => {
                let customerList = CustomerFactory.parseSimpleCustomerList(res);
                let advancedSearchPaginationService = new window.PAYFIRMA.PaginationService(customerList);
                _this.customerListForDisplay = advancedSearchPaginationService.getAllData();
                _this.isLoadingData = false;
            })
            .catch(err => {
                _this.isLoadingData = false;
                console.error(err);
            });
    });

    $scope.$on('$destroy', function () {
        if (paginationService) {
            paginationService.unload();
        }
    });
}

angular
.module('payhqUIApp')
.component('customerVault', {
    bindings: {
        customerList: '<',
        recurringCustomers: '<',
        planId: '<'
    },
    controller: customerVault,
    controllerAs: 'customerVault',
    templateUrl: 'view/vault/vault.html'
});