import angular from 'angular';

function paperMerchanttypeFilter($element) {

    var _this;
    _this = this;

    _this.isDropped = false;

    _this.doDrop = function() {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function() {
        if (_this.isDropped === true) {
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });
    $element.on('click', function (e) {
        if (_this.isDropped === true) {
            $('dropdown').show();
        }
        e.stopPropagation();
    });

    var defaultTitle = window.PAYFIRMA.TEXT_STRINGS.TYPE;
    var defaultSelected = ['Basic', 'Pro', 'Demo'];
    _this.title = defaultTitle + ' (' + defaultSelected.length + ')';

    _this.selectedMerchantType = defaultSelected;
    _this.merchantTypeList = [
        { key: 'Demo', name: window.PAYFIRMA.TEXT_STRINGS.DEMO, merchantTypeSelected: true },
        { key: 'Basic', name: window.PAYFIRMA.TEXT_STRINGS.BASIC, merchantTypeSelected: true },
        { key: 'Pro', name: window.PAYFIRMA.TEXT_STRINGS.PRO, merchantTypeSelected: true },
        { key: 'Internal', name: window.PAYFIRMA.TEXT_STRINGS.INTERNAL, merchantTypeSelected: false }
    ];

    _this.setSelectedMerchantType = function(merchantType) {
        var key = merchantType.key;

        if (merchantType.merchantTypeSelected) {
            _this.selectedMerchantType.push(key);
            merchantType.merchantTypeSelected = true;
        } else if (_.includes(_this.selectedMerchantType, key)) {
            _this.selectedMerchantType = _.without(_this.selectedMerchantType, key);
            merchantType.merchantTypeSelected = false;
        }

        if (_this.selectedMerchantType.length === 0) {
            _this.title = defaultTitle;
            _this.filtermerchanttype = { type: defaultSelected };

        } else {
            _this.title = defaultTitle + ' (' + _this.selectedMerchantType.length + ')';
            _this.filtermerchanttype = { type: _this.selectedMerchantType };
        }
    };

    function initFilter() {
        _this.filtermerchanttype = { type: _this.selectedMerchantType };
    }

    initFilter();
}


angular
.module('payhqUIApp')
.component('paperMerchanttypeFilter', {
    bindings: {
        filtermerchanttype: '='
    },
    controller: paperMerchanttypeFilter,
    controllerAs: 'paperMerchanttypeFilter',
    templateUrl: 'component/paper-filter/paper-merchanttype-filter/paper-merchanttype-filter.html'
});