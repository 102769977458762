import _ from 'lodash';

let MerchantModel = function (data) {
    let _this = this;
    _.extend(_this, data);

    function getGatewayByCurrency(currency) {
        var resultGateway = _.find(_this.gateways, function (gateway) {
            return !gateway.is_card_terminal && gateway.currency === currency;
        });

        var defaultGateway = {
            currency: currency, // Need default currency
            is_card_terminal: false,
            is_eft: false
        };

        if (resultGateway) {
            resultGateway.is_card_terminal = false;
            resultGateway.is_eft = false;
        }
        return resultGateway || defaultGateway;
    }

    _this.getEFTGateways = function () {
        const eftGateways = _.filter(_this.gateways, function (gateway) {
            return gateway.is_eft;
        });
          // Push each corresponding canadian and US terminal as pairs into result array
        var filteredEFTGateways = [];

        for (var i = 0; i < eftGateways.length; i++) {
              // Search for exact match in result array
            var result = _.find(filteredEFTGateways, {
                processor: eftGateways[i].processor,
                account_id: eftGateways[i].account_id,
                secret_key: eftGateways[i].secret_key,
                user_id: eftGateways[i].user_id,
                terminal_id: eftGateways[i].terminal_id,
                is_card_terminal:false
            });
            if (!result) {
                filteredEFTGateways.push(eftGateways[i]);
            }
        }

        return filteredEFTGateways;
    };

    _this.getTerminals = function() {
        // Filter out card terminals
        const cardTerminals = _.filter(_this.gateways, function (gateway) {
            return gateway.is_card_terminal;
        });

        // Push each corresponding canadian and US terminal as pairs into result array
        var filteredTerminals = [];
        for (var i = 0; i < cardTerminals.length; i++) {
            // Search for exact match in result array
            var result = _.find(filteredTerminals, {
                processor: cardTerminals[i].processor,
                account_id: cardTerminals[i].account_id,
                secret_key: cardTerminals[i].secret_key,
                user_id: cardTerminals[i].user_id,
                terminal_id: cardTerminals[i].terminal_id,
                is_eft:false
            });

            if (!result) {
                filteredTerminals.push(cardTerminals[i]);
            } else if ((cardTerminals[i].currency === 'USD' && result.currency === 'CAD') ||
                (cardTerminals[i].currency === 'CAD' && result.currency === 'USD')) {
                result.currency = 'BOTH';
            }
        }

        return filteredTerminals;
    };

    _this.timezone = _this.timezone || 'PST';
    _this.hardwares = _this.hardwares || [];
    _this.mcc_code = _this.mcc_code || '';

    _this.avs_enabled = _this.avs_enabled || false;

    _this.mfa_enabled = _.isBoolean(_this.mfa_enabled) ? _this.mfa_enabled : true;

    _this.product_permissions = _this.product_permissions ||
        {
            customer_vault: true,
            ecommerce: true,
            mobile_app: true,
            recurring_billing: true,
            tablet_pos: true,
            web_terminal: true,
            card_terminal: true,
            invoice: true,
            pin_pad: true,
            open_refund: false,
            eft: false,
            hpp_link: false
        };

    _this.getCanadianGateway = function () {
        return getGatewayByCurrency('CAD');
    };

    _this.getUSGateway = function () {
        return getGatewayByCurrency('USD');
    };

    _this.isGatewayInactive = function (gateway) {
        return !gateway || gateway.processor === 'INACTIVE';
    };

    _this.isNew = function () {
        return !_this.id;
    };

    if (!_this.isNew() && !_this.mcc_code) {
        _this.mcc_code = 0;
    }

    return _this;
};
export default MerchantModel;