/* @flow */

import angular from 'angular';
import moment from 'moment-timezone';
import SessionStorageService from 'service/session-storage-service';

/**
 * @ngdoc service
 * @name payhqUIApp.customerApp.service:SettingsService
 * @requires Restangular
 * @description
 *
 * Service for handling customer behaviours
 *
 */
angular
.module('settingsApp')
.service('TimeService', function TimeService
    (SettingsService, DateFormat, TimeFormat, TIMEZONE_OFFSET) {

    let timezoneOffsetList = TIMEZONE_OFFSET;

    function getTimeZone(){
        let timezone = SessionStorageService.get().getTimezone();
        if (!timezone){
            timezone = timezoneOffsetList[9].value;
        }
        return timezone;
    }

    function findTimeZoneString(zoneCode){
        var timezoneCode;
        if (zoneCode){
            timezoneCode = zoneCode;
        } else {
            timezoneCode = getTimeZone();
        }
        return _.find(timezoneOffsetList, function (offset) {
            return offset.value === timezoneCode;
        });
    }

    function momentDate(epochTime){
        return moment(epochTime).tz(findTimeZoneString().zone).format(DateFormat.DISPLAY_FORMAT);
    }
    function momentTime(epochTime){
        return moment(epochTime).tz(findTimeZoneString().zone).format(TimeFormat.DISPLAY_FORMAT);
    }

    return {
        getTimezone: function () {
            return getTimeZone();
        },

        getLocalTime: function (zoneCode) {
            let timeZoneStringByZoneCode = findTimeZoneString(zoneCode);
            if (timeZoneStringByZoneCode) {
                return moment()
                .tz(timeZoneStringByZoneCode.zone)
                .format(window.PAYFIRMA.CONSTANT.STANDARD_TIME_FORMAT);
            }
            return undefined;
        },

        epochToDateAndTime: function (epochTime) {
            var date = momentDate(epochTime);
            var time = momentTime(epochTime);
            var dateSting = date + ' @ ' + time;
            return dateSting;
        },

        epochToDate: function (epochTime) {
            var date = momentDate(epochTime);
            return date;
        },

        epochToTime: function (epochTime) {
            var time = momentTime(epochTime);
            return time;
        },

        getTimezoneList : function() {
            return timezoneOffsetList;
        },

        getTimezoneString: function() {
            return findTimeZoneString(getTimeZone()).zone;
        },

        toEndOfDayByTimezone: function(dueDate) {
            let timeZoneString = findTimeZoneString(getTimeZone()).zone;
            let selectedDate = momentDate(dueDate);
            return moment(selectedDate).tz(timeZoneString).endOf('day').unix() * 1000;
        }
    };
});
