const CONSTANT_HEAP = {
    TERMINAL: {
        TAB_SALE: 'heap-terminal-tab-sale',
        TAB_AUTHORIZE: 'heap-terminal-tab-authorize',
        TAB_CAPTURE: 'heap-terminal-tab-capture',
        TAB_REFUND: 'heap-terminal-tab-refund',
        SALE: 'heap-terminal-sale',
        AUTHORIZE: 'heap-terminal-authorize',
        CAPTURE: 'heap-terminal-capture',
        REFUND: 'heap-terminal-refund',
        OPEN_REFUND: 'heap-terminal-open-refund',
        MORE_DETAILS: 'heap-terminal-more-details',
        LESS_DETAILS: 'heap-terminal-less-details'
    },
    TRANSACTIONS: {
        MORE_DETAILS: 'heap-transactions-more-details',
        LESS_DETAILS: 'heap-transactions-less-details',
        SEND_RECEIPT: 'heap-transactions-send-receipt',
        PRINT_RECEIPT: 'heap-transactions-print-receipt',
        CAPTURE_PAYMENT: 'heap-transactions-capture-payment'
    },
    NAVIGATION: {
        LOGO: 'heap-navigation-logo',
        COMPACT: 'heap-navigation-compact',
        EXPAND: 'heap-navigation-expand'
    }
};

export default CONSTANT_HEAP;