import angular from 'angular';
import lodash from 'lodash';
import THEME_CONSTANT from 'view/settings/theme/theme-constant';

function theme(PaperToastService, Image2datauriService, AssetService, $window, $route, SessionService, $location) {

    const LOGO_IMAGE_UPLOAD_TYPE = 'logo';
    const ICON_IMAGE_UPLOAD_TYPE = 'icon';
    const LOGIN_LOGO_IMAGE_UPLOAD_TYPE = 'loginLogo';
    const BACKGROUND_IMAGE_UPLOAD_TYPE = 'background';
    const FAVICON_IMAGE_UPLOAD_TYPE = 'favicon';
    const DEFAULT_STYLE_COLOR = '#37454d';

    let _this = this;

    _this.hostname = window.location.hostname;

    _this.colorStyleOverride = true;

    _this.cachedFormData = {
        image: {},
        header: {},
        sidebar: {},
        button: {},
        loginButton: {},
        icon: {}
    };
    _this.formData = {
        asset: {
            title: 'Payfirma HQ'
        },
        image: {
            icon: '',
            loginLogo: '',
            background: '',
            logo: ''
        },
        icon: {
            color: 0,
            weight: 0
        },
        style: {
            background: DEFAULT_STYLE_COLOR
        },
        header: {
            text: 0
        },
        sidebar: {
            text: 0
        },
        button: {
            text: 0
        },
        login: {},
        loginButton: {}
    };
    _this.imagePreview = {};
    _this.logoImage = '';
    _this.styles = THEME_CONSTANT.styles;
    _this.textColors = THEME_CONSTANT.textColors;
    _this.iconColors = THEME_CONSTANT.iconColors;
    _this.iconWeights = THEME_CONSTANT.iconWeights;

    let poweredByPayfirmaWhiteUrl;
    let poweredByPayfirmaGreyUrl;
    let poweredByPayfirmaBlackUrl;
    let loginPoweredByPayfirmaWhiteUrl;
    let loginPoweredByPayfirmaGreyUrl;
    let loginPoweredByPayfirmaBlackUrl;

    function loadAllImageResources() {
        const poweredByPayfirmaImageType = 'image/png';

        //cannot use the relative url in the customized css file because
        //customized css file is read from a different domain
        //convert images to data uri first
        Image2datauriService.toDataUri('app/css/images/logo-powered-kort-white.png', poweredByPayfirmaImageType,
            function (dataUri) {
                poweredByPayfirmaWhiteUrl = dataUri;
            });

        Image2datauriService.toDataUri('app/css/images/logo-powered-kort-white.png', poweredByPayfirmaImageType,
            function (dataUri) {
                poweredByPayfirmaGreyUrl = dataUri;
            });

        Image2datauriService.toDataUri('app/css/images/logo-powered-kort-black.png', poweredByPayfirmaImageType,
            function (dataUri) {
                poweredByPayfirmaBlackUrl = dataUri;
            });

        Image2datauriService.toDataUri('app/css/images/logo-powered-kort-white.png',
            poweredByPayfirmaImageType,
            function (dataUri) {
                loginPoweredByPayfirmaWhiteUrl = dataUri;
            });

        Image2datauriService.toDataUri('app/css/images/logo-powered-kort-white.png',
            poweredByPayfirmaImageType,
            function (dataUri) {
                loginPoweredByPayfirmaGreyUrl = dataUri;
            });

        Image2datauriService.toDataUri('app/css/images/logo-powered-kort-black.png',
            poweredByPayfirmaImageType,
            function (dataUri) {
                loginPoweredByPayfirmaBlackUrl = dataUri;
            });
    }

    loadAllImageResources();

    if (!SessionService.checkUserPermission('MasterEntity')){
        $location.url('settings');
    }

    function rgb2hex(rgb){
        let hex = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (hex && hex.length === 4) ? '#' +
        ('0' + parseInt(hex[1],10).toString(16)).slice(-2) +
        ('0' + parseInt(hex[2],10).toString(16)).slice(-2) +
        ('0' + parseInt(hex[3],10).toString(16)).slice(-2) : '';
    }

    _this.onLogoLoaded = (imageObject) => {
        let imageType = imageObject.type;
        let images = {
            logo: {
                width: 200,
                height: 70,
                errorMessage: 'Please upload a logo with the correct dimensions of 200px in width and 70px in height.'
            },
            icon: {
                width: 35,
                height: 35,
                errorMessage: 'Please upload an icon with the correct dimensions of 35px in width and 35px in height.'
            },
            loginLogo: {
                width: 200,
                height: 70,
                errorMessage: 'Please upload a login logo with the correct dimensions of 200px in width and 70px in '+
                'height.'
            },
            background: {
                width: 1920,
                height: 1080,
                errorMessage: 'Please upload a background with dimensions of at least 1920px in width and at least '+
                '1080px in height.'
            },
            favicon: {
                width: 48,
                height: 48,
                errorMessage: 'Please upload an icon with the correct dimensions of 48px in width and 48px in height.'
            }
        };

        let imageWidth = images[imageType].width;
        let imageHeight = images[imageType].height;
        let imageErrorMessage = images[imageType].errorMessage;

        let imageData = imageObject.data.dataUrl;
        let image = new window.Image();
        image.onload = () => {
            let isImageValid =
                imageObject.type !== 'background' &&
                image.width === imageWidth &&
                image.height === imageHeight;
            let isBackgroundImageValid =
                imageObject.type === 'background' &&
                imageObject.data.size <= 1000000 &&
                image.width >= imageWidth &&
                image.height >= imageHeight;

            if (isImageValid || isBackgroundImageValid){
                _this.formData.image[imageType] = imageData;
                PaperToastService.closeErrors();
            } else if (imageObject.data.size >= 1000000){
                PaperToastService.create('Please upload an image 1 megabyte or lower in file size.', 'error');
            } else {
                PaperToastService.create(imageErrorMessage, 'error');
            }
        };
        image.src = imageData;
    };

    function loadImage(imageType){
        window.PAYFIRMA.CommunicationService.triggerImageUploadEvent(imageType);
    }

    function removeImage(imageType){
        let imgElement = 'img[type='+imageType+']';
        if (document.querySelector(imgElement)){
            document.querySelector(imgElement).removeAttribute('src');
        }
        _this.formData.image[imageType] = undefined;
    }

    function loadStyleSheetCssRules(cssRules){
        _.forEach(cssRules, (cssRule) => {
            let elementSelector = cssRule.selectorText;
            _.forEach(cssRule.style, (stylePropertyKey) => {
                let stylePropertyValue = cssRule.style[stylePropertyKey];
                if (elementSelector === '[theme-header-text]'){
                    _this.colorHeaderOverride = true;
                    _this.formData.header.text = Number(stylePropertyValue);
                }
                if (elementSelector === '[theme-header-background]'){
                    _this.colorHeaderOverride = true;
                    _this.formData.header.background = rgb2hex(stylePropertyValue);
                }
                if (elementSelector === '[theme-sidebar-text]'){
                    _this.colorSidebarOverride = true;
                    _this.formData.sidebar.text = Number(stylePropertyValue);
                }
                if (elementSelector === '[theme-sidebar-background]'){
                    _this.colorSidebarOverride = true;
                    _this.formData.sidebar.background = rgb2hex(stylePropertyValue);
                }
                if (elementSelector === '[theme-button-text]'){
                    _this.colorButtonOverride = true;
                    _this.formData.button.text = Number(stylePropertyValue);
                }
                if (elementSelector === '[theme-button-background]'){
                    _this.colorButtonOverride = true;
                    _this.formData.button.background = rgb2hex(stylePropertyValue);
                }
                if (elementSelector === '[theme-image-logo]'){
                    _this.formData.image.logo = stylePropertyValue.slice(4,-2).replace('"', '');
                }
                if (elementSelector === '[theme-image-login-logo]'){
                    _this.formData.image.loginLogo = stylePropertyValue.slice(4,-2).replace('"', '');
                }
                if (elementSelector === '[theme-image-background]'){
                    _this.formData.image.background = stylePropertyValue.slice(4,-2).replace('"', '');
                }
                if (elementSelector === '[theme-image-icon]'){
                    _this.formData.image.icon = stylePropertyValue.slice(4,-2).replace('"', '');
                }
                if (elementSelector === '[theme-login-button-text]'){
                    _this.colorLoginButtonOverride = true;
                    _this.formData.loginButton.text = Number(stylePropertyValue);
                }
                if (elementSelector === '[theme-login-button-background]'){
                    _this.colorLoginButtonOverride = true;
                    _this.formData.loginButton.background = rgb2hex(stylePropertyValue);
                }
                if (elementSelector === '[theme-image-favicon]'){
                    _this.formData.image.favicon = stylePropertyValue.slice(4,-2).replace('"', '');
                    _this.faviconImage = stylePropertyValue.slice(4,-2).replace('"', '');
                    _this.previewFaviconImage = stylePropertyValue.slice(4,-2).replace('"', '');
                }
                if (elementSelector === '[theme-asset-title]'){
                    _this.formData.asset.title = stylePropertyValue.slice(1,-1);
                }
                if (elementSelector === '[theme-icon-color]'){
                    _this.iconToggle = true;
                    _this.formData.icon.color = Number(stylePropertyValue);
                }
                if (elementSelector === '[theme-icon-weight]'){
                    _this.iconToggle = true;
                    _this.formData.icon.weight = Number(stylePropertyValue);
                }

            });
        });
    }

    function readStyleSheet() {
        lodash.forEach(document.styleSheets, (stylesheet) => {
            if (stylesheet.title === 'subdomain-theme'){
                let themeStyleSheet = stylesheet;
                let themeStyleSheetCssRules = themeStyleSheet.cssRules;
                loadStyleSheetCssRules(themeStyleSheetCssRules);
            }
        });
    }

    function resolveColorTextToValue(data, type){
        let colorValue = '';
        if (data[type].text === 1){
            colorValue = 'rgb(240,240,240)';
        }
        if (data[type].text === 2){
            colorValue = DEFAULT_STYLE_COLOR;
        }
        if (data[type].text === 3){
            colorValue = 'rgb(10,10,10)';
        }
        return colorValue;
    }

    function resolveSidebarIconPositionY(data, type){
        let sidebarIconPositionY = '';
        if (data[type].text === 1){
            sidebarIconPositionY = '-5px';
        }
        if (data[type].text === 2){
            sidebarIconPositionY = '-34px';
        }
        if (data[type].text === 3){
            sidebarIconPositionY = '-64px';
        }
        return sidebarIconPositionY;
    }

    function resolveImagePoweredByPayfirma(data, type){
        let imagePoweredByPayfirma = '';
        if (data[type].text === 1){
            imagePoweredByPayfirma = poweredByPayfirmaWhiteUrl;
        } else if (data[type].text === 2){
            imagePoweredByPayfirma = poweredByPayfirmaGreyUrl;
        } else if (data[type].text === 3){
            imagePoweredByPayfirma = poweredByPayfirmaBlackUrl;
        }
        return imagePoweredByPayfirma;
    }

    function resolveImageLoginPoweredByPayfirma(data, type){
        let imagePoweredByPayfirma = '';
        if (data[type].text === 1){
            imagePoweredByPayfirma = loginPoweredByPayfirmaWhiteUrl;
        } else if (data[type].text === 2){
            imagePoweredByPayfirma = loginPoweredByPayfirmaBlackUrl;
        } else if (data[type].text === 3){
            imagePoweredByPayfirma = loginPoweredByPayfirmaGreyUrl;
        }
        return imagePoweredByPayfirma;
    }

    _this.resolveIconColorValue = (color) => {
        let iconColor;
        switch (Number(color)) {
            case 0:
                iconColor = '';
                break;
            case 1:
                iconColor = './app/css/images/icons/icon-set-white.png';
                break;
            case 2:
                iconColor = './app/css/images/icons/icon-set-grey.png';
                break;
            case 3:
                iconColor = './app/css/images/icons/icon-set-black.png';
                break;
            default:
                iconColor = '';
                break;
        }
        return iconColor;
    };
    _this.resolveIconWeightPosition = (weight) => {
        let iconWeight;
        switch (Number(weight)) {
            case 0:
                iconWeight = '';
                break;
            case 1:
                iconWeight = '0px!important';
                break;
            case 2:
                iconWeight = '-30px!important';
                break;
            case 3:
                iconWeight = '-60px!important';
                break;
            default:
                iconWeight = '';
                break;
        }
        return iconWeight;
    };

    function buildThemeObject(data){
        let themeObject = {};
        if (data){
            themeObject = {
                icon: {
                    color: data.icon.color,
                    weight: data.icon.weight,
                    colorValue: _this.resolveIconColorValue(data.icon.color),
                    weightPosition: _this.resolveIconWeightPosition(data.icon.weight)
                },
                header: {
                    text: data.header.text || 0,
                    background: data.header.background || '',
                    textColor: resolveColorTextToValue(data, 'header')
                },
                sidebar: {
                    text: data.sidebar.text || 0,
                    background: data.sidebar.background || '',
                    textColor: resolveColorTextToValue(data, 'sidebar'),
                    iconPositionX: resolveSidebarIconPositionY(data, 'sidebar'),
                    imagePowered: resolveImagePoweredByPayfirma(data, 'sidebar')
                },
                button: {
                    text: data.button.text || 0,
                    background: data.button.background || '',
                    textColor: resolveColorTextToValue(data, 'button')
                },
                loginButton: {
                    text: data.loginButton.text || 0,
                    background: data.loginButton.background || '',
                    textColor: resolveColorTextToValue(data, 'loginButton')
                },
                login: {
                    imagePowered: resolveImageLoginPoweredByPayfirma(data, 'sidebar') ||
                        resolveImageLoginPoweredByPayfirma(data, 'style')
                },
                image: {
                    logo: data.image.logo || '',
                    icon: data.image.icon || '',
                    loginLogo: data.image.loginLogo || '',
                    background: data.image.background || '',
                    favicon: data.image.favicon || ''
                },
                asset: {
                    title: data.asset.title || 0
                }
            };
        }
        return themeObject;
    }

    _this.iconWeightPreviewStyle = () => {
        let style;
        switch (_this.formData.icon.weight){
            case 1:
                style = { 'background-position-x': '0px' };
                break;
            case 2:
                style = { 'background-position-x': '-30px' };
                break;
            case 3:
                style = { 'background-position-x': '-60px' };
                break;
            default:
                style = '';
        }
        return style;
    };

    _this.toggledStyleOveride = (override, toggleType) => {
        if (toggleType){
            if (!override){
                _this.cachedFormData[toggleType].background = _this.formData[toggleType].background;
                _this.formData[toggleType].background = null;
                _this.cachedFormData[toggleType].text = _this.formData[toggleType].text;
                _this.formData[toggleType].text = 0;
            } else {
                _this.formData[toggleType].background = _this.cachedFormData[toggleType].background;
                _this.formData[toggleType].text = _this.cachedFormData[toggleType].text;
            }
        }
    };

    _this.toggleIcon = (toggle) => {
        if (!toggle){
            _this.cachedFormData.icon.color = _this.formData.icon.color;
            _this.formData.icon.color = null;
            _this.cachedFormData.icon.weight = _this.formData.icon.weight;
            _this.formData.icon.weight = null;
        } else {
            _this.formData.icon.color = _this.cachedFormData.icon.color;
            _this.formData.icon.weight = _this.cachedFormData.icon.weight;
        }
    };

    _this.backgroundColor = (color) => {
        return {
            'background-color': color
        };
    };

    _this.styleColors = (color, colorBackground) => {
        let colorValue;
        switch (color) {
            case 1:
                colorValue = '#eeeeee';
                break;
            case 2:
                colorValue = DEFAULT_STYLE_COLOR;
                break;
            case 3:
                colorValue = '#141414';
                break;
            default:
                colorValue = '';
        }
        return {
            'background-color': colorBackground,
            'color': colorValue
        };
    };
    _this.poweredClass = () => {
        let colorNumber = _this.formData.sidebar.text;
        let colorClass;
        switch (colorNumber) {
            case 1:
                colorClass = 'light';
                break;
            case 2:
                colorClass = 'medium';
                break;
            case 3:
                colorClass = 'dark';
                break;
            default:
                colorClass = '';
        }
        return colorClass;
    };
    _this.textColor = (element, element2) => {
        let colorValue;
        let colorNumber;
        if (element2){
            colorNumber = _this.formData[element].text || _this.formData[element2].text;
        } else if (element === 'icon') {
            colorNumber = _this.formData[element].color;
        } else {
            colorNumber = _this.formData[element].text;
        }
        switch (colorNumber) {
            case 1:
                colorValue = '#eeeeee';
                break;
            case 2:
                colorValue = DEFAULT_STYLE_COLOR;
                break;
            case 3:
                colorValue = '#141414';
                break;
            default:
                colorValue = '';
        }
        return {
            'background-color': colorValue
        };
    };

    _this.previewPayfirmaLogo = () => {
        let colorValue;
        let colorNumber =
            _this.formData.sidebar.text;
        switch (colorNumber) {
            case 1:
                colorValue = 'url(app/css/images/logo-payfirma-white.png)';
                break;
            case 2:
                colorValue = 'url(app/css/images/logo-payfirma-grey.png)';
                break;
            case 3:
                colorValue = 'url(app/css/images/logo-payfirma-black.png)';
                break;
            default:
                colorValue = '';
        }
        return {
            'background-image': colorValue
        };
    };

    _this.themeStyle = (style) => {
        let styleClass;
        if (style === 0){
            styleClass = 'none';
        } else if (style === 1){
            styleClass = 'light';
        } else if (style === 2){
            styleClass = 'medium';
        } else if (style === 3){
            styleClass = 'dark';
        }
        return styleClass;
    };

    _this.loadLogo = () => {
        loadImage(LOGO_IMAGE_UPLOAD_TYPE);
    };

    _this.removeLogo = () => {
        removeImage(LOGO_IMAGE_UPLOAD_TYPE);
    };

    _this.loadIcon = () => {
        loadImage(ICON_IMAGE_UPLOAD_TYPE);
    };

    _this.removeIcon = () => {
        removeImage(ICON_IMAGE_UPLOAD_TYPE);
    };

    _this.loadLoginLogo = () => {
        loadImage(LOGIN_LOGO_IMAGE_UPLOAD_TYPE);
    };

    _this.removeLoginLogo = () => {
        removeImage(LOGIN_LOGO_IMAGE_UPLOAD_TYPE);
    };

    _this.loadBackground = () => {
        loadImage(BACKGROUND_IMAGE_UPLOAD_TYPE);
    };

    _this.removeBackground = () => {
        removeImage(BACKGROUND_IMAGE_UPLOAD_TYPE);
    };

    _this.backgroundPreviewStyle = () => {
        let style;
        if (_this.formData.image.background){
            style = { 'background-image': 'url('+_this.formData.image.background+')' };
        }
        return style;
    };
    _this.loadFavicon = () => {
        loadImage(FAVICON_IMAGE_UPLOAD_TYPE);
    };

    _this.removeFavicon = () => {
        removeImage(FAVICON_IMAGE_UPLOAD_TYPE);
    };

    _this.$onInit = () => {
        readStyleSheet();
    };

    _this.iconPositionY = (color) => {
        let iconPositionY;
        switch (color) {
            case 1:
                iconPositionY = '-5px';
                break;
            case 2:
                iconPositionY = '-34px';
                break;
            case 3:
                iconPositionY = '-64px';
                break;
            default:
                iconPositionY = '';
        }
        if (iconPositionY){
            return {
                'background-position-y': iconPositionY
            };
        } else {
            return null;
        }
    };

    _this.putTheme = (form, formData) => {
        let putThemeString = '';
        if (form && form.$valid && formData){
            _this.savingTheme = true;
            PaperToastService.create('Saving theme...', 'update');

            let putThemeStrings = {
                asset: {
                    title:
                        '[theme-asset-title]{'+
                            'content:"'+buildThemeObject(formData).asset.title+'";'+
                        '}'
                },
                icon: {
                    color:
                        '[theme-icon-color]{'+
                            'z-index:'+buildThemeObject(formData).icon.color+';'+
                        '}'+
                        '.symbol{'+
                            'background-image: url('+ buildThemeObject(formData).icon.colorValue +')!important;'+
                        '}',
                    weight:
                        '[theme-icon-weight]{'+
                            'z-index:'+buildThemeObject(formData).icon.weight+';'+
                        '}'+
                        '.symbol{'+
                            'background-position-x:'+ buildThemeObject(formData).icon.weightPosition +';'+
                        '}'
                },
                header: {
                    text:
                        '[theme-header-text]{'+
                            'z-index:'+buildThemeObject(formData).header.text+';'+
                        '}'+
                        'paper-header{'+
                            'color:'+ buildThemeObject(formData).header.textColor +';'+
                        '}'+
                        'paper-header > section a{'+
                            'color:'+ buildThemeObject(formData).header.textColor +';'+
                        '}'+
                        'paper-header [business-name]{'+
                            'color:'+buildThemeObject(formData).header.textColor+';'+
                        '}'+
                        'paper-header > section{'+
                            'color:'+buildThemeObject(formData).header.textColor+';'+
                        '}',
                    background:
                        '[theme-header-background]{'+
                            'color:'+buildThemeObject(formData).header.background+';'+
                        '}'+
                        'paper-header{'+
                            'background-color:'+buildThemeObject(formData).header.background+';'+
                        '}'+
                        'paper-header > section paper-dropdown:hover, paper-header > section a:hover{'+
                            'color:'+buildThemeObject(formData).header.background+'!important;'+
                        '}'+
                        'paper-dropdown dropdown div section:hover{'+
                            'background-color:'+buildThemeObject(formData).header.background+'!important;'+
                        '}'
                },
                sidebar: {
                    text:
                        '[theme-sidebar-text]{'+
                            'z-index:'+buildThemeObject(formData).sidebar.text+';'+
                        '}'+
                        'paper-navigation > section a .text, paper-navigation > footer a .text{'+
                            'color:'+buildThemeObject(formData).sidebar.textColor+';'+
                        '}'+
                        'paper-navigation > section a{'+
                            'color:'+ buildThemeObject(formData).sidebar.textColor +';'+
                        '}'+
                        'paper-navigation [powered]{'+
                            'background-image: url('+ buildThemeObject(formData).sidebar.imagePowered +');'+
                        '}'+
                        'paper-navigation > section a:hover{'+
                            'color:'+ buildThemeObject(formData).sidebar.textColor +';'+
                        '}'+
                        '[login-powered]{'+
                            'background-image: url('+ buildThemeObject(formData).sidebar.imagePowered +');'+
                        '}',
                    background:
                        '[theme-sidebar-background]{'+
                            'color:'+buildThemeObject(formData).sidebar.background+';'+
                        '}'+
                        'paper-navigation{'+
                            'background-color:'+buildThemeObject(formData).sidebar.background+';'+
                        '}'+
                        'paper-navigation>footer a.active, paper-navigation>section a.active {' +
                            'background-color: rgba(0, 0, 0, 0.40)!important;'+
                        '}',
                    icon: 'paper-navigation > section a .icon{'+
                            'background-position-y:'+buildThemeObject(formData).sidebar.iconPositionX+'!important;'+
                        '}'
                },
                button: {
                    text:
                        '[theme-button-text]{'+
                            'z-index:'+buildThemeObject(formData).button.text+';'+
                        '}'+
                        'paper-button:not(.cancel) span{'+
                            'color:'+buildThemeObject(formData).button.textColor+';'+
                        '}',
                    background:
                        '[theme-button-background]{'+
                            'color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'paper-button{'+
                            'background-color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'paper-input[type="file"]{'+
                            'background-color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'login a{'+
                            'color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'paper-data-table [orange]{'+
                            'color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'paper-header-help [link]{'+
                            'color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'paper-header-help [help-result-list] [help-result] h1{'+
                            'color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'settings-theme paper-form form fieldset [row-button]:hover{'+
                            'color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'terminal .more-order-detail h1:hover{'+
                            'color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'paper-dropdown-menu dropdown span section:hover{'+
                            'background-color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'paper-button-group-menu dropdown div section:hover{'+
                            'background-color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        '.paper-filters dropdown section:hover{'+
                            'background-color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'paper-data-table [orange]{'+
                            'color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'paper-data-table > section > header.expanded{'+
                            'background-color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'paper-data-table > section > header:hover{'+
                            'background-color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        '.paper-filters dropdown paper-checkbox:hover{'+
                            'background-color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        'paper-checkbox input:checked + .element .icon{'+
                            'background-color:'+buildThemeObject(formData).button.background+';'+
                        '}'+
                        '.daterangepicker td.active, .daterangepicker td.active:hover{'+
                            'background-color:'+buildThemeObject(formData).button.background+'!important;'+
                            'border-color:'+buildThemeObject(formData).button.background+'!important;'+
                        '}'+
                        '.range_inputs .applyBtn{'+
                            'background-color:'+buildThemeObject(formData).button.background+'!important;'+
                        '}'+
                        '.daterangepicker td.in-range{'+
                            'background-color:'+buildThemeObject(formData).button.background+'!important;'+
                        '}'+
                        '.daterangepicker .input-mini.active{'+
                            'border-bottom-color:'+buildThemeObject(formData).button.background+'!important;'+
                        '}'+
                        '.form-button-group li:hover{'+
                            'background-color:'+buildThemeObject(formData).button.background+'!important;'+
                        '}'+
                        'paper-button-group-menu dropdown div section:hover{'+
                            'background-color:'+buildThemeObject(formData).button.background+'!important;'+
                        '}'+
                        'message a{'+
                            'color:'+buildThemeObject(formData).button.background+'!important;'+
                        '}'
                },
                loginButton: {
                    text:
                        '[theme-login-button-text]{'+
                            'z-index:'+buildThemeObject(formData).loginButton.text+';'+
                        '}'+
                        'login paper-button:not(.cancel) span{'+
                            'color:'+buildThemeObject(formData).loginButton.textColor+';'+
                        '}',
                    background:
                        '[theme-login-button-background]{'+
                            'color:'+buildThemeObject(formData).loginButton.background+';'+
                        '}'+
                        'login paper-button{'+
                            'background-color:'+buildThemeObject(formData).loginButton.background+';'+
                        '}'
                },
                image: {
                    logo:
                        '[theme-image-logo]{'+
                            'background-image: url('+buildThemeObject(formData).image.logo+');'+
                        '}'+
                        'login .payfirmaLogo {background-image: url(' + buildThemeObject(formData).image.logo + ')'+
                        ' !important;}'+
                        'paper-navigation > header span{'+
                            'background-image: url('+buildThemeObject(formData).image.logo+');'+
                        '}',
                    icon:
                        '[theme-image-icon]{'+
                            'background-image: url('+buildThemeObject(formData).image.icon+');'+
                        '}'+
                        '.compactNavigation paper-navigation > header span{'+
                            'background-image: url('+buildThemeObject(formData).image.icon+');'+
                        '}',
                    loginLogo:
                        '[theme-image-login-logo]{'+
                            'background-image: url('+buildThemeObject(formData).image.loginLogo+');'+
                        '}'+
                        'login .payfirmaLogo {background-image: url(' + buildThemeObject(formData).image.loginLogo +')'+
                        ' !important;}',
                    background:
                        '[theme-image-background]{'+
                            'background-image: url('+buildThemeObject(formData).image.background+');'+
                        '}'+
                        'login {background-image: url(' + buildThemeObject(formData).image.background +')'+
                        ' !important;}',
                    favicon:
                        '[theme-image-favicon]{'+
                            'background-image: url('+buildThemeObject(formData).image.favicon+');'+
                        '}'
                }
            };
            if (buildThemeObject(formData).header.text && _this.colorHeaderOverride){
                putThemeString = putThemeString + putThemeStrings.header.text;
            }
            if (buildThemeObject(formData).header.background && _this.colorHeaderOverride){
                putThemeString = putThemeString + putThemeStrings.header.background;
            }
            if (buildThemeObject(formData).sidebar.text && _this.colorSidebarOverride){
                putThemeString = putThemeString + putThemeStrings.sidebar.text;
            }
            if (buildThemeObject(formData).sidebar.background && _this.colorSidebarOverride){
                putThemeString = putThemeString + putThemeStrings.sidebar.background;
            }
            if (buildThemeObject(formData).sidebar.iconPositionX){
                putThemeString = putThemeString + putThemeStrings.sidebar.icon;
            }
            if (buildThemeObject(formData).button.text && _this.colorButtonOverride){
                putThemeString = putThemeString + putThemeStrings.button.text;
            }
            if (buildThemeObject(formData).button.background && _this.colorButtonOverride){
                putThemeString = putThemeString + putThemeStrings.button.background;
            }
            if (buildThemeObject(formData).image.logo){
                putThemeString = putThemeString + putThemeStrings.image.logo;
            }
            if (buildThemeObject(formData).image.loginLogo){
                putThemeString = putThemeString + putThemeStrings.image.loginLogo;
            }
            if (buildThemeObject(formData).image.background){
                putThemeString = putThemeString + putThemeStrings.image.background;
            }
            if (buildThemeObject(formData).image.icon){
                putThemeString = putThemeString + putThemeStrings.image.icon;
            }
            if (buildThemeObject(formData).loginButton.text){
                putThemeString = putThemeString + putThemeStrings.loginButton.text;
            }
            if (buildThemeObject(formData).loginButton.background){
                putThemeString = putThemeString + putThemeStrings.loginButton.background;
            }
            if (buildThemeObject(formData).image.favicon){
                putThemeString = putThemeString + putThemeStrings.image.favicon;
            }
            if (buildThemeObject(formData).asset.title){
                putThemeString = putThemeString + putThemeStrings.asset.title;
            }
            if (buildThemeObject(formData).icon.color && _this.iconToggle){
                putThemeString = putThemeString + putThemeStrings.icon.color;
            }
            if (buildThemeObject(formData).icon.weight && _this.iconToggle){
                putThemeString = putThemeString + putThemeStrings.icon.weight;
            }
        }
        AssetService.postAsset(putThemeString)
        .then(() => {
            _this.savingTheme = false;
            window.location.reload();
            PaperToastService.create('Your theme has been successfully updated', 'update');
        }).catch(() => {
            PaperToastService.create('Error updating custom theme.', 'error');
        });
    };

    _this.cancelTheme = () =>{
        $route.reload();
    };

}
angular
.module('payhqUIApp')
.component('settingsTheme', {
    bindings: {
    },
    controller: theme,
    controllerAs: 'theme',
    templateUrl: 'view/settings/theme/theme.html'
});