import angular from 'angular';

function paperPlanFilter($element, Restangular, PlanService, UrlService) {

    var _this = this;

    _this.isDropped = false;
    _this.isLoading = false;

    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function(){
        if (_this.isDropped === true){
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });

    $element.on('click', function(e){
        if (_this.isDropped === true){
            $('dropdown').show();
        }
        e.stopPropagation();
    });

    _this.planList = [];

    function createSelectedFilterPlanObject(plan) {
        _this.title = plan.name;
        _this.filterplan = {
            plan_lookup_id: plan.lookup_id,
            planId : plan.id
        };
    }
    _this.setSelectedPlan = function (plan) {
        createSelectedFilterPlanObject(plan);

        _this.doDrop(false);

        UrlService.go('/customer-vault/recurring/' + plan.lookup_id);
    };

    function initFilter() {
        _this.isLoading = true;
        var params = {
            limit: window.PAYFIRMA.CONSTANT.PLAN_PAGINATION_LIMIT,
            min_number_of_current_subscriptions:1
        };

        PlanService.get(params)
            .then(function(allPlansObject) {
                angular.forEach(allPlansObject.entities, function (plan) {
                    _this.planList.push(plan);
                    if ( plan.lookup_id === _this.filterinitplan ){
                        createSelectedFilterPlanObject(plan);
                    }
                });

                _this.isLoading = false;
            });
    }

    initFilter();
}


angular
.module('payhqUIApp')
.component('paperPlanFilter', {
    bindings: {
        filterplan: '=',
        filterinitplan: '='
    },
    controller: paperPlanFilter,
    controllerAs: 'paperPlanFilter',
    templateUrl: 'component/paper-filter/paper-plan-filter/paper-plan-filter.html'
});