import angular from 'angular';
import CustomerSubscription from 'model/customer-subscription';

function customerDetails(
    $scope, $translate, Utils, CustomerCardService, SubscriptionService, $log, PaperToastService, $location,
    SessionService) {

    var checkUserPermission = SessionService.checkUserPermission;

    const CANCEL = 'CANCEL';
    const RESUME_SUSPENDED = 'RESUME_SUSPENDED';

    const CONST = {
        ACTIVE: 'ACTIVE',
        RETRY: 'RETRY',
        PAUSED: 'PAUSED',
        SUSPENDED: 'SUSPENDED'
    };

    var _this;
    _this = this;

    _this.canCustomerAddCards = () => {
        let customerHasMoreThanFiveCards = _this.customer.cards.length < 10;
        return !customerHasMoreThanFiveCards;
    };

    function refreshRecurringPlanList(response) {
        if (response) {
            _this.customer.setCustomerPlans(response);
        }
    }

    function handlesSubscriptionUpdate(promise) {
        promise
        .then(function (response) {
            refreshRecurringPlanList(response.data.subscriptions);
        })
        .catch(function (error) {
            $log.error(error);
        });
    }

    function refreshCreditCardList(response) {
        _this.customer.cards = response;
    }

    function enableAddCreditCardModal() {
        _this.addCreditCardEnabled = true;
        window.PAYFIRMA.CommunicationService.triggerSaveCreditCardModalOpen(undefined, _this.customer);
    }

    function openRemoveCreditCardModal(removedCreditCardLookupId) {
        _this.removeCreditCardEnabled = true;
        window.PAYFIRMA.CommunicationService.triggerRemoveCreditCardModalOpen(removedCreditCardLookupId);
    }

    function doRemoveCreditCard(removedCreditCardLookupId) {
        CustomerCardService.deleteCustomerCard(removedCreditCardLookupId, _this.customer.lookup_id)
            .then(function (response) {
                refreshCreditCardList(response);
            })
            .catch(function (error) {
                $log.error(error);
            });
    }

    function checkIfCardSubscribedToPlans(removedCreditCardLookupId) {
        var subscribedPlans = [];
        var customerPlans = _this.customer.getAllCustomerPlans();
        if (customerPlans) {
            subscribedPlans = _.filter(customerPlans, function (customerPlan) {
                var plan = new CustomerSubscription(customerPlan);
                if ( plan.isValid() && plan.card_lookup_id === removedCreditCardLookupId) {
                    return plan;
                }
            });
        }
        return subscribedPlans;
    }

    // Compare the current time with 1st day 00 am of the next month that the card is expired
    // ex. card_expiry: '06/20', current_date: '28/06/20'
    // isCardExpired = window.moment('2020 July 1st 00:00').isSameOrBefore('2020 June 28 00:00') = false
    _this.isCardExpired = function(card){
        let isCardExpired = false;
        if (card){
            let year = card.card_expiry.slice(3);
            let month = card.card_expiry.slice(0,2);
            if (month === '12') {
                year = +year + 1;
                month = '00';
            }
            let cardYear = '20' + year;
            let cardDate = window.moment([cardYear, month]);
            let thisDay = window.moment();
            isCardExpired = window.moment(cardDate).isSameOrBefore(thisDay);
        }
        return isCardExpired;
    };

    _this.hasInvoicingPermission = function(){
        return checkUserPermission('InvoiceMaster');
    };

    _this.hasTransactionsPermission = function(){
        return checkUserPermission('MyTransactions_Master');
    };

    _this.hasEFTPermission = function(){
        return checkUserPermission('EFTMaster');
    };

    _this.hasSubscriptionsPermission = function(){
        return checkUserPermission('RecurringMaster');
    };

    _this.isExistingCustomer = function(){
        //put this logic inside the customer model once we refactor it, cannot put it now because application's
        // inconsistency of modeling
        return _this.customer && _this.customer.id && _this.customer.first_name && _this.customer.last_name &&
        _this.customer.email;
    };

    _this.checkPlanCycles = function(plan){
        var totalCycles = plan.total_cycles;
        var remainingCycles = plan.remaining_cycles;
        // Was sometimes returning as 0 others as "0"
        if (!totalCycles || totalCycles === 0 || totalCycles === '0'){
            return '∞';
        } else {
            return remainingCycles;
        }
    };

    window.PAYFIRMA.CommunicationService.onRefreshCreditCardList(function(response) {
        refreshCreditCardList(response);
    });

    window.PAYFIRMA.CommunicationService.onRefreshRecurringPlanList(function (subscribedPlans) {
        refreshRecurringPlanList(subscribedPlans);
    });

    _this.addCreditCardEnabled = false;
    _this.removeCreditCardEnabled = false;
    _this.removeCreditCardConfirmModal = false;

    _this.isCustomerInfoSubmitReady = function(){
        var isCustomerInfoSubmitReady = false;
        if (_this.customer &&
        _this.customer.first_name &&
        _this.customer.last_name &&
        _this.customer.email){
            isCustomerInfoSubmitReady = true;
        }
        return isCustomerInfoSubmitReady;
    };

    _this.canAddCreditCard = _this.isCustomerInfoSubmitReady();

    _this.canSubscribePlan = function() {
        var isCanSubscribePlan = false;
        if (_this.customer && _this.customer.cards.length){
            isCanSubscribePlan = true;
        }
        return isCanSubscribePlan;
    };
    _this.canViewAllTransactions = function() {
        return _this.isExistingCustomer();
    };
    _this.canCreateInvoice = function() {
        return _this.isExistingCustomer();
    };

    function canAddOrEditSubscription() {
        var allCreditCards = _this.customer.cards;
        var validCreditCards;
        if (allCreditCards && allCreditCards.length) {
            validCreditCards = _.filter(allCreditCards, function (creditCard) {
                return creditCard.is_expired !== true;
            });
            return validCreditCards && validCreditCards.length;
        } else {
            return false;
        }
    }

    function displayNoValidCreditCardAvailableError() {
        PaperToastService.error('cardRequiredMessage');
    }

    function displayDelinquentCycleCheckMessage(subscription, actionType) {
        var message;
        var delinquentCycles = subscription.getPlanDelinquentCycles();
        if (delinquentCycles > 0) {
            message = '';
            if (actionType === CANCEL) {
                message = 'Subscription cancelled. Customer missed ' + delinquentCycles + ' payments.';
            } else if (actionType === RESUME_SUSPENDED) {
                message = 'Subscription resumed. Customer missed ' + delinquentCycles + ' payments.';
            }

            PaperToastService.create(message, 'error');
        }
    }

    window.PAYFIRMA.CommunicationService.onCustomerSuccessfullySavedEvent(function(customer){
        _this.customer = customer;
        enableAddCreditCardModal();
    });

    //credit card actions
    _this.addCreditCard = function() {
        enableAddCreditCardModal();
    };

    function resetCardRequestURL() {
        _this.cardRequestUrl = '';
    }

    _this.getCardRequestURLEmail = function() {
        _this.isLoadingUrl = true;
        _this.cardRequest = true;
        CustomerCardService.getCardRequestURLEmail(_this.customer.lookup_id, false).then((cardURLEmailResponse) => {
            _this.cardRequestUrl = cardURLEmailResponse.card_request_url;
        })
        .finally(() => {
            _this.isLoadingUrl = false;
        });
    };

    _this.sendCardURLEmail = function() {
        CustomerCardService.getCardRequestURLEmail(_this.customer.lookup_id, true).then((cardURLEmailResponse) => {
            if (cardURLEmailResponse && cardURLEmailResponse.sent_email){
                PaperToastService.create(`Sent Card Request Email to ${_this.customer.email}`, 'update');
                _this.cardRequest = false;
                resetCardRequestURL();
            }
        }).catch(() => {
            PaperToastService.create('Email not sent successfully', 'error');
        });
    };

    _this.cancelCardURLEmailModal = function() {
        _this.cardRequest = false;
        resetCardRequestURL();
    };

    //copy the card request url to the clipboard
    _this.copyUrlToClip = (url) => {
        if (window.clipboardData && window.clipboardData.setData) {
            _this.cardRequest = false;
            // IE specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData('Text',url);
        } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            let textarea = document.createElement('textarea');
            textarea.textContent = url;
            textarea.style.position = 'fixed';
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy');
                PaperToastService.create('The URL has been copied.', 'update');
                _this.cardRequest = false;
            } catch (ex) {
                PaperToastService.create('Failed to copy' ,'error');
            } finally {
                document.body.removeChild(textarea);
            }
        }
    };

    _this.editCreditCard = function (card) {
        _this.addCreditCardEnabled = true;
        window.PAYFIRMA.CommunicationService.triggerSaveCreditCardModalOpen(card, _this.customer);
        Utils.forceScopeApply($scope);
    };

    _this.removeCreditCard = function (card) {
        var removedCreditCardLookupId = card.lookup_id;
        var subscribedPlans = checkIfCardSubscribedToPlans(removedCreditCardLookupId);
        if (subscribedPlans.length > 0) {
            if (_this.customer.cards.length === 1) {
                $translate('customerSubscribed').then(function (translation) {
                    PaperToastService.create(translation, 'error');
                });
            } else {
                openRemoveCreditCardModal(removedCreditCardLookupId);
            }
        } else {
            _this.setCardToBeDeleted(card);
            _this.removeCreditCardConfirmModal = true;
        }
    };

    _this.setCardToBeDeleted = function (card) {
        _this.cardToBeDeleted = card;
    };

    _this.closeRemoveCreditCardConfirmModal = function() {
        _this.removeCreditCardConfirmModal = false;
        _this.setCardToBeDeleted(null);
    };

    _this.removeCreditCardWithConfirmation = function() {
        doRemoveCreditCard(_this.cardToBeDeleted.lookup_id);
        _this.closeRemoveCreditCardConfirmModal();
    };

    _this.cancelRemoveCreditCard = function() {
        _this.closeRemoveCreditCardConfirmModal();
    };

    _this.setDefaultCreditCard = function (card) {
        var creditCardObject = {
            is_default: true,
            card_description: card.card_description,
            customer_id: _this.customer.id,
            customer_lookup_id: _this.customer.lookup_id,
            lookup_id: card.lookup_id,
            id: card.id
        };

        CustomerCardService.saveCustomerCard(creditCardObject)
            .then(function (response) {
                refreshCreditCardList(response);
            })
            .catch(function (error) {
                $log.error(error);
            });
    };

    _this.goToNewSaleTransaction = function (creditCardId) {
        $location
        .path('/terminal/sale')
        .search('customer', _this.customer.lookup_id)
        .search('card', creditCardId);
    };

    _this.goToNewAuthorizeTransaction = function (creditCardId) {
        $location
        .path('/terminal/authorize')
        .search('customer', _this.customer.lookup_id)
        .search('card', creditCardId);
    };

    _this.viewAllCustomerTransactionsWithPlan = function () {
        Utils.go(decodeURIComponent('/transactions/customer/email/' + _this.customer.getEmail() + '/recurring' ));
    };
    _this.addSubscription = function () {
        if (canAddOrEditSubscription()) {
            window.PAYFIRMA.CommunicationService.triggerSaveSubscriptionModalOpen();
            _this.saveSubscriptionEnabled = true;

        } else {
            displayNoValidCreditCardAvailableError();
        }
    };

    _this.editSubscription = function (subscription) {
        if (canAddOrEditSubscription()) {
            window.PAYFIRMA.CommunicationService.triggerSaveSubscriptionModalOpen(subscription);
            _this.saveSubscriptionEnabled = true;
        } else {
            displayNoValidCreditCardAvailableError();
        }
    };

    _this.cancelSubscription = function (subscriptionData) {
        var customerSubscription = new CustomerSubscription(subscriptionData);
        displayDelinquentCycleCheckMessage(customerSubscription, CANCEL);
        return SubscriptionService.cancelSubscription(_this.customer.lookup_id, customerSubscription.lookup_id)
            .then(function (response) {
                refreshRecurringPlanList(response.data.subscriptions);
            });
    };

    _this.pauseSubscription = function (subscriptionData) {
        handlesSubscriptionUpdate(SubscriptionService
            .pauseSubscription(_this.customer.lookup_id,subscriptionData.lookup_id));
    };

    _this.resumeSubscription = function (subscriptionData) {
        var customerSubscription = new CustomerSubscription(subscriptionData);
        // If we are resuming a suspended subscription, display number of outstanding payments
        if (customerSubscription.isSuspended()) {
            displayDelinquentCycleCheckMessage(customerSubscription, RESUME_SUSPENDED);
        }

        handlesSubscriptionUpdate(SubscriptionService
            .resumeSubscription(customerSubscription, _this.customer.lookup_id));
    };
    //$scope has to be used here because _this.$onDestroy does not work, sounds like
    //a bug in angularjs
    $scope.$on('$destroy', function () {
        window.PAYFIRMA.CommunicationService.disableSaveCreditCardModalOpenTrigger();
        window.PAYFIRMA.CommunicationService.disableRefreshCreditCardListTrigger();
        window.PAYFIRMA.CommunicationService.disableRefreshRecurringPlanListTrigger();
        window.PAYFIRMA.CommunicationService.disableRemoveCreditCardModalOpenTrigger();
        window.PAYFIRMA.CommunicationService.disableSaveSubscriptionModalOpenTrigger();
        window.PAYFIRMA.CommunicationService.disableRetryTransactionEventTrigger();
        window.PAYFIRMA.CommunicationService.disableCancelTransactionConfirmEvent();
        window.PAYFIRMA.CommunicationService.disableTransactionCompleleteEvent();
        window.PAYFIRMA.CommunicationService.disableOpenTransactionConfirmEventTrigger();
        window.PAYFIRMA.CommunicationService.disableCustomerSuccessfullySavedEvent();
        window.PAYFIRMA.CommunicationService.disableSaveCustomerEventTrigger();
        window.PAYFIRMA.CommunicationService.disableDeleteCustomerProfileModalOpenTrigger();
        window.PAYFIRMA.CommunicationService.disableConfirmDeleteCustomerTrigger();
    });

    _this.isValidSubscription = function (subscriptionData) {
        var customerSubscription = new CustomerSubscription(subscriptionData);
        return customerSubscription.isValid();
    };

    _this.checkPlanStatus = function(planStatus, statusArray){
        var isButtonVisible = false;
        if (planStatus && statusArray){
            _.forEach(statusArray, function(value){
                if (planStatus === value){
                    isButtonVisible = true;
                }
            });
        }
        return isButtonVisible;
    };

    _this.isEditPlanButtonVisible = function(planStatus){
        var isButtonVisible = false;
        if (planStatus){
            isButtonVisible = this.checkPlanStatus(
                planStatus, [CONST.ACTIVE, CONST.RETRY, CONST.PAUSED, CONST.SUSPENDED]);
        }
        return isButtonVisible;
    };

    _this.isPausePlanButtonVisible = function(planStatus){
        var isButtonVisible = false;
        if (planStatus){
            isButtonVisible = this.checkPlanStatus(planStatus, [CONST.ACTIVE, CONST.RETRY]);
        }
        return isButtonVisible;
    };

    _this.isResumePlanButtonVisible = function(planStatus){
        var isButtonVisible = false;
        if (planStatus){
            isButtonVisible = this.checkPlanStatus(planStatus, [CONST.PAUSED, CONST.SUSPENDED]);
        }
        return isButtonVisible;
    };

    _this.isCancelPlanButtonVisible = function(planStatus){
        var isButtonVisible = false;
        if (planStatus){
            isButtonVisible = this.checkPlanStatus(
                planStatus, [CONST.ACTIVE, CONST.RETRY, CONST.PAUSED, CONST.SUSPENDED]);
        }
        return isButtonVisible;
    };

    _this.timeStampToHuman = function(date) {
        return Utils.timeStampToHuman(date);
    };
}

angular
.module('payhqUIApp')
.component('customerDetails', {
    bindings: {
        customer: '<'
    },
    controller: customerDetails,
    controllerAs: 'customerDetails',
    templateUrl: 'view/vault/customer-details.html'
});