import angular from 'angular';
import CONSTANT_APP from 'constant/constant-app';
import 'component/paper-filter/paper-calendar-filter/paper-calendar-filter';
import 'filter/transactions-channel-filter';
const APP_MODULE = CONSTANT_APP.module;

function ageVerification($scope, $timeout, PaperToastService,
  AgeVerificationService, TimeService) {
    var _this = this;

    _this.isPaginationLoading = false;
    _this.isLoadingData = false;

    let paginationService = new window.PAYFIRMA.PaginationService(_this.list);

    function hasMoreData() {
        return paginationService.hasMoreData()
            && paginationService.nextCursor() < _this.list.length;
    }

    function updateAfterGetLogs(dataFromServer) {
        $timeout(function () {
            paginationService.updateAllData(dataFromServer);
            _this.isLoadingData = false;
            _this.list = paginationService.getAllData();
            if (window.PAYFIRMA.AgeVerificationDataService.exceedsDataLimit()) {
                PaperToastService.create('There were too many logs to search.' +
                    ' Please search with a smaller date range.', 'error');
            }
        });
    }

    function getAgeVerifications() {
        _this.isLoadingData = true;
        _this.searchParameters.limit = 20;
        window.PAYFIRMA.AgeVerificationDataService.getAllData({
            forceFetch: true,
            searchParameters: _this.searchParameters
        })
        .then(function(data) {
            updateAfterGetLogs(data);
        });
    }

    /**
     * Watch for filter changes
     */
    function watchFilters() {
        function updateSearchParameters(text, newValue) {
            _this.searchParameters[text] = newValue;
        }

        //watch calendar start date
        $scope.$watch('filtercalendar', function (newValue, oldValue) {
            //update search only if user manually change the calendar search
            if (newValue && oldValue) {
                updateSearchParameters('from_date', newValue.startDate.format('YYYY-MM-DD'));
                updateSearchParameters('to_date', newValue.endDate.format('YYYY-MM-DD'));
                getAgeVerifications();
            }
        });
    }

    _this.searchParameters = AgeVerificationService.createInitialSearchParameters();

    _this.dateToString = function(time) {
        return TimeService.epochToDateAndTime(time);
    };

    _this.getPaginationNext = function () {
        paginationService.getNext();
        _this.isPaginationLoading = false;
    };

    /**
     * Infinite scrolling, firing once user hits bottom of the page.
     */
    _this.lazyLoad = function () {
        //check if next page value and grab transactions
        if (hasMoreData()) {
            _this.isPaginationLoading = true;
            //prevent duplicate api calls when scrolling with 1s timeout
            _this.scrollTimeout = setTimeout(function () {
                _this.getPaginationNext();
            }, 0);
        }
    };

    _this.$onInit = function () {
        watchFilters();
        getAgeVerifications();
    };
}

angular
.module(APP_MODULE)
.component('ageVerification', {
    bindings: {
        list: '='
    },
    controller: ageVerification,
    controllerAs: 'ageVerification',
    templateUrl: 'view/age-verification/age-verification.html'
});