/* @flow */

import _ from 'lodash';

let ReceiptSetting = function (data : Object) {
    let _this = this;
    _.extend(_this, data);

    return _this;
};
export default ReceiptSetting;