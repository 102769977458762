'use strict';

function fileUploader($element, CSVFileReader) {
    let _this;
    _this = this;

    function initFileReader() {
        _this.fileElement = $element[0].querySelector('input[type="file"]');
        if (_this.fileElement) {
            CSVFileReader.readFile(_this.fileElement, CSVFileReader.CONTENT_TYPE.FILE,
                function (fileData) {
                    _this.onFileLoaded({
                        value: {
                            data: fileData,
                            type: _this.fileUploadType
                        }
                    });
                });
        }
    }

    window.PAYFIRMA.CommunicationService.onFileUploadEvent(function (fileUploadType) {
        initFileReader();
        if (fileUploadType === _this.fileUploadType) {
            CSVFileReader.openFile(_this.fileElement);
        }
    });
}

angular.module('payhqUIApp').component('fileUploader', {
    bindings: {
        onFileLoaded: '&',
        fileUploadType: '<'
    },
    controller: fileUploader,
    controllerAs: 'fileUploader',
    templateUrl: 'view/file-uploading/file-uploader.html'
});