import angular from 'angular';

/*
 API is used for redirect user to another view based on certain conditions
 for example, user does not have the permission to a particular view will be redirected
 to home or error page.

 to use this API, just add addRedirectHook: function (stateCheckRoutine,
 redirectRoutine, redirectDestination, doOtherChecks) to your app initialization routine.
 stateCheckRoutine is the function to be executed for checking whether or not a redirection is needed,
 redirectRoutine is the customize redirection logic provided by the user,
 redirectDestination is the path that the app redirect users to,
 doOtherChecks is the function to be executed in case user want to do additional work after redirection

 to expand this API to check other views, just modify the STATES_NEED_REDIRECTION global variable,
 or help externalize this variable

*/

angular
.module('payhqUIApp')
.factory('ErrorRedirectService', ['$rootScope', 'SessionService', '$location', function ($rootScope,
    SessionService, $location) {

    var STATES_NEED_REDIRECTION = ['#/manage-invoices'];

    function needsToCheckForRedirectToAnotherState(toUrl) {
        var nextHash = new window.URI(toUrl).hash();
        return _.find(STATES_NEED_REDIRECTION, function(state){
            return nextHash.indexOf(state)!==-1;
        });
    }

    function isAlreadyAtRedirectDestination(currentState) {
        var currentHash = new window.URI(window.location.href).hash();
        return currentHash.indexOf(currentState)!==-1;
    }

    function forwardRequest(next) {
        var uri = new window.URI(next);
        var hash = uri.hash();

        var queryObjectMap;

        //because angular does not follow the standard url convention
        //the format of the angular path is /#pathname?querystring

        if (hash.indexOf('#')!==-1) {
            hash = hash.substring(hash.indexOf('#')+1);
        }

        var startOfQueryString = hash.indexOf('?');
        if (startOfQueryString!==-1) {
            var queryString = hash.substring(startOfQueryString);
            queryObjectMap = window.URI.parseQuery(queryString);
            hash = hash.substring(0, startOfQueryString);
        }

        var cacheBuster = {
            cache : Math.round(new Date().getTime() / 1000)
        };
        //use cache buster to force a state transition if already on the same state
        //same state transition works intermittently on chrome but absolutely
        //not working on firefox
        if (queryObjectMap){
            $location.path(hash).search(_.extend(queryObjectMap, cacheBuster));
        } else {
            $location.path(hash).search(cacheBuster);
        }
    }

    function redirectIfNeeded(event,
                                    next,
                                    stateCheckCallback,
                                    redirectRoutine,
                                    redirectDestination) {

        if (needsToCheckForRedirectToAnotherState(next)){
            if ($rootScope.stateChangeCheck) {
                event.preventDefault();
                stateCheckCallback(function(){
                    forwardRequest(next);
                    $rootScope.stateChangeCheck = false;
                }, function(errorType){
                    redirectRoutine(errorType);
                    if (!isAlreadyAtRedirectDestination(redirectDestination)) {
                        //if already at destination, angular will make no state transition,
                        //and we will continue to do state check next time
                        $rootScope.stateChangeCheck = false;
                    }
                });

            } else {
                $rootScope.stateChangeCheck = true;
            }
        } else {
            $rootScope.stateChangeCheck = true;
        }

    }

    return {
        addRedirectHook: function (stateCheckRoutine,
                                    redirectRoutine,
                                    redirectDestination,
                                    doOtherChecks) {
            $rootScope.stateChangeCheck = true;

            $rootScope.$on('$locationChangeStart', function (event, next) {

                redirectIfNeeded(
                    event,
                    next,
                    stateCheckRoutine,
                    redirectRoutine,
                    redirectDestination);

                doOtherChecks();
            });
        }
    };
}]);