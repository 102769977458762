import angular from 'angular';

angular
.module('payhqUIApp')
.service('Image2datauriService', function () {
    return {
        toDataUri: function (imageSourceUrl, imageType, callback) {
            var img = new window.Image();

            img.setAttribute('crossOrigin', 'anonymous');

            img.onload = function () {
                let canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;

                let ctx = canvas.getContext('2d');
                ctx.drawImage(this, 0, 0);

                callback(canvas.toDataURL(imageType));
            };

            img.src = imageSourceUrl;
        }
    };
});
