import angular from 'angular';

function paperToast($scope, $element, $location, $timeout, PaperToastService, $sce, $rootScope) {
    let messageTypes = [
        'update',
        'error',
        'info'
    ];

    let _this = this;
    let messageType;
    let currentMessage;

    function closeToast(){
        _this.isToastVisible = false;
        $element.removeClass('appear');
    }

    function openToast(){
        messageType = PaperToastService.currentType;
        currentMessage = PaperToastService.currentMessage;
        _this.isToastVisible = true;
        _this.messageType = messageType;
        _this.isToastUpdate = _this.messageType === messageTypes[0];
        _this.isToastError = _this.messageType === messageTypes[1];
        _this.isToastInfo = _this.messageType === messageTypes[2];
        //addjust for width of message
        let toastWidth = $('toast').width();
        toastWidth = Math.abs(toastWidth) * -1;
        $('.toast').css('margin-left', toastWidth);
        $element.addClass('appear');

        let messageTypeIsNotError = messageType !== messageTypes[1];
        let messageTypeIsNotInfo = messageType !== messageTypes[2];
        if (messageTypeIsNotError && messageTypeIsNotInfo) {
            $timeout(function() {
                closeToast();
            }, 4000);
        }
    }

    _this.isToastVisible = false;

    _this.openToast = function() {
        openToast();
    };

    _this.closeToast = function() {
        closeToast();
    };

    _this.displayLiveDashboardData = () => {
        window.PAYFIRMA.CommunicationService.triggerDisplayLiveDashboardData();
        closeToast();
    };

    //update message and type
    $scope.$on('message.update', function() {

        if ($element.hasClass('appear')){
            closeToast();
            $timeout(function() {
                openToast();
            }, 1800);
        } else {
            openToast();
        }
    });
    //Service call for closing messgae
    $scope.$on('message.close', function() {
        _this.closeToast();
    });

    //Service call for closing messgae
    $scope.$on('message.closeErrors', function() {
        if (_this.messageType !== messageTypes[0]){
            closeToast();
        }
    });

    $scope.getHtmlMessage = function(){
        if (currentMessage) {
            return $sce.trustAsHtml(currentMessage);
        }
        return '';
    };

    $scope.clickHtmlToastMessage = function() {
        $rootScope.$broadcast(window.PAYFIRMA.CONSTANT.EVENT.HTML_TOAST_MESSAGE_CLICKED);
    };
}
angular.module('payhqUIApp').component('paperToast', {
    bindings: {
    },
    require: '?ngModel',
    controller: paperToast,
    controllerAs: 'paperToast',
    templateUrl: 'component/paper-toast/paper-toast.html'
});

//a hacky solution for inserting directives inside toast messages
angular.module('payhqUIApp').directive('compileTemplate', function($compile, $parse){
    return {
        link: function(scope, element, attr){
            var parsed = $parse(attr.ngBindHtml);
            function getStringValue() { return (parsed(scope) || '').toString(); }

            //Recompile if the template changes
            scope.$watch(getStringValue, function() {
                $compile(element, null, -9999)(scope);
                //The -9999 makes it skip directives so that we do not recompile ourselves
            });
        }
    };
});