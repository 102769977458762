import _ from 'lodash';

class ThemeLoadService {
    constructor() {
        this.currentHost = window.location.hostname;

        if (this.currentHost === 'staging.payfirma.com') {
            this.urlConfigData = 'https://staging-file.payfirma.com/';
        } else if (this.currentHost === 'dev-test.payfirma.com') {
            this.urlConfigData = 'https://dev-test-file.payfirma.com/';
        } else if (this.currentHost === 'test.payfirma.com' ||
            this.currentHost === 'test-demo.payfirma.com') {
            this.urlConfigData = 'https://test-file.payfirma.com/';
        } else if (this.currentHost === 'cibctest.payfirma.com') {
            this.urlConfigData = 'https://cibctest-file.payfirma.com/';
        } else if (this.currentHost === 'sandbox.payfirma.com') {
            this.urlConfigData = 'https://sandbox-file.payfirma.com/';
        } else if (this.currentHost === 'hq2.payfirma.com') {
            this.urlConfigData = 'https://file2.payfirma.com/';
        } else if (this.currentHost === 'dev.payfirma.com' ||
            this.currentHost === 'dev-api.payfirma.com' ||
            this.currentHost === 'dev-hq.payfirma.com' ||
            this.currentHost === 'dev-local-api.payfirma.com' ||
            this.currentHost === 'devlocal-hq.payfirma.com') {
            this.urlConfigData = 'https://dev-api.payfirma.com:6200/';
            
        } else {
            this.urlConfigData = 'https://file.payfirma.com/';
        }

        this.getSubdomain = (locationHref) => {
            var hostArray =
                /:\/\/(www[0-9]?\.)?(.[^/:]+)/i.exec(locationHref);
            if (hostArray.length > 2) {
                let fullUrl = hostArray[2];
                var domainArray = fullUrl.split('.');
                if (domainArray.length > 0) {
                    return domainArray[0];
                }
            }
            return '';
        };

        this.addStyle = (data) => {
            var head = document.head;
            var style = document.createElement('style');
            style.title = 'subdomain-theme';
            style.type = 'text/css';
            if (style.styleSheet) {
                style.styleSheet.cssText = data;
            } else {
                style.appendChild(document.createTextNode(data));
            }
            head.appendChild(style);
        };

        this.loadStyleSheetCssRules = (cssRules) => {
            _.forEach(cssRules, (cssRule) => {
                let elementSelector = cssRule.selectorText;
                _.forEach(cssRule.style, (stylePropertyKey) => {
                    let stylePropertyValue = cssRule.style[stylePropertyKey];

                    if (elementSelector === '[theme-image-favicon]') {
                        document.querySelector('link[rel="shortcut icon"]').href = stylePropertyValue.replace('"', '')
                        .replace('"', '').slice(4, -1);
                    }

                    if (elementSelector === '[theme-asset-title]') {
                        document.title = stylePropertyValue.replace('"', '')
                        .replace('"', '').replace('\'', '')
                        .replace('\'', '');
                    }
                });
            });
        };

        this.buildThemeUrl = (locationHref) => {
            return this.urlConfigData + 'theme/' + this.getSubdomain(locationHref);
        };

        this.loadStyle = (responseFromServer) => {
            this.addStyle(responseFromServer);
            let themeStyleSheet = document.styleSheets[document.styleSheets.length - 1];
            let themeStyleSheetCssRules = themeStyleSheet.cssRules;
            this.loadStyleSheetCssRules(themeStyleSheetCssRules);
        };
    }

    getTheme() {
        let _this = this;
        $.get(_this.buildThemeUrl(window.location.href), function (resp) {
            _this.loadStyle(resp);
        });
    }
}

//TODO: PLEASE REMOVE ME EVENTUALLY, CREATED SO THAT WE CAN UNIT TEST THIS PIECE BEFORE FIXING ALL ES6 UNIT TEST
//LOADING ISSUE
window.PAYFIRMA = window.PAYFIRMA || {};
window.PAYFIRMA.themeLoadService = ThemeLoadService;

export default ThemeLoadService;