'use strict';

window.PAYFIRMA = window.PAYFIRMA || {};

window.PAYFIRMA.PaginationService = function (data, limit) {
    let _this = this;
    window.PAYFIRMA.CURRENT_PAGINATION_SERVICE = _this;

    var allData = data || [];
    var pageLimit = window.PAYFIRMA.CONSTANT.UI_PAGINATION_LIMIT;
    if (limit) {
        pageLimit = limit;
    }

    var cursor = pageLimit;

    _this.getInitDataForDisplay = function () {
        if (cursor >= allData.length) {
            return allData.slice(0);
        } else {
            return allData.slice(0, cursor);
        }
    };

    _this.getNext = function () {
        if (cursor) {
            if (pageLimit >= allData.length - cursor) {
                let dataForDisplay = allData.slice(cursor);
                cursor = allData.length;
                return dataForDisplay;
            } else {
                let dataForDisplay = allData.slice(cursor, cursor + pageLimit);
                cursor = cursor + pageLimit;
                return dataForDisplay;
            }
        }
        return [];
    };

    _this.updateAllData = function (updatedData) {
        allData = updatedData;
        cursor = pageLimit;
    };

    _this.nextCursor = function () {
        return cursor;
    };

    _this.hasMoreData = function () {
        return cursor < allData.length;
    };

    _this.getPageLimit = function () {
        return pageLimit;
    };

    _this.getAllData = function () {
        return allData;
    };
    _this.unload = function () {
        window.PAYFIRMA.CURRENT_PAGINATION_SERVICE = undefined;
    };
};