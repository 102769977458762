import angular from 'angular';

angular
.module('payhqUIApp')
.service('UrlService', function ($location) {
    return {
        go: function(urlPath){
            $location.path(urlPath);
        }
    };
});