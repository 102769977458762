import angular from 'angular';

angular
.module('payhqUIApp')
.service('ResizeService', function ($window, $rootScope) {
    var _this = this;
    var observerCallbacks = [];
    var windowWidth = $window.innerWidth;
    var notifyObservers = function(){
        angular.forEach(observerCallbacks, function(callback){
            callback();
        });
    };

    _this.isCompact = false;

    if (windowWidth <= 1400) {
        _this.isCompact = true;
    }

    $window.onresize = function() {
        if ($window.innerWidth <= 1400) {
            $rootScope.$apply(function () {
                _this.isCompact = true;
            });
        } else {
            $rootScope.$apply(function () {
                _this.isCompact = false;
            });
        }
        notifyObservers();
    };

    return {
        registerObserverCallback: function(callback){
            observerCallbacks.push(callback);
        },
        isCompact: function(){
            return _this.isCompact;
        },
        toggleCompact: function(){
            _this.isCompact = !_this.isCompact;
            notifyObservers();
        }
    };
});