import angular from 'angular';
import ConfigurationService from 'service/configuration-service';
import UtilityService from 'service/utility-service';

/**
 * @ngdoc service
 * @name payhqUIApp.merchantApp.service:MerchantAccountRequestService
 * @description
 *
 * Service for handling merchantAccountRequests
 *
 */

angular
    .module('payhqUIApp')
    .service('MerchantAccountRequestService',
        function MerchantAccountRequestService(HttpClientService) {

            function getMerchantAccountRequests(searchOptions) {

                const cursor = searchOptions ? searchOptions.cursor : undefined;
                const limit = searchOptions ? searchOptions.limit : undefined;

                let param = {};

                if (limit) {
                    param.limit = limit;
                }
                if (cursor) {
                    param.after = cursor;
                }

                return HttpClientService.doGet(
                    ConfigurationService.getConfig().merchantServiceApi + 'account-request', param)
                    .then(function (getMerchantAccountRequestsResponse) {
                        let returnedCursor;

                        if (getMerchantAccountRequestsResponse.data.paging) {
                            returnedCursor = getMerchantAccountRequestsResponse.data.paging.cursor;
                        }

                        let entities = _.map(getMerchantAccountRequestsResponse.data.entities, function (merchantResponse) {
                            return merchantResponse;
                        });

                        return new window.PAYFIRMA.Paging(returnedCursor, entities);
                    }).catch(function(e){
                        throw UtilityService.parseErrorMessage(e);
                    });
            }

            return {
                getNext: function (searchOptions) {
                    return getMerchantAccountRequests({
                        limit: window.PAYFIRMA.CONSTANT.PAGINATION_LOAD_BATCH_SIZE,
                        cursor: searchOptions ? searchOptions.cursor : undefined
                    });
                },
                getInitialData: function () {
                    return getMerchantAccountRequests({
                        limit: window.PAYFIRMA.CONSTANT.PAGINATION_INITIAL_LOAD_BATCH_SIZE
                    });
                },
                deleteMerchantAccountRequest: function (requestId) {
                    return HttpClientService.doDelete(
                        ConfigurationService.getConfig().merchantServiceApi + 'account-request/' + requestId
                    );
                },
                getMerchantAccountRequest: function (requestId) {
                    return HttpClientService.doGet(
                        ConfigurationService.getConfig().merchantServiceApi + 'account-request/' + requestId)
                        .then(function (merchantResponse) {
                            return merchantResponse.data;
                        })
                        .catch(function(e){
                            throw UtilityService.parseErrorMessage(e);
                        });
                }
            };
        });