import angular from 'angular';
import 'script/app-init';
import 'service/utils';
import 'service/customer-service';
import 'service/heap-service';
import 'script/constants-angular';

function customerInfo($route, Utils, CustomerService, TransactionService, PaperToastService, HeapService, HeapMessage) {
    var _this;
    _this = this;
    _this.countryList = [];
    _this.provinceList = [];
    _this.oldValues = {
        country : '',
        countryMessage : '',
        province : '',
        provinceMessage : ''
    };
    const oldValueMessage = 'Old value : ';

    function showLoader() {
        _this.isLoading = true;
    }

    function hideLoader() {
        _this.isLoading = false;
    }

    function refreshCurrentView() {
        $route.reload();
    }

    function goToCustomerVaultProfile(customerId) {
        Utils.go('/customer-vault/edit/' + customerId);
    }

    function goToCustomerVault(){
        Utils.go('/customer-vault');
    }

    function isNewCustomer(){
        if (_this.customer && _this.customer.lookup_id){
            return false;
        }
        return true;
    }

    _this.cancel = function () {
        goToCustomerVault();
    };

    _this.deleteCustomerProfileEnabled = false;
    _this.deleteCustomerProfile = function (customer) {
        _this.deleteCustomerProfileEnabled = true;
        window.PAYFIRMA.CommunicationService.triggerDeleteCustomerProfileModalOpen(customer);
    };

    function updateExistingCustomer(existingCustomer){

        // Update only customer information
        var updateData = {
            lookup_id: existingCustomer.lookup_id,
            email:  existingCustomer.email,
            first_name : existingCustomer.first_name,
            last_name : existingCustomer.last_name,
            company : existingCustomer.company,
            bcc_emails : existingCustomer.bcc_emails,
            telephone : existingCustomer.telephone,
            address1 : existingCustomer.address1,
            address2 : existingCustomer.address2,
            city : existingCustomer.city,
            province : existingCustomer.province,
            country : existingCustomer.country,
            postal_code : existingCustomer.postal_code,
            custom_id : existingCustomer.custom_id
        };

        // using old country value to update if dropdown not selected
        if (_this.oldValues.country && !_this.customer.country){
            updateData.country = _this.oldValues.country;
        }

        // using old province value to update if dropdown not selected
        if (_this.oldValues.province && !_this.customer.province){
            updateData.province = _this.oldValues.province;
        }

        return CustomerService.updateExistingCustomer(updateData);

    }

    function createNewCustomer(newCustomer){
        return CustomerService.createNewCustomer(newCustomer);
    }

    function save(customer) {
        if (isNewCustomer()) {
            return createNewCustomer(customer);
        } else {
            return updateExistingCustomer(customer);
        }
    }
    _this.save = function () {
        var errorMessage = 'Failed to save customer information';
        showLoader();
        let isCreatingNewCustomer = isNewCustomer();
        if (_this.form && _this.form.$valid) {
            return save(_this.customer)
                .then(function (response) {
                    hideLoader();
                    if (response && response.error && response.error.data) {
                        errorMessage = '';
                        angular.forEach(response.error.data.errors, function(value){
                            errorMessage += value.message;
                        });
                        PaperToastService.create(errorMessage, 'error');
                    } else if (response && response.lookup_id) {
                        _this.customer = response;
                        window.PAYFIRMA.CustomerDataService.setNeedToUpdate(true);
                        if (isCreatingNewCustomer) {
                            HeapService.heapTrack(HeapMessage.TYPE_CUSTOMER_VAULT, HeapMessage.MESSAGE_NEW_PROFILE);
                            PaperToastService.create('Customer created', 'update');
                            const customerLookupId = _this.customer.lookup_id;
                            goToCustomerVaultProfile(customerLookupId);
                        } else {
                            PaperToastService.create('Updated customer information', 'update');
                            refreshCurrentView();
                        }
                    } else {
                        PaperToastService.create('Unknown error', 'error');
                        goToCustomerVault();
                    }
                }, function () {
                    hideLoader();
                    PaperToastService.create(errorMessage, 'error');
                });
        } else {
            hideLoader();
            PaperToastService.create('Missing required field', 'error');
        }
    };

    function initializeCountrySelections () {
        _this.provinceList.push({
            key: 'Select a Province/State',
            value: null
        });
        _this.countryList = TransactionService.getCountries();
    }

    function resetOldValuesForCountry (forCountry) {
        if (forCountry){
            _this.oldValues.country = '';
            _this.oldValues.countryMessage = '';
        } else {
            _this.oldValues.province = '';
            _this.oldValues.provinceMessage = '';
        }
    }

    _this.onChangeCountry = function (selectedCountry) {
        resetOldValuesForCountry(true);
        if (!selectedCountry){
            _this.customer.province=null;
            return;
        }
        _this.provinceList = TransactionService.getProvinces(selectedCountry);
    };

    _this.onChangeProvince = function () {
        resetOldValuesForCountry(false);
    };

    initializeCountrySelections();

    _this.$onInit = function(){
        _this.isThisNewCustomer = isNewCustomer();
        if (!_this.isThisNewCustomer){
            const resultFound = TransactionService.getCountryProvinceCode(_this.customer, _this.onChangeCountry);
            if (!resultFound.country){
                TransactionService.notFoundOldValues(_this.oldValues, _this.customer, oldValueMessage);
            }
            if (!resultFound.province){
                TransactionService.notFoundOldValues(_this.oldValues, _this.customer, oldValueMessage, false);
            }
        }
    };
}

export default angular.module('payhqUIApp').component('customerInfo', {
    bindings: {
        customer: '<'
    },
    controller: customerInfo,
    controllerAs: 'customerInfo',
    templateUrl: 'view/vault/customer-info/customer-info.html'
}).name;