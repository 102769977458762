'use strict';

window.PAYFIRMA = window.PAYFIRMA || {};

window.PAYFIRMA.SUBSCRIPTION_STATUS = {
    SUSPENDED: 'SUSPENDED',
    RETRY: 'RETRY',
    PAUSED: 'PAUSED',
    ACTIVE: 'ACTIVE',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED'
};
