/* @flow */

import angular from 'angular';
import CONSTANT_APP from 'constant/constant-app';
import CONSTANT_COMPONENT from 'constant/constant-component';

import 'service/paper-calendar-filter-service';

import 'component/paper-filter/paper-filter-min-max/paper-filter-min-max';
import 'component/paper-filter/paper-channel-filter/paper-channel-filter';
import 'component/paper-filter/paper-calendar-filter/paper-calendar-filter';
import 'component/paper-filter/paper-advanced-search/paper-advanced-search';
import 'filter/min-max-filter';

import _ from 'lodash';
import 'filter/transactions-channel-filter';

const APP_MODULE = CONSTANT_APP.module;
const COMPONENT_TRANSACTIONS = CONSTANT_COMPONENT.transactions;

function transactions($scope, $http, $log, $timeout, $route,
    SessionService, UrlService, TransactionService,
    PaperToastService, TransactionReceiptService,
    Utils, HeapService, PaperCalendarFilterService, EFTService) {

    var _this = this;
    _this.customerList = [];
    _this.sendEmailList = [];
    _this.showReceiptModal = false;
    _this.sendTooEmail = '';
    _this.currentTransactionId = '';
    _this.findNewTransactions = null;

    _this.newTransactionsAvailable = false;
    _this.isPaginationLoading = false;
    _this.isLoadingData = true;

    _this.exportButtonClicked = false;

    _this.transactionStatus = '';

    _this.advancedSearchedParmas = [];

    let paginationService = new window.PAYFIRMA.PaginationService(_this.list);

    function getTransactionsForDisplay(){
        if (!$route.current || !$route.current.params || !$route.current.params.eft_type){
            return paginationService.getAllData();
        }
        let eftType;
        switch ($route.current.params.eft_type) {
            case 'credit':
                eftType = 'EFT_CREDIT';
                break;
            case 'debit':
                eftType = 'EFT_DEBIT';
                break;
            default:
                eftType = '';
                break;
        }
        return _.filter(paginationService.getAllData(),(transaction)=>transaction.transaction_type===eftType);
    }
    _this.transactionsForDisplay = getTransactionsForDisplay() || [];

    function needOverrideInitialParameters() {
        return _this.customerEmail || (_this.channels && _this.channels.length);
    }

    function createSearchParameters(customerEmail, channels, customerLookupId) {
        var searchParameters = {};

        if (customerEmail) {
            searchParameters.email_address = customerEmail;
        }

        if (customerLookupId) {
            searchParameters.customer_lookup_id = customerLookupId;
        }

        if (channels && channels.length){
            searchParameters.channel = channels;
        }

        return searchParameters;
    }

    // If customer email has been entered in the route, set email search parameter as default
    if (needOverrideInitialParameters()) {
        _this.searchParameters = createSearchParameters(_this.customerEmail, _this.channels, _this.customerLookupId);
    } else {
        _this.searchParameters = TransactionService.createInitialSearchParameters();
    }

    _this.HEAP_EVENT_NAME = window.PAYFIRMA.CONSTANT.HEAP_EVENT.TRANSACTIONS;

    /**
     * Check tablet transactions for inventory item list
     * @param {object} transactionObject - transaction object
     */
    function getInventoryItems(transactionObject) {
        const source = transactionObject.transaction_source;
        const id = transactionObject.id;
        let price;
        let discount;
        let dallarValue;
        //if transaction is from tablet and has not been set after
        //TODO: delete this in the future when we drop API V1
        if (!transactionObject.inventory && source === 'TABLET_POS' || source === 'MOBILE') {
            //append new properties
            transactionObject.showTransDetails = true;
            transactionObject.inventory = [];
            transactionObject.loadingItems = true;
            transactionObject.itemsSummery = {
                subtotal: null,
                discount: null
            };
            //get the transaction objects
            //TODO: should we move this to API V2?
            TransactionService.getInventoryItems(id).then(
                function (response) {
                    transactionObject.loadingItems = false;
                    //calculate and push item summery
                    for (var index = 0; index < response.length; ++index) {
                        price = parseInt(response[index].price, 10);
                        discount = parseInt(response[index].discount, 10);
                        transactionObject.itemsSummery.subtotal = transactionObject.itemsSummery.subtotal + price;
                        if (discount) {
                            dallarValue = (discount / 100) * price;
                            response[index].discount = dallarValue;
                            transactionObject.itemsSummery.discount = transactionObject.itemsSummery.discount +
                                dallarValue;
                        }
                        transactionObject.inventory.push(response[index]);
                    }
                }
            );
        } else {
            transactionObject.showTransDetails = false;
        }
    }

    _this.toggleShowTransactionDetails = function (transactionObject) {
        let customerLookupId = _.get(transactionObject, 'customer_lookup_id', null);

        if (customerLookupId === null) {
            let originalId = _.get(transactionObject,'original_id', null);
            while (originalId !== null) {
                customerLookupId = _.get(_.find(this.filteredTransactionList, { id: originalId }), 'customer_lookup_id', null);
                originalId = _.get(_.find(this.filteredTransactionList, { id: originalId }),'original_id', null);
            }
        }
        transactionObject.custom_id_customer = Utils.getCustomerId(this.allCustomers,
            customerLookupId ? { lookup_id: customerLookupId } : null);
        transactionObject.isVisible = !transactionObject.isVisible;
        getInventoryItems(transactionObject);
    };

    function hasMoreData() {
        return paginationService.hasMoreData()
            && paginationService.nextCursor() < _this.filteredTransactionList.length;
    }

    _this.getPaginationNext = function () {
        paginationService.getNext();
        _this.isPaginationLoading = false;
    };

    /**
     * Infinite scrolling, firing once user hits bottom of the page.
     */
    _this.lazyLoad = function () {
        //check if next page value and grab transactions
        if (hasMoreData()) {
            _this.isPaginationLoading = true;
            //prevent duplicate api calls when scrolling with 1s timeout
            _this.scrollTimeout = setTimeout(function () {
                _this.getPaginationNext();
            }, 0);
        }
    };

    function updateAfterGetTransactions(dataFromServer) {
        $timeout(function () {
            paginationService.updateAllData(dataFromServer);
            _this.transactionsForDisplay = getTransactionsForDisplay();
            _this.isLoadingData = false;
            if (window.PAYFIRMA.TransactionDataService.exceedsDataLimit()) {
                PaperToastService.create('There were too many transactions to search.' +
                    ' Please search with a smaller date range or more specific keyword.', 'error');
            }
        });
    }

    function getTransactions() {
        _this.isLoadingData = true;
        window.PAYFIRMA.TransactionDataService.getAllData({
            forceFetch: true,
            searchParameters: _this.searchParameters
        })
            .then(function (data) {
                updateAfterGetTransactions(data);
            });
    }

    /**
     * Watch for filter changes
     */
    function watchFilters() {

        function updateSearchParameters(text, newValue) {
            _this.searchParameters[text] = newValue;
        }

        //watch calendar start date
        $scope.$watch('filtercalendar', function (newValue, oldValue) {
            //update search only if user manually change the calendar search
            if (newValue && oldValue) {
                updateSearchParameters('from_date', newValue.startDate.format('YYYY-MM-DD'));
                updateSearchParameters('to_date', newValue.endDate.format('YYYY-MM-DD'));
                getTransactions();
            }
        });

        //watch transaction status
        $scope.$watch('transactions.transactionStatus', function (newValue, oldValue) {
            //update search only if user manually change the calendar search
            if (newValue !== oldValue) {
                updateSearchParameters('transaction_status', newValue);
                getTransactions();
            }
        });

        function heapServiceTrackInput(inputName, inputValue) {
            return HeapService.trackInput(inputName, inputValue);
        }

        $scope.$watch('filteramountmin', function (newValue, oldValue) {
            if (oldValue && newValue !== oldValue) {
                heapServiceTrackInput('heap-transactions-amount-min', newValue);
            }
        });
        //watch max amount
        $scope.$watch('filteramountmax', function (newValue, oldValue) {
            if (oldValue && newValue !== oldValue) {
                heapServiceTrackInput('heap-transactions-amount-max', newValue);
            }
        });

        //watch advanced search
        $scope.$watch('advancedsearch', (newValue, oldValue) => {
            if (newValue !== oldValue && !_.isEmpty(newValue)) {
                let combine = [];
                _this.advancedSearchedParmas = []; // clear advanced search params
                for (let i = 0; i < newValue.keywords.length; ++i) {
                    combine.push(window.btoa(newValue.keywords[i]) + '|' + newValue.fields[i]);
                    _this.advancedSearchedParmas.push({ 'name': newValue.names[i], 'keyword': newValue.keywords[i] });
                }
                _this.searchParameters.advanced = combine.toString();
                delete _this.searchParameters.from_date;
                delete _this.searchParameters.to_date;
                document.getElementById('date-range-filter').value = '';
                getTransactions();
            }
        });
    }

    /*
     * Remove advanced search parameter and set the date range filter
     */
    _this.setToDefault = () => {
        _this.searchParameters.from_date = window.PAYFIRMA.CONSTANT.DEFAULT_CALENDAR_START_DATE.format('YYYY-MM-DD');
        _this.searchParameters.to_date = window.PAYFIRMA.CONSTANT.DEFAULT_CALENDAR_END_DATE.format('YYYY-MM-DD');
        delete _this.searchParameters.advanced;
        if (document.getElementById('date-range-filter')){
            document.getElementById('date-range-filter')
            .value = _this.searchParameters.from_date + ' - ' + _this.searchParameters.to_date;
        }
        getTransactions();
    };

    /**
     *  show send receipt functions
     */
    _this.showSendReceipt = function (transactionObject) {
        _this.currentTransactionId = transactionObject.id;
        if (transactionObject.email) {
            _this.sendTooEmail = transactionObject.email;
        }
        _this.showReceiptModal = true;
    };

    /**
     *  hide send receipt functions
     */
    _this.hideSendReceipt = function () {
        _this.sendTooEmail = '';
        _this.sendEmailList = [];
        _this.showReceiptModal = false;
        _this.currentTransactionId = '';
    };

    /**
     * validate email address for send list
     * @param {string} email - email string to validate
     */
    function validateEmail(email) {
        var regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    }

    /**
     * check for duplication in email list
     * @param {string} email - email string to check
     */
    function isDuplicateEmail(email) {
        return _this.sendEmailList.indexOf(email) > -1;
    }

    /**
     *  add email name to list
     */
    _this.addToEmailList = function (keyEvent) {
        if (keyEvent.which === 32 || keyEvent.which === 13
            && (validateEmail(_this.sendTooEmail) && !isDuplicateEmail(_this.sendTooEmail))) {
            _this.sendEmailList.push(_this.sendTooEmail);
            _this.sendTooEmail = '';
        }
    };
    /**
     * send recipt to email address on the email list
     *
     */
    _this.sendReceipt = function () {
        let transId;
        let sendReceiptRequest;
        _this.sendEmailList = _this.sendEmailList || [];
        if (validateEmail(_this.sendTooEmail) && !isDuplicateEmail(_this.sendTooEmail)) {
            _this.sendEmailList.push(_this.sendTooEmail);
            _this.sendTooEmail = '';
        }
        if (_this.sendEmailList.length >= 1) {
            _this.sending = true;
            transId = _this.currentTransactionId;

            sendReceiptRequest = {
                emails: _this.sendEmailList
            };
            //grab new transactions list
            return TransactionService.sendReceipt(transId, sendReceiptRequest)
                .then(function () {
                    _this.sending = false;
                    PaperToastService.create('Receipt sent.', 'update');
                    _this.hideSendReceipt();
                }).catch(function (e) {
                    _this.sending = false;
                    PaperToastService.create('Error sending receipt.', 'error');
                    throw e;
                });
        } else {
            PaperToastService.create('Please enter a valid email.', 'error');
        }
    };

    /**
     * Print transaction receipts
     * @param {Number} transId - Id of transaction to print
     */
    _this.printReceipt = function (transId) {
        TransactionReceiptService.printReceipt(transId);
    };

    /**
     *  Can a transaction be refunded
     */
    _this.refundPossible = function (transactionObject) {
        var refundPossible = true;
        var transactionSource = transactionObject.transaction_source;
        var transStatus = Utils.enumToHuman(transactionObject.transaction_result);
        var amountRefunded = transactionObject.amount_refunded;
        var amount = transactionObject.amount;

        if ([window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE,
            window.PAYFIRMA.TRANSACTION_TYPE.REFUND,
            window.PAYFIRMA.TRANSACTION_TYPE.NAKED_REFUND,
            window.PAYFIRMA.TRANSACTION_TYPE.VOID]
            .includes(transactionObject.transactionType) ||
            transStatus !== 'Approved') {
            refundPossible = false;
        }
        if (amountRefunded === amount) {
            refundPossible = false;
        }
        if (transactionSource === 'TABLET_POS') {
            refundPossible = false;
        }
        if (!SessionService.checkUserPermission('VT_ProcessRefund')) {
            refundPossible = false;
        }
        if (transactionSource === 'EFT') {
            refundPossible = false;
        }

        return refundPossible;
    };


    /**
     *  Can a transaction be captured
     */
    _this.capturePossible = function (transactionObject) {
        var isCapturePossible = false;

        if (transactionObject.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.AUTHORIZE
            && transactionObject.transaction_result.toUpperCase() === 'APPROVED'
            && transactionObject.captured === false
            && SessionService.checkUserPermission('VT_ProcessCapture')) {
            isCapturePossible = true;
        }

        return isCapturePossible;
    };

     /**
     *  Function to check if transaction can be cancelled
     */
    _this.cancelPossible = function (transactionObject) {
        let isCancelPossible = false;
        if (transactionObject.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.EFT_CREDIT
            && transactionObject.transaction_result.toUpperCase() === 'PENDING'
            && SessionService.checkUserPermission('EFTMaster')) {
            isCancelPossible = true;
        }
        return isCancelPossible;
    };

    _this.isEFTTransaction = function (transactionObject) {
        return transactionObject.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.EFT_CREDIT ||
                transactionObject.transactionType === window.PAYFIRMA.TRANSACTION_TYPE.EFT_DEBIT;
    };

    _this.isUpdatableEFTTransaction = function (transactionObject) {
        return this.isEFTTransaction(transactionObject) &&
                transactionObject.transaction_result.toUpperCase() === 'PENDING';
    };

    /**
     * Check to see if card type is a card or other
     * @param {string} cardType - String type of card
     */
    _this.isCard = function (cardType) {
        var isCard = true;
        if (cardType === 'Cash' || cardType === 'Check') {
            isCard = false;
        }
        return isCard;
    };

    /**
     * When to show customer data
     */
    _this.showCustomerData = function (transactionObject) {
        var showCusomter = false;
        var transactionField;
        var required = ['company', 'address1', 'city', 'province', 'country', 'postalcode', 'telephone', 'first_name',
            'last_name', 'email'];
        for (var index = 0; index < required.length; ++index) {
            transactionField = required[index];
            if (transactionObject[transactionField] !== null && transactionObject[transactionField] !== '' &&
                transactionObject[transactionField] !== undefined) {
                showCusomter = true;
            }
        }
        return showCusomter;
    };

    /**
     * When to show customer data
     */
    _this.showAddressData = function (transactionObject) {
        var showAddress = false;
        var transactionField;
        var required = ['address1', 'city', 'province', 'country', 'postalcode', 'company'];
        for (var index = 0; index < required.length; ++index) {
            transactionField = required[index];
            if (transactionObject[transactionField] !== null && transactionObject[transactionField] !== '' &&
                transactionObject !== undefined) {
                showAddress = true;
            }
        }
        TransactionService.getCountryProvinceName(transactionObject);
        return showAddress;
    };

    /**
     *  Do refund
     */
    _this.refundTransaction = function (transactionId, sourceType) {
        if (sourceType && sourceType === 'CARD_TERMINAL') {
            UrlService.go('cardterminal/refund/' + transactionId);
        } else {
            UrlService.go('terminal/refund/' + transactionId);
        }
    };

    /**
     *  Do capture
     */
    _this.captureTransaction = function (transactionId, sourceType) {
        if (sourceType && sourceType === 'CARD_TERMINAL') {
            UrlService.go('cardterminal/capture/' + transactionId);
        } else {
            UrlService.go('terminal/capture/' + transactionId);
        }
    };

    /**
     *  function to cancel outgoing eft request
     */
    _this.cancelRequestTransaction = function (transactionId) {
        _this.isLoadingData = true;
        EFTService.cancelEFTRequest(transactionId).then((result)=>{
            if (result){
                _this.isLoadingData = false;
                let cancelledTransaction = _.find(_this.transactionsForDisplay,(transactionsObj) => transactionsObj.id === transactionId);
                if (result.transaction_success) {
                    cancelledTransaction.transaction_result = result.transaction_result;
                    PaperToastService.create('EFT request cancelled successfully', 'update');
                }
            }
        },
        function (error) {
            _this.isLoadingData = false;
            const message = 'EFT request cancellation failed';
            $log.error(message, error);
            PaperToastService.create(message, 'error');
        });
    };

    /**
     *  function to update eft transaction status
     */
    _this.updateEFTTransactionStatus = function (transactionId) {
        _this.isLoadingData = true;
        EFTService.updateStatus(transactionId).then(()=>{
            PaperToastService.create('Successfully updated', 'update');
            TransactionService.getTransaction(transactionId)
                .then(result => {
                    if (result) {
                        let updatedTransaction = _.find(_this.transactionsForDisplay,(transactionsObj) => transactionsObj.id === transactionId);
                        updatedTransaction.transaction_result = result.transaction_result;
                        if (updatedTransaction.transaction_result === 'DECLINED') {
                            updatedTransaction.transaction_message = result.transaction_message;
                        }
                    }
                })
                .catch(() => {
                    PaperToastService.create('Failed to update screen. Please reload to see the updated transaction', 'error');
                });
        })
        .catch(error => {
            PaperToastService.create(error.message, 'error');
        })
        .finally(() => {
            _this.isLoadingData = false;
        });
    };

    _this.enumToHuman = function (enumString) {
        return Utils.enumToHuman(enumString);
    };

    /**
     *  change dollar values to bool
     */
    _this.dollarToBool = function (dollarValue) {
        var value = true;
        if (!dollarValue || dollarValue === '0.00' || dollarValue === '0' || dollarValue === '') {
            value = false;
        }
        return value;
    };

    _this.export = function (calendarStart, calendarEnd, amountMin, amountMax, channel) {
        _this.exportButtonClicked = true;

        let parameters = {};

        if (channel.transaction_source) {
            parameters.channel = _.join(channel.transaction_source);
        }

        parameters.from_date = calendarStart;
        parameters.to_date = calendarEnd;
        parameters.min_amount = amountMin;
        parameters.max_amount = amountMax;
        parameters.transaction_status = _this.transactionStatus;
        parameters.email_address = _.find(_this.advancedSearchedParmas, { 'name': 'email' });
        parameters.advanced = _this.searchParameters.advanced;
        return TransactionService.exportTransactionListCSV(parameters)
            .then(function(){
                _this.exportButtonClicked = false;
            })
            .catch(function (error) {
                _this.exportButtonClicked = false;
                let errorMessage = 'Could not export File';
                if (error && error.message) {
                    errorMessage = error.message;
                }
                PaperToastService.create(errorMessage, 'error');
                throw error;
            });

    };

    // Get Transactions with Search Parameters
    _this.loadNewTransactions = function () {
        _this.isLoadingData = true;
        _this.newTransactionsAvailable = false;
        return window.PAYFIRMA.TransactionDataService.getNext({
            backwards: true,
            searchParameters: _this.searchParameters
        })
        //always reload from server when enters customer vault
            .then(function (data) {
                updateAfterGetTransactions(data);
                return undefined;
            })
            .catch(function (error) {
                _this.isPaginationLoading = false;
                $log.error(error);
                throw error;
            });
    };

    function checkForNewTransactions() {
        if (!_this.isLoadingData) {
            window.PAYFIRMA.TransactionDataService.hasMorePreviousData({
                searchParameters: _this.searchParameters
            })
                .then(function (hasNewTransactions) {
                    _this.newTransactionsAvailable = hasNewTransactions;
                });
        }
    }

    // Initialize
    _this.$onInit = function () {
        watchFilters();

        getTransactions();

        _this.findNewTransactions = setInterval(function () {
            checkForNewTransactions();
        }, 15000);

        if (_this.customerEmail) {
            _this.searchKey = _this.customerEmail;
        }
    };

    $scope.$on('$destroy', function () {
        if (_this.findNewTransactions) {
            clearInterval(_this.findNewTransactions);
        }

        if (paginationService) {
            paginationService.unload();
        }

        window.PAYFIRMA.TransactionDataService.unload();
    });

    $scope.$on(window.PAYFIRMA.CONSTANT.EVENT.HTML_TOAST_MESSAGE_CLICKED, function () {
        PaperCalendarFilterService.updateCalenderFilterDateRange();
    });

}
angular
.module(APP_MODULE)
.component(COMPONENT_TRANSACTIONS, {
    bindings: {
        list: '=',
        planId: '<',
        channels: '<',
        customerLookupId: '<',
        customerEmail: '<'
    },
    controller: transactions,
    controllerAs: 'transactions',
    templateUrl: 'view/transactions/transactions.html'
});
