import ValidatorLocator from 'service/validators/validator-locator';
import FormatterLocator from 'service/formatters/formatter-locator';
let ControllerProcessor = {
    process: function(controller, attr){
        var formatter = attr.formatter;
        if (attr.formatter) {
            controller.$formatters.push(function (modelValue) {

                var validator = attr.validator;
                if (validator) {
                    var errorKey = attr.name;
                    if (!errorKey) {
                        errorKey = 'general-error';
                    }
                    controller.$setValidity(ValidatorLocator[validator](errorKey, modelValue));
                }
                return FormatterLocator[formatter];
            });
        }
    }
};
export default ControllerProcessor;