import angular from 'angular';
/**
 * @ngdoc service
 * @name payhqUIApp.service:Html2ImageService
 * @description
 *
 * Service for converting html to image (PNG by default)
 *
 */
angular
.module('payhqUIApp')
.service('Html2ImageService', function ($q) {

    return {
        toImage: function (element) {

            //a problem with current version of html2canvas, seems like it uses body height to capture screenshot
            //to fix the overflow problem which actually makes the situation worse.
            //to fix it, set body height to fit the entire overflow element
            //and reset it after rendering.
            //hope they can fix it in future versions

            //set body to fit the overflow element
            var body = $('body'),
                originalBodyHeight = body.height();
            body.height(originalBodyHeight+element[0].scrollHeight);

            var deferred = $q.defer();

            window.html2canvas(element, {

                onrendered: function (canvas) {
                    //reset body to original height
                    body.height(originalBodyHeight);

                    deferred.resolve(canvas);
                }
            });

            return deferred.promise;
        }
    };
});