import angular from 'angular';

/**
 * @ngdoc service
 * @name payhqUIApp.transactionApp.service:TransactionReceiptService
 * @requires $modal
 * @description
 *
 * Service for handling transaction receipt behaviours
 *
 */
angular
.module('transactionApp')
.service('TransactionReceiptService', function TransactionReceiptService(Restangular, $log) {

    /**
     * Opent print window for user
     * @param {obj} receiptContent - HTML page for receipt
     */
    function openPrintWindow(receiptContent) {
        if (/chrome/i.test(window.navigator.userAgent)) {
            var newWindow =
                window.open('about:blank', 'name',
                    'height=455,width=760,location=no,menubar=no,'+
                    'resizable=no,scrollbars=no,status=no,titlebar=no,toolbar=no');
            newWindow.document.body.innerHTML = receiptContent;
            newWindow.document.close();
            newWindow.focus();
            newWindow.print();
        } else {
            var fragment = window.document.createDocumentFragment();
            fragment.innerHTML = receiptContent;
            if (window.document.getElementById('receiptFrame') !== null) {
                window.document.getElementById('receiptFrame')
                .parentNode.removeChild(window.document.getElementById('receiptFrame'));
            }
            var receiptFrame = window.document.createElement('IFRAME');
            receiptFrame.setAttribute('id', 'receiptFrame');
            receiptFrame.src = 'about:blank';
            receiptFrame.style.position = 'absolute';
            receiptFrame.style.top = '0px';
            receiptFrame.style.left = '0px';
            receiptFrame.style.width = '0px';
            receiptFrame.style.height = '0px';
            if (receiptFrame.addEventListener) {
                receiptFrame.addEventListener('load', function () {
                    var doc = receiptFrame.contentDocument || receiptFrame.contentWindow.document;
                    doc.body.innerHTML = fragment.innerHTML;
                    window.document.getElementById('receiptFrame').contentWindow.print();
                }, false);
            } else if (receiptFrame.attachEvent) {
                receiptFrame.attachEvent('onload', function () {
                    window.parent.receiptFrame.focus();
                    var doc = receiptFrame.contentDocument || receiptFrame.contentWindow.document;
                    doc.body.innerHTML = fragment.innerHTML;
                    window.document.getElementById('receiptFrame').contentWindow.print();
                });
            }
            window.document.body.appendChild(receiptFrame);
        }
    }

    return {


        /**
         * Print transaction receipts
         * @param {Number} transId - Id of transaction to print
         */
        printReceipt: function (transId) {

            return Restangular.one('transactions', transId).one('send_receipt').get()
                .then(
                    function (response) {
                        // Once we got receipt back open the print window
                        openPrintWindow(response);
                        return response;
                    }, function () {
                        $log.error('tempMSG: There was a problem printing recipt');
                        return undefined;
                    }
                );
        }
    };

});