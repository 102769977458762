import angular from 'angular';

/**
 * @ngdoc service
 * @name payhqUIApp.customerApp.service:CustomerLookupService
 * @requires $sce
 * @description
 *
 * Service for handling customer lookup behaviours
 *
 */
angular
.module('customerApp')
.factory('CustomerLookupService', function ($sce) {

    var self = this;
    self.autocompleteAllowItemDelete = true;
    self.customerLookupList = [];
    self.customerSelectedList = [];


    /**
     * Customer lookup autocomplete
     *
     * @param {String} term - Search term used for lookup
     * @return {Array} results - List of results found based on search terms
     */
    function lookupCustomer(term) {

        var searchTerm = term.toLowerCase().trim();
        var results = [];
        var displayValue = '';

        // Find first 10 customers that start with `term`.
        _.each(self.customerLookupList, function (customerObj) {
            var firstname = customerObj.getFirstName();
            var lastname = customerObj.getLastName();
            var email = customerObj.getEmail();
            var customerInfo = customerObj.getInfo();
            _.each(customerInfo, function (value) {
                if (value && String(value).toLowerCase().indexOf(searchTerm) === 0) {
                    displayValue = firstname + ' ' + lastname + '\n' + email;
                    if (results.length <= 10) {
                        results.push({
                            label: displayValue,
                            obj: customerObj,
                            value: email
                        });
                    }
                }
            });
        });

        return results;
    }

    /**
     * Add highlight to search results
     *
     * @param {String} str - String to replace
     * @param {String} term - Search term to highlight
     * @return {String} strReplaced - Modified search entry with highlighted term
     */
    function lookupTermHighlight(str, term) {
        return str.replace(new RegExp('(' + term + ')', 'gi'), '<span class="highlight">$1</span>');
    }

    /**
     * Validate email value
     *
     * @param {String} input - Input email to validate
     * @return {String} True if input is a valid email. False otherwise.
     */
    function validateEmail(input) {
        return /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(input);
    }

    return {
        /**
         * Delete item from lookup list.
         *
         * @param {String} selected - Item to delete from list
         * @return {Array} customerSelectedList - Updated lookup list of customers
         */
        deleteLookupItem: function (selected) {
            for (var index = 0; index < self.customerSelectedList.length; index++) {
                var item = self.customerSelectedList[index];

                if (item.value === selected) {
                    self.customerSelectedList.splice(index, index + 1);
                    break;
                }
            }

            return self.customerSelectedList;
        },

        /**
         * Highlight lookup results
         *
         * @param {String} term - Search term used for lookup
         * @return {Array} suggestions - List of suggested entries based on search terms
         */
        lookupCustomerWithHighlight: function (term) {
            var suggestions = lookupCustomer(term);
            suggestions.forEach(function (s) {
                s.label = $sce.trustAsHtml(lookupTermHighlight(s.label, term));
            });

            return suggestions;
        },

        /**
         * Populate the list of email addresses to email a receipt to.
         *
         * @param {Object} selected - Selected object from lookup list or value typed in
         * @return {Array} customerSelectedList - Updated lookup list of customers
         */
        populateLookupList: function (selected) {

            var allowItemDelete = true;
            var itemObject = null;
            var itemValue;

            if (!selected) {
                return;
            }

            // Item selected from the lookup list
            if (selected && selected.obj) {
                itemObject = selected.obj;
                itemValue = itemObject.getEmail();
            } else {
                // Value was typed in (not selected from the list)
                var validInput = validateEmail(selected);
                if (!validInput) {
                    return;
                }
                itemValue = selected;
            }

            // Don't allow user to delete the first email if this transaction
            // is associated with a customer
            if (self.customerSelectedList.length === 0 && !self.autocompleteAllowItemDelete) {
                allowItemDelete = false;
            }

            self.customerSelectedList.push({
                allowLookupItemDelete: allowItemDelete,
                obj: itemObject,
                value: itemValue.trim()
            });

            return self.customerSelectedList;
        },

        /**
         * Returns a string representation of customer lookup emails
         *
         * @return {String} customerEmails - String of customer lookup emails
         */
        getLookupEmails: function () {
            var customerEmails = '';
            var emailCount = self.customerSelectedList.length;
            if (emailCount > 0) {
                customerEmails += '[';
                for (var index = 0; index < emailCount; index++) {
                    customerEmails += '\"' + self.customerSelectedList[index].value + '\"';

                    if (index < emailCount - 1) {
                        customerEmails += ',';
                    }
                }
                customerEmails += ']';
            }
            return customerEmails;
        },

        /**
         * Reset the customer lookup states
         */
        resetLookupStates: function () {
            self.autocompleteAllowItemDelete = true;
            self.customerSelectedList = [];
        },

        /**
         * Set whether or not the initial lookup item is allowed to be deleted
         *
         * @param {Boolean} value - True to allow the first lookup item to be deletable.
         * False otherwise.
         */
        setAllowLookupItemDelete: function (value) {
            self.autocompleteAllowItemDelete = value;
        },

        /**
         * Set the pool of customers to lookup from
         *
         * @param {Array} customers - List of customers for performing lookups.
         */
        setCustomerLookupList: function (customers) {
            self.customerLookupList = customers;
        }
    };
});
