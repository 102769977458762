import angular from 'angular';
import ConfigurationService from 'service/configuration-service';

function cardRequest(CustomerCardService, SettingsService, PaperToastService,$log, SessionService,
    vcRecaptchaService) {

    var _this;
    _this = this;

    _this.loading = false;
    _this.showSuccess = false;
    _this.receipt = null;
    _this.customerLookupId = '';
    _this.cardDetails = {
        cardNumber:'',
        cvv:'',
        expiry:'',
        captcha:''
    };
    _this.currentYear = new Date().getFullYear();
    _this.captchaSiteKey = '';

    function getReceiptSettings() {
        SettingsService.getReceiptSettings().then(function (response) {
            _this.receipt = response;
            if (response.icon_image_path) {
                _this.receipt.icon_image_path = ConfigurationService.getConfig().fileManagementServicePublicApi + 'receipt_images/'+ response.icon_image_path.match(/[0-9]+/g);
            }
        });
    }

    // request card-add token
    function requestToken(tokenRequest) {
        _this.loading = true;
        CustomerCardService.requestCardAddToken(tokenRequest)
            .then(function (response) {
                SessionService.setAuthorizationHeaderToken(response.access_token);
                getReceiptSettings();
            }).catch(function () {
                const errorMessage = 'Failed to create access token';
                PaperToastService.create(errorMessage, 'error');
                $log.error(errorMessage);
                throw new Error(errorMessage);
            })
            .finally(function () {
                _this.loading = false;
            });
    }

    // split URL by pipe
    function splitUrlByPipe(urlString) {
        const stringSplit = [];
        let index = 0;
        //grab url and split on pipe into string split
        urlString.split('|').forEach(function (x) {
            stringSplit[index] = x;
            index++;
        });
        _this.customerLookupId = stringSplit[0];
        const tokenRequest = stringSplit[1];
        requestToken(tokenRequest);
    }

    // save card
    _this.addCard = function(form) {
        if (!_this.cardDetails.captcha) {
            return PaperToastService.create('Please check the captcha.', 'error');
        }
        if (form.$invalid) {
            return PaperToastService.create('Fill in Required Fields.', 'error');
        }
        _this.loading = true;
        const requestBody = {
            customer_lookup_id : _this.customerLookupId,
            card_number : _this.cardDetails.cardNumber.split(' ').join(''),
            card_cvv : _this.cardDetails.cvv,
            card_expiry : _this.cardDetails.expiry,
            card_description : '',
            is_default : true,
            captcha : _this.cardDetails.captcha,
            is_through_customer_vault : false
        };
        CustomerCardService.saveCustomerCard(requestBody)
            .then(function () {
                _this.showSuccess = true;
            }).catch(function () {
                const errorMessage = 'Failed to save card.';
                PaperToastService.create(errorMessage, 'error');
                $log.error(errorMessage);
                throw new Error(errorMessage);
            })
            .finally(function () {
                vcRecaptchaService.reload();
                _this.loading = false;
            });
    };

    _this.$onInit = function() {
        splitUrlByPipe(_this.data);
        _this.captchaSiteKey = ConfigurationService.getConfig().captchaSiteKey;
    };

}

angular
    .module('payhqUIApp')
    .component('cardRequest', {
        bindings: {
            data: '='
        },
        controller: cardRequest,
        controllerAs: 'cardRequest',
        templateUrl: 'view/card-request/card-request.html'
    });
