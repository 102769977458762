import angular from 'angular';
angular
.module('payhqUIApp')
.directive('paperToggleGroup',function() {
    return {
        restrict : 'E',
        scope: {
            text: '@',
            type: '@',
            filters: '='
        },
        link: function(scope){
            scope.toggleChange = function(filter, state){
                var obj = { key: state };
                scope.$parent.$parent.toggleModel = obj;
            };
        },
        templateUrl : 'component/paper-toggle-group/paper-toggle-group.html'
    };
});