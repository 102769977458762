import angular from 'angular';
angular
.module('payhqUIApp')
.directive('imagecrop',
function (HttpClientService, ImageCropService) {

    return {

        link: function (scope, elem, attrs) {
            scope.$watch(function () {
                return attrs.src;
            }, function (newValue) {
                if (newValue) {
                    var imageElement = elem[0];
                    if (imageElement) {
                        ImageCropService.attachToElement(imageElement);
                    }
                }
            });
        }
    };
});
