/* @flow */
import angular from 'angular';
import FormatterLocator from 'service/formatters/formatter-locator';
import CREDIT_CARD_FIELD_TYPE from 'filter/credit-card-field-type';
import SPECIAL_CHARACTERS from 'service/formatters/special-characters';

function saveCreditCard(CustomerCardService, PaperToastService, $log, $filter,Utils, $scope,
    HeapService, HeapMessage ) {

    let _this = this;

    _this.formData = {};

    _this.doesCardExist = false;

    function loadExistingCard(existingCard) {
        let creditCardMask = $filter('creditCardMask');
        _this.formData.cardType = FormatterLocator.formatCardType(existingCard.card_type);
        _this.formData.cardNumber = creditCardMask(existingCard.card_suffix,
            CREDIT_CARD_FIELD_TYPE.CARD_NUMBER);
        _this.formData.cardExpiry = existingCard.card_expiry;
        _this.formData.isDefault = existingCard.is_default;
        _this.formData.cardDescription = existingCard.card_description;
        _this.cardId = existingCard.id;
        _this.formData.lookup_id = existingCard.lookup_id;
        _this.formData.cvv = creditCardMask(existingCard.card_cvv,
            CREDIT_CARD_FIELD_TYPE.CVV);
        _this.originalCardInfo = existingCard;
    }

    //@author: carl, as discovered by Marcell, if user has no other default card, we should
    //save the card as default (this is in lieu with production hq)
    function shouldMarkAsDefault() {
        return _this.formData.isDefault || !_this.customer.getDefaultCard();
    }

    function closeAndCleanup() {
        _this.componentEnabled = false;
        _this.formData.cardNumber = undefined;
        _this.formData.cardExpiry = undefined;
        _this.formData.isDefault = false;
        _this.formData.cvv = undefined;
        _this.formData.cardDescription = undefined;
        _this.formData.cardType = undefined;
        _this.formData.card_prefix = undefined;
        _this.formData.card_suffix = undefined;
        _this.formData.id = undefined;
        _this.formData.lookup_id = undefined;
        _this.formData.originalCardInfo = undefined;
        _this.originalCardInfo = undefined;
    }

    window.PAYFIRMA.CommunicationService.onModalCloseEvent(() => {
        closeAndCleanup();
    });

    window.PAYFIRMA.CommunicationService.onSaveCreditCardModalOpen(function (data) {
        _this.customer = data.customer;
        _this.formData.isDefault = shouldMarkAsDefault();
        let existingCard = data.creditCardObject;
        _this.doesCardExist = false;
        if (existingCard) {
            _this.doesCardExist = true;
            loadExistingCard(data.creditCardObject);
        }

    });

    _this.cancel = function () {
        closeAndCleanup();
    };

    function saveCard(creditCardData) {
        creditCardData.is_default = shouldMarkAsDefault();
        CustomerCardService.saveCustomerCard(creditCardData).then(
            function (response) {
                //Heap Track
                HeapService.heapTrack(HeapMessage.TYPE_CUSTOMER_VAULT, HeapMessage.MESSAGE_CARD_ADDED);

                PaperToastService.update('cardSuccessfullySaved');
                _this.isLoading = false;
                window.PAYFIRMA.CommunicationService.triggerRefreshCreditCardList(response);
                closeAndCleanup();
                Utils.forceScopeApply($scope);
            },
            function (error) {
                if (error.message === 'Failed to Authorize') {
                    PaperToastService.error('errorFailedToAuthorizeMessage');
                } else if (error.data.errors[0].message === 'cardNumber field : Invalid Card Number'){
                    PaperToastService.error('errorInvalidCard');
                } else {
                    PaperToastService.create(error.data.errors[0].message, 'error');
                }
                $log.error(error);
                _this.isLoading = false;
                Utils.forceScopeApply($scope);
            }
        );
    }

    function saveNewCard(formData) {
        let creditCardData = {
            customer_lookup_id: _this.customer.getCustomerLookupId(),
            customer_id: _this.customer.getId(),
            card_number: formData.cardNumber.split(' ').join(''),
            card_cvv: formData.cvv,
            card_description: formData.cardDescription,
            card_expiry: formData.cardExpiry,
            is_default: undefined
        };
        saveCard(creditCardData);
    }

    function isValueMasked(value) {
        return value && value.indexOf(SPECIAL_CHARACTERS.CREDIT_CARD_MASK) !== -1;
    }

    function isCreditCardNumberMasked() {
        return isValueMasked(_this.formData.cardNumber);
    }


    function isCvvMasked() {
        return isValueMasked(_this.formData.cvv);
    }

    function editExistingCard() {
        let creditCardData = {
            id: _this.cardId,
            customer_lookup_id: _this.customer.getCustomerLookupId(),
            customer_id: _this.customer.getId(),
            lookup_id: _this.formData.lookup_id,
            card_number: _this.formData.cardNumber,
            card_cvv: _this.formData.cvv,
            card_description: _this.formData.cardDescription,
            card_expiry: _this.formData.cardExpiry,
            is_default: undefined,
            card_prefix: undefined,
            card_suffix: undefined
        };

        if (isCreditCardNumberMasked()) {
            creditCardData.card_number = '';
            creditCardData.card_prefix = _this.card_pefix;
            creditCardData.card_suffix = _this.card_suffix;
        }

        if (_this.originalCardInfo.card_expiry === _this.formData.cardExpiry){
            creditCardData.card_expiry = undefined;
        }

        if (isCvvMasked()) {
            creditCardData.card_cvv = undefined;
        }

        saveCard(creditCardData);
    }

    _this.save = function (form, formData) {
        _this.isLoading = true;
        if (form.$valid) {
            if (!_this.originalCardInfo) {
                saveNewCard(formData);
            } else {
                editExistingCard();
            }
        } else {
            _this.isLoading = false;
        }
    };

    _this.onCardExpriyChange = function (currentValue) {
        //expiry date was changed by user
        if (isCvvMasked() && (currentValue && currentValue !== _this.originalCardInfo.card_expiry)) {
            _this.formData.cvv = '';
        }
    };

    _this.onCardNumberChange = function (currentValue) {
        //card number was changed by user
        if (isCvvMasked() && (currentValue && !isValueMasked(currentValue))) {
            _this.formData.cvv = '';
        }
    };
}

angular
.module('payhqUIApp')
.component('saveCreditCard', {
    bindings: {
        componentEnabled: '='
    },
    controller: saveCreditCard,
    controllerAs: 'saveCreditCard',
    templateUrl: 'view/vault/modal/save-credit-card.html'
});