import angular from 'angular';
import concat from 'lodash/concat';
import filter from 'lodash/filter';

export default angular.module('payhqUIApp').filter('orderByWithEmptyAtBottom', function($filter) {
    function moveEmptyValuesToBottom(list, key) {
        var present = filter(list, function (item) {
            return item[key];
        });
        var empty = filter(list, function (item) {
            return !item[key];
        });

        return concat(present, empty);
    }

    return function(list, expression) {
        var orderBy = $filter('orderBy');
        var sortedList = orderBy(list, expression);

        var key = expression;
        if (expression && (expression.charAt(0)==='+' || expression.charAt(0)==='-')) {
            key = expression.substring(1);
        }

        return moveEmptyValuesToBottom(sortedList, key);
    };
});