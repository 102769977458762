import angular from 'angular';

function paperReporttypeFilter($element) {
    const PAYMENT_METHOD = 'Payment Methods';
    const ITEMS_SOLD = 'Items Sold';
    const TRANSACTIONS = 'transactions';
    const INVENTORY = 'inventory';

    var _this = this;

    _this.isDropped = false;


    _this.isLoading = false;

    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function(){
        if (_this.isDropped === true){
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });

    $element.on('click', function(e){
        if (_this.isDropped === true){
            $('dropdown').show();
        }
        e.stopPropagation();
    });

    _this.reportTypeList = [{ name: PAYMENT_METHOD, key:TRANSACTIONS }, { name: ITEMS_SOLD, key:INVENTORY }];

    _this.setSelectedReportType = function (reportType) {
        _this.title = reportType.name;
        _this.filterreporttype = reportType.key;
        _this.doDrop(false);
    };

    function initFilter() {
        _this.title = PAYMENT_METHOD;
        _this.filterreporttype = TRANSACTIONS;
    }

    initFilter();
}

angular
.module('payhqUIApp')
.component('paperReporttypeFilter', {
    bindings: {
        filterreporttype: '='
    },
    controller: paperReporttypeFilter,
    controllerAs: 'paperReporttypeFilter',
    templateUrl: 'component/paper-filter/paper-reporttype-filter/paper-reporttype-filter.html'
});