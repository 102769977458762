import angular from 'angular';

angular
.module('payhqUIApp')
.filter('transactionsChannel', function () {
    return function (transactionList, channelPropertyKey, filteredChannels) {
        if (transactionList && transactionList.length && channelPropertyKey) {
            return _.filter(transactionList, function (transaction) {
                if (filteredChannels) {
                    return _.find(filteredChannels, function (channel) {
                        return channel === transaction[channelPropertyKey];
                    });
                }

                return true;
            });
        }
        return [];
    };
});