/* @flow */

'use strict';

class ReportingPermission {
    _viewAllTransactions: boolean;

    constructor(serverData?) {
        this._viewAllTransactions = false;
        if (serverData) {
            this._viewAllTransactions = serverData.view_all_transactions;
        }
    }

    get viewAllTransactions() {
        return this._viewAllTransactions;
    }

    set viewAllTransactions(v) {
        this._viewAllTransactions = v;
    }

    updateAllPermissions(value: boolean) {
        this._viewAllTransactions = value;
    }

    toPermissionsObject() {
        return {
            view_all_transactions: this._viewAllTransactions
        };
    }
}

class AccountPermission {
    _active: boolean;

    constructor(serverData?) {
        this._active = false;
        if (serverData) {
            this._active = serverData.active;
        }
    }

    get active() {
        return this._active;
    }

    set active(a) {
        this._active = a;
    }

    updateAllPermissions(value: boolean) {
        this._active = value;
    }

    toPermissionsObject() {
        return {
            active: this._active
        };
    }
}

class AccessPermission {
    _webTerminal: boolean;
    _cardTerminal: boolean;
    _invoicing: boolean;
    _customerVault: boolean;
    _myTransactions: boolean;
    _dashboard: boolean;
    _mobile: boolean;
    _tabletPos: boolean;
    _apiAccess: boolean;
    _eft: boolean;

    constructor(serverData?) {
        this._webTerminal = false;
        this._cardTerminal = false;
        this._invoicing = false;
        this._customerVault = false;
        this._myTransactions = false;
        this._dashboard = false;
        this._mobile = false;
        this._tabletPos = false;
        this._apiAccess = false;
        this._eft = false;

        if (serverData) {
            this._webTerminal = serverData.web_terminal;
            this._cardTerminal = serverData.card_terminal;
            this._invoicing = serverData.invoicing;
            this._customerVault = serverData.customer_vault;
            this._myTransactions = serverData.my_transactions;
            this._dashboard = serverData.dashboard;
            this._mobile = serverData.mobile;
            this._tabletPos = serverData.tablet_pos;
            this._apiAccess = serverData.api_access;
            this._eft = serverData.eft;
        }
    }

    get webTerminal() {
        return this._webTerminal;
    }

    get cardTerminal() {
        return this._cardTerminal;
    }

    get invoicing() {
        return this._invoicing;
    }

    get customerVault() {
        return this._customerVault;
    }

    get myTransactions() {
        return this._myTransactions;
    }

    get dashboard() {
        return this._dashboard;
    }

    get mobile() {
        return this._mobile;
    }

    get tabletPos() {
        return this._tabletPos;
    }

    get apiAccess() {
        return this._apiAccess;
    }

    get eft() {
        return this._eft;
    }

    set webTerminal(w) {
        this._webTerminal = w;
    }

    set cardTerminal(c) {
        this._cardTerminal = c;
    }

    set invoicing(i) {
        this._invoicing = i;
    }

    set customerVault(c) {
        this._customerVault = c;
    }

    set myTransactions(m) {
        this._myTransactions = m;
    }

    set dashboard(d) {
        this._dashboard = d;
    }

    set mobile(m) {
        this._mobile = m;
    }

    set tabletPos(t) {
        this._tabletPos = t;
    }

    set apiAccess(a) {
        this._apiAccess = a;
    }

    set eft(e) {
        this._eft = e;
    }

    updateAllPermissions(value: boolean) {
        this._webTerminal = value;
        this._cardTerminal = value;
        this._invoicing = value;
        this._customerVault = value;
        this._myTransactions = value;
        this._dashboard = value;
        this._mobile = value;
        this._tabletPos = value;
        this._apiAccess = value;
        this._eft = value;
    }

    toPermissionsObject() {
        return {
            web_terminal: this._webTerminal,
            card_terminal: this._cardTerminal,
            invoicing: this._invoicing,
            customer_vault: this._customerVault,
            my_transactions: this._myTransactions,
            dashboard: this._dashboard,
            mobile: this._mobile,
            tablet_pos: this._tabletPos,
            api_access: this._apiAccess,
            eft: this._eft
        };
    }
}

class TransactionPermission {
    _sale: boolean;
    _authorize: boolean;
    _refund: boolean;
    _capture: boolean;

    constructor(serverData?) {
        this._sale = false;
        this._authorize = false;
        this._refund = false;
        this._capture = false;

        if (serverData) {
            this._sale = serverData.sale;
            this._authorize = serverData.authorize;
            this._refund = serverData.refund;
            this._capture = serverData.capture;
        }
    }

    get sale() {
        return this._sale;
    }

    get authorize() {
        return this._authorize;
    }

    get refund() {
        return this._refund;
    }

    get capture() {
        return this._capture;
    }

    set sale(s) {
        this._sale = s;
    }

    set authorize(a) {
        this._authorize = a;
    }

    set refund(r) {
        this._refund = r;
    }

    set capture(c) {
        this._capture = c;
    }

    updateAllPermissions(value: boolean) {
        this._sale = value;
        this._authorize = value;
        this._refund = value;
        this._capture = value;
    }

    toPermissionsObject() {
        return {
            sale: this._sale,
            authorize: this._authorize,
            refund: this._refund,
            capture: this._capture
        };
    }
}

export default class StaffPermission {
    _transactionPermission: TransactionPermission;
    _accessPermission: AccessPermission;
    _accountPermission: AccountPermission;
    _reportingPermission: ReportingPermission;
    _originalAccountPermission: AccountPermission;

    constructor(serverData?: Object) {
        if (serverData) {
            this._transactionPermission = new TransactionPermission(serverData.transaction_permissions);
            this._accessPermission = new AccessPermission(serverData.access_permissions);
            this._accountPermission = new AccountPermission(serverData.account_permissions);
            this._originalAccountPermission = new AccountPermission(serverData.account_permissions);
            this._reportingPermission = new ReportingPermission(serverData.reporting_permissions);
        } else {
            this._transactionPermission = new TransactionPermission();
            this._accessPermission = new AccessPermission();
            this._accountPermission = new AccountPermission();
            this._reportingPermission = new ReportingPermission();
            this._originalAccountPermission = new AccountPermission();
        }
    }

    set transactionPermission(t: TransactionPermission) {
        this._transactionPermission = t;
    }

    get transactionPermission(): TransactionPermission {
        return this._transactionPermission;
    }

    set accessPermission(accessPermission: AccessPermission) {
        this._accessPermission = accessPermission;
    }

    get accessPermission(): AccessPermission {
        return this._accessPermission;
    }

    set accountPermission(accountPermission: AccountPermission) {
        this._accountPermission = accountPermission;
    }

    get accountPermission(): AccountPermission {
        return this._accountPermission;
    }

    get reportingPermission(): ReportingPermission {
        return this._reportingPermission;
    }

    set reportingPermission(r: ReportingPermission) {
        this._reportingPermission = r;
    }

    get originalAccountPermission(): AccountPermission {
        return this._originalAccountPermission;
    }

    updateAllPermissions(value: boolean) {
        this._transactionPermission.updateAllPermissions(value);
        this._accessPermission.updateAllPermissions(value);
        this._accountPermission.updateAllPermissions(value);
        this._reportingPermission.updateAllPermissions(value);
    }

    toPermissionsObject() {
        //to restore the json object for the purpose of communicating with backend
        return {
            transaction_permissions: this._transactionPermission.toPermissionsObject(),
            access_permissions: this._accessPermission.toPermissionsObject(),
            account_permissions: this._accountPermission.toPermissionsObject(),
            reporting_permissions: this._reportingPermission.toPermissionsObject()
        };
    }
}