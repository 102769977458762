/* @flow */

import angular from 'angular';
/**
 * @ngdoc object
 * @name payhqUIApp.controller:ResetPasswordController
 * @description
 *
 * ResetPassword controller of the payhqUIApp
 *
 */

function resetPassword($element, $location, $translate, Utils, $log,
                       AuthService, SessionService, PaperToastService, ResetPasswordService) {
    let _this = this;

    _this.resetPasswordClicked = false;

    let element = $element[0];
    let defaultErrorMessage = 'Reset password failed';
    // allowed or denied
    _this.userPasswordResetStatus = '';
    _this.newPasswordReset = '';
    _this.passwordConfirm = '';
    _this.paramObject = '';

    element.className = 'background' + Utils.maxRandomNumberGenerator(12);

    function extractResetEmailParamsAndVerifyResetPasswordLink() {
        _this.paramObject = $location.search();
        _this.userId = _this.paramObject.userid;
        _this.token = _this.paramObject.token;
        _this.email = window.atob(_this.paramObject.username);
        _this.verifyResetpasswordLink();
    }

    function clearQueryParameters() {
        $location.url($location.path());
    }

    _this.initialize = function () {
        let promise = AuthService.logout();
        if (promise) {
            return promise.then(function(){
                extractResetEmailParamsAndVerifyResetPasswordLink();
            }).catch(function(){
                extractResetEmailParamsAndVerifyResetPasswordLink();
            });
        } else {
            extractResetEmailParamsAndVerifyResetPasswordLink();
        }
    };

    /**
     * Check ResetPassword Key
     * @returns {undefined}
     */
    _this.verifyResetpasswordLink = function () {
        _this.resetPasswordClicked = true;
        return ResetPasswordService.verifyToken(_this.userId, _this.token)
            .then(function () {
                _this.resetPasswordClicked = false;
            }).catch(function (error) {
                _this.displayToastMessage(error);
                _this.redirectToLoginRestPassword();
                _this.resetPasswordClicked = false;
                throw error;
            });
    };
    /**
     * Display toast message
     */
    _this.displayToastMessage = function (error) {
        let errorMessage = defaultErrorMessage;
        if (error.message) {
            errorMessage = error.message;
        }
        $log.error(errorMessage);
        PaperToastService.create(errorMessage, 'error');
    };

    /**
     * Redirect to reset password page
     */
    _this.redirectToLoginRestPassword = function () {
        setTimeout(function () {
            clearQueryParameters();
            $location.path('login').search(window.PAYFIRMA.RESET_PASSWORD_QUERY_PARAM, 'true');
        }, 2500);
    };

    /**
     * Do password reset,
     * if success, login to home
     */

    _this.executeResetPassword = function () {
        _this.resetPasswordClicked = true;
        return ResetPasswordService.applyNewPassword(_this.userId, _this.token,
            _this.newPasswordReset).then(function () {
                PaperToastService.create($translate.instant('newpasswordUpdated'), 'update');
                return _this.loginAndRirect();
            }).catch(function (error) {
                _this.resetPasswordClicked = false;
                _this.displayToastMessage(error);
                throw error;
            });
    };

    _this.resetCurrentPassword = function (form) {
        if (form.$valid) {
            if (_this.newPasswordReset !== _this.passwordConfirm) {
                PaperToastService.create($translate.instant('passwordNotMatch'), 'error');
            } else {
                return _this.executeResetPassword();
            }
        }
    };

    /**
     * if success, login to home
     */
    _this.loginAndRirect = function () {
        //clean url param first
        return AuthService.login(_this.email, _this.newPasswordReset)
            .then(function (response) {
                if (response.otpVerificationRequired && response.otpVerificationRequired === true){
                    $location.path('/login/').search({ 'otpSessionToken': response.otpSessionToken });
                } else {
                    return SessionService.initializeSessionWithPromise()
                    .then(function (perm) {
                        clearQueryParameters();
                        PaperToastService.close();
                        SessionService.goToDefaultView(perm);
                        _this.resetPasswordClicked = false;
                    });
                }
            }).catch(function (errorMessage) {
                _this.resetPasswordClicked = false;
                if (errorMessage) {
                    PaperToastService.create(errorMessage.message, 'error');
                    $log.error(errorMessage.message);
                }
                throw errorMessage;
            });
    };

    _this.initialize();
}


angular
    .module('payhqUIApp')
    .component('resetPassword', {
        bindings: {},
        controller: resetPassword,
        controllerAs: 'resetPassword',
        templateUrl: 'view/reset-password/reset-password.html'
    });