import angular from 'angular';

angular
.module('payhqUIApp')
.service('UserRedirectionService', function (SessionService, UserPermissionService, $rootScope, Utils) {

    //TODO: DELETE ME!!!  THIS LOGIC SHOULD BE MOVED TO SESSIONSERVICE
    function checkPerm(permissionName) {
        if (permissionName === 'VTMaster') {
            var hasVTMaster = SessionService.checkUserPermission('VTMaster');
            var hasVTProcessSale = SessionService.checkUserPermission('VT_ProcessSale');
            var hasVTProcessAuth = SessionService.checkUserPermission('VT_ProcessAuth');
            var hasVTProcessCapture = SessionService.checkUserPermission('VT_ProcessCapture');
            var hasVTProcessRefund = SessionService.checkUserPermission('VT_ProcessRefund');

            if (!hasVTProcessSale && !hasVTProcessAuth && !hasVTProcessCapture && !hasVTProcessRefund) {
                return false;
            } else {
                return hasVTMaster;
            }
        } else {
            return SessionService.checkUserPermission(permissionName);
        }
    }

    function hasPermission(nextPath) {
        if (nextPath) {
            for (var route in window.PAYFIRMA.ROUTE_PERMISSION_MAP) {
                if (window.PAYFIRMA.ROUTE_PERMISSION_MAP.hasOwnProperty(route)
                    && nextPath.toLowerCase().indexOf(route) !== -1) {
                    return checkPerm(window.PAYFIRMA.ROUTE_PERMISSION_MAP[route]);

                }
            }
        }
        return true;
    }

    function goToLoginView() {
        Utils.go(window.PAYFIRMA.ROUTE.LOGIN);
        Utils.forceScopeApply($rootScope);
    }

    return {
        initialize: function () {
            $rootScope.$on('$routeChangeStart', function (event, next) {
                var nextPath = next && next.$$route && next.$$route.originalPath;
                SessionService.initializeSessionWithPromise(nextPath)
                    .then(function () {
                        if (SessionService.requirePermissionCheck(nextPath) && !hasPermission(nextPath)) {
                            goToLoginView();
                        }
                    })
                    .catch(function () {
                        if (SessionService.requirePermissionCheck(nextPath)) {
                            goToLoginView();
                        }
                    });
            });
        }
    };
});