'use strict';

function imageUploader($element, FileReader) {
    let _this;
    _this = this;

    function initFileReader() {
        _this.imageElement = $element[0].querySelector('input[type="file"]');
        if (_this.imageElement) {
            FileReader.readFile(_this.imageElement, FileReader.CONTENT_TYPE.IMAGE,
                function (imageData) {
                    _this.onImageLoaded({
                        value: {
                            data: imageData,
                            type: _this.imageUploadType
                        }
                    });
                });
        }
    }

    window.PAYFIRMA.CommunicationService.onImageUploadEvent(function (imageUploadType) {

        initFileReader();
        if (imageUploadType === _this.imageUploadType) {
            FileReader.openFile(_this.imageElement);
        }
    });
}

angular.module('payhqUIApp').component('imageUploader', {
    bindings: {
        onImageLoaded: '&',
        imageUploadType: '<'
    },
    controller: imageUploader,
    controllerAs: 'imageUploader',
    templateUrl: 'view/settings/theme/uploader/image-uploader.html'
});