import angular from 'angular';

function deleteCustomerProfile(CustomerService, $log, PaperToastService, Utils) {

    let _this = this;

    window.PAYFIRMA.CommunicationService.onDeleteCustomerProfileModalOpen(function (customerData) {
        _this.customer = customerData;
    });

    _this.cancel = function () {
        _this.componentEnabled = false;
    };

    _this.deleteCustomer = function () {
        return CustomerService.deleteCustomer(_this.customer.lookup_id)
        .then(function () {
            _this.componentEnabled = false;
            window.PAYFIRMA.CommunicationService.triggerConfirmDeleteCustomer();
            Utils.go('customer-vault');
        })
        .catch((error) => {
            PaperToastService.create(error.message, 'error');
            _this.cancel();
        });
    };
}

angular
.module('payhqUIApp')
.component('deleteCustomerProfile', {
    bindings: {
        componentEnabled: '='
    },
    controller: deleteCustomerProfile,
    controllerAs: 'deleteCustomerProfile',
    templateUrl: 'view/vault/modal/delete-customer-profile.html'
});