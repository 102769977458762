/* @flow */

import _ from 'lodash';

let Nonce = function (data : Object) {
    let _this = this;
    _.extend(_this, data);

    return _this;
};
export default Nonce;