/* @flow */

import angular from 'angular';
import ConfigurationService from 'service/configuration-service';

/**
 * @ngdoc service
 * @name payhqUIApp.customerApp.service:IframeSettingService
 * @requires Restangular
 * @description
 *
 * Service for iframee related endpoints
 *
 */
angular
    .module('settingsApp')
    .service('IframeSettingService', function (HttpClientService) {

        const privateIframeUrl = ConfigurationService.getConfig().merchantServiceApi + 'iframe';

        function updateIframeAccessToken(iframeLookupId, token) {
            const updateRequest = {
                access_token:token
            };
            return HttpClientService
                    .doPut(
                        privateIframeUrl + '/' + iframeLookupId + '/token' ,
                        updateRequest
                    )
                    .then(function (response) {
                        return response;
                    });
        }

        return {

            getIframeSettings: function () {
                return HttpClientService
                    .doGet(privateIframeUrl)
                    .then((response) => {
                        return response.data;
                    }).catch((error) => {
                        throw error;
                    });
            },

            saveIframeSettings: function (requestObj) {
                return HttpClientService
                    .doPost(
                        privateIframeUrl,
                        requestObj)
                    .then((response) => {
                        return response.data;
                    }).catch((error) => {
                        throw error;
                    });
            },

            updateIframeSettings: function (requestObj, lookupId) {
                return HttpClientService
                    .doPut(
                        privateIframeUrl + '/' + lookupId,
                        requestObj)
                    .then((response) => {
                        return response.data;
                    }).catch((error) => {
                        throw error;
                    });
            },


            deleteIframeSettings: function (lookupId) {
                return HttpClientService
                    .doDelete(
                        privateIframeUrl + '/' + lookupId )
                    .then((response) => {
                        return response.data;
                    }).catch((error) => {
                        throw error;
                    });
            },

            getIframeGenerateToken: function (requestData) {
                let hqConfig = ConfigurationService.getConfig();
                return HttpClientService.doFormPost(
                hqConfig.hqJwtTokenUrl + 'oauth/token',
                    {
                        grant_type: 'client_credentials',
                        client_id: requestData.client_id,
                        client_secret: requestData.client_secret
                    },{ headers: { 'Authorization': 'Basic ' + window.btoa(requestData.client_id + ':' + requestData.client_secret) } },
                 false)
                .then(function (response) {
                    if (response.data.access_token) {
                        return updateIframeAccessToken(requestData.lookup_id,response.data.access_token);

                    } else {
                        throw new Error('Cannot get generated token');
                    }
                })
                .catch(function (errors) {
                    throw errors;
                });

            }
        };
    });
