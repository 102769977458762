/* @flow */

import angular from 'angular';

/**
 * @ngdoc object
 * @name payhqUIApp.controller:ChangeOwnPasswordController
 * @description
 *
 * ChangeOwnPassword controller of the payhqUIApp
 *
 */

function changeOwnPassword($element, $location, $translate, Utils, $log,
                           AuthService, SessionService, PaperToastService, ResetPasswordService) {
    let _this = this;

    _this.buttonClicked = false;

    let element = $element[0];
    let defaultErrorMessage = 'We cannot change your password.  Please contact customer service.';

    element.className = 'background' + Utils.maxRandomNumberGenerator(12);

    function displayToastMessage(error) {
        let errorMessage = defaultErrorMessage;
        if (error.message) {
            errorMessage = error.message;
        }
        $log.error(errorMessage);
        PaperToastService.create(errorMessage, 'error');
    }

    _this.initialize = function () {
        let changePasswordInfo =
            ResetPasswordService.getChangePasswordInfo();
        if (!changePasswordInfo) {
            Utils.go(window.PAYFIRMA.ROUTE.LOGIN);
        } else {
            _this.email = changePasswordInfo.email;
            _this.userLookupId = changePasswordInfo.userLookupId;
            _this.institutionId = changePasswordInfo.institutionId;
            _this.resetPasswordHashToken = changePasswordInfo.resetPasswordHashToken;
            _this.tokenType = changePasswordInfo.tokenType;
        }

        return AuthService.logout();
    };

    /**
     * user changes own password,
     * if success, login to home
     */

    _this.changePassword = function (form) {
        if (form.$valid) {
            if (_this.newPassword === _this.currentPassword) {
                let errorMessage = $translate.instant('sameCurrentNewPasswords');
                PaperToastService.create(errorMessage, 'error');
                throw new Error(errorMessage);
            } else if (_this.newPassword !== _this.newPasswordConfirmation) {
                let errorMessage = $translate.instant('passwordNotMatch');
                PaperToastService.create(errorMessage, 'error');
                throw new Error(errorMessage);
            } else {
                _this.buttonClicked = true;
                return ResetPasswordService.changePassword({
                    userLookupId: _this.userLookupId,
                    institutionId: _this.institutionId,
                    resetPasswordHashToken: _this.resetPasswordHashToken,
                    currentPassword: _this.currentPassword,
                    newPassword: _this.newPassword
                }).then(function () {
                    ResetPasswordService.clearChangePasswordInfo();
                    PaperToastService.create($translate.instant('newpasswordUpdated'), 'update');
                    return _this.loginAndRirect();
                }).catch(function (error) {
                    _this.buttonClicked = false;
                    displayToastMessage(error);
                    throw error;
                });
            }
        }
    };

    /**
     * if success, login to home
     */
    _this.loginAndRirect = function () {
        //clean url param first
        return AuthService.login(_this.email, _this.newPassword)
            .then(function (response) {
                if (response.otpVerificationRequired && response.otpVerificationRequired === true){
                    $location.path('/login/').search({ 'otpSessionToken': response.otpSessionToken });
                } else {
                    return SessionService.initializeSessionWithPromise()
                    .then(function (perm) {
                        PaperToastService.close();
                        SessionService.goToDefaultView(perm);
                        _this.buttonClicked = false;
                    });
                }
            }).catch(function (errorMessage) {
                _this.buttonClicked = false;
                if (errorMessage) {
                    PaperToastService.create(errorMessage.message, 'error');
                    $log.error(errorMessage.message);
                }
                throw errorMessage;
            });
    };

    _this.initialize();
}


angular
    .module('payhqUIApp')
    .component('changeOwnPassword', {
        bindings: {},
        controller: changeOwnPassword,
        controllerAs: 'changeOwnPassword',
        templateUrl: 'view/reset-password/change-own-password.html'
    });