import angular from 'angular';

angular.module('payhqUIApp')
    .filter('pagination', function ($filter) {
        return function (list) {
            var paginationService = window.PAYFIRMA.CURRENT_PAGINATION_SERVICE;
            if (paginationService) {
                return $filter('limitTo')(list, paginationService.nextCursor());
            }
            return [];
        };
    });