import angular from 'angular';

angular
.module('payhqUIApp')
.filter('dateFormatter', function (DateFormat, Utils) {
    return function (value) {
        var momentObject = Utils.dateStringToMomentObject(value);
        if (momentObject) {
            return momentObject.format(DateFormat.DISPLAY_FORMAT);
        } else {
            //TODO: CANNOT GUESS WHICH FORMAT THE DATE OBJECT MIGHT HAVE, RETURN AS IT IS
            //should unify the date format across the system
            return value;
        }
    };
});