import angular from 'angular';
import BILLING_CONSTANT from 'constant/billing-constant';
import ConfigurationService from 'service/configuration-service';

/**
 * @ngdoc service
 * @name payhqUIApp.recurringApp.service:SubscriptionService
 * @requires Restangular
 * @requires RecurringBillingFactory
 * @description
 *
 * Service for handling recurring billing behaviours
 *
 */
angular
.module('recurringApp')
.service('SubscriptionService', function SubscriptionService(HttpClientService) {

    function updateCustomerSubscription(subscription, customerLookupId, subscriptionLookupId) {
        return HttpClientService
            .doPatch(
                ConfigurationService.getConfig()
                    .customerServiceApi + 'customer/' +
                        customerLookupId +'/subscription/' + subscriptionLookupId,
                    subscription
            )
            .then(function (subscriptionResponse) {
                return subscriptionResponse;
            });
    }

    return {
        /**
         * Save a customer plan.
         *
         * @param {Object} customerPlan - Customer plan object to save
         * @returns {Object} customerPlan - Returns a customer plan object
         */
        createCustomerPlan: function (customerPlan,customerLookupId) {
            return HttpClientService
                .doPost(
                    ConfigurationService.getConfig()
                    .customerServiceApi + 'customer/' + customerLookupId +'/subscription' ,
                    customerPlan
                )
                .then(function (subscriptionResponse) {
                    return subscriptionResponse;
                });
        },
        updateSubscription: function(subscription, customerLookupId){
            return updateCustomerSubscription(subscription, customerLookupId, subscription.lookup_id);
        },
        deleteCustomerPlan: function (customerLookupId, subscriptionLookupId) {
            return HttpClientService
                .doDelete(
                    ConfigurationService.getConfig()
                    .customerServiceApi + 'customer/' +
                        customerLookupId +'/subscription/' + subscriptionLookupId)
                .then(function (subscriptionResponse) {
                    return subscriptionResponse;
                });
        },
        resumeSubscription: function (subscription, customerLookupId) {
            var currentStatus = subscription.getPlanStatus();
            var retryCount = subscription.getPlanFailedAttempts();
            var status = BILLING_CONSTANT.status.active;

            // If we are resuming a subscription previously in Retry state, we want to continue in Retry state
            if (currentStatus === BILLING_CONSTANT.status.paused && retryCount > 0) {
                status = BILLING_CONSTANT.status.retry;
            }
            var updatesubscription = {
                'status': status
            };
            return updateCustomerSubscription(updatesubscription, customerLookupId, subscription.lookup_id);
        },

        cancelSubscription: function (customerLookupId, subscriptionLookupId) {
            var updatesubscription = {
                'status': BILLING_CONSTANT.status.cancelled
            };
            return updateCustomerSubscription(updatesubscription, customerLookupId, subscriptionLookupId);
        },

        pauseSubscription: function (customerLookupId, subscriptionLookupId) {
            var updatesubscription = {
                'status': BILLING_CONSTANT.status.paused
            };
            return updateCustomerSubscription(updatesubscription, customerLookupId, subscriptionLookupId);
        },

        suspendSubscription: function (customerLookupId, subscriptionLookupId) {
            var updatesubscription = {
                'status': BILLING_CONSTANT.status.suspended
            };
            return updateCustomerSubscription(updatesubscription, customerLookupId, subscriptionLookupId);
        },

        activateSubscription: function (customerLookupId, subscriptionLookupId) {
            var updatesubscription = {
                'status': BILLING_CONSTANT.status.active
            };
            return updateCustomerSubscription(updatesubscription, customerLookupId, subscriptionLookupId);
        }
    };
});
