import angular from 'angular';
/**
 *  view : <paper-channel-filter filterchannel="filterchannel"></paper-channel-filter>
 *  set filter on ng-repeat : customFilter:filterchannel
 */
function paperChannelFilter($element) {
    const E_COMMERCE = 'E_COMMERCE';
    const INVOICE = 'INVOICE';
    const MOBILE = 'MOBILE';
    const RECURRING = 'RECURRING';
    const TABLET_POS = 'TABLET_POS';
    const VT = 'VT';
    const CARD_TERMINAL = 'CARD_TERMINAL';
    const HPP = 'HPP';
    const EFT = 'EFT';
    const IFRAME = 'IFRAME';
    const PAY_SUBSCRIPTION = 'PAY_SUBSCRIPTION';
    const SHOPIFY = 'SHOPIFY';
    var _this = this;
    var defaultTitle = window.PAYFIRMA.TEXT_STRINGS.CHANNEL;
    var defaultSelected = [E_COMMERCE, INVOICE, MOBILE, RECURRING, TABLET_POS, VT, CARD_TERMINAL, HPP, EFT, IFRAME, PAY_SUBSCRIPTION, SHOPIFY];

    _this.isDropped = false;

    // Drops element
    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function(){
        if (_this.isDropped === true){
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });

    $element.on('click', function(e) {
        if (_this.isDropped === true){
            $('dropdown').show();
        }
        e.stopPropagation();
    });

    _this.title = defaultTitle + ' (' + defaultSelected.length + ')';

    _this.selectedChannel = defaultSelected;
    _this.channelList = [
        { name: window.PAYFIRMA.TEXT_STRINGS.ECOMMERCE, key: E_COMMERCE },
        { name: window.PAYFIRMA.TEXT_STRINGS.INVOICING, key: INVOICE },
        { name: window.PAYFIRMA.TEXT_STRINGS.MOBILE, key: MOBILE },
        { name: window.PAYFIRMA.TEXT_STRINGS.RECURRING, key: RECURRING },
        { name: window.PAYFIRMA.TEXT_STRINGS.TABLETPOS, key: TABLET_POS },
        { name: window.PAYFIRMA.TEXT_STRINGS.WEBTERMINAL, key: VT },
        { name: window.PAYFIRMA.TEXT_STRINGS.CARDTERMINAL, key: CARD_TERMINAL },
        { name: window.PAYFIRMA.TEXT_STRINGS.HPP, key: HPP },
        { name: window.PAYFIRMA.TEXT_STRINGS.EFT, key: EFT },
        { name: window.PAYFIRMA.TEXT_STRINGS.IFRAME, key: IFRAME },
        { name: window.PAYFIRMA.TEXT_STRINGS.PAY_SUBSCRIPTION, key: PAY_SUBSCRIPTION },
        { name: window.PAYFIRMA.TEXT_STRINGS.SHOPIFY, key: SHOPIFY }
    ];

    _this.setSelectedChannel = function (channel) {
        var key = channel.key;

        if (channel.channelSelected) {
            _this.selectedChannel.push(key);
            channel.channelSelected = true;
        } else if (_.includes(_this.selectedChannel, key)) {
            _this.selectedChannel = _.without(_this.selectedChannel, key);
            channel.channelSelected = false;
        }

        if (_this.selectedChannel.length === 0) {
            _this.title = defaultTitle;
            _this.filterchannel = { transaction_source: defaultSelected };

        } else {
            _this.title = defaultTitle + ' (' + _this.selectedChannel.length + ')';
            _this.filterchannel = { transaction_source: _this.selectedChannel };
        }
    };

    _this.initFilter = function () {
        if (_this.channels && _this.channels.length) {
            angular.forEach(_this.channels, function (channel) {
                const selectChannel = _.find(_this.channelList, (chl)=>chl.key===channel);
                selectChannel.channelSelected = true;
            });
            _this.selectedChannel = _this.channels;
            _this.filterchannel = { transaction_source: _this.selectedChannel };
            _this.title = defaultTitle + ' (' + _this.selectedChannel.length + ')';
        } else {
            angular.forEach(_this.channelList, function (channel) {
                channel.channelSelected = true;
            });
            _this.filterchannel = { transaction_source: _this.selectedChannel };
        }
    };

    _this.initFilter();
}


angular
.module('payhqUIApp')
.component('paperChannelFilter', {
    bindings: {
        filterchannel: '=',
        channels: '='
    },
    controller: paperChannelFilter,
    controllerAs: 'paperChannelFilter',
    templateUrl: 'component/paper-filter/paper-channel-filter/paper-channel-filter.html'
});