import angular from 'angular';

function paperSearchBox() {

}

angular.module('payhqUIApp').component('paperSearchBox', {
    bindings: {
        ngModel: '=',
        heapIdentifier: '<'
    },
    controller: paperSearchBox,
    controllerAs: 'paperSearchBox',
    require: '?ngModel',
    templateUrl: function(){
        return 'component/paper-search-box/paper-search-box.html';
    }
});

angular.module('payhqUIApp').filter('fieldSearch', function ($filter) {
    return function (list, text) {
        if (text && text.length > 2) {
            return $filter('filter')(list, text);
        }
        return list;
    };
});
