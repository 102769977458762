import angular from 'angular';

angular.module('payhqUIApp').directive('paperLanguage',['$translate', 'localeSupported',
    function($translate) {
        return {
            restrict: 'E',
            controller: function($scope){
                $scope.selectedLang = $translate.use();
                $scope.supportedLang = [
                    {
                        value: 'en',
                        displayText: 'English'
                    },
                    {
                        value: 'fr',
                        displayText: 'Français'
                    }
                ];

                $scope.getLanguage = function(lng) {
                    return $scope.supportedLang.find(lang => lang.value === lng).displayText;
                };
            },
            link: function(scope) {
                scope.currentLang = scope.getLanguage($translate.use());
                scope.setLocale = function () {
                    scope.currentLang = scope.getLanguage(scope.selectedLang);
                    $translate.use(scope.selectedLang);
                    window.PAYFIRMA.CommunicationService.triggerLanguageChangedEvent();
                };
                scope.setLanguage = function(language){
                    scope.getLanguage = language;
                    $translate.use(language);
                    window.PAYFIRMA.CommunicationService.triggerLanguageChangedEvent();
                };
            },
            templateUrl : 'component/paper-language/paper-language.html'
        };
    }
]);