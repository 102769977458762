import angular from 'angular';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import DATE_CONSTANT from 'constant/date-constant';

function reporting($scope, $translate, Restangular, SessionService) {
    let _this = this;
    var searchParameters;

    _this.report = 'transactions';
    _this.transactionSource = 0;

    _this.orderListBy = null;
    _this.pageLimit = 100;
    _this.limitStep = 50;
    _this.listLoading = false;

    _this.currencySelect = 'CAD';
    _this.userFilter = 'all';

    let transactionCollectionsList = [
        'visaCollection',
        'mcCollection',
        'amexCollection',
        'discoverCollection',
        'jcbCollection',
        'debitCollection',
        'cashCollection',
        'checkCollection',
        'eftCollection',
        'otherCollection'
    ];

    let metricList = [
        'totals',
        'visa',
        'mc',
        'amex',
        'discover',
        'jcb',
        'debit',
        'cash',
        'check',
        'eft',
        'other'
    ];

    let metricPropertyList = [
        'count',
        'gross',
        'refunded',
        'tax',
        'tips',
        'discounts',
        'total',
        'received',
        'sent'
    ];

    function setupMetricsObject() {
        _this.salesMetrics = {};
        _.forEach(metricList, (metric) => {
            _this.salesMetrics[metric] = {};
            _.forEach(metricPropertyList, (metricProperty) => {
                _this.salesMetrics[metric][metricProperty] = 0;
            });
        });
    }

    function setupInitialValues() {
        _this.inventoryList = [];
        _this.visaCollection = [];
        _this.mcCollection = [];
        _this.amexCollection = [];
        _this.discoverCollection = [];
        _this.jcbCollection = [];
        _this.debitCollection = [];
        _this.cashCollection = [];
        _this.checkCollection = [];
        _this.eftCollection = [];
        _this.otherCollection = [];
    }

    _this.$onInit = () => {
        setupInitialValues();
        setupMetricsObject();
    };

     /** Clears all metrics and collections, sets all method columbs to closed*/
    function clearMetrics() {
        setupInitialValues();
        setupMetricsObject();

        //close all rows
        _this.visaRow = false;
        _this.mcRow = false;
        _this.amexRow = false;
        _this.discoverRow = false;
        _this.jcbRow = false;
        _this.debitRow = false;
        _this.cashRow = false;
        _this.checkRow = false;
        _this.eftRow = false;
        _this.otherRow = false;
        _this.pageLimit = 100;

    }

    $scope.$watch('reporting.report', (newValue, oldValue) => {
        if (newValue !== oldValue){
            clearMetrics();
        }
    });

    _this.toggleRow = (type) => {
        _this[type] = !_this[type];
    };

    _this.forceNumeric = (responseData) => {
        _.forEach(responseData, (value) => {
            //force numeric on Price
            if (typeof value.Price !== 'number') {
                let bigValuePrice = new BigNumber(value.Price || 0);
                value.Price = bigValuePrice.toNumber();
            }
            //force numeric on Gross value and remove $
            if (typeof value.GrossSales !== 'number') {
                value.GrossSales = value.GrossSales.replace('$', '');
                value.GrossSales = value.GrossSales.replace(/\,/g, '');
                let bigGrossSales = new BigNumber(value.GrossSales || 0);
                value.GrossSales = bigGrossSales.toNumber();
            }
            //force numeric on Qnty
            if (typeof value.QuantitySold !== 'number') {
                let bigQuantitySold = new BigNumber(value.QuantitySold || 0);
                value.QuantitySold = bigQuantitySold.toNumber();
            }
        });
        return responseData;
    };


    function listLoading(bool) {
        _this.listLoading = bool;
    }

    function groupByDate() {
        var collection;
        $(transactionCollectionsList).each(function (index, value) {
            var byDate = {};
            collection = value;
            $.each(_this[collection], function () {
                var record = byDate[this.Date] || (byDate[this.Date] = []);
                record.push(this);
            });
            _this[collection] = byDate;
        });
        listLoading(false);
    }

    function isPurchase(transactionType) {
        var isPurchaseValue = false;
        var purchaseTransactionTypes = ['purchase', 'cash_purchase', 'terminal_purchase', 'terminal_void',
            'terminal_authorize', 'terminal_capture', 'external_purchase', 'check_purchase','capture'];

        angular.forEach(purchaseTransactionTypes, function (transaction) {
            if (transaction === transactionType) {
                isPurchaseValue = true;
            }
        });

        return isPurchaseValue;
    }

    function isRefund(transactionType) {
        var isRefundValue = false;

        var refundTransactionTypes = ['refund', 'naked_refund', 'external_refund', 'check_refund', 'cash_refund'];

        angular.forEach(refundTransactionTypes, function (transaction) {
            if (transaction === transactionType) {
                isRefundValue = true;
            }
        });

        return isRefundValue;
    }

    function auditSales(dataArray) {
        const nonEFTSales = _.filter(dataArray, (data) => data.TransactionSource !== 'EFT');
        const EFTSales = _.filter(dataArray, (data) => data.TransactionSource === 'EFT');
        $.each(nonEFTSales, function () {
            var method;

            let bigNumberGrossAmount = new BigNumber(this.GrossAmount || 0);
            let bigNumberAmountTax = new BigNumber(this.AmountTax || 0);
            let bigNumberAmountTip = new BigNumber(this.AmountTip || 0);
            let bigNumberDiscountAmount = new BigNumber(this.DiscountAmount || 0);
            let bigNumberDiscountPercent = new BigNumber(this.xDiscountPercentxx || 0);
            let bigNumberAmount = new BigNumber(this.Amount || 0);
            if (this.TransactionType !== 'authorize') {
                _this.salesMetrics.totals.count++;
                if (isRefund(this.TransactionType)) {
                    _this.salesMetrics.totals.refunded += bigNumberGrossAmount.toNumber();
                    _this.salesMetrics.totals.tax += bigNumberAmountTax.absoluteValue().negated().toNumber();
                } else {
                    _this.salesMetrics.totals.gross += bigNumberGrossAmount.toNumber();
                    _this.salesMetrics.totals.tax += bigNumberAmountTax.toNumber();
                }
                _this.salesMetrics.totals.tips += bigNumberAmountTip.toNumber();
                if (bigNumberDiscountPercent.greaterThan(0)) {
                    _this.salesMetrics.totals.discounts +=
                        (bigNumberDiscountPercent.toNumber() / 100) *
                        bigNumberAmount.toNumber();
                }
                _this.salesMetrics.totals.discounts += bigNumberDiscountAmount.toNumber();
                //sort by method
                switch (this.CardType) {
                    case 'Visa':
                        _this.visaCollection.push(this);
                        method = 'visa';
                        break;
                    case 'Mastercard':
                        _this.mcCollection.push(this);
                        method = 'mc';
                        break;
                    case 'Amex':
                        _this.amexCollection.push(this);
                        method = 'amex';
                        break;
                    case 'Discover':
                        _this.discoverCollection.push(this);
                        method = 'discover';
                        break;
                    case 'Jcb':
                        _this.jcbCollection.push(this);
                        method = 'jcb';
                        break;
                    case 'Debit':
                        _this.debitCollection.push(this);
                        method = 'debit';
                        break;
                    case 'Cash':
                        _this.cashCollection.push(this);
                        method = 'cash';
                        break;
                    case 'Check':
                        _this.checkCollection.push(this);
                        method = 'check';
                        break;
                    default:
                        _this.otherCollection.push(this);
                        method = 'other';
                        break;
                }

                //Save data metrics for each method
                _this.salesMetrics[method].count++;
                if (isRefund(this.TransactionType)) {
                    _this.salesMetrics[method].refunded += bigNumberGrossAmount.toNumber();
                    _this.salesMetrics[method].tax += bigNumberAmountTax.absoluteValue().negated().toNumber();
                } else {
                    _this.salesMetrics[method].gross += bigNumberGrossAmount.toNumber();
                    _this.salesMetrics[method].tax += bigNumberAmountTax.toNumber();
                }
                if (bigNumberDiscountPercent.toNumber() > 0) {
                    _this.salesMetrics[method].discounts +=
                        (bigNumberDiscountPercent.toNumber() / 100) *
                        bigNumberAmount.toNumber();
                }
                _this.salesMetrics[method].discounts += bigNumberDiscountAmount.toNumber();
                _this.salesMetrics[method].tips += bigNumberAmountTip.toNumber();
            }
        });

        $.each(EFTSales, function () {

            let method, bigNumberRequestedAmount = new BigNumber(0), bigNumberSentAmount = new BigNumber(0);

            if (this.TransactionType === 'eft_credit'){
                bigNumberRequestedAmount = new BigNumber(this.Amount);
            } else if (this.TransactionType === 'eft_debit'){
                bigNumberSentAmount = new BigNumber(this.Amount);
            }

            if (this.CardType === 'EFT') {
                _this.eftCollection.push(this);
                method = 'eft';
            }

            //Save data metrics for each method
            _this.salesMetrics[method].count++;
            _this.salesMetrics[method].received += bigNumberRequestedAmount.toNumber();
            _this.salesMetrics[method].sent += bigNumberSentAmount.toNumber();
            _this.salesMetrics[method].total += bigNumberSentAmount.toNumber() + bigNumberRequestedAmount.toNumber();
        });
        //calculate totals for each method
        let totalSales =
            _this.salesMetrics.totals.gross +
            _this.salesMetrics.totals.tips +
            _this.salesMetrics.totals.tax;
        let totalVisa =
            _this.salesMetrics.visa.gross +
            _this.salesMetrics.visa.tips +
            _this.salesMetrics.visa.tax;
        let totalMastercard =
            _this.salesMetrics.mc.gross +
            _this.salesMetrics.mc.tips +
            _this.salesMetrics.mc.tax;
        let totalAmericanExpress =
            _this.salesMetrics.amex.gross +
            _this.salesMetrics.amex.tips +
            _this.salesMetrics.amex.tax;
        let totalDiscover =
            _this.salesMetrics.discover.gross +
            _this.salesMetrics.discover.tips +
            _this.salesMetrics.discover.tax;
        let totalJCB =
            _this.salesMetrics.jcb.gross +
            _this.salesMetrics.jcb.tips +
            _this.salesMetrics.jcb.tax;
        let totalDebit =
            _this.salesMetrics.debit.gross +
            _this.salesMetrics.debit.tips +
            _this.salesMetrics.debit.tax;
        let totalCash =
            _this.salesMetrics.cash.gross +
            _this.salesMetrics.cash.tips +
            _this.salesMetrics.cash.tax;
        let totalCheck =
            _this.salesMetrics.check.gross +
            _this.salesMetrics.check.tips +
            _this.salesMetrics.check.tax;
        let totalOther =
            _this.salesMetrics.other.gross +
            _this.salesMetrics.other.tips +
            _this.salesMetrics.other.tax;

        _this.salesMetrics.totals.total =
            (totalSales - _this.salesMetrics.totals.discounts) -
            _this.salesMetrics.totals.refunded;

        _this.salesMetrics.visa.total =
            (totalVisa - _this.salesMetrics.visa.discounts) -
            _this.salesMetrics.visa.refunded;

        _this.salesMetrics.mc.total =
            (totalMastercard - _this.salesMetrics.mc.discounts) -
            _this.salesMetrics.mc.refunded;

        _this.salesMetrics.amex.total =
            (totalAmericanExpress - _this.salesMetrics.amex.discounts) -
            _this.salesMetrics.amex.refunded;

        _this.salesMetrics.discover.total =
            (totalDiscover - _this.salesMetrics.discover.discounts) -
            _this.salesMetrics.discover.refunded;

        _this.salesMetrics.jcb.total =
            (totalJCB - _this.salesMetrics.jcb.discounts) -
            _this.salesMetrics.jcb.refunded;

        _this.salesMetrics.debit.total =
            (totalDebit - _this.salesMetrics.debit.discounts) -
            _this.salesMetrics.debit.refunded;

        _this.salesMetrics.cash.total =
            (totalCash - _this.salesMetrics.cash.discounts) -
            _this.salesMetrics.cash.refunded;

        _this.salesMetrics.check.total =
            (totalCheck - _this.salesMetrics.check.discounts) -
            _this.salesMetrics.check.refunded;

        _this.salesMetrics.other.total =
            (totalOther - _this.salesMetrics.other.discounts) -
            _this.salesMetrics.other.refunded;

        //group transactions.
        groupByDate();
    }

    function getTransactions(exports) {
        clearMetrics();
        let startDate = _this.filtercalendarstart;
        let endDate = _this.filtercalendarend;

        if (startDate === null && endDate === null || $('#date-range-filter').val() === '') {
            startDate = 'all';
            endDate = 'all';
        } else {
            startDate = moment(startDate).format(DATE_CONSTANT.default_format);
            endDate = moment(endDate).format(DATE_CONSTANT.default_format);
        }

        let transactionSearchParameters = {
            start_date: startDate,
            end_date: endDate,
            users: _this.filterstaff,
            currency: _this.filtercurrency,
            transaction_source: _this.transactionSource
        };
        //get request with search parameters
        Restangular
        .one('sales-summary')
        .get(transactionSearchParameters)
        .then((responseData) => {
            if (responseData.length > 0) {
                _this.responseData = responseData;
                auditSales(responseData);
                if (exports){
                    _this.export();
                }
            } else {
                listLoading(false);
            }
        });
    }

    function getInventory(exports) {
        clearMetrics();
        let startDate = _this.filtercalendarstart;
        let endDate = _this.filtercalendarend;
        //set parameters

        if (startDate === null && endDate === null || $('#date-range-filter').val() === '') {
            startDate = 'all';
            endDate = 'all';
        } else {
            startDate = moment(startDate).format(DATE_CONSTANT.default_format);
            endDate = moment(endDate).format(DATE_CONSTANT.default_format);
        }
        //set parameters
        searchParameters = {
            start_date: startDate,
            end_date: endDate,
            users: _this.filterstaff,
            timezone: 'PST'
        };
        //get request with search parameters
        Restangular
        .one('inventory-summary/')
        .get(searchParameters)
        .then((responseData) => {
            if (responseData.length > 0) {
                _this.inventoryList = _this.forceNumeric(responseData);
                _this.responseData = responseData;
                listLoading(false);
                if (exports){
                    _this.export();
                }
            } else {
                listLoading(false);
            }
        });
    }
    function isString(value){
        return typeof value === 'string' || value instanceof String;
    }
    function isNumeric(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }
    // JSON to CSV Converter
    function convertToCSV(objArray) {
        var preline = '';
        var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (array[i].TransactionType !== 'authorize'){
                    if (line !== ''){
                        line += ',';
                    }

                    if ((isString(array[i][index]) && (array[i][index]).indexOf('object:') === -1) ||
                    isNumeric(array[i][index])){
                        line += array[i][index];
                    }

                    if (i === 0 && preline !== ''){
                        preline += ',';
                    }
                    if (i === 0 && index !== '$$hashKey'){
                        preline += index;
                    }
                }
            }
            if (line.length !== 0){
                str += line + '\r\n';
            }
        }
        return preline + '\r\n' + str;
    }

    _this.checkUserPermission = function(permission) {
        return SessionService.checkUserPermission(permission);
    };

    // TODO We need to standardize the export methods and remove this old methods (see CIM-161)
    _this.export = () => {
        var jsonObject;
        var converted;
        var a;
        var csv;
        var url;
        var filename = moment(_this.filtercalendarstart).format(DATE_CONSTANT.default_format) + '-' +
        moment(_this.filtercalendarend).format(DATE_CONSTANT.default_format) + '-reports.csv';
        if (_this.responseData){
            jsonObject = JSON.stringify(_this.responseData);
            converted = convertToCSV(jsonObject);
            a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';

            csv = new window.Blob([converted],{ type: 'text/csv;charset=utf-8;' });

            // If IE10, IE11+ (Only IE10+ has the attribute msSaveBlob under navigator)
            if (window.navigator.msSaveBlob) {
                // Create and save the Blob
                window.navigator.msSaveOrOpenBlob(csv, filename);
            // Else for other browsers but IE10, IE11+
            } else {
                url = window.URL.createObjectURL(csv);
                a.href = url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        } else {
            _this.runReports(true);
        }
    };

    function getReportList(list, exports) {
        if (list === 'transactions') {
            getTransactions(exports);
        } else if (list === 'inventory') {
            getInventory(exports);
        }
    }

    /** Run the ropport that '$scope.report' has selected*/
    _this.runReports = (exports) => {
        listLoading(true);
        _this.currencySelect = _this.filtercurrency;
        getReportList(_this.report, exports);
    };

    /** Infinate scroll, fires once user hits bottom of the page  */
    _this.lazyLoad = () => {
        _this.pageLimit = _this.pageLimit + _this.limitStep;
    };

    /** taks ng-repeated items from template and sets color pallet of transaction */
    _this.transionColorSchema = (transaction) => {
        var colorSchema;
        switch (transaction.TransactionType) {
            case 'purchase':
            case 'capture':
                colorSchema = 'success';
                break;
            case 'refund':
                colorSchema = 'info';
                break;
            case 'authorize':
                colorSchema = 'warning';
                break;
            default:
                colorSchema = '';
                break;
        }

        return colorSchema;
    };


    _this.getAmount = (transaction) => {
        let amount = 0;
        let bigNumberTransactionGrossAmount = new BigNumber(transaction.GrossAmount || 0);
        if (isPurchase(transaction.TransactionType)) {
            amount = bigNumberTransactionGrossAmount.toNumber();
        }
        return amount;
    };

    _this.getRefund = (transaction) => {
        let refund = 0;
        let bigNumberTransactionGrossAmount = new BigNumber(transaction.GrossAmount || 0);
        if (isRefund(transaction.TransactionType)) {
            refund = bigNumberTransactionGrossAmount.negated().toNumber();
        }
        return refund;
    };

    /** calculates total of transaction row for ng-repeat*/
    _this.getTotal = (transaction) => {
        let bigNumberTransactionGrossAmount = new BigNumber(transaction.GrossAmount || 0);
        let bigNumberTransactionAmountTax = new BigNumber(transaction.AmountTax || 0);
        let bigNumberTransactionAmountTip = new BigNumber(transaction.AmountTip || 0);
        let total;
        if (isRefund(transaction.TransactionType)) {
            total = bigNumberTransactionGrossAmount
                .plus(bigNumberTransactionAmountTax)
                .plus(bigNumberTransactionAmountTip)
                .negated()
                .toNumber();
        } else {
            total = bigNumberTransactionGrossAmount
                .plus(bigNumberTransactionAmountTax)
                .plus(bigNumberTransactionAmountTip)
                .toNumber();
        }
        return total;
    };

    _this.getKeyRowTotal = (collection) => {
        let bigNumberRowTotal = new BigNumber(0);
        angular.forEach(collection, (collectionEntry) => {
            let bigNumberTotal = new BigNumber(_this.getTotal(collectionEntry) || 0);
            bigNumberRowTotal =
                bigNumberRowTotal
                .plus(bigNumberTotal);
        });
        let rowTotal = bigNumberRowTotal.toNumber();
        return rowTotal;
    };

    /**
     * Apply sorting to table columns
     */
    _this.sortCol = (parameter, $event) => {

        var targetElement = $($event.target);
        var sortableColumn = $('[sort]');
        //set tabel sort function and display arrow class
        if (targetElement.hasClass('ascending')) {
            _this.orderListBy = '-' + parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort').addClass('decending');
        } else if (targetElement.hasClass('decending')) {
            _this.orderListBy = parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort').addClass('ascending');
        } else if (targetElement.hasClass('no-sort')) {
            _this.orderListBy = parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort');
            targetElement.addClass('ascending');
        }
    };

    _this.isTotalMinus = (total) =>{
        var isTotalMinus = true;
        if (total >= 0){
            isTotalMinus = false;
        }
        return isTotalMinus;
    };

}
angular
.module('payhqUIApp')
.component('reporting', {
    bindings: {},
    controller: reporting,
    controllerAs: 'reporting',
    templateUrl: 'view/reporting/reporting.html'
});