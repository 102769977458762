import angular from 'angular';

function invoiceTableSection(Utils) {
    var _this;
    _this = this;

    _this.createInvoice = function () {
        Utils.go('/create-invoice/' + _this.customerLookupId);
    };

    // Shortens Currency label
    _this.shortenCurrencyLabel = function(currency) {
        var labelString = '';
        if (currency === 'CAD') {
            labelString = 'CA';
        }
        if (currency === 'USD') {
            labelString = 'US';
        }
        return labelString;
    };
}

angular
.module('payhqUIApp')
.component('invoiceTableSection', {
    bindings: {
        recentInvoices: '<',
        customerLookupId: '<'
    },
    controller: invoiceTableSection,
    controllerAs: 'invoiceTableSection',
    templateUrl: 'view/vault/invoice-table/invoice-table-section.html'
});
