import angular from 'angular';
import ConfigurationService from 'service/configuration-service';
angular
.module('payhqUIApp')
.service('AssetService', function (HttpClientService) {
    let privateUrl = ConfigurationService.getConfig().fileManagementServiceApi+'theme/';
    let params = {};

    return {
        getAsset: (subdomain) => {
            return HttpClientService
            .doGet(
                privateUrl + subdomain,
                params,
                {})
            .then((response) => {
                return response;
            }).catch((error) => {
                throw error;
            });
        },
        postAsset: (asset) => {
            return HttpClientService
            .doPost(privateUrl, window.btoa(asset))
            .then((response) => {
                return response;
            }).catch((error) => {
                throw error;
            });
        },
        deleteAsset: () => {
            return HttpClientService
            .doDelete(privateUrl, params)
            .then((response) => {
                return response;
            }).catch((error) => {
                throw error;
            });
        }
    };
});