import angular from 'angular';
import TRANSACTION_CONSTANT from 'constant/transaction-constant';
import 'service/mcc-service';
import MerchantModel from 'model/merchant';

function merchantEdit(MerchantService, UrlService, MccService, PaperToastService,
                      TransactionService, MerchantAccountRequestService, TimeService, $scope) {

    let self = this;

    let _this = this;

    function clearTransaction() {
        _this.isTransactionVisible = false;
        _this.merchantTransaction = {};
    }

    function refreshGateway() {
        self.merchant.gateways = [_this.canadianGateway, _this.usGateway];

        // Push card terminals to gateway
        for (var i = 0; i < self.cardTerminals.length; i++) {
            // CAD
            if (self.cardTerminals[i].currency === 'CAD' || self.cardTerminals[i].currency === 'BOTH') {
                const canadianCardTerminal = _.assign({}, self.cardTerminals[i], { currency: 'CAD' });
                self.merchant.gateways.push(canadianCardTerminal);
            }

            // USD
            if (self.cardTerminals[i].currency === 'USD' || self.cardTerminals[i].currency === 'BOTH') {
                const usCardTerminal = _.assign({}, self.cardTerminals[i], { currency: 'USD' });
                self.merchant.gateways.push(usCardTerminal);
            }
        }

        // Push eft to gateway
        for (var j = 0; j < self.eftGateways.length; j++) {
            // CAD
            if (self.eftGateways[j].currency === 'CAD') {
                self.merchant.gateways.push(self.eftGateways[j]);
            }
        }
    }

    function revertEFTGatewayDeleted() {
        for (let i = 0; i < self.eftGateways.length; ++i) {
            self.eftGateways[i].is_delete = false;
        }
    }

    _this.view = {
        info: true,
        transaction: false,
        eftFee : false
    };

    _this.switchView = (view) => {
        _this.view = {};
        clearTransaction();
        _this.searchTransactionId = '';
        _this.view[view] = true;
    };

    self.array = {};

    _this.transactionResultArray = TRANSACTION_CONSTANT.result;

    _this.transactionSourceArray = TRANSACTION_CONSTANT.source;
    _this.merchantTransaction = {};

    _this.lookupTransaction = (form, id) => {
        if (form && form.$valid && id) {
            let transactionId = String(id);
            let merchantId = String(_this.merchant.id);
            return TransactionService.getTransactionMerchant(
                transactionId,
                merchantId)
                .then((getTransactionResult) => {
                    _this.merchantTransaction = getTransactionResult;
                    _this.isTransactionVisible = true;
                    _this.merchantTransaction.time =
                        TimeService.epochToDateAndTime(_this.merchantTransaction.transaction_time);
                }, (getTransactionError) => {
                    clearTransaction();
                    PaperToastService.create(getTransactionError.message, 'error');
                });
        } else {
            PaperToastService.create('Please fill out the transaction id.', 'error');
        }
    };

    _this.patchTransaction = (form, formData) => {
        if (form && form.$valid && formData) {
            let transactionId = String(formData.id);
            let merchantId = String(_this.merchant.id);
            let patchTransactionObject = {
                transaction_result: formData.transaction_result,
                transaction_source: formData.transaction_source,
                processor_transaction_id: formData.processor_transaction_id,
                processor_auth_code: formData.processor_auth_code
            };
            return TransactionService.patchTransactionMerchant(
                transactionId,
                merchantId,
                patchTransactionObject)
                .then((patchTransactionResult) => {
                    clearTransaction();
                    PaperToastService.create('Transaction was successfully updated.', 'update');
                    _this.merchantTransaction = patchTransactionResult;
                }, (patchTransactionError) => {
                    PaperToastService.create(patchTransactionError.message, 'error');
                });
        } else {
            return PaperToastService.create('Please fill out the required fields.', 'error');
        }
    };

    _this.cancelLookupTransaction = () => {
        clearTransaction();
    };

    // List of countries
    self.array.countries = [
        {
            key: 'Canada',
            value: 'CA'
        },
        {
            key: 'United States',
            value: 'US'
        }
    ];

    // List of all provinces in Canada
    self.array.provinces = [
        {
            key: 'BC - British Columbia',
            value: 'BC'
        },
        {
            key: 'AB - Alberta',
            value: 'AB'
        },
        {
            key: 'MB - Manitoba',
            value: 'MB'
        },
        {
            key: 'NB - New Brunswick',
            value: 'NB'
        },
        {
            key: 'NL - Newfoundland and Labrador',
            value: 'NL'
        },
        {
            key: 'NS - Nova Scotia',
            value: 'NS'
        },
        {
            key: 'NT - Northwest Territories',
            value: 'NT'
        },
        {
            key: 'NU - Nunavut',
            value: 'NU'
        },
        {
            key: 'ON - Ontario',
            value: 'ON'
        },
        {
            key: 'PE - Prince Edward Island',
            value: 'PE'
        },
        {
            key: 'QC - Quebec',
            value: 'QC'
        },
        {
            key: 'SK - Saskatchewan',
            value: 'SK'
        },
        {
            key: 'YT - Yukon',
            value: 'YT'
        }
    ];

    // List of all states in US
    self.array.states = [
        {
            key: 'AL - Alabama', value: 'AL'
        },
        {
            key: 'AK - Alaska', value: 'AK'
        },
        {
            key: 'AR - Arkansas', value: 'AR'
        },
        {
            key: 'AK - Alaska', value: 'AK'
        },
        {
            key: 'AR - Arkansas', value: 'AR'
        },
        {
            key: 'AS - American Samoa', value: 'AS'
        },
        {
            key: 'AZ - Arizona', value: 'AZ'
        },
        {
            key: 'CA - California', value: 'CA'
        },
        {
            key: 'CO - Colorado', value: 'CO'
        },
        {
            key: 'CT - Connecticut', value: 'CT'
        },
        {
            key: 'DC - District of Columbia', value: 'DC'
        },
        {
            key: 'DE - Delaware', value: 'DE'
        },
        {
            key: 'FL - Florida', value: 'FL'
        },
        {
            key: 'FM - Federated States of Micronesia', value: 'FM'
        },
        {
            key: 'GA - Georgia', value: 'GA'
        },
        {
            key: 'GU - Guam', value: 'GU'
        },
        {
            key: 'HI - Hawaii', value: 'HI'
        },
        {
            key: 'IA - Iowa', value: 'IA'
        },
        {
            key: 'ID - Idaho', value: 'ID'
        },
        {
            key: 'IL - Illinois', value: 'IL'
        },
        {
            key: 'IN - Indiana', value: 'IN'
        },
        {
            key: 'KS - Kansas', value: 'KS'
        },
        {
            key: 'KY - Kentucky', value: 'KY'
        },
        {
            key: 'LA - Louisiana', value: 'LA'
        },
        {
            key: 'MA - Massachusetts', value: 'MA'
        },
        {
            key: 'MD - Maryland', value: 'MD'
        },
        {
            key: 'ME - Maine', value: 'ME'
        },
        {
            key: 'MH - Marshall Islands', value: 'MH'
        },
        {
            key: 'MI - Michigan', value: 'MI'
        },
        {
            key: 'MN - Minnesota', value: 'MN'
        },
        {
            key: 'MO - Missouri', value: 'MO'
        },
        {
            key: 'MP - Northern Mariana Islands', value: 'MP'
        },
        {
            key: 'MS - Mississippi', value: 'MS'
        },
        {
            key: 'MT - Montana', value: 'MT'
        },
        {
            key: 'NC - North Carolina', value: 'NC'
        },
        {
            key: 'ND - North Dakota', value: 'ND'
        },
        {
            key: 'NE - Nebraska', value: 'NE'
        },
        {
            key: 'NH - New Hampshire', value: 'NH'
        },
        {
            key: 'NJ - New Jersey', value: 'NJ'
        },
        {
            key: 'NM - New Mexico', value: 'NM'
        },
        {
            key: 'NV - Nevada', value: 'NV'
        },
        {
            key: 'NY - New York', value: 'NY'
        },
        {
            key: 'OH - Ohio', value: 'OH'
        },
        {
            key: 'OK - Oklahoma', value: 'OK'
        },
        {
            key: 'OR - Oregon', value: 'OR'
        },
        {
            key: 'PA - Pennsylvania', value: 'PA'
        },
        {
            key: 'PR - Puerto Rico', value: 'PR'
        },
        {
            key: 'PW - Palau', value: 'PW'
        },
        {
            key: 'RI - Rhode Island', value: 'RI'
        },
        {
            key: 'SC - South Carolina', value: 'SC'
        },
        {
            key: 'SD - South Dakota', value: 'SD'
        },
        {
            key: 'TN - Tennessee', value: 'TN'
        },
        {
            key: 'TX - Texas', value: 'TX'
        },
        {
            key: 'UT - Utah', value: 'UT'
        },
        {
            key: 'VT - Vermont', value: 'VT'
        },
        {
            key: 'VA - Virginia', value: 'VA'
        },
        {
            key: 'VI - Virgin Islands', value: 'VI'
        },
        {
            key: 'WA - Washington', value: 'WA'
        },
        {
            key: 'WI - Wisconsin', value: 'WI'
        },
        {
            key: 'WV - West Virginia', value: 'WV'
        },
        {
            key: 'WY - Wyoming', value: 'WY'
        }
    ];

    // List of all acceptable statuses
    self.array.account_status = [
        {
            key: 'New',
            value: 'NEW'
        },
        {
            key: 'Active',
            value: 'ACTIVE'
        },
        {
            key: 'Suspended',
            value: 'SUSPENDED'
        },
        {
            key: 'Inactive',
            value: 'INACTIVE'
        },
        {
            key: 'Closed',
            value: 'CLOSED'
        },
        {
            key: 'Testing',
            value: 'TESTING'
        }
    ];

    // List of all account types
    self.array.account_type = [
        {
            key: 'Demo',
            value: 'DEMO'
        },
        {
            key: 'Basic',
            value: 'BASIC'
        },
        {
            key: 'Pro',
            value: 'PRO'
        },
        {
            key: 'Internal',
            value: 'INTERNAL'
        }
    ];

    // List of all gateways
    self.array.account_gateway = [
        {
            key: 'Inactive',
            value: 'INACTIVE'
        },
        {
            key: 'Bluepay',
            value: 'BLUEPAY'
        },
        {
            key: 'TestProcessor',
            value: 'TEST'
        },
        {
            key: 'PayFacto',
            value: 'PAYFACTO'
        },
        {
            key: 'RS2',
            value: 'RS2'
        },
        {
            key: 'Paysafe',
            value: 'PAYSAFE'
        },
        {
            key: 'Tsys',
            value: 'TSYS'
        },
        {
            key: 'NMI',
            value: 'NMI'
        }
    ];

    self.array.eft_gateway = [
        {
            key: 'Inactive',
            value: 'INACTIVE'
        },
        {
            key: 'Apaylo',
            value: 'APAYLO'
        }
    ];

    // List of all device gateways
    self.array.account_device_gateway = [
        {
            key: 'PayFacto',
            value: 'PAYFACTO'
        },
        {
            key: 'Tsys',
            value: 'TSYS'
        }
    ];

    // List of all gateways
    self.array.currencies = [
        {
            key: 'CAD',
            value: 'CAD'
        },
        {
            key: 'USD',
            value: 'USD'
        }
    ];

    // List of all processors
    self.array.account_processor = [
        {
            key: 'First Data',
            value: 'FIRSTDATA'
        },
        {
            key: 'Chase Paymentech',
            value: 'CHASE'
        }
    ];

    // List of all account platforms
    self.array.account_platform = [
        {
            key: 'South',
            value: 'SOUTH'
        },
        {
            key: 'Nashville',
            value: 'NASHVILLE'
        },
        {
            key: 'North',
            value: 'NORTH'
        },
        {
            key: 'Omaha',
            value: 'OMAHA'
        }
    ];

    self.mccMessage = '';

    /**
     * Initialization
     */
    _this.$onInit = function () {
        if (!_this.merchant) {
            _this.merchant = new MerchantModel();
        }

        _this.canadianGateway = _this.merchant.getCanadianGateway() || {};
        _this.usGateway = _this.merchant.getUSGateway() || {};
        _this.cardTerminals = _this.merchant.getTerminals() || [{}];
        _this.eftGateways = _this.merchant.getEFTGateways() || [{}];

        // If account request is not empty, initialize the fields from it
        if (!_.isEmpty(_this.accountRequest)) {
            _this.merchant.business_name = _this.accountRequest.businessName;
            _this.merchant.first_name = _this.accountRequest.firstName;
            _this.merchant.last_name = _this.accountRequest.lastName;
            _this.merchant.email = _this.accountRequest.email;
            _this.merchant.address1 = _this.accountRequest.address1;
            _this.merchant.telephone = _this.accountRequest.telephone;
            _this.merchant.city = _this.accountRequest.city;
            _this.merchant.postal_code = _this.accountRequest.postalCode;
            _this.merchant.country = _this.accountRequest.country;
            _this.merchant.province = _this.accountRequest.province;
            _this.merchant.mcc_code = 0;
            _this.canadianGateway.processor = 'TEST';
            _this.canadianGateway.mid = 0;
            _this.merchant.mfa_enabled = true;
        }
    };

    _this.$onInit();

    self.addCardTerminal = function () {
        _this.cardTerminals.push({
            id:-1,
            currency: 'CAD',
            is_card_terminal: true,
            is_eft: false,
            is_delete: false
        });
    };


    self.addEFTGateway = function () {
        _this.eftGateways.push({
            id:-1,
            currency: 'CAD',
            is_card_terminal: false,
            is_eft: true,
            is_delete: false
        });
    };

    self.deleteCardTerminal = function (terminal) {
        var index = _this.cardTerminals.indexOf(terminal);
        _this.cardTerminals[index].is_delete = true;
    };

    self.deleteEFTGateway = function (gateway) {
        var index = _this.eftGateways.indexOf(gateway);
        _this.eftGateways[index].is_delete = true;
    };

    self.hideAddEFTGateway = function () {
        const resultLimit = _.filter(_this.eftGateways, function (gateway) {
            return gateway.is_eft && !gateway.is_delete;
        });
        if (resultLimit.length) {
            return false;
        }
        return true;
    };


    /**
     *  Check the master entity
     */
    self.checkMasterEntity = function () {
        return !self.merchant.master_entity;
    };

    /**
     *  Go back to merchant list
     */
    self.goToMerchantList = function () {
        UrlService.go('merchant');
    };

    /**
     * Go to the given Url
     * @param url
     */
    self.go = function (url) {
        UrlService.go(url);
    };

    /**
     * Show non filtered devices
     */
    self.removedFilter = function (device) {
        let isVisible = true;

        if (device.Deleted === 1) {
            isVisible = false;
        }

        return isVisible;
    };

    /**
     * Validate the provided Merchant Category Code
     *
     * @param value - MCC value
     */
    self.checkMcc = function (value) {
        if (value !== '') {

            let mccForceNumber = Number(value),
                mccCheck = MccService.search(mccForceNumber);

            if (mccCheck === 'not found') {
                self.mccMessage = 'The Merchant Category Code is invalid';
                self.mccColorClass = 'red';
            } else {
                self.mccMessage = mccCheck;
                self.mccColorClass = '';
            }
        } else {
            self.mccMessage = '';
        }

        return self.mccMessage;
    };

    /**
     * Checking 'TSYS' gateway selection
     */
    _this.isGatewayTsys = function (selectedGateWay) {
        if (selectedGateWay !== 'TSYS'){
            return false;
        }
        return true;
    };

    _this.isActiveProcessor = function(selectedGateway) {
        if (selectedGateway !== 'INACTIVE') {
            return true;
        }
        return false;
    };

    _this.onMerchanEFTFee = function (processorId) {
        _this.eftProcessorId = processorId;
        _this.view.eftFee = true;
    };

    // Currently only payfacto merchants are supported, this must be adjusted accordingly as we
    // write integrations with other gateways
    _this.hasSupportGooglePay = function () {
        if (_this.canadianGateway.processor==='PAYFACTO' || _this.usGateway.processor==='PAYFACTO') {
            return true;
        }
        _this.merchant.product_permissions.google_pay = false;
        return false;
    };

    /**
     * Generating Random String for secret key
     */
    function generateRandomString (length) {
        let result='';
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for ( let index = 0; index < length; index++ ) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

     /**
     * Updating secret key input box
     */
    _this.onChangeGatewayTsys = function (params,selectedGateWay) {
        let nonDeletedCardTerminals = _.filter(_this.cardTerminals,(terminal)=>!terminal.is_delete);
        if (selectedGateWay !== 'TSYS'){
            nonDeletedCardTerminals[params.index].currency = 'CAD';
            nonDeletedCardTerminals[params.index].secret_key = '';
            return;
        }
        nonDeletedCardTerminals[params.index].currency = 'USD';
        nonDeletedCardTerminals[params.index].secret_key = generateRandomString(10);
    };

    /**
     * Delete merchant
     */
    _this.deleteMerchant = function (merchantId) {

        return MerchantService.deleteMerchant(merchantId)
            .then(function () {
                PaperToastService.create('Merchant record deleted', 'update');
                UrlService.go('merchant');
                return;
            })
            .catch(function(e){
                PaperToastService.create(e.message, 'error');
                throw e;
            });
    };

    /**
     * Show or hide marketing banner for the merchant
     */
    _this.showHideMarketingBanner = function (merchant) {

        let merchantId = merchant.merchant.id;

        return MerchantService.showHideMarketingBanner(merchantId)
            .then(function (response) {
                if (response.data === true) {
                    PaperToastService.create('Marketing Banner Is Now Visible For This Merchant', 'update');
                } else {
                    PaperToastService.create('Marketing Banner Is Now Hidden For This Merchant', 'error');
                }
                return;
            })
            .catch(function(e){
                PaperToastService.create(e.message, 'error');
                throw e;
            });
    };

    /**
     * Delete account request if account was created through accountRequest
     */
    _this.checkAndDeleteAccountRequest = function () {
        if (!_.isEmpty(_this.accountRequest)) {
            return MerchantAccountRequestService.deleteMerchantAccountRequest(_this.accountRequest.id)
            .then(function () {
                setTimeout(function() {
                    PaperToastService.create('Merchant Account Request was deleted', 'update');
                }, 3000); // Delaying the message by 3 seconds, as it overrides merchant created message
                return;
            })
            .catch(function(e){
                PaperToastService.create(e.message, 'error');
                throw e;
            });
        } else {
            return Promise.resolve();
        }
    };

    /**
     *  Save merchant
     */
    _this.save = function (form) {

        if (form.$valid) {

            self.saving = true;

            let completedMessage;

            // Handle permissions
            if (self.merchant.isNew()) {
                completedMessage = 'Merchant created.';
            } else {
                completedMessage = 'Merchant updated.';
            }

            if (self.merchant.sub_domain === '') {
                self.merchant.sub_domain = undefined;
            }

            refreshGateway();

            return MerchantService.saveMerchant(self.merchant)
                .then(
                    function () {
                        self.saving = false;
                        PaperToastService.create(completedMessage, 'update');
                        _this.checkAndDeleteAccountRequest();
                        UrlService.go('merchant');
                        return;
                    }).catch(function(e){
                        self.saving = false;
                        PaperToastService.create(e.message, 'error');
                        if (e.message && e.message === 'There may be not charged EFT transactions. Please try to delete EFT Gateway later') {
                            revertEFTGatewayDeleted();
                        }
                        throw e;
                    });
        } else {
            PaperToastService.create('Fill in Required Fields.', 'error');
        }
    };

    _this.viewDeleteMerchant = function () {
        _this.isDeleteModalEnabled = true;
    };

    _this.cancelDeleteMerchant = function () {
        _this.isDeleteModalEnabled = false;
    };

    _this.disableDeleteMerchant = function () {
        return _this.deleteInput!=='DELETE';
    };

    _this.confirmDeleteMerchant = function () {
        _this.deleteMerchant(_this.merchant.id);
    };

    $scope.$on('closeEFTFee', function() {
        _this.view.eftFee = false;
    });
}

angular.module('payhqUIApp').component('merchantEdit', {
    bindings: {
        merchant: '<',
        accountRequest: '<'
    },
    controller: merchantEdit,
    controllerAs: 'merchantEdit',
    templateUrl: 'view/merchant/merchant-edit/merchant-edit.html'
});