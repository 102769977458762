/* @flow */

import { SessionObject } from 'service/session-object';
import SESSION from 'constant/session-constant';

let sessionObject = new SessionObject();
let SESSION_OBJECT_KEY = 'session-object';
let emitterService;
let storage;

class SessionStorageService {
    constructor() {
    }

    static initialize(es, s) {
        emitterService = es;
        storage = s;
        sessionObject = new SessionObject();
        let jsonStringStored = storage.getItem(SESSION_OBJECT_KEY);
        if (jsonStringStored) {
            // $FlowFixMe
            sessionObject.deserialize(jsonStringStored);
        }
    }

    static get() {
        return sessionObject;
    }

    static update() {
        // $FlowFixMe
        storage.setItem(SESSION_OBJECT_KEY, sessionObject.serialize());
        emitterService.triggerEvent(SESSION.SESSION_OBJECT_UPDATED);
    }
}

export default SessionStorageService;