import angular from 'angular';
import 'service/image-editing/services/ImageCropService';
import 'service/image-editing/stores/ImageStore';

function imageCropper($scope, PaperToastService, ImageCropService, ImageStore, Utils) {
    var self = this;
    self.isLoading = false;

    function close() {
        self.isLoading = false;
        self.componentEnabled = false;
        self.photo = undefined;
        Utils.forceScopeApply($scope);
    }
    /**
     * Save cropped profile image
     *
     */
    function saveCroppedImage() {
        ImageStore.uploadImage(ImageCropService.getCroppedImage())
            .then(function () {
                close();
            })
            .catch(function () {
                self.isLoading = false;
                PaperToastService.create('Cannot upload image!', 'error');
            });
    }

    self.cancel = function () {
        close();
    };

    self.update = function () {
        self.isLoading = true;
        //force crop, need delay because canvas needs time to update
        setTimeout(function () {
            //cant trigger click in digest so delay is mandatory
            $('.darkroom-button-success').trigger('click');
            setTimeout(function () {
                saveCroppedImage();
            }, 20);
        }, 1);
    };

    window.PAYFIRMA.CommunicationService.onOpenImageCropperModalEvent(function (imageForCropping) {
        self.componentEnabled = true;
        self.photo = imageForCropping;
        Utils.forceScopeApply($scope);
    });
}

angular
.module('payhqUIApp')
.component('imageCropper', {
    bindings: {},
    controller: imageCropper,
    controllerAs: 'imageCropper',
    templateUrl: 'view/image-editing/modal/image-cropper.html'
});