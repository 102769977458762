import angular from 'angular';
function paperInvoice($location, Html2PdfService, InvoiceService, PaperToastService, Utils, $log) {
    var _this = this;
    var currentLocation = $location.path();
    window.PAYFIRMA.CommunicationService.onPaperModalOpenEvent(function () {
        _this.toggleVisibility(true);
    });
    //Is email valid?
    function validateEmail(email) {
        var regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    }
    _this.toggleVisibility = function(isVisible){
        _this.isInvoiceVisible = isVisible;
    };
    _this.timeStampToHuman = function(date) {
        var formattedDate;
        if (Utils.isNumeric(date)){
            formattedDate = date / 1000;
            formattedDate = window.moment.unix(formattedDate).format('MMM DD, YYYY');
        } else {
            formattedDate = date;
        }
        return formattedDate;
    };
    _this.isInvoicePreview = function(){
        var isInvoicePreview = false;
        if (currentLocation === '/invoicing/create'){
            isInvoicePreview = true;
        }
        return isInvoicePreview;
    };

    _this.isInvoiceView = function(){
        var isInvoiceView = false;
        if (currentLocation === '/invoicing'){
            isInvoiceView = true;
        }
        return isInvoiceView;

    };
    //Convert to image then to pdf for user download
    _this.downloadPdf = function() {
        var INVOICE_BODY_CLASS = 'pdf',
            modalBody = window.document
            .getElementsByClassName(INVOICE_BODY_CLASS);
        Html2PdfService.convert(modalBody, 'invoice.pdf')
            .then(function() {
                //add spinner
            }).catch(function(error) {
                $log.error(error);
            });
    };
    //Send invoice via email
    _this.sendInvoice = function(invoiceObject, email){
        var invoiceId = invoiceObject.invoice_id;
        if (validateEmail(email)){
            InvoiceService.sendInvoiceEmail(invoiceId, email);
            invoiceObject.showResendInvoice = false;
            invoiceObject.showViewInvoice = false;
            PaperToastService.create('Invoice sent', 'update');
            _this.toggleVisibility(false);
        } else {
            PaperToastService.create('Please enter a valid email', 'error');
        }
    };
}
angular.module('payhqUIApp').component('paperInvoice', {
    bindings: {
        data: '='
    },
    controller: paperInvoice,
    controllerAs: 'paperInvoice',
    require: '?ngModel',
    templateUrl: 'component/paper-invoice/paper-invoice.html'
});