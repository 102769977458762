import angular from 'angular';
import EventEmitterService from 'service/event-emitter-service';

angular
    .module('payhqUIApp')
    .service('PaperToastService', function ($rootScope, $translate) {

        var _this = this;
        //create message with type
        _this.create = function(message, type) {
            _this.currentMessage = message;
            _this.currentType = type;
            $rootScope.$broadcast('message.update');
            EventEmitterService.triggerEvent('paper-toast-open', true);
        };

        _this.error = function(messageKey) {
            $translate(messageKey).then(function (translation) {
                _this.create(translation, 'error');
            });
        };

        _this.update = function(messageKey) {
            $translate(messageKey).then(function (translation) {
                _this.create(translation, 'update');
            });
        };

        _this.info = function(messageKey) {
            $translate(messageKey).then(function (translation) {
                _this.create(translation, 'info');
            });
        };

        //close toast
        _this.close = function() {
            $rootScope.$broadcast('message.close');
        };

         //close toast
        _this.closeErrors = function() {
            $rootScope.$broadcast('message.closeErrors');
        };

    });