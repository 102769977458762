import 'view/billing/billing';
import 'view/common/page-not-found';
import 'view/dashboard/dashboard';
import 'view/eft/eft';
import 'view/age-verification/age-verification';
import 'view/image-editing/component/paper-image-upload-button';
import 'view/image-editing/directives/ImageCrop';
import 'view/image-editing/directives/ImageFileOpener';
import 'view/file-uploading/file-uploader';
import 'view/image-editing/modal/delete-photo-modal';
import 'view/image-editing/modal/image-cropper';
import 'view/invoicing/edit/edit';
import 'view/invoicing/edit/modal/error-invoice-modal';
import 'view/invoicing/invoicing';
import 'view/invoicing/view-invoice/view-invoice';
import 'view/invoicing/manage/invoice/pay/pay-invoicing';
import 'view/login/login';
import 'view/login/sso/cibc-sso';
import 'view/merchant/merchant';
import 'view/merchant/merchant-edit/merchant-edit';
import 'view/merchant/eft-fee/eft-fee';
import 'view/merchant/users/users';
import 'view/new-reporting-system/new-reporting-system';
import 'view/pay-invoice/pay-invoice';
import 'view/card-request/card-request';
import 'view/preview-theme/preview-theme';
import 'view/preview-theme-login/preview-theme-login';
import 'view/profile/profile';
import 'view/reporting/reporting';
import 'view/reset-password/reset-password';
import 'view/reset-password/change-own-password';
import 'view/settings/settings';
import 'view/settings/ecommerce-settings/ecommerce-settings';
import 'view/settings/ecommerce-settings/modify-apikey/modify-apikey';
import 'view/settings/ecommerce-settings/modify-apikey/refresh-apikey-confirmation';
import 'view/settings/theme/theme';
import 'view/settings/theme/uploader/image-uploader';
import 'view/settings/hpp-settings/hpp-settings';
import 'view/staff/edit/edit';
import 'view/staff/edit/modal/delete-staff-profile';
import 'view/staff/staff';
import 'view/terminal/terminal';
import 'view/cardterminal/cardterminal';
import 'view/transactions/transactions';
import 'view/vault/customer-details';
import 'view/vault/customer-info/customer-info';
import 'view/vault/invoice-table/invoice-table-section';
import 'view/vault/modal/remove-credit-card';
import 'view/vault/modal/save-credit-card';
import 'view/vault/modal/delete-customer-profile';
import 'view/vault/modal/save-subscription';
import 'view/vault/modal/transaction-confirmation';
import 'view/vault/recurring/recurring';
import 'view/vault/transaction-table/transaction-table-section';
import 'view/vault/eft-transaction-table/eft-transaction-table-section';
import 'view/vault/vault';
import 'view/prospects/prospects';
