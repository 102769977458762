import angular from 'angular';

function paperSystemIdle(Utils, $scope, AuthService, $interval) {
    var _this = this;

    var totalTime = 60;
    var halfOfTotalTime = totalTime/2;
    var oneTenthOfTotalTime = totalTime / 10;

    window.PAYFIRMA.CommunicationService.onSystemIdleEvent(function(showSystemIdleEvent){
        _this.isPaperSystemIdleVisible = showSystemIdleEvent;
    });

    // Sets time left
    var timeLeft = totalTime;

    function updateLoadingCircle(timeLeftValue) {
        var leftSemiCircleRotation;
        var rightSemiCircleRotation;

        if (timeLeftValue > halfOfTotalTime) {
            leftSemiCircleRotation = timeLeftValue - halfOfTotalTime;
            rightSemiCircleRotation = halfOfTotalTime;
        } else {
            leftSemiCircleRotation = 0;
            rightSemiCircleRotation = timeLeftValue;
        }

        leftSemiCircleRotation = oneTenthOfTotalTime * leftSemiCircleRotation;
        rightSemiCircleRotation = oneTenthOfTotalTime * rightSemiCircleRotation;

        $('.loading-spinner__left__progress').css({
            '-webkit-transform': 'rotate3d(0,0,1,' + leftSemiCircleRotation + 'deg)',
            '-moz-transform': 'rotate3d(0,0,1,' + leftSemiCircleRotation + 'deg)',
            '-ms-transform': 'rotate(0,0,1,' + leftSemiCircleRotation + 'deg)',
            '-o-transform': 'rotate3d(0,0,1,' + leftSemiCircleRotation + 'deg)',
            'transform': 'rotate3d(0,0,1,' + leftSemiCircleRotation + 'deg)'
        });
        $('.loading-spinner__right__progress').css({
            '-webkit-transform': 'rotate3d(0,0,1,' + rightSemiCircleRotation + 'deg)',
            '-moz-transform': 'rotate3d(0,0,1,' + rightSemiCircleRotation + 'deg)',
            '-ms-transform': 'rotate(0,0,1,' + rightSemiCircleRotation + 'deg)',
            '-o-transform': 'rotate3d(0,0,1,' + rightSemiCircleRotation + 'deg)',
            'transform': 'rotate3d(0,0,1,' + rightSemiCircleRotation + 'deg)'
        });
        $('.loading-spinner').attr('data-progress', Math.floor(timeLeftValue));
    }

    $interval(function() {
        if (!_this.isPaperSystemIdleVisible){
            // We set 61 seconds instead of 60 because modal
            // takes about 2 seconds to appear
            timeLeft = 61;
        } else {
            timeLeft -= 1;
            updateLoadingCircle(timeLeft);
            if (timeLeft < 1) {
                timeLeft = 1;
            }
        }
    }, 1000);
}

angular
.module('payhqUIApp')
.component('paperSystemIdle', {
    bindings: {},
    controller: paperSystemIdle,
    controllerAs: 'paperSystemIdle',
    templateUrl: 'component/paper-system-idle/paper-system-idle.html'
});