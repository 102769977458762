import angular from 'angular';

/**
 * @ngdoc factory
 * @name payhqUIApp.customerApp.factory:CustomerCardFactory
 * @description
 *
 * Factory for creating customer card objects
 *
 */
angular
.module('customerApp')
.factory('CustomerCardFactory', function (CardService) {

    /**
     * Customer Card Object constructor.
     *
     * @constructor
     * @param {Object} initialData - Data to initialize customer card object with
     */
    var CustomerCard = function (initialData) {
        var self = this;
        var cardData = {
            id: '',
            lookup_id: '',
            customer_id: '',
            card_number: '',
            card_prefix: '',
            card_suffix: '',
            card_expiry: '',
            card_expiry_month: '',
            card_expiry_year: '',
            card_cvv: '',
            card_cvv2: '',
            card_description: '',
            card_type: '',
            is_default: false,
            approved_currencies: [],
            is_expired: false,
            almost_expired: false,
            months_until_expired: null
        };

        /**
         * Parse and store appropriate card properties
         *
         * @param {Object} data - Customer card data to parse
         */
        function parseData(data) {

            // Add additional properties if any
            if (data) {
                if (data.card_number) {
                    var cardNumber = data.card_number;
                    self.card_prefix = CardService.getCardPrefix(cardNumber);
                    self.card_suffix = CardService.getCardSuffix(cardNumber);
                }
                self.card_cvv2 = self.card_cvv;
                self.card_type = CardService.getCardType(self.card_prefix);

                // Set card expiry month and year
                var cardExpiry = data.card_expiry;
                if (cardExpiry) {
                    var cardExpiryObj = CardService.getCardExpiry(cardExpiry);
                    if (cardExpiryObj) {
                        angular.extend(self, cardExpiryObj);
                    }
                }
                // Check if card is expired
                self.is_expired = CardService.getCardExpiryStatus(self.card_expiry_month,self.card_expiry_year);
                // Check if card is about to expire
                self.months_until_expired = CardService
                                                .getCardMonthsUntilExpiry(self.card_expiry_month,
                                                    self.card_expiry_year);
                // If card is about to expire set almost expired to trues
                if (self.months_until_expired >= 0 && self.months_until_expired < 4){
                    self.almost_expired = true;
                }
                // Approved currencies
                self.approved_currencies = [];
                var allApprovedCurrencies = data.approved_currencies;
                if (allApprovedCurrencies) {
                    for (var key in allApprovedCurrencies){
                        if (allApprovedCurrencies.hasOwnProperty(key)){
                            self.approved_currencies.push(allApprovedCurrencies[key]);
                        }

                    }
                }
            }
        }

        /**
         * Get card info.
         *
         * @returns {Object} cardData - Returns the card's data
         */
        this.getCardInfo = function () {
            return cardData;
        };

        /**
         * Get card approved currencies.
         *
         * @returns {Array} cardApprovedCurrencies - Returns the card's approved
         * currencies in an array format.
         */
        this.getCardApprovedCurrency = function () {
            return self.approved_currencies;
        };

        /**
         * Get card approved currencies display.
         *
         * @returns {String} cardCurrencies - Returns the card's approved currencies
         * in a readable display format
         */
        this.getCardApprovedCurrencyLabel = function () {
            var currencies = '';
            angular.forEach(self.approved_currencies, function (value) {
                currencies += value + ' ';
            });

            return currencies;
        };

        /**
         * Get card customer id.
         *
         * @returns {Number} cardCustomerId - Returns the card customer id
         */
        this.getCardCustomerId = function () {
            return self.customer_id;
        };

        /**
         * Get card description.
         *
         * @returns {String} cardDescription - Returns the card description
         */
        this.getCardDescription = function () {
            return self.card_description;
        };

        /**
         * Get card expiry.
         *
         * @returns {String} cardExpiry - Returns the card expiry
         */
        this.getCardExpiry = function () {
            return self.card_expiry;
        };

        /**
         * Get card id.
         *
         * @returns {Number} cardId - Returns the card id
         */
        this.getCardId = function () {
            return self.id;
        };

        /**
         * Get card default value.
         *
         * @returns {Boolean} isDefault - Returns the card's default setting
         */
        this.getCardIsDefault = function () {
            return !!(self.is_default === true || self.is_default === 1);
        };

        /**
         * Get card lookup id.
         *
         * @returns {Number} cardLookupId - Returns the card lookup id
         */
        this.getCardLookupId = function () {
            return self.lookup_id;
        };

        /**
         * Get card select option name for dropdowns.
         *
         * @returns {String} cardOptionName - Returns the name to display in select options
         */
        this.getCardOptionName = function () {
            var cardOptionName;

            var cardType = this.getCardType();
            cardType = cardType.charAt(0).toUpperCase() + cardType.slice(1);

            cardOptionName = cardType + ' ' + this.getCardSuffix();

            var cardDescription = this.getCardDescription();
            if (cardDescription) {
                cardOptionName += ' - ' + cardDescription;
            }

            // Handle long card names
            if (cardOptionName.length > 40) {
                cardOptionName = cardOptionName.substring(0, 40) + '...';
            }

            return cardOptionName;
        };

        /**
         * Get card number value.
         *
         * @returns {String} cardNumber - Returns the card number value
         */
        this.getCardNumber = function () {
            return self.card_number;
        };

        /**
         * Get card number masked value.
         *
         * @returns {String} cardNumberMaskedValue - Returns the card number masked value
         */
        this.getCardNumberMaskedValue = function () {
            var cardNumberMaskedValue = '************' + self.card_suffix;
            if (self.card_type === 'amex') {
                cardNumberMaskedValue = '***********' + self.card_suffix;
            }
            return cardNumberMaskedValue;
        };

        /**
         * Get card cvv value.
         *
         * @returns {String} cardCvv - Returns the card cvv value
         */
        this.getCardCvv = function () {
            return self.card_cvv;
        };

        /**
         * Get card cvv masked value.
         *
         * @returns {String} cardCvvMaskedValue - Returns the card cvv masked value
         */
        this.getCardCvvMaskedValue = function () {
            var cardCvvMaskedValue = '***';
            if (self.card_type === 'amex') {
                cardCvvMaskedValue = '****';
            }
            return cardCvvMaskedValue;
        };

        /**
         * Get card suffix.
         *
         * @returns {String} cardSuffix - Returns the card suffix
         */
        this.getCardSuffix = function () {
            return self.card_suffix;
        };

        /**
         * Get card type.
         *
         * @returns {String} cardType - Returns the card type
         */
        this.getCardType = function () {
            return self.card_type;
        };

        /**
         * Get is expiry.
         *
         * @returns {Boolean} isExpired - Returns true if the card is expired.
         *    False otherwise.
         */
        this.getIsExpired = function () {
            return self.is_expired;
        };

        /**
         * Get is almost_expired
         *
         * @returns {Boolean} almost_expired - Returns true if the card is almost expired
         *    False otherwise.
         */
        this.getIsAlmostExpired = function () {
            return self.almost_expired;
        };

        /**
         * Set card as default.
         */
        this.setAsDefault = function () {
            self.is_default = 1;
            cardData.is_default = 1;
        };

        /**
         * Set customer card info.
         *
         * @param {Object} data - Data to set customer card object with
         * @param {Boolean} nullNotAllowed - (Optional) Allow null values to override properties.
         * By default, this is set to false - any value is allowed to support overriding.
         * Set to true if only non-null values are allowed.
         */
        this.setCardInfo = function (data, nullNotAllowed) {
            angular.forEach(data, function (value, key) {
                if (self.hasOwnProperty(key) && (!nullNotAllowed || (nullNotAllowed && value)) ) {
                    self[key] = value;
                }
            });
            parseData(data);

            for (var key in self) {
                if (cardData.hasOwnProperty(key) && typeof self[key] !== 'function') {
                    var value = self[key];
                    if (!nullNotAllowed || (nullNotAllowed && value)) {
                        cardData[key] = value;
                    }
                }
            }
        };

        // Initialize customer data
        angular.extend(this, cardData);
        self.setCardInfo(initialData);

        return self;
    };

    return {
        parseCards: function(cards){
            return _.map(cards,function(card){
                card.card_type = CardService.getCardType(card.card_prefix);
                return card;
            });
        },
        /**
         * Create a customer card request object.
         *
         * @param {Object} data - Data to set customer card to
         * @returns {Object} CustomerCard - Returns a customer card object
         */
        createCustomerCard: function (data) {
            return new CustomerCard(data);
        },
        /**
         * Create a customer card response object.
         *
         * @param {Object} data - Data to set customer card response to
         * @param {Object} originalRequestCustomerCard - Additional data to set
         * customer card response to
         * @returns {Object} CustomerCard - Returns a customer card response object
         */
        parseResponse: function (data, originalRequestCustomerCard) {
            var newRequestCustomerCard = new CustomerCard(data);
            if (originalRequestCustomerCard) {
                newRequestCustomerCard.setCardInfo(originalRequestCustomerCard, true);
            }
            return newRequestCustomerCard;
        }
    };
});
