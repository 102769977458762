import angular from 'angular';

angular
.module('payhqUIApp')
.service('HttpAuthErrorInterceptorService', function ($translate, $location, SessionService) {

    let shouldDisplayErrorModal = true;

    function requireSessionCheck() {
        return SessionService.requireSessionCheck($location.path());
    }

    function setupHttpErrorHandler() {
        window.PAYFIRMA.CommunicationService.onHttpAuthSuccessfulEvent(function () {
            shouldDisplayErrorModal = true;
        });

        window.PAYFIRMA.CommunicationService.onHttpAuthErrorEvent(function (response) {
            if (requireSessionCheck() && response && response.data) {
                var error = response.data.error || response.data;
                if (window.PAYFIRMA.ServerErrorService.isUnauthorized(error) ||
                    window.PAYFIRMA.ServerErrorService.isInvalidToken(error) ||
                    window.PAYFIRMA.ServerErrorService.isAuthorizationServiceError(error)) {
                    if ($location.$$url !== '/settings/hpp' && !window.PAYFIRMA.ServerErrorService.isAuthorizationServiceError(error)) {
                        $translate(['invalidToken', 'session_kick'])
                        .then(function (translations) {
                            if (shouldDisplayErrorModal) {
                                var messages = {
                                    title: translations.invalidToken,
                                    subtitle: translations.session_kick
                                };
                                window.PAYFIRMA.CommunicationService
                                    .triggerSystemErrorEnabledEvent(messages);
                                shouldDisplayErrorModal = false;
                            }
                        });
                    }
                } else if (window.PAYFIRMA.ServerErrorService
                        .isSystemUnderMaintenance(error)) {
                    $translate(['systemMaintanance, system_maintainance_description'])
                        .then(function (translations) {
                            var messages = {
                                title: translations.systemMaintanance,
                                subtitle: translations.system_maintainance_description
                            };
                            window.PAYFIRMA.CommunicationService
                                .triggerSystemErrorEnabledEvent(messages);
                        });
                } else if (window.PAYFIRMA.ServerErrorService.isSessionExpired(error)) {
                    $translate(['sessionExpired', 'session_expired'])
                        .then(function (translations) {
                            var messages = {
                                title: translations.sessionExpired,
                                subtitle: translations.session_expired
                            };
                            window.PAYFIRMA.CommunicationService
                                .triggerSystemErrorEnabledEvent(messages);
                        });
                } else if (window.PAYFIRMA.ServerErrorService
                        .isAccountDisabled(error)) {
                    $translate(['accountDisabled', 'inactivityMessage'])
                        .then(function (translations) {
                            var messages = {
                                title: translations.accountDisabled,
                                subtitle: translations.inactivityMessage
                            };
                            window.PAYFIRMA.CommunicationService
                                .triggerSystemErrorEnabledEvent(messages);
                        });
                }
            }
        });
    }

    return {
        initialize: function () {
            setupHttpErrorHandler();
        }
    };
});
