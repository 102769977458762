import moment from 'moment';
/*
 * JavaScript Constants
 */
window.PAYFIRMA = window.PAYFIRMA || {};

window.PAYFIRMA.CONSTANT = {
    NEW_INVOICE: 'new',
    DRAFT_INVOICE: 'draft',
    CLONE_INVOICE: 'clone',
    HEAP_EVENT: {
        TERMINAL: {
            TAB_SALE: 'heap-terminal-tab-sale',
            TAB_AUTHORIZE: 'heap-terminal-tab-authorize',
            TAB_CAPTURE: 'heap-terminal-tab-capture',
            TAB_REFUND: 'heap-terminal-tab-refund',
            SALE: 'heap-terminal-sale',
            AUTHORIZE: 'heap-terminal-authorize',
            CAPTURE: 'heap-terminal-capture',
            REFUND: 'heap-terminal-refund',
            OPEN_REFUND: 'heap-terminal-open-refund',
            MORE_DETAILS: 'heap-terminal-more-details',
            LESS_DETAILS: 'heap-terminal-less-details'
        },
        TRANSACTIONS: {
            MORE_DETAILS: 'heap-transactions-more-details',
            LESS_DETAILS: 'heap-transactions-less-details',
            SEND_RECEIPT: 'heap-transactions-send-receipt',
            PRINT_RECEIPT: 'heap-transactions-print-receipt',
            CAPTURE_PAYMENT: 'heap-transactions-capture-payment'
        },
        NAVIGATION: {
            LOGO: 'heap-navigation-logo',
            COMPACT: 'heap-navigation-compact',
            EXPAND: 'heap-navigation-expand'
        }
    },
    EPOCH_FORMAT: {
        DATE_STANDARD: 'MMM DD, YYYY',
        TIME_STANDARD: 'hh:mm A'
    },
    CURRENCY: {
        CAD: 'CAD',
        USD: 'USD'
    },
    EVENT: {
        HTML_TOAST_MESSAGE_CLICKED: 'HTML_TOAST_MESSAGE_CLICKED'
    },
    UI_PAGINATION_LIMIT: 40,
    PAGINATION_INITIAL_LOAD_BATCH_SIZE: 200,
    PLAN_PAGINATION_LIMIT: 1000,
    STANDARD_TIME_FORMAT: 'dddd MMMM D, YYYY, h:mma',
    PAGINATION_LOAD_BATCH_SIZE: 4000,
    BEGINNING_CURSOR: 'PAGINATION.BEGINNING_CURSOR',
    DEFAULT_CALENDAR_START_DATE: moment().subtract('days', 30),
    DEFAULT_CALENDAR_END_DATE: moment(),
    IMAGE_BASE_LOCATION: 'app/css/images/'
};

window.PAYFIRMA.TOKEN_VALIDITIES = [
    {
        key: '10 Minutes',
        value: 600
    },
    {
        key: '20 Minutes',
        value: 1200
    },
    {
        key: '30 Minutes',
        value: 1800
    },
    {
        key: '45 Minutes',
        value: 2700
    },
    {
        key: '1 Hour',
        value: 3600
    },
    {
        key: '4 Hours',
        value: 14400
    },
    {
        key: '12 Hours',
        value: 43200
    },
    {
        key: '1 Day',
        value: 86400
    },
    {
        key: '2 Days',
        value: 172800
    },
    {
        key: '1 Week',
        value: 604800
    },
    {
        key: '1 Year',
        value: 31622400
    },
    {
        key: '10 Years',
        value: 316224000
    }
];

window.PAYFIRMA.DEFAULT_ACCESS_TOKEN_VALIDITY = 43200;

window.PAYFIRMA.DEFAULT_REFRESH_TOKEN_VALIDITY = 365 * 24 * 3600; //extend refresh token validity to a year

window.PAYFIRMA.RESET_PASSWORD_QUERY_PARAM = 'resetpassword';

window.PAYFIRMA.TRANSACTION_TYPE = {
    SALE: 'purchase',
    AUTHORIZE: 'authorize',
    CAPTURE: 'capture',
    NAKED_REFUND: 'naked_refund',
    REFUND: 'refund',
    VOID: 'void',
    CASH_PURCHASE: 'CASH_PURCHASE',
    CHECK_PURCHASE: 'CHECK_PURCHASE',
    EXTERNAL_PURCHASE: 'EXTERNAL_PURCHASE',
    CASH_REFUND: 'CASH_REFUND',
    CHECK_REFUND: 'CHECK_REFUND',
    EXTERNAL_REFUND: 'EXTERNAL_REFUND',
    EFT_CREDIT: 'EFT_CREDIT',
    EFT_DEBIT: 'EFT_DEBIT',
    GOOGLE_PAY_PURCHASE: 'GOOGLE_PAY_PURCHASE'
};

let Constants = window.PAYFIRMA.CONSTANT;

export default Constants;