import angular from 'angular';
import SessionStorageService from 'service/session-storage-service';
import ConfigurationService from 'service/configuration-service';

function newReportingSystem(NewReportingSystemService) {
    let _this = this;

    function encryptData(data) {
        return NewReportingSystemService.encryptData(data).then(function (response) {
            _this.loading = false;
            return response;
        }).catch(function (errors) {
            let errorMessage = _this.convertErrorMessage(errors);
            _this.loading = false;
            throw new Error(errorMessage);
        });
    }

    function redirect() {
        //Get env config json, stringify it.
        let hqconfig = ConfigurationService.getConfig();

        // Get JWT and Refresh tokens from current session
        var sessionObject = SessionStorageService.get().getCurrentUserInfo();
        const jwt = sessionObject.jwt;
        const refreshToken = sessionObject.refreshToken;

        //Encrypt the access tokens and environment config
        //Encrypt(configStr)
        const encryptedJWT = encryptData({ 'text_to_encrypt':jwt });
        const encryptedRefreshToken = encryptData({ 'text_to_encrypt':refreshToken });

        //Wait for api results before continuing
        Promise.all([encryptedJWT, encryptedRefreshToken]).then((values) => {
            //Encode access tokens and environment config to be URL-Safe
            const encodedJwt = encodeURIComponent(values[0]);
            const encodedRefreshToken = encodeURIComponent(values[1]);

            //Form the url, Clear session and redirect.
            const newReportingSystemUrl = hqconfig.reportingSystemUrl;
            const redirectUrl = newReportingSystemUrl + `?authToken=${encodedJwt}&refresh_token=${encodedRefreshToken}`;
            window.sessionStorage.clear();
            window.location.href = redirectUrl;
        }).catch((error) => {
            // Handle any errors from the encryption promises
            let errorMessage = _this.convertErrorMessage(error);
            _this.loading = false;
            throw new Error(errorMessage);
        });
    }

    _this.$onInit = function(){
        redirect();
    };
}

angular
.module('payhqUIApp')
.component('newReportingSystem', {
    bindings: {
        list: '='
    },
    controller: newReportingSystem,
    controllerAs: 'newReportingSystem',
    templateUrl: 'view/new-reporting-system/new-reporting-system.html'
});
