import angular from 'angular';

import CONSTANT_APP from 'constant/constant-app';
import CONSTANT_COMPONENT from 'constant/constant-component';

const APP_MODULE = CONSTANT_APP.module;
const COMPONENT_BILLING = CONSTANT_COMPONENT.billing;

function billing(SessionService, Utils, $route, PaperToastService, $log,
    PlanService, UrlService, HeapService, HeapMessage) {

    var checkUserPermission = SessionService.checkUserPermission;
    var _this = this;
    var defaultErrorMessage = 'Recurring plan operation failed';

    _this.orderListBy = null;
    // Max Number of rows per list
    _this.listLimit = 150;
    // Array: Frequency of Billing
    _this.billingFrequency = PlanService.getAllBillingFrequencies();
    // Object: New Plan
    _this.plan = {};

    // Initialize
    _this.$onInit = function () {
        _this.availableCurrencies =
            PlanService.getAvailableCurrencyOptions(SessionService.getCurrentMerchantAvailableCurrencies());
        _this.sortBy('name');
    };

    // Create Plan
    // uses _this.plan (billing.plan) in this controller
    _this.createPlan = function () {

        _this.plan.isVisible = true;
        _this.plan.show = true;
        _this.plan.send_receipt = false;
        _this.plan.currency = SessionService.getCurrentMerchantDefaultCurrency();

        _this.plan.save = function (form) {
            if (form.$valid) {
                _this.plan.saving = true;

                //set up plan object to post
                var newPlanObject = {
                    amount: _this.plan.amount,
                    currency: _this.plan.currency,
                    number_of_payments: _this.plan.number_of_payments,
                    frequency: _this.plan.frequency,
                    name: _this.plan.name,
                    send_receipt: _this.plan.send_receipt
                };
                //post new plan object
                return PlanService.create(newPlanObject)
                    .then(function (plan) {
                        PaperToastService.create('New plan created.', 'update');

                        //Heap Track
                        HeapService.heapTrack(HeapMessage.TYPE_RECURRING, HeapMessage.MESSAGE_RECURRING_ADDED);

                        _this.plan.saving = false;
                        $route.reload();
                        return plan;
                    })
                    .catch(function (error) {
                        $log.error(error);
                        _this.plan.saving = false;
                        var errorMessage = window.PAYFIRMA.ServerErrorService.extractErrorMessage(error);
                        if (errorMessage) {
                            PaperToastService.create(defaultErrorMessage + ' - ' + errorMessage, 'error');
                        } else {
                            PaperToastService.create(defaultErrorMessage, 'error');
                        }
                        throw error;
                    });
            } else {
                PaperToastService.create('Fill in Required Fields.', 'error');
            }
        };
        _this.plan.cancel = function () {
            _this.plan.isVisible = false;
        };
    };

    // Edit Plan
    // plan object is the scope from ng-repeat in view
    _this.editPlan = function (plan) {
        var orginalPlanConfig = Utils.cloneObject(plan);
        plan.showEditPlan = !plan.showEditPlan;
        plan.editData = true;
        plan.button = 'false';
        plan.isRequiredError = {};
        plan.isRequiredError.email = true;
        plan.confirmDelete = false;

        // close expanded info and form
        plan.cancel = function () {
            plan.showEditPlan = false;
            angular.forEach(orginalPlanConfig, function (value, key) {
                if (plan.hasOwnProperty(key) && value !== null) {
                    plan[key] = value;
                }
            });
        };

        /**
         * Set up exsisting plans using plan object passed in from ng-repeat
         *
         * @param {Object} form - Pass in current form object
         */
        plan.update = function (form) {
            if (form.$valid) {
                //loading function for editting plan
                plan.saving = true;

                var updatedPlanObject = {
                    name: plan.name,
                    amount: plan.amount,
                    currency: plan.currency,
                    frequency: plan.frequency,
                    number_of_payments: plan.number_of_payments,
                    send_receipt: plan.send_receipt
                };

                return PlanService.update(plan.lookup_id, updatedPlanObject)
                    .then(function (updatedPlan) {
                        if (updatedPlan) {
                            plan.showEditPlan = false;
                            $log.info('Updated Plan:' + updatedPlan.id);
                            plan.saving = false;
                            PaperToastService.create('Recurring plan updated.', 'update');
                            $route.reload();
                        }
                        return updatedPlan;
                    })
                    .catch(function (error) {
                        $log.error(error);
                        plan.saving = false;
                        var errorMessage = window.PAYFIRMA.ServerErrorService.extractErrorMessage(error);
                        if (errorMessage) {
                            PaperToastService.create(defaultErrorMessage + ' - ' + errorMessage, 'error');
                        } else {
                            PaperToastService.create(defaultErrorMessage, 'error');
                        }
                        return error;
                    });
            } else {
                PaperToastService.create('Fill in Required Fields.', 'error');
            }
        };
    };

    // Delete Plan
    // plan object is the scope from ng-repeat in view
    _this.deletePlan = function (plan) {
        plan.isDeleteVisible = true;
        plan.cancel = function () {
            plan.isDeleteVisible = false;
        };
        plan.confirmDeletePlan = function () {
            plan.deleting = true;

            return PlanService.delete(plan.lookup_id)
                .then(function () {
                    plan.deleting = false;
                    PaperToastService.create('Recurring plan deleted', 'update');
                    $route.reload();
                    return true;
                })
                .catch(function (error) {
                    $log.error(error);
                    plan.deleting = false;
                    var errorMessage = window.PAYFIRMA.ServerErrorService.extractErrorMessage(error);
                    if (errorMessage) {
                        PaperToastService.create(defaultErrorMessage + ' - ' + errorMessage, 'error');
                    } else {
                        PaperToastService.create(defaultErrorMessage, 'error');
                    }
                    throw error;
                });
        };
    };

    function sortColumn($event) {

        var targetElement = $($event.target);
        var sortableColumn = $('[sort]');
        //set tabel sort function and display arrow class
        if (targetElement.hasClass('ascending')) {
            _this.orderListBy = '-' + window.parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort').addClass('decending');
        } else if (targetElement.hasClass('decending')) {
            _this.orderListBy = window.parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort').addClass('ascending');
        } else if (targetElement.hasClass('no-sort')) {
            _this.orderListBy = window.parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort');
            targetElement.addClass('ascending');
        }
    }

    _this.hasHPPLink = function(){
        return checkUserPermission('HppLink');
    };

    _this.sortBy = function (columnNameSelected, $event) {
        _this.billingSortKey = Utils.updateSortKey(_this.billingSortKey, columnNameSelected);
        if ($event) {
            sortColumn($event);
        }
    };

    _this.gotoCustomerVaultRecurring = function (planLookupId) {
        UrlService.go('/customer-vault/recurring/' + planLookupId);
    };

    _this.isPaginationLoading = false;

    _this.getPaginationNext = function() {
        return PlanService.get({
            after : _this.paging.after
        })
            .then(function(allPlansObject){
                _this.isPaginationLoading = false;
                _this.paging = allPlansObject.paging;
                _this.bills = _this.bills.concat(allPlansObject.entities);
                return true;
            });
    };

    /**
     * Infinite scrolling, firing once user hits bottom of the page.
     */
    _this.lazyLoad = function() {
        //check if next page value and grab transactions
        if (_this.paging && _this.paging.nextCursor()) {
            _this.isPaginationLoading = true;
            //prevent duplicate api calls when scrolling with 1s timeout
            _this.scrollTimeout = setTimeout(function() {
                _this.getPaginationNext();
            }, 0);
        }
    };

}

angular
.module(APP_MODULE)
.component(COMPONENT_BILLING, {
    bindings: {
        bills: '<',
        paging: '<'
    },
    controller: billing,
    controllerAs: 'billing',
    templateUrl: 'view/billing/billing.html'
});