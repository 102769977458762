function displayCustomError(element, message) {
    var defaultErrorMessageElement = element[0].querySelector('#defaultErrorMessage');
    var customErrorMessageElement = element[0].querySelector('#customErrorMessage');

    if (message) {
        if (customErrorMessageElement) {
            customErrorMessageElement.style.display = 'block';
            customErrorMessageElement.textContent = message;
        }
        if (defaultErrorMessageElement) {
            defaultErrorMessageElement.style.display = 'none';
        }
    } else { //hide custom message
        if (customErrorMessageElement) {
            customErrorMessageElement.style.display = 'none';
            customErrorMessageElement.textContent = '';
        }
        if (defaultErrorMessageElement) {
            defaultErrorMessageElement.style.display = 'block';
        }
    }
}

let ValidatorLocator = {
    validateMaxLength: function (element, maxLength) {
        return function (ngModelValue) {
            var message = 'Exceeds Max Length of ' + maxLength;

            if (maxLength && !isNaN(maxLength) && ngModelValue && ngModelValue.length > maxLength) {
                displayCustomError(element, message);
                return false;
            }

            displayCustomError(element, '');
            return true;
        };
    },
    validateEmail: function(value){
        return value && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
    }
};
export default ValidatorLocator;