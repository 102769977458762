import angular from 'angular';
import ConfigurationService from 'service/configuration-service';

angular.module('payhqUIApp')
    .service('OauthTokenService', function (HttpClientService, SessionService, $log) {
        function isResponseValid(response) {
            return response && response.data && response.data.access_token && response.data.refresh_token;
        }

        return {
            refreshTokenAndUpdateSession: function () {
                let hqConfig = ConfigurationService.getConfig();
                var jwtClientId = hqConfig.jwtClientId;
                var refreshToken = SessionService.getRefreshToken();
                //https://github.com/witoldsz/angular-http-auth，
                //no need to go through 401 auth interceptor again when
                //refresh token because if fail we should directly go to the login page
                return HttpClientService.doFormPost(hqConfig.hqJwtTokenUrl + 'payhq/token',
                    {
                        grant_type: 'refresh_token',
                        refresh_token: refreshToken,
                        client_id: jwtClientId
                    }, {}, true, true)
                    .then(function (response) {
                        if (isResponseValid(response)) {
                            let tokenData = response.data;
                            SessionService.updateTokens(tokenData.access_token, tokenData.refresh_token);
                            return true;
                        } else {
                            throw new Error('Cannot get refresh token');
                        }
                    })
                    .catch(function (error) {
                        throw error;
                    });
            },
            revokeTokenAndLogout: function () {
                let hqConfig = ConfigurationService.getConfig();
                let accessToken = SessionService.getAccessToken();

                if (accessToken) {
                    return HttpClientService.doFormDelete(hqConfig.hqJwtTokenUrl + 'oauth/revoke_token',
                        {
                            headers: {
                                'Authorization': 'Bearer ' + accessToken
                            }
                        }, {}, true)
                        .then(function() {
                            SessionService.deleteAllStoredLogInSessionsAndRedirectToLogin();
                            return true;
                        }).catch(function (error) {
                            $log.error(error);
                            SessionService.deleteAllStoredLogInSessionsAndRedirectToLogin();
                            throw error;
                        });
                }
            },
            revokeTokenWithUserId: function (userId) {
                let hqConfig = ConfigurationService.getConfig();
                let accessToken = SessionService.getAccessToken();

                if (accessToken) {
                    return HttpClientService.doFormDelete(hqConfig.hqJwtTokenUrl + 'oauth/revoke_token/' + userId,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + accessToken
                            }
                        }, {}, true)
                        .then(function() {
                            return true;
                        }).catch(function (error) {
                            $log.error(error);
                            throw error;
                        });
                }
            },
            revokeTokenAndLogoutForLoggedInAsUser: function(){
                let hqConfig = ConfigurationService.getConfig();

                let accessToken = SessionService.getAccessToken();

                if (accessToken) {
                    return HttpClientService.doFormDelete(hqConfig.hqJwtTokenUrl + 'oauth/revoke_token',
                        {
                            headers: {
                                'Authorization': 'Bearer ' + accessToken
                            }
                        }, {}, true)
                        .then(function () {
                            SessionService.deleteStoredLogInAsUserSessionAndRedirectToMerchant();
                        }).catch(function (error) {
                            $log.error(error);
                            SessionService.deleteAllStoredLogInSessionsAndRedirectToLogin();
                            throw error;
                        });
                }
            }
        };
    });