import angular from 'angular';

var testFunc;
var doesPaperTooltipElementExist = false;

function paperTooltip($scope, $element) {
    var _this = this;
    // Set by placing element on screen. Can use ng-if as a toggle.
    doesPaperTooltipElementExist = true;

    _this.label = '';
    // Sets label and top cordinate
    testFunc = function(label, coordinate){
        _this.label = label;
        $element.css({ 'top': coordinate });
        $scope.$digest();
    };
}
angular
.module('payhqUIApp')
.component('paperTooltip', {
    bindings: {
    },
    controller: paperTooltip,
    controllerAs: 'paperTooltip',
    templateUrl: 'component/paper-tooltip/paper-tooltip.html'
});

// paper-tooltip Directive attribute for elements
// use it to set a desired label
angular
.module('payhqUIApp')
.directive('paperTooltip',
    function () {
        return {
            restrict: 'A',
            controller: function($scope, $element, $attrs){
                var thisElement = $element[0];
            // On mouse over, get label, and get top cordinate
                thisElement.onmouseover = function(){
                    if (doesPaperTooltipElementExist){
                        var label = $attrs.paperTooltip;
                        var rect = thisElement.getBoundingClientRect();
                    // -35 pixels offset comes from the header
                        var topCordinate = rect.top - 35;
                        testFunc(label, topCordinate);
                    }
                };
            // On mouse out, empty label and give off-screen cordinates.
                thisElement.onmouseout = function(){
                    if (doesPaperTooltipElementExist){
                        var label = '';
                    // -3000 pixels from the top pushes it off-screen
                        var topCordinate = -3000;
                        testFunc(label, topCordinate);
                    }
                };
            }
        };
    });