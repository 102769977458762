import angular from 'angular';

function deletePhotoModal(ImageStore, $log, Utils, $scope) {
    var self = this;
    self.isLoading = false;

    function close() {
        self.isLoading = false;
        self.componentEnabled = false;
        Utils.forceScopeApply($scope);
    }

    self.cancel = function () {
        close();
    };

    self.delete = function () {
        self.isLoading = true;
        ImageStore.deleteImage()
            .then(function () {
                close();
            })
            .catch(function (error) {
                $log(error);
                close();
            });
    };
}

angular
.module('payhqUIApp')
.component('deletePhotoModal', {
    bindings: {
        componentEnabled: '='
    },
    controller: deletePhotoModal,
    controllerAs: 'deletePhotoModal',
    templateUrl: 'view/image-editing/modal/delete-photo-modal.html'
});