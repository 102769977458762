import angular from 'angular';
import CONSTANT_APP from 'constant/constant-app';
import CONSTANT_COMPONENT from 'constant/constant-component';
import SESSION from 'constant/session-constant';
import EventEmitterService from 'service/event-emitter-service';
import SessionStorageService from 'service/session-storage-service';


function paperHeader($scope, SessionService, $location) {
    let _this = this;

    _this.username = '';

    _this.go = function(value){
        $location.path(value);
    };

    _this.hasSettingsPermission = function () {
        return SessionService.checkUserPermission(window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.SETTINGS]);
    };
    EventEmitterService.onEvent(SESSION.SESSION_OBJECT_UPDATED, () => {
        const user = SessionStorageService.get().getUser();
        if (user) {
            _this.businessname = user.business_information.name || '';
            _this.username = user.getFullName();
        }
    });
}
angular
.module(CONSTANT_APP.module)
.component(CONSTANT_COMPONENT.paper_header, {
    bindings: {
    },
    controller: paperHeader,
    controllerAs: 'paperHeader',
    templateUrl: 'component/paper-header/paper-header.html'
});