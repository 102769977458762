import angular from 'angular';

function customerVaultRecurring(UrlService, CustomerService, PaperToastService) {
    var _this = this;
    _this.filterinitplan = _this.customerrecurringdata.planLookupId;
    _this.editCustomer = function (customerLookupId) {
        UrlService.go('/customer-vault/edit/' + customerLookupId);
    };
    _this.recurringPlanCustomers = [];
    _.forEach(_this.customerrecurringdata.recurringCustomers, function(customer){
        _.forEach(customer.subscriptions, function(subscription){
            if (_this.customerrecurringdata.planLookupId === subscription.plan_lookup_id){
                var recurringPlanCustomer = {
                    id: customer.id,
                    lookup_id: customer.lookup_id,
                    first_name: customer.first_name,
                    last_name: customer.last_name,
                    company: customer.company,
                    email: customer.email,
                    card_on_file: customer.default_card_type,
                    creation_date: customer.creation_date,
                    recurring_plan_id: subscription.plan_id,
                    status: subscription.plan_status
                };
                _this.recurringPlanCustomers.push(recurringPlanCustomer);
            }
        });
    });

    _this.export = function () {
        _this.exportButtonClicked = true;

        return CustomerService.exportCustomerSubscriptions(_this.customerrecurringdata.planLookupId,
            _this.filterplanstatus.status)
            .then(function () {
                _this.exportButtonClicked = false;
            })
            .catch(function (error) {
                _this.exportButtonClicked = false;
                let errorMessage = 'Could not export File';
                if (error && error.message) {
                    errorMessage = error.message;
                }
                PaperToastService.create(errorMessage, 'error');
                throw error;
            });
    };
}

angular
.module('payhqUIApp')
.component('customerVaultRecurring', {
    bindings: {
        customerrecurringdata: '='
    },
    controller: customerVaultRecurring,
    controllerAs: 'customerVaultRecurring',
    templateUrl: 'view/vault/recurring/recurring.html'
});