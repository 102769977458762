import angular from 'angular';
import Staff from 'model/staff';
import ConfigurationService from 'service/configuration-service';
import UtilityService from 'service/utility-service';
/**
 * @ngdoc service
 * @name payhqUIApp.userApp.service:UserService
 * @requires UserFactory
 * @description
 *
 * Service for handling user behaviours
 *
 */
angular
    .module('userApp')
    .factory('UserService', function UserService($location, Restangular, UserFactory, HttpClientService) {
        return {
            getUsersByMerchant: function (merchantId) {
                return HttpClientService.doGet(
                    ConfigurationService.getConfig().merchantServiceApi + 'user/merchant/'
                    + merchantId + '?limit=' + window.PAYFIRMA.CONSTANT.PAGINATION_LOAD_BATCH_SIZE)
                    .then(
                        function (response) {
                            let users = [];

                            if (response.data && response.data.entities && response.data.entities.length) {
                                _.each(response.data.entities, function (user) {
                                    users.push(new Staff(user));
                                });
                            }
                            return users;
                        });
            },

            getAllUsers: function () {
                let param = {};

                param.limit = window.PAYFIRMA.CONSTANT.PAGINATION_LOAD_BATCH_SIZE;

                return HttpClientService.doGet(
                    ConfigurationService.getConfig().merchantServiceApi + 'user', param)
                    .then(function (allUsersResponse) {
                        return _.map(_.filter(allUsersResponse.data.entities, function (userResponse) {
                            return userResponse.user_group !== 'SERVER' && userResponse.user_group !== 'API';
                            //exclude internal users
                        }), function (userResponse) {
                            return new Staff(userResponse);
                        });
                    });

            },

            /**
             * Get a user.
             *
             * @param {Number} userId - Id of user to fetch
             * @returns {Object} user - Returns a user object
             */
            getUser: function (userLookupId) {
                return HttpClientService.doGet(
                    ConfigurationService.getConfig().merchantServiceApi + 'user/' + userLookupId)
                        .then(
                            function (response) {
                                return new Staff(response.data);
                            }
                        ).catch(function(e){
                            throw UtilityService.parseErrorMessage(e);
                        });
            },

            /**
             * Get a user.
             *
             * @param {Number} userLookupId - Id of user to fetch
             * @param {Number} merchantId - Id of merchant to fetch
             * @returns {Object} user - Returns a user object
             */
            getMerchantUser: function (userLookupId, merchantId) {
                return HttpClientService.doGet(ConfigurationService.getConfig().merchantServiceApi + 'user/' +
                    userLookupId + '/merchant/' + merchantId)
                    .then(
                        function (response) {
                            return new Staff(response.data, merchantId);
                        }
                    ).catch(function(e){
                        throw UtilityService.parseErrorMessage(e);
                    });
            },

            /**
             * Save a user.
             *
             * @param {Object} user - User object to save
             * @returns {Object} user - Returns a user object
             */
            saveUser: function (user) {

                if (user) {
                    user.updatePermissionsObject();
                }

                // Update an existing user
                if (user && user.id) {

                    // Check the locked_user flag
                    if (!user.locked_user) {
                        user.locked_user = false;
                    }

                    return HttpClientService.doPut(ConfigurationService.getConfig().merchantServiceApi + 'user/' +
                        user.lookup_id, user)
                        .then(
                            function (response) {
                                return new Staff(response.data);
                            }
                        ).catch(function(e){
                            throw UtilityService.parseErrorMessage(e);
                        });


                } else {
                    // Save a new staff
                    return HttpClientService
                        .doPost(
                            ConfigurationService.getConfig().merchantServiceApi + 'user',user
                        ).then(function (response) {
                            return new Staff(response.data);
                        }).catch(function(e){
                            throw UtilityService.parseErrorMessage(e);
                        });
                }
            },
            updateMerchantUser: function (user) {

                // Update an existing user
                if (user && user.lookup_id) {

                    // Check the locked_user flag
                    if (!user.locked_user) {
                        user.locked_user = false;
                    }

                    return HttpClientService.doPut(ConfigurationService.getConfig().merchantServiceApi + 'user/' +
                        user.lookup_id + '/merchant/' + user.merchant_id, user)
                        .then(
                            function (response) {
                                return new Staff(response.data);
                            }
                        ).catch(function(e){
                            throw UtilityService.parseErrorMessage(e);
                        });
                } else {
                    throw new Error('User does not exist');
                }
            },
            deleteUser: function (userLookupId) {
                return HttpClientService
                .doDelete(ConfigurationService.getConfig().merchantServiceApi + 'user/' + userLookupId)
                    .then(function(response){
                        return response;
                    }).catch(function(e){
                        throw UtilityService.parseErrorMessage(e);
                    });
            }
        };
    });