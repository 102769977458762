import angular from 'angular';
import CONSTANT_APP from 'constant/constant-app';
import CONSTANT_COMPONENT from 'constant/constant-component';
import CONSTANT_IMAGE_CATEGORY from 'constant/constant-image-category';
import 'script/app-init';
import 'service/image-editing/stores/ImageStore';
import 'service/image-editing/services/ImageCropService';
import SessionStorageService from 'service/session-storage-service';
import ConfigurationService from 'service/configuration-service';

const APP_MODULE = CONSTANT_APP.module;
const COMPONENT_PROFILE = CONSTANT_COMPONENT.profile;

function profile(UserService, CurrentUserService, $rootScope, $http, $log,
    $location, PaperToastService, SessionService, AuthService,
    ImageStore, $scope, Utils, ImageCropService, HeapService) {

    let _this = this;

    function isAddingUser() {
        return false;
    }

    function isEditingAnotherUser() {
        return !_this.isMyProfile;
    }

    function initializeUserProfileImage() {
        ImageStore.onImageUpdated(function (image) {
            _this.profilePicture = image;
            _this.imageLoaded = true;
            Utils.forceScopeApply($scope);
        });

        if (!isAddingUser()) {
            if (isEditingAnotherUser()) {
                //load picture only if we are editing an existing user
                //adding new user will just load the default image
                var user = _this.merchantuser;
                ImageStore.requestImage({
                    merchantId: user.merchant_id,
                    userId: user.id
                }, CONSTANT_IMAGE_CATEGORY.OTHER_USER_PROFILE_PICTURE);
            } else {
                ImageStore.requestImage({}, CONSTANT_IMAGE_CATEGORY.USER_PROFILE_PICRTURE);
            }
        } else {
            ImageStore.requestDefaultImage(CONSTANT_IMAGE_CATEGORY.OTHER_USER_PROFILE_PICTURE);
        }
    }

    _this.isPhotoUploadEnabled = function () {
        return !(isEditingAnotherUser() || isAddingUser());
    };

    // Initialize
    _this.onInit = function () {
        if (_this.user) {
            _this.currentUser = _this.user.data;
        } else if (_this.merchantuser) {
            _this.currentUser = _this.merchantuser;
        }

        _this.setAvatarChar(_this.currentUser.first_name);

        initializeUserProfileImage();
    };

    _this.isAccessLoginAsUser = function() {
        let currentUser = SessionStorageService.get().getUser();
        return currentUser.isPayfirmaAdmin() && !_this.user;
    };

    _this.canUnlockUser = function(){
        return _this.currentUser.locked_user && CurrentUserService.canUnlockMerchantUser();
    };
    // User functions
    _this.userEdit = function(user) {
        var originalUserObject = Object.assign({}, user);
        user.isEdittingProfile = true;
        _this.setAvatarChar(user.first_name);

        // Close edit mode
        user.cancel = function () {
            if (_this.user || _this.merchantuser) {
                _this.currentUser = originalUserObject;
                _this.setAvatarChar(_this.currentUser.first_name);
                user.isEdittingProfile = false;
            }
        };

        // Update user object and save
        user.update = function(form) {
            var currentEmail = _this.currentUser.email;
            var currentPassword = _this.currentUser.password;

            $rootScope.$broadcast('validateInput');
            if (form.$valid) {
                user.updating = true;
                if (_this.user) {
                    //remove password param if it is blank
                    if (_this.currentUser.password === '') {
                        delete _this.currentUser.password;
                    }

                    return CurrentUserService.updateCurrentUser(_this.currentUser).then(function(response) {
                        user.updating = false;
                        if (response.id) {
                            _this.setAvatarChar(response.first_name);
                            user.password = '';
                            user.isEdittingProfile = false;
                            $rootScope.$broadcast('userNameChange');
                            HeapService.trackUserEmailAndPassword(currentEmail, currentPassword);
                            _this.showMessage('userUpdated');
                            return response;
                        } else {
                            _this.showMessage(null, response.data.error.message, 'error');
                        }
                    }).catch(function(e){
                        user.updating = false;
                        PaperToastService.create(e.data.errors[0].message, 'error');
                        throw e;
                    });
                } else if (_this.merchantuser) {
                    return UserService.updateMerchantUser(_this.currentUser).then(function(response) {
                        user.updating = false;

                        _this.setAvatarChar(response.first_name);
                        _this.showMessage('userUpdated');
                        user.password = '';
                        user.isEdittingProfile = false;
                        $rootScope.$broadcast('userNameChange');
                        HeapService.trackMerchantUserEmailAndPassword(currentEmail, currentPassword);
                        return response;

                    }).catch(function(e){
                        user.updating = false;
                        PaperToastService.create(e.message, 'error');
                        throw e;
                    });
                }
            } else {
                PaperToastService.create('User update  failed.', 'error');
            }
        };

        // Validate user form
        user.validate = function() {
            var isValid = true,
                requiredFields = ['first_name', 'last_name', 'email'],
                requiredFieldsSet = true;

            user.isPasswordRequiredError = false;
            user.isRequiredError = {};
            PaperToastService.close();
            //Check required fields list
            angular.forEach(requiredFields, function(key) {
                user.isRequiredError[key] = false;
                if (!user[key]) {
                    user.isRequiredError[key] = true;
                    isValid = false;
                    requiredFieldsSet = false;
                }
            });

            // If there is an error on the form what message too show
            if (requiredFieldsSet === false && user.isPasswordRequiredError === true) {
                _this.showMessage('formIssues');
            } else if (requiredFieldsSet === false && user.isPasswordRequiredError === false) {
                _this.showMessage('requiredFields');
            }

            return isValid;
        };
    };

    // Set profile avatar letter
    _this.setAvatarChar = function (userFirstName) {
        _this.currentUser.initial = userFirstName.charAt(0);
    };

    // Format any message returned from the api
    _this.formatApiError = function (apiError) {
        var errorMessage = 'API Error';
        if (typeof apiError.data.error !== 'undefined') {
            errorMessage = apiError.data.error.message;
        }
        _this.showMessage(errorMessage);
    };

    // Show toast message
    _this.showMessage = function (messageKind, messageString, messageType) {
        var message;
        var type;

        if (messageString && messageType){
            message = messageString;
            type = messageType;
        } else {
            switch (messageKind) {
                case 'userUpdated':
                    message = 'User successfully updated!';
                    type = 'update';
                    break;
                case 'formIssues':
                    message = 'There are problems with your form.';
                    type = 'error';
                    break;
                case 'passwordsDontMatch':
                    message = 'Your confirm password does not match.';
                    type = 'error';
                    break;
                case 'requiredFields':
                    message = 'Please check for required fields.';
                    type = 'error';
                    break;
                default:
                    message = 'There was an unknown issue.';
                    type = 'error';
            }
        }
        PaperToastService.create(message, type);

    };

    // Login as a user
    _this.loginAsUser = function (user) {
        let hqconfig = ConfigurationService.getConfig();
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var userId = user.id;
        var token = currentUserInfo.jwt;
        var apiUrl = hqconfig.hqApi;
        var arrJWTtoken = token.split('.');
        var decodedJWTtoken = window.atob(arrJWTtoken[1]);
        var objJWTtoken = JSON.parse(decodedJWTtoken);
        var adminAccessToken = objJWTtoken.access_token;
        var jwtTokenUrl = hqconfig.hqJwtTokenUrl;
        var clientId = hqconfig.jwtClientId;

        // Object for credentials
        var encodedCredentials = {
            grant_type: 'login_as_user',
            client_id: clientId,
            user_id: userId,
            access_token: adminAccessToken
        };
        // Post credentials using jwtTokenUrl
        $http({
            method: 'POST',
            url: jwtTokenUrl + 'payhq/token',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: $.param(encodedCredentials)
        }).success(function(response) {
            var jwtToken = response.access_token;

            if (jwtToken && jwtToken.length) {
                SessionService.storeParentAdminUserSession();
                AuthService.setLoginAsUserCredentials(user.getUsername(), jwtToken, apiUrl);
                $location.path('/transactions');
            }

        }).error(function(errorResponse) {
            $log.error(errorResponse.error);
        });
    };

    // Initialize function
    _this.onInit();

    ImageCropService.disableImageCroppingTriggers($scope);
}

angular
.module(APP_MODULE)
.component(COMPONENT_PROFILE, {
    bindings: {
        user: '=',
        merchantuser: '=',
        isMyProfile: '<'
    },
    controller: profile,
    controllerAs: 'profile',
    templateUrl: 'view/profile/profile.html'
});
