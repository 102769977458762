let THEME_CONSTANT = {
    styles: [
        {
            'key': 'White',
            'value': 1
        },
        {
            'key': 'Grey',
            'value': 2
        },
        {
            'key': 'Black',
            'value': 3
        }
    ],
    textColors: [
        {
            'key': 'White',
            'value': 1
        },
        {
            'key': 'Grey',
            'value': 2
        },
        {
            'key': 'Black',
            'value': 3
        }
    ],
    iconColors: [
        {
            key: 'White',
            value: 1
        },
        {
            key: 'Grey',
            value: 2
        },
        {
            key: 'Black',
            value: 3
        }
    ],
    iconWeights: [
        {
            key: 'Light',
            value: 1
        },
        {
            key: 'Medium',
            value: 2
        },
        {
            key: 'Heavy',
            value: 3
        }
    ]
};
export default THEME_CONSTANT;