import angular from 'angular';

/*
 API is used for displaying messages
 there are two types:
 1) display messages when view is loaded
 2) display message triggered by event such as click

 1) can be triggered by adding error type (generated by the method
 'getErrorParameterForOnViewLoadTrigger') in the query string fo the view's url
 2) can be triggered by triggerErrorMessage method when event is triggered

 make sure you add addMessageDisplayHook: function (errorTypesSubscribed, view, scope) method
 to the initialization routine of the view that you want to subscribe the hook.

 to expand this api by adding more error type, you can modify the global variable ERROR_TYPE

 note: actually error messages are stored in html templates, displayed by angular modal
 */
var ERROR_TEMPLATE_PATH = 'scripts/errorhandling/message-modal';

var ERROR_MESSAGE_CHANGED_MESSAGE_NAME = 'errorMessageChanged';

var ERROR_TYPE = {
    NO_INVOICE_TEMPLATE: {
        name: 'no-invoice',
        template: ERROR_TEMPLATE_PATH+'/no-invoice-template.html'
    },
    NO_INVOICE_PERMISSION: {
        name: 'no-invoice-permission',
        template: ERROR_TEMPLATE_PATH+'/no-invoice-permission.html'
    }
};

function getErrorTemplate(errorMessageType){
    for (var key in ERROR_TYPE) {
        if (Object.prototype.hasOwnProperty.call(ERROR_TYPE, key)) {
            var val = ERROR_TYPE[key];
            if (val && val.name===errorMessageType) {
                return val.template;
            }
        }
    }

    return undefined;
}

function needsHandleTheError(errorTypesSubscribed, errorType){
    return _.find(errorTypesSubscribed, function(error){
        return error.name === errorType.name;
    });
}

function getCheckErrorAlreadyDisplayedFlagName(view) {
    return view+'errorAlreadyDisplayed';
}

function initErrorAlreadyDisplayedFlag(scope, view){
    scope[getCheckErrorAlreadyDisplayedFlagName(view)] = false;
}

function isErrorAlreadyDisplayed(scope, view){
    return scope[getCheckErrorAlreadyDisplayedFlagName(view)];
}

function setErrorAlreadyDisplayed(scope, view){
    scope[getCheckErrorAlreadyDisplayedFlagName(view)] = true;
}

angular
.module('payhqUIApp')
.factory('ErrorMessageDisplayService', function ($rootScope, $location, $modal) {
    function needsDisplayErrorMessage(){
        var uri = new window.URI($location.absUrl());
        var hash = uri.hash();

        var queryObjectMap;

        var errorMessageType;

        if (hash.indexOf('?')!==-1) {
            var queryString = hash.substring(hash.indexOf('?'));
            queryObjectMap = window.URI.parseQuery(queryString);
            if (queryObjectMap){
                errorMessageType = queryObjectMap.error;
            }
        }

        return (typeof errorMessageType === 'string' || errorMessageType instanceof String)?
            errorMessageType :
            undefined;
    }

    function resetError(){
        $location.search('error', undefined);
    }

    return {

        ERROR_TYPE : ERROR_TYPE,

        getErrorParameterForOnViewLoadTrigger: function(errorType) {
            return {
                'error': errorType.name
            };
        },

        triggerErrorMessage: function(errorType){
            $rootScope.$broadcast(ERROR_MESSAGE_CHANGED_MESSAGE_NAME, errorType);
        },

        addMessageDisplayHook: function (errorTypesSubscribed, view, scope) {
            initErrorAlreadyDisplayedFlag(scope, view);

            scope.$on('$viewContentLoaded', function() {
                if (!isErrorAlreadyDisplayed(scope, view)){
                    //to avoid error message displayed twice on startup
                    var errorMessageType = needsDisplayErrorMessage();
                    if (errorMessageType){
                        $modal.open({
                            templateUrl: getErrorTemplate(errorMessageType),
                            controller: 'ErrorModalController as errorModal'
                        });
                        resetError();
                    }
                }
            });

            scope.$on(ERROR_MESSAGE_CHANGED_MESSAGE_NAME, function(event, errorType) {
                if (needsHandleTheError(errorTypesSubscribed, errorType)) {
                    //only handles the error the hook is subscribed
                    $modal.open({
                        templateUrl: getErrorTemplate(errorType.name),
                        controller: 'ErrorModalController as errorModal'
                    });
                    resetError();
                    setErrorAlreadyDisplayed(scope, view);
                }
            });

        }
    };
});