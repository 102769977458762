/* @flow */

import StaffPermission from 'model/staff-permission';
import User from 'model/user';

//TODO: Staff should be User
export default class Staff extends User {
    constructor(serverResponse?: Object, merchantId: number) {
        super(serverResponse, merchantId);

        if (serverResponse) {
            this.staffPermissions = new StaffPermission(serverResponse.permissions);
            this.originalLockedUserValue = serverResponse.locked_user;
        } else {
            this.staffPermissions = new StaffPermission();
        }
    }

    isChangedToDeactivated() {
        let isOriginallyDeactivated = this.originalLockedUserValue ||
            !this.staffPermissions.originalAccountPermission.active ||
            this.staffPermissions.originalAccountPermission.active === false;
        let isDeactivated = this.locked_user || !this.staffPermissions.accountPermission.active ||
            this.staffPermissions.accountPermission.active === false;

        //is now deactivated, and the originally not deactivated
        return isDeactivated && !isOriginallyDeactivated;
    }

    updatePermissionsObject() {
        this.permissions = this.staffPermissions.toPermissionsObject();
    }
}
