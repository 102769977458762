import angular from 'angular';
import 'service/pagination-service';
import 'filter/pagination-filter';

function prospects(MerchantAccountRequestService, PaperToastService, UrlService, $scope, $timeout) {
    let _this = this;

    _this.paginationService = new window.PAYFIRMA.PaginationService(_this.prospects);

    _this.initializeProspects = function() {
        _this.isLoadingData = true;

        window.PAYFIRMA.MerchantAccountRequestService.getAllData({
            forceFetch: true
        })
        //always reload from server when user enters prospects page
            .then(function (data) {
                $timeout(function () {
                    _this.paginationService.updateAllData(data);
                    _this.requestsForDisplay = _this.paginationService.getAllData();
                });
                _this.isLoadingData = false;
            });
    };

    _this.expandProspectDetails = function (currentProspect){
        if (_this.currentSelectedProspect && _this.currentSelectedProspect !== currentProspect) {
            _this.currentSelectedProspect.show = false;
        }

        _this.currentSelectedProspect = currentProspect;
        currentProspect.show = !currentProspect.show;
    };

    _this.deleteProspect = function (accountRequestId) {
        return MerchantAccountRequestService.deleteMerchantAccountRequest(accountRequestId)
            .then(function () {
                _this.isDeleteModalEnabled = false;
                _this.initializeProspects();
                return;
            })
            .catch(function(e){
                PaperToastService.create(e.message, 'error');
                throw e;
            });
    };

    _this.viewDeleteProspect = function () {
        _this.isDeleteModalEnabled = true;
    };

    _this.cancelDeleteProspect = function () {
        _this.deleteInput = '';
        _this.isDeleteModalEnabled = false;
    };

    _this.confirmDeleteProspect = function () {
        let id = _this.currentSelectedProspect.id;
        _this.deleteProspect(id);
    };

    _this.disableDeleteProspect = function () {
        return _this.deleteInput!=='DELETE';
    };

    /**
     * Create a new merchant with accountRequestId
     */
    _this.createMerchantFromAccountRequest = function (accountRequestId) {
        UrlService.go('/merchant/create/' + accountRequestId);
    };

    _this.initializeProspects();
}
angular.module('payhqUIApp').component('prospects', {
    bindings: {
        prospects: '<'
    },
    controller: prospects,
    controllerAs: 'prospects',
    templateUrl: 'view/prospects/prospects.html'
});