import angular from 'angular';
/**
 * @name customtimefilter
 * @description custom time filter
 *
 */
angular
.module('payhqUIApp')
.filter('customtime', function() {
    return function(time) {
        if (time){
            var timeTemp = time.split(/:/);
            var after = 'AM';
            if (timeTemp[0] > 12 ){
                timeTemp[0] = timeTemp[0] - 12;
                after = 'PM';
            } else if (timeTemp[0] === 12){
                after = 'PM';
            }
            return timeTemp[0]+':'+timeTemp[1]+':'+timeTemp[2]+ ' '+after;
        } else {
            return false;
        }
    };
});