function onEvent(trigger, callback) {
    var triggerCopy = trigger;
    if (!triggerCopy) {
        triggerCopy = new window.Rx.Subject();
    }
    triggerCopy.subscribe(callback);
    return triggerCopy;
}
function triggerEvent(trigger, data) {
    var triggerCopy = trigger;
    if (!triggerCopy) {
        triggerCopy = new window.Rx.Subject();
    }
    triggerCopy.onNext(data);
    return triggerCopy;
}
function disableEventTrigger(trigger) {
    var triggerCopy = trigger;
    if (triggerCopy) {
        triggerCopy.dispose();
    }
}

let eventsObject = {};

let EventEmitterService = {
    onEvent: (eventName, callback) => {
        eventsObject[eventName] = onEvent(eventsObject[eventName], callback);
    },
    triggerEvent: (eventName, event) => {
        eventsObject[eventName] = triggerEvent(eventsObject[eventName], event);
    },
    disableEvent: (eventName) => {
        eventsObject[eventName] = disableEventTrigger(eventsObject[eventName]);
    }
};

export default EventEmitterService;