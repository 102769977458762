import angular from 'angular';
import moment from 'moment';
import 'script/app-init';
import 'component/paper-toast/paper-toast-service';
import 'script/constants-angular';
import 'angular-translate';
import _ from 'lodash';

angular
.module('payhqUIApp')
.service('Utils', function ($location, PaperToastService, DateFormat, $translate, CARDTYPE, REGEX) {
    this.updateSortKey = function (oldKey, newKeyValue) {
        /*
            a sort key has the following structure as an object
            value: the property name used to sort the data,
            order: either ASC or DEC
            expression: the actual expressioned, in this case angular, used for sorting
            this new expression abstract away the actual angular expression for ordering so that
            our code is less dependent on angular
            */

        var ASC = 'ASC', DEC = 'DEC';
        var sortKey = {
            value: newKeyValue,
            order: ASC
        };

        if (oldKey && oldKey.value === newKeyValue) {
            if (!oldKey.order || oldKey.order === ASC) {
                sortKey.order = DEC;
            } else {
                sortKey.order = ASC;
            }
        }

        if (sortKey.order === DEC) {
            sortKey.expression = '-' + sortKey.value;
        } else {
            sortKey.expression = '+' + sortKey.value;
        }

        return sortKey;
    };
    this.go = function (url) {
        $location.path(url);
    };
    this.dateStringToMomentObject = function (dateString) {
        var format = '';
        //TODO: THE DATE FORMATS OF THE SERVER OBJECT ARE WILDLY INCONSISTENT
        if (dateString) {
            if (dateString.indexOf('-') !== -1) {
                format = DateFormat.DEFAULT_VALUE_FORMAT;
            } else if (dateString.indexOf('/') !== -1) {
                format = DateFormat.VALUE_FORMAT_WITH_SLASH;
            }
        }

        if (format) {
            return moment(dateString, format);
        } else {
            //TODO: CANNOT GUESS WHICH FORMAT THE DATE OBJECT MIGHT HAVE, DO NOT PARSE
            return undefined;
        }
    };
    this.initializeDate = function () {
        return moment().format(DateFormat.DEFAULT_VALUE_FORMAT);
    };

    this.initializeDateAddDays = function (days) {
        if (days > 0){
            return moment().add(days,'days').format(DateFormat.DEFAULT_VALUE_FORMAT);
        }
    };

    this.forceScopeApply = function($scope) {
        try {
            $scope.$apply();
            //it seems that we need to manually trigger apply here because rxjs is not part of the anular scope
            //so angular's digest cycle does not track changes here...weird
        } catch (e) {
            //no need to handle...this is just in case when angular has a digest cycle triggered already
            //a second apply will throw an exception
        }
    };

    // Cloning objects the easy way in ES5.
    this.cloneObject = function(originalObject) {
        var clonedObject = Object.create(originalObject.prototype || null);
        Object.keys(originalObject).map(function (attribute) {
            // Map cloned object from original object
            clonedObject[attribute] = originalObject[attribute];
        });
        return clonedObject;
    };
    // Checks for IE11 specifically
    this.isInternetExplorer = function msieversion() {
        var navigatorUserAgent = window.navigator.userAgent;
        var msie = navigatorUserAgent.indexOf('MSIE');
        var isInternetExplorer = false;

        // If Internet Explorer, return true
        if (msie > 0 || !!window.navigator.userAgent.match(/Trident.*rv\:11\./)){
            isInternetExplorer = true;
        }
        return isInternetExplorer;
    };

    this.isSafari = !!window.navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

    // Scrolls user to the top of current view
    this.scrollToTop = function(){
        document.getElementsByTagName('main')[0].scrollTop = 0;
    };

    // Returns credit card type from card number
    this.getCreditCardType = function(cardNumber){
        var cardType = '';
        if (REGEX.CARD_MASTERCARD.test(cardNumber)) {
            cardType = CARDTYPE.MASTERCARD;
        } else if (REGEX.CARD_VISA.test(cardNumber)) {
            cardType = CARDTYPE.VISA;
        } else if (REGEX.CARD_AMERICAN_EXPRESS.test(cardNumber)) {
            cardType = CARDTYPE.AMEX;
        } else if (REGEX.CARD_DISCOVER.test(cardNumber)) {
            cardType = CARDTYPE.DISCOVER;
        } else if (REGEX.CARD_JCB.test(cardNumber)) {
            cardType = CARDTYPE.JCB;
        }
        return cardType;
    };

    //convert enum format to standard display format eg. ENUM to Enum
    this.enumToHuman = function(enumString){
        var string = enumString.toLowerCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    this.isNumeric = function(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    };
    this.translateStrings = function(strings){
        var translatedStrings = {};
        return $translate(strings)
        .then(function (translations) {
            _.forEach(strings, function(value){
                translatedStrings[value] = translations[value];
            });
            return translatedStrings;
        });
    };
    this.maxRandomNumberGenerator = function(maxValue){
        var randomNumber = Math.floor((Math.random() * maxValue) + 1);
        return randomNumber;
    };
    this.timeStampToHuman = function(date) {
        var formattedDate = date / 1000;
        formattedDate = moment.unix(formattedDate).format('MMM DD YYYY');
        return formattedDate;
    };

    // Get a customer id of a specific transaction object if it has customer information.
    // such as email or customer lookup id
    this.getCustomerId = (allCustomers, params) => {
        let id = null;
        let customer = params ? _.find(allCustomers, params, null) : null;
        if (customer) {
            if (customer.custom_id) {
                id = customer.custom_id;
            }
        }
        return id;
    };
});
