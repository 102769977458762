import angular from 'angular';
import UtilityService from 'service/utility-service';
import SessionStorageService from 'service/session-storage-service';

function staff(UrlService) {
    var _this;
    _this = this;

    _this.orderListBy = null;

    _this.userGroupStringMapping = (string) =>{
        return UtilityService.userGroupStringMapping(string);
    };

    _this.convertToYesNo = function (boolean) {
        return UtilityService.convertToYesNo(boolean);
    };

    _this.currentUserId = SessionStorageService.get().getUser().id;

    _this.createStaff = function () {
        UrlService.go('/staff/create');
    };

    _this.editStaffById = function (lookupId) {
        UrlService.go('staff/' + lookupId);
    };

     /**
     * Apply sorting to table columns
     */
    _this.sortCol = function(parameter, $event) {

        var targetElement = $($event.target);
        var sortableColumn = $('[sort]');
        //set tabel sort function and display arrow class
        if (targetElement.hasClass('ascending')) {
            _this.orderListBy = '-' + parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort').addClass('decending');
        } else if (targetElement.hasClass('decending')) {
            _this.orderListBy = parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort').addClass('ascending');
        } else if (targetElement.hasClass('no-sort')) {
            _this.orderListBy = parameter;
            sortableColumn.removeClass('ascending decending').addClass('no-sort');
            targetElement.removeClass('no-sort');
            targetElement.addClass('ascending');
        }
    };
}
angular
.module('payhqUIApp')
.component('staff', {
    bindings: {
        list: '='
    },
    controller: staff,
    controllerAs: 'staff',
    templateUrl: 'view/staff/staff.html'
});
