import angular from 'angular';
/**
 * @ngdoc service
 * @name payhqUIApp.service:PdfGeneratorService
 * @description
 *
 * Service for converting image (or some other media types in the future) to pdf
 *
 */
angular
.module('payhqUIApp')
.service('PdfGeneratorService', function ($q) {

    // a good algorithm for split a lengthy image into multiple pages
    // reference: http://plnkr.co/edit/RgR6Ve0TVNlvL5BTUN2B?p=preview
    function createPdf() {
        var UNIT = 'px',
            FORMAT = 'a4';

        return new window.jsPDF({
            unit: UNIT,
            format: FORMAT
        });
    }

    function addImageToPdf(image) {

        var pdf = createPdf(),
            pdfInternals = pdf.internal,
            pdfPageSize = pdfInternals.pageSize,
            pdfPageWidth = pdfPageSize.width,
            pdfPageHeight = pdfPageSize.height,
            actualWidth,
            actualHeight,
            pageCount = 0,
            accumulativeHeight = 0;

        if (image.width < pdfPageWidth) {
            actualWidth = image.width;
        } else {
            actualWidth = pdfPageWidth;
        }
        actualHeight = image.height / (image.width / actualWidth);

        if (pdfPageHeight > 0 && actualHeight > 0) {
            while (accumulativeHeight < actualHeight) {
                pdf.addImage(image.data, image.format, 0, -accumulativeHeight, actualWidth, actualHeight);
                pageCount++;
                accumulativeHeight = pdfPageHeight * pageCount;
                if (accumulativeHeight < actualHeight) {
                    pdf.addPage();
                }
            }
            return pdf;
        }

        return undefined;
    }

    return {
        fromImage: function (image, fileNameIfSave) {
            var deferred = $q.defer();
            var promise = deferred.promise;

            var pdf = addImageToPdf(image);
            if (pdf && fileNameIfSave) {
                if (window.bowser.firefox) {
                    window.setTimeout(function () {
                        pdf.save(fileNameIfSave);
                        deferred.resolve();
                    }, 2000);
                } else {
                    pdf.save(fileNameIfSave);
                    deferred.resolve();
                }

            } else {
                deferred.reject('Cannot create pdf.');
            }

            return promise;
        }
    };
});