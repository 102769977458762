import angular from 'angular';

angular
.module('payhqUIApp')
.factory('MccService', function () {
    return {
        search: function (value) {
            var industries = [
                {
                    name: {
                        en: 'Testing',
                        fr: 'Testing'
                    },
                    range: [0, 0],
                    exceptions: [],
                    codes: [0]
                },
                {
                    name: {
                        en: 'Agriculture, Forestry & Fishing',
                        fr: 'L`agriculture, Sylviculture et Pêche'
                    },
                    range: [100, 999],
                    exceptions: [],
                    codes: [100, 200, 700, 800, 900]
                },
                {
                    name: {
                        en: 'Mining',
                        fr: 'Exploitation Minière'
                    },
                    range: [1000, 1499],
                    exceptions: [],
                    codes: [1000, 1040, 1090, 1220, 1221, 1311, 1381, 1382, 1389, 1400]
                },
                {
                    name: {
                        en: 'Construction',
                        fr: 'Construction'
                    },
                    range: [1500, 1999],
                    exceptions: [780, 763],
                    codes: [763, 780, 1520, 1531, 1540, 1600, 1623, 1700, 1711, 1731, 1740, 1750, 1761, 1771, 1799]
                },
                {
                    name: {
                        en: 'Manufacturing',
                        fr: 'Fabrication'
                    },
                    range: [2000, 3999],
                    exceptions: [],
                    codes: [
                        2000, 2011, 2013, 2015, 2020, 2024, 2030, 2033, 2040, 2050, 2052, 2060, 2070, 2080,
                        2082, 2090, 2092,2100, 2111, 2200, 2211, 2221, 2250, 2253, 2273, 2300, 2320, 2330,
                        2340, 2390, 2400, 2421, 2430, 2451,2452, 2510, 2511, 2520, 2522, 2531, 2540, 2590,
                        2600, 2611, 2621, 2631, 2650, 2670, 2673, 2711, 2721,2731, 2732, 2741, 2750, 2761,
                        2771, 2780, 2790, 2791, 2800, 2810, 2820, 2821, 2833, 2834, 2835, 2836,2840, 2842,
                        2844, 2851, 2860, 2870, 2890, 2891, 2911, 2950, 2990, 3011, 3021, 3050, 3060, 3080,
                        3081,3086, 3089, 3100, 3140, 3211, 3220, 3221, 3231, 3241, 3250, 3260, 3270, 3272,
                        3281, 3290, 3310, 3312,3317, 3320, 3330, 3334, 3341, 3350, 3357, 3360, 3390, 3411,
                        3412, 3420, 3430, 3433, 3440, 3442, 3443,
                        3444, 3448, 3451, 3452, 3460, 3470, 3480, 3490, 3510, 3523, 3524, 3530, 3531, 3532,
                        3533, 3537, 3540,3541, 3550, 3555, 3559, 3560, 3561, 3562, 3564, 3567, 3569, 3570,
                        3571, 3572, 3575, 3576, 3577, 3578,3579, 3580, 3585, 3590, 3600, 3612, 3613, 3620,
                        3621, 3630, 3634, 3640, 3651, 3652, 3661, 3663, 3669,
                        3670, 3672, 3674, 3677, 3678, 3679, 3690, 3695, 3711, 3713, 3714, 3715, 3716, 3720,
                        3721, 3724, 3728,3730, 3743, 3751, 3760, 3790, 3812, 3821, 3822, 3823, 3824, 3825,
                        3826, 3827, 3829, 3841, 3842, 3843,
                        3844, 3845, 3851, 3861, 3873, 3910, 3911, 3931, 3942, 3944, 3949, 3950, 3960, 3990
                    ]
                },
                {
                    name: {
                        en: 'Transportation, Communications, Electric, Gas & Sanitary Services',
                        fr: 'Transports, communications, électricité, gaz et services sanitaires'
                    },
                    range: [4000, 4999],
                    exceptions: [],
                    codes: [
                        4011, 4013, 4100, 4111, 4112, 4119, 4121, 4131, 4210, 4213, 4214, 4215, 4220,
                        4225, 4231, 4400, 4411, 4412, 4457, 4468, 4511, 4512, 4513, 4522, 4581, 4582,
                        4610, 4700, 4722, 4731, 4784, 4789, 4812, 4813, 4814, 4816, 4821, 4822, 4829,
                        4832, 4833, 4841, 4899, 4900, 4911, 4922, 4923, 4924, 4931, 4932, 4941,
                        4950, 4953, 4955, 4961, 4991
                    ]
                },
                {
                    name: {
                        en: 'Wholesale Trade',
                        fr: 'Commerce de Gros'
                    },
                    range: [5000, 5199],
                    exceptions: [],
                    codes: [
                        5000, 5010, 5013, 5020, 5021, 5030, 5031, 5039, 5040, 5044, 5045, 5046,
                        5047, 5050, 5051, 5063, 5064,5065, 5070, 5072, 5074, 5080, 5082, 5084, 5085, 5090,
                        5094, 5099, 5110, 5111, 5122, 5130, 5131, 5137,
                        5139, 5140, 5141, 5150, 5160, 5169, 5171, 5172, 5180, 5190, 5192, 5193, 5198, 5199
                    ]
                },
                {
                    name: {
                        en: 'Retail Trade',
                        fr: 'Commerce de Détail'
                    },
                    range: [5200, 5999],
                    exceptions: [],
                    codes: [
                        5200, 5211, 5231, 5251, 5261, 5271, 5300, 5309, 5310,
                        5311, 5331, 5399, 5400, 5411, 5412, 5422, 5441,
                        5444, 5451, 5462, 5499, 5500, 5511, 5521, 5531, 5532,
                        5533, 5541, 5542, 5551, 5561, 5571, 5592, 5598,
                        5599, 5600, 5611, 5621, 5631, 5641, 5651, 5655, 5661, 5681,
                        5691, 5697, 5698, 5699, 5700, 5712, 5713,
                        5714, 5718, 5719, 5722, 5731, 5732, 5733, 5734, 5735,
                        5810, 5811, 5812, 5813, 5814, 5900, 5912, 5921,
                        5931, 5932, 5933, 5935, 5937, 5940, 5941, 5942, 5943,
                        5944, 5945, 5946, 5947, 5948, 5949, 5950, 5960,
                        5961, 5962, 5963, 5964, 5965, 5966, 5967, 5968, 5969,
                        5970, 5971, 5972, 5973, 5975, 5976, 5977, 5978,
                        5983, 5990, 5992, 5993, 5995, 5996, 5997, 5998, 5999
                    ]
                },
                {
                    name: {
                        en: 'Finance, Insurance & Real Estate',
                        fr: 'Finances, Assurances et Immobilier'
                    },
                    range: [6000, 6999],
                    exceptions: [4829],
                    codes: [
                        4829, 6010, 6011, 6012, 6021, 6022, 6029, 6035, 6036,
                        6050, 6051, 6099, 6111, 6141, 6153, 6159, 6162,
                        6163, 6172, 6189, 6199, 6200, 6211, 6221, 6282, 6300,
                        6311, 6321, 6324, 6331, 6351, 6361, 6399, 6411,
                        6500, 6510, 6512, 6513, 6519, 6529, 6530, 6531, 6532,
                        6533, 6534, 6535, 6552, 6770, 6792, 6794, 6795,
                        6798, 6799
                    ]
                },
                {
                    name: {
                        en: 'Services',
                        fr: 'Services'
                    },
                    range: [7000, 8999],
                    exceptions: [742],
                    codes: [
                        742, 7000, 7011, 7012, 7032, 7033, 7200, 7210,
                        7211, 7216, 7217, 7221, 7230, 7251, 7261, 7273, 7276,
                        7277, 7278, 7296, 7297, 7298, 7299, 7310, 7311, 7320,
                        7321, 7330, 7331, 7333, 7334, 7338, 7339, 7340,
                        7342, 7349, 7350, 7359, 7361, 7363, 7370, 7371, 7372,
                        7373, 7374, 7377, 7379, 7380, 7381, 7384, 7385,
                        7389, 7392, 7393, 7394, 7395, 7399, 7500, 7510, 7511,
                        7512, 7513, 7519, 7523, 7531, 7534, 7535, 7538,
                        7542, 7549, 7600, 7622, 7623, 7629, 7631, 7641, 7692,
                        7699, 7812, 7819, 7822, 7829, 7830, 7832, 7841,
                        7900, 7911, 7922, 7929, 7932, 7933, 7941, 7948, 7990,
                        7991, 7992, 7993, 7994, 7995, 7996, 7997, 7998,
                        7999, 8000, 8011, 8021, 8031, 8041, 8042, 8043, 8049,
                        8050, 8051, 8060, 8062, 8071, 8082, 8090, 8093,
                        8099, 8111, 8200, 8211, 8220, 8241, 8244, 8249, 8299,
                        8300, 8351, 8398, 8600, 8641, 8651, 8661, 8675,
                        8699, 8700, 8711, 8731, 8734, 8741, 8742, 8744,
                        8748, 8880, 8888, 8900, 8911, 8931, 8999
                    ]
                },
                {
                    name: {
                        en: 'Public Administration',
                        fr: 'Administration Publique'
                    },
                    range: [9000, 9999],
                    exceptions: [],
                    codes: [9211, 9222, 9223, 9311, 9399, 9402, 9405, 4751, 9752, 9721, 9995]
                }
            ];

            var found = false;
            var language = 'en';
            var result = '';
            for (var i = 0; i <= 9; i++) {
                var industry = industries[i];
                if (((value >= industry.range[0]) && (value <= industry.range[1])) ||
                    (industry.exceptions.indexOf(value) > -1) && (industry.codes.indexOf(value) > -1)) {
                    result = industry.name[language];
                    found = true;
                }
            }
            if (!found) {
                result = 'not found';
            }
            return result;
        }
    };
});
