import angular from 'angular';

angular
.module('payhqUIApp')
.service('ImageCropService', function () {
    var darkRoomJs;

    //@author: carl
    //there is a bug with darkroomjs, every new instance of darkroomjs automatically
    //creates a new canvas container and the existing ones are not deleted, which
    //does not work for us as we need the new image source to replace the existing
    //image source.
    //thus I have to delete the existing canvas container manually before creating
    //a new darkroomjs object, hope it does not cause any side-effects
    function cleanUpExistingContainers() {
        if (darkRoomJs) {
            var existingContainerElement = darkRoomJs.containerElement,
                originalImageElement = darkRoomJs.originalImageElement;

            existingContainerElement.parentElement
                .replaceChild(originalImageElement, existingContainerElement);
        }
    }

    //the function is created to bypass the lint rule
    //that prevents me from using new as a side-effect
    //which is actually a valid concern
    function createDarkRoomObject(element) {
        cleanUpExistingContainers();
        darkRoomJs = new window.Darkroom('#' + element.id, {

            // Canvas initialization size
            maxWidth: element.getAttribute('maxwidth'),
            maxHeight: element.getAttribute('maxheight'),

            plugins: {
                crop: {
                    minHeight: 50,
                    minWidth: 50
                },
                save: false
            },

            // Post initialization method
            initialize: function () {
                // Active crop selection
                this.plugins.crop.requireFocus();
            }
        });
    }

    return {
        attachToElement: function (element) {
            createDarkRoomObject(element);
        },
        getCroppedImage: function () {
            if (darkRoomJs) {
                return darkRoomJs.canvas.toDataURL();
            }
            return undefined;
        },
        disableImageCroppingTriggers: function($scope) {
            $scope.$on('$destroy', function () {
                window.PAYFIRMA.CommunicationService.disableCroppedImageUpdateEventTrigger();
                window.PAYFIRMA.CommunicationService.disableImageUpdateEvent();
                window.PAYFIRMA.CommunicationService.disableOpenImageCropperModalEventTrigger();
            });
        }
    };
});
