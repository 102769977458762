import angular from 'angular';
import SessionStorageService from 'service/session-storage-service';

function modifyApikey(MerchantApplicationService, PaperToastService, $log, $translate) {
    let _this = this;
    let translation = {};
    const AVAILABLE_PERMISSIONS = ['TRANSACTION', 'CUSTOMER', 'PLAN', 'ANALYTICS'];

    _this.isModalEnabled = false;
    _this.isAdvancedSettingsOpen = false;
    _this.onlyClientCredentials = true;
    _this.clientCredentialsAuthCode = false;
    _this.isSaving = false;

    let currentUser = SessionStorageService.get().getUser();
    let merchantId = currentUser && currentUser.business_information ? currentUser.business_information.id : undefined;
    let parentUser = SessionStorageService.get().getParentUser();

    _this.isPayfirmaAdminControlled = parentUser && parentUser.apiUrl;
    _this.tokenValidityArray = window.PAYFIRMA.TOKEN_VALIDITIES;
    _this.accessTokenValidity = window.PAYFIRMA.DEFAULT_ACCESS_TOKEN_VALIDITY;
    _this.refreshTokenValidity = window.PAYFIRMA.DEFAULT_REFRESH_TOKEN_VALIDITY;

    function cleanup() {
        _this.name = undefined;
        _this.existingApiKey = undefined;
        _this.description = undefined;
        _this.redirect_url = undefined;
        _this.logo_url = undefined;
        _this.company_name = undefined;
        _this.accessTokenValidity = window.PAYFIRMA.DEFAULT_ACCESS_TOKEN_VALIDITY;
        _this.refreshTokenValidity = window.PAYFIRMA.DEFAULT_REFRESH_TOKEN_VALIDITY;
        _this.isModalEnabled = false;
        _this.isAdvancedSettingsOpen = false;
        _this.isSaving = false;
        _this.accessTokenTermsConfirmed = false;
    }

    _this.cancel = function () {
        cleanup();
    };

    $translate([
        'view_ecommerce_settings.toast_application_updated_success',
        'view_ecommerce_settings.toast_application_updated_fail',
        'fillOutRequiredFields'
    ]).then((translated) => {
        translation = translated;
    });

    _this.convertErrorMessage = function (errors) {
        const errorArray = errors.data.errors;
        let errorMessage = 'Error creating API keys';
        if (errorArray[0].code === 'Https') {
            errorMessage = 'Please, use https (Hypertext Transfer Protocol Secure) for URL\'s';
        } else if ((errorArray[0].message.includes('Domain_UNIQUE'))){
            errorMessage = 'The domain already exists';
        }
        if (_this.redirect_url && _this.redirect_url.substring(0, 8).toLowerCase() !== ('https://')) {
            $('.redirect_url_input').addClass('ng-invalid');
        }
        if (_this.logo_url && _this.logo_url.substring(0, 8).toLowerCase() !== ('https://')) {
            $('.logo_url_input').addClass('ng-invalid');
        }

        return errorMessage;
    };

    function update() {
        _this.existingApiKey.name = _this.name;
        _this.existingApiKey.description = _this.description;
        _this.existingApiKey.redirect_uri = _this.redirect_url;
        _this.existingApiKey.logo_url = _this.logo_url;
        _this.existingApiKey.company_name = _this.company_name;
        _this.existingApiKey.refresh_token_validity = _this.refreshTokenValidity;
        _this.existingApiKey.access_token_validity =
        _this.accessTokenTermsConfirmed ? _this.accessTokenValidity : window.PAYFIRMA.DEFAULT_ACCESS_TOKEN_VALIDITY;

        //Does API key have AUTHORIZATION_CODE grant type (before updating)
        let isCurrentlyAuth = (_this.existingApiKey.authorized_grant_types && _this.existingApiKey.authorized_grant_types.includes('AUTHORIZATION_CODE'));
        const wantsClientCredentialsAndAuth = _this.clientCredentialsAuthCode && !_this.onlyClientCredentials;
        const wantsClientCredentialsOnly = _this.onlyClientCredentials && !_this.clientCredentialsAuthCode;
    
        if (wantsClientCredentialsOnly && !isCurrentlyAuth) {
            return MerchantApplicationService.updateMerchantApplication(_this.existingApiKey)
            .then(function () {
                window.PAYFIRMA.CommunicationService.triggerApiKeyModifiedEvent();
                cleanup();
                PaperToastService
                .create(translation['view_ecommerce_settings.toast_application_updated_success'], 'update');
                _this.isSaving = false;
            })
            .catch(function (error) {
                PaperToastService
                .create(translation['view_ecommerce_settings.toast_application_updated_fail'], 'error');
                $log.error(error);
                throw error;
            });
        }
        if (wantsClientCredentialsAndAuth || wantsClientCredentialsOnly && isCurrentlyAuth) {
            if (wantsClientCredentialsOnly){
                _this.existingApiKey.authorized_grant_types = ['CLIENT_CREDENTIALS'];
                _this.existingApiKey.logo_url = undefined;
                _this.existingApiKey.redirect_uri = undefined;
                _this.existingApiKey.company_name = undefined;
            }
            if (wantsClientCredentialsAndAuth){
                _this.existingApiKey.authorized_grant_types = ['REFRESH_TOKEN','CLIENT_CREDENTIALS','AUTHORIZATION_CODE'];
            }
            return MerchantApplicationService.updateMerchantApplicationForAdmin(_this.existingApiKey, merchantId)
            .then(function () {
                window.PAYFIRMA.CommunicationService.triggerApiKeyModifiedEvent();
                cleanup();
                PaperToastService
                .create(translation['view_ecommerce_settings.toast_application_updated_success'], 'update');
                _this.isSaving = false;
            })
            .catch(function (errors) {
                let errorMessage = _this.convertErrorMessage(errors);
                PaperToastService.create(errorMessage, 'error');
                $log.error(errorMessage);
                _this.isSaving = false;
                throw new Error(errorMessage);
            });
        } else {
            let error = 'Unable to determine application creation endpoint!';
            PaperToastService.create(error, 'error');
            $log.error(error);
            throw error;
        }
    }


    function add() {
        if (_this.onlyClientCredentials && !_this.clientCredentialsAuthCode){
            return MerchantApplicationService.createMerchantApplication({
                name: _this.name,
                description: _this.description,
                type: 'WEB',
                scope: AVAILABLE_PERMISSIONS,
                access_token_validity:
                _this.accessTokenTermsConfirmed ? _this.accessTokenValidity : window.PAYFIRMA.DEFAULT_ACCESS_TOKEN_VALIDITY,
                refresh_token_validity: _this.refreshTokenValidity
            })
                .then(function () {
                    window.PAYFIRMA.CommunicationService.triggerApiKeyCreatedEvent();
                    PaperToastService.create('Application created!', 'update');
                    cleanup();
                })
                .catch(function (error) {
                    PaperToastService.create('Application creation failed!', 'error');
                    $log.error(error);
                    throw error;
                });
        }
        if (_this.clientCredentialsAuthCode && !_this.onlyClientCredentials){
            return MerchantApplicationService.createMerchantApplicationForAdmin({
                name: _this.name,
                description: _this.description,
                redirect_uri: _this.redirect_url,
                logo_url: _this.logo_url,
                company_name: _this.company_name,
                type: 'WEB',
                scope: AVAILABLE_PERMISSIONS,
                access_token_validity:
                _this.accessTokenTermsConfirmed ? _this.accessTokenValidity : window.PAYFIRMA.DEFAULT_ACCESS_TOKEN_VALIDITY,
                refresh_token_validity: _this.refreshTokenValidity,
                authorized_grant_types: ['REFRESH_TOKEN','CLIENT_CREDENTIALS','AUTHORIZATION_CODE']
            }, merchantId)
                .then(function () {
                    window.PAYFIRMA.CommunicationService.triggerApiKeyCreatedEvent();
                    PaperToastService.create('Application created!', 'update');
                    cleanup();
                })
                .catch(function (errors) {
                    let errorMessage = _this.convertErrorMessage(errors);
                    PaperToastService.create(errorMessage, 'error');
                    $log.error(errorMessage);
                    _this.isSaving = false;
                    throw new Error(errorMessage);
                });
        } else {
            let error = 'Unable to determine application creation endpoint!';
            PaperToastService.create(error, 'error');
            $log.error(error);
            throw error;
        }

    }

    _this.checkAccessTokenTermsConfirmed = () => {
        if (!_this.accessTokenTermsConfirmed){
            _this.accessTokenValidity = window.PAYFIRMA.DEFAULT_ACCESS_TOKEN_VALIDITY;
        }
    };

    _this.clearTokenTypeSelection = () => {
        _this.onlyClientCredentials = false;
        _this.clientCredentialsAuthCode = false;
    };

    _this.selectOnlyClientCredentials = () => {
        _this.clearTokenTypeSelection();
        _this.onlyClientCredentials = true;
    };

    _this.selectClientCredentialsAuthCode = () => {
        _this.clearTokenTypeSelection();
        _this.clientCredentialsAuthCode = true;
    };

    _this.isUpdate = function () {
        return _this.existingApiKey;
    };

    _this.save = function (form) {
        if (form.$valid){
            _this.isSaving = true;
            if (_this.form && _this.form.$valid) {
                if (_this.isUpdate()) {
                    return update();
                } else {
                    return add();
                }
            }
        } else {
            PaperToastService
            .create(translation.fillOutRequiredFields, 'error');
        }
    };

    _this.toggleAdvancedSettings = function () {
        _this.isAdvancedSettingsOpen = !_this.isAdvancedSettingsOpen;
    };

    window.PAYFIRMA.CommunicationService.onAddApiKeyEnabledEvent(() => {
        _this.isModalEnabled = true;
    });

    window.PAYFIRMA.CommunicationService.onEditApiKeyEnabledEvent((existingApiKey) => {
        if (existingApiKey) {
            if (existingApiKey.access_token_validity !== window.PAYFIRMA.DEFAULT_ACCESS_TOKEN_VALIDITY){
                _this.accessTokenTermsConfirmed = true;
            } else {
                _this.accessTokenTermsConfirmed = false;
            }

            if (existingApiKey.authorized_grant_types && existingApiKey.authorized_grant_types.includes('AUTHORIZATION_CODE')) {
                _this.selectClientCredentialsAuthCode();
            } else {
                _this.selectOnlyClientCredentials();
            }
            
            _this.existingApiKey = existingApiKey;
            _this.name = existingApiKey.name;
            _this.description = existingApiKey.description;
            _this.redirect_url = existingApiKey.redirect_uri;
            _this.logo_url = existingApiKey.logo_url;
            _this.company_name = existingApiKey.company_name;
            _this.refreshTokenValidity = existingApiKey.refresh_token_validity;
            _this.accessTokenValidity = existingApiKey.access_token_validity;
        }
        _this.isModalEnabled = true;
    });
}

angular
.module('payhqUIApp')
.component('modifyApikey', {
    templateUrl: 'view/settings/ecommerce-settings/modify-apikey/modify-apikey.html',
    controller: modifyApikey,
    controllerAs: 'modifyApikey'
});
