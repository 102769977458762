import 'service/newrelic-service';
import lodash from 'lodash';
window._ = lodash;
import moment from 'moment';
window.moment = moment;
import 'jquery';
import bluebird from 'bluebird';
window.Promise = bluebird;
import 'leaflet';
import 'mapbox.js';
import 'markercluster';
import 'fabric';
import 'darkroom';
import angular from 'angular';
import uiMask from 'angular-ui-mask';
import 'angularlocalization';
import ngResource from 'angular-resource';
import ngRoute from 'angular-route';
import ngSanitize from 'angular-sanitize';
import ngTouch from 'angular-touch';
import ngAnimate from 'angular-animate';
import ngCookies from 'angular-cookies';
import 'restangular';
import 'ng-infinite-scroll';
import 'angular-mass-autocomplete';
import 'angular-recaptcha';
import 'angular-input-masks';
import 'bootstrap-daterangepicker/daterangepicker';
import daterangepicker from 'angular-daterangepicker';
import ngProgress from 'ngprogress';
import ngIdle from 'ng-idle';
import 'd3';
import 'spectrum';
import httpAuthInterceptor from 'angular-http-auth';
import ngTranslate from 'angular-translate';
import 'angular-translate-storage-local';
import 'angular-translate-storage-cookie';
import 'angular-translate-loader-static-files';
import 'script/_all_modules';
import 'script/_all_services';
/**
 * @ngdoc overview
 * @name payhqUIApp
 * @description # payhqUIApp
 *
 * Main entry point : Main module of the application.
 */

angular.module('payhqUIApp', [
    ngResource,
    ngRoute,
    ngSanitize,
    ngTouch,
    ngAnimate,
    ngCookies,
    ngProgress,
    ngIdle,
    ngTranslate,
    httpAuthInterceptor,
    uiMask,
    'vcRecaptcha',
    'd3',
    'ngLocalize',
    'ngLocalize.Config',
    'ngLocalize.InstalledLanguages',
    'customerApp',
    'recurringApp',
    'transactionApp',
    'analyticsApp',
    'userApp',
    'oracleApp',
    'settingsApp',
    'restangular',
    'infinite-scroll',
    'MassAutoComplete',
    daterangepicker,
    'ui.utils.masks'
]);
