import angular from 'angular';

function paperModal() {
    let _this = this;
    const isEscapeCharacterDisabled = _this.disableEscape !== 'true';

    function detectEscapeCharacter(event) {
        let eventValue = event || window.event;
        // 27 is the keycode for 'ESC' key
        const isEscapeCharacterPressed = eventValue.keyCode === 27;

        if (isEscapeCharacterPressed && isEscapeCharacterDisabled) {
            window.PAYFIRMA.CommunicationService.triggerModalCloseEvent();
            _this.ngIf = false;
        }
    }
    window.onkeydown = detectEscapeCharacter;
}
angular
.module('payhqUIApp')
.component('paperModal', {
    bindings: {
        disableEscape: '@',
        ngIf: '='
    },
    controller: paperModal,
    controllerAs: 'paperModal',
    require: '?ngModel'
});