import angular from 'angular';
import CONSTANT_APP from 'constant/constant-app';
import CONSTANT_COMPONENT from 'constant/constant-component';

const APP_MODULE = CONSTANT_APP.module;
const COMPONENT_VIEW_INVOICE = CONSTANT_COMPONENT.view_invoice;

function viewInvoice($timeout, InvoiceService, Utils, TransactionService) {
    var _this = this;

    if (_this.data && _this.data.invoice){
        _this.invoiceData = _this.data.invoice;
        TransactionService.getCountryProvinceName( _this.data.invoice, _this.invoiceData, 'billing_country', 'billing_state');
        _this.imageLogoSource = InvoiceService.getInvoiceLogo(_this.invoiceData.invoice_id);
    }

    _this.timeStampToHuman = function(date) {
        return Utils.timeStampToHuman(date);
    };

    _this.$onInit = function() {
        $timeout(function() {
            window.PAYFIRMA.CommunicationService.triggerPaperModalOpenEvent();
        }, 100);
    };
}
angular
.module(APP_MODULE)
.component(COMPONENT_VIEW_INVOICE, {
    bindings: {
        data: '='
    },
    controller: viewInvoice,
    controllerAs: 'viewInvoice',
    templateUrl: 'view/invoicing/view-invoice/view-invoice.html'
});