import angular from 'angular';
import BILLING_CONSTANT from 'constant/billing-constant';

/**
 *  view : <paper-customervault-filter filtercustomervault="filtercustomervault"
    filterplanstatus="filterplanstatus"></paper-customervault-filter>
 *  set filter on ng-repeat : customFilter:filtercustomervault | customFilter:filterplanstatus
 */
function paperCustomervaultFilter() {
    var _this = this;

    _this.isDropped = false;
    _this.isDroppedStatus = false;

    _this.title = window.PAYFIRMA.TEXT_STRINGS.ALL_CUSTOMERS;
    _this.titleStatus = window.PAYFIRMA.TEXT_STRINGS.STATUS+' (6)';

    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
    };

    _this.doDropStatus = function () {
        _this.isDroppedStatus = !_this.isDroppedStatus;
    };

    var defaultSelected = [BILLING_CONSTANT.status.active];

    _this.selectedCustomerGroup = [BILLING_CONSTANT.category.all,
        BILLING_CONSTANT.category.with_plans,
        BILLING_CONSTANT.category.without_plans];

    _this.customerGroupList = [{
        name: window.PAYFIRMA.TEXT_STRINGS.ALL_CUSTOMERS,
        key: BILLING_CONSTANT.category.all
    }, {
        name: window.PAYFIRMA.TEXT_STRINGS.WITH_PLANS,
        key: BILLING_CONSTANT.category.with_plans
    }, {
        name: window.PAYFIRMA.TEXT_STRINGS.WITHOUT_PLANS,
        key: BILLING_CONSTANT.category.without_plans
    }];

    _this.selectedPlanStatus = [];
    _this.planStatusList = [{
        key: BILLING_CONSTANT.status.active,
        name: window.PAYFIRMA.TEXT_STRINGS.ACTIVE
    }, {
        name:window.PAYFIRMA.TEXT_STRINGS.CANCELLED,
        key: BILLING_CONSTANT.status.cancelled
    }, {
        name:window.PAYFIRMA.TEXT_STRINGS.RETRY,
        key: BILLING_CONSTANT.status.retry
    }, {
        name:window.PAYFIRMA.TEXT_STRINGS.SUSPENDED,
        key: BILLING_CONSTANT.status.suspended
    }, {
        name: window.PAYFIRMA.TEXT_STRINGS.EXPIRED,
        key: BILLING_CONSTANT.status.expired
    }, {
        name:window.PAYFIRMA.TEXT_STRINGS.PAUSED,
        key: BILLING_CONSTANT.status.paused
    }];

    //customerGroup
    _this.setSelectedCustomerGroup = function (customerGroup, initOnly, event) {
        var key = customerGroup.key;

        _this.customerGroupSelected = customerGroup;

        if (key === BILLING_CONSTANT.category.with_plans) {
            _this.selectedPlanStatus = defaultSelected;

            // Active status should be the default value
            _this.planStatusList[0].planStatusSelected = true;

            _this.filterplanstatus = {
                recurring_plan_statuses: defaultSelected
            };

            _this.titleStatus = window.PAYFIRMA.TEXT_STRINGS.STATUS+' (' + defaultSelected.length + ')';
        } else {
            _this.selectedPlanStatus = [];
            _this.filterplanstatus = [];
        }

        _this.filtercustomervault = key;
        _this.title = customerGroup.name;

        if (!initOnly) {
            _this.doDrop();
        }
        if (event){
            event.stopPropagation();
        }
    };

    _this.setSelectedCustomerGroup(_this.customerGroupList[0], true);

    _this.setSelectedPlanStatus = function (planStatus) {
        var statusKey = planStatus.key;

        if (planStatus.planStatusSelected) {
            _this.selectedPlanStatus.push(statusKey);
            planStatus.planStatusSelected = true;
        } else if (_.includes(_this.selectedPlanStatus, statusKey)) {
            _this.selectedPlanStatus = _.without(_this.selectedPlanStatus, statusKey);
            planStatus.planStatusSelected = false;

        }

        if (_this.selectedPlanStatus.length === 0) {
            _this.titleStatus = window.PAYFIRMA.TEXT_STRINGS.STATUS;
            _this.filterplanstatus = {
                recurring_plan_statuses: defaultSelected
            };
        } else {
            _this.titleStatus = window.PAYFIRMA.TEXT_STRINGS.STATUS+' (' + _this.selectedPlanStatus.length + ')';
            _this.filterplanstatus = {
                recurring_plan_statuses: _this.selectedPlanStatus
            };
        }
    };

    let planFilterElement = document.querySelector('#plan-filter');
    let statusFilterElement = document.querySelector('#status-filter');

    $(document).click(function(){
        _this.isDropped = false;
        _this.isDroppedStatus = false;
    });
    planFilterElement.onclick = function(e) {
        _this.isDropped = true;
        e.stopPropagation();
    };

    statusFilterElement.onclick = function(e) {
        _this.isDroppedStatus = true;
        e.stopPropagation();
    };
}


angular
.module('payhqUIApp')
.component('paperCustomervaultFilter', {
    bindings: {
        filtercustomervault: '=',
        filterplanstatus: '='
    },
    controller: paperCustomervaultFilter,
    controllerAs: 'paperCustomervaultFilter',
    templateUrl: 'component/paper-filter/paper-customervault-filter/paper-customervault-filter.html'
});