import angular from 'angular';

angular
.module('payhqUIApp')
.filter('creditCardCurrency', function () {
    return function (creditCardCurrencyList) {
        if (creditCardCurrencyList && creditCardCurrencyList.length) {
            return _.map(creditCardCurrencyList, function(creditCardCurrency){
                var newCreditCardCurrencyString = creditCardCurrency;
                //@author: carl
                //server side sometimes return a $ sign after currency code
                //clien side needs to sanitize the values for the server!;)
                if (creditCardCurrency.indexOf('$') === creditCardCurrency.length - 1) {
                    newCreditCardCurrencyString = creditCardCurrency.substring(0, creditCardCurrency.length - 1);
                }
                return newCreditCardCurrencyString;
            }).join((' '));
        }
        return '';
    };
});