import angular from 'angular';
import SessionStorageService from 'service/session-storage-service';
import ConfigurationService from 'service/configuration-service';
import User from 'model/user';
/**
 * @ngdoc service
 * @name payhqUIApp.payhqUIApp.service:CurrentUserService
 * @description
 *
 * Service for Current User functions
 *
 */
angular
    .module('payhqUIApp')
    .service('CurrentUserService', function CurrentUserService($location, UserFactory, HttpClientService) {

        let currentUser = undefined;
        let userCurrentUrl = ConfigurationService.getConfig().merchantServiceApi + 'user/current';

        function getCurrentLoggedInUser() {
            let user = SessionStorageService.get().getCurrentUserInfo();

            if (angular.isDefined(user)) {

                return HttpClientService
                    .doGet(
                        userCurrentUrl)
                    .then((response) => {
                        return new User(response.data);
                    }).catch((error) => {
                        throw error;
                    });
            }
            return undefined;
        }

        function updateSessionStorageService(updatedUserData) {
            SessionStorageService.get().setUser(updatedUserData);
            SessionStorageService.update();
        }

        return {
            updateCurrentUser: function (newUserObject) {
                let updateData = {
                    first_name: newUserObject.first_name,
                    last_name: newUserObject.last_name,
                    email: newUserObject.email,
                    telephone: newUserObject.telephone
                };

                if (newUserObject.password) {
                    updateData.password = newUserObject.password;
                }

                return HttpClientService
                .doPatch(userCurrentUrl, updateData)
                .then(function (response) {
                    currentUser = new User(response.data);
                    updateSessionStorageService(currentUser);
                    return currentUser;
                });
            },
            getCurrentUser: function () {
                return new Promise(function (resolve, reject) {
                    if (currentUser) {
                        resolve(currentUser);
                    } else {
                        getCurrentLoggedInUser()
                            .then(function (user) {
                                if (user) {
                                    currentUser = user;

                                    updateSessionStorageService(currentUser);
                                    resolve(currentUser);
                                }
                            })
                            .catch(function () {
                                currentUser = undefined;
                                reject();
                            });
                    }
                });
            },

            deleteCurrentUser: function () {
                currentUser = undefined;
            },
            canUnlockMerchantUser: function () {
                return currentUser && currentUser.isPayfirmaAdmin();
            }
        };

    });