import angular from 'angular';
import CONSTANT_APP from 'constant/constant-app';
import CONSTANT_FILTER from 'constant/constant-filter';

angular
.module(CONSTANT_APP.module)
.filter(CONSTANT_FILTER.custom_filter, function ($filter) {

    function hasCustomToString(obj) {
        return angular.isFunction(obj.toString) && obj.toString !== Object.prototype.toString;
    }

    var comparator = function (actual, expected) {
        if (angular.isUndefined(actual)) {
            // No substring matching against `undefined`
            return false;
        }
        if ((actual === null) || (expected === null)) {
            // No substring matching against `null`; only match against `null`
            return actual === expected;
        }
        if ((angular.isObject(expected) && !angular.isArray(expected)) ||
            (angular.isObject(actual) && !hasCustomToString(actual))) {
            // Should not compare primitives against objects, unless they have custom `toString` method
            return false;
        }

        var lowcasActual = angular.lowercase('' + actual);
        if (angular.isArray(expected)) {
            var match = false;
            expected.forEach(function (e) {
                // strict matching for exactly match
                if (angular.lowercase('' + e) === lowcasActual){
                    match = true;
                }

            });
            return match;
        } else {
            var lowcaseExpected = angular.lowercase('' + expected);
            return lowcasActual.indexOf(lowcaseExpected) !== -1;
        }
    };

    return function (array, expression) {
        return $filter('filter')(array, expression, comparator);
    };
});