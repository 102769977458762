import angular from 'angular';

function paperStaffFilter($element, UserService) {

    var _this = this;

    _this.isDropped = false;

    _this.title = window.PAYFIRMA.TEXT_STRINGS.ALL_STAFF;
    _this.filterstaff = 'All';
    _this.isLoading = false;

    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function(){
        if (_this.isDropped === true){
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });

    $element.on('click', function (e) {
        if (_this.isDropped === true){
            $('dropdown').show();
        }
        e.stopPropagation();
    });

    _this.staffList = [];

    _this.setSelectedStaff = function (staff) {
        if (staff === 'All') {
            _this.title = window.PAYFIRMA.TEXT_STRINGS.ALL_STAFF;
            _this.filterstaff = 'All';
        } else {
            _this.title = staff.first_name + ' ' + staff.last_name;
            _this.filterstaff = staff.id;
        }
        _this.doDrop(false);
    };

    function initFilter() {
        _this.isLoading = true;
        UserService.getAllUsers()
            .then(function(userListResponse) {
                _this.staffList = userListResponse;
                _this.isLoading = false;
            });
    }

    initFilter();
}


angular
.module('payhqUIApp')
.component('paperStaffFilter', {
    bindings: {
        filterstaff: '='
    },
    controller: paperStaffFilter,
    controllerAs: 'paperStaffFilter',
    templateUrl: 'component/paper-filter/paper-staff-filter/paper-staff-filter.html'
});