/* @flow */

import angular from 'angular';
import ConfigurationService from 'service/configuration-service';
import HppSetting from 'model/hpp-setting';

/**
 * @ngdoc service
 * @name payhqUIApp.customerApp.service:HppSettingService
 * @requires Restangular
 * @description
 *
 * Service for hosted pay page related endpoints
 *
 */
angular
    .module('settingsApp')
    .service('HppSettingService', function (HttpClientService) {

        const privateUrl = ConfigurationService.getConfig().merchantServiceApi + 'hpp';
        const privateLinkAppUrl = ConfigurationService.getConfig().merchantServiceApi + 'hpp/link-app';
        const privateHppLinkUrl = ConfigurationService.getConfig().merchantServiceApi + 'link-app';
        const privateExternalStoreUrl = ConfigurationService.getConfig().merchantServiceApi + 'hpp/external-store';


        function isResponseValid(response) {
            return response && response.data && response.data.access_token;
        }

        function updateAccessToken(hppLookupId, token) {
            const updateRequest = {
                access_token:token
            };
            return HttpClientService
                    .doPut(
                        privateUrl + '/' + hppLookupId + '/token',
                        updateRequest
                    )
                    .then(function (response) {
                        return new HppSetting(response.data);
                    });
        }

        function getRevokeTokenFromJWT (jwtToken) {
            if (jwtToken) {
                let subStingArray = jwtToken.split(/[..]/);
                let base64String = subStingArray[1];
                let decodedString = window.atob(base64String);
                let jsonDecodedObject = JSON.parse(decodedString);
                return jsonDecodedObject.access_token;
            }
            return undefined;
        }

        return {
            getHppSetting: function () {
                return HttpClientService
                    .doGet(privateUrl)
                    .then((response) => {
                        return new HppSetting(response.data);
                    }).catch((errors) => {
                        throw errors;
                    });
            },
            getHppLinkAppSetting: function () {
                return HttpClientService
                    .doGet(privateLinkAppUrl)
                    .then((response) => {
                        return new HppSetting(response.data);
                    }).catch((errors) => {
                        throw errors;
                    });
            },
            getExternalStoreSetting: function () {
                return HttpClientService
                    .doGet(privateExternalStoreUrl)
                    .then((response) => {
                        return response.data;
                    }).catch((errors) => {
                        throw errors;
                    });
            },
            deleteExternalStoreSetting: function (externalStoreLookupId) {
                return HttpClientService
                    .doDelete(privateExternalStoreUrl + '/' + externalStoreLookupId)
                    .then(function(response) {
                        return response;
                    }).catch(function(errors) {
                        throw errors;
                    });
            },
            createHppLinkAppSetting: function () {
                return HttpClientService
                    .doPost(privateLinkAppUrl)
                    .then((response) => {
                        return new HppSetting(response.data);
                    }).catch((errors) => {
                        throw errors;
                    });
            },
            createHppSetting: function (createHppSettingData) {
                return HttpClientService
                    .doPost(privateUrl,createHppSettingData)
                    .then((response) => {
                        return new HppSetting(response.data);
                    }).catch((errors) => {
                        throw errors;
                    });
            },
            updateHppSetting: function (hppLookupId, updateRequest) {
                return HttpClientService
                    .doPut(
                        privateUrl + '/' + hppLookupId,
                        updateRequest
                    )
                    .then(function (response) {
                        return new HppSetting(response.data);
                    })
                    .catch(function (errors) {
                        throw errors;
                    });
            },
            deleteHppSetting: function (hppLookupId) {
                return HttpClientService.doDelete(
                        privateUrl + '/' + hppLookupId
                    ).then(function(response){
                        return response;
                    }).catch(function(errors){
                        throw errors;
                    });
            },
            getGenerateToken: function (requestData) {
                let hqConfig = ConfigurationService.getConfig();
                return HttpClientService.doFormPost(
                    hqConfig.hqJwtTokenUrl + 'oauth/token',
                    {
                        grant_type: 'client_credentials',
                        client_id: requestData.client_id,
                        client_secret: requestData.client_secret
                    },{ headers: { 'Authorization': 'Basic ' + window.btoa(requestData.client_id + ':' + requestData.client_secret) } },
                     false)
                    .then(function (response) {
                        if (isResponseValid(response)) {
                            return updateAccessToken(requestData.lookup_id,response.data.access_token);

                        } else {
                            throw new Error('Cannot get generated token');
                        }
                    })
                    .catch(function (errors) {
                        throw errors;
                    });

            },
            revokeToken: function (accessToken) {
                let hqConfig = ConfigurationService.getConfig();
                const rawToken = getRevokeTokenFromJWT(accessToken);
                return HttpClientService.doFormDelete(hqConfig.hqJwtTokenUrl + 'oauth/revoke_token',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + rawToken
                        }
                    }, {}, true)
                    .then(function() {
                        return true;
                    }).catch(function (errors) {
                        throw errors;
                    });
            },
            deleteHppLink: function (hppLinkLookupId, hppLookupId) {
                let requestUrl = privateHppLinkUrl + '/' + hppLinkLookupId + '/hpp/' + hppLookupId;
                return HttpClientService.doDelete( requestUrl)
                    .then(function(response){
                        return response;
                    }).catch(function(errors){
                        throw errors;
                    });
            },
            createHppLink: function (createHppLinkData) {
                return HttpClientService
                .doPost(privateHppLinkUrl,createHppLinkData)
                .then((response) => {
                    return new HppSetting(response.data);
                }).catch((errors) => {
                    throw errors;
                });
            }
        };
    });
