import angular from 'angular';

function deleteStaffProfile(UserService, Utils, PaperToastService, $translate, OauthTokenService) {

    let _this = this;

    _this.cancel = function () {
        _this.componentEnabled = false;
    };

    _this.deleteStaff = function () {
        return OauthTokenService.revokeTokenWithUserId(_this.staffUser.id)
        .then(function () {
            return UserService.deleteUser(_this.staffUser.lookup_id);
        })
        .then(function () {
            _this.componentEnabled = false;
            $translate('deleteStaffSuccess').then((translation) => {
                PaperToastService.create(translation, 'update');
            });

            Utils.go('staff');
        })
        .catch((error) => {
            PaperToastService.create(error.message, 'error');
            _this.cancel();
        });
    };
}

angular
.module('payhqUIApp')
.component('deleteStaffProfile', {
    bindings: {
        componentEnabled: '=',
        staffUser: '<'
    },
    controller: deleteStaffProfile,
    controllerAs: 'deleteStaffProfile',
    templateUrl: 'view/staff/edit/modal/delete-staff-profile.html'
});