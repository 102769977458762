import angular from 'angular';

function imageUploadButton($scope, FileReader, ImageStore) {
    var self = this;

    self.enableDeletePhotoModal = false;

    function showImageCropView(imageForCropping) {
        //launch modal for image editing
        self.uploadPhotoModalEnabled = true;
        window.PAYFIRMA.CommunicationService.triggerOpenImageCropperModalEvent(imageForCropping);
    }
    ImageStore.onImageCroppingUpdated(function (imageForCropping) {
        showImageCropView(imageForCropping);
    });

    self.uploadPhoto = function () {
        FileReader.openFile();
    };

    self.photoExists = function () {
        return ImageStore.imageExists();
    };

    self.editPhoto = function () {
        ImageStore.requestImageForCopping();
    };

    self.deletePhoto = function () {
        self.enableDeletePhotoModal = true;
    };
}

angular
.module('payhqUIApp')
.component('imageUploadButton', {
    bindings: {},
    controller: imageUploadButton,
    controllerAs: 'imageUploadButton',
    templateUrl: 'view/image-editing/component/paper-image-upload-button.html'
});