import angular from 'angular';

angular
.module('payhqUIApp')
.service('CSVFileReader', function ($log) {
    var fileInputElement;
    var fileTobeRead;
    var addedListeners = {};

    function loadFile(fileToBeRead, success, fail) {
        //Initialize the FileReader object to read the file
        var fileReader = new window.FileReader();
        fileReader.onload = function () {
            var dataUrl = fileReader.result;
            if (dataUrl) {
                success({
                    filename: fileToBeRead.name,
                    size: fileToBeRead.size,
                    dataUrl: dataUrl
                });
            } else {
                fail('cannot read the image properly');
            }
        };
        fileReader.readAsText(fileToBeRead);
    }
    return {
        CONTENT_TYPE: {
            FILE: /text.*/
        },

        openFile: function () {
            if (fileInputElement) {
                fileInputElement.click();
                fileInputElement.onclick = function () {
                    this.value = null;
                };
            } else {
                $log.error('please initialize the file input element first.');
            }
        },
        readFile: function (fileElement, contentType, success, fail, fileValidation) {
            
            fileInputElement = fileElement; // attach service to the file input
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                
                if (addedListeners['change']) {
                    return;
                }

                var changeEvent = function () {
                    //Set the extension for the file
                    //Get the file object
                    fileTobeRead = fileElement.files[0];
                    //Check of the extension match
                    if (fileTobeRead) {
                        if (fileValidation && fileValidation(fileTobeRead)) {
                            fail(fileValidation(fileTobeRead));
                        } else if (fileTobeRead.type.match(contentType)) {
                            loadFile(fileTobeRead, success, fail);
                            fileElement.files = undefined;
                            fileTobeRead = undefined;
                        } else {
                            fail('not expected file type ' + contentType);
                        }

                    } else {
                        fail('cannot load the file');
                    }
                };

                addedListeners['change'] = changeEvent;
                fileElement.addEventListener('change', changeEvent , false);
            } else {
                fail('your browser does not support file uploading');
            }
        }
    };
});