import angular from 'angular';

function paperTextarea($scope, $element) {
    var _this = this;

    _this.focused = function() {
        _this.isSelected = true;
        $element[0].classList.add('isFocused');
    };
    _this.blurred = function() {
        _this.isSelected = false;
        $element[0].classList.remove('isFocused');
    };
    // Besides ngChange, no way to watch changes in input
    $scope.$watch('paperTextarea.ngModel', function(value) {
        if (value){
            $element[0].classList.add('isFilled');
        } else {
            $element[0].classList.remove('isFilled');
        }
    });

}
angular
.module('payhqUIApp')
.component('paperTextarea', {
    bindings: {
        label: '@',
        ngModel: '=',
        heapIdentifier : '@'
    },
    controller: paperTextarea,
    controllerAs: 'paperTextarea',
    templateUrl: 'component/paper-textarea/paper-textarea.html'
});