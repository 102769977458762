import angular from 'angular';
import MerchantApplication from 'model/merchant-application';
import ConfigurationService from 'service/configuration-service';

/**
 * @ngdoc service
 * @name payhqUIApp.merchantApp.service:MerchantApplicationService
 * @description
 *
 * Service for handling merchantApp
 *
 */

angular
    .module('payhqUIApp')
    .service('MerchantApplicationService',
        function MerchantApplicationService(HttpClientService) {

            function getMerchantApplications(searchOptions) {

                const cursor = searchOptions ? searchOptions.cursor : undefined;
                const limit = searchOptions ? searchOptions.limit : undefined;

                let param = {};

                if (limit) {
                    param.limit = limit;
                }
                if (cursor) {
                    param.after = cursor;
                }

                return HttpClientService.doGet(
                    ConfigurationService.getConfig().merchantServiceApi + 'application', param)
                    .then(function (allApplicationsResponse) {
                        let returnedCursor;

                        if (allApplicationsResponse.data.paging) {
                            returnedCursor = allApplicationsResponse.data.paging.cursor;
                        }

                        let entities = _.map(allApplicationsResponse.data.entities, function (merchantResponse) {
                            return new MerchantApplication(merchantResponse);
                        });

                        return new window.PAYFIRMA.Paging(returnedCursor, entities);
                    });
            }

            return {
                getNext: function (searchOptions) {
                    return getMerchantApplications({
                        limit: window.PAYFIRMA.CONSTANT.UI_PAGINATION_LIMIT,
                        cursor: searchOptions ? searchOptions.cursor : undefined
                    });
                },
                getInitialData : function () {
                    return getMerchantApplications({
                        limit: window.PAYFIRMA.CONSTANT.UI_PAGINATION_LIMIT
                    });
                },
                createMerchantApplication: function (newApplicationObject) {
                    return HttpClientService
                        .doPost(
                            ConfigurationService.getConfig().merchantServiceApi + 'application',
                            newApplicationObject
                        )
                        .then(function (applicationResponse) {
                            return new MerchantApplication(applicationResponse.data);
                        });
                },
                createMerchantApplicationForAdmin: function (newApplicationObject, merchantId) {
                    return HttpClientService
                        .doPost(
                            ConfigurationService.getConfig().merchantServiceApi + 'application/merchant/' + merchantId,
                            newApplicationObject
                        )
                        .then(function (applicationResponse) {
                            return new MerchantApplication(applicationResponse.data);
                        });
                },
                updateMerchantApplication: function (updatedApplicationsObject) {
                    return HttpClientService
                        .doPut(
                            ConfigurationService.getConfig().merchantServiceApi + 'application/'
                            + updatedApplicationsObject.lookup_id,
                            updatedApplicationsObject
                        )
                        .then(function (applicationResponse) {
                            return new MerchantApplication(applicationResponse.data);
                        });
                },
                updateMerchantApplicationForAdmin: function (updatedApplicationsObject, merchantId) {
                    return HttpClientService
                        .doPut(
                            ConfigurationService.getConfig().merchantServiceApi + 'application/'
                            + updatedApplicationsObject.lookup_id
                            + '/merchant/' + merchantId,
                            updatedApplicationsObject
                        )
                        .then(function (applicationResponse) {
                            return new MerchantApplication(applicationResponse.data);
                        });
                },
                refreshMerchantApplication: function (applicationLookupId) {
                    return HttpClientService
                        .doPatch(
                            ConfigurationService.getConfig().merchantServiceApi + 'application/' + applicationLookupId
                        )
                        .then(function (applicationResponse) {
                            return new MerchantApplication(applicationResponse.data);
                        });
                },
                deleteMerchantApplication: function (applicationLookupId) {
                    return HttpClientService.doDelete(
                        ConfigurationService.getConfig().merchantServiceApi + 'application/' + applicationLookupId
                    );
                }
            };
        });