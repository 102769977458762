/* @flow */

import angular from 'angular';
import ConfigurationService from 'service/configuration-service';
import UtilityService from 'service/utility-service';
import ExportFileService from 'service/export-file-service';
import BILLING_CONSTANT from 'constant/billing-constant';

/**
 * @ngdoc service
 * @name payhqUIApp.customerApp.service:CustomerService
 * @requires CustomerFactory
 * @requires TransactionService
 * @description
 *
 * Service for handling customer behaviours
 *
 */
angular
.module('customerApp')
.service('CustomerService', function CustomerService(CustomerFactory,
    TransactionService, HttpClientService, PlanService, $log, $window, SessionService) {
    const CUSTOMER_VAULT_TRANSACTION_SIZE = 5;
    let exportFileService = new ExportFileService($window);

    function parseSimpleCustomerList(customerList) {
        var parsedCustomerList;
        if (customerList) {
            parsedCustomerList = CustomerFactory.parseSimpleCustomerList(customerList);
        }
        return parsedCustomerList;
    }

    function parseCustomer(customer) {
        var parsedCustomer;
        if (customer) {
            parsedCustomer = CustomerFactory.buildComplexCustomer(customer);
        }
        return parsedCustomer;
    }

    function getCustomer(searchOptions) {
        const cursor = searchOptions ? searchOptions.cursor : undefined;
        const limit = searchOptions ? searchOptions.limit : undefined;

        var param = {};

        if (limit) {
            param.limit = limit;
        }
        if (cursor) {
            param.after = cursor;
        }

        return HttpClientService.doGet(ConfigurationService.getConfig().customerServiceApi+'customer', param)
            .then(
                function (response) {
                    var returnedCursor;
                    if (response.data.paging) {
                        returnedCursor = response.data.paging.cursor;
                    }

                    return new window.PAYFIRMA.Paging(
                        returnedCursor, parseSimpleCustomerList(response.data));
                }
            ).catch(function(error){
                throw error;
            });
    }

    function getCustomerEFTTransactions (customerLookupId) {
        const searchParameters = {
            customer_lookup_id: customerLookupId,
            channel : 'EFT'
        };
        return window.PAYFIRMA.TransactionDataService.getInitialData({
            searchParameters: searchParameters,
            limit: CUSTOMER_VAULT_TRANSACTION_SIZE
        }).then(function (responseRecentEFTTransactions) {
            let sortedUnionedList = UtilityService.orderBy(responseRecentEFTTransactions, 'transaction_time', 'desc');
            let slicedSortedUnionedList = UtilityService.slice(sortedUnionedList, 0, 5);
            return slicedSortedUnionedList;
        });
    }

    return {
        customerList : [],

        getNext: function (searchOptions) {
            return getCustomer({
                limit: window.PAYFIRMA.CONSTANT.PAGINATION_LOAD_BATCH_SIZE,
                cursor: searchOptions ? searchOptions.cursor : undefined
            });
        },

        getInitialData : function () {
            return getCustomer({
                limit: window.PAYFIRMA.CONSTANT.PAGINATION_INITIAL_LOAD_BATCH_SIZE
            });
        },

        getCustomerEFTTransactions: function (customerLookupId) {
            return getCustomerEFTTransactions(customerLookupId);
        },

        /**
         * Get customers for a specific recurring plan.
         *
         * @return {Array} List of customers
         */
        getCustomersByRecurringPlan: function (planLookupId) {
            var param = {
                limit: window.PAYFIRMA.CONSTANT.PLAN_PAGINATION_LIMIT
            };
            return HttpClientService.doGet(ConfigurationService.getConfig()
                .customerServiceApi+'customer/plan/'+planLookupId, param)
                .then(
                    function (response) {
                        return parseSimpleCustomerList(response.data);
                    }
                ).catch(function(error){
                    throw error;
                });
        },

        /**
         * Get a customer.
         *
         * @param {Number} customerId - Id of customer to fetch
         * @returns {Object} customer - Returns a customer object
         */
        getCustomer: function (customerLookupId) {
            return HttpClientService.doGet(ConfigurationService.getConfig()
                .customerServiceApi+'customer/' + customerLookupId)
                .then(
                    function (response) {
                        var fetchedCustomer = parseCustomer(response.data);
                        return fetchedCustomer;
                    }, function () {
                        return undefined;
                    }
                );
        },

        /**
         * Get a customer (Unparsed Raw data).
         *
         * @param {Number} customerId - Id of customer to fetch
         * @returns {Object} customer - Returns a customer object
         */
        getCustomerRaw: function (customerLookupId) {
            return HttpClientService.doGet(ConfigurationService.getConfig()
                .customerServiceApi+'customer/' + customerLookupId)
                .then(
                    function (response) {
                        return response.data;
                    }, function () {
                        return undefined;
                    }
                );
        },

        getCustomersForAdvancedSearch: function(filter) {
            let queryParams = [];
            if (!_.isEmpty(filter.first_name)){
                queryParams.push(`first_name=${filter.first_name}`);
            }
            if (!_.isEmpty(filter.last_name)) {
                queryParams.push(`last_name=${filter.last_name}`);
            }
            if (!_.isEmpty(filter.company)) {
                queryParams.push(`company=${filter.company}`);
            }
            if (!_.isEmpty(filter.email_address)) {
                queryParams.push(`email_address=${filter.email_address}`);
            }
            if (!_.isEmpty(filter.custom_id)) {
                queryParams.push(`custom_id=${filter.custom_id}`);
            }
            
            const queryString = queryParams.join('&');
        
            return HttpClientService.doGet(ConfigurationService.getConfig().customerServiceApi + 'customer' + (queryString ? '?' + queryString : ''))
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    throw err;
                });
        },

        /**
         * Get Customer's full email, first name and last name from search input.
         * This will be used at Web Terminal and Card Terminal
         *
         * @param email
         * @returns {Object} customer - Returns a customer object with just the fields defined above
         */
        getCustomersForAutoComplete: function(email) {
            return HttpClientService.doGet(ConfigurationService.getConfig()
            .customerServiceApi + 'customer/autocomplete?email=' + email)
            .then(response => {
                return response;
            }).catch(function(error){
                throw error;
            });
        },

        /**
         * Get a customer's vault info.
         *
         * @param {Number} customerLookupId - Customer lookup id to use for fetching
         * @param fetchAdditionalCustomerRelatedData - load transaction/plan/invoice data if true
         * @returns {Object} customer - Returns a customer object
         */
        getVaultCustomer: function (customerLookupId, fetchAdditionalCustomerRelatedData) {

            let loadAdditionalData = fetchAdditionalCustomerRelatedData === undefined ?
                true : fetchAdditionalCustomerRelatedData;

            let fetchedCustomer = null;
            let recentTransactions = [];
            let promise = HttpClientService.doGet(ConfigurationService.getConfig()
                    .customerServiceApi + 'customer/' + customerLookupId)
                .then(function(response){
                    fetchedCustomer = parseCustomer(response.data);
                    return fetchedCustomer;
                });

            let hasInvoiceMaster = SessionService.checkUserPermission('InvoiceMaster');
            let hasRecurringMaster = SessionService.checkUserPermission('RecurringMaster');
            let hasEFT = SessionService.checkUserPermission('EFTMaster');

            if (loadAdditionalData) {
                const channel = 'E_COMMERCE,INVOICE,MOBILE,RECURRING,TABLET_POS,VT,CARD_TERMINAL,HPP,IFRAME,PAY_SUBSCRIPTION';
                promise = promise.then(function () {
                    if (hasEFT){
                        getCustomerEFTTransactions(fetchedCustomer.lookup_id).then(function(recentEFTTransactions){
                            fetchedCustomer.setEFTTransactions(recentEFTTransactions);
                        });
                    }
                    if (hasInvoiceMaster) {
                        return fetchedCustomer.getInvoices(fetchedCustomer.id, true);
                    }
                })
                .then(function () {
                    const searchParameters = {
                        email_address: fetchedCustomer.email,
                        for_customer_vault: true,
                        channel
                    };
                    return window.PAYFIRMA.TransactionDataService.getInitialData({
                        searchParameters: searchParameters,
                        limit: CUSTOMER_VAULT_TRANSACTION_SIZE
                    });
                }).then(function (responseRecentTransactionsByEmail) {
                    recentTransactions = responseRecentTransactionsByEmail;
                    const searchParameters = {
                        customer_lookup_id: fetchedCustomer.lookup_id,
                        channel
                    };
                    return window.PAYFIRMA.TransactionDataService.getInitialData({
                        searchParameters: searchParameters,
                        limit: CUSTOMER_VAULT_TRANSACTION_SIZE
                    });
                }).then(function (responseRecentTransactionsByLookupId) {
                    let unionedList = UtilityService.unionBy(recentTransactions,
                        responseRecentTransactionsByLookupId, 'id');
                    let sortedUnionedList = UtilityService.orderBy(unionedList, 'transaction_time', 'desc');
                    let slicedSortedUnionedList = UtilityService.slice(sortedUnionedList, 0, 5);
                    fetchedCustomer.setTransactions(slicedSortedUnionedList);

                    if (hasRecurringMaster) {
                        return PlanService.get();
                    } else {
                        return null;
                    }
                }).then(function (allPlansObject) {
                    if (allPlansObject) {
                        fetchedCustomer.setMerchantPlans(allPlansObject.entities);
                    }
                    return fetchedCustomer;
                });
            }

            return promise.catch(function (error) {
                $log.error(error);
                return fetchedCustomer;
            });
        },
        exportCustomers: function (customerGroup,filterplanstatus) {
            let conditionWhereWithPlan = '';
            if (customerGroup === BILLING_CONSTANT.category.with_plans) {
                conditionWhereWithPlan = '?with_subscription=true';
                if (filterplanstatus && filterplanstatus.recurring_plan_statuses
                    && filterplanstatus.recurring_plan_statuses.length) {
                    conditionWhereWithPlan += '&subscription_status='
                    + filterplanstatus.recurring_plan_statuses.toString();
                }
            } else if (customerGroup === BILLING_CONSTANT.category.without_plans) {
                conditionWhereWithPlan = '?with_subscription=false';
            }

            return HttpClientService.doGet(ConfigurationService.getConfig()
                .customerServiceApi + 'customer/export' + conditionWhereWithPlan)
                .then(function (response) {
                    let fileName = response.data.file_name;

                    if (fileName) {
                        exportFileService.export(fileName);
                    } else {
                        throw new Error('File name cannot be empty');
                    }
                });
        },

        exportCustomerSubscriptions: function (planLookupId, subscriptionStatusList) {

            let subscriptionStatusParam = '';

            if (subscriptionStatusList && subscriptionStatusList.length) {
                subscriptionStatusParam = '?subscription_status=' + subscriptionStatusList.join(',');
            }

            return HttpClientService.doGet(ConfigurationService.getConfig()
                    .customerServiceApi + 'customer/plan/' + planLookupId + '/export' + subscriptionStatusParam)
                .then(function (response) {
                    let fileName = response.data.file_name;

                    if (fileName) {
                        exportFileService.export(fileName);
                    } else {
                        throw new Error('File name cannot be empty');
                    }
                });
        },

        updateExistingCustomer: function (existingCustomer) {
            // Update an existing customer
            return HttpClientService.doPut(ConfigurationService.getConfig()
                .customerServiceApi+'customer/' + existingCustomer.lookup_id,existingCustomer)
            .then(
                function (response) {
                    return parseCustomer(response.data);
                }, function (error) {
                    return {
                        error: error
                    };
                }
            );
        },
        deleteCustomer: function (customerLookupId) {
            return HttpClientService
            .doDelete(ConfigurationService.getConfig()
                .customerServiceApi + 'customer/' + customerLookupId)
                .then(function(response){
                    return response;
                }).catch(function(error){
                    throw new Error(error.data.errors[0].message);
                });
        },
        createNewCustomer: function(newCustomer){
            return HttpClientService.doPost(
                    ConfigurationService.getConfig().customerServiceApi + 'customer', newCustomer)
            .then(
                function (response) {
                    return parseCustomer(response.data);
                }, function (error) {
                    return {
                        error: error
                    };
                }
            );
        },
        createNewCustomerInBulk: function(newCustomer){
            return HttpClientService.doPost(
                    ConfigurationService.getConfig().customerServiceApi + 'customer/addBulk', newCustomer)
            .then(
                function (response) {
                    return response.data;
                }
            ).catch(function(error){
                throw new Error(error.data.errors[0].message);
            });
        },
        //in order to inject the service for unit test
        //need to refactor customer service to not return a new Object, but rather return the service itself
        setExportFileService: function(service) {
            exportFileService = service;
        },

        getExportFileService: function() {
            return exportFileService;
        }
    };
});
