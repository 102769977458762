import angular from 'angular';

/**
 * @ngdoc service
 * @name payhqUIApp.service:LocalStorageService
 * @description
 *
 * Service for handling local data storage
 * for now it only handles compress and store large objects that exceeds cookie size limit
 *
 */
//TODO maybe we should check object size after compression to make sure it is still below cookie size limit
angular
    .module('payhqUIApp')
    .service('LocalStorageService', function ($cookieStore, $log) {
        function isObject(value) {
            return value && Object.prototype.toString.call(value).slice(8, -1) === 'Object';
        }

        function deleteCookie(name) {
            $cookieStore.remove(name);
        }

        return {
            save: function (name, value) {
                var valueToBeStored = value;
                if (isObject(valueToBeStored)) {
                    valueToBeStored = JSON.stringify(valueToBeStored);
                }
                $cookieStore.put(name, window.btoa(valueToBeStored));
            },

            get: function (name) {
                try {
                    var encodedValue = $cookieStore.get(name);
                    if (encodedValue) {
                        return window.atob(encodedValue);
                    }
                } catch (e) {
                    $log.error('cannot get cookie ' + name + ': ' + e);
                    deleteCookie(name);
                    //clean cookies if get cookie failed
                }

                return undefined;
            },

            getObject: function (name) {
                try {
                    var encodedValue = $cookieStore.get(name);
                    if (encodedValue) {
                        var decodedValue = window.atob(encodedValue);
                        if (decodedValue) {
                            return JSON.parse(decodedValue);
                        }
                    }
                } catch (e) {
                    $log.error('cannot get cookie object ' + name + ': ' + e);
                    deleteCookie(name);
                    //clean cookies if get cookie failed
                }

                return undefined;
            },

            delete: function (name) {
                deleteCookie(name);
            }

        };
    });