import 'rxjs';

window.PAYFIRMA = window.PAYFIRMA || {};

var saveCreditCardEnabledEvent;
var refreshCreditCardListEvent;
var removeCreditCardEnabledEvent;
var deleteCustomerProfileEvent;
var confirmDeleteCustomerEvent;
var refreshRecurringPlanListEvent;
var saveSubscriptionEnabledEvent;
var retryTransactionEvent;
var openTransactionConfirmEvent;
var cancelTransactionConfirmEvent;
var transactionCompleleteEvent;
var systemErrorEnabledEvent;
var imageUpdateEvent;
var croppedImageUpdateEvent;
var openImageCropperModalEvent;
var saveCustomerEvent;
var languageChangedEvent;
var customerSuccessfullySavedEvent;
var httpAuthErrorEvent;
var httpAuthSuccessfulEvent;
var systemIdleEvent;
var modalCloseEvent;
var paperModalOpenEvent;
let addApiKeyEnabledEvent;
let editApiKeyEnabledEvent;
let apiKeyCreatedEvent;
let apiKeyModifiedEvent;
let apiKeyRefreshEvent;
let refreshApiKeyConfirmationEvent;
let imageUploadEvent;
let displayLiveDashboardData;
let originalTransactionPopulatedEvent;
let totalIncomingRequestEvent;

let fileUploadEvent;
let confirmBulkCustomerUploadEvent;
let confirmPartialCustomerUploadEvent;

function onEvent(trigger, callback) {
    var triggerCopy = trigger;
    if (!triggerCopy) {
        triggerCopy = new window.Rx.Subject();
    }
    triggerCopy.subscribe(callback);
    return triggerCopy;
}

function triggerEvent(trigger, data) {
    var triggerCopy = trigger;
    if (!triggerCopy) {
        triggerCopy = new window.Rx.Subject();
    }
    triggerCopy.onNext(data);
    return triggerCopy;
}

function disableEventTrigger(trigger) {
    var triggerCopy = trigger;
    if (triggerCopy) {
        triggerCopy.dispose();
    }
    return undefined;
}

window.PAYFIRMA.CommunicationService = {
    onSaveCreditCardModalOpen: function (callback) {
        saveCreditCardEnabledEvent = onEvent(saveCreditCardEnabledEvent, callback);
    },
    triggerSaveCreditCardModalOpen: function (creditCardObject, customer) {
        saveCreditCardEnabledEvent = triggerEvent(saveCreditCardEnabledEvent, {
            creditCardObject: creditCardObject,
            customer: customer
        });
    },
    disableSaveCreditCardModalOpenTrigger: function () {
        saveCreditCardEnabledEvent = disableEventTrigger(saveCreditCardEnabledEvent);
    },
    onRemoveCreditCardModalOpen: function (callback) {
        removeCreditCardEnabledEvent = onEvent(removeCreditCardEnabledEvent, callback);
    },
    triggerRemoveCreditCardModalOpen: function (removedCreditCardLookupId) {
        removeCreditCardEnabledEvent =
            triggerEvent(removeCreditCardEnabledEvent, removedCreditCardLookupId);
    },
    disableRemoveCreditCardModalOpenTrigger: function () {
        removeCreditCardEnabledEvent = disableEventTrigger(removeCreditCardEnabledEvent);
    },
    onDeleteCustomerProfileModalOpen: function (callback) {
        deleteCustomerProfileEvent = onEvent(deleteCustomerProfileEvent, callback);
    },
    triggerDeleteCustomerProfileModalOpen: function (customerData) {
        deleteCustomerProfileEvent =
            triggerEvent(deleteCustomerProfileEvent, customerData);
    },
    disableDeleteCustomerProfileModalOpenTrigger: function () {
        deleteCustomerProfileEvent = disableEventTrigger(deleteCustomerProfileEvent);
    },
    onConfirmDeleteCustomer: function (callback) {
        confirmDeleteCustomerEvent = onEvent(confirmDeleteCustomerEvent, callback);
    },
    triggerConfirmDeleteCustomer: function () {
        confirmDeleteCustomerEvent =
            triggerEvent(confirmDeleteCustomerEvent);
    },
    disableConfirmDeleteCustomerTrigger: function () {
        confirmDeleteCustomerEvent = disableEventTrigger(confirmDeleteCustomerEvent);
    },
    onSaveSubscriptionModalOpen: function (callback) {
        saveSubscriptionEnabledEvent = onEvent(saveSubscriptionEnabledEvent, callback);
    },
    triggerSaveSubscriptionModalOpen: function (subscription) {
        saveSubscriptionEnabledEvent = triggerEvent(saveSubscriptionEnabledEvent, subscription);
    },
    disableSaveSubscriptionModalOpenTrigger: function () {
        saveSubscriptionEnabledEvent = disableEventTrigger(saveSubscriptionEnabledEvent);
    },
    onRefreshCreditCardList: function (callback) {
        refreshCreditCardListEvent = onEvent(refreshCreditCardListEvent, callback);
    },
    triggerRefreshCreditCardList: function (creditCardListresponse) {
        refreshCreditCardListEvent = triggerEvent(refreshCreditCardListEvent, creditCardListresponse);
    },
    disableRefreshCreditCardListTrigger: function () {
        refreshCreditCardListEvent = disableEventTrigger(refreshCreditCardListEvent);
    },
    onRefreshRecurringPlanList: function (callback) {
        refreshRecurringPlanListEvent = onEvent(refreshRecurringPlanListEvent, callback);
    },
    triggerRefreshRecurringPlanList: function (subscriptions) {
        refreshRecurringPlanListEvent = triggerEvent(refreshRecurringPlanListEvent, subscriptions);
    },
    disableRefreshRecurringPlanListTrigger: function () {
        refreshRecurringPlanListEvent = disableEventTrigger(refreshRecurringPlanListEvent);
    },
    onRetryTransactionEvent: function (callback) {
        retryTransactionEvent = onEvent(retryTransactionEvent, callback);
    },
    triggerRetryTransactionEvent: function () {
        retryTransactionEvent = triggerEvent(retryTransactionEvent);
    },
    disableRetryTransactionEventTrigger: function () {
        retryTransactionEvent = disableEventTrigger(retryTransactionEvent);
    },
    onOpenTransactionConfirmEvent: function (callback) {
        openTransactionConfirmEvent = onEvent(openTransactionConfirmEvent, callback);
    },
    triggerOpenTransactionConfirmEvent: function (transactionResponse) {
        openTransactionConfirmEvent =
            triggerEvent(openTransactionConfirmEvent, transactionResponse);
    },
    disableOpenTransactionConfirmEventTrigger: function () {
        openTransactionConfirmEvent = disableEventTrigger(openTransactionConfirmEvent);
    },
    onCancelTransactionConfirmEvent: function (callback) {
        cancelTransactionConfirmEvent = onEvent(cancelTransactionConfirmEvent, callback);
    },
    triggerCancelTransactionConfirmEvent: function () {
        cancelTransactionConfirmEvent = triggerEvent(cancelTransactionConfirmEvent);
    },
    disableCancelTransactionConfirmEvent: function () {
        cancelTransactionConfirmEvent = disableEventTrigger(cancelTransactionConfirmEvent);
    },

    onTransactionCompleleteEvent: function (callback) {
        transactionCompleleteEvent = onEvent(transactionCompleleteEvent, callback);
    },

    triggerTransactionCompleleteEvent: function () {
        transactionCompleleteEvent = triggerEvent(transactionCompleleteEvent);
    },

    disableTransactionCompleleteEvent: function () {
        transactionCompleleteEvent = disableEventTrigger(transactionCompleleteEvent);
    },

    onSystemErrorEnabledEvent: function (callback) {
        systemErrorEnabledEvent = onEvent(systemErrorEnabledEvent, callback);
    },

    triggerSystemErrorEnabledEvent: function (messages) {
        systemErrorEnabledEvent = triggerEvent(systemErrorEnabledEvent, messages);
    },

    disableSystemErrorEnabledEvent: function () {
        systemErrorEnabledEvent = disableEventTrigger(systemErrorEnabledEvent);
    },

    onSystemIdleEvent: function (callback) {
        systemIdleEvent = onEvent(systemIdleEvent, callback);
    },

    triggerSystemIdleEvent: function (showSystemIdleEvent) {
        systemIdleEvent = triggerEvent(systemIdleEvent, showSystemIdleEvent);
    },

    disableSystemIdleEvent: function () {
        systemIdleEvent = disableEventTrigger(systemIdleEvent);
    },

    onModalCloseEvent: function (callback) {
        modalCloseEvent = onEvent(modalCloseEvent, callback);
    },

    triggerModalCloseEvent: function () {
        modalCloseEvent = triggerEvent(modalCloseEvent);
    },

    onImageUpdateEvent: function (callback) {
        imageUpdateEvent = onEvent(imageUpdateEvent, callback);
    },

    triggerImageUpdateEvent: function () {
        imageUpdateEvent = triggerEvent(imageUpdateEvent);
    },

    disableImageUpdateEvent: function () {
        imageUpdateEvent = disableEventTrigger(imageUpdateEvent);
    },

    onCroppedImageUpdateEvent: function (callback) {
        croppedImageUpdateEvent = onEvent(croppedImageUpdateEvent, callback);
    },

    triggerCroppedImageUpdateEvent: function () {
        croppedImageUpdateEvent = triggerEvent(croppedImageUpdateEvent);
    },

    disableCroppedImageUpdateEventTrigger: function () {
        croppedImageUpdateEvent = disableEventTrigger(croppedImageUpdateEvent);
    },

    onOpenImageCropperModalEvent: function (callback) {
        openImageCropperModalEvent = onEvent(openImageCropperModalEvent, callback);
    },

    triggerOpenImageCropperModalEvent: function (imageForCropping) {
        openImageCropperModalEvent = triggerEvent(openImageCropperModalEvent, imageForCropping);
    },

    disableOpenImageCropperModalEventTrigger: function () {
        openImageCropperModalEvent = disableEventTrigger(openImageCropperModalEvent);
    },
    onLanguageChangedEvent: function (callback) {
        languageChangedEvent = onEvent(languageChangedEvent, callback);
    },

    triggerTotalIncomingRequestEvent: function (totalIncomingRequest) {
        totalIncomingRequestEvent = triggerEvent(totalIncomingRequestEvent, totalIncomingRequest);
    },
    onTotalIncomingRequestEvent: function (callback) {
        totalIncomingRequestEvent = onEvent(totalIncomingRequestEvent, callback);
    },

    triggerLanguageChangedEvent: function () {
        languageChangedEvent = triggerEvent(languageChangedEvent);
    },

    disableSaveCustomerEventTrigger: function () {
        saveCustomerEvent = disableEventTrigger(saveCustomerEvent);
    },

    onCustomerSuccessfullySavedEvent: function (callback) {
        customerSuccessfullySavedEvent = onEvent(customerSuccessfullySavedEvent, callback);
    },

    triggerCustomerSuccessfullySavedEvent: function (customer) {
        customerSuccessfullySavedEvent = triggerEvent(customerSuccessfullySavedEvent, customer);
    },

    disableCustomerSuccessfullySavedEvent: function () {
        customerSuccessfullySavedEvent = disableEventTrigger(customerSuccessfullySavedEvent);
    },

    onHttpAuthSuccessfulEvent: function (callback) {
        httpAuthSuccessfulEvent = onEvent(httpAuthSuccessfulEvent, callback);
    },

    triggerAuthSuccessfulEvent: function () {
        httpAuthSuccessfulEvent = triggerEvent(httpAuthSuccessfulEvent);
    },

    disableAuthSuccessfulEvent: function () {
        httpAuthSuccessfulEvent = disableEventTrigger(httpAuthSuccessfulEvent);
    },

    onHttpAuthErrorEvent: function (callback) {
        httpAuthErrorEvent = onEvent(httpAuthErrorEvent, callback);
    },

    triggerHttpAuthErrorEvent: function (errorResponse) {
        httpAuthErrorEvent = triggerEvent(httpAuthErrorEvent, errorResponse);
    },

    disableHttpAuthErrorEvent: function () {
        httpAuthErrorEvent = disableEventTrigger(httpAuthErrorEvent);
    },

    onPaperModalOpenEvent: function (callback) {
        paperModalOpenEvent = onEvent(paperModalOpenEvent, callback);
    },

    triggerPaperModalOpenEvent: function () {
        paperModalOpenEvent = triggerEvent(paperModalOpenEvent);
    },

    onAddApiKeyEnabledEvent: function (callback) {
        addApiKeyEnabledEvent = onEvent(addApiKeyEnabledEvent, callback);
    },
    triggerAddApiKeyEnabledEvent: function () {
        addApiKeyEnabledEvent = triggerEvent(addApiKeyEnabledEvent);
    },
    disableAddApiKeyEnabledEventTrigger: function () {
        addApiKeyEnabledEvent = disableEventTrigger(addApiKeyEnabledEvent);
    },
    onApiKeyCreatedEvent: function (callback) {
        apiKeyCreatedEvent = onEvent(apiKeyCreatedEvent, callback);
    },
    triggerApiKeyCreatedEvent: function () {
        apiKeyCreatedEvent = triggerEvent(apiKeyCreatedEvent);
    },
    disableApiKeyCreatedEventTrigger: function () {
        apiKeyCreatedEvent = disableEventTrigger(apiKeyCreatedEvent);
    },
    onApiKeyModifiedEvent: function (callback) {
        apiKeyModifiedEvent = onEvent(apiKeyModifiedEvent, callback);
    },
    triggerApiKeyModifiedEvent: function () {
        apiKeyModifiedEvent = triggerEvent(apiKeyModifiedEvent);
    },
    disableApiKeyModifiedEventTrigger: function () {
        apiKeyModifiedEvent = disableEventTrigger(apiKeyModifiedEvent);
    },
    onEditApiKeyEnabledEvent: function (callback) {
        editApiKeyEnabledEvent = onEvent(editApiKeyEnabledEvent, callback);
    },
    triggerEditApiKeyEnabledEvent: function (existingApiKey) {
        editApiKeyEnabledEvent = triggerEvent(editApiKeyEnabledEvent, existingApiKey);
    },
    disableEditApiKeyEnabledEventTrigger: function () {
        editApiKeyEnabledEvent = disableEventTrigger(editApiKeyEnabledEvent);
    },
    onRefreshApiKeyConfirmationEvent: function (callback) {
        refreshApiKeyConfirmationEvent = onEvent(refreshApiKeyConfirmationEvent, callback);
    },
    triggerRefreshApiKeyConfirmationEvent: function (apiKey) {
        refreshApiKeyConfirmationEvent = triggerEvent(refreshApiKeyConfirmationEvent, apiKey);
    },
    disableRefreshApiKeyConfirmationEventTrigger: function () {
        refreshApiKeyConfirmationEvent = disableEventTrigger(refreshApiKeyConfirmationEvent);
    },
    onApiKeyRefreshEvent: function (callback) {
        apiKeyRefreshEvent = onEvent(apiKeyRefreshEvent, callback);
    },
    triggerApiKeyRefreshEvent: function (apiKey) {
        apiKeyRefreshEvent = triggerEvent(apiKeyRefreshEvent, apiKey);
    },
    disableApiKeyRefreshEventTrigger: function () {
        apiKeyRefreshEvent = disableEventTrigger(apiKeyRefreshEvent);
    },
    onOriginalTransactionPopulatedEvent: function (callback) {
        originalTransactionPopulatedEvent = onEvent(originalTransactionPopulatedEvent, callback);
    },
    triggerOriginalTransactionPopulatedEvent: function (transaction) {
        originalTransactionPopulatedEvent = triggerEvent(originalTransactionPopulatedEvent, transaction);
    },
    disableOriginalTransactionPopulatedEventTrigger: function () {
        originalTransactionPopulatedEvent = disableEventTrigger(originalTransactionPopulatedEvent);
    },
    onImageUploadEvent: function (callback) {
        imageUploadEvent = onEvent(imageUploadEvent, callback);
    },
    triggerImageUploadEvent: function (parentElementName) {
        imageUploadEvent = triggerEvent(imageUploadEvent, parentElementName);
    },
    disableImageUploadEventTrigger: function () {
        imageUploadEvent = disableEventTrigger(imageUploadEvent);
    },
    onDisplayLiveDashboardData: function (callback) {
        displayLiveDashboardData = onEvent(displayLiveDashboardData, callback);
    },
    triggerDisplayLiveDashboardData: function () {
        displayLiveDashboardData = triggerEvent(displayLiveDashboardData);
    },
    onFileUploadEvent: function (callback) {
        if (fileUploadEvent === undefined) {
            fileUploadEvent = onEvent(fileUploadEvent, callback);
        }
    },
    triggerConfirmBulkCustomerUpload: function () {
        confirmBulkCustomerUploadEvent =
            triggerEvent(confirmBulkCustomerUploadEvent);
    },
    triggerPartialBulkCustomerUpload: function () {
        confirmPartialCustomerUploadEvent =
            triggerEvent(confirmPartialCustomerUploadEvent);
    },
    onConfirmBulkCustomerUpload: function (callback) {
        confirmBulkCustomerUploadEvent = onEvent(confirmBulkCustomerUploadEvent, callback);
    },
    onConfirmPartialBulkCustomerUpload: function (callback) {
        confirmPartialCustomerUploadEvent = onEvent(confirmPartialCustomerUploadEvent, callback);
    },
    triggerFileUploadEvent: function (parentElementName) {
        fileUploadEvent = triggerEvent(fileUploadEvent, parentElementName);
    }
};
