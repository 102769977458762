/* @flow */

import angular from 'angular';
import ConfigurationService from 'service/configuration-service';

/**
 * @ngdoc service
 * @name payhqUIApp.customerApp.service:EFTFeeService
 * @requires Restangular
 * @description
 *
 * Service for eft-fee related endpoints
 *
 */
angular
    .module('settingsApp')
    .service('EFTFeeService', function (HttpClientService) {

        const privateEFTFee = ConfigurationService.getConfig().merchantServiceApi + 'merchant/';

        return {

            getEFTFees: function (merchantId, queryParams) {
                return HttpClientService
                    .doGet(privateEFTFee + merchantId + '/eft-fee'
                    , queryParams)
                    .then((response) => {
                        return response.data;
                    }).catch((error) => {
                        throw error;
                    });
            },

            createEFTFees: function (merchantId, gatewayInfoId, requestObj) {
                return HttpClientService
                    .doPost(
                        privateEFTFee + merchantId + '/processor/' + gatewayInfoId + '/eft-fee',
                        requestObj)
                    .then((response) => {
                        return response.data;
                    }).catch((error) => {
                        throw error;
                    });
            },

            updateEFTFees: function (merchantId, gatewayInfoId, requestObj) {
                return HttpClientService
                    .doPut(
                        privateEFTFee + merchantId + '/processor/' + gatewayInfoId + '/eft-fee',
                        requestObj)
                    .then((response) => {
                        return response.data;
                    }).catch((error) => {
                        throw error;
                    });
            },


            deleteEFTFee: function (merchantId, gatewayInfoId, eftFeeId) {
                return HttpClientService
                    .doDelete(
                        privateEFTFee + merchantId + '/processor/' + gatewayInfoId + '/eft-fee/' + eftFeeId)
                    .then((response) => {
                        return response;
                    }).catch((error) => {
                        throw error;
                    });
            }
        };
    });
