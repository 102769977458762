import angular from 'angular';

function paperAdvancedSearch($element){

    const TITLE = 'Status';
    let HEADING = 'This search will not be affected by the date range';
    const FIELDSET_ERROR_MESSAGE = 'At least one field must be filled';
    const DEFAULT_NAME = 'Select';
    var _this = this;
    _this.isDropped = false;

    _this.isFieldDropped = false;
    _this.fieldSet = [];

    _this.isLoading = false;
    _this.selectedField = {
        names: [],
        keys: []
    };
    _this.advancedsearch = null;

    if (!_.isEmpty(_this.fieldListOverride)) {
        _this.fieldList = _this.fieldListOverride;
        HEADING = '';
    } else {
        _this.fieldList = [
            { name: 'transactionId', key: 'id' },
            { name: 'email', key: 'email' },
            { name: 'name', key: 'name' },
            { name: 'description', key: 'description' },
            { name: 'transactionCustomId', key: 'customid' },
            { name: 'company', key: 'company' },
            { name: 'customerId', key: 'customerid' },
            { name: 'salesPerson', key: 'salesperson' },
            { name: 'orderId', key: 'orderid' },
            { name: 'invoiceId', key: 'invoiceid' },
            { name: 'Card Suffix', key: 'suffix' }
        ];
    }

    _this.$onInit = () => {
        _this.title = TITLE;
        _this.fieldsetErrorMessage = FIELDSET_ERROR_MESSAGE;
        _this.heading = HEADING;
        _this.initializeFieldSet();
    };

    // initialize activeFieldList
    _this.initActiveFieldList = () => {
        _this.activeFieldList = [];
        _this.fieldList.forEach(element => {
            _this.activeFieldList.push(element);
        });
    };

    // Initialize advanced search parameters
    _this.initializeFieldSet = () => {
        _this.fieldSet = [
            { element: { name:DEFAULT_NAME }, keyword: '' },
            { element: { name:DEFAULT_NAME }, keyword: '' },
            { element: { name:DEFAULT_NAME }, keyword: '' }
        ];
        _this.droppedFieldSet = -1;
        _this.clearErrorMessage();
        _this.initActiveFieldList();
    };

    _this.doDrop = () => {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
        _this.droppedFieldSet = -1;
    };

    // Field selection dropdown
    _this.doFieldDrop = (index) => {
        _this.droppedFieldSet = _this.droppedFieldSet === index ? -1 : index;
    };

    // Action on clicking a specific field to search
    _this.selectField = (index, element) => {
        _this.fieldSet[index].element = element;
        _this.fieldSet[index].dropped = !_this.fieldSet[index].dropped;
        _this.droppedFieldSet = -1;

        // reset activeFieldList
        _this.initActiveFieldList();

        // iterate and remove the items from dropdown which are selected
        for (let i = 0; i < _this.fieldSet.length; i++) {
            if (_this.fieldSet[i].element.name !== 'Select') {
                // remove selected elements
                _this.activeFieldList.splice(_this.activeFieldList.indexOf(_this.fieldSet[i].element), 1);
            }
        }
    };

    // Close Dropdown
    _this.closeDropdown = () => {
        if (_this.isDropped === true){
            _this.isDropped = false;
            _this.clearErrorMessage();
        }
    };

    _this.clearErrorMessage = () => {
        $('.advanced-input').removeClass('error-border');
        $('.advanced-set-field').removeClass('error-border');
        $('.advanced-fieldset-error').addClass('hide');
    };

    _this.blurred = event => {
        if (event.relatedTarget && event.relatedTarget.tagName === 'INPUT'){
            _this.droppedFieldSet = -1;
        }
    };

    /*
     * Transaction will capture this change and send request for searching
     */
    _this.doSearch = (fieldSet) => {
        let keywords = [];
        let fields = [];
        let names = [];

        for (let field of fieldSet) {
            if (field.element.name !== DEFAULT_NAME && field.keyword !== '') {
                keywords.push(field.keyword);
                fields.push(field.element.key);
                names.push(field.element.name);
            }
        }
        if (keywords.length) {
            _this.advancedsearch = {
                keywords: keywords,
                fields: fields,
                names: names
            };
            _this.closeDropdown();
        } else {
            $('.advanced-input').addClass('error-border');
            $('.advanced-set-field').addClass('error-border');
            $('.advanced-fieldset-error').removeClass('hide');
        }
    };

    $(document).click(() => {
        _this.closeDropdown();
    });

    $element.on('click', e => {
        if (_this.isDropped === true){
            $('dropdown').show();
        }
        e.stopPropagation();
    });

    // Hit Enter key to search
    $('.advanced-search-list').keyup(event => {
        if (event.keyCode === 13) {
            _this.doSearch(_this.fieldSet);
        }
    });
}

angular
.module('payhqUIApp')
.component('paperAdvancedSearch', {
    bindings: {
        advancedsearch: '=',
        fieldListOverride: '='
    },
    controller: paperAdvancedSearch,
    controllerAs: 'paperAdvancedSearch',
    templateUrl: 'component/paper-filter/paper-advanced-search/paper-advanced-search.html'
});