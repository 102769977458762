import angular from 'angular';

function paperButtonGroupMenu($element) {
    var dropdownMenu = $($element.find('dropdown'));
    var _this;
    _this = this;

    _this.openDropdown = function (e) {
        _this.dropdownIsOpen = true;
        dropdownMenu.show();
        e.stopPropagation();
    };

    $(window.document).click(function () {
        _this.dropdownIsOpen = false;
        dropdownMenu.hide();
    });
}
angular
.module('payhqUIApp')
.component('paperButtonGroupMenu', {
    bindings: {
        onMainActionClick: '&',
        mainButtonText: '<',
        showSubmenu:'<'
    },
    transclude: true,
    controller: paperButtonGroupMenu,
    controllerAs: 'paperButtonGroupMenu',
    templateUrl: 'component/paper-button-group-menu/paper-button-group-menu.html'
});