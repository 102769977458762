import angular from 'angular';

function paperMerchantstatusFilter($element) {

    var _this;
    _this = this;

    _this.isDropped = false;

    _this.doDrop = function() {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function() {
        if (_this.isDropped === true) {
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });
    $element.on('click', function (event) {
        if (_this.isDropped === true) {
            $('dropdown').show();
        }
        event.stopPropagation();
    });

    var defaultTitle = window.PAYFIRMA.TEXT_STRINGS.STATUS;
    var defaultSelected = ['New', 'Active', 'Testing'];

    _this.title = defaultTitle + ' (' + defaultSelected.length + ')';
    _this.selectedMerchantStatus = defaultSelected;
    _this.merchantStatusList = [
        { name: window.PAYFIRMA.TEXT_STRINGS.NEW, key: 'New', merchantStatusSelected: true },
        { name: window.PAYFIRMA.TEXT_STRINGS.ACTIVE, key: 'Active', merchantStatusSelected: true },
        { name: window.PAYFIRMA.TEXT_STRINGS.SUSPENDED, key: 'Suspended', merchantStatusSelected: false },
        { name: window.PAYFIRMA.TEXT_STRINGS.INACTIVE, key: 'Inactive', merchantStatusSelected: false },
        { name: window.PAYFIRMA.TEXT_STRINGS.CLOSED, key: 'Closed', merchantStatusSelected: false },
        { name: window.PAYFIRMA.TEXT_STRINGS.TESTING, key: 'Testing', merchantStatusSelected: true }
    ];

    _this.setSelectedMerchantStatus = function(merchantStatus) {
        var key = merchantStatus.key;

        if (merchantStatus.merchantStatusSelected) {
            _this.selectedMerchantStatus.push(key);
            merchantStatus.merchantStatusSelected = true;
        } else if (_.includes(_this.selectedMerchantStatus, key)) {
            _this.selectedMerchantStatus = _.without(_this.selectedMerchantStatus, key);
            merchantStatus.merchantStatusSelected = false;
        }

        if (_this.selectedMerchantStatus.length === 0) {
            _this.title = defaultTitle;
            _this.filtermerchantstatus = { status: defaultSelected };

        } else {
            _this.title = defaultTitle + ' (' + _this.selectedMerchantStatus.length + ')';
            _this.filtermerchantstatus = { status: _this.selectedMerchantStatus };
        }
    };

    function initFilter() {
        _this.filtermerchantstatus = { status: _this.selectedMerchantStatus };
    }

    initFilter();
}


angular
.module('payhqUIApp')
.component('paperMerchantstatusFilter', {
    bindings: {
        filtermerchantstatus: '='
    },
    controller: paperMerchantstatusFilter,
    controllerAs: 'paperMerchantstatusFilter',
    templateUrl: 'component/paper-filter/paper-merchantstatus-filter/paper-merchantstatus-filter.html'
});
