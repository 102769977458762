import angular from 'angular';
import QRCode from 'qrcodejs2';
import ConfigurationService from 'service/configuration-service';
import UtilityService from 'service/utility-service';
import SessionStorageService from 'service/session-storage-service';

function hppSettings(HppSettingService, SessionService, PaperToastService, PlanService, IframeSettingService, AgeVerificationService, $log, $route, $timeout) {

    const REFRESH_KEYWORD = 'REFRESH';
    const DELETE_KEYWORD = 'DELETE';

    let _this = this;
    _this.isPaginationLoading = false;
    _this.isLoadingData = true;
    _this.editMode = false;
    _this.hasAccessToken = false;
    _this.isModalEnabled = false;
    _this.isDeleteModalEnabled = false;
    _this.hppLinkAppEnable = false;
    _this.hppLinkList = null;
    _this.externalStoreEnabled = false;

    _this.selectedHppLinkItem = null;
    _this.selectedExternalStoreItem = null;

    _this.isPaymentPlans = false;
    //Used to update Currency and Amount Inputs when a Payment Plan is selected.
    _this.paymentPlanList = [];
    _this.paymentPlanDetails = [];

    _this.optionTrueFalse = [
        { 'key': 'NO','value': false },
        { 'key': 'YES','value': true }
    ];

    _this.tokenValidityArray = window.PAYFIRMA.TOKEN_VALIDITIES.slice(0, 7);

    _this.iframe = {
        validity : _this.tokenValidityArray[0].value,
        token : '',
        lookupId : null,
        clientId : null,
        clientSecret : null
    };

    _this.qrCode = {
        modal : false
    };

    _this.ageVerification = {
        token : '',
        lookupId : null,
        clientId : null,
        clientSecret : null
    };

    _this.externalStoreList = [];

    _this.modalType = '';

    _this.planError = '';

    _this.initializeCurrencySelections = function() {
        // Currency Toggle, get the merchant's gateways.
        const user = SessionStorageService.get().getUser();
        if (user && user.currencies && user.currencies.length) {
            _this.currencyList = _.map(user.currencies, function (currency) {
                return {
                    key: currency,
                    value: currency
                };
            });
        }
    };

    _this.initializeCurrencySelections();

    _this.onPaymentPlanSelected = function (selectedPaymentPlan) {
        _this.paymentPlanDetails.forEach(plan => {
            if (plan.key === selectedPaymentPlan){
                _this.createLinkItem.amount = plan.value.amount;
                _this.createLinkItem.currency = plan.value.currency;
            }
        });
    };

    function checkPlanCycles (plan){
        var numberOfPayments = plan.number_of_payments;
        // Was sometimes returning as 0 others as "0"
        if (!numberOfPayments || numberOfPayments === 0 || numberOfPayments === '0'){
            return '∞';
        } else {
            return numberOfPayments;
        }
    }

    function getPlanOptionName (plan) {
        var cycleCount = checkPlanCycles(plan);
        return plan.name + ' $' + plan.amount + ' (' + plan.currency + ' ' +
            cycleCount + '/' + plan.frequency + ')';
    }

    _this.getPlans = function () {
        //Get all plans for merchant
        PlanService.get()
        .then((allPlans) => {
            if (allPlans.entities.length > 0){
                _this.isPaymentPlans = true;
            }
            //Format them for display purposes.
            _this.merchantPlansOptions = _.map(
                _.sortBy(allPlans.entities, function(plan){
                    return plan.name ? plan.name.toLowerCase() : '';
                }),
                function (merchantPlan) {
                    merchantPlan.frequency = UtilityService.toLowerCaseCapitalized(merchantPlan.frequency);
                    return {
                        value: merchantPlan,
                        key: getPlanOptionName(merchantPlan)
                    };
                });

            _this.merchantPlansOptions.forEach(plan => {
                _this.paymentPlanList.push({ 'key': plan.key,'value': plan.value.id });

                //Populate variable so we can automatically update Currency and Amount Inputs when a payment plan is selected.
                let planDetails = { 'amount':plan.value.amount, 'currency':plan.value.currency };
                _this.paymentPlanDetails.push({ 'key': plan.value.id,'value': planDetails });

                //HPP links with payment plans only contain plan id. Match plan description, from the loaded merchant payment plans.
                //Also update the displayed amount to show use the payment plans amount.
                if (_this.hppLinkList){
                    _this.hppLinkList.forEach(hppLink => {
                        if (hppLink.payment_plan_id && hppLink.payment_plan_id === plan.value.id){
                            hppLink.amount = plan.value.amount;
                            hppLink.payment_plan_description = plan.key;
                        }
                    });
                }
            });
        })
        .catch((error) => {
            _this.planError = error;
        });
    };

    _this.createHppSetting = function () {
        _this.loading = true;
        const createHppSettingData = {
            domain: _this.hppSettingsData.domain,
            redirect_url: _this.hppSettingsData.redirect_url,
            callback_url: _this.hppSettingsData.callback_url,
            capture_billing_address: _this.hppSettingsData.capture_billing_address ? _this.hppSettingsData.capture_billing_address : false,
            capture_shipping_address: _this.hppSettingsData.capture_shipping_address ? _this.hppSettingsData.capture_shipping_address : false
        };

        return HppSettingService.createHppSetting(createHppSettingData).then(function (response) {
            _this.loading = false;
            PaperToastService.create('Created Hosted Pay Page setting.', 'update');
            $route.reload();
            return response;
        }).catch(function (errors) {
            let errorMessage = _this.convertErrorMessage(errors);
            _this.loading = false;
            PaperToastService.create(errorMessage, 'error');
            $log.error(errorMessage);
            throw new Error(errorMessage);
        });
    };

    _this.updateHppSetting = function () {
        _this.loading = true;
        const updateHppSettingData = {
            domain: _this.hppSettingsData.domain,
            redirect_url: _this.hppSettingsData.redirect_url,
            callback_url: _this.hppSettingsData.callback_url,
            capture_billing_address: _this.hppSettingsData.capture_billing_address ? _this.hppSettingsData.capture_billing_address : false,
            capture_shipping_address: _this.hppSettingsData.capture_shipping_address ? _this.hppSettingsData.capture_shipping_address : false
        };

        return HppSettingService.updateHppSetting(_this.hppSettingsData.lookup_id,updateHppSettingData).then(function (response) {
            _this.loading = false;
            PaperToastService.create('Updated Hosted Pay Page setting.', 'update');
            $route.reload();
            return response;
        }).catch(function (errors) {
            let errorMessage = _this.convertErrorMessage(errors);
            _this.loading = false;
            PaperToastService.create(errorMessage, 'error');
            $log.error(errorMessage);
            throw new Error(errorMessage);
        });
    };


    _this.convertErrorMessage = function (errors) {

        $('.domain_input').removeClass('ng-invalid');
        $('.redirect_url_input').removeClass('ng-invalid');
        $('.callback_url_input').removeClass('ng-invalid');

        const errorArray = errors.data.errors;
        let errorMessage = 'Error in Hosted Pay Page setting';
        if (errorArray[0].code === 'Https') {
            errorMessage = 'Please, use https (Hypertext Transfer Protocol Secure) ';

            if ((errorArray[0].message.includes('domain field'))){
                $('.domain_input').addClass('ng-invalid');
            }
            if (_this.hppSettingsData.redirect_url && _this.hppSettingsData.redirect_url.substring(0, 8).toLowerCase() !== ('https://')) {
                $('.redirect_url_input').addClass('ng-invalid');
            }
            if (_this.hppSettingsData.callback_url && _this.hppSettingsData.callback_url.substring(0, 8).toLowerCase() !== ('https://')) {
                $('.callback_url_input').addClass('ng-invalid');
            }

        } else if ((errorArray[0].message.includes('Domain_UNIQUE'))){
            errorMessage = 'The domain already exists';
            $('.domain_input').addClass('ng-invalid');
        }

        return errorMessage;
    };


    _this.generateToken = function () {
        const hppDataInformation = _this.hppSettingsData;

        return HppSettingService.getGenerateToken(hppDataInformation).then(function (response) {
            _this.loading = false;
            PaperToastService.create('Success to generate the token.', 'update');
            $route.reload();
            return response;
        }).catch(function () {
            let errorMessage = 'Error to generate the token.';
            _this.loading = false;
            PaperToastService.create(errorMessage, 'error');
            $log.error(errorMessage);
            throw new Error(errorMessage);
        });
    };

    _this.refreshToken = function (type) {
        _this.modalType = type;
        _this.isModalEnabled = true;
    };
    _this.refreshCancel = function () {
        _this.refreshInput = undefined;
        _this.isModalEnabled = false;
    };
    _this.isRefreshTyped = function () {
        return _this.refreshInput === REFRESH_KEYWORD;
    };

    function generateIframeToken (data, creating = true) {
        return IframeSettingService.getIframeGenerateToken(data).then(function (response) {
            PaperToastService.create(`Successfully ${creating? 'generated' : 'refreshed' } IFrame token`, 'update');
            _this.iframe.token = response.data.access_token;
            return response;
        }).catch(function () {
            let errorMessage = 'Error to generate the token.';
            _this.loading = false;
            PaperToastService.create(errorMessage, 'error');
            $log.error(errorMessage);
            throw new Error(errorMessage);
        });
    }

    function generateAgeVerificationToken (data, creating = true) {
        return AgeVerificationService.getAgeVerificationGenerateToken(data).then(function (response) {
            PaperToastService.create(`Successfully ${creating? 'generated' : 'refreshed' } Age-Verification token`, 'update');
            _this.ageVerification.token = response.data.access_token;
            return response;
        }).catch(function () {
            let errorMessage = 'Error to generate the token.';
            _this.loading = false;
            PaperToastService.create(errorMessage, 'error');
            $log.error(errorMessage);
            throw new Error(errorMessage);
        });
    }

    function accessTokenModal (type) {
        switch (type) {
            case 'IFRAME':
                return _this.iframe.token;
            case 'AGE_VERIFICATION':
                return _this.ageVerification.token;
            default:
                return _this.hppSettingsData.access_token;
        }
    }

    function refreshTokenHelper (type) {
        switch (type) {
            case 'IFRAME':
                const data = {
                    lookup_id : _this.iframe.lookupId,
                    client_id : _this.iframe.clientId,
                    client_secret : _this.iframe.clientSecret
                };
                return generateIframeToken(data, false);
            case 'AGE_VERIFICATION':
                return generateAgeVerificationToken(_this.ageVerification);
            default:
                return _this.generateToken();
        }
    }

    _this.refreshConfirm = function () {
        return HppSettingService.revokeToken(accessTokenModal(_this.modalType)).then(function (response) {
            if (response) {
                return refreshTokenHelper(_this.modalType);
            } else {
                let errorMessage = 'Error to revoke the token.';
                _this.loading = false;
                PaperToastService.create(errorMessage, 'error');
                $route.reload();
                throw new Error(errorMessage);
            }
        }).catch(function () {
            let errorMessage = 'Error to refresh the token.';
            _this.loading = false;
            PaperToastService.create(errorMessage, 'error');
            $log.error(errorMessage);
            throw new Error(errorMessage);
        })
        .finally(function () {
            _this.isModalEnabled = false;
        });

    };

    _this.deleteModal = function (type) {
        _this.modalType = type;
        _this.isDeleteModalEnabled = true;
    };

    _this.deleteModalCancel = function () {
        _this.deleteInput = undefined;
        _this.isDeleteModalEnabled = false;
    };

    _this.isDeleteTyped = function () {
        return _this.deleteInput === DELETE_KEYWORD;
    };

    function resetIframe(){
        _this.iframe.lookupId = null;
        _this.iframe.token = '';
    }

    function resetAgeVerfification(){
        _this.ageVerification.lookupId = null;
        _this.ageVerification.token = '';
    }

    _this.deleteHppSettingById = function (lookupId) {
        return HppSettingService.deleteHppSetting(lookupId).then(function (response) {
            _this.loading = false;
            PaperToastService.create('Delete Hosted Pay Page setting.', 'update');
            $route.reload();
            return response;
        }).catch(function (errors) {
            let errorMessage = _this.convertErrorMessage(errors);
            _this.loading = false;
            PaperToastService.create(errorMessage, 'error');
            $log.error(errorMessage);
            throw new Error(errorMessage);
        });
    };

    function deleteIframeSettings (lookupId) {
        return IframeSettingService.deleteIframeSettings(lookupId).then(function (response) {
            PaperToastService.create('Successfully deleted IFrame setting.', 'update');
            resetIframe();
            return response;
        }).catch(function (errors) {
            let errorMessage = _this.convertErrorMessage(errors);
            PaperToastService.create(errorMessage, 'error');
            $log.error(errorMessage);
            throw new Error(errorMessage);
        })
        .finally(function () {
            _this.loading = false;
            _this.isDeleteModalEnabled = false;
        });
    }

    function deleteAgeVerification (lookupId) {
        return AgeVerificationService.deleteAgeVerification(lookupId).then(function (response) {
            PaperToastService.create('Successfully deleted Age-Verification token.', 'update');
            resetAgeVerfification();
            return response;
        }).catch(function () {
            const errorMessage = 'Error to delete Age-Verification token.';
            PaperToastService.create(errorMessage, 'error');
            $log.error(errorMessage);
            throw new Error(errorMessage);
        })
        .finally(function () {
            _this.loading = false;
            _this.isDeleteModalEnabled = false;
        });
    }

    function deleteTokenHelper (type) {
        switch (type) {
            case 'IFRAME':
                return deleteIframeSettings(_this.iframe.lookupId);
            case 'AGE_VERIFICATION':
                return deleteAgeVerification(_this.ageVerification.lookupId);
            default:
                return _this.deleteHppSettingById(_this.hppSettingsData.lookup_id);
        }
    }

    _this.deleteConfirm = function () {
        const token = accessTokenModal(_this.modalType);
        if (token) {
            return HppSettingService.revokeToken(token).then(function (response) {
                if (response) {
                    deleteTokenHelper(_this.modalType);
                } else {
                    let errorMessage = 'Error deleting token.';
                    _this.loading = false;
                    PaperToastService.create(errorMessage, 'error');
                    $route.reload();
                    throw new Error(errorMessage);
                }
            }).catch(function () {
                let errorMessage = 'Error deleting token.';
                _this.loading = false;
                PaperToastService.create(errorMessage, 'error');
                $log.error(errorMessage);
                throw new Error(errorMessage);
            });
        } else {
            return deleteTokenHelper(_this.modalType);
        }
    };

    _this.enableHppLinkAppSetting = function () {
        _this.loading = true;

        return HppSettingService.createHppLinkAppSetting().then(function (response) {
            _this.loading = false;
            PaperToastService.create('Enabled Pay By Link App setting.', 'update');
            $route.reload();
            return response;
        }).catch(function (errors) {
            let errorMessage = _this.convertErrorMessage(errors);
            _this.loading = false;
            PaperToastService.create(errorMessage, 'error');
            $log.error(errorMessage);
            throw new Error(errorMessage);
        });
    };

    _this.deleteHppLinkAppModal = function () {
        _this.isDeleteHppLinkAppModalEnabled = true;
    };

    _this.deleteHppLinkAppModalCancel = function () {
        _this.deleteHppLinkAppInput = undefined;
        _this.isDeleteHppLinkAppModalEnabled = false;
    };

    _this.isDeleteHppLinkAppTyped = function () {
        return _this.deleteHppLinkAppInput === DELETE_KEYWORD;
    };

    _this.isHppLinkEnabled = function() {
        return SessionService.checkUserPermission('HppLink');
    };

    _this.deleteHppLinkAppConfirm = function () {
        if (_this.hppLinkAppSettingsData && _this.hppLinkAppSettingsData.lookup_id ) {
            return _this.deleteHppSettingById(_this.hppLinkAppSettingsData.lookup_id);

        } else {
            return null;
        }
    };

    _this.convertMoneyFormat = function(amount) {

        if (amount) {
            let bigAmount = amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            return bigAmount;
        } else {
            return '--';
        }

    };

    _this.convertToYesNo = function (boolean) {
        return UtilityService.convertToYesNo(boolean);
    };

    _this.deleteHppLinkModal = function (HPP_LINK_LOOKUP_ID, HPP_LOOKUP_ID) {
        _this.isHppLinkDeleteModalEnabled = true;

        _this.selectedHppLinkItem = {
            'HPP_LINK_LOOKUP_ID': HPP_LINK_LOOKUP_ID,
            'HPP_LOOKUP_ID': HPP_LOOKUP_ID
        };
    };

    _this.deleteHppLinkModalCancel = function () {
        _this.deleteHppLinkInput = undefined;
        _this.isHppLinkDeleteModalEnabled = false;
        _this.resetSelectedHppLinkItem();
    };

    _this.deleteExternalStoreModal = function (EXTERNAL_STORE_LOOKUP_ID) {
        _this.isExternalStoreDeleteModalEnabled = true;
        _this.selectedExternalStoreItem = EXTERNAL_STORE_LOOKUP_ID;
    };

    _this.deleteExternalStoreModalCancel = function () {
        _this.deleteExternalStoreInput = undefined;
        _this.isExternalStoreDeleteModalEnabled = false;
        _this.selectedExternalStoreItem = null;
    };

    _this.isExternalStoreDeleteTyped = function () {
        return _this.deleteExternalStoreInput === DELETE_KEYWORD;
    };

    _this.resetSelectedHppLinkItem = function () {
        _this.selectedHppLinkItem = null;
    };

    _this.isHppLinkDeleteTyped = function () {
        return _this.deleteHppLinkInput === DELETE_KEYWORD;
    };

    _this.deleteHppLinkConfirm = function () {
        return HppSettingService.
            deleteHppLink(_this.selectedHppLinkItem.HPP_LINK_LOOKUP_ID, _this.selectedHppLinkItem.HPP_LOOKUP_ID)
            .then(function (response) {
                _this.loading = false;
                PaperToastService.create('Deleted Pay By Link.', 'update');
                $route.reload();
                return response;
            }).catch(function (errors) {
                let errorMessage = _this.convertErrorMessage(errors);
                _this.loading = false;
                PaperToastService.create(errorMessage, 'error');
                $log.error(errorMessage);
                throw new Error(errorMessage);
            });
    };

    _this.deleteExternalStoreConfirm = function () {
        return HppSettingService.
            deleteExternalStoreSetting(_this.selectedExternalStoreItem)
            .then(function (response) {
                _this.loading = false;
                PaperToastService.create('Deleted External Store', 'update');
                $route.reload();
                return response;
            }).catch(function (errors) {
                let errorMessage = _this.convertErrorMessage(errors);
                _this.loading = false;
                PaperToastService.create(errorMessage, 'error');
                $log.error(errorMessage);
                throw new Error(errorMessage);
            });
    };

    _this.saveIframeSettings = function (isUpdate) {
        _this.loading = true;
        const requestObj = {
            validity : _this.iframe.validity
        };
        let promiseIframe;
        if (!isUpdate){
            promiseIframe = IframeSettingService.saveIframeSettings(requestObj);
        } else {
            promiseIframe = IframeSettingService.updateIframeSettings(requestObj, _this.iframe.lookupId);
        }
        promiseIframe.then(function (response) {
            if (response && response.lookup_id){
                _this.iframe.validity = response.validity;
                _this.iframe.lookupId = response.lookup_id;
                _this.iframe.clientSecret = response.client_secret;
                _this.iframe.clientId = response.client_id;
            }
            if (!isUpdate){
                generateIframeToken(response);
            }
        }).catch(function (error) {
            const errorMessage = `Error ${_this.iframe.lookupId ? 'updating' : 'creating'} settings`;
            $log.error('Error updating settings');
            PaperToastService.create(errorMessage, 'error');
            throw error;
        })
        .finally(function () {
            if (isUpdate){
                const errorMessage = 'Updating IFrame setting';
                PaperToastService.create(errorMessage, 'update');
            }
            _this.loading = false;
        });
    };

    _this.saveAgeVerification = function () {
        _this.loading = true;
        AgeVerificationService.saveAgeVerification().then(function (response) {
            if (response && response.lookup_id){
                _this.ageVerification.lookupId = response.lookup_id;
                _this.ageVerification.clientSecret = response.client_secret;
                _this.ageVerification.clientId = response.client_id;
            }
            generateAgeVerificationToken(_this.ageVerification);
        }).catch(function (error) {
            const errorMessage = `Error ${_this.ageVerification.lookupId ? 'updating' : 'creating'} Age-verification token`;
            $log.error(errorMessage);
            PaperToastService.create(errorMessage, 'error');
            throw error;
        })
        .finally(function () {
            _this.loading = false;
        });
    };


    _this.getIframeSettings = function () {
        return IframeSettingService.getIframeSettings().then(function (response) {
            if (response && response.lookup_id){
                _this.iframe = {
                    validity : response.validity,
                    token : response.access_token,
                    lookupId : response.lookup_id,
                    clientId : response.client_id,
                    clientSecret : response.client_secret
                };
            }
        }).catch(function (error) {
            throw error;
        });
    };

    _this.getAgeVerification= function () {
        return AgeVerificationService.getAgeVerification().then(function (response) {
            if (response && response.lookup_id){
                _this.ageVerification = {
                    token : response.access_token,
                    lookupId : response.lookup_id,
                    clientId : response.client_id,
                    clientSecret : response.client_secret
                };
            }
        }).catch(function (error) {
            throw error;
        });
    };

    _this.getDeleteModalName = function () {
        switch (_this.modalType) {
            case 'IFRAME':
                return 'IFrame';
            case 'AGE_VERIFICATION':
                return 'Age Verification';
            default:
                return 'Hosted Pay Page';
        }
    };

    _this.getExternalStoreSetting = function () {
        return HppSettingService.getExternalStoreSetting().then(function (response) {
            if (response !== undefined && response.length !== 0) {
                _this.externalStoreEnabled = true;
                
                for (let index = 0; index < response.length; index++) {
                    const element = response[index];
                    _this.externalStoreList.push({
                        lookupId : element.lookup_id,
                        date : new Date(element.date).toLocaleString(),
                        storeUrl : element.store_url,
                        isEnabled : element.is_enabled
                    });
                }
            }
        }).catch(function (error) {
            throw error;
        });
    };

    _this.createHppLinkModal = function () {
        _this.isHppLinkCreateModalEnabled = true;
    };

    _this.createHppLinkModalCancel = function () {
        _this.isHppLinkCreateModalEnabled = false;
        _this.resetCreateLinkForm();
    };

    _this.resetCreateLinkForm = function () {
        _this.createLinkItem = {};
    };

    _this.createHppLinkConfirm = function (form, formData) {

        $('.optionTrueFalse').removeClass('ng-invalid');
        $('#amount-editable-invalid').remove();
        $('.custom-field-invalid').remove();
        let isValid = true;

        let editableValue = formData.amount_editable;
        if ( (!formData.amount) && !editableValue && !formData.recurring_payment) {
            $('.optionTrueFalse').addClass('ng-invalid');
            $('.optionTrueFalse').after($('<span id="amount-editable-invalid" style="color:red;font-size:10px;">')
                .text('If your Amount is $0.00 or empty, Amount Editable should be YES.'));
            isValid = false;
        }

        if (formData.require_custom_field === true && !formData.custom_field_name) {
            $('.customFieldName')
                .after($('<span class="custom-field-invalid" id="custom-field-invalid1" style="color:red;font-size:10px;">')
                .text('You need to set the field name if it is required.'));
            isValid = false;
        }

        if (formData.require_custom_field2 === true && !formData.custom_field_name2) {
            $('.customFieldName2')
                .after($('<span class="custom-field-invalid" id="custom-field-invalid2" style="color:red;font-size:10px;">')
                .text('You need to set the field name if it is required.'));
            isValid = false;
        }

        if (formData.require_custom_field3 === true && !formData.custom_field_name3) {
            $('.customFieldName3')
                .after($('<span class="custom-field-invalid" id="custom-field-invalid3" style="color:red;font-size:10px;">')
                .text('You need to set the field name if it is required.'));
            isValid = false;
        }

        if (!isValid) {
            return null;
        }

        if (form.$valid) {
            _this.loading = true;
            if (formData.payment_plan_id){
                editableValue = false;
            }

            const createHppLinkData = {
                hpp_lookup_id: _this.hppLinkAppSettingsData.lookup_id,
                amount: formData.amount === 0 ? null : formData.amount,
                amount_editable: editableValue,
                currency: formData.currency,
                capture_billing_address: formData.capture_billing_address ? formData.capture_billing_address : false,
                capture_shipping_address: formData.capture_shipping_address ? formData.capture_shipping_address : false,
                description: formData.description,
                test_mode: formData.test_mode,
                custom_field_name: formData.custom_field_name,
                require_custom_field: formData.require_custom_field || false,
                custom_field_name2: formData.custom_field_name2,
                require_custom_field2: formData.require_custom_field2 || false,
                custom_field_name3: formData.custom_field_name3,
                require_custom_field3: formData.require_custom_field3 || false
            };

            if (formData.recurring_payment){
                createHppLinkData.payment_plan_id = formData.payment_plan_id;
            }

            return HppSettingService.createHppLink(createHppLinkData).then(function (response) {
                _this.loading = false;
                PaperToastService.create('Created new Pay By Link.', 'update');
                $route.reload();
                return response;
            }).catch(function () {
                let errorMessage = 'Failed to create a new Pay By Link';
                _this.loading = false;
                PaperToastService.create(errorMessage, 'error');
                $log.error(errorMessage);
                throw new Error(errorMessage);
            });
        } else {
            return null;
        }
    };

    _this.toggleShowHppLinkDetails = function (hppLinkObject) {
        if (hppLinkObject.isVisible) {
            hppLinkObject.isVisible = !hppLinkObject.isVisible;
        } else {
            hppLinkObject.isVisible = true;
        }
    };

    _this.generateQRcode = function (hppUrl) {
        _this.qrCode.modal = true;
        $timeout(function () {
            const qrcode = new QRCode('qrcode', {
                width: 256,
                height: 256,
                colorDark : '#000000',
                colorLight : '#ffffff',
                correctLevel : QRCode.CorrectLevel.L
            });
            qrcode.makeCode(hppUrl);
        }, 100);
     
    };

    _this.downloadQRcode = function () {
        const qrCodeUrl = $('#qrcode img').prop('src');
        var a = document.createElement('a');
        a.href = qrCodeUrl;
        a.download = 'QR-Code.jpg';
        a.click();
        _this.qrCode.modal = false;
    };

    _this.cancelQRcode = function () {
        _this.qrCode.modal = false;
    };

    _this.checkAgeVerificationPermission = function () {
        return SessionService.checkUserPermission('Age_Verification');
    };

    _this.$onInit = function () {
        if (_this.checkAgeVerificationPermission()){
            _this.getAgeVerification();
        }
        _this.getExternalStoreSetting();
        _this.getIframeSettings();
        if (_this.hppSettingsData && _this.hppSettingsData.lookup_id ) {
            _this.editMode = true;
            if (_this.hppSettingsData.access_token) {
                _this.hasAccessToken = true;
            }
            _this.hppServiceInitUrl = ConfigurationService.getConfig().hppServiceApi;
        }

        if (_this.hppLinkAppSettingsData && _this.hppLinkAppSettingsData.lookup_id ) {
            _this.hppLinkAppEnable = true;
            _this.hppLinkList = _this.hppLinkAppSettingsData.hosted_pay_page_link_list;
            _this.getPlans();
        }
    };
}


angular
.module('payhqUIApp')
.component('hppSettings', {
    bindings: {
        hppSettingsData: '=',
        hppLinkAppSettingsData: '='
    },
    templateUrl: 'view/settings/hpp-settings/hpp-settings.html',
    controller: hppSettings,
    controllerAs: 'hppSettings'
});