import angular from 'angular';
import SessionStorageService from 'service/session-storage-service';
import ConfigurationService from 'service/configuration-service';
import 'service/pagination-service';
import 'filter/pagination-filter';

function merchant(UrlService, $scope, $timeout, $window) {

    let _this = this;
    _this.isPaginationLoading = false;
    _this.isLoadingData = true;

    _this.paginationService = new window.PAYFIRMA.PaginationService(_this.merchants);

    _this.merchantsForDisplay = _this.paginationService.getAllData() || [];

    window.PAYFIRMA.MerchantDataService.getAllData({
        forceFetch: true
    })
    //always reload from server when enters merchant
        .then(function (data) {
            $timeout(function () {
                _this.paginationService.updateAllData(data);
                _this.merchantsForDisplay = _this.paginationService.getAllData();
                _this.isLoadingData = false;
            });
        });

    _this.expandMerchantDetails = function (requestedMerchant){
        if (_this.currentSelectedMerchant && _this.currentSelectedMerchant !== requestedMerchant) {
            _this.currentSelectedMerchant.show = false;
        }

        _this.currentSelectedMerchant = requestedMerchant;
        requestedMerchant.show = !requestedMerchant.show;
    };

    /**
     * Edit existing merchant
     * @param id
     */
    _this.editMerchantById = function (id) {
        UrlService.go('/merchant/' + id);
    };

    /**
     * Create a new merchant
     */
    _this.createMerchant = function () {
        UrlService.go('/merchant/create');
    };

    /**
     * View users of a given merchant
     * @param id
     */
    _this.viewMerchantUsers = function (id) {
        UrlService.go('/merchant/' + id + '/users');
    };

    // TODO We need to standardize the export methods and remove this old methods (see CIM-161)
    _this.export = function(statusFilter, typeFilter){
        let currentUserInfo = SessionStorageService.get().getCurrentUserInfo();
        var token = currentUserInfo.jwt;
        var apiUrl = ConfigurationService.getConfig().hqApi;

        var types = '',
            statuses = '';

        if (statusFilter.status) {
            statuses = _.join(statusFilter.status);
        }

        if (typeFilter.type) {
            types = _.join(typeFilter.type);
        }

        var searchKeyword;

        if (angular.isUndefined(_this.search)) {
            searchKeyword = '';
        } else {
            searchKeyword = encodeURIComponent(_this.search);
        }

        var url = apiUrl + 'merchant_csv?content_type=text/csv&token=' + token;

        url += (_this.startDate) ? '&date_start=' + _this.startDate : '&date_start=false';
        url += (_this.endDate) ? '&date_end=' + _this.endDate : '&date_end=false';
        url += '&merchant_types=' + types;
        url += '&merchant_statuses=' + statuses;
        url += '&search=' + searchKeyword;

        $window.open(url, 'Download');
    };

    function hasMoreData() {
        return _this.paginationService.hasMoreData()
            && _this.paginationService.nextCursor() < _this.filteredMerchantList.length;
    }

    _this.getPaginationNext = function () {
        _this.paginationService.getNext();
        _this.isPaginationLoading = false;
    };

    /**
     * Infinite scrolling, firing once user hits bottom of the page.
     */
    _this.lazyLoad = function () {
        //check if next page value and grab transactions
        if (hasMoreData()) {
            _this.isPaginationLoading = true;
            //prevent duplicate api calls when scrolling with 1s timeout
            _this.scrollTimeout = setTimeout(function () {
                _this.getPaginationNext();
            }, 0);
        }
    };

    $scope.$on('$destroy', function () {
        if (_this.paginationService) {
            _this.paginationService.unload();
        }
        window.PAYFIRMA.CommunicationService.disableAddApiKeyEnabledEventTrigger();
        window.PAYFIRMA.CommunicationService.disableEditApiKeyEnabledEventTrigger();
        window.PAYFIRMA.CommunicationService.disableApiKeyCreatedEventTrigger();
        window.PAYFIRMA.CommunicationService.disableApiKeyModifiedEventTrigger();
        window.PAYFIRMA.CommunicationService.disableRefreshApiKeyConfirmationEventTrigger();
    });
}

angular.module('payhqUIApp').component('merchant', {
    bindings: {
        merchants: '<'
    },
    controller: merchant,
    controllerAs: 'merchant',
    templateUrl: 'view/merchant/merchant.html'
});