import angular from 'angular';

function paperPlanstatusFilter($element) {

    var _this = this;

    _this.isDroppedStatus = false;
    _this.titleStatus = window.PAYFIRMA.TEXT_STRINGS.STATUS+' (4)';

    _this.doDropStatus = function () {
        _this.isDroppedStatus = !_this.isDroppedStatus;
        _this.currentElement = $element;
    };

    $(document).click(function(){
        if (_this.isDroppedStatus === true){
            _this.isDroppedStatus = false;
            $('.dropdown-status').hide();
        }
    });

    $element.on('click', function(e){
        if (_this.isDroppedStatus === true){
            $('.dropdown-status').show();
        }
        e.stopPropagation();
    });

    var defaultSelected = ['ACTIVE', 'RETRY', 'SUSPENDED', 'PAUSED'];

    _this.selectedPlanStatus = defaultSelected;
    _this.planStatusList = [
        { name: window.PAYFIRMA.TEXT_STRINGS.ACTIVE, key: 'ACTIVE' },
        { name: window.PAYFIRMA.TEXT_STRINGS.CANCELLED, key: 'CANCELLED' },
        { name: window.PAYFIRMA.TEXT_STRINGS.RETRY, key: 'RETRY' },
        { name: window.PAYFIRMA.TEXT_STRINGS.SUSPENDED, key: 'SUSPENDED' },
        { name: window.PAYFIRMA.TEXT_STRINGS.EXPIRED, key:'EXPIRED' },
        { name: window.PAYFIRMA.TEXT_STRINGS.PAUSED, key:'PAUSED' }
    ];

    _this.setSelectedPlanStatus = function (planStatus) {

        var statusKey = planStatus.key;

        if (planStatus.planStatusSelected) {
            _this.selectedPlanStatus.push(statusKey);
            planStatus.planStatusSelected = true;
        } else if (_.includes(_this.selectedPlanStatus, statusKey)) {
            _this.selectedPlanStatus = _.without(_this.selectedPlanStatus, statusKey);
            planStatus.planStatusSelected = false;
        }

        if (_this.selectedPlanStatus.length === 0) {
            _this.titleStatus = window.PAYFIRMA.TEXT_STRINGS.STATUS;
            _this.filterplanstatus = { status: defaultSelected };
        } else {
            _this.titleStatus = window.PAYFIRMA.TEXT_STRINGS.STATUS+' (' + _this.selectedPlanStatus.length + ')';
            _this.filterplanstatus = { status: _this.selectedPlanStatus };
        }
    };

    function initFilter() {
        angular.forEach(_this.planStatusList, function (planStatus) {

            if (planStatus.key !== 'CANCELLED' && planStatus.key !== 'EXPIRED' ){
                planStatus.planStatusSelected = true;
            } else {
                planStatus.planStatusSelected = false;
            }
        });
        _this.filterplanstatus = { status: _this.selectedPlanStatus };
    }

    initFilter();
}

angular
.module('payhqUIApp')
.component('paperPlanstatusFilter', {
    bindings: {
        filterplanstatus: '='
    },
    controller: paperPlanstatusFilter,
    controllerAs: 'paperPlanstatusFilter',
    templateUrl: 'component/paper-filter/paper-planstatus-filter/paper-planstatus-filter.html'
});
