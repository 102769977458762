import angular from 'angular';

function merchantUsers(UrlService, UserService, PaperToastService) {
    var self = this;
    self.go = function(currentUser){
        UrlService.go('merchant/' + self.merchantId + '/user/' + currentUser.lookup_id);
    };

    self.updateMerchantUser = function(form, currentUser){
        if (form.$valid){
            UserService.saveUser(currentUser).then(
                function(response){
                    if (response){
                        PaperToastService.create('Merchant user updated.', 'update');
                    }
                },
                function(error){
                    if (error){
                        PaperToastService.create('Merchant user update failed.', 'error');
                    }
                }
            );
        } else {
            PaperToastService.create('Fill in Required Fields.', 'error');
        }
    };
}

angular
.module('payhqUIApp')
.component('merchantUsers', {
    bindings: {
        users: '<',
        merchantId: '<'
    },
    controller: merchantUsers,
    controllerAs: 'merchantUsers',
    templateUrl: 'view/merchant/users/users.html'
});