import angular from 'angular';

angular
.module('payhqUIApp')
.service('FormSubmitted', function () {
    return {
        isSubmitted: false,
        send: function(form){
            this.isSubmitted = form.$submitted;
            return this.isSubmitted;
        },
        get: function(){
            return this.isSubmitted;
        }
    };
});