import angular from 'angular';
import moment from 'moment';
import ValidatorLocator from 'service/validators/validator-locator';
import 'bootstrap-daterangepicker/daterangepicker';
import 'angular-daterangepicker';
/**
 *  @ngdoc component
 *  @name paperInput
 *  @element paper-input
 *  @restrict ECA
 *
 *  @description
 *  Loading animation component. Renders a progress image.
 *
 *  @example
    <paper-input
       type=''
       label=''
       placeholder=''
       ngModel=''
       ng-readonly=''
       ng-required=''
       modelOptions='>
    </paper-input>
*/
var CHAR_SPECIAL = /[$&+,:;=?@#|'<>.^*()%!\-\}\{\"]/;
var CHAR_NUMBER = /[0-9]/;
var CHAR_UPPERCASE = /[A-Z]/;
var CHAR_LOWERCASE = /[a-z]/;
var CHAR_MIN = /^.{8,}$/;
var CHAR_CHECK = /[^\dA-Z]/g;

let existingDate;
let isExistingDateInTheFuture;

function paperInput($scope, $element, $attrs, Restangular, $timeout, TransactionService,
    PROVINCES, Utils) {
    let _this = this;

    _this.selectionLimit = 5;

    _this.invalidMessage = 'Required Field';
    _this.locationList = PROVINCES;

    _this.isInputForProvince = _this.type === 'province';
    _this.isInputForDecimal = _this.type === 'decimal';
    _this.isInputForCardNumber = _this.type === 'cardnumber';
    _this.isInputForEmailSearch = _this.type === 'emailsearch';
    _this.isInputDatepicker = _this.type === 'datepicker';
    _this.isEmail = _this.name === 'email';
    _this.isPasswordNew = _this.name === 'passwordNew';
    _this.isCurrentPassword = _this.name === 'currentPassword';
    _this.isPasswordConfirmation = _this.name === 'passwordConfirmation';
    _this.selectionIndex = null;
    _this.isSelected = false;
    _this.isInitialSelection = false;
    _this.isPasswordConfirmationSameAsPassword = false;
    _this.displayErrorMessage = false;
    _this.isValid = false;
    _this.email = {};
    _this.passwordNew = {};
    _this.currentPassword = {};
    _this.passwordConfirmation = {};
    _this.listFiltered = [];
    _this.isInputForAutocomplete = _this.isInputForProvince || _this.isInputForEmailSearch;

    _this.billing = () => $attrs.billing;
    _this.invoice = () => $attrs.invoice;

    let isNewBilling = _this.billing() === 'new';
    let isNewInvoice = _this.invoice() === 'new';
    let isExistingInvoice = _this.invoice() === 'existing';

    // This allows our custom paper-inputs to take in values and update accordingly from the datepicker plugin
    if (_this.isInputDatepicker){
        window.setInterval(function () {
            if (angular.element($element).find('input')[0].value){
                _this.ngModel = angular.element($element).find('input')[0].value;
            }
        }, 100);
    }

    if (isNewBilling){
        _this.ngModel = moment().add(1, 'days').format('MMM DD, YYYY');
    } else if (isNewInvoice){
        _this.ngModel = moment().format('MMM DD, YYYY');
    } else if (isExistingInvoice){
        existingDate = moment(_this.ngModel);
        isExistingDateInTheFuture = existingDate.diff(moment(), 'days') >= 0;
        if (!isExistingDateInTheFuture){
            _this.ngModel = moment().format('MMM DD, YYYY');
        }
    }

    // $onInit runs after everything else is loaded
    _this.$onInit = function(){
        if ((Utils.isSafari || Utils.isInternetExplorer()) && _this.type === 'color'){
            $(($element)).spectrum({
                allowEmpty: true,
                color: _this.ngModel || ''
            });
            window.setInterval(function () {
                let spectrumElement = $(($element)).spectrum('get');
                if (spectrumElement.toHex()){
                    _this.ngModel = '#'+spectrumElement.toHex();
                }
            }, 100);
        }
    };

    _this.isSafari = Utils.isSafari || Utils.isInternetExplorer();

    function selectElementByQueryType(query){
        return angular.element(document.querySelector('input[type=' + query + ']'));
    }

    _this.autocomplete = function (keyEvent) {
        if (_this.isInputForProvince || _this.isInputForEmailSearch){
            //Down arrow
            if (keyEvent.keyCode === 40) {
                if (_this.selectionIndex === null){
                    _this.selectionIndex = 0;
                } else {
                    _this.selectionIndex++;
                }
            }
            //Up arrow
            if (keyEvent.keyCode === 38) {
                _this.selectionIndex--;
            }
            //entery key
            if (keyEvent.keyCode === 13 || keyEvent.keyCode === 188 || keyEvent.keyCode === 32) {
                _this.selectedItem(_this.currentSelectedItem, _this.selectfunction());
                _this.selectionIndex = null;
                keyEvent.preventDefault();
                _this.currentSelectedItem = null;
            }
            //limmit of list
            if (_this.selectionIndex >= _this.listFiltered.length) {
                _this.selectionIndex = 0;
            }
            //start of list
            if (_this.selectionIndex < 0) {
                _this.selectionIndex = _this.listFiltered.length;
            }
        }
    };

    _this.selectedItem = function (item, callBackFunction) {
        var theItem;
        callBackFunction(item);
        if (_this.isInputForProvince){
            theItem = item.province;
            selectElementByQueryType('country').scope().$parent.paperInput.ngModel = item.country;
        }
        if (_this.isInputForEmailSearch){
            theItem = item.email;

            selectElementByQueryType('firstname').scope().$parent.paperInput.ngModel = item.first_name;
            selectElementByQueryType('lastname').scope().$parent.paperInput.ngModel = item.last_name;
            selectElementByQueryType('company').scope().$parent.paperInput.ngModel = item.company;
            selectElementByQueryType('address1').scope().$parent.paperInput.ngModel = item.address1;
            selectElementByQueryType('address2').scope().$parent.paperInput.ngModel = item.address2;
            selectElementByQueryType('city').scope().$parent.paperInput.ngModel = item.city;
            selectElementByQueryType('postalcode').scope().$parent.paperInput.ngModel = item.postal_code;
            if (angular.element(document.getElementsByTagName('form')).scope().invoicingEdit.invoice){
                angular.element(document.getElementsByTagName('form')).scope()
                    .invoicingEdit.invoice.customer_id = item.id;
                angular.element(document.getElementsByTagName('form')).scope()
                    .invoicingEdit.invoice.customer_lookup_id = item.lookup_id;
            }
        }
        _this.ngModel = theItem;
        angular.element($element).find('input')[0].blur();

    };
    _this.checkSelectionIndex = function (index, list) {
        var isSelected = false;
        if (_this.selectionIndex >= list.length) {
            _this.selectionIndex--;
        }
        if (index === _this.selectionIndex) {
            isSelected = true;
            _this.currentSelectedItem = list[index];
        }
        return isSelected;
    };

    $scope.$on('validateInput',function () {
        _this.displayErrorMessage = true;
    });
    $scope.$on('acceptInput',function () {
        _this.displayErrorMessage = false;
    });

    _this.focused = function() {
        _this.isInitialSelection = true;
        _this.isSelected = true;
        $element[0].classList.add('isFocused');

        if (_this.isInputForCardNumber && _this.ngModel){
            _this.ngModel = _this.ngModel.replace(CHAR_CHECK, '').trim();
        }

        if (_this.isPasswordNew) {
            $element[0].classList.add('show-password-hint');
        }
    };
    _this.blurred = function() {
        $element[0].classList.remove('isFocused');

        if (_this.isInputForCardNumber && _this.ngModel){
            _this.ngModel = _this.ngModel.replace(CHAR_CHECK, '').replace(/(.{4})/g, '$1 ').trim();
        }

        //Timeout is required so clicks can fire an action before auto-complete dropdowns close
        $timeout(function() {
            _this.isSelected = false;
        }, 100);
    };

    // functions
    _this.hasSpecialCharacters = function(value){
        var testedValue;
        if (value){
            testedValue = CHAR_SPECIAL.test(value);
        }
        return testedValue;
    };
    _this.hasNumberCharacters = function(value){
        var testedValue;
        if (value){
            testedValue = CHAR_NUMBER.test(value);
        }
        return testedValue;
    };
    _this.hasUppercaseCharacters = function(value){
        var testedValue;
        if (value){
            testedValue = CHAR_UPPERCASE.test(value);
        }
        return testedValue;
    };
    _this.hasLowercaseCharacters = function(value){
        var testedValue;
        if (value){
            testedValue = CHAR_LOWERCASE.test(value);
        }
        return testedValue;
    };
    _this.hasMinimumCharacters = function(value){
        var testedValue;
        if (value){
            testedValue = CHAR_MIN.test(value);
        }
        return testedValue;
    };
    _this.hasRequiredCharacters = function(value){
        var truth;
        var reqChar = _this.hasSpecialCharacters(value) && _this.hasNumberCharacters(value) &&
            _this.hasUppercaseCharacters(value) && _this.hasLowercaseCharacters(value) &&
            _this.hasMinimumCharacters(value);
        if (reqChar){
            truth = true;
        } else {
            truth = false;
        }
        return truth;
    };
    _this.isInvalid = function(){
        var truth;
        if (!_this.isValid && _this.displayErrorMessage){
            truth = true;
        } else {
            truth = false;
        }
        return truth;
    };
    if (_this.isPasswordNew){
        _this.passwordNew = {
            isFocused : function(){
                var truth;
                if (_this.isPasswordNew && _this.isSelected){
                    truth = true;
                } else {
                    truth = false;
                }
                return truth;
            },
            isIncomplete : function(){
                if (_this.isInitialSelection && !_this.hasRequiredCharacters(_this.ngModel) && _this.isPasswordNew){
                    return true;
                }
            },
            isIncompleteNotSelected : function() {
                var truth;
                if (_this.passwordNew.isIncomplete() && _this.isSelected){
                    truth = true;
                    $element[0].classList.add('show-password-hint');
                    $element[0].classList.remove('remove-messages-box');
                } else if (_this.passwordNew.isIncomplete()) {
                    truth = true;
                } else {
                    truth = false;
                    $element[0].classList.remove('show-password-hint');
                    $element[0].classList.add('remove-messages-box');
                }
                return truth;
            },
            hasValue : function(value){
                if (_this.isPasswordNew && value){
                    return true;
                }
            }
        };
    }
    if (_this.isPasswordConfirmation){
        _this.passwordConfirmation = {
            hasValue : function(value){
                if (_this.isPasswordConfirmation && value){
                    return true;
                }
            },
            hasPasswordValue : function(value){
                var truth;
                if (_this.passwordValue === value){
                    truth = true;
                } else {
                    truth = false;
                }
                return truth;
            }
        };
    }
    if (_this.isCurrentPassword){
        _this.currentPassword = {
            hasValue : function(value){
                if (_this.isCurrentPassword && value){
                    return true;
                }
            },
            hasPasswordValue : function(value){
                var truth;
                if (_this.passwordValue === value){
                    truth = false;
                } else {
                    truth = true;
                }
                return truth;
            }
        };
    }

    _this.checkEmailExists = function(email){
        var db = Restangular.all('user-exists');
        db.get('', { email: email })
        .then(
            function(response) {
                _this.isValid = !response.exists;
                return !response.exists;
            },
            function() {
                return null;
            }
        );
    };

    if (_this.isEmail){
        _this.email.hasValue = function(value){
            if (_this.isEmail && value){
                return true;
            }
        };
        _this.email.lookup = function(){
        };
    }

    if (_this.type === 'emailsearch'){
        if (_this.hasValue){
            _this.isUserSearch = true;
        } else {
            _this.isUserSearch = false;
        }
    }
    if (_this.type === 'cardnumber'){
        _this.invalidMessage = 'Invalid Card Number';
    }
    if (_this.type === 'cardcvv'){
        _this.invalidMessage = 'Invalid Card Security';
    }
    if (_this.type === 'carddate'){
        _this.invalidMessage = 'Invalid Card Date';
    }
    if (_this.type === 'transactionid'){
        _this.invalidMessage = 'Invalid Transaction ID';
    }
    if (_this.type === 'amount'){
        _this.invalidMessage = 'Invalid Amount';
    }
    if (_this.type === 'password'){
        _this.invalidMessage = 'Invalid Password';
    }
    if (_this.type === 'subdomain'){
        _this.invalidMessage = 'Invalid Subdomain';
    }
}
angular.module('payhqUIApp').component('paperInput', {
    bindings: {
        formname: '@',
        heapIdentifier: '@',
        initialDate: '<',
        isMoney: '=',
        label: '@',
        maxlength: '@',
        min: '@',
        minDate: '@',
        modelOptions: '=',
        name: '@',
        ngChange: '=',
        ngDisabled: '=',
        ngFocus: '&',
        ngKeyup: '&',
        ngModel: '=',
        ngReadonly: '=',
        ngRequired: '=',
        ngStyle: '=',
        ngValue: '&',
        options: '=',
        placeholder: '@',
        readonly: '@',
        required: '=',
        suggestions: '<',
        type: '@',
        selectfunction: '&'
    },
    controller: paperInput,
    controllerAs: 'paperInput',
    require: '?ngModel',
    templateUrl: function(){
        return 'component/paper-input/paper-input.html';
    }
});

// <PAPER-INPUT> Directive
angular.module('payhqUIApp').directive('paperInput', function() {
    return {
        restrict        : 'EA',
        require         : 'ngModel',
        scope: false,
        link: function(scope, element, attribute, controller){
            const maximumInputNgModelValue = 256;

            var _this = scope;

            function removeCardType(){
                _this.cardtype = '';
                element[0].classList.remove('mastercard');
                element[0].classList.remove('visa');
                element[0].classList.remove('amex');
                element[0].classList.remove('discover');
                element[0].classList.remove('jcb');
            }

            if (attribute.type === 'cardyear'){
                controller.$validators.cardyear = function customValidator(value) {
                    var isCardYearValid = false;
                    if (value && value.length === 2){
                        var isYearInThePast = Number(moment().format('YY')) > value;
                        if (isYearInThePast){
                            isCardYearValid = false;
                        } else {
                            isCardYearValid = true;
                        }
                    } else if (value && value.length === 4){
                        var isAgeInThePast = Number(moment().year()) > value;
                        if (isAgeInThePast){
                            isCardYearValid = false;
                        } else {
                            isCardYearValid = true;
                        }
                    }
                    if (value && value.length === 2){
                        window.sessionStorage.cardYear = '20' + value;
                    } else {
                        window.sessionStorage.cardYear = value;
                    }
                    return isCardYearValid;
                };
            }

            if (attribute.type === 'cardmonth'){
                var isCardMonthValid = true;
                controller.$validators.cardmonth = function customValidator(value) {
                    var isValidDate = moment().year(Number(window.sessionStorage.cardYear))
                                                    .month(Number(value - 1)) >= moment();
                    if (window.sessionStorage.cardYear && isValidDate || (Number(value) <= 13 || Number(value) >= 0)){
                        isCardMonthValid = true;
                    } else if (window.sessionStorage.cardYear && !isValidDate || !value){
                        isCardMonthValid = false;
                    }

                    if (value && value.length === 1){
                        window.sessionStorage.cardMonth = '0' + value;
                    } else {
                        window.sessionStorage.cardMonth = value;
                    }
                    return isCardMonthValid;
                };
            }
            if (attribute.type === 'amount' && !attribute.novalidate){
                controller.$validators.amount = function customValidator(value){
                    var isAmountValid = true;
                    if (!value || value === 0){
                        isAmountValid = false;
                    }
                    return isAmountValid;
                };
            }
            if (attribute.type === 'cardnumber'){
                controller.$validators.cardnumber = function customValidator(value){

                    var isValid = true;

                    // Allows pasted card numbers to be valid
                    // 16 for most card types, 15 for AMEX cards

                    if ((/[*]/.test(value) && (value.length === 16 || value.length === 15))){
                        isValid = true;
                            // Allows AMEX card numbers to be valid
                    } else {
                        if ((value && value.length === 16) || (/^3[47]/.test(value) && value.length === 15)){
                                // Allows AMEX card numbers to be valid
                            if (/^3[47]/.test(value) && value.length !== 15 ){
                                isValid = false;
                            } else {
                                isValid = true;
                            }
                        }

                        if (/^3[47]/.test(value)){
                            if (value.length <= 14 || (/[\s]/.test(value) &&
                                ( value.length <= 17 || value.length > 18 ))){
                                isValid = false;
                            }
                        } else if (!value || value.length <= 15 || ((/[\s]/.test(value) && value.length <= 18))){
                            isValid = false;
                        }
                    }

                    return isValid;
                };
            }
            if (attribute.type === 'carddate' && attribute.novalidate !== 'false'){
                controller.$validators.amount = function customValidator(value){
                    var isCardDateValid;
                    if ((!value || value.length <= 4) ||
                        (moment(value, 'MM/YY').add(1,'months').unix() <= moment().unix())){
                        isCardDateValid = false;
                    // Invalidate card date if its expiry is in on any previous months before the current.
                    } else {
                        isCardDateValid = true;
                    }
                    return isCardDateValid;
                };
            }

            if (attribute.type === 'cardcvv' ){
                controller.$validators.cardcvv = function customValidator(value){
                    var isCardCvvValid = true;
                    if (!value) {
                        isCardCvvValid = false;
                    }
                    return isCardCvvValid;
                };
            }

            function init() {
                controller.$validators.decorator = function customValidator(ngModelValue) {
                    if (ngModelValue || ngModelValue === 0 || ngModelValue === false || attribute.placeholder){
                        element[0].classList.add('isFilled');
                    } else {
                        element[0].classList.remove('isFilled');
                    }
                    return true;
                };
                controller.$validators.maxLength = function customValidator(ngModelValue) {
                    if (ngModelValue && ngModelValue.length >= maximumInputNgModelValue){
                        return false;
                    }
                    return true;
                };

                if (attribute.maxLength) {
                    controller.$validators.maxlength = ValidatorLocator.validateMaxLength(element, attribute.maxLength);
                }

                if (attribute.required){
                    controller.$validators.required = function customValidator(value) {
                        var isRequiredValid = false;
                        if (value){
                            isRequiredValid = true;
                        }

                        if (!isRequiredValid){
                            element[0].classList.add('errormessage');
                        } else {
                            element[0].classList.remove('errormessage');
                        }

                        return isRequiredValid;
                    };
                }

                window.sessionStorage.cardYear = '';
                window.sessionStorage.cardMonth = '';

                if (attribute.type === 'cardnumber'){
                    _this.validatorCardIcon = function(value){
                        var cardPrefix;
                        _this.cardtype = '';
                        removeCardType();
                        if (attribute.cardType){
                            _this.cardtype = attribute.cardType.toLowerCase();
                        } else if (value || attribute.cardPrefix){
                            if (value){
                                cardPrefix = value;
                            }
                            if (attribute.cardPrefix){
                                cardPrefix = attribute.cardPrefix;
                            }
                            if (/^5[1-5]/.test(cardPrefix)) {
                                _this.cardtype = 'mastercard';
                            } else if (/^4/.test(cardPrefix)) {
                                _this.cardtype = 'visa';
                            } else if (/^3[47]/.test(cardPrefix)) {
                                _this.cardtype = 'amex';
                            } else if (/^6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5]))/
                                .test(cardPrefix)) {
                                _this.cardtype = 'discover';
                            } else if (/^35|800|2131/.test(cardPrefix)) {
                                _this.cardtype = 'jcb';
                            }
                        }
                        if (_this.cardtype){
                            element[0].classList.add(_this.cardtype);
                        }
                        return true;
                    };
                    controller.$validators.cardicon = function customValidator(value) {
                        return _this.validatorCardIcon(value);
                    };
                }

                // functions
                var hasSpecialCharacters = function(value){
                    if (value){
                        if (CHAR_SPECIAL.test(value)){
                            attribute.$set('character-special','yes');
                        } else {
                            attribute.$set('character-special','no');
                        }
                        return CHAR_SPECIAL.test(value);
                    }
                };
                var hasNumberCharacters = function(value){
                    if (value){
                        return CHAR_NUMBER.test(value);
                    }
                };
                var hasUppercaseCharacters = function(value){
                    if (value){
                        return CHAR_UPPERCASE.test(value);
                    }
                };
                var hasLowercaseCharacters = function(value){
                    if (value){
                        return CHAR_LOWERCASE.test(value);
                    }
                };
                var hasMinimumCharacters = function(value){
                    if (value){
                        return CHAR_MIN.test(value);
                    }
                };
                var hasRequiredCharacters = function(value){
                    var hasRequiredCharactersValue;
                    var reqChar = hasSpecialCharacters(value) && hasNumberCharacters(value) &&
                        hasUppercaseCharacters(value) && hasLowercaseCharacters(value) &&
                        hasMinimumCharacters(value);
                    if (reqChar){
                        hasRequiredCharactersValue = true;
                    } else {
                        hasRequiredCharactersValue = false;
                    }
                    return hasRequiredCharactersValue;
                };
                if (attribute.type === 'password'){
                    var truth;
                    controller.$validators.password = function customValidator(modelValue) {
                        var containsSpecialCharacters = hasSpecialCharacters(modelValue);
                        var containsNumberCharacters = hasNumberCharacters(modelValue);
                        var containsUppercaseCharacters = hasUppercaseCharacters(modelValue);
                        var containsLowercaseCharacters = hasLowercaseCharacters(modelValue);
                        var containsMinimumCharacters = hasMinimumCharacters(modelValue);

                        if (containsSpecialCharacters){
                            element.addClass('character-special');
                        } else {
                            element.removeClass('character-special');
                        }
                        if (containsNumberCharacters){
                            element.addClass('character-number');
                        } else {
                            element.removeClass('character-number');
                        }
                        if (containsUppercaseCharacters){
                            element.addClass('character-uppercase');
                        } else {
                            element.removeClass('character-uppercase');
                        }
                        if (containsLowercaseCharacters){
                            element.addClass('character-lowercase');
                        } else {
                            element.removeClass('character-lowercase');
                        }
                        if (containsMinimumCharacters){
                            element.addClass('character-minimum');
                        } else {
                            element.removeClass('character-minimum');
                        }


                        if (!modelValue || hasRequiredCharacters(modelValue)){
                            truth = true;
                        } else {
                            truth = false;
                        }
                        return truth;
                    };
                // Needs refactoring
                } else if (_this.isPasswordConfirmation){
                    _this.invalidMessage = 'Must match password';
                    scope.$on('password',function (event, argument) {
                        _this.passwordValue = argument.value;
                    });
                    controller.$validators.passwordConfirmation = function customValidator(modelValue) {
                        if (_this.passwordValue === modelValue ||
                            _this.passwordValue === '' || _this.passwordValue === undefined){
                            _this.isValid = true;
                            return true;
                        } else {
                            _this.isValid = false;
                            return false;
                        }
                    };
                } else if (_this.isCurrentPassword){
                    _this.invalidMessage = 'Current and new passwords cannot be the same.';
                    scope.$on('password',function (event, argument) {
                        _this.passwordValue = argument.value;
                    });
                    controller.$validators.currentPassword = function customValidator(modelValue) {
                        if (_this.passwordValue === modelValue ||
                            _this.passwordValue === '' || _this.passwordValue === undefined){
                            _this.isValid = false;
                            return false;
                        } else {
                            _this.isValid = true;
                            return true;
                        }
                    };
                }
            }
            init();
        }
    };

});

// <INPUT> DIRECTIVE
angular.module('payhqUIApp').directive('input',
        function(DateFormat) {
            return {
                restrict: 'E',
                require: '?ngModel',
                link: function(scope, element, attribute, controller) {
                    var previousCardValue;
                    var datePickerOptions = {};

                    var isInputDatePicker = attribute.type === 'datepicker';
                    var isInputCardNumber = attribute.type === 'cardnumber';
                    var isInputTypeQuantity = attribute.type === 'quantity';
                    var isInputCardDate = attribute.type === 'carddate';
                    var isInputOTP = attribute.type === 'otp';
                    var isInputCardSecurity = attribute.type === 'cardcvv';
                    var isInputAmount = attribute.type === 'amount';
                    var isInputSearch = attribute.type === 'search';
                    var isDeleteKeyPressed = false;

                    let isNewBilling = attribute.billing === 'new';
                    let isExistingBilling = attribute.billing === 'existing';
                    let isNewInvoice = attribute.invoice === 'new';
                    let isExistingInvoice = attribute.invoice === 'existing';

                    var focusThisInput = function(){
                        return element[0].focus();
                    };

                    var previousDateValue;

                    if (isInputDatePicker) {
                        datePickerOptions.singleDatePicker = true;
                        datePickerOptions.parentEl = element.parent();
                        datePickerOptions.locale = {};
                        datePickerOptions.locale.format = DateFormat.DISPLAY_FORMAT;

                        if (isNewBilling || isExistingBilling){
                            datePickerOptions.minDate = moment().add(1, 'days');
                        }

                        if (isNewBilling){
                            datePickerOptions.startDate = moment().add(1, 'days');
                        } else if (isNewInvoice){
                            datePickerOptions.startDate = moment();
                            datePickerOptions.minDate = moment();
                        } else if (isExistingInvoice){
                            if (isExistingDateInTheFuture){
                                datePickerOptions.startDate = moment(existingDate);
                            } else {
                                datePickerOptions.startDate = moment();
                            }
                            datePickerOptions.minDate = moment();
                        } else {
                            datePickerOptions.startDate = moment();
                        }

                        $(element).daterangepicker(datePickerOptions);
                    }

                    if (isInputTypeQuantity){
                        element.on('keydown', function(event) {
                            if (event.key === 'Shift'){
                                event.preventDefault();
                                return false;
                            }
                            if (
                                /\d/.test(event.key) ||
                                event.key === 'ArrowLeft' ||
                                event.key === 'ArrowRight' ||
                                event.key === 'Backspace'){
                                return true;
                            } else {
                                event.preventDefault();
                                return false;
                            }
                        });
                        controller.$parsers.push((value) => {
                            let returnValue;
                            returnValue = value.replace('.', '');
                            controller.$setViewValue(returnValue);
                            controller.$render();
                            return returnValue;
                        });
                    }

                    if (isInputTypeQuantity){
                        element.on('keydown', function(event) {
                            if (event.key === 'Shift'){
                                event.preventDefault();
                                return false;
                            }
                            if (
                                /\d/.test(event.key) ||
                                event.key === 'ArrowLeft' ||
                                event.key === 'ArrowRight' ||
                                event.key === 'Backspace'){
                                return true;
                            } else {
                                event.preventDefault();
                                return false;
                            }
                        });
                        controller.$parsers.push((value) => {
                            let returnValue;
                            returnValue = value.replace('.', '');
                            controller.$setViewValue(returnValue);
                            controller.$render();
                            return returnValue;
                        });
                    }

                    if (isInputCardNumber) {
                        controller.$parsers.push(function(value) {

                            var returnValue;

                            returnValue = value.replace(CHAR_CHECK, '');
                            if ((/^3[47]/.test(value)) && value.length === 15){
                                $('input[type="carddate"]').focus();
                            }

                            if (value.length === 16) {
                                previousCardValue = value;
                                $('input[type="carddate"]').focus();
                            } else if (value.length >= 17) {
                                returnValue = previousCardValue;
                            }

                            controller.$setViewValue(returnValue);
                            controller.$render();

                            return returnValue;
                        });
                    }

                    if (isInputCardDate) {
                        element.on('keydown', function(e) {
                            if (e.keyCode >= 48 && e.keyCode <= 57 ||
                                e.keyCode === 32 || e.keyCode >= 96 && e.keyCode <= 105) {
                                isDeleteKeyPressed = false;
                            } else if (e.keyCode === 8) {
                                isDeleteKeyPressed = true;
                            } else {
                                isDeleteKeyPressed = false;
                                e.preventDefault();
                            }
                        });
                        controller.$parsers.push(function(value) {
                            let parsedValue = value;
                            let isValueLengthGreaterThanOne = value.length > 1;
                            let valueDoesNotContainSlash = value.indexOf('/') === -1;

                            if (!isDeleteKeyPressed){
                                if (isValueLengthGreaterThanOne && valueDoesNotContainSlash){
                                    parsedValue = value.slice(0, 2) + '/' + value.slice(2);
                                } else if (value.length === 5) {
                                    previousDateValue = value;
                                    $('input[type="cardcvv"]').focus();
                                } else if (value.length >= 6) {
                                    parsedValue = previousDateValue;
                                }
                                controller.$setViewValue(parsedValue);
                                controller.$render();
                            }
                            return parsedValue;
                        });
                    }

                    if (isInputOTP) {
                        element.on('keydown', function(e) {
                            // Do nothing unless the user pressed a numeric key (0-9), pressed numpad keys (96-105), pressed backspace or is  pressing control v to paste
                            if (!( (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || (e.keyCode === 8 || (e.ctrlKey || e.metaKey) && e.keyCode === 86))) {
                                e.preventDefault();
                            }
                        });
                    
                        // Handle paste event for both keyboard shortcuts and context menu
                        element.on('paste', function(e) {
                            e.preventDefault();  // Prevent the default paste action
                        
                            // Safely access clipboard data
                            const clipboardData = (e.clipboardData || window.clipboardData || e.originalEvent.clipboardData);
                            const pastedData = clipboardData ? clipboardData.getData('text') : '';
                        
                            // Check if pasted data is numeric and limit to 6 characters
                            if (/^\d+$/.test(pastedData)) {
                                const truncatedData = pastedData.slice(0, 6);
                                controller.$setViewValue(truncatedData);
                                controller.$render();
                            }
                        });
                    
                        // Ensure only numeric characters and limit to 6 characters on normal input
                        controller.$parsers.push(function(value) {
                            let parsedValue = value.replace(/\D/g, ''); // Remove any non-numeric characters
                    
                            // Limit to 6 characters
                            if (parsedValue.length > 6) {
                                parsedValue = parsedValue.slice(0, 6);
                                controller.$setViewValue(parsedValue);
                                controller.$render();
                            }
                    
                            return parsedValue;
                        });
                    }

                    if (isInputCardSecurity) {
                        let previousCvvValue;
                        controller.$parsers.push(function(value) {

                            var returnValue = value;

                            if (value.length === 4) {
                                previousCvvValue = value;
                            } else if (value.length >= 5) {
                                returnValue = previousCvvValue;
                            }
                            controller.$setViewValue(returnValue);
                            controller.$render();

                            return returnValue;
                        });
                    }

                    function moveCursorToEnd(el) {
                        if (typeof el.selectionStart === 'number') {
                            el.selectionStart = el.selectionEnd = el.value.length;
                        } else if (typeof el.createTextRange !== 'undefined') {
                            el.focus();
                            var range = el.createTextRange();
                            range.collapse(false);
                            range.select();
                        }
                    }

                    if (isInputAmount) {
                        focusThisInput();
                        element.on('click', function() {
                            moveCursorToEnd(element[0]);
                        });
                    }

                    if (isInputSearch) {
                        focusThisInput();
                    }
                }
            };

        });

angular.module('payhqUIApp').directive('selectMe', function() {
    return {
        scope: {
            select: '&'
        },
        link: function(scope, element) {
            element.on('keyup', '[selectable]', function(event) {
                var $this = $(this);
                var selectedElement = {};
                scope.$apply(function() {
                    if (event.which === 40) {
                        selectedElement = $this.next('[selectable]');
                        $('li[selectable]').removeClass('selected');
                        selectedElement.addClass('selected');
                        if (selectedElement.length > 0) {
                            scope.select({
                                element: selectedElement
                            });
                        }
                    } else if (event.which === 38) {
                        selectedElement = $this.prev('[selectable]');
                        $('li[selectable]').removeClass('selected');
                        selectedElement.addClass('selected');
                        if (selectedElement.length > 0) {
                            scope.select({
                                element: $this.prev('[selectable]')
                            });
                        }
                    }
                });

                if (selectedElement.length > 0) {
                    $this.blur();
                    selectedElement.focus();
                }

            });
        }
    };
});
