import angular from 'angular';
/**
 *  view : <paper-minmax-filter filteramountmin="filteramountmin"
    filteramountmax = "filteramountmax"></paper-minmax-filter>
 *  set filter on ng-repeat :
 */
function paperFilterMinMax($scope) {

    var _this = this;

    _this.filteramountMin = null;
    _this.filteramountMax = null;

    $scope.$watch('amountMin', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            _this.filteramountmin = newValue;
        }
    });

    $scope.$watch('amountMax', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            _this.filteramountmax = newValue;
        }
    });
}


angular
.module('payhqUIApp')
.component('paperFilterMinMax', {
    bindings: {
        filteramountmin: '=',
        filteramountmax: '='
    },
    controller: paperFilterMinMax,
    controllerAs: 'paperFilterMinMax',
    templateUrl: 'component/paper-filter/paper-filter-min-max/paper-filter-min-max.html'
});