/* @flow */

import angular from 'angular';
import ConfigurationService from 'service/configuration-service';
import ReceiptSetting from 'model/receipt-setting';
import ApiSetting from 'model/api-setting';
import SessionStorageService from 'service/session-storage-service';
import Setting from 'model/setting';

/**
 * @ngdoc service
 * @name payhqUIApp.customerApp.service:SettingsService
 * @requires Restangular
 * @description
 *
 * Service for all settings related endpoints
 *
 */
angular
    .module('settingsApp')
    .service('SettingsService', function SettingsService(HttpClientService) {

        function getSettings() {
            let privateUrl = ConfigurationService.getConfig().merchantServiceApi + 'setting';
            let params = {};
            return HttpClientService
                .doGet(
                    privateUrl,
                    params,
                    {})
                .then((response) => {
                    return new Setting(response.data);
                }).catch((error) => {
                    throw error;
                });
        }

        return {

            initSettings: function () {
                let setting = SessionStorageService.get().getSetting();

                return new Promise(function (resolve, reject) {
                    if (setting) {
                        resolve(setting);
                    } else {
                        getSettings().then(
                            function (response) {
                                SessionStorageService.get().setSetting(response);
                                SessionStorageService.update();
                                resolve(response);
                            }
                        ).catch(function (e) {
                            reject(e);
                        });
                    }
                });
            },

            getRequiredFields: function () {
                return SessionStorageService.get().getRequiredFields();
            },

            saveSettings: function (data) {
                let privateUrl = ConfigurationService.getConfig().merchantServiceApi + 'setting';

                return HttpClientService
                    .doPut(
                        privateUrl,
                        data)
                    .then((response) => {
                        let setting = new Setting(response.data);
                        SessionStorageService.get().setSetting(setting);
                        SessionStorageService.update();
                        return setting;
                    }).catch((error) => {
                        throw error;
                    });
            },

            getReceiptSettings: function () {
                let privateUrl = ConfigurationService.getConfig().merchantServiceApi + 'receipt';
                return HttpClientService
                    .doGet(privateUrl)
                    .then((response) => {
                        return new ReceiptSetting(response.data);
                    }).catch((error) => {
                        throw error;
                    });
            },

            saveReceiptSettings: function (receiptSettings) {

                let lookupId = receiptSettings.lookup_id;

                if (lookupId) {
                    return HttpClientService
                        .doPut(ConfigurationService.getConfig().merchantServiceApi + 'receipt/' + lookupId,
                            receiptSettings)
                        .then((response) => {
                            return new ReceiptSetting(response.data);
                        }).catch((error) => {
                            throw error;
                        });
                } else {
                    return HttpClientService
                        .doPost(ConfigurationService.getConfig().merchantServiceApi + 'receipt', receiptSettings)
                        .then((response) => {
                            return new ReceiptSetting(response.data);
                        }).catch((error) => {
                            throw error;
                        });
                }
            },

            getApiSettings: function () {
                return HttpClientService
                    .doGet(ConfigurationService.getConfig().merchantServiceApi + 'api')
                    .then((response) => {
                        return new ApiSetting(response.data);
                    }).catch((error) => {
                        throw error;
                    });
            },
            updateApiSettings: function (updateApiSettingsRequest) {
                return HttpClientService
                    .doPatch(ConfigurationService.getConfig().merchantServiceApi + 'api', updateApiSettingsRequest)
                    .then((response) => {
                        return new ApiSetting(response.data);
                    }).catch((error) => {
                        throw error;
                    });
            }
        };
    });
