import angular from 'angular';
import moment from 'moment';

const UPDATE_PAPER_CALENDAR_FILTER_RANGE = 'UPDATE_PAPER_CALENDAR_FILTER_RANGE';

function paperCalendarFilter($scope, $translate,
                             HeapService) {

    var _this = this;

    $scope.filter = {
        date: {
            end: false,
            start: false
        },
        dateRange: {
            startDate: window.PAYFIRMA.CONSTANT.DEFAULT_CALENDAR_START_DATE.format('MM/DD/YYYY'),
            endDate: window.PAYFIRMA.CONSTANT.DEFAULT_CALENDAR_END_DATE.format('MM/DD/YYYY')
        }
    };

    _this.filtercalendar = {
        startDate: window.PAYFIRMA.CONSTANT.DEFAULT_CALENDAR_START_DATE,
        endDate: window.PAYFIRMA.CONSTANT.DEFAULT_CALENDAR_END_DATE
    };

    if (_this.noDates) {
        $scope.filter.dateRange.startDate = '';
        $scope.filter.dateRange.endDate = '';
    }


    _this.$onInit = function(){
        if (document.getElementById('date-range-filter')){
            document.getElementById('date-range-filter')
            .value = $scope.filter.dateRange.startDate + ' - ' + $scope.filter.dateRange.endDate;
        }
    };

    var currentLang = $translate.use();
    var localSetup = {
        'format': 'MM/DD/YYYY',
        'separator': ' - '
    };
    moment.locale('en');

    var ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract('days', 1), moment().subtract('days', 1)],
        'Last 7 Days': [moment().subtract('days', 6), moment()],
        'Last 30 Days': [moment().subtract('days', 29), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract('month', 1).startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    if (currentLang === 'fr') {
        moment.locale('fr-ca');
        localSetup = {
            'format': 'YYYY/MM/DD',
            'separator': ' - ',
            'applyLabel': 'Appliquer',
            'cancelLabel': 'Annuler',
            'fromLabel': 'de',
            'toLabel': 'à',
            'customRangeLabel': 'Coutume',
            'daysOfWeek': [
                'Di',
                'Lu',
                'Ma',
                'We',
                'Je',
                'Ve',
                'Sa'
            ],
            'monthNames': [
                'Janvier',
                'Février',
                'Mars',
                'Avril',
                'Mai',
                'Juin',
                'Juillet',
                'Août',
                'Septembre',
                'Octobre',
                'Novembre',
                'Décembre'
            ],
            'firstDay': 1
        };
        ranges = {
            'Aujourd`hui': [moment(), moment()],
            'Hier': [moment().subtract('days', 1), moment().subtract('days', 1)],
            '7 Derniers jours': [moment().subtract('days', 6), moment()],
            '30 Derniers jours': [moment().subtract('days', 29), moment()],
            'Ce mois-ci': [moment().startOf('month'), moment().endOf('month')],
            'Mois dernier': [moment().subtract('month', 1).startOf('month'),
                moment().subtract('month', 1).endOf('month')]
        };
    }

    $scope.dateRangeFilterOpts = {
        locale: localSetup,
        ranges: ranges
    };

    function initFilter() {
        _this.filtercalendarstart = moment().subtract('days', 30).format('YYYY-MM-DD');
        _this.filtercalendarend = moment().format('YYYY-MM-DD');
    }

    initFilter();

    /**
     * Watch for filter changes in datepicker start and end date
     */
    $scope.$watch('filter.dateRange', function (newValue, oldValue) {
        HeapService.trackDatePicker(newValue.startDate, newValue.endDate);
        if (newValue !== oldValue) {
            if (!newValue.startDate) {
                _this.filtercalendarstart = '';
            } else {
                _this.filtercalendarstart = $scope.filter.dateRange.startDate.format('YYYY-MM-DD');
            }

            if (!newValue.endDate) {
                _this.filtercalendarend = '';
            } else {
                _this.filtercalendarend = $scope.filter.dateRange.endDate.format('YYYY-MM-DD');
            }

            _this.filtercalendar = {
                startDate: $scope.filter.dateRange.startDate,
                endDate: $scope.filter.dateRange.endDate
            };
        }
    }, true);

    $scope.$on(UPDATE_PAPER_CALENDAR_FILTER_RANGE, function(){
    });
}


angular
.module('payhqUIApp')
.component('paperCalendarFilter', {
    bindings: {
        filtercalendarstart: '=',
        filtercalendarend: '=',
        filtercalendar: '=',
        filtercalendardaterange: '=',
        noDates: '='
    },
    controller: paperCalendarFilter,
    controllerAs: 'paperCalendarFilter',
    templateUrl: 'component/paper-filter/paper-calendar-filter/paper-calendar-filter.html'
});