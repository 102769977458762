let currentHost = window.location.host;

let TC_BANNERS = [
    // CIM-1954 - Remove this object when done
    {
        'title':'Strengthen Your Account Protection',
        // eslint-disable-next-line max-len
        'content':'Enhance your account security with Multi-Factor Authentication (MFA). Set it up easily by entering your phone number for an extra layer of protection. Enabling MFA will apply to all staff members.',
        'button_label':'Enable MFA',
        'link_url':`https://${currentHost}/#/my-profile`
    },
    {
        'title':'Get 30 More Days to Pay Your Supplier',
        'content':'BillMarket gives you credit to pay your suppliers on time and extend invoice payments by up to 120 days!',
        'button_label':'Sign me up!',
        'link_url':'https://www.billmarket.com/payfirma/'
    },
    {
        'title':'Extend your business buyer terms by up to 120 days!',
        'content':'BillMarket gives you credit to pay your suppliers on time and allows you to extend invoice payments by up to 120 days',
        'button_label':'Extend my terms now!',
        'link_url':'https://www.billmarket.com/payfirma/'
    },
    {
        'title':'Extend terms, manage cash flow and please your supplier',
        'content':'Get instant access to credit to pay your suppliers and extend payment terms!',
        'button_label':'How much do I qualify for?',
        'link_url':'https://www.billmarket.com/payfirma/'
    },
    {
        'title':'Find out your free Equifax small business credit grade!',
        'content':'Find out how much credit can be extended to your business and how to maximize that!',
        'button_label':'Check my grade',
        'link_url':'https://www.billmarket.com/payfirma/'
    },
    {
        'title':'Get your free Equifax small business credit grade today!',
        'content':'Check your grade and see how much credit is available to your business! Get working capital at your fingertips to maximize your business needs.',
        'button_label':'Check my grade',
        'link_url':'https://www.billmarket.com/payfirma/'
    },
    {
        'title':'Find out how much credit can be extended to your business!',
        'content':'Check your free Equifax small business grade today and see how much credit is available to your business!',
        'button_label':'Check my grade',
        'link_url':'https://www.billmarket.com/payfirma/'
    },
    {
        'title':'Get more credit for your small business today!',
        'content':'Check your free Equifax small business grade today and see how much credit is available to your business!',
        'button_label':'Check my grade',
        'link_url':'https://www.billmarket.com/payfirma/'
    }
];

export default TC_BANNERS;