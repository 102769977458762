import angular from 'angular';
/**
 *  view : <paper-invoicestatus-filter filterinvoicestatus='filterinvoicestatus'></paper-invoicestatus-filter>
 *  set filter on ng-repeat : customFilter:filterinvoicestatus
 */
function paperFilterInvoiceStatus($element) {
    var _this = this;

    _this.isDropped = false;

    _this.doDrop = function () {
        _this.isDropped = !_this.isDropped;
        _this.currentElement = $element;
    };

    $(document).click(function(){
        if (_this.isDropped === true){
            _this.isDropped = false;
            $('dropdown').hide();
        }
    });

    $element.on('click' ,function(e){
        if (_this.isDropped === true){
            $('dropdown').show();
        }
        e.stopPropagation();
    });

    var defaultTitle = window.PAYFIRMA.TEXT_STRINGS.STATUS;

    var defaultSelected = ['Draft', 'Unpaid', 'Paid', 'Overdue', 'Closed'];

    _this.title = defaultTitle + ' ('+ defaultSelected.length + ')';

    _this.selectedInvoiceStatus = defaultSelected;
    _this.invoiceStatusList = [
        { name: window.PAYFIRMA.TEXT_STRINGS.DRAFT, key:'Draft' },
        { name: window.PAYFIRMA.TEXT_STRINGS.UNPAID, key:'Unpaid' },
        { name: window.PAYFIRMA.TEXT_STRINGS.PAID, key:'Paid' },
        { name: window.PAYFIRMA.TEXT_STRINGS.OVERDUE, key:'Overdue' },
        { name: window.PAYFIRMA.TEXT_STRINGS.CLOSED, key:'Closed' }
    ];

    _this.setSelectedInvoiceStatus = function (invoiceStatus) {

        var key = invoiceStatus.key;

        if (invoiceStatus.invoiceStatusSelected) {
            _this.selectedInvoiceStatus.push(key);
            invoiceStatus.invoiceStatusSelected = true;
        } else if (_.includes(_this.selectedInvoiceStatus, key)) {
            _this.selectedInvoiceStatus = _.without(_this.selectedInvoiceStatus, key);
            invoiceStatus.invoiceStatusSelected = false;
        }

        if (_this.selectedInvoiceStatus.length === 0){
            _this.title = defaultTitle;
            _this.filterinvoicestatus = { status: defaultSelected };
        } else {
            _this.title = defaultTitle +' (' + _this.selectedInvoiceStatus.length + ')';
            _this.filterinvoicestatus = { status: _this.selectedInvoiceStatus };
        }
    };

    function initFilter() {
        angular.forEach(_this.invoiceStatusList, function (invoiceStatus) {
            invoiceStatus.invoiceStatusSelected = true;
        });
        _this.filterinvoicestatus = { status: _this.selectedInvoiceStatus };
    }

    initFilter();
}


angular
.module('payhqUIApp')
.component('paperFilterInvoiceStatus', {
    bindings: {
        filterinvoicestatus: '='
    },
    controller: paperFilterInvoiceStatus,
    controllerAs: 'paperFilterInvoiceStatus',
    templateUrl: 'component/paper-filter/paper-filter-invoice-status/paper-filter-invoice-status.html'
});