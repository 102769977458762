/* @flow */

import angular from 'angular';
import ConfigurationService from 'service/configuration-service';

/**
 * @ngdoc service
 * @name payhqUIApp.analyticsApp.service:AnalyticsService
 * @description
 *
 * Service for accessing Backend Analytics Service
 *
 */
angular
.module('analyticsApp')
.factory('AnalyticsService', function AnalyticsService(HttpClientService) {
    return {
        getDashboardTransactions: function (searchParameters) {
            const DASHBOARD_TRANSACTION_ENDPOINT = 'dashboard_transactions/';
            var queryString = '?' +
                Object.keys(searchParameters).map(function(key) {
                    return encodeURIComponent(key) + '=' +
                        encodeURIComponent(searchParameters[key]);
                }).join('&');
            return HttpClientService.doGet(ConfigurationService.getConfig().analyticsServiceApi +
                   DASHBOARD_TRANSACTION_ENDPOINT + queryString)
                .then(
                    function (response) {
                        if (response.data) {
                            return response.data.data;
                        } else {
                            return undefined;
                        }
                    }
                );
        }
    };
});
