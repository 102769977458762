import angular from 'angular';
/**
 * @ngdoc directive
 * @name payHqUIApp.component:paperLoader
 * @element paper-loader
 *
 * @description
 * Loading animation component. Renders a progress image.
 *
 * @example
 * <paper-loader></paper-loader>
 *
*/

function paperLoader() {
}
export default angular.module('payhqUIApp').component('paperLoader', {
    bindings: {
    },
    controller: paperLoader,
    controllerAs: 'paperLoader',
    require: '?ngModel',
    templateUrl: function(){
        return 'component/paper-loader/paper-loader.html';
    }
}).name;