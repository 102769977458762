import angular from 'angular';

function paperSelect($element){
    var _this = this;
    var selected;
    _this.invalidMessage = 'Required Field';
    _this.focused = function() {
        $element[0].classList.add('isFocused');
    };
    _this.blurred = function() {
        if (!_this.ngModel){
            $element[0].classList.remove('isFocused');
        }
    };

    _this.getValueSelectedAndUpdateModelIfNecessary = function(){
        //catch all in case there is no default value set at the uplevel
        if (!angular.isDefined(_this.ngModel) && _this.options && _this.options.length) {
            _this.ngModel = _this.options[0].value;
        }

        selected = _.find(_this.options, function(option) {
            return option.value === _this.ngModel;
        });

        if (selected) {
            return selected.key;
        } else {
            return undefined;
        }
    };

    _this.change = function(selectFunction){
        if (selectFunction){
            if (_this.functiontype === 'selectTax' || _this.functiontype === 'merchantCardTerminal'){
                selectFunction(_this.parameters,_this.ngModel);
            } else {
                selectFunction(_this.ngModel);
            }
        }
    };

    _this.newChange = (functionCall) => {
        if (functionCall){
            functionCall(_this.ngModel);
        }
    };
}
angular.module('payhqUIApp').component('paperSelect', {
    bindings: {
        ngModel       : '=',
        type: '@',
        ngReadonly    : '=',
        ngDisabled    : '=',
        options       : '<',
        change        : '&',
        name          : '@',
        placeholder   : '@',
        formname      : '@',
        required      : '@',
        label         : '@',
        validPassword : '@',
        thisOption    : '@',
        ngChange      : '&',
        action        : '&',
        changegroup   : '&',
        selectfunction: '&',
        functiontype: '@',
        parameters: '=',
        heapIdentifier : '@',
        additionalMessage   : '='
    },
    controller: paperSelect,
    controllerAs: 'paperSelect',
    templateUrl: 'component/paper-select/paper-select.html'
});

angular.module('payhqUIApp').directive('paperSelect',
    function() {
        return {
            restrict        : 'E',
            require         : '?ngModel',
            link: function(scope, element, attribute, controller){
                if (attribute.required){
                    controller.$validators.required = function customValidator(ngModelValue) {
                        var isRequiredValid = false;

                        if (ngModelValue){
                            isRequiredValid = true;
                        }

                        if (!isRequiredValid || isRequiredValid !== 0){
                            element[0].classList.add('errormessage');
                        } else {
                            element[0].classList.remove('errormessage');
                        }

                        return isRequiredValid;
                    };

                }
            }
        };
    }
);
