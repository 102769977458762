/* @flow */

import angular from 'angular';
import $ from 'jquery';
import UtilityService from 'service/utility-service';

window.jQuery = $;

function login($scope, $element, $location, $window, Utils, $log,
               AuthService, SessionService, PaperToastService, ResetPasswordService) {

    const MESSAGE_TYPE_ALERT = 'alert';
    const MESSAGE_TYPE_INFO = 'info';
    const MESSAGE_TYPE_TITLE = 'title';
    const REGISTER_SCREEN = 'registerScreen';
    const RESET_PASSWORD_SCREEN = 'resetPasswordScreen';
    const RESET_PASSWORD_SCREEN_RESULT = 'resetPasswordScreen2';
    const VERIFY_OTP_SCREEN = 'verifyOTPSceen';
    const SIGNUP_URL_LINK = 'https://www.payfirma.com/signup/';

    const TOKEN_PARAM_NAME = 'token';
    const PROVIDER_PARAM_NAME = 'provider';
    const STATE_PARAM_NAME = 'state';
    const OIDC_USER_ID_PARAM_NAME = 'oidc_user_id';

    let _this = this;

    _this.resetPasswordClicked = false;

    let element = $element[0];
    element.className = 'background' + Utils.maxRandomNumberGenerator(12);

    let nextState;

    function getNextViewToGo() {
        if ($window.oidcUserInfo) {
            nextState = $window.oidcUserInfo.state;
        }

        if (nextState) {
            if (nextState.toUpperCase() === 'MY_TRANSACTION') {
                return window.PAYFIRMA.ROUTE.TRANSACTION;
            } else if (nextState.toUpperCase() === 'DASHBOARD') {
                return window.PAYFIRMA.ROUTE.DASHBOARD;
            }
            nextState = undefined;
        }
        return undefined;
    }

    function clearOidcUserIdWithProvider() {
        if ($window.oidcUserInfo) {
            $window.oidcUserInfo = undefined;
        }
    }

    function redirectAfterLogin(perm) {
        let nextView = getNextViewToGo();
        clearOidcUserIdWithProvider();

        if (nextView) {
            Utils.go(nextView);
        } else {
            SessionService.goToDefaultView(perm);
        }

        Utils.forceScopeApply($scope);
    }

    function clearResetPasswordQueryParam() {
        $location.search(window.PAYFIRMA.RESET_PASSWORD_QUERY_PARAM, undefined);
    }

    setTimeout(function () {
        window.jQuery('body,.login-content').removeClass('hide');
        window.jQuery('.signupInput,.email-address').val('');
    }, 250);
    // Setup other setTimeout
    // Reason : firefox delayed to load login content
    setTimeout(function () {
        window.jQuery('body,.login-content').removeClass('hide');
        window.jQuery('#loginEmail').focus();
    }, 1500);

    _this.loginCard = {
        isVisible: true,
        isSigningIn: false,
        signInScreen: {
            username: '',
            password: '',
            isVisible: true,
            alertMessage: null
        },
        resetPasswordScreen: {
            email: '',
            isVisible: false,
            alertMessage: null
        },
        resetPasswordScreen2: {
            isVisible: false,
            alertMessage: null
        },
        verifyOTPScreen: {
            isVisible: false,
            alertMessage: null,
            otp: '',
            otpSessionToken: '',
            disableSendNewOtp: false,
            rememberDevice: false
        },
        otpDeliveryMethods : [
            {
                'key': 'Email',
                'value': 'EMAIL'
            }
        ],
        SelectedOTPDeliveryMethod: '',
        disableVerify: true
    };

    function setLoginViewVisble(isVisible) {
        _this.loginCard.isVisible = isVisible;
    }

    function setOidcUserIdWithProvider(oidcUserId, provider, state) {
        $window.oidcUserInfo = {
            oidcUserId: oidcUserId,
            provider: provider,
            state: state
        };
    }

    _this.changeView = function (view) {
        if (view === REGISTER_SCREEN) {
            window.open(SIGNUP_URL_LINK, '_blank');
        } else if (view === RESET_PASSWORD_SCREEN) {
            PaperToastService.closeErrors();
            _this.loginCard.signInScreen.isVisible = false;
            _this.loginCard.resetPasswordScreen.isVisible = true;
            _this.alertMessage = _this.loginCard.resetPasswordScreen.alertMessage;
        } else if (view === RESET_PASSWORD_SCREEN_RESULT) {
            _this.loginCard.resetPasswordScreen.isVisible = false;
            _this.loginCard.resetPasswordScreen2.isVisible = true;
        } else if (view === VERIFY_OTP_SCREEN) {
            _this.loginCard.signInScreen.isVisible = false;
            _this.loginCard.verifyOTPScreen.isVisible = true;
            _this.alertMessage = _this.loginCard.verifyOTPScreen.alertMessage;
            _this.startResendOtpTimer();
        } else {
            _this.loginCard.resetPasswordScreen.isVisible = false;
            _this.loginCard.resetPasswordScreen2.isVisible = false;
            _this.loginCard.verifyOTPScreen.isVisible = false;
            _this.loginCard.signInScreen.isVisible = true;
            _this.alertMessage = _this.loginCard.signInScreen.alertMessage;
        }
    };

    _this.goToLoginAfterResetPassword = function () {
        clearResetPasswordQueryParam();
        _this.changeView('signInScreen');
    };

    // Display Message
    // Alert, Information and Title Messages
    _this.displayMessage = function (message, type) {
        if (type === MESSAGE_TYPE_ALERT) {
            _this.alertMessage = message;
        } else if (type === MESSAGE_TYPE_INFO) {
            _this.infoMessage = message;
        } else if (type === MESSAGE_TYPE_TITLE) {
            _this.titleMessage = message;
        }
    };

    function cleanUpAlertMessage() {
        _this.displayMessage('', MESSAGE_TYPE_ALERT);
    }

    function clearSsoQueryStrings() {
        $location.search(TOKEN_PARAM_NAME, undefined);
        $location.search(STATE_PARAM_NAME, undefined);
        $location.search(PROVIDER_PARAM_NAME, undefined);
        $location.search(OIDC_USER_ID_PARAM_NAME, undefined);
    }

    function handle(authenticationResponse) {
        if (authenticationResponse.otpVerificationRequired){
            _this.initializeOTPDeliveryMethods(authenticationResponse.isTelephoneFound);
            _this.changeView(VERIFY_OTP_SCREEN);
            _this.loginCard.verifyOTPScreen.otpSessionToken = authenticationResponse.otpSessionToken;
        } else if (authenticationResponse.verificationFailed) {
            authenticationResponse.verificationFailed = true;
        } else if (authenticationResponse.reset_password_hash_token) {
            ResetPasswordService.updateChangePasswordInfo(
                authenticationResponse.user_lookup_id,
                authenticationResponse.institution_id,
                authenticationResponse.reset_password_hash_token,
                authenticationResponse.email,
                authenticationResponse.token_type);
            Utils.go(window.PAYFIRMA.ROUTE.CHANGE_OWN_PASSWORD);
            return authenticationResponse;
        } else {
            return SessionService.initializeSessionWithPromise()
                .then(function (perm) {
                    redirectAfterLogin(perm);
                });
        }
    }

    function authenticate(authServiceLoginCall) {
        _this.loginCard.isSigningIn = true;
        return authServiceLoginCall
            .then(function (authenticationResponse) {
                return handle(authenticationResponse);
            })
            .catch(function (error) {
                if (error) {
                    $log.error('Login failed');
                    _this.loginCard.isSigningIn = false;
                    setLoginViewVisble(true);
                    _this.displayMessage(error.message, MESSAGE_TYPE_ALERT);
                }
            });
    }

    _this.signIn = function (username, password) {
        cleanUpAlertMessage();

        let signInUserName = username || _this.loginCard.signInScreen.username;
        let signInPassword = password || _this.loginCard.signInScreen.password;

        let oidcUserId = ($window.oidcUserInfo) ? $window.oidcUserInfo.oidcUserId : undefined;
        let oidcProvider = ($window.oidcUserInfo) ? $window.oidcUserInfo.provider : undefined;

        return authenticate(AuthService.login(signInUserName, signInPassword, oidcUserId, oidcProvider));
    };

    _this.resetPasswordRequest = function () {
        _this.resetPasswordClicked = true;

        cleanUpAlertMessage();

        return ResetPasswordService
            .requestResetPassword(_this.loginCard.resetPasswordScreen.email).then(function () {
                _this.loginCard.resetPasswordScreen.email = '';
                _this.resetPasswordClicked = false;
                _this.changeView(RESET_PASSWORD_SCREEN_RESULT);
            }).catch(function (error) {
                _this.resetPasswordClicked = false;
                let errorMessage = 'Reset failed, please double check e-mail';
                if (error && error.message) {
                    errorMessage = error.message;
                }
                PaperToastService.create(errorMessage, 'error');
                throw error;
            });
    };

    function getStateValue(url) {
        return UtilityService.getQueryParameterValue(url, STATE_PARAM_NAME);
    }

    function getOneTimeToken(url) {
        return UtilityService.getQueryParameterValue(url, TOKEN_PARAM_NAME);
    }

    function isRestPasswordEnabled(url) {
        return UtilityService.getQueryParameterValue(url, window.PAYFIRMA.RESET_PASSWORD_QUERY_PARAM);
    }

    function verifyProvider(url) {
        let providerValue = UtilityService.getQueryParameterValue(url, PROVIDER_PARAM_NAME);
        return providerValue && providerValue.toUpperCase() === 'CIBC';
    }

    function getOidcUserId(url) {
        return UtilityService.getQueryParameterValue(url, OIDC_USER_ID_PARAM_NAME);
    }

    function extractOneTimeTokenAndLogin() {
        let oneTimeAuthenticationPromise = undefined;
        let currentUrl = $window.location.href;
        let oneTimeAuthenticationToken = getOneTimeToken(currentUrl);
        let oidcUserId = getOidcUserId(currentUrl);

        if (oneTimeAuthenticationToken && verifyProvider(currentUrl)) {
            nextState = getStateValue(currentUrl);
            setLoginViewVisble(false);
            oneTimeAuthenticationPromise = authenticate(AuthService.oneTimeAuthenticate(oneTimeAuthenticationToken));
        } else if (oidcUserId && verifyProvider(currentUrl)) {
            let providerValue = UtilityService.getQueryParameterValue(currentUrl, PROVIDER_PARAM_NAME);
            let state = getStateValue(currentUrl);
            setOidcUserIdWithProvider(oidcUserId, providerValue, state);
            setLoginViewVisble(true);
        } else {
            setLoginViewVisble(true);
        }

        clearSsoQueryStrings();

        return oneTimeAuthenticationPromise;
    }

    //Reset the one time password send timer.
    _this.startResendOtpTimer = function () {
        setTimeout(function () {
            _this.enableSendNewOTP();
        }, 60000);
    };

    //Event Handler for Send One Time Password button click.
    _this.sendOneTimePassword = function(){
        cleanUpAlertMessage();
        switch (_this.loginCard.SelectedOTPDeliveryMethod){
            case _this.loginCard.otpDeliveryMethods[0].value: //Email
                _this.sendEmailOtp();
                break;
            case _this.loginCard.otpDeliveryMethods[1].value: //Mobile
                _this.sendMobileOTP();
                break;
            default:
                _this.displayMessage('Please Select a Delivery Method for your One Time Password to be sent', MESSAGE_TYPE_ALERT);
                break;
        }
    };

    //Send Mobile One Time Password to user.
    _this.sendMobileOTP = function () {
        AuthService.sendMobileOTP(_this.loginCard.verifyOTPScreen.otpSessionToken)
        .then((response) => {
            _this.hasOneTimePasswordSessionExpired(response);
            _this.loginCard.verifyOTPScreen.otpSessionToken = response.otpSessionToken;
            _this.disableSendNewOTP();
            _this.startResendOtpTimer();
            _this.enableVerifyControls();
        }).catch(function (error) {
            _this.displayMessage(error.message, MESSAGE_TYPE_ALERT);
        });
    };

    //Email One Time Password to user.
    _this.sendEmailOtp = function () {
        cleanUpAlertMessage();
        _this.disableSendNewOTP();
        AuthService.sendEmailOTP(_this.loginCard.verifyOTPScreen.otpSessionToken)
        .then((response) => {
            _this.hasOneTimePasswordSessionExpired(response);
            _this.loginCard.verifyOTPScreen.otpSessionToken = response.otpSessionToken;
            _this.disableSendNewOTP();
            _this.startResendOtpTimer();
            _this.enableVerifyControls();
        }).catch(function (error) {
            _this.displayMessage(error.message, MESSAGE_TYPE_ALERT);
        });
    };

    //Verify One time Password
    _this.verifyOneTimePassword = function (){
        _this.loginCard.disableVerify = true;
        cleanUpAlertMessage();

        try {
            authenticate(AuthService.verifyOneTimePassword(
                _this.loginCard.verifyOTPScreen.otpSessionToken,
                _this.loginCard.verifyOTPScreen.otp,
                _this.loginCard.verifyOTPScreen.rememberDevice,
                )
            );
        } finally {
            _this.enableVerifyControls();
        }
    };

    //Disable UI Controls to send new One Time Password
    _this.disableSendNewOTP = function (){
        _this.loginCard.verifyOTPScreen.disableSendNewOtp = true;
    };

    //Enable UI Controls to send new One Time Password
    _this.enableSendNewOTP = function(){
        _this.loginCard.verifyOTPScreen.disableSendNewOtp = false;
    };

    //Enable UI Controls to send new One Time Password
    _this.enableVerifyControls = function (){
        _this.loginCard.disableVerify = false;
    };

    //Enable UI Controls to send new One Time Password
    _this.disableVerifyControls = function (){
        _this.loginCard.disableVerify = true;
    };

    //Checks if the one time password session has expired.
    _this.hasOneTimePasswordSessionExpired = function (response) {
        if (response.sessionExpired === true) {
            _this.loginCard.signInScreen.alertMessage = 'Your OTP session has expired. Please retry logging in!';
            _this.changeView('signInScreen');
            _this.loginCard.isSigningIn = false;
            _this.disableSendNewOTP();
            return true;
        }
        return false;
    };

    //Set the delivery methods available for the user in the drop down selector.
    _this.initializeOTPDeliveryMethods = function (isTelephoneFound) {
        _this.loginCard.disableVerify = true;

        _this.loginCard.otpDeliveryMethods = [
            {
                'key': 'Email',
                'value': 'EMAIL'
            }
        ];
    
        if (isTelephoneFound) {
            _this.loginCard.otpDeliveryMethods.push({
                'key': 'Mobile/SMS',
                'value': 'MOBILE'
            });
        }

        _this.loginCard.SelectedOTPDeliveryMethod = _this.loginCard.otpDeliveryMethods[0].value;
    };
    
    _this.initialize = function () {
        let currentUrl = $window.location.href;
        
        //Check if user is logging in via One time Password.
        if (UtilityService.getQueryParameterValue(currentUrl, 'otpSessionToken')){
            _this.loginCard.verifyOTPScreen.otpSessionToken = UtilityService.getQueryParameterValue(currentUrl, 'otpSessionToken');
            _this.changeView(VERIFY_OTP_SCREEN);
        }
        
        //Check if user is logging in via navigating back from the reporting system.
        let jwtToken = UtilityService.getQueryParameterValue(currentUrl, 'authToken');
        let refreshToken = UtilityService.getQueryParameterValue(currentUrl, 'refresh_token');
        let username = UtilityService.getQueryParameterValue(currentUrl, 'user');

        //If the url params exist, we are logging in via Reporting System SSO
        if (jwtToken && refreshToken && username){

            // //Revert the URL-safe base64 string to its original base64
            jwtToken = jwtToken.replace(/-/g, '+').replace(/_/g, '/');
            refreshToken = refreshToken.replace(/-/g, '+').replace(/_/g, '/');
            username = username.replace(/-/g, '+').replace(/_/g, '/');

            AuthService.loginSSO(jwtToken, refreshToken, username).then(function () {

                return SessionService.initializeSessionWithPromise()
                .then(function (perm) {
                    redirectAfterLogin(perm);
                });
            });
        } else {
            ResetPasswordService.clearChangePasswordInfo();
            AuthService.logout();
            if (isRestPasswordEnabled($window.location.href)) {
                _this.changeView(RESET_PASSWORD_SCREEN);
            } else {
                clearResetPasswordQueryParam();
                return extractOneTimeTokenAndLogin();
            }
        }
    };

    _this.initialize();
}

angular.module('payhqUIApp').component('login', {
    bindings: {
        data: '='
    },
    controller: login,
    controllerAs: 'login',
    templateUrl: 'view/login/login.html'
});
