import angular from 'angular';
import ConfigurationService from 'service/configuration-service';

angular
.module('payhqUIApp')
.service('TransactionEndpointService', function(Restangular) {
    return Restangular.withConfig(function(RestangularConfigurer) {
        let url = ConfigurationService.getConfig().transactionServiceApi;
        RestangularConfigurer.setBaseUrl(url);
    });
});

