module.exports={
  "_args": [
    [
      "mapbox.js@2.4.0",
      "/var/atlassian/application-data/bamboo/local-working-dir/TE-SHU-PUB"
    ]
  ],
  "_development": true,
  "_from": "mapbox.js@2.4.0",
  "_id": "mapbox.js@2.4.0",
  "_inBundle": false,
  "_integrity": "sha1-xDsISl3XEzTIPuHfKPpnRD1zwpw=",
  "_location": "/mapbox.js",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "mapbox.js@2.4.0",
    "name": "mapbox.js",
    "escapedName": "mapbox.js",
    "rawSpec": "2.4.0",
    "saveSpec": null,
    "fetchSpec": "2.4.0"
  },
  "_requiredBy": [
    "#DEV:/"
  ],
  "_resolved": "https://registry.npmjs.org/mapbox.js/-/mapbox.js-2.4.0.tgz",
  "_spec": "2.4.0",
  "_where": "/var/atlassian/application-data/bamboo/local-working-dir/TE-SHU-PUB",
  "author": {
    "name": "Mapbox"
  },
  "bugs": {
    "url": "https://github.com/mapbox/mapbox.js/issues"
  },
  "dependencies": {
    "corslite": "0.0.6",
    "isarray": "0.0.1",
    "leaflet": "0.7.7",
    "mustache": "2.2.1",
    "sanitize-caja": "0.1.3"
  },
  "description": "mapbox javascript api",
  "devDependencies": {
    "browserify": "^13.0.0",
    "clean-css": "~2.0.7",
    "eslint": "^0.23.0",
    "expect.js": "0.3.1",
    "happen": "0.1.3",
    "leaflet-fullscreen": "0.0.4",
    "leaflet-hash": "0.2.1",
    "marked": "~0.3.0",
    "minifyify": "^6.1.0",
    "minimist": "0.0.5",
    "mocha": "2.4.5",
    "mocha-phantomjs": "4.0.2",
    "sinon": "1.10.2"
  },
  "engines": {
    "node": "*"
  },
  "homepage": "http://mapbox.com/",
  "license": "BSD-3-Clause",
  "main": "src/index.js",
  "name": "mapbox.js",
  "optionalDependencies": {},
  "repository": {
    "type": "git",
    "url": "git://github.com/mapbox/mapbox.js.git"
  },
  "scripts": {
    "test": "eslint --no-eslintrc -c .eslintrc src && mocha-phantomjs test/index.html"
  },
  "version": "2.4.0"
}
