/* @flow */

import angular from 'angular';
import ConfigurationService from 'service/configuration-service';

/**
 * @ngdoc service
 * @name payhqUIApp.customerApp.service:NewReportingSystemService
 * @requires Restangular
 * @description
 *
 * Service for new reporting system related endpoints
 *
 */
angular
    .module('settingsApp')
    .service('NewReportingSystemService', function (HttpClientService) {

        let hqconfig = ConfigurationService.getConfig();
        const url = hqconfig.merchantServiceApi + 'encryption/encrypt';

        return {
            encryptData: function (dataToEncrypt) {
                return HttpClientService
                    .doPost(url, dataToEncrypt)
                    .then((response) => {
                        return response.data;
                    }).catch((errors) => {
                        throw errors;
                    });
            }
        };
    });
