import angular from 'angular';

/**
 * @ngdoc factory
 * @name payhqUIApp.userApp.factory:UserFactory
 * @description
 *
 * Factory for creating user objects
 *
 */

/**
 * User Object constructor.
 *
 * @constructor
 * @param {Object} initialData - Data to initialize user object with
 */
function User(initialData) {

    var self = this;
    var userData = {
        id: null,
        address1: '',
        address2: '',
        accountType: '',
        city: '',
        country: '',
        dob: null,
        email: '',
        first_name: '',
        last_name: '',
        locked_user: false,
        merchant_id: '',
        organization_id: null,
        postal_code: '',
        profile_image: null,
        province: '',
        username: '',
        user_group_id: '',
        password: '',
        telephone: '',
        title: '',
        homepage: ''
    };

    /**
     * Parse and store appropriate user details.
     *
     * @param {Object} data - User data to parse
     */
    function parseData(data) {
        if (data){
            // Save user model properties
            _.forOwn(data, function (value, key) {
                if (userData.hasOwnProperty(key) && value) {
                    userData[key] = value;

                    // Expose properties used for list filtering
                    self[key] = value;
                }
            });
        }
    }

    /**
     * Initialize user details.
     *
     * @param {Object} data - Data to initialize user object with
     */
    function initialize(data) {
        if (data) {
            parseData(data);
        }
    }

    initialize(initialData);


    /**
     * Get user info.
     *
     * @returns {Object} userData - Returns the user's data
     */
    this.getUserInfo = function () {
        return userData;
    };

    /**
     * Get user email.
     *
     * @returns {String} userEmail - Returns the user's email
     */
    this.getUserEmail = function () {
        return userData.email;
    };

    /**
     * Get user first name.
     *
     * @returns {String} userFirstName - Returns the user's first name
     */
    this.getUserFirstName = function () {
        return userData.first_name;
    };

    /**
     * Get user id.
     *
     * @returns {Number} userId - Returns the user id
     */
    this.getUserId = function () {
        return userData.id;
    };

    /**
     * Get user merchant id.
     *
     * @returns {Number} userMerchantId - Returns the user's merchant id
     */
    this.getUserMerchantId = function () {
        return userData.merchant_id;
    };

    /**
     * Get user last name.
     *
     * @returns {String} userLastName - Returns the user's last name
     */
    this.getUserLastName = function () {
        return userData.last_name;
    };

    /**
     * Set user info.
     *
     * @param {Object} data - Data to set user to
     */
    this.setUserInfo = function (data) {
        parseData(data);
    };
}

angular
    .module('userApp')
    .factory('UserFactory', function UserFactory() {
        return {
            getUser: function (data) {
                return new User(data);
            }
        };
    });
