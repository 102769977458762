import angular from 'angular';

function paperHeaderHelp(Restangular, PaperToastService) {

    var _this = this;

    var defaultHelpSet = [
        {
            title: 'Getting Started Checklist',
            html_url: 'https://support.payfirma.com/hc/en-us/articles/204169660-Getting-Started-Checklist'
        },
        {
            title: 'Processing Web Sales',
            html_url: 'https://support.payfirma.com/hc/en-us/articles/201396790-Processing-Sales-Transactions-Web'
        },
        {
            title: 'Creating a Customer Profile',
            html_url: 'https://support.payfirma.com/hc/en-us/articles/201398350-Creating-A-Customer-Profile'
        },
        {
            title: 'Customizing Receipts',
            html_url: 'https://support.payfirma.com/hc/en-us/articles/201394680-Customizing-Receipts'
        }
    ];

    // Set controller variables
    _this.visible = false;
    _this.articles = defaultHelpSet;

    // modalView: search or contact
    _this.modalView = 'search';
    _this.ticketSuccess = true;
    _this.loading = false;

    /**
     * Calls Zendesk API for articles
     */
    _this.searchZendesk = function() {
        _this.loading = true;

        // Make sure search is not empty
        if (!angular.isUndefined(_this.searchString) && _this.searchString !== '') {

            // Define search string
            var searchObject = {
                searchString: _this.searchString
            };

            // Clear articles if there is any and search for new ones
            _this.articles = '';

            var searchHelp = Restangular.one('zendesk');

            return searchHelp.customPOST({}, 'search-articles', searchObject, {})
                .then(function(response) {

                    // Display results
                    _this.articles = response.results;
                    _this.loading = false;
                    return response;
                },
                function() {
                    PaperToastService.create('There was a problem with your search', 'error');
                    _this.loading = false;
                    return null;
                }
            );
        } else {
            _this.articles = '';
            _this.loading = false;
        }
    };


    /**
     * Opens article in new tab
     *
     * @param {string} url ulr of article page to open
     */
    _this.openArticle = function(url) {
        window.open(url);
    };


    /**
     *  switch to contact modal
     */
    _this.contactUs = function() {
        _this.modalView = 'contact';
    };


    /**
     * Close the modal
     */
    _this.close = function() {
        _this.visible = false;
        _this.searchString = undefined;
        _this.articles = defaultHelpSet;
    };

    /**
     * Show the help modal
     */
    _this.showHelp = function () {
        _this.visible = true;
        _this.modalView = 'search';
    };

    /**
     * Show different views
     * @param view name
     * @returns {boolean}
     */
    _this.showView = function (view) {
        return _this.modalView === view;
    };

}

angular
.module('payhqUIApp')
.component('paperHeaderHelp', {
    bindings: {
    },
    controller: paperHeaderHelp,
    controllerAs: 'paperHeaderHelp',
    templateUrl: 'component/paper-header-help/paper-header-help.html'
});