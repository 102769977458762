'use strict';

window.PAYFIRMA = window.PAYFIRMA || {};

window.PAYFIRMA.ROUTE = {
    LOGIN: '/login',
    LOGOUT: '/logout',
    WEB_TERMINAL : '/terminal',
    CARD_TERMINAL : '/cardterminal',
    DASHBOARD : '/dashboard',
    TRANSACTION:'/transactions',
    CUSTOMER_VAULT:'/customer-vault',
    STAFF:'/staff',
    MERCHANT : '/merchant',
    SETTINGS : '/settings',
    REPORTING : '/reporting',
    RECURRING_BILLING : '/billing',
    INVOICE: '/invoicing',
    CREATE_INVOICE : '/create-invoice',
    PAGE_NOT_FOUND: '/page-not-found',
    CIBC_SSO: '/cibc-sso',
    RESET_PASSWORD: '/auth/resetpassword',
    CHANGE_OWN_PASSWORD: '/auth/changeownpassword',
    PAY_INVOICE: '/pay-invoice/',
    CARD_REQUEST: '/card-request/',
    PROSPECTS: '/prospects'
};

window.PAYFIRMA.ROUTE_PERMISSION_MAP = {
};

window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.WEB_TERMINAL] = 'VTMaster';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.CARD_TERMINAL] = 'CardTerminal';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.DASHBOARD] = 'Dashboard_Master';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.TRANSACTION] = 'MyTransactions_Master';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.CUSTOMER_VAULT] = 'CustomerVault';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.STAFF] = 'MyPeople';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.MERCHANT] = 'Oracle';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.PROSPECTS] = 'Oracle';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.SETTINGS] = 'ReceiptSettings';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.REPORTING] = 'ReportViewing';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.RECURRING_BILLING] = 'RecurringMaster';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.INVOICE] = 'InvoiceMaster';
window.PAYFIRMA.ROUTE_PERMISSION_MAP[window.PAYFIRMA.ROUTE.CREATE_INVOICE] = 'InvoiceMaster';